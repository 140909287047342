import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { Button, Column, Context, ContextMenu, ContextMenuItem, Grid, IconButton, Text } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import FormDropdown from '../../components/form-dropdown';
import FormInput from '../../components/form-input';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';
import EmailDirectorySelect from './email-directory-select';
import {
  comparisionOptions,
  domainActionOptions,
  matchOptions,
  subjectOptions,
  userActionOptions
} from './fields-options';

type Props = {
  intl: Intl;
  isDomainLevelFilter: boolean;
  validationUtils: ValidationUtils;
  values: any;
  selectedFilterEntity: { name: string };
  change: Function;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  selectEmailFolderDialog: any,
  selectedPath: string
};

const { Fragment } = React;

const hasValues = (values) => {
  if (!values) {
    return false;
  }

  return Boolean(Object.keys(values).length);
};

class FilterField extends React.Component<Props, State> {
  readonly state = {
    selectEmailFolderDialog: null,
    selectedPath: 'INBOX'
  };

  getTranslations = (options) => options.map((option) => ({
    ...option,
    label: this.props.intl.formatMessage({ id: option.label })
  }));

  renderCreateFilter() {
    const { intl, validationUtils } = this.props;

    return (
      <Fragment>
        <Column xsSpan="10" smSpan="10" lgSpan="11">
          <Field
            name="filter_name"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.emailFilter.filter.name' })}
            validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column className="email-filters-match-conditions" xsSpan="10" smSpan="10" lgSpan="11">
          <Field
            name="match_cond"
            options={this.getTranslations(matchOptions)}
            optionValue="value"
            optionLabel="label"
            style={{ display: 'inline-block', width: 'auto' }}
            data-e2e="match-condition"
            component={FormDropdown}
          />

          <Text weight="bold">{intl.formatMessage({ id: 'translate.page.emailFilter.filter.conditions.title' })}</Text>
        </Column>
      </Fragment>
    );
  }

  renderCreateFilterConditions = (data) => {
    const { values, validationUtils, intl } = this.props;
    const fields = data.fields;

    return fields.map((name, index, array) => {
      const shouldRenderConditions = hasValues(values) ?
        values.conditions[index] && values.conditions[index].field !== 'is_err' :
        true;

      return (
        <Fragment key={index}>
          <Column key={index} xsSpan="10" smSpan="10" lgSpan="10">
            <Grid xs="1" sm="12" gap="large" className="email-filters-condition-grid">
              <Column xsSpan="1" smSpan="6">
                <Field
                  name={`${name}.field`}
                  type="text"
                  options={this.getTranslations(subjectOptions)}
                  optionValue="value"
                  optionLabel="label"
                  data-e2e="subject"
                  component={FormDropdown}
                />
              </Column>

              {shouldRenderConditions && (
                <Column xsSpan="1" smSpan="6">
                  <Field
                    name={`${name}.expr`}
                    type="text"
                    options={this.getTranslations(comparisionOptions)}
                    optionValue="value"
                    optionLabel="label"
                    data-e2e="comparision"
                    component={FormDropdown}
                  />
                </Column>
              )}

              {shouldRenderConditions && (
                <Column xsSpan="1" smSpan="12">
                  <Field
                    name={`${name}.value`}
                    type="text"
                    placeholder={
                      intl.formatMessage({ id: 'translate.page.emailFilter.condition.criteria.placeholder' })
                    }
                    validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
                    component={FormInput}
                  />
                </Column>
              )}
            </Grid>
          </Column>

          <Column xsSpan="2" smSpan="2" lgSpan="2" flex align="center">
            <Context.Consumer>
              {({ device }) => device.isPhone ? (
                <React.Fragment>
                  <ContextMenu opener={<IconButton shape="circle" icon="dots" />}>
                    <ContextMenuItem
                      icon="circle-plus"
                      data-e2e="condition-plus"
                      onClick={() => fields.insert(index + 1, {
                        field: subjectOptions[0].value,
                        expr: comparisionOptions[0].value
                      })}
                    >
                      {intl.formatMessage({ id: 'translate.page.emailFilter.condition.add.new' })}
                    </ContextMenuItem>

                    <ContextMenuItem
                      icon="circle-minus"
                      data-e2e="condition-minus"
                      onClick={() => {
                        if (index === 0 && array.length === 1) {
                          fields.insert(index + 1, {
                            field: subjectOptions[0].value,
                            expr: comparisionOptions[0].value
                          });
                        }

                        fields.remove(index);
                      }}
                    >
                      {intl.formatMessage({ id: 'translate.page.emailFilter.condition.remove' })}
                    </ContextMenuItem>
                  </ContextMenu>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IconButton
                    icon="circle-minus"
                    data-e2e="condition-minus"
                    shape="circle"
                    onClick={() => {
                      if (index === 0 && array.length === 1) {
                        fields.insert(index + 1, {
                          field: subjectOptions[0].value,
                          expr: comparisionOptions[0].value
                        });
                      }

                      fields.remove(index);
                    }}
                  />

                  <IconButton
                    icon="circle-plus"
                    shape="circle"
                    data-e2e="condition-plus"
                    onClick={() => fields.insert(index + 1, {
                      field: subjectOptions[0].value,
                      expr: comparisionOptions[0].value
                    })}
                  />
                </React.Fragment>
              )}
            </Context.Consumer>
          </Column>
        </Fragment>
      );
    });
  };

  renderCreateFilterActions = (data) => {
    const fields = data.fields;
    const { intl, selectedFilterEntity, isDomainLevelFilter, change, values, validationUtils } = this.props;

    const validateProp = validationUtils ?
      [validationUtils.required, validationUtils.validationWithMetaApi] : undefined;

    return (
      <Fragment>
        <Column xsSpan="12">
          <Text align="left" weight="bold">
            {intl.formatMessage({ id: 'translate.page.emailFilter.filter.actions.title' })}
          </Text>
        </Column>

        {fields.map((name, index, array) => {
          const hasFormValues = hasValues(values);
          const actionFieldValue = hasFormValues && values.actions[index] && values.actions[index].action;
          const shouldRenderValueField = actionFieldValue !== 'discard' && actionFieldValue !== 'no_more_filters';
          let shouldRenderBrowseSuffix = false;

          let validateFunctions = validateProp;

          if (hasFormValues) {
            const actionField = values.actions[index] && values.actions[index].action;

            if (actionField === 'forward_to' && validationUtils) {
              validateFunctions = [validationUtils.required, validationUtils.email];
            }

            shouldRenderBrowseSuffix = actionField === 'move_to' || actionField === 'copy_to';
          }

          return (
            <React.Fragment key={index}>
              <Column xsSpan="10" smSpan="10" lgSpan="10">
                <Context.Consumer>
                  {({ device }) => (
                    <Grid sm="12" gap={device.isPhone ? 'medium' : 'large'}>
                      <Column smSpan="6">
                        <Field
                          key={isDomainLevelFilter ? 'domain' : 'user'}
                          name={`${name}.action`}
                          type="text"
                          options={isDomainLevelFilter ?
                            this.getTranslations(domainActionOptions) :
                            this.getTranslations(userActionOptions)
                          }
                          optionValue="value"
                          optionLabel="label"
                          data-e2e="actions"
                          onChange={() => change(`${name}.value`, '')}
                          component={FormDropdown}
                        />
                      </Column>

                      {shouldRenderValueField && (
                        <Column smSpan="6">
                          <Field
                            name={`${name}.value`}
                            type="text"
                            suffix={shouldRenderBrowseSuffix && (
                              <Button
                                action="button"
                                color="dark"
                                size="small"
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectEmailFolderDialog: {
                                        selectedFilterEntity,
                                        fieldToUpdate: `${name}.value`
                                      }
                                    },
                                    () => this.props.openSGDialog(DIALOGS.SELECT_EMAIL));
                                }}
                              >
                                {intl.formatMessage({ id: 'translate.generic.browse' })}
                              </Button>
                            )}
                            validate={validateFunctions}
                            component={FormInput}
                          />
                        </Column>
                      )}
                    </Grid>
                  )}
                </Context.Consumer>
              </Column>

              <Column xsSpan="2" lgSpan="2" flex align="center">
                <Context.Consumer>
                  {({ device }) => device.isPhone ? (
                    <React.Fragment>
                      <ContextMenu opener={<IconButton shape="circle" icon="dots" />}>
                        <ContextMenuItem
                          icon="circle-plus"
                          data-e2e="action-plus"
                          onClick={() => fields.insert(index + 1, { action: userActionOptions[0].value })}
                        >
                          {intl.formatMessage({ id: 'translate.page.emailFilter.action.add.new' })}
                        </ContextMenuItem>

                        <ContextMenuItem
                          icon="circle-minus"
                          data-e2e="action-minus"
                          onClick={() => {
                            if (index === 0 && array.length === 1) {
                              fields.insert(index + 1, { action: userActionOptions[0].value });
                            }

                            fields.remove(index);
                          }}
                        >
                          {intl.formatMessage({ id: 'translate.page.emailFilter.action.remove' })}
                        </ContextMenuItem>
                      </ContextMenu>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <IconButton
                        icon="circle-minus"
                        data-e2e="action-minus"
                        shape="circle"
                        onClick={() => {
                          if (index === 0 && array.length === 1) {
                            fields.insert(index + 1, { action: userActionOptions[0].value });
                          }

                          fields.remove(index);
                        }}
                      />

                      <IconButton
                        icon="circle-plus"
                        shape="circle"
                        data-e2e="action-plus"
                        onClick={() => fields.insert(index + 1, { action: userActionOptions[0].value })}
                      />
                    </React.Fragment>
                  )}
                </Context.Consumer>
              </Column>
            </React.Fragment>
          );
        })}
      </Fragment>
    );
  };

  render() {
    const { selectedFilterEntity, intl, change, closeSGDialog } = this.props;
    const { selectEmailFolderDialog, selectedPath } = this.state;

    return (
      <Fragment>
        {this.renderCreateFilter()}

        <FieldArray
          name="conditions"
          component={this.renderCreateFilterConditions}
        />

        <FieldArray
          name="actions"
          component={this.renderCreateFilterActions}
        />

        <SGDialog
          id={DIALOGS.SELECT_EMAIL}
          title={
            intl.formatMessage({ id: 'translate.page.emailFilter.directory.select.title' },
              { name: selectEmailFolderDialog && selectEmailFolderDialog.selectedFilterEntity.name })
          }
          icon="folder"
          state="warning"
          footer={
            <div>
              <SGDialogCancel id={DIALOGS.SELECT_EMAIL} />
              <Button
                color="primary"
                action="button"
                data-e2e="dialog-submit"
                onClick={() => {
                  change(selectEmailFolderDialog.fieldToUpdate, selectedPath);
                  closeSGDialog(DIALOGS.SELECT_EMAIL);
                }}
              >
                {intl.formatMessage({ id: 'translate.generic.select' })}
              </Button>
            </div>
          }
          resources={[{ resourceName: API_RESOURCE.EMAIL_FOLDER.resourceName, methods: ['GET'] }]}
          onCloseHandler={() => this.setState({ selectEmailFolderDialog: null })}
        >
          <EmailDirectorySelect
            selectedFilterEntity={selectedFilterEntity}
            onSelection={(path) => this.setState({ selectedPath: path })}
          />
        </SGDialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect<{}, {}, any>(undefined, mapDispatchToProps)(injectIntl(FilterField));
