import * as Actions from '../constants/actions';

export const fetchItems: FetchItems = (payload, onComplete = null) => ({
  type: Actions.FETCH_ITEMS_REQUESTED,
  payload,
  onComplete
});

export const fetchItem: FetchItem = (payload, onComplete = null) => ({
  type: Actions.FETCH_ITEM_REQUESTED,
  payload,
  onComplete
});

export const createItem: CreateItem = (payload, onComplete = null) => ({
  type: Actions.CREATE_ITEM_REQUESTED,
  payload,
  onComplete
});

export const deleteItem: DeleteItem = (payload, onComplete = null) => ({
  type: Actions.DELETE_ITEM_REQUESTED,
  payload,
  onComplete
});

export const updateItem: UpdateItem = (payload, onComplete = null) => ({
  type: Actions.UPDATE_ITEM_REQUESTED,
  payload,
  onComplete
});

export const changeQuotaItem: UpdateItem = ({ quota_unlimited, ...payload }, onComplete = null) => ({
  type: Actions.UPDATE_ITEM_REQUESTED,
  payload: {
    ...payload,
    quota_size: quota_unlimited ? 0 : parseInt(payload.quota_size, 10)
  },
  onComplete
});

export const clearResource: ClearResource = (resourceName, initialState = null) => ({
  type: Actions.CLEAR_RESOURCE,
  payload: { resourceName, initialState }
});
