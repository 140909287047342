import * as React from 'react';
import { injectIntl } from 'react-intl';

import { Icon, Text, Title } from 'sg-styleguide';

import Entity from '../entity';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../core/constants/common';
import { getEntityReadableName, getEntitySize, getEntityType } from '../../core/utils';
import bytesToSize from '../../../../../core/common/size-converter';

import './entity-preview.scss';

const EntityView = ({ entity, intl }) => {
  const isFile = getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;

  return (
    <div className="file-manager-entity-preview">
      <div className="file-manager-entity-preview__icon">
        {isFile && (
          <Entity entity={entity} size="large" hideName />
        )}
        {!isFile && (
          <Icon name="folder-outlined" size="60" />
        )}
      </div>

      <Title level="4" density="none" color="light">
        {isFile ? getEntityReadableName(entity) : intl.formatMessage({ id: 'translate.generic.empty.folder' })}
      </Title>

      {isFile && (
        <Text weight="bold" color="light">
          {intl.formatMessage({ id: 'translate.file.manager.file.size' }, { size: bytesToSize(getEntitySize(entity)) })}
        </Text>
      )}
    </div>
  );
};

export default injectIntl(EntityView);
