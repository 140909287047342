import { delay } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as NotificationActions from '../actions/notifications';
import * as Actions from '../constants/actions';
import { NOTIFICATION_DELAY } from '../constants/common';
import { isValidationErrorMatchingInput } from './form-submission';

export const extractNotificationData = (requestedAction): SpanelNotification => (
  requestedAction.notification || // nemo actions notification
  requestedAction.payload &&
  requestedAction.payload._meta &&
  requestedAction.payload._meta.notification
);

export function* handleNotificationCreation({ payload }) {
  const notification: SpanelNotification = payload;
  const { id, type, preventAutoClose } = notification;

  // Handle generic notification auto close.
  // Form notifications are handled differently -> check componentDidMount in containers/form-notification.tsx
  if (type === 'generic' && !preventAutoClose) {
    yield delay(NOTIFICATION_DELAY);

    yield put(
      NotificationActions.removeNotification({ id })
    );
  }
}

export function* handleRequestCompletion({ payload }) {
  const { requestedAction, response = {}, task } = payload;
  const { data, result } = response;

  const notificationData: SpanelNotification = {
    ...extractNotificationData(requestedAction),
    state: 'success'
  };

  if (task && notificationData && notificationData.type === 'generic') {
    return yield put(
      NotificationActions.createTaskNotification(task)
    );
  }

  // data for normal response and result for tasks
  const responseData = task ? result : data;

  if (notificationData && notificationData.success) {
    // Creation of generic/form notification after task or normal response
    yield put(NotificationActions.createNotification({
      ...notificationData,
      responseData
    }));
  }
}

export function* handleRequestFailure({ payload }) {
  const { requestedAction, exception, task } = payload;
  const formFieldValidationFailed = yield call(isValidationErrorMatchingInput, requestedAction, exception);

  // preventing notifications when form field BE validation is triggered
  if (formFieldValidationFailed) {
    return;
  }

  // for normal response
  const exceptionErrorMessage = exception && exception.message;

  const notificationData: SpanelNotification = {
    ...extractNotificationData(requestedAction),
    state: 'error'
  };

  if (task && notificationData && notificationData.type === 'generic') {
    return yield put(
      NotificationActions.createTaskNotification(task)
    );
  }

  if (notificationData && notificationData.error) {
    yield put(NotificationActions.createNotification({
      ...notificationData,
      responseError: {
        message: exceptionErrorMessage
      }
    }));
  }
}

export default function* notifications() {
  yield [
    takeEvery(Actions.HTTP_REQUEST_SUCCEEDED, handleRequestCompletion),
    takeEvery(Actions.HTTP_REQUEST_FAILED, handleRequestFailure),
    takeEvery(Actions.CREATE_NOTIFICATION, handleNotificationCreation)
  ];
}
