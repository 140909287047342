import { deepclone } from '../../core/common/deepclone';

const SLASH = '/';

export const generatePathTruncationParts = (name: string, delimiter = SLASH): string[] => {
  if (name.includes(delimiter)) {
    const namePieces = name.split(delimiter);
    let lastPiece = namePieces.pop();

    if (lastPiece === '') {
      lastPiece = namePieces.pop();
    }

    return [
      namePieces.join(delimiter),
      delimiter + lastPiece
    ];
  }

  return [name];
};

export const normalizeDirectoryData = (data = [], publicPath = '/public_html') => {
  const publicFolder = data.find((d) => d.entity && d.entity.n === publicPath);

  if (publicFolder) {
    const modifiedPublic = deepclone(publicFolder);
    modifiedPublic.entity.n = '//';

    return [modifiedPublic];
  }

  return data;
};

export const handleDefaultPathSelection = (path: string, pathToReplace: string) =>
  path.replace(pathToReplace, '') || '/';

export const formatPathInput = (path: string = '') => path.charAt(0) === '/' ? path : '/' + path;
