import * as React from 'react';
import { Grid, Section } from 'sg-styleguide';
import { openNewTabAction, OpenNewTabPayload } from '../../../core/actions/open-new-tab';
import { requestAutoconfigureData } from '../../../core/actions/pages/email-accounts';
import { requestData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { findMainDomain, filterStagingDomains } from '../../../core/selectors';
import { RootState } from '../../../core/reducers';
import { ChangePassword, ChangeQuota } from '../../components/common-forms';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import QuotaProgress from '../../components/quota-progress/quota-progress';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import Autoconfigure from './autoconfigure/autoconfigure';
import { CreateBox, CreateForm } from './create';

type Props = {
  actions: {
    fetchItem: FetchItem;
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
    changeQuotaItem: UpdateItem;
  };
  items: {
    email: any;
  };
  mainDomain: any;
  listedDomains: any[];
  session: {
    user: {
      email: string;
    }
  };
  intl: Intl;
  location: any;
  requestAutoconfigureData: Function;
  openSGDialog: Function;
  closeSGDialog: Function;
  openNewTab: (payload: OpenNewTabPayload) => any;
  requestData: RequestData;
};

enum WebMail {
  RoundCube = 'roundcube'
};

type EmailExtra = {
  webmail: WebMail;
};

const pickerEndpoints: any = [API_RESOURCE.DOMAIN, API_RESOURCE.DOMAIN_ALIAS];

type State = {
  currentChangePasswordPayload: any;
  currentChangeQuotaPayload: any;
  currentDeleteConformationDialogPayload: any;
  currentAutoconfigurePayload: any;
  selectedDomain: any;
};

class EmailPage extends React.Component<Props, State> {
  readonly state = {
    currentChangePasswordPayload: null,
    currentChangeQuotaPayload: null,
    currentDeleteConformationDialogPayload: null,
    currentAutoconfigurePayload: null,
    selectedDomain: null
  };

  /* event listeners */
  onDomainChanged = (domainId) => {
    const { listedDomains } = this.props;
    const domain = listedDomains.find(({ id }) => id === domainId);

    this.setState({
      selectedDomain: {
        name: domain.name,
        id: domain.id
      }
    });
  };

  onCreateFormSubmit = (formData) => {
    const { mainDomain } = this.props;
    const account = `${formData.name}@${mainDomain.name}`;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_EMAIL,
          success: {
            intlKey: 'translate.page.email.created_msg',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.page.email.failed_create_msg',
            intlValues: { account }
          }
        }
      }
    });
  };

  /* filters */
  arrangeOptionsData(data) {
    return data.sort((a, b) => {
      return (a.id - b.id);
    });
  }

  pickerOptions() {
    const { listedDomains } = this.props;
    return this.arrangeOptionsData(listedDomains);
  }

  filterTabledData() {
    const { items } = this.props;
    const { selectedDomain } = this.state;
    const options = items.email || [];
    const domainId = selectedDomain && selectedDomain.id;

    return options.filter((option) => option.domain_id === domainId);
  }

  updateDefaultWebmail = async (emailId, webmail: WebMail) =>
    await new Promise((resolve) =>
      this.props.actions.updateItem({
        id: emailId,
        extra: { webmail },
        _metaFields: {
          ...API_RESOURCE.EMAIL
        }
      }, resolve)
    );

  loginWebmail = async (emailId, webmail: WebMail) =>
    await new Promise((resolve) =>
      this.props.requestData({
        payload: {
          _metaFields: {
            endpoint: `${API_RESOURCE.EXTAPP.endpoint}/${webmail}`,
            method: 'GET',
            itemId: emailId
          },
          urlParams: {
            email_id: emailId
          }
        },
        requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA
      }, ({ app_login }) => [resolve(), this.props.openNewTab({ url: app_login })])
    );

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={this.props.intl.formatMessage({ id: 'translate.page.email.delete.dialog.title' }, { entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderChangePasswordComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangePasswordPayload } = this.state;
    const entityName = currentChangePasswordPayload && currentChangePasswordPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_PASSWORD}
        icon="lock"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.password' }, { account: entityName })}
        resources={[{
          resourceName: API_RESOURCE.EMAIL.resourceName,
          methods: ['PUT']
        }]}
      >
        <ChangePassword
          initialValues={currentChangePasswordPayload}
          onSubmit={(data) => actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.GENERIC_PASSWORD)
          )}
        />
      </SGDialogForm>
    );
  };

  renderChangeQuotaComponent = () => {
    const { actions, intl, closeSGDialog } = this.props;
    const { currentChangeQuotaPayload } = this.state;
    const entityName = currentChangeQuotaPayload && currentChangeQuotaPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_QUOTA}
        icon="settings"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.quota' }, { account: entityName })}
        resources={[{
          resourceName: API_RESOURCE.EMAIL.resourceName,
          methods: ['PUT']
        }]}
      >
        <ChangeQuota
          siteMetaApiKey="max_mail_quota_size"
          initialValues={currentChangeQuotaPayload}
          onSubmit={(data) => actions.changeQuotaItem(
            data,
            () => closeSGDialog(REDUX_FORM.GENERIC_QUOTA)
          )}
        />
      </SGDialogForm>
    );
  };

  renderAutoconfigureDialogComponent = () => {
    const { mainDomain, intl, session, requestAutoconfigureData, closeSGDialog } = this.props;
    const { currentAutoconfigurePayload } = this.state;
    const userEmail = (session && session.user) ? session.user.email : '';
    const selectedEmail = currentAutoconfigurePayload && currentAutoconfigurePayload.email;

    return (
      <Autoconfigure
        selectedDomain={mainDomain}
        userEmail={userEmail}
        onSubmit={(emailClientId: string) => requestAutoconfigureData(
          {
            emailId: this.state.currentAutoconfigurePayload.emailId,
            emailClientId,
            onDataLoaded: (url) => this.props.openNewTab({ url }),
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.email.pop.imap.settings.success.message'
                },
                error: {
                  intlKey: 'translate.page.email.pop.imap.settings.error.message'
                }
              }
            }
          }, () => closeSGDialog(DIALOGS.EMAIL_AUTOCONFIGURE)
        )}
      />
    );
  };

  render() {
    const { intl, listedDomains, mainDomain } = this.props;
    const { selectedDomain } = this.state;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.account.name' }),
        accessor: 'name',
        render: (email, entity) => {
          return `${email}@${entity.domain_name}`;
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.quota' }),
        accessor: 'quota_size',
        render: (quota, entity) => (
          <QuotaProgress quota={quota} max={entity.quota_size_max} usedQuota={entity.used_size} />
        )
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const pickerOptions = this.pickerOptions();

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: 'translate.page.email.title' })}
          icon="presentational-email-account"
          instructions={intl.formatMessage({ id: 'translate.page.email.info' })}
        />
        <Section>
          <Grid>
            <DomainSelect
              mainDomain={mainDomain}
              options={pickerOptions}
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              selectedValue={selectedDomain && selectedDomain.id}
              optionValue="id"
              optionLabel="name"
              onChange={this.onDomainChanged}
            />

            <CreateBox>
              <CreateForm
                domainName={selectedDomain && selectedDomain.name}
                onSubmit={(data) => this.onCreateFormSubmit({
                  ...data,
                  domain_id: selectedDomain.id
                })}
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.EMAIL.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.email.list.title' })}
                data={this.filterTabledData()}
                columns={columns}
                resources={[{ resourceName: 'email', methods: ['GET'] }]}
                rowResources={[{ requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA, methods: ['GET'] }]}
                noDataMessage="translate.page.email.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderChangePasswordComponent()}
        {this.renderChangeQuotaComponent()}
        {this.renderAutoconfigureDialogComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog, mainDomain } = this.props;
    const account = `${entity.name}@${mainDomain && mainDomain.name}`;

    const entityInfo = {
      domainName: mainDomain.name,
      maxQuotaSize: entity.quota_size_max,
      entityName: account
    };

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: account,
      ...entityInfo,
      _metaFields: { ...API_RESOURCE.EMAIL },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.email.deleted_msg',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.page.email.failed_delete_msg',
            intlValues: { account }
          }
        }
      }
    };

    const changePasswordPayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL,
        ...entityInfo
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.generic.password_changed',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.generic.password_failed',
            intlValues: { account }
          }
        }
      },
      id: entity.id
    };

    const changeQuotaPayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL,
        ...entityInfo
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.generic.quota_changed',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.generic.quota_failed',
            intlValues: { account }
          }
        }
      },
      quota_size: entity.quota_size,
      id: entity.id
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL.resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          icon: 'login',
          label: intl.formatMessage({ id: 'translate.page.email.context-menu-item.login-to-webmail.label' }),
          onClick: async () => {
            const extra: EmailExtra = entity.extra;

            if (extra && extra.webmail) {
              this.loginWebmail(entity.id, extra.webmail);
              return;
            }

            await this.updateDefaultWebmail(
              entity.id,
              WebMail.RoundCube
            );

            this.loginWebmail(entity.id, WebMail.RoundCube);
          }
        }, {
          vcsMethod: 'PUT',
          icon: 'lock',
          label: intl.formatMessage({ id: 'translate.generic.change.password' }),
          onClick: () => this.setState(
            { currentChangePasswordPayload: changePasswordPayload },
            () => openSGDialog(REDUX_FORM.GENERIC_PASSWORD)
          )
        }, {
          vcsMethod: 'PUT',
          icon: 'settings',
          label: intl.formatMessage({ id: 'translate.generic.change.quota' }),
          onClick: () => this.setState(
            { currentChangeQuotaPayload: changeQuotaPayload },
            () => openSGDialog(REDUX_FORM.GENERIC_QUOTA)
          )
        }, {
          vcsMethod: 'PUT',
          icon: 'mail-settings',
          label: intl.formatMessage({ id: 'translate.page.email.pop.imap.settings.title' }),
          onClick: () => this.setState({
            currentAutoconfigurePayload: {
              emailId: entity.id,
              email: account
            }
          }, () => {
            this.props.actions.fetchItem({
              itemId: mainDomain && mainDomain.name,
              ...API_RESOURCE.DOMAIN_ALL
            });
            openSGDialog(DIALOGS.EMAIL_AUTOCONFIGURE);
          })
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  mainDomain: findMainDomain(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  session: state.session,
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN_ALL.resourceName)
});

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTabAction(payload)),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  requestAutoconfigureData: (payload, onComplete) => dispatch(requestAutoconfigureData(payload, onComplete)),
  requestData: (payload, onComplete) => dispatch(requestData(payload, onComplete))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  EmailPage,
  API_RESOURCE.EMAIL,
  API_RESOURCE.EXTAPP,
  API_RESOURCE.DOMAIN_ALL
);
