import * as Actions from '../constants/actions';

export const createNotification = (notification: SpanelNotification) => ({
  type: Actions.CREATE_NOTIFICATION,
  payload: {
    id: +new Date() + Math.random(),
    ...notification
  }
});

export const removeNotification = ({ id, formName = null }) => ({
  type: Actions.REMOVE_NOTIFICATION,
  payload: { id, formName }
});

export const createTaskNotification = (task: Task) => ({
  type: Actions.CREATE_TASK_NOTIFICATION,
  payload: { ...task }
});

export const removeTaskNotification = (id: string) => ({
  type: Actions.REMOVE_TASK_NOTIFICATION,
  payload: { id }
});
