import * as React from 'react';
import { RootState } from '../../../core/reducers';
import { connect } from 'react-redux';
import { Button, Icon, Title } from 'sg-styleguide';
import GearIcon from './gear-icon';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import * as taskSelectors from '../../../core/selectors/pending-tasks';
import TaskNotifications from './task-notifications';
import './task-loader.scss';

type TaskLoaderProps = {
  router?: any;
  location?: {
    pathname: string;
    search: string;
  };
  pendingLocalTasks?: Task[];
  pendingTasks?: Task[];
  removeTask?: Function;
  intl?: Intl;
};

class TaskLoader extends React.Component<TaskLoaderProps, any> {
  renderLoader() {
    const { pendingTasks, pendingLocalTasks } = this.props;

    if (pendingTasks.length === 0 || pendingLocalTasks.length > 0) {
      return null;
    }

    return (
      <div key="loaderTasks" className="task-loader-wrapper" data-e2e="long-task-dialog">
        <div className="task-loader">
          <GearIcon />

          <Title level="3" weight="regular" color="darkest">
            {taskSelectors.getTaskTitle(pendingTasks[0])}
          </Title>
        </div>
      </div>
    );
  }

  renderTaskNotifications() {
    return <TaskNotifications pathname={this.props.location.pathname} />;
  }

  render() {
    return [
      this.renderLoader(),
      this.renderTaskNotifications()
    ];
  }
}

const mapStateToProps = ({ tasks }: RootState, ownProps: TaskLoaderProps) => ({
  pendingTasks: taskSelectors.getPendingTasks({ tasks }, ownProps.location.pathname),
  pendingLocalTasks: taskSelectors.getPendingLocalTasks({ tasks }, ownProps.location.pathname)
});

export default withRouter(
  connect<{}, {}, TaskLoaderProps>(
    mapStateToProps
  )
(injectIntl(TaskLoader)));
