import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Column,
  Container,
  Flex,
  Grid,
  Icon,
  ProductTable,
  Section,
  Spacer,
  Text,
  Tile,
  TileProps,
  Title,
  Toolbar
} from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import PageHeader from '../../../components/page-header';
import { SGDialogForm } from '../../../containers/sg-dialog';
import { withFormMetadata } from '../../../containers/sg-spanel-forms';
import { SecondLevelTitle } from '../../../containers/titles';
import VCS from '../../../containers/visibility-control-service';
import './landing-page.scss';
import { ConnectAccount } from './update';

interface CloudflareProps {
  actions?: any;
  nemoStoreRetrieve?: {
    domain?: string
    page?: string
  };
  cloudflareZonePending?: any[];
  environment?: any;
  pageNotification?: any;
  intl?: Intl;
  getFormValue: Function;
  openSGDialog?: Function;
  closeSGDialog?: Function;
}

const cloudflarePlanData = [
  { id: 1, feature: 'translate.page.cloudflare.landing.table.speed.ssl.text', basic: true, premium: true },
  { id: 2, feature: 'translate.page.cloudflare.landing.table.security.text', basic: true, premium: true },
  { id: 3, feature: 'translate.page.cloudflare.landing.table.cache.text', basic: true, premium: true },
  {
    id: 4,
    feature: 'translate.page.cloudflare.landing.table.statistics.text',
    basic: 'translate.page.cloudflare.landing.table.daily.text',
    premium: 'translate.page.cloudflare.landing.table.hourly.text'
  },
  { id: 5, feature: 'translate.page.cloudflare.landing.table.page.rules.text', basic: 3, premium: 30 },
  { id: 6, feature: 'translate.page.cloudflare.landing.table.wap.text', basic: false, premium: true },
  { id: 7, feature: 'translate.page.cloudflare.landing.table.advanced.features.text', basic: false, premium: true }
];

class CloudflarePage extends React.Component<CloudflareProps, any> {
  readonly state = {
    accountDialog: null
  };

  componentWillUnmount() {
    this.props.actions.fetchItems({
      ...API_RESOURCE.CLOUDFLARE_ZONE
    });
    this.props.actions.fetchItems({
      ...API_RESOURCE.CLOUDFLARE_ZONE_PENDING
    });
  }

  shouldShowProductsComparison() {
    const { nemoStoreRetrieve, cloudflareZonePending = [] } = this.props;

    // TODO page constant
    if (nemoStoreRetrieve && nemoStoreRetrieve.page === 'cloudflare') {
      return false;
    }

    if (cloudflareZonePending.length > 0) {
      return false;
    }

    return true;
  }

  renderPlanData = (cellData) => {
    const { intl } = this.props;

    if (typeof cellData === 'boolean') {
      const iconProps = {
        name: cellData ? 'check' : 'cross',
        color: cellData ? 'positive' : 'light',
        size: cellData ? '18' : '14'
      };

      return (
        <Icon {...iconProps} />
      );
    }
    return (
      <Text weight="regular" color="darkest">
        {typeof cellData === 'number' ? cellData : intl.formatMessage({ id: cellData })}
      </Text>
    );
  };

  renderHeaderInfo() {
    const { intl, environment } = this.props;

    return (
      <React.Fragment>
        <Flex
          wrap="nowrap"
          gutter="none"
          margin="none"
          align={environment.isPhone ? 'flex-start' : 'center'}
          direction={environment.isPhone ? 'column' : 'row'}
        >
          <Icon name="logo-cloudflare" size="140" multicolor style={{ marginRight: '20px' }} />
          <Title level="3" color="dark" weight="regular">
            {intl.formatMessage({ id: 'translate.page.cloudflare.landing.title' })}
          </Title>
        </Flex>

        <Text>{intl.formatMessage({ id: 'translate.page.cloudflare.landing.info' })}</Text>

        <Title level="3" color="dark" weight="regular">
          {intl.formatMessage({ id: 'translate.page.cloudflare.landing.subtitle' })}
        </Title>
      </React.Fragment>
    );
  }

  renderProductTable() {
    const { intl } = this.props;

    const columns = [{
      header: ' ',
      accessor: 'feature',
      render: this.renderPlanData
    }, {
      header: (
        <Title density="none" level="3" align="center">
          {intl.formatMessage({ id: 'translate.page.cloudflare.plan.free' })}
        </Title>
      ),
      accessor: 'basic',
      render: this.renderPlanData,
      style: {
        textAlign: 'center'
      }
    }, {
      header: (
        <Title density="none" level="3" align="center">
          {intl.formatMessage({ id: 'translate.page.cloudflare.plan.paid' })}
        </Title>
      ),
      accessor: 'premium',
      render: this.renderPlanData,
      style: {
        textAlign: 'center'
      }
    }];

    return (
      <ProductTable
        className="cloudflare-header-table"
        data={cloudflarePlanData}
        columns={columns}
        headerBackground="white"
      />
    );
  }

  renderCustomPageHeader = () => {
    const { environment } = this.props;

    if (environment.isPhone) {
      return null;
    }

    return (
      <React.Fragment>
        <Container padding="xx-large" elevation="none">
          <Grid sm="12" gap="xx-large">
            <Column smSpan="5">
              {this.renderHeaderInfo()}
            </Column>

            <Column smSpan="7">
              {this.renderProductTable()}
            </Column>
          </Grid>
        </Container>
      </React.Fragment>
    );
  };

  renderTileSection() {
    const { environment, intl, openSGDialog } = this.props;
    const shouldShowProductsComparison = this.shouldShowProductsComparison();
    const accountDialogPayload = {
      terms: false,
      actionType: 'CREATE',
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE
      },
      _meta: {
        notification: {
          type: 'generic',
          // type: 'form',
          // formName: REDUX_FORM.CREATE_CLOUDFLARE_ACCOUNT,
          success: {
            intlKey: 'translate.page.translate.cloudflare.create.account.success'
          },
          error: {
            intlKey: 'translate.page.translate.cloudflare.create.account.error'
          }
        }
      }
    };
    const tileProps: Partial<TileProps> = {
      className: 'cloudflare-tile',
      padding: environment.isPhone ? ['large', 'none'] : 'large',
      style: environment.isPhone ? {
        margin: '20px'
      } : undefined
    };

    const tiles = [{
      icon: 'presentational-cloudflare-link-account',
      titleId: 'translate.page.cloudflare.landing.tile.connect.title',
      textId: 'translate.page.cloudflare.landing.tile.connect.text'
    }, {
      icon: 'presentational-cloudflare-configure',
      titleId: 'translate.page.cloudflare.landing.tile.аctivate.title',
      textId: shouldShowProductsComparison ?
        'translate.page.cloudflare.landing.tile.аctivate.text' :
        'translate.page.cloudflare.landing.tile.аctivate.redirected.text'
    }, {
      icon: 'presentational-cloudflare-activate-domain',
      titleId: shouldShowProductsComparison ?
        'translate.page.cloudflare.landing.tile.configure.title' :
        'translate.page.cloudflare.landing.tile.configure.redirected.title',
      textId: shouldShowProductsComparison ?
        'translate.page.cloudflare.landing.tile.configure.text' :
        'translate.page.cloudflare.landing.tile.configure.redirected.text'
    }];

    return (
      <Container padding="none" elevation="1">
        <Grid sm="3" gap="none" padding={environment.isPhone ? 'none' : ['x-large', 'none']}>
          {tiles.map((tile) => (
            <Tile {...tileProps}>
              <Icon name={tile.icon} size="85" multicolor />
              <Title level="5" density="compact">
                {intl.formatMessage({ id: tile.titleId })}
              </Title>
              <Text color="light" weight="bold">
                {intl.formatMessage({ id: tile.textId })}
              </Text>
            </Tile>
          ))}
        </Grid>

        <Toolbar style={{ marginTop: 0 }}>
          <Spacer />

          <Button
            color="primary"
            onClick={() => this.setState({
              accountDialog: accountDialogPayload
            }, () => openSGDialog(REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT))}
          >
            {intl.formatMessage({ id: 'translate.generic.set.up' })}
          </Button>
        </Toolbar>
      </Container>
    );
  }

  render() {
    const { environment, intl } = this.props;
    const shouldShowProductsComparison = this.shouldShowProductsComparison();

    return (
      <div>
        {shouldShowProductsComparison && (
          <PageHeader
            title={intl.formatMessage({ id: 'translate.page.cloudflare.title' })}
            icon="presentational-cloudflare"
            render={this.renderCustomPageHeader}
          />
        )}

        <Section>

          {environment.isPhone && shouldShowProductsComparison && (
            <Grid>
              <Container>
                {this.renderHeaderInfo()}
              </Container>

              {this.renderProductTable()}
            </Grid>
          )}

          <VCS resourceName={API_RESOURCE.CLOUDFLARE.resourceNameMetaApi} hasMethod="GET">
            <SecondLevelTitle>
              {intl.formatMessage({ id: 'translate.page.cloudflare.landing.list.title' })}
            </SecondLevelTitle>

            {this.renderTileSection()}

            {this.renderAccountDialog()}
          </VCS>
        </Section>
      </div>
    );
  }

  renderAccountDialog = () => {
    const { intl, actions, getFormValue } = this.props;
    const { accountDialog } = this.state;
    const selectedAction = getFormValue(REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT, 'actionType');

    return (
      <SGDialogForm
        name={REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT}
        icon="product-cloudflare"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.cloudflare.landing.connect.account.dialog.title' })}
        submitLabel={intl.formatMessage(
          { id: selectedAction === 'CREATE' ? 'translate.generic.create' : 'translate.generic.connect' }
        )}
        resources={[{
          resourceName: API_RESOURCE.CLOUDFLARE.resourceName,
          methods: ['POST']
        }]}
      >
        <ConnectAccount
          initialValues={accountDialog}
          onSubmit={({ terms, actionType, ...data }) => actions.createItem(data)}
        />
      </SGDialogForm>
    );
  };
}

const mapStateToProps = (state) => ({
  nemoStoreRetrieve: state.nemoStore.retrieve,
  cloudflareZonePending: state.pageItems.cloudflareZonePending || [],
  environment: state.environment
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    withFormMetadata(CloudflarePage, [REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT])
  )
);
