import { handleActions } from 'redux-actions';
import * as Actions from '../../constants/actions';

const initialState: any = {
  // TODO constants after json refactor
  preferences: { spanel_dashboard_tiles: ['app-installer', 'email', 'filemanager', 'cloudflare'] }
};
const reducerMap: any = {
  [Actions.GET_DASHBOARD_PIN_ITEMS_SUCCEEDED]: (state, action: any) => ({
    ...state,
    preferences: {
      ...state.preferences,
      ...action.data.preferences
    }
  }),
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
