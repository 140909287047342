import * as React from 'react';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import DNSFields from '../dns-fields';
import { API_RESOURCE_MAP, DNSTypes } from '../constants';

type Props = {
  intl: Intl;
  formValues: any;
  validationUtils: ValidationUtils;
  selectedDNSType: DNSTypes;
  getFormValues: Function;
  reset: Function;
  form: string;
  domainName: string;
  sPanelFormSubmit: (formName) => void;
  change: (field, value) => void;
};

class Form extends React.Component <Props, {}> {
  render() {
    const { domainName, selectedDNSType, intl, sPanelFormSubmit, validationUtils, getFormValues, form } = this.props;

    return (
      <SGForm onSubmit={sPanelFormSubmit(form)}>
        <DNSFields
          domainName={domainName}
          dnsType={selectedDNSType}
          intl={intl}
          validationUtils={validationUtils}
          formName={form}
          getFormValues={getFormValues}
        />
        <Column smSpan="12">
          <SGSubmitButton formName={form} label={intl.formatMessage({ id: 'translate.generic.create' })} />
        </Column>
      </SGForm>
    );
  }
}

const DNS_TYPE_BY_FORM_NAME = {
  [REDUX_FORM.CREATE_DNS_A]: DNSTypes.DNS_A,
  [REDUX_FORM.CREATE_DNS_AAAA]: DNSTypes.DNS_AAAA,
  [REDUX_FORM.CREATE_DNS_CNAME]: DNSTypes.DNS_CNAME,
  [REDUX_FORM.CREATE_DNS_MX]: DNSTypes.DNS_MX,
  [REDUX_FORM.CREATE_DNS_SRV]: DNSTypes.DNS_SRV,
  [REDUX_FORM.CREATE_DNS_TXT]: DNSTypes.DNS_TXT
};

const createFormToExport = (formName: string) =>
  withSpanelForm(
    injectIntl(
      reduxForm({
        form: formName,
        initialValues: {
          ttl: 3600,
          _metaFields: {
            ...API_RESOURCE_MAP[DNS_TYPE_BY_FORM_NAME[formName]],
            parentResourceName: 'dns',
            formName
          }
        }
      })(Form)
    )
  );

export const CreateFormA = createFormToExport(REDUX_FORM.CREATE_DNS_A);
export const CreateFormAAAA = createFormToExport(REDUX_FORM.CREATE_DNS_AAAA);
export const CreateFormCNAME = createFormToExport(REDUX_FORM.CREATE_DNS_CNAME);
export const CreateFormMX = createFormToExport(REDUX_FORM.CREATE_DNS_MX);
export const CreateFormSRV = createFormToExport(REDUX_FORM.CREATE_DNS_SRV);
export const CreateFormTXT = createFormToExport(REDUX_FORM.CREATE_DNS_TXT);
