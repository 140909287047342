import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';

import CreateBox from '../../../../components/create-box';
import VCS from '../../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: any;
  renderCustomNotification: () => void;
  renderBeforeBoxChildren: () => void;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.SSH;
const formName = REDUX_FORM.CREATE_SSH;

const CreateContainer = ({ children, intl, renderCustomNotification, renderBeforeBoxChildren }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.ssh.create.title' })}
      renderBeforeBoxChildren={renderBeforeBoxChildren}
      renderCustomNotification={renderCustomNotification}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName: API_RESOURCE.SSH.resourceName,
        methods: ['POST']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
