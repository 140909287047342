import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { RETRIEVE_DATA_FROM_NEMO_SUCCEEDED, STORE_DATA_IN_NEMO_SUCCEEDED } from '../constants/nemo-store';

export type NemoStore = {
  store: any,
  retrieve: any
};

const initialState: NemoStore = {
  store: null,
  retrieve: null
};

export default handleActions({
  [STORE_DATA_IN_NEMO_SUCCEEDED]: (state: NemoStore, action: any) => ({
    ...state,
    store: action.data
  }),
  [RETRIEVE_DATA_FROM_NEMO_SUCCEEDED]: (state: NemoStore, action: any) => {
    return {
      ...state,
      retrieve: action.data
    };
  },
  [Actions.LOGOUT]: () => initialState
}, initialState);
