import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Section } from 'sg-styleguide';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import ROUTES from '../../../core/constants/routes';

type RedirectProps = {
  intl: any;
  router: any;
  location: any;
  children: any;
};

export const ProtectedUrlsPage = ({ intl, location, router, children }: RedirectProps) => (
  <React.Fragment>
    <PageHeader
      icon="presentational-password-protected-urls"
      title={intl.formatMessage({ id: 'translate.page.protected.title' })}
      instructions={intl.formatMessage({ id: 'translate.page.protected.instructions' })}
    />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          urls: intl.formatMessage({ id: 'translate.page.protected.urls' }),
          users: intl.formatMessage({ id: 'translate.page.protected.users' })
        }}
        routesByTabId={{
          urls: ROUTES.PROTECTED_URLS,
          users: ROUTES.PROTECTED_USERS
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>
      {children}
    </Section>
  </React.Fragment>
);

export default injectIntl(ProtectedUrlsPage);
