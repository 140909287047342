import * as Actions from '../constants/actions';
import { FileManagerRootState } from './utils/internal-state-definition';
import { CHANGE_CURRENT_DOMAIN_NAME } from '../../../../../core/constants/actions';
import { initialFileManagerState } from './utils/initial-state';

const ResetReducer = {
  [Actions.FILE_MANAGER_CLEAR_STORE_PROPERTIES]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [CHANGE_CURRENT_DOMAIN_NAME]: () => ({ ...initialFileManagerState })
};
export default ResetReducer;
