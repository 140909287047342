const bytesToSize = (bytes) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return '0 B';
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024)).toFixed(0);
  return Math.round(bytes / Math.pow(1024, parseInt(i, 10))) + ' ' + sizes[i];
};

export default bytesToSize;
