import * as React from 'react';
import { Button, Dialog, Link, Section, Switch, Table, Text } from 'sg-styleguide';
import { API_RESOURCE } from '../../../core/constants/api';
import { RootState } from '../../../core/reducers';
// COMPONENTS
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import VCS from '../../containers/visibility-control-service';

type FlagValue = 0 | 1 | number;

type Domain = {
  id: string;
  name: string;
  main: FlagValue;
  settings: {
    https_rewrite_links: FlagValue;
    https_enforce: FlagValue;
  },
  openSGDialog: Function;
  closeSGDialog: Function;
};

type DomainAlias = {
  name: string;
};

type Props = {
  items: {
    domain: Domain[];
    domainAlias: DomainAlias[];
  };
  actions: {
    updateItem: UpdateItem;
  };
  environment: {
    isPhone: boolean;
  };
  intl: Intl;
};

type State = {
  openedParkedDomainsList: boolean;
};

const DOMAIN_HTTPS_ENFORCE_SETTINGS = 'DOMAIN_HTTPS_ENFORCE_SETTINGS';

// 0 -> 1, 1 -> 0
const invertFlagValue = (value: FlagValue): FlagValue => Number(!Boolean(value));

class HttpsEnforce extends React.Component<Props, State> {
  readonly state = {
    openedParkedDomainsList: false
  };

  updateSettings = (
    { id, name },
    { https_enforce, https_rewrite_links },
    { successMessageKey, errorMessageKey }
  ) =>
    this.props.actions.updateItem({
      id,
      settings: { https_enforce, https_rewrite_links },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: successMessageKey,
            intlValues: { domain: name }
          },
          error: {
            intlKey: errorMessageKey,
            intlValues: { domain: name }
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.DOMAIN
      }
    });

  render() {
    const {
      items,
      intl,
      environment
    } = this.props;

    const { domain = [], domainAlias = [] } = items;
    const switchColumnsStyles = !environment.isPhone ? {
      width: '180px'
    } : null;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-https"
          title={intl.formatMessage({ id: 'translate.page.https-enforce.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.https-enforce.instructions' })}
        />
        <VCS resourceName={API_RESOURCE.DOMAIN.resourceNameMetaApi} hasMethods={['GET', 'PUT']}>
          <Section>
            <SGTable
              title={intl.formatMessage({ id: 'translate.page.https-enforce.table.title' })}
              data={domain}
              resources={[{
                resourceName: API_RESOURCE.DOMAIN.resourceName,
                methods: ['GET']
              }, {
                resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
                methods: ['GET']
              }]}
              rowResources={[{
                resourceName: API_RESOURCE.DOMAIN.resourceName,
                methods: ['PUT']
              }]}
              columns={[{
                header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.domain' }),
                accessor: 'name',
                render: (name: string, { main }: Domain) => (
                  <VCS
                    resourceName={API_RESOURCE.DOMAIN_ALIAS.resourceNameMetaApi}
                    hasMethod="GET"
                  >
                    <Text truncate>
                      {name}
                      &nbsp;
                      {main && domainAlias.length > 0 ? (
                        <Link onClick={() => this.setState({ openedParkedDomainsList: true })}>
                          {intl.formatMessage(
                            { id: 'translate.page.https-enforce.plus.more' },
                            { count: domainAlias.length })
                          }
                        </Link>
                      ) : null}
                    </Text>
                  </VCS>
                )
              }, {
                header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.https-enforce' }),
                accessor: 'settings',
                style: switchColumnsStyles,
                render: ({ https_enforce, https_rewrite_links }: Domain['settings'], { id, name }: Domain) => (
                  <Switch
                    checked={Boolean(https_enforce)}
                    data-e2e={`https-enforce-switch-https-${id}`}
                    onChange={() => this.updateSettings({
                      id,
                      name
                    }, {
                      https_enforce: invertFlagValue(https_enforce),
                      // https_rewrite_links cannot be 1 if https_enforce is 0 (1 && 0 -> 0)
                      https_rewrite_links: https_rewrite_links && invertFlagValue(https_enforce)
                    }, {
                      successMessageKey: Boolean(https_enforce) ?
                        'translate.page.https-enforce.table.https-enforce-disable.success' :
                        'translate.page.https-enforce.table.https-enforce-enable.success',
                      errorMessageKey: Boolean(https_enforce) ?
                        'translate.page.https-enforce.table.https-enforce-disable.fail' :
                        'translate.page.https-enforce.table.https-enforce-enable.fail'
                    })}
                  />
                )
              }, {
                header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.external-links-rewrite' }),
                accessor: 'settings',
                style: switchColumnsStyles,
                render: ({ https_enforce, https_rewrite_links }: Domain['settings'], { id, name }: Domain) => (
                  <Switch
                    disabled={!Boolean(https_enforce)}
                    checked={Boolean(https_rewrite_links)}
                    data-e2e={`https-enforce-switch-external-link-${id}`}
                    onChange={() => this.updateSettings({
                      id,
                      name
                    }, {
                      https_enforce,
                      https_rewrite_links: invertFlagValue(https_rewrite_links)
                    }, {
                      successMessageKey: Boolean(https_rewrite_links) ?
                        'translate.page.https-enforce.table.external-links-rewrite-disable.success' :
                        'translate.page.https-enforce.table.external-links-rewrite-enable.success',
                      errorMessageKey: Boolean(https_rewrite_links) ?
                        'translate.page.https-enforce.table.external-links-rewrite-disable.fail' :
                        'translate.page.https-enforce.table.external-links-rewrite-enable.fail'
                    })}
                  />
                )
              }, {
                header: '',
                acceessor: 'settings',
                style: {
                  width: 0
                }
              }]}
            />
          </Section>
        </VCS>
        {this.renderParkedDomainsList()}
      </React.Fragment>
    );
  }

  renderParkedDomainsList() {
    const { intl, items } = this.props;
    const { domainAlias = [] } = items;
    const { openedParkedDomainsList } = this.state;

    if (openedParkedDomainsList) {
      return (
        <Dialog
          icon="glob"
          state="warning"
          density="none"
          title={intl.formatMessage({ id: 'translate.page.domainAlias.parked_domains' })}
          onCloseHandler={() => this.setState({ openedParkedDomainsList: false })}
          footer={
            <Button onClick={() => this.setState({ openedParkedDomainsList: null })}>
              {intl.formatMessage({ id: 'translate.generic.ok' })}
            </Button>
          }
        >
          <Table
            mobileLayout="row"
            data={domainAlias}
            columns={[{
              accessor: 'name',
              render: (name) => <Text align="left">{name}</Text>
            }]}
          />
        </Dialog>
      );
    }
  }
}

export default indexWithCRUD(({ environment }: RootState) => ({
  environment
}))(
  HttpsEnforce,
  API_RESOURCE.DOMAIN,
  API_RESOURCE.DOMAIN_ALIAS
);
