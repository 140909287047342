import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { APP_TYPES } from '../../../../core/constants/application';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';
import customRequestTypes from '../../../../core/constants/custom-request-types';

type Props = {
  children: any;
  intl?: Intl;
  apiResource: {
    resourceName: string
    resourceNameMetaApi: string
  };
};

const formName = REDUX_FORM.CREATE_APP_INSTALL;

const CreateContainer = ({ children, intl, apiResource }: Props) => {
  const { resourceName, resourceNameMetaApi } = apiResource;

  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.app-manager.install-new.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[{
          resourceName: API_RESOURCE.DOMAIN.resourceName,
          methods: ['GET']
        }, {
          resourceName: API_RESOURCE.AUTOINSTALL.resourceName,
          methods: ['POST']
        }, {
          resourceName: API_RESOURCE.WORDPRESS.resourceName,
          methods: ['POST']
        }, {
          requestTypeName: customRequestTypes.REQUEST_LOAD_WEEBLY_EXTRAS
        }, {
          requestTypeName: customRequestTypes.REQUEST_INSTALL_WEEBLY
        }]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer);
