
export const redirectToLoginApp = ({
  sessionExpired = false
}) => {
  const getParams = [
    `?redirect=${encodeURIComponent(window.location.href)}`,
    sessionExpired ? '&reason=session-expired' : ''
  ].join('');

  window.location.href = `${CONFIG.LOGIN_URL}${getParams}`;
};
