import { browserHistory } from 'react-router';
import { delay } from 'redux-saga';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../api/site';
import * as Actions from '../constants/actions';
import { API_RESOURCE } from '../constants/api';

import {
  isTaskCompleted,
  isTaskSuccessful,
  isTaskUnfinished,
  isTaskMatchingPathname
} from '../selectors/pending-tasks';

import { getCurrentPathname } from '../selectors/routing';
import { siteRequestAuthorizationFailed } from '../actions/session';
import { triggerPageDataFetch } from '../actions/index-with-crud';

const DELAY_ON_HTTP200 = 5000;
const DELAY_ON_HTTP500 = 10000;
const seenTasks = {};

const TASKS_UPDATED_ACTIONS = [
  Actions.ADD_TASK,
  Actions.PENDING_TASK_COMPLETED,
  Actions.PENDING_TASK_MISSING
];

function* fetchPendingTasksSaga(action: any) {
  for (const taskId of action.payload) {
    yield put({ type: Actions.CHECKING_TASK_REQUESTED, taskId });
  }
}

function* checkingTaskSaga(action: any) {
  if (seenTasks[action.taskId]) {
    return;
  }
  while (true) {
    seenTasks[action.taskId] = 1;
    try {
      const lastState = yield select();
      const { data } = yield call(siteApi({
        endpoint: `/task/${action.taskId}`,
        method: 'GET',
        state: lastState
      }));
      const existingTask = lastState.tasks.find((task) => task.id === action.taskId);

      // isNewTask
      if (!existingTask) {
        yield put({ type: Actions.ADD_TASK, payload: data });
        // task completed
      } else if (existingTask && isTaskCompleted(data)) {
        yield put({ type: Actions.PENDING_TASK_COMPLETED, task: data });
      }

      if (isTaskUnfinished(data)) {
        yield delay(DELAY_ON_HTTP200);
      } else {
        return;
      }

    } catch (e) {
      if (e.status === 401) {
        yield put(siteRequestAuthorizationFailed(action));
        return;
      } else if (e.status >= 500 && e.status < 600) {
        yield delay(DELAY_ON_HTTP500);
      } else {
        yield put({ type: Actions.PENDING_TASK_MISSING, taskId: action.taskId });
        return;
      }
    }
  }
}

function* subscribeForTaskCompletion({ taskId, onComplete, onFailure }) {
  while (true) {
    yield take(TASKS_UPDATED_ACTIONS);

    const { tasks } = yield select();
    const taskData = tasks.find(({ id }) => id === taskId);

    if (taskData && isTaskCompleted(taskData)) {
      return isTaskSuccessful(taskData) ?
        onComplete(taskData.response, taskData) :
        onFailure(taskData.response.message, taskData);
    }

    if (!taskData) {
      console.error('Missing task data...', taskId);
    }
  }
}

function* handleNotifyTaskCompletion({ payload }) {
  const { task } = payload;
  const pathname = yield select(getCurrentPathname);

  if (task && isTaskMatchingPathname(task, pathname)) {
    // trigger fetch for page
    yield put(triggerPageDataFetch());
  }
}

function* tasks(): any {
  yield takeLatest(Actions.FETCH_PENDING_TASKS_REQUESTED, fetchPendingTasksSaga);
  yield takeEvery(Actions.CHECKING_TASK_REQUESTED, checkingTaskSaga);
  yield takeEvery(Actions.SUBSCRIBE_FOR_TASK_COMPLETION, subscribeForTaskCompletion);

  yield takeEvery(Actions.HTTP_REQUEST_SUCCEEDED, handleNotifyTaskCompletion);
}

export default tasks;
