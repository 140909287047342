import { takeEvery, select, put } from 'redux-saga/effects';
import * as DialogActions from '../constants/sg-dialog';
import { closeSGDialog } from '../actions/sg-dialog';

import {
  REQUEST_STARTED_BACKGROUND_TASK,
  CREATE_ITEM_REQUESTED,
  UPDATE_ITEM_REQUESTED,
  DELETE_ITEM_REQUESTED
} from '../constants/actions';

type Subscribers = {
  [dialogId: string]: Function[];
};

const callSubscribers = (subscribers: Function[] = [], ...args: any[]) => {
  for (const index in subscribers) {
    if (subscribers[index] && typeof(subscribers[index]) === 'function') {
      subscribers[index](...args);
    }
  }
};

export function* handleFormDialogCloseOnBackgroundTask({ payload }) {
  const submitActions = [
    CREATE_ITEM_REQUESTED,
    UPDATE_ITEM_REQUESTED,
    DELETE_ITEM_REQUESTED
  ];

  const { type } = payload.action;
  const { dialog } = yield select();

  if (submitActions.includes(type)) {
    for (const dialogId in dialog) {
      if (dialog[dialogId]) {
        yield put(closeSGDialog(dialogId));
      }
    }
  }
}

function* sgDialog() {
  const openDialogSubscribers: Subscribers = { };
  const closeDialogSubscribers: Subscribers = { };

  yield takeEvery(DialogActions.SUBSCRIBE_SG_DIALOG_OPENED, function* ({ id, onOpened }) {
    const subscribers = openDialogSubscribers[id] || [];
    openDialogSubscribers[id] = [...subscribers, onOpened];
  });

  yield takeEvery(DialogActions.SUBSCRIBE_SG_DIALOG_CLOSED, function* ({ id, onClosed }) {
    const subscribers = closeDialogSubscribers[id] || [];
    closeDialogSubscribers[id] = [...subscribers, onClosed];
  });

  yield takeEvery(DialogActions.UNSUBSCRIBE_SG_DIALOG_STATE, function* ({ id }) {
    openDialogSubscribers[id] = [];
    closeDialogSubscribers[id] = [];
  });

  yield takeEvery(DialogActions.OPEN_SG_DIALOG, function* ({ id, payload }) {
    callSubscribers(openDialogSubscribers[id], payload);
  });

  yield takeEvery(DialogActions.CLOSE_SG_DIALOG, function* ({ id }) {
    callSubscribers(closeDialogSubscribers[id]);
  });

  // close submit dialogs, when background task starts
  yield takeEvery(REQUEST_STARTED_BACKGROUND_TASK, handleFormDialogCloseOnBackgroundTask);
}

export default sgDialog;
