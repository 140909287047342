export const FIELDS = {
  APP_TYPE: 'app',
  DOMAIN_ID: 'domain_id',
  URL_PATH: 'path',
  ADMIN_USERNAME: 'username',
  ADMIN_PASSWORD: 'password',
  ADMIN_EMAIL: 'email',
  LANGUAGE: 'language',
  INSTALL_WP_STARTER: 'wp_starter',
  SAMPLE_DATA: 'extra_content',
  ADMIN_PATH: 'datadir',
  ADMIN_FOLDER: 'datadir',
  DATA_DIRECTORY: 'datadir',
  BOARD_EMAIL: 'contact_email',
  VISUAL_VERIFICATION: 'visual_verification',
  WIKI_EMAIL: 'contact_email',
  ENABLE_UPLOADS: 'enable_uploads',
  WEEBLY_PLAN: 'weebly_plan'
};

export const PLUGINS = {
  WP_STARTER: 'wp_starter'
};

export const VISUAL_VERIFICATIONS = [{
  id: 'none',
  label: 'None'
}, {
  id: 'very_simple',
  label: 'Very Simple - Plain text on image'
}, {
  id: 'simple',
  label: 'Simple - Overlapping colored letters, no noise'
}, {
  id: 'medium',
  label: 'Medium - Overlapping colored letters, with noise/lines'
}, {
  id: 'high',
  label: 'High - Angled letters, considerable noise/lines'
}, {
  id: 'extreme',
  label: 'Extreme - Angled letters, noise, lines and blocks'
}];

export const WEEBLY_PLANS = {
  FREE: 'free',
  STARTER: 'starter',
  PROFESSIONAL: 'professional',
  BUSSINESS: 'business'
};

export const WEEBLY_PLANS_OPTIONS = [{
  id: WEEBLY_PLANS.FREE,
  label: 'translate.page.weebly-plan.free.label'
}, {
  id: WEEBLY_PLANS.STARTER,
  label: 'translate.page.weebly-plan.starter.label'
}, {
  id: WEEBLY_PLANS.PROFESSIONAL,
  label: 'translate.page.weebly-plan.professional.label'
}, {
  id: WEEBLY_PLANS.BUSSINESS,
  label: 'translate.page.weebly-plan.bussiness.label'
}];

export const WEEBLY_CLASS_NUMBER_TO_PLAN = {
  1: WEEBLY_PLANS.FREE,
  2: WEEBLY_PLANS.STARTER,
  3: WEEBLY_PLANS.PROFESSIONAL,
  4: WEEBLY_PLANS.BUSSINESS
};
