import {
  REQUEST_SUPPORT_SEARCH,
  REQUEST_SUPPORT_SUGGESTIONS,
  REQUEST_ARTICLE,
  REQUEST_ARTICLES,
  SAVE_SUPPORT_STATE,
  CLEAR_SUPPORT_STATE
} from '../constants/actions';

import customRequestTypes from '../constants/custom-request-types';

export const requestArticles = (context): NemoRequestAction => ({
  type: REQUEST_ARTICLES,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { context }
});

export const requestArticle = (article) => ({
  type: REQUEST_ARTICLE,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { article }
});

export const requestSuggestions = (queryString): NemoRequestAction => ({
  type: REQUEST_SUPPORT_SUGGESTIONS,
  payload: { queryString }
});

export const requestSearch = (inquiry): NemoRequestAction => ({
  type: REQUEST_SUPPORT_SEARCH,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { inquiry }
});

export const saveSupportState = (popup) => ({
  type: SAVE_SUPPORT_STATE,
  payload: { popup }
});

export const clearSupportState = () => ({
  type: CLEAR_SUPPORT_STATE
});
