import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Loader, Title } from 'sg-styleguide';
import { RootState } from '../../../core/reducers';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';
import { LoaderContext } from '../../contexts';
import { LocalTaskLoaderType } from '../../../core/constants/common';
import * as taskSelectors from '../../../core/selectors/pending-tasks';
import { getCurrentPathname } from '../../../core/selectors/routing';

type Props = {
  intl?: Intl;
  closeDelay?: number;
  message?: string;
  resources: LoaderResource[];
  requested?: number;
  failed?: number;
  localTaskLoaderType?: LocalTaskLoaderType;
  pendingLocalTasks?: number;
  pendingTasks?: number;
  hideSpinner?: boolean;
  position?: 'absolute' | 'fixed' | 'sticky';
  showOnlyLoader?: boolean;
  preventShowingLoader?: boolean;
  initialLoadingState?: boolean;
};

type State = {
  isLoaderVisible: boolean;
};

class PartialLoader extends React.Component<Props, State> {
  readonly state = {
    isLoaderVisible: Boolean(this.props.initialLoadingState)
  };

  static defaultProps: Partial<Props> = {
    closeDelay: 0,
    showOnlyLoader: false,
    position: 'fixed'
  };

  timeout = null;

  componentWillUpdate(nextProps) {
    const { requested } = nextProps;
    const isLoading = this.props.requested > 0;
    const needLoading = requested > 0;

    if (!isLoading && needLoading) {
      this.setState({ isLoaderVisible: true });
    }

    if (isLoading && !needLoading) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }

      this.timeout = setTimeout(() => {
        // (another request might start in the meantime)
        this.setState({ isLoaderVisible: Boolean(this.props.requested > 0) });
      }, this.props.closeDelay);
    }
  }

  renderLoader() {
    const { message, hideSpinner, position, pendingLocalTasks, intl } = this.props;

    return (
      <Loader
        position={position}
        hideSpinner={hideSpinner}
      >
        {
          pendingLocalTasks > 0 ?
            intl.formatMessage({ id: 'translate.generic.task_running_longer_than_expected' }) :
            message
        }
      </Loader>
    );
  }

  renderLoaderContent = ({ parentLoaderVisible }) => {
    const { children, showOnlyLoader, pendingTasks, pendingLocalTasks, preventShowingLoader } = this.props;
    const { isLoaderVisible } = this.state;

    const globalTaskLoaderIsVisible = Boolean(pendingTasks > 0 && pendingLocalTasks === 0);
    const havingLoader = !globalTaskLoaderIsVisible && Boolean(isLoaderVisible || pendingLocalTasks > 0);

    const shouldRenderLoader = (!parentLoaderVisible && havingLoader);
    const shouldRenderChildren = !(showOnlyLoader && havingLoader);

    return (
      <LoaderContext.Provider value={{
        parentLoaderVisible: havingLoader || parentLoaderVisible
      }}>
        {shouldRenderLoader && !preventShowingLoader && this.renderLoader()}
        {shouldRenderChildren && children}
      </LoaderContext.Provider>
    );
  }

  render() {
    return (
      <LoaderContext.Consumer children={this.renderLoaderContent} />
    );
  }

  componentDidMount() {
    const { requested } = this.props;

    if (requested) {
      this.setState({ isLoaderVisible: true });
    }
  }
}

const mapStateToProps = (
  store: RootState,
  { resources, localTaskLoaderType }: Props
): Partial<Props> => {
  let localTasksPending = [];
  const pathname = getCurrentPathname(store);
  const pendingTasks = taskSelectors.getPendingTasks(store, pathname);

  if (localTaskLoaderType) {
    localTasksPending = taskSelectors.getPendingLocalTasks(store, pathname, localTaskLoaderType);
  }

  const { requested, failed } = getBlockingRequests(store, resources);

  return {
    requested: requested.length,
    failed: failed.length,
    pendingLocalTasks: localTasksPending.length,
    pendingTasks: pendingTasks.length
  };
};

export default connect<{}, {}, Props>(mapStateToProps)(injectIntl(PartialLoader));
