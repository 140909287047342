import { CLIENT_TOKEN_TTL_KEY } from '../constants/local-storage-keys';

export const clearTokenTTL = () => localStorage.removeItem('client-token-ttl');

export const setClientTokenTTLIntoLocalStorage = (ttl) => {
  if (ttl === undefined || ttl === null) {
    return;
  }

  const clientTokenExpireDate = new Date();
  clientTokenExpireDate.setSeconds(clientTokenExpireDate.getSeconds() + ttl);

  localStorage.setItem(CLIENT_TOKEN_TTL_KEY, clientTokenExpireDate.getTime().toString());
};
