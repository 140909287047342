import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import { Icon, Text } from 'sg-styleguide/';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_DND_TYPE, FILE_MANAGER_ICONS } from '../core/constants/common';
import { getEntityReadableName, isEntityProtected } from '../core/utils';

import './draggable-entity.scss';

const dragItemSource = {
  beginDrag(props) {
    return { entity: props.entity };
  },
  canDrag(props) {
    return !isEntityProtected(props.entity);
  }
};

const dropItemSource = {
  canDrop(props, monitor, component) {
    const dragItem = monitor.getItem().entity;
    const isDragSourceFile = dragItem[FILE_MANAGER_API_RESPONSE_DIR.TYPE] === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const isDropTargetFile = props.entity.t === FILE_MANAGER_API_RESPONSE_DIR.FILE;

    if (isDragSourceFile && isDropTargetFile) {
      return false;
    }

    if (!isDragSourceFile && isDropTargetFile) {
      return false;
    }

    return true;
  },

  drop(props, monitor, component) {
    const dragSourceEntity = monitor.getItem().entity;
    const dropTargetEntity = props.entity;

    // Don't replace items with themselves
    if (dragSourceEntity === dropTargetEntity) {
      return;
    }

    return props.handleDrop({ dragSourceEntity, dropTargetEntity });
  }
};

const collectDrag = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

const collectDrop = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

class DraggableEntity extends React.Component<any, any> {
  render() {
    const { isDragging, isOver, connectDragSource, connectDropTarget, canDrop, children } = this.props;

    return connectDragSource(connectDropTarget(
      <ul>
        <li className="sn-dragaggble">
          {this.renderLabel()}
          {children}
        </li>
      </ul>
    ));
  }

  renderLabel() {
    const {
      entity,
      isExpanded,
      isSelected,
      isHighlighted,
      padding = 0,
      onArrowClick,
      onLabelClick,
      onDoubleClick,
      isOver,
      canDrop
    } = this.props;
    const label = getEntityReadableName(entity);
    const isFolder = entity[FILE_MANAGER_API_RESPONSE_DIR.TYPE] === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY;

    const classes = [
      'sn-dragaggble-item',
      isSelected && 'sn-dragaggble-item--selected',
      isHighlighted && 'sn-dragaggble-item--highlighted',
      isOver && canDrop && 'sn-dragaggble-item--dropzone'
    ].filter(Boolean).join(' ');

    const isTextBold = isSelected || (isOver && canDrop);
    const paddingLeft = `${padding * 20 + 30}px`;

    return (
      <span className={classes} style={{ paddingLeft }}>
        {isFolder && (
          <Icon
            name={isExpanded ? 'arrow-down' : 'arrow-right'}
            size="10"
            className="sn-dragaggble-item__arrow-icon"
            onClick={onArrowClick}
            data-e2e="draggable-item-expand-arrow"
          />
        )}

        <span
          className="sn-dragaggble-item__label"
          onClick={onLabelClick}
          onDoubleClick={onDoubleClick}
          data-e2e="draggable-item"
        >
          <Icon
            name={FILE_MANAGER_ICONS[entity.t]}
            size="18"
            className={`sn-dragaggble-item__label-icon ${isFolder ? '' : 'sn-dragaggble-item__label-icon--file'}`}
          />
          <Text
            color={isSelected ? 'darkest' : 'dark'}
            weight={isTextBold ? 'bold' : 'medium'}
            className="sn-dragaggble-item__label-text"
          >
            {label}
          </Text>
        </span>
      </span>
    );
  }
}

let DragSideItem = DragSource(FILE_MANAGER_DND_TYPE.ENTITY, dragItemSource, collectDrag)(DraggableEntity);
DragSideItem = DropTarget(FILE_MANAGER_DND_TYPE.ENTITY, dropItemSource, collectDrop)(DragSideItem);

export default DragSideItem;
