import { call, put, select, takeLatest, take, race } from 'redux-saga/effects';
import { clientApi } from '../api/client';
import * as sessionActions from '../actions/session';
import * as Actions from '../constants/actions';
import { fetchSiteMetaApi } from '../actions/site-meta-api';

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchLogin(action) {
  // TODO: Revisit: Use load and initialize site handling from page-load

  // try {
  //   const state = yield select();

  //   const loginData = yield call(clientApi(`/auth/${CONFIG.NEMO_CLIENT_TOKEN_API}`, 'POST', action.payload));
  //   yield put(sessionActions.storeSessionData(loginData));

  //   const sites = loginData.data.sites;
  //   // yield put({ type: Actions.FETCH_SITES_SUCCEEDED, sites });

  //   if (!sites.length) {
  //     return loginData;
  //   }

  //   // const siteId = initializeSiteId(state, sites);

  //   // yield put({ type: Actions.FETCH_SITES_SUCCEEDED, sites, siteId });

  //   yield put(sessionActions.refreshSiteToken());

  //   const { siteTokenRefreshed, siteTokenRefreshFailed } = yield race({
  //     siteTokenRefreshed: take(Actions.REFRESH_SITE_TOKEN_SUCCEEDED),
  //     siteTokenRefreshFailed: take(Actions.REFRESH_CLIENT_TOKEN_FAILED)
  //   });

  //   if (siteTokenRefreshed) {
  //     yield put(fetchSiteMetaApi());
  //   }

  //   return loginData;
  // } catch (error) {
  //   yield put({ type: Actions.LOGIN_FAILED });
  //   throw error;
  // }
}

function* login(): any {
  yield takeLatest(Actions.LOGIN_REQUESTED, fetchLogin);
}

export default login;
