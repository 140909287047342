import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form'; // ES6
import Password from 'sg-styleguide/lib/components/password';
import { createNotification } from '../../core/actions/notifications';
import { PASSWORD_VALIDATION_ERROR_MAP } from '../../core/common/form-validations';

/* tslint:disable */
const getTranskations = (intl) => ({
  button: {
    copy: intl.formatMessage({ id: 'translate.form.password.copy' }),
    generate: intl.formatMessage({ id: 'translate.form.password.generate' })
  },
  labelTooltip: intl.formatMessage({ id: 'translate.form.password.tooltip.label' }),
  strengthMeter: {
    weak: intl.formatMessage({ id: 'translate.form.password.weak' }),
    fair: intl.formatMessage({ id: 'translate.form.password.fair' }),
    good: intl.formatMessage({ id: 'translate.form.password.good' }),
    strong: intl.formatMessage({ id: 'translate.form.password.strong' })
  },
  sentences: {
    'Add another word or two. Uncommon words are better.': intl.formatMessage({ id: 'translate.form.password.Add another word or two. Uncommon words are better.' }),
    'Use a few words avoid common phrases.': intl.formatMessage({ id: 'translate.form.password.Use a few words avoid common phrases.' }),
    'No need for symbols digits or uppercase letters.': intl.formatMessage({ id: 'translate.form.password.No need for symbols digits or uppercase letters.' }),
    'Straight rows of keys are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Straight rows of keys are easy to guess.' }),
    'Short keyboard patterns are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Short keyboard patterns are easy to guess.' }),
    'Use a longer keyboard pattern with more turns.': intl.formatMessage({ id: 'translate.form.password.Use a longer keyboard pattern with more turns.' }),
    'Repeats like "aaa" are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Repeats like "aaa" are easy to guess.' }),
    'Repeats like "abcabcabc" are only slightly harder to guess than "abc".': intl.formatMessage({ id: 'translate.form.password.Repeats like "abcabcabc" are only slightly harder to guess than "abc".' }),
    'Avoid repeated words and characters.': intl.formatMessage({ id: 'translate.form.password.Avoid repeated words and characters.' }),
    'Sequences like abc or 6543 are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Sequences like abc or 6543 are easy to guess.' }),
    'Avoid sequences.': intl.formatMessage({ id: 'translate.form.password.Avoid sequences.' }),
    'Recent years are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Recent years are easy to guess.' }),
    'Avoid recent years.': intl.formatMessage({ id: 'translate.form.password.Avoid recent years.' }),
    'Avoid years that are associated with you.': intl.formatMessage({ id: 'translate.form.password.Avoid years that are associated with you.' }),
    'Dates are often easy to guess.': intl.formatMessage({ id: 'translate.form.password.Dates are often easy to guess.' }),
    'Avoid dates and years that are associated with you.': intl.formatMessage({ id: 'translate.form.password.Avoid dates and years that are associated with you.' }),
    'This is a top-10 common password.': intl.formatMessage({ id: 'translate.form.password.This is a top-10 common password.' }),
    'This is a top-100 common password.': intl.formatMessage({ id: 'translate.form.password.This is a top-100 common password.' }),
    'This is a very common password.': intl.formatMessage({ id: 'translate.form.password.This is a very common password.' }),
    'This is similar to a commonly used password.': intl.formatMessage({ id: 'translate.form.password.This is similar to a commonly used password.' }),
    'A word by itself is easy to guess.': intl.formatMessage({ id: 'translate.form.password.A word by itself is easy to guess.' }),
    'Names and surnames by themselves are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Names and surnames by themselves are easy to guess.' }),
    'Common names and surnames are easy to guess.': intl.formatMessage({ id: 'translate.form.password.Common names and surnames are easy to guess.' }),
    'Capitalization doesn\'t help very much.': intl.formatMessage({ id: 'translate.form.password.Capitalization doesn\'t help very much.' }),
    'All-uppercase is almost as easy to guess as all-lowercase.': intl.formatMessage({ id: 'translate.form.password.All-uppercase is almost as easy to guess as all-lowercase.' }),
    'Reversed words aren\'t much harder to guess.': intl.formatMessage({ id: 'translate.form.password.Reversed words aren\'t much harder to guess.' }),
    'Predictable substitutions like \'@\' instead of \'a\' don\'t help very much.': intl.formatMessage({ id: 'translate.form.password.Predictable substitutions like \'@\' instead of \'a\' don\'t help very much.' })
  }
});
/* tslint:enable */

const FormPassword = ({ input, meta, intl, siteMetaApi, placeholder, createNotification, ...props }) => {
  let status;
  let error;

  const touchedOrActive = (meta.touched || meta.active);
  const touchedOrHasValue = (meta.touched || Boolean(input.value));

  if (touchedOrActive && meta.valid && meta.dirty) {
    status = 'success';
  }

  if (!meta.valid && touchedOrActive && touchedOrHasValue) {
    status = 'error';
    error = meta.error;
  }

  return (
    <Password
      state={status}
      validationMessage={error !== PASSWORD_VALIDATION_ERROR_MAP.invalid ? error : undefined}
      placeholder={intl.formatMessage({ id: 'translate.form.password.placeholder' })}
      data-e2e={`form-password-${input.name}`}
      translation={getTranskations(intl)}
      onCopyToClipboard={(isSuccessful) => {
        createNotification({
          type: 'generic',
          state: isSuccessful ? 'success' : 'error',
          success: {
            intlKey: 'translate.generic.copied.to.clipboard'
          },
          error: {
            intlKey: 'translate.generic.failed.copied.to.clipboard'
          }
        });
      }}
      {...input}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    siteMetaApi: state.siteMetaApi
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNotification: (payload) => dispatch(createNotification(payload))
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FormPassword));
