import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Column, Grid, Link, List, ListItem, Title } from 'sg-styleguide';
import { LocalTaskLoaderType } from '../../../core/constants/common';
import { findFormNotification } from '../../../core/selectors';
import { HideOnMobile } from '../../containers/device';
import { default as FormNotification, RenderCustomNotification } from '../../containers/form-notification';
import PartialLoader from '../../containers/partial-loader';
import { SecondLevelTitle } from '../../containers/titles';
import './create-box.scss';

type CreateBoxProps = {
  formName?: string;
  resourceName?: string;
  children?: any;
  environment?: any
  resources?: LoaderResource[];
  renderBeforeBoxChildren?: Function;
  formNotification?: SpanelNotification;
  renderCustomNotification?: (params: RenderCustomNotification) => any;

  // TODO: delete
  [otherProps: string]: any;
};

const generateStyles = (pageNotification): any =>
  pageNotification ? {
    position: 'absolute',
    visibility: 'hidden'
  } : null;

class CreateBox extends React.Component<CreateBoxProps, any> {
  renderFormNotification = () => {
    const { resourceName, formNotification, renderCustomNotification } = this.props;

    return (
      <FormNotification
        resourceName={resourceName}
        notification={formNotification}
        renderCustomNotification={renderCustomNotification}
      />
    );
  };

  renderChildren() {
    const { children, renderBeforeBoxChildren } = this.props;

    return (
      <React.Fragment>
        {renderBeforeBoxChildren}
        <div className="create-box__content">
          {children}
        </div>
      </React.Fragment>
    );
  }

  renderCreateBoxTutorial() {
    const renderInfo = ({ titleProps, listProps, listItemProps }) => (
      <React.Fragment>
        <Title {...titleProps}>Tutorials and Resources</Title>
        <List {...listProps} >
          <ListItem {...listItemProps}>
            <Link style={{ wordBreak: 'initial' }}>How to use this tool?</Link>
          </ListItem>
          <ListItem {...listItemProps}>
            <Link style={{ wordBreak: 'initial' }}>What are the benefits of using this?</Link>
          </ListItem>
          <ListItem {...listItemProps}>
            <Link style={{ wordBreak: 'initial' }}>Tips and tricks for better tool management</Link>
          </ListItem>
        </List>
      </React.Fragment>
    );

    return (
      <HideOnMobile>
        <Column smSpan="4" className="create-box__tutorial">
          {renderInfo({
            titleProps: {
              density: 'compact',
              level: '6',
              color: 'light'
            },
            listProps: {
              unordered: true,
              color: 'light'
            },
            listItemProps: {
              icon: 'dot'
            }
          })}
        </Column>
      </HideOnMobile>
    );
  }

  render() {
    const {
      intl,
      title,
      formNotification,
      resources = []
    } = this.props;

    return (
      <div>
        <SecondLevelTitle>
          {title}
        </SecondLevelTitle>
        <div className="create-box" data-e2e="create-box">
          <Grid sm="12" gap="none">
            <Column smSpan="8" style={{ position: 'relative' }}>
              <PartialLoader
                resources={resources}
                position="absolute"
                localTaskLoaderType={LocalTaskLoaderType.CREATE_BOX}
                preventShowingLoader={Boolean(formNotification)}
              >
                <div style={generateStyles(formNotification)}>
                  {this.renderChildren()}
                </div>
                {formNotification && this.renderFormNotification()}
              </PartialLoader>
            </Column>
            {this.renderCreateBoxTutorial()}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { formName }) => ({
  environment: state.environment,
  formNotification: findFormNotification(state, { formName })
});

export default connect<any, any, CreateBoxProps>(mapStateToProps)(
  injectIntl(CreateBox)
);
