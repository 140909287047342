import { TOGGLE_TRANSLATION_KEYS } from '../constants/i18n';
import { CHANGE_I18N } from '../constants/actions';

export const toggleTranslationKeys = () => ({ type: TOGGLE_TRANSLATION_KEYS });

export const changeI18N = ({ locale, messages }) => ({
  type: CHANGE_I18N,
  payload: {
    locale,
    messages
  }
});
