import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Notice, Text, Link } from 'sg-styleguide';
import { SecondLevelTitle } from '../../containers/titles';
import indexWithCRUD from '../../components/indexWithCRUD';
import { API_RESOURCE } from '../../../core/constants/api';

type Props = {
  items: {
    domainCache: any[];
  };
  intl: Intl;
};

class DynamicCachePage extends React.Component<Props, any> {
  render() {
    const { intl } = this.props;

    return (
      <div>
        <SecondLevelTitle>
          {intl.formatMessage({ id: 'translate.page.superCacher.dynamicCache' })}
        </SecondLevelTitle>
        <Notice
          type="instruction"
          background="white"
          title={intl.formatMessage({ id: 'translate.page.superCacher.dynamicCache.notice.title' })}
          shadow
        >
          <Text>
            {intl.formatMessage({ id: 'translate.page.superCacher.dynamic-cache.notice.info.text' })}
            &nbsp;
            <FormattedMessage
              id="translate.page.superCacher.dynamic-cache-notice.link.text"
              values={{
                link: (
                  <Link
                    href={intl.formatMessage({ id: 'translate.page.superCacher.sg-optimizer.link.url' })}
                    target="_blank"
                  >
                    {intl.formatMessage({ id: 'translate.page.superCacher.sg-optimizer.link.label' })}
                  </Link>
                )
              }}
            />
          </Text>
        </Notice>
      </div>
    );
  }
}

export default indexWithCRUD()(
  injectIntl(DynamicCachePage),
  API_RESOURCE.DOMAIN_CACHE
);
