import * as React from 'react';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { injectIntl } from 'react-intl';

import { Icon, Title } from 'sg-styleguide/';
import arrayFlatten from '../core/utils/arrayFlatten';

import dirTraverse from '../core/utils/dirTraverse';

import './dropzone.scss';

const dropItemSource = {
  drop(props, monitor) {
    if (!monitor.getItem().items) {
      return props.onUnsupportedBroserDrop();
    }

    const droppedFiles = monitor.getItem().files || [];
    const droppedFilesPromises = [];
    const areOnlyFilesUploaded = droppedFiles.every((file) => file.type !== '');

    if (areOnlyFilesUploaded) {
      return props.handleDrop(droppedFiles);
    }

    for (const item of monitor.getItem().items) {
      const droppedContent = item.getAsEntry ? item.getAsEntry() : item.webkitGetAsEntry();

      if (droppedContent.isFile) {
        const modifiedFilePromises = new Promise((resolve) => {
          droppedContent.file(resolve);
        });

        droppedFilesPromises.push(modifiedFilePromises);
      }

      if (droppedContent.isDirectory) {
        droppedFilesPromises.push(
          dirTraverse(droppedContent)
        );
      }
    }

    Promise.all(droppedFilesPromises).then((traversedFiles) => {
      const flatten = arrayFlatten(traversedFiles);

      props.handleDrop(flatten);
    });
  }
};

const connectFileDropZone = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

class FileDropZone extends React.Component <any, any> {
  render() {
    const { children, connectDropTarget, isOver, canDrop, intl } = this.props;

    return connectDropTarget(
      <div className="dropzone">
        {children}

        {canDrop && (
          <div className="dropzone__overlay">
            <Icon name="file-drag" size="100" />
            <Title>
              {!isOver && intl.formatMessage({ id: 'translate.file.manager.drop.file' })}
              {isOver && intl.formatMessage({ id: 'translate.file.manager.drop.file' })}
            </Title>
          </div>
        )}
      </div>
    );
  }
}

export default DropTarget(NativeTypes.FILE, dropItemSource, connectFileDropZone)(injectIntl(FileDropZone));
