import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, PlanCard, SharedPlanPrice, Text } from 'sg-styleguide';
import { HostingTypes, planExipiresLessThan30Days } from 'sg-styleguide/lib/utils';
import { DIALOGS } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import ExcludingTax from '../../../components/excluding-tax';
import FormattedCurrency from '../../../components/formatted-currency';
import { SGDialog, SGDialogCancel } from '../../../containers/sg-dialog';
import { PlanData, UpgradeResponseAccountPlans } from '../types';

type Props = {
  intl: Intl;
  upgradeData: UpgradeResponseAccountPlans;
  onPlanConfirmed: ({ planType }) => void;
};

type State = {
  selectedPlan: HostingTypes;
};

const defaultPlanData: PlanData = {
  description: null,
  features: [],
  subtitle: null,
  price: {}
};

const defaultUpgradeData: UpgradeResponseAccountPlans = {
  account_expiration: null,
  current_plan: null,
  months_left: null,
  current_plan_billing_cycle: null,
  plans: {
    shared: {
      shared_geek: defaultPlanData,
      shared_plus: defaultPlanData
    }
  }
};

const getPlanPrice = ({ price = {} }: PlanData): PriceData => price[Object.keys(price).pop()];

export class CacheUpgradeDialog extends React.Component<Props, State> {
  readonly state = {
    selectedPlan: null
  };

  toggleSelectedPlan = (planType: HostingTypes) => {
    const selectedPlan = this.state.selectedPlan === planType ? null : planType;
    this.setState({ selectedPlan });
  };

  render() {
    const { intl, upgradeData, onPlanConfirmed } = this.props;
    const upgradePlansData = upgradeData || defaultUpgradeData;

    const plans = upgradePlansData && upgradePlansData.plans;
    const sharedPlus = plans && plans.shared && { ...plans.shared.shared_plus };
    const sharedGeek = plans && plans.shared && { ...plans.shared.shared_geek };

    const planExpiresInLessThan30Days = planExipiresLessThan30Days(upgradePlansData);
    const priceKey = planExpiresInLessThan30Days ? 'regular_price' : 'price';

    const sharedPlusPrice = getPlanPrice(sharedPlus);
    const sharedGeekPrice = getPlanPrice(sharedGeek);

    const planCardProps = {
      isPlanAvailable: true,
      isPlanNotAvailable: false,
      isPlanCurrent: false,
      expiresInLessThan30Days: planExpiresInLessThan30Days,
      excludingTax: <ExcludingTax />,
      translations: {
        regularPriceLabel: intl.formatMessage({ id: 'translate.generic.regular.price.label' }),
        notAvailable: intl.formatMessage({ id: 'translate.generic.not.available.label' })
      }
    };

    if (sharedPlus && sharedPlus.features) {
      sharedPlus.features = sharedPlus.features.concat([
        { text: intl.formatMessage({ id: 'translate.page.superCacher.upgrade-plan-dialog.levels.of.superCacher' }) }
      ]);
    }

    if (sharedGeek && sharedGeek.features) {
      sharedGeek.features = sharedGeek.features.concat([
        { text: intl.formatMessage({ id: 'translate.page.superCacher.upgrade-plan-dialog.levels.of.superCacher' }) }
      ]);
    }

    const hasResponseData = Boolean(sharedPlusPrice || sharedGeekPrice);

    return (
      <SGDialog
        id={DIALOGS.CACHE_UPGRADE}
        icon="warning"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.superCacher.upgrade-plan-dialog.title' })}
        size="x-large"
        onCloseHandler={() => this.setState({ selectedPlan: null })}
        resources={[{ requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS }]}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.CACHE_UPGRADE} label={intl.formatMessage({ id: 'translate.generic.cancel' })} />
            <Button
              data-e2e="cache-upgrade-dialog-submit"
              color="primary"
              disabled={this.state.selectedPlan === null}
              onClick={() => onPlanConfirmed({ planType: this.state.selectedPlan })}
            >
              {intl.formatMessage({ id: 'translate.generic.continue' })}
            </Button>
          </React.Fragment>
        }
      >
        {
          hasResponseData && (
            <React.Fragment>
              <Text weight="bold">
                {intl.formatMessage({ id: 'translate.page.superCacher.upgrade-plan-dialog.text' })}
              </Text>
              <br />
              <Grid sm="2" gap="x-large">
                {
                  sharedPlusPrice && (
                    <PlanCard
                      key="grow-big-plan-card"
                      icon="star"
                      selected={this.state.selectedPlan === HostingTypes.GROW_BIG}
                      buttonText={intl.formatMessage({ id: 'translate.generic.select.plan.label' })}
                      handleSelect={() => this.toggleSelectedPlan(HostingTypes.GROW_BIG)}
                      {...sharedPlus}
                    >
                      <SharedPlanPrice
                        {...planCardProps}
                        formattedPrice={
                          <FormattedCurrency price={sharedPlusPrice && sharedPlusPrice[priceKey]} period="1" />
                        }
                        formattedRegularPrice={
                          <FormattedCurrency price={sharedPlusPrice && sharedPlusPrice.regular_price} period="1" />
                        }
                      />
                    </PlanCard>
                  )
                }
                {
                  sharedGeekPrice && (
                    <PlanCard
                      key="go-geek-plan-card"
                      icon="product-crown"
                      selected={this.state.selectedPlan === HostingTypes.GO_GEEK}
                      buttonText={intl.formatMessage({ id: 'translate.generic.select.plan.label' })}
                      handleSelect={() => this.toggleSelectedPlan(HostingTypes.GO_GEEK)}
                      {...sharedGeek}
                    >
                      <SharedPlanPrice
                        {...planCardProps}
                        formattedPrice={
                          <FormattedCurrency price={sharedGeekPrice && sharedGeekPrice[priceKey]} period="1" />
                        }
                        formattedRegularPrice={
                          <FormattedCurrency price={sharedGeekPrice && sharedGeekPrice.regular_price} period="1" />
                        }
                      />
                    </PlanCard>
                  )
                }
              </Grid>
            </React.Fragment>
          )
        }
      </SGDialog>
    );
  }
}

export default injectIntl(CacheUpgradeDialog);
