import * as React from 'react';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { getRoutes } from '../../../routes';

interface RootPros extends React.Props<any> {
  history: any;
  store: any;
}

class Root extends React.Component<RootPros, any> {
  public render() {
    return (
      <Provider store={this.props.store}>
        <Router history={this.props.history} key={Math.random()}>
          {getRoutes(this.props.store)}
        </Router>
      </Provider>
    );
  }
}

export default Root;
