import * as React from 'react';
import { Button, Flex, Text, Label } from 'sg-styleguide';
import { SettingApiType } from '../types';

type Props = {
  value: string;
  data: {
    id: string;
    can_set?: number;
    user_value: string;
  },
  type: SettingApiType;
  onAddValue: Function;
  customText: string;
  addValueText: string;
};

const booleanSettings = ['No', 'Yes'];

const getValue = (type: string, value, userValue) => {
  const setValue = userValue !== undefined ? userValue : value;
  return (type === 'bool' && booleanSettings[setValue]) || setValue;
};

export const PhpIniValue = ({
  value,
  data,
  type,
  onAddValue,
  customText,
  addValueText
}: Props) => {
  const { can_set, user_value, id } = data;

  const hasValue = Boolean(
    getValue(type, value, user_value) &&
    getValue(type, value, user_value).length > 0
  );

  const isCustom = (user_value !== undefined);

  if (hasValue) {
    return (
      <Flex align="center" margin="none" gutter="none">
        <Text color="dark" style={{ marginRight: '5px' }}>
          {getValue(type, value, user_value)}
        </Text>
        {
          isCustom && (
            <Label type="instruction-link" size="medium">
              {customText}
            </Label>
          )
        }
      </Flex>
    );
  }

  return can_set ? (
    <Button color="secondary" type="link" onClick={() => onAddValue()}>
      {addValueText}
    </Button>
  ) : null;
};

export default PhpIniValue;
