import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ContextMenuItem, ContextMenuList } from 'sg-styleguide';
import { getFileMangerContextPermission, RootState } from '../../../../core/reducers';

import * as fileManagerActions from '../core/actions/file-manager';

import { FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';

class FilemanagerContextMenu extends React.Component<any, any> {
  getContextMenuProps() {
    const { clientX, clientY, onClose } = this.props;

    return {
      onClose,
      position: 'fixed',
      rect: {
        top: `${clientY}px`,
        left: `${clientX}px`,
        bottom: `${window.innerHeight - clientY}px`,
        right: `${window.innerWidth - clientX}px`
      }
    };
  }

  renderShowFolderSizeItem = () => {
    const { environment, intl } = this.props;
    const { canShowFolderSize } = this.props.fileMangerContextPermission;

    if (!environment.isDesktop || !canShowFolderSize) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="folder-size"
        data-e2e="context-show-folder-size"
        onClick={this.props.menuAction.fetchDirWithFolderSize}
      >
        {intl.formatMessage({ id: 'translate.generic.show.folder.size' })}
      </ContextMenuItem>
    );
  };

  clearContextEntities = () => {
    if (this.props.environment.isDesktop) {
      this.props.actions.clearContextEntities();
    }
  };

  onContextMenuClick =
    (callback) =>
      (...args) => [
        this.clearContextEntities(),
        callback(...args)
      ];

  render() {
    const { clientX, clientY, type } = this.props;

    if (!clientX || !clientY) {
      return null;
    }

    switch (type) {
      case FILE_MANAGER_CONTEXT_MENU_TYPE.BASE:
        return this.renderBaseContextMenu();
      case FILE_MANAGER_CONTEXT_MENU_TYPE.CODE_EDITOR:
        return this.renderCodeEditorContextMenu();
      default:
        return this.renderSelectedEntityContextMenu();
    }
  }

  renderBaseContextMenu() {
    const { intl } = this.props;
    const {
      canCreateDir,
      canCreateFile,
      canUploadFile,
      canUploadDir,
      canPaste
    } = this.props.fileMangerContextPermission;

    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {canCreateFile && (
          <ContextMenuItem
            icon="file-new"
            data-e2e="context-menu-new-file"
            onClick={this.onContextMenuClick(this.props.menuAction.createFile)}
          >
            {intl.formatMessage({ id: 'translate.generic.new.file' })}
          </ContextMenuItem>
        )}

        {canCreateDir && (
          <ContextMenuItem
            icon="folder-new"
            data-e2e="context-menu-new-folder"
            onClick={this.onContextMenuClick(this.props.menuAction.createDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.new.folder' })}
          </ContextMenuItem>
        )}

        {canUploadFile && (
          <ContextMenuItem
            icon="file-upload"
            data-e2e="context-menu-upload-file"
            onClick={this.onContextMenuClick(this.props.menuAction.openFileUpload)}
          >
            {intl.formatMessage({ id: 'translate.generic.file.upload' })}
          </ContextMenuItem>
        )}

        {canUploadDir && (
          <ContextMenuItem
            icon="folder-upload"
            data-e2e="context-menu-upload-file"
            onClick={this.onContextMenuClick(this.props.menuAction.openFolderUpload)}
          >
            {intl.formatMessage({ id: 'translate.generic.folder.upload' })}
          </ContextMenuItem>
        )}

        {canPaste && (
          <ContextMenuItem
            icon="paste"
            data-e2e="context-menu-paste"
            onClick={this.onContextMenuClick(this.props.menuAction.pasteDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.paste' })}
          </ContextMenuItem>
        )}

        {this.renderShowFolderSizeItem()}
      </ContextMenuList>
    );
  }

  renderCodeEditorContextMenu() {
    const { intl, codeEditor, codeEditorFiles } = this.props;
    const { canCreateFile } = this.props.fileMangerContextPermission;

    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {canCreateFile && (
          <ContextMenuItem
            icon="file-new"
            data-e2e="context-menu-new-file"
            onClick={this.onContextMenuClick(this.props.menuAction.createFile)}
          >
            {intl.formatMessage({ id: 'translate.generic.new.file' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="save"
            data-e2e="context-menu-save"
            onClick={this.onContextMenuClick(this.props.menuAction.saveFile)}
          >
            {intl.formatMessage({ id: 'translate.generic.save' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="tabs-close"
            data-e2e="context-menu-close-all-tabs"
            onClick={this.onContextMenuClick(codeEditor.closeTabsWithConfirmation)}
          >
            {intl.formatMessage({ id: 'translate.file.manager.close.all.tabs' })}
          </ContextMenuItem>)}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="search"
            data-e2e="context-menu-search"
            onClick={this.onContextMenuClick(codeEditor.openFindPanel)}
          >
            {intl.formatMessage({ id: 'translate.generic.search' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="replace"
            data-e2e="context-menu-replace"
            onClick={this.onContextMenuClick(codeEditor.openFindReplacePanel)}
          >
            {intl.formatMessage({ id: 'translate.generic.replace' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="download"
            data-e2e="context-menu-download"
            onClick={this.onContextMenuClick(codeEditor.downloadActiveTabContent)}
          >
            {intl.formatMessage({ id: 'translate.generic.download' })}
          </ContextMenuItem>
        )}
      </ContextMenuList>
    );
  }

  renderSelectedEntityContextMenu() {
    const { environment, intl } = this.props;
    const {
      canEdit,
      canDownload,
      canCopy,
      canPaste,
      canMove,
      canRename,
      canDelete,
      canExtract,
      canArchive,
      canChangePermissions,
      isProtected
    } = this.props.fileMangerContextPermission;

    const isVisible = environment.isDesktop ? true : !isProtected;

    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {canEdit && (
          <ContextMenuItem
            icon="edit"
            data-e2e="context-menu-edit"
            onClick={this.onContextMenuClick(() => this.props.codeEditor.openFile())}
          >
            {intl.formatMessage({ id: 'translate.generic.edit' })}
          </ContextMenuItem>
        )}
        {canDownload && (
          <ContextMenuItem
            icon="download"
            data-e2e="context-menu-download"
            onClick={this.onContextMenuClick(this.props.menuAction.downloadFile)}
          >
            {intl.formatMessage({ id: 'translate.generic.download' })}
          </ContextMenuItem>
        )}
        {canRename && isVisible && (
          <ContextMenuItem
            icon="rename"
            data-e2e="context-menu-rename"
            disabled={isProtected}
            onClick={this.onContextMenuClick(this.props.menuAction.renameDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.rename' })}
          </ContextMenuItem>
        )}
        {canCopy && (
          <ContextMenuItem
            icon="copy"
            data-e2e="context-menu-copy"
            onClick={this.onContextMenuClick(this.props.menuAction.markForCopy)}
          >
            {intl.formatMessage({ id: 'translate.file.manager.copy' })}
          </ContextMenuItem>
        )}
        {canPaste && (
          <ContextMenuItem
            icon="paste"
            data-e2e="context-menu-paste"
            onClick={this.onContextMenuClick(this.props.menuAction.pasteDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.paste' })}
          </ContextMenuItem>
        )}
        {canMove && isVisible && (
          <ContextMenuItem
            icon="move"
            data-e2e="context-menu-move"
            disabled={isProtected}
            onClick={this.onContextMenuClick(this.props.menuAction.moveDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.move' })}
          </ContextMenuItem>
        )}
        {canDelete && isVisible && (
          <ContextMenuItem
            icon="trash"
            data-e2e="context-menu-delete"
            disabled={isProtected}
            onClick={this.onContextMenuClick(this.props.menuAction.deleteDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.delete' })}
          </ContextMenuItem>
        )}
        {canExtract && (
          <ContextMenuItem
            icon="extract"
            data-e2e="context-menu-extract"
            onClick={this.onContextMenuClick(this.props.menuAction.extractDir)}
          >
            {intl.formatMessage({ id: 'translate.generic.extract' })}
          </ContextMenuItem>
        )}
        {canArchive && (
          <ContextMenuItem
            icon="archive"
            data-e2e="context-menu-archive"
            onClick={this.props.menuAction.archiveDir}
          >
            {intl.formatMessage({ id: 'translate.generic.archive' })}
          </ContextMenuItem>
        )}
        {canChangePermissions && isVisible && (
          <ContextMenuItem
            icon="key"
            data-e2e="context-menu-permissions"
            disabled={isProtected}
            onClick={this.onContextMenuClick(this.props.menuAction.changeEntityPermissions)}
          >
            {intl.formatMessage({ id: 'translate.generic.change.permissions' })}
          </ContextMenuItem>
        )}

        {this.renderShowFolderSizeItem()}
      </ContextMenuList>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  codeEditorFiles: state.fileManager.codeEditor.files,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  fileMangerContextPermission: getFileMangerContextPermission(state)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FilemanagerContextMenu));
