import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link, List, ListItem, Title } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: any;
};

const { resourceName } = API_RESOURCE.DOMAIN_ALIAS;
const formName = REDUX_FORM.CREATE_DOMAIN_ALIAS;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceName} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.domainAlias.create.title' })}
      resourceName={resourceName}
      formName={formName}
      renderInfo={({ titleProps, listProps, listItemProps }) => (
        <React.Fragment>
          <Title {...titleProps}>{intl.formatMessage({ id: 'translate.create.box.default.info.box.title' })}</Title>
          <List {...listProps} >
            <ListItem {...listItemProps}>
              <Link
                href="https://www.siteground.com/tutorials/cpanel/parked-domains/#Add_a_Parked_Domain"
                target="_blank"
              >
                {intl.formatMessage({ id: 'translate.page.domainAlias.create.link.add.parked.domain' })}
              </Link>
            </ListItem>
            <ListItem {...listItemProps}>
              <Link
                href="https://www.siteground.com/tutorials/cpanel/parked-domains/#Remove_a_Parked_Domain"
                target="_blank"
              >
                {intl.formatMessage({ id: 'translate.page.domainAlias.create.link.remove.parked.domain' })}
              </Link>
            </ListItem>
            <ListItem {...listItemProps}>
              <Link
                href="https://www.siteground.com/tutorials/getting-started/initial-domain-setup/"
                target="_blank"
              >
                {intl.formatMessage({ id: 'translate.page.domainAlias.create.link.change.dns' })}
              </Link>
            </ListItem>
          </List>
        </React.Fragment>
      )}
      resources={[{
        resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
        methods: ['POST']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
