import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Column } from 'sg-styleguide';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms';
import FormInput from '../../../../components/form-input';

type Props = {
  validationUtils: ValidationUtils;
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_CRON_NOTIFICATION;

export const Form = ({
  validationUtils,
  intl
}: Props) => (
  <Grid>
    <Column smSpan="12">
      <Field
        name="email"
        type="text"
        label={intl.formatMessage({ id: 'translate.page.cron.notification.email' })}
        placeholder={intl.formatMessage({ id: 'translate.page.cron.notification.email' })}
        component={FormInput}
        validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
      />
    </Column>
  </Grid>
);

const UpdateCronNotification = reduxForm({ form: formName })(Form);

export default withSpanelForm(
  injectIntl(UpdateCronNotification)
);
