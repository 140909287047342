import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

type Props = {
  toPage: string;
  router?: {
    push: Function;
  };
  location?: { search: string };
  onClick?: Function;
  [other: string]: any
};

export const withRouteTo = (Wrapped) => {
  class WithRouteToWrapper extends React.Component <Props, any> {
    render() {
      const { router, location, onClick, toPage } = this.props;
      return (
        <Wrapped
          {...this.props}
          onClick={(event) => {
            router.push(`${toPage}${location.search}`);

            if (onClick) {
              onClick(event);
            }
          }}
        />
      );
    }
  };

  const mapStateToProps = (state) => ({
    location: state.location
  });

  return connect<{}, {}, Props>(mapStateToProps)(withRouter(WithRouteToWrapper));
};

export default withRouteTo;
