import * as React from 'react';
import { connect } from 'react-redux';
import { Button, ContextMenu, ContextMenuItem, Flex, getDate, getTime, Grid, IconButton, Text } from 'sg-styleguide';
import { cancelBackupUpgrade, confirmBackupUpgrade, createBackup } from '../../../../core/actions/pages/backup';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { BuyBackupTypes, DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { RootState } from '../../../../core/reducers';
import * as siteMetaApiSelectors from '../../../../core/selectors/site-meta-api';
import DateWithTime from '../../../components/date-with-time';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import { DeleteDialog } from '../../../containers/dialogs';
import FeatureNotAvailable from '../../../containers/dialogs/feature-not-available';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../containers/sg-dialog';
import VCS from '../../../containers/visibility-control-service';
import { DEST_DIR, RESTORE_TYPE, RESTORES } from '../constants';
import { mapSelectedDatabases } from '../utils';
import { CreateBox, CreateForm } from './create';
import { BuyBackup } from './dialogs';

import {
  RestoreDatabaseDialogForm,
  RestoreEmailDialogForm,
  RestoreFilesDialogForm,
  RestoreFullSiteForm
} from './update';

type Props = {
  actions: CrudActions;
  intl: Intl;
  items: any;
  availableSlots: number;
  openSGDialog: Function;
  closeSGDialog: Function;
  createBackup: typeof createBackup;
  confirmBackupUpgrade: Function;
  cancelBackupUpgrade: Function;
};

type State = {
  selectedOrderBackupType: BuyBackupTypes;
  backupOrderData: BackupOrderData;
  [other: string]: any;
};

class BackupRestoreManagePage extends React.Component<Props, State> {
  readonly state = {
    buyBackupsDialog: false,
    restoreFullSiteDialog: null,
    restoreFilesDialog: null,
    restoreDatabaseDialog: null,
    restoreEmailDialog: null,
    currentDeleteConformationDialogPayload: null,
    selectedOrderBackupType: null,
    backupOrderData: null
  };

  handleRestoreBackup = (createPayload: CreateItemPayload) =>
    this.props.actions.createItem(createPayload);

  onCreateFormSubmit = (data: CreateItemPayload) => {
    const { createBackup, openSGDialog } = this.props;

    createBackup({
      ...data,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_BACKUP,
          taskTitle: 'translate.page.backup.restore.create.task.loader.title',
          success: {
            intlKey: 'translate.page.backup.restore.create.success.message',
            intlValues: { name: data.instant_label }
          },
          error: {
            intlKey: 'translate.page.backup.restore.create.error.message',
            intlValues: { name: data.instant_label }
          }
        }
      }
    }, {
      onUpgradeRequired: (backupOrderData) =>
        this.setState({ backupOrderData }, () => openSGDialog(DIALOGS.BUY_BACKUP)),
      onUANotAvailable: () => openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE)
    });
  };

  render() {
    const { availableSlots, intl, items = {}, openSGDialog } = this.props;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.page.backup.restore.create.list.create.date' }),
      accessor: 'ts_start',
      render: (date, entity) => {
        if (entity.instant_label) {
          return (
            <Flex margin="none" gutter="none">
              <DateWithTime date={date} />
              &nbsp;-&nbsp;
              <Text color="dark">
                {entity.instant_label}
              </Text>
            </Flex>
          );
        }

        return (
          <DateWithTime date={date} />
        );
      }
    }, {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.create.list.backup.type' }),
      accessor: 'instant_backup',
      render: (backupType) => (
        backupType === 1 ? intl.formatMessage({ id: 'translate.generic.manual' }) :
          intl.formatMessage({ id: 'translate.generic.system' })
      )
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderContextMenu,
      style: { textAlign: 'right' }
    }];

    return (
      <Grid>
        <CreateBox>
          <CreateForm
            availableSlots={availableSlots}
            onSubmit={this.onCreateFormSubmit}
          />
        </CreateBox>

        <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="GET">
          <SGTable
            data={items[API_RESOURCE.BACKUP.resourceName]}
            resources={[{
              resourceName: API_RESOURCE.BACKUP.resourceName,
              methods: ['GET']
            }]}
            columns={columns}
            title={intl.formatMessage({ id: 'translate.page.backup.restore.create.list.title' })}
            noDataMessage="translate.page.backup.restore.create.no.data"
          />
        </VCS>

        <FeatureNotAvailable />

        {this.renderBuyBackupsDialog()}
        {this.renderRestoreFullSiteDialog()}
        {this.renderRestoreFilesDialog()}
        {this.renderRestoreDatabasesDialog()}
        {this.renderRestoreEmailsDialog()}
        {this.renderDeleteConformationDialogComponent()}
      </Grid>
    );
  };

  renderBuyBackupsDialog() {
    const { intl, closeSGDialog, cancelBackupUpgrade } = this.props;
    const { selectedOrderBackupType, backupOrderData } = this.state;

    return (
      <SGDialog
        id={DIALOGS.BUY_BACKUP}
        state="warning"
        size="x-large"
        onCloseHandler={() => {
          this.setState({ selectedOrderBackupType: null, backupOrderData: null });
          cancelBackupUpgrade();
        }}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.BUY_BACKUP} />

            <Button
              data-e2e="buy-backup-dialog-submit"
              color="primary"
              disabled={selectedOrderBackupType === null}
              onClick={() => this.props.confirmBackupUpgrade({
                planType: this.state.selectedOrderBackupType
              })}
            >
              {intl.formatMessage({ id: 'translate.generic.continue' })}
            </Button>
          </React.Fragment>
        }
      >
        {
          backupOrderData && (
            <BuyBackup
              backupOrderData={backupOrderData}
              selectedType={selectedOrderBackupType}
              onSelection={(type: BuyBackupTypes, state: boolean) => this.setState({
                selectedOrderBackupType: state ? type : null
              })}
              intl={intl}
            />
          )
        }
      </SGDialog>
    );
  }

  renderRestoreFullSiteDialog = () => {
    const { intl } = this.props;
    const { restoreFullSiteDialog } = this.state;
    const entity = restoreFullSiteDialog ? restoreFullSiteDialog.entity : {};

    return (
      <SGDialog
        id={DIALOGS.BACKUP_RESTORE_FULL_SITE_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.backup.restore.full.site.restore.title' })}
        icon="product-website"
        state="warning"
        resources={[{
          resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName,
          methods: ['POST']
        }]}
        footer={(
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.BACKUP_RESTORE_FULL_SITE_DIALOG} />
            <Button
              color="primary"
              onClick={() => this.handleRestoreBackup({
                backup_id: entity.id,
                restore_type: RESTORE_TYPE.FULL,
                _meta: {
                  notification: {
                    type: 'generic',
                    taskTitle: 'translate.page.backup.restore.full.site.title',
                    success: {
                      intlKey: 'translate.page.backup.restore.full.site.success.message'
                    },
                    error: {
                      intlKey: 'translate.page.backup.restore.full.site.error.message'
                    }
                  }
                },
                _metaFields: {
                  ...API_RESOURCE.BACKUP_RESTORE
                }
              })}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        )}
      >
        <RestoreFullSiteForm entity={entity} />
      </SGDialog>
    );
  };

  renderRestoreFilesDialog = () => {
    const { intl } = this.props;
    const { restoreFilesDialog } = this.state;
    const entity = restoreFilesDialog ? restoreFilesDialog.entity : {};

    const formInitialValues: CreateItemPayload = {
      restore_type: RESTORE_TYPE.FILE,
      custom_dest: 'false',
      _meta: {
        notification: {
          type: 'generic',
          taskTitle: 'translate.page.backup.restore.files.title',
          success: {
            intlKey: 'translate.page.backup.restore.files.success.message'
          },
          error: {
            intlKey: 'translate.page.backup.restore.files.error.message'
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.BACKUP_RESTORE
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.BACKUP_RESTORE_FILES_DIALOG}
        icon="file-outlined"
        size="x-large"
        density="none"
        title={intl.formatMessage(
          { id: 'translate.page.backup.restore.files.restore.title' },
          { date: getDate(entity.ts_start), time: getTime(entity.ts_start) }
        )}
        resources={[
          { resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName, methods: ['POST'] },
          { resourceName: API_RESOURCE.BACKUP_RESTORE_FILES.resourceName, methods: ['GET'] }
        ]}
      >
        <RestoreFilesDialogForm
          entity={entity}
          initialValues={formInitialValues}
          onSubmit={({ custom_dest, terms, ...data }: CreateItemPayload) => {
            const modifiedData = {
              dest_dir: '/',
              ...data,
              backup_id: entity.id
            };

            if (custom_dest === 'true') {
              const utcDate: any = Math.floor(+new Date() / 1000);
              modifiedData.dest_dir = `${DEST_DIR}${RESTORES}/${utcDate}/`;
            }

            this.handleRestoreBackup(modifiedData);
          }}
        />
      </SGDialogForm>
    );
  };

  renderRestoreDatabasesDialog = () => {
    const { intl, items } = this.props;
    const { restoreDatabaseDialog } = this.state;
    const entity = restoreDatabaseDialog ? restoreDatabaseDialog.entity : {};
    const backupDatabases = items[API_RESOURCE.BACKUP_RESTORE_DATABASE.resourceName] || [];

    const formInitialValues: CreateItemPayload = {
      backup_id: entity.id,
      restore_type: RESTORE_TYPE.DATABASE,
      terms: false,
      _meta: {
        notification: {
          type: 'generic',
          taskTitle: 'translate.page.backup.restore.databases.title',
          success: {
            intlKey: 'translate.page.backup.restore.databases.success.message'
          },
          error: {
            intlKey: 'translate.page.backup.restore.databases.error.message'
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.BACKUP_RESTORE
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.BACKUP_RESTORE_DATABASE_DIALOG}
        icon="database"
        density="none"
        size="large"
        title={intl.formatMessage(
          { id: 'translate.page.backup.restore.databases.restore.title' },
          { date: getDate(entity.ts_start), time: getTime(entity.ts_start) }
        )}
        resources={[
          { resourceName: API_RESOURCE.BACKUP_RESTORE_DATABASE.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName, methods: ['POST'] }
        ]}
        footer={backupDatabases.length === 0 && (
          <SGDialogCancel
            id={REDUX_FORM.BACKUP_RESTORE_DATABASE_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        )}
      >
        <RestoreDatabaseDialogForm
          entity={entity}
          initialValues={formInitialValues}
          onSubmit={({ restore_data, keep_original, terms, ...data }: CreateItemPayload) =>
            this.handleRestoreBackup({
              ...data,
              keep_original: Number(Boolean(keep_original)),
              restore_data: mapSelectedDatabases(restore_data, backupDatabases)
            })
          }
        />
      </SGDialogForm>
    );
  };

  renderRestoreEmailsDialog = () => {
    const { intl, items } = this.props;
    const { restoreEmailDialog } = this.state;
    const entity = restoreEmailDialog ? restoreEmailDialog.entity : {};
    const emails = items[API_RESOURCE.BACKUP_RESTORE_EMAIL.resourceName] || [];

    const initialFormValues: CreateItemPayload = {
      restore_data: [],
      _meta: {
        notification: {
          type: 'generic',
          taskTitle: 'translate.page.backup.restore.emails.title',
          success: {
            intlKey: 'translate.page.backup.restore.emails.success.message'
          },
          error: {
            intlKey: 'translate.page.backup.restore.emails.error.message'
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.BACKUP_RESTORE
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.BACKUP_RESTORE_EMAILS_DIALOG}
        title={intl.formatMessage(
          { id: 'translate.page.backup.restore.emails.restore.title' },
          { date: getDate(entity.ts_start), time: getTime(entity.ts_start) }
        )}
        icon="mail"
        density="none"
        size="large"
        submitLabel={intl.formatMessage({ id: 'translate.generic.restore' })}
        resources={[
          { resourceName: API_RESOURCE.BACKUP_RESTORE_EMAIL.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName, methods: ['POST'] }
        ]}
        footer={emails.length ? undefined :
          <SGDialogCancel
            id={REDUX_FORM.BACKUP_RESTORE_EMAILS_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
      >
        <RestoreEmailDialogForm
          entity={entity}
          initialValues={initialFormValues}
          onSubmit={(data: CreateItemPayload) => this.handleRestoreBackup({
            ...data,
            backup_id: entity.id,
            restore_type: RESTORE_TYPE.EMAIL
          })}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.entityLabel;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.backup.restore.delete.title' }, { name: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName: `${getDate(entity.ts_start)} ${getTime(entity.ts_start)}`,
      entityLabel: entity.instant_label,
      _metaFields: {
        ...API_RESOURCE.BACKUP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.backup.restore.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.backup.restore.delete.error.message'
          }
        }
      }
    };

    // TODO refactor
    return (
      <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasOneOfMethods={['POST', 'DELETE']}>
        <ContextMenu opener={<IconButton shape="circle" size="medium" icon="dots" />}>
          <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="POST">
            <ContextMenuItem
              icon="product-website"
              onClick={() => this.setState(
                { restoreFullSiteDialog: { entity } },
                () => openSGDialog(DIALOGS.BACKUP_RESTORE_FULL_SITE_DIALOG)
              )}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.context.menu.restore.full.site' })}
            </ContextMenuItem>
          </VCS>
          <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="POST">
            <ContextMenuItem
              icon="file-outlined"
              onClick={() => this.setState(
                { restoreFilesDialog: { entity } },
                () => openSGDialog(REDUX_FORM.BACKUP_RESTORE_FILES_DIALOG)
              )}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.context.menu.restore.files' })}
            </ContextMenuItem>
          </VCS>
          <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="POST">
            <ContextMenuItem
              icon="database"
              onClick={() => this.setState(
                { restoreDatabaseDialog: { entity } },
                () => openSGDialog(REDUX_FORM.BACKUP_RESTORE_DATABASE_DIALOG)
              )}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.context.menu.restore.databases' })}
            </ContextMenuItem>
          </VCS>
          <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="POST">
            <ContextMenuItem
              icon="mail"
              onClick={() => this.setState(
                { restoreEmailDialog: { entity } },
                () => openSGDialog(REDUX_FORM.BACKUP_RESTORE_EMAILS_DIALOG)
              )}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.context.menu.restore.emails' })}
            </ContextMenuItem>
          </VCS>
          {entity.instant_backup === 1 && (
            <VCS resourceName={API_RESOURCE.BACKUP.resourceNameMetaApi} hasMethod="DELETE">
              <ContextMenuItem
                icon="trash"
                onClick={() => this.setState(
                  { currentDeleteConformationDialogPayload: deletePayload },
                  () => openSGDialog(DIALOGS.GENERIC_DELETE)
                )}
              >
                {intl.formatMessage({ id: 'translate.generic.delete' })}
              </ContextMenuItem>
            </VCS>
          )}
        </ContextMenu>
      </VCS>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  availableSlots: siteMetaApiSelectors.getAvailableSlots(state)
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  createBackup: (formData, callbacks) => dispatch(createBackup(formData, callbacks)),
  confirmBackupUpgrade: ({ planType }) => dispatch(confirmBackupUpgrade({ planType })),
  cancelBackupUpgrade: () => dispatch(cancelBackupUpgrade())
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  indexWithCRUD()(
    BackupRestoreManagePage,
    API_RESOURCE.BACKUP
  )
);
