import { call, put, select, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import { FETCH_ITEMS_REQUESTED, UPDATE_EMAIL_FILTERS_ORDER } from '../../constants/actions';
import { API_RESOURCE } from '../../constants/api';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* updateEmailFiltersOrder(action) {
  const { name, domain_id, filter_ids } = action.payload;
  const state = yield select();

  const response = yield call(siteApi({
    endpoint: API_RESOURCE.EMAIL_FILTER_ORDER.endpoint,
    method: 'POST',
    body: {
      name,
      domain_id,
      filter_ids
    },
    state
  }));

  yield put({
    type: FETCH_ITEMS_REQUESTED,
    payload: { ...API_RESOURCE.EMAIL_FILTER }
  });

  return response;
}

function* EmailFilters(): any {
  yield takeLatest(UPDATE_EMAIL_FILTERS_ORDER, handleAvalonApiRequest(updateEmailFiltersOrder));
}

export default EmailFilters;
