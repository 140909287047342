import * as React from 'react';
import { Link } from 'sg-styleguide';

import './setting-list-item-title.scss';

const SettingListItemTitle = ({ title, entities, pluralLabel, onLinkClick }) => (
  <div>
    <span className="spf-settings-list-item-title">
      {title}
    </span>

    {entities && entities.length === 1 && (
      <Link onClick={onLinkClick}>{entities[0]}</Link>
    )}

    {entities && entities.length > 1 && (
      <Link onClick={onLinkClick}>{entities.length} {pluralLabel}</Link>
    )}
  </div>
);

export default SettingListItemTitle;
