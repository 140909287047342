import * as React from 'react';
import { Link, withRouter } from 'react-router';
import { RootState } from '../../core/reducers';
import { connect } from 'react-redux';

interface SGLinkProps {
  text: any;
  to: any;
  onClick?: any;
  activeClassName?: any;
  routing?: any;
  'data-e2e'?: string;
  // for unit testing
  store?: any;
}

class SGLink extends React.Component<SGLinkProps, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { onClick } = this.props;
    let to: any = {};

    if (typeof this.props.to === 'string') {
      to = { pathname: this.props.to };
      // is object
    } else {
      to = this.props.to;
    }

    if (to.query === undefined) {
      to.query = {};
    }

    // append siteId to query
    if (to.query.siteId === undefined) {
      to.query.siteId = this.props.routing.locationBeforeTransitions.query.siteId;
    }

    return (
      <Link
        to={to}
        activeClassName={this.props.activeClassName}
        onClick={onClick}
        data-e2e={this.props['data-e2e']}
      >
        {this.props.text}
      </Link>
    );

  }
}

function mapStateToProps(state: RootState) {
  return {
    routing: state.routing
  };
}

export default connect<{}, {}, SGLinkProps>(
  mapStateToProps
)(withRouter(SGLink));
