export const DOMAIN_SELECT_NAME = 'name';

export const ZONE_SETTING_ID = {
  CACHE_LEVEL: 'cache_level',
  DEVELOPMENT_MODE: 'development_mode',
  WAF: 'waf',
  SECURITY_LEVEL: 'security_level',
  ALWAYS_ONLINE: 'always_online',
  EMAIL_OBFUSCATION: 'email_obfuscation',
  MINIFY_JS: 'minify_js',
  MINIFY_CSS: 'minify_css',
  MINIFY_HTML: 'minify_html',
  RAILGUN: 'railgun',
  SSL: 'ssl',
  POLISH: 'polish',
  MIRAGE: 'mirage'
};

export const FIREWALL_API_PROPS = {
  IP: 'ip',
  RANGE: 'ip_range',
  BLOCK: 'block'
};
