import * as React from 'react';
import { Field } from 'redux-form';
import { Button, Grid, Link } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import ROUTES from '../../../../core/constants/routes';
import { ChangePassword } from '../../../components/common-forms';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../../containers/dialogs';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../containers/sg-dialog';
import VCS from '../../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create/index';
import UpdateProtectedURLs from './update/fields';

interface RedirectUsersProps {
  actions: CrudActions;
  items: any;
  location: any;
  intl: Intl;
  router: any;
  closeSGDialog: Function;
  openSGDialog: Function;
}

const { resourceName, endpoint, resourceNameMetaApi } = API_RESOURCE.LOCATION_USER;

class ProtectedUsers extends React.Component<RedirectUsersProps, any> {
  readonly state = {
    currentChangePasswordPayload: null,
    currentDeleteConformationDialogPayload: null,
    currentManageProtectedURLsPayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_ITEM_LOCATION_USER,
          success: {
            intlKey: 'translate.page.protected.users.create.success.message'
          },
          error: {
            intlKey: 'translate.page.protected.users.create.error.message'
          }
        }
      }
    });
  };

  renderUpdateProtectedUrls() {
    const { currentManageProtectedURLsPayload } = this.state;
    const entityName = currentManageProtectedURLsPayload && currentManageProtectedURLsPayload.username;
    const { intl, closeSGDialog } = this.props;

    const initialValues = {
      ...currentManageProtectedURLsPayload,
      location_ids: currentManageProtectedURLsPayload && currentManageProtectedURLsPayload.location_ids.concat(''),
      realm: 'Protected Folder'
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_PROTECTED_USERS_DIALOG}
        icon="key"
        title={intl.formatMessage({ id: 'translate.page.protected.user.update.access.title' }, { name: entityName })}
        resources={[{
          resourceName: API_RESOURCE.LOCATION_USER.resourceName,
          methods: ['PUT']
        }]}
      >
        <UpdateProtectedURLs
          initialValues={initialValues}
          onSubmit={(data) => {
            this.props.actions.updateItem(
              {
                ...data,
                _metaFields: {
                  ...data._metaFields
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.protected.user.updated_msg',
                      intlValues: { account: data.username }
                    },
                    error: {
                      intlKey: 'translate.page.protected.user.failed_update_msg',
                      intlValues: { account: data.username }
                    }
                  }
                }
              },
              () => closeSGDialog(REDUX_FORM.CHANGE_PROTECTED_USERS_DIALOG)
            );
          }}
        />
      </SGDialogForm>
    );
  }

  render() {
    const { location, items, intl, router, actions } = this.props;

    const columns = [
      { header: intl.formatMessage({ id: 'translate.generic.user' }), accessor: 'username' },
      {
        header: intl.formatMessage({ id: 'translate.page.protected.user.url.access' }),
        accessor: 'location_ids',
        render: this.renderPathsBasedOnId
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <Grid>
        <CreateBox
          location={items[API_RESOURCE.LOCATION_PROTECT.resourceName]}
          invokeChangeURLAccess={this.renderUpdateURLsDialog}
          onCreateUser={() => router.push(`${ROUTES.PROTECTED_URLS}${location.search}`)}
        >
          <CreateForm onSubmit={this.onCreateFormSubmit} />
        </CreateBox>

        {this.renderChangePasswordComponent()}
        {this.renderCreatePathPromptDialog()}
        {this.renderDeleteConformationDialogComponent()}
        {this.renderUpdateProtectedUrls()}

        <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.protected.user.list.title' })}
            data={items.locationUser || []}
            columns={columns}
            resources={[{ resourceName: API_RESOURCE.LOCATION_USER.resourceName, methods: ['GET'] }]}
            noDataMessage="translate.page.protected.user.sg-table.no-data.message"
          />
        </VCS>
      </Grid>
    );
  };

  renderUpdateURLsDialog = (entity) => {
    const { openSGDialog } = this.props;

    const manageProtectedURLsPayload = {
      initialValues: { location_ids: [''] },
      _metaFields: {
        fetchItemsAfterUpdate: true,
        resourceName,
        endpoint,
        resourceNameMetaApi
      },
      ...entity
    };

    this.setState(
      { currentManageProtectedURLsPayload: manageProtectedURLsPayload },
      () => openSGDialog(REDUX_FORM.CHANGE_PROTECTED_USERS_DIALOG)
    );
  };

  renderChangePasswordComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangePasswordPayload } = this.state;
    const entityName = currentChangePasswordPayload && currentChangePasswordPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_PASSWORD}
        icon="lock"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.password' }, { account: entityName })}
        resources={[{
          resourceName: API_RESOURCE.LOCATION_USER.resourceName,
          methods: ['PUT']
        }]}
      >
        <ChangePassword
          initialValues={currentChangePasswordPayload}
          onSubmit={(data) => actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.GENERIC_PASSWORD)
          )}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.protected.user.delete.title' }, { name: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const entityInfo = {
      entityName: entity.username
    };

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: entity.username,
      ...entityInfo,
      _metaFields: { ...API_RESOURCE.LOCATION_USER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.protected.user.deleted_msg',
            intlValues: { account: entity.username }
          },
          error: {
            intlKey: 'translate.page.protected.user.failed_delete_msg',
            intlValues: { account: entity.username }
          }
        }
      }
    };

    const changePasswordPayload = {
      _metaFields: {
        ...entityInfo,
        ...API_RESOURCE.LOCATION_USER,
        dontChangeItemsState: true
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.generic.password_changed',
            intlValues: { account: entity.username }
          },
          error: {
            intlKey: 'translate.page.protected.user.failed_change_password',
            intlValues: { account: entity.username }
          }
        }
      },
      id: entity.id
    };
    const manageProtectedURLsPayload = {
      _metaFields: {
        fetchItemsAfterUpdate: true,
        ...entityInfo,
        ...API_RESOURCE.LOCATION_USER
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.protected.user.updated_msg',
            intlValues: { account: entity.username }
          },
          error: {
            intlKey: 'translate.page.protected.user.failed_update_msg',
            intlValues: { account: entity.username }
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[{
          vcsMethod: 'POST',
          icon: 'key',
          label: intl.formatMessage({ id: 'translate.page.protected.user.manage.access' }),
          e2eAttr: 'table-action-add-url',
          visibleOnDesktop: true,
          onClick: () => {
            if (this.props.items.locationProtect.length > 0) {
              this.setState(
                { currentManageProtectedURLsPayload: manageProtectedURLsPayload },
                () => openSGDialog(REDUX_FORM.CHANGE_PROTECTED_USERS_DIALOG)
              );
            } else {
              openSGDialog(DIALOGS.PASSWORD_CREATE_PATH);
            }
          }
        }, {
          vcsMethod: 'PUT',
          icon: 'lock',
          label: intl.formatMessage({ id: 'translate.generic.change.password' }),
          e2eAttr: 'table-action-edit',
          onClick: () => this.setState(
            { currentChangePasswordPayload: changePasswordPayload },
            () => openSGDialog(REDUX_FORM.GENERIC_PASSWORD)
          )
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };

  renderPathsBasedOnId = (locationIDs, entity) => {
    const { intl, items } = this.props;
    const { locationProtect } = items;
    const justSelectedFormValues = new Set(locationIDs);
    const selectedPaths = locationProtect.filter((obj) => justSelectedFormValues.has(obj.id));

    if (selectedPaths.length === 0) {
      return null;
    }

    return (
      <Link
        onClick={() => this.renderUpdateURLsDialog(entity)}
      >
        {
          selectedPaths.length === 1 ?
            `${selectedPaths[0].domain_name}/${selectedPaths[0].path}`.replace('//', '/') :
            `${selectedPaths.length} ${intl.formatMessage({ id: 'translate.generic.urls' })}`
        }
      </Link>
    );
  };

  renderCreatePathPromptDialog = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialog
        id={DIALOGS.PASSWORD_CREATE_PATH}
        state="info"
        icon="information"
        title={intl.formatMessage({ id: 'translate.page.protected.urls.no.url.dialog.title' })}
        footer={(
          <div>
            <SGDialogCancel id={DIALOGS.PASSWORD_CREATE_PATH} />

            <Button
              color="primary"
              action="reset"
              data-e2e="dialog-navigate"
              onClick={() => {
                this.props.router.push(`${ROUTES.PROTECTED_URLS}${location.search}`);
                closeSGDialog(DIALOGS.PASSWORD_CREATE_PATH);
              }}
            >
              {intl.formatMessage({ id: 'translate.page.protected.protect.url' })}
            </Button>
          </div>
        )}
      />
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default indexWithCRUD(undefined, mapDispatchToProps)(
  ProtectedUsers,
  API_RESOURCE.LOCATION_PROTECT,
  API_RESOURCE.LOCATION_USER
);
