import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDate, getTime, IconButton, Loader, Table, Text } from 'sg-styleguide/';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import bytesToSize from '../../../../core/common/size-converter';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../../core/reducers';
import { LoaderContext } from '../../../contexts';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';
import { getEntityPath, getEntityReadableName, getEntityType } from '../core/utils';
import DropZone from '../dropzone';
import './content.scss';
import DragContentItem from './draggable-entity';
import Entity from './entity';
import EntityPreview from './entity-preview';

class FileManagerContent extends React.Component<any, any> {
  openMobileContextMenuTimeout;

  getEntitiesForRender() {
    const { entities, selectedNavigationEntity } = this.props;
    const selectedPath = selectedNavigationEntity && getEntityPath(selectedNavigationEntity);
    return selectedPath ? entities[selectedPath] : entities['/'];
  }

  renderDropZone = ({ parentLoaderVisible }) => {
    const entitiesToRender = this.getEntitiesForRender();
    const hasEntitiesToRenderLength = entitiesToRender && entitiesToRender.length > 0;

    return (
      <DropZone
        handleDrop={this.props.dragAndDrop.uploadFiles}
        onUnsupportedBroserDrop={() => this.props.openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
          title: 'translate.file.manager.drag.and.drop.not.supported'
        })}
      >
        {hasEntitiesToRenderLength && this.renderTable()}
        {!hasEntitiesToRenderLength && this.renderContentPreview(parentLoaderVisible)}
      </DropZone>
    );
  };

  render() {
    const { entities } = this.props;

    if (Object.keys(entities).length === 0) {
      return null;
    }

    return (
      <div
        className="content"
        onContextMenu={(event) => this.props.openContextMenu(event, FILE_MANAGER_CONTEXT_MENU_TYPE.BASE)}
      >
        <LoaderContext.Consumer children={this.renderDropZone} />
      </div>
    );
  }

  renderContentPreview(parentLoaderVisible: boolean) {
    const { entities, selectedNavigationEntity, intl } = this.props;
    const entityPath = getEntityPath(selectedNavigationEntity);
    const isEntityFolder = getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY;
    const folderName = getEntityReadableName(selectedNavigationEntity);

    const isLoadingVisible = isEntityFolder && entities[entityPath] === undefined;

    if (isLoadingVisible && !parentLoaderVisible) {
      return (
        <Loader className="content__loader">
          {intl.formatMessage({ id: 'translate.generic.loading' })}
          <br />
          <strong>{folderName}</strong>
        </Loader>
      );
    }

    return <EntityPreview entity={selectedNavigationEntity} />;
  }

  /**
   * Render Table with content ↓
   */

  renderTable() {
    const { environment, selectedContentEntities } = this.props;
    const entitiesToRender = this.getEntitiesForRender();

    const defaultProps = {
      className: 'content__table content__table--mobile',
      data: entitiesToRender || [],
      mobileLayout: 'row',
      onRowSelection: this.onRowSelection,
      rowKey: FILE_MANAGER_API_RESPONSE_DIR.INFO_NUMBER,
      selectable: true,
      selectedRows: selectedContentEntities
    };

    return environment.isDesktop ? this.renderDesktopTable(defaultProps) : this.renderMobileTable(defaultProps);
  }

  renderMobileTable(props) {
    const mobileColumns = [{
      accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
      render: (cell, entity) => <Entity entity={entity} />
    }, {
      accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
      style: { textAlign: 'right', width: '70px' },
      render: (cell, entity) =>
        <IconButton
          icon="dots"
          data-e2e="row-selection-button"
          onClick={(event) => {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            if (this.openMobileContextMenuTimeout) {
              clearTimeout(this.openMobileContextMenuTimeout);
            }

            this.onRowSelection([entity]);
            this.props.openContextMenu(event);
          }}
        />
    }];

    return (
      <Table
        disableTableHead
        density="small"
        columns={mobileColumns}
        onRowTap={(row, event) => {
          this.openMobileContextMenuTimeout = setTimeout(() => {
            this.onNameDoubleClickHandler(row);
          }, 100);
        }}
        onRowPress={(row) => {
          // TODO talk with Niki and Bobi about multi delete ....
          const { selectedContentEntities } = this.props;
          const isRowSelected = selectedContentEntities
            .find((entity) => JSON.stringify(entity) === JSON.stringify(row));

          const newSelectedRows = isRowSelected ?
            selectedContentEntities.filter((entity) => JSON.stringify(entity) !== JSON.stringify(row)) :
            selectedContentEntities.concat(row);

          this.onRowSelection(newSelectedRows);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        {...props}
      />
    );
  }

  renderDesktopTable(props) {
    const { intl } = this.props;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.name' }),
      accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
      render: this.renderNameColumn
    }, {
      header: intl.formatMessage({ id: 'translate.generic.date.modified' }),
      accessor: FILE_MANAGER_API_RESPONSE_DIR.MODIFIED_TIME,
      render: this.renderModifiedDateColumn,
      style: {
        width: '200px'
      }
    }, {
      header: intl.formatMessage({ id: 'translate.generic.permissions' }),
      accessor: FILE_MANAGER_API_RESPONSE_DIR.PERMISSIONS,
      render: this.renderPermissionsColumn,
      style: {
        width: '110px'
      }
    }, {
      header: intl.formatMessage({ id: 'translate.generic.size' }),
      accessor: FILE_MANAGER_API_RESPONSE_DIR.SIZE,
      render: this.renderSizeColumn,
      style: {
        textAlign: 'right',
        width: '100px'
      }
    }];

    return (
      <Table
        columns={columns}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (event.target.nodeName === 'TH') {
            return;
          }

          this.props.openContextMenu(event);
        }}
        {...props}
      />
    );
  }

  renderNameColumn = (name, entity) => {
    return (
      <DragContentItem
        entity={entity}
        handleDrop={this.props.dragAndDrop.moveDir}
        onDoubleClick={(event) => this.onNameDoubleClickHandler(entity)}
      />
    );
  };

  renderModifiedDateColumn(dm, entity) {
    return (
      <div className="content__row">
        <Text color="light">{`${getDate(dm)} ${getTime(dm)}`}</Text>
      </div>
    );
  }

  renderSizeColumn = (size, entity) => {
    const { intl } = this.props;
    const isFile = getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const renderInfo = isFile || (!isFile && size > 0) ? bytesToSize(size) : '-';

    return (
      <div className="content__row" style={{ justifyContent: 'flex-end' }}>
        <Text color="light">{renderInfo}</Text>
      </div>
    );
  };

  renderPermissionsColumn = (permissions, entity) => {
    return (
      <div className="content__row">
        <Text color="light">{permissions}</Text>
      </div>
    );
  };

  onRowSelection = (selectedContentEntities) => {
    this.props.actions.selectContentRows(selectedContentEntities);
  };

  onNameDoubleClickHandler = (entity) => {
    const entityType = getEntityType(entity);
    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
      this.openFolder(entity);
    }

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      this.props.codeEditor.openFile(entity);
    }
  };

  openFolder(entity) {
    this.props.actions.onEntityClick({
      entity
    });

    this.props.actions.fetchDir({
      urlParams: {
        id: getEntityPath(entity)
      }
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

const mapStateToProps = (state: RootState) => ({
  time: state.fileManager.time,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  entities: state.fileManager.entities,
  environment: state.environment
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerContent));
