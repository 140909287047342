import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormEmailDropdown from '../../../components/form-email-dropdown';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: { destination: any };
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG;

const ForwardForm = ({ intl, initialValues, validationUtils }: Props) => (
  <Field
    data-e2e="forward-update-email_select_visual"
    name="destination"
    selectedValue={initialValues.destination}
    label={intl.formatMessage({ id: 'translate.page.emailForward.destination' })}
    placeholder={intl.formatMessage({ id: 'translate.page.emailForward.destination' })}
    validate={[validationUtils.required ,validationUtils.emails]}
    component={FormEmailDropdown}
  />
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(ForwardForm)
  )
);
