import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Flex, Title } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { WP_LANGUAGES } from '../../../../core/constants/languages';
import { normalizeDirectoryData, handleDefaultPathSelection, formatPathInput } from '../../../../core/utils/paths';
import FormCheckbox from '../../../components/form-checkbox';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import FormPassword from '../../../components/form-password';
import InformationIcon from '../../../components/information-icon';
import SelectDirectory from '../../../containers/dialogs/select-directory';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import { WORDPRESS, WORDPRESS_WOOCOMMERCE } from '../constants';
import LogoTile from '../logo-tile';
import { FIELDS } from '../../app-manager/constants';
import { APP_TYPES } from '../../../../core/constants/application';

type Props = {
  intl: Intl;
  change: Function;
  formValues: Function;
  domain: any[];
  sPanelFormSubmit: (formName) => null;
  setParentState: (object) => null;
  selectedInstallation: string
  userLang: string;
  validationUtils: ValidationUtils;
  openSGDialog: Function;
};

const formName = REDUX_FORM.CREATE_WP_MANAGE;

class Form extends React.Component<Props, any> {
  componentDidMount() {
    const { domain, userLang, change } = this.props;
    const lang = userLang === 'en' ? 'en_US' : userLang;

    change('lang', lang);
    change(FIELDS.INSTALL_WP_STARTER, true);
  }

  renderFields() {
    const { domain, intl, validationUtils, openSGDialog } = this.props;
    const { email, required, password, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.application.setup' })}
          </Title>
        </Column>

        <Column smSpan="6">
          <Field
            name="domain_id"
            label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.domain' })}
            options={domain}
            optionValue="id"
            optionLabel="name"
            onChange={(value) => this.props.change('path', formatPathInput())}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="path"
            label={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path' })}
            validate={[validationWithMetaApi]}
            suffix={
              <Button
                action="button"
                color="dark"
                size="small"
                onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
              >
                {intl.formatMessage({ id: 'translate.generic.browse' })}
              </Button>
            }
            normalize={formatPathInput}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="lang"
            label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.language' })}
            options={WP_LANGUAGES}
            optionValue="id"
            optionLabel="label"
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Flex gutter="none" align="flex-end">
            <Field
              name={FIELDS.INSTALL_WP_STARTER}
              decoration="dotted"
              component={FormCheckbox}
            >
              {intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter.text' })}
            </Field>
          </Flex>
        </Column>

        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.admin.info' })}
          </Title>
        </Column>

        <Column smSpan="6">
          <Field
            name="admin_name"
            label={intl.formatMessage({ id: 'translate.generic.username' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            style={{ display: 'none' }}
            name="admin_pass"
            label="fake_password_to_prevent_autofill"
            data-e2e="fake-password"
            component={FormPassword}
          />
          <Field
            name="admin_pass"
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            validate={[required, password]}
            component={FormPassword}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="admin_email"
            label={intl.formatMessage({ id: 'translate.generic.email' })}
            validate={[required, email]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.install' })} />
        </Column>
      </React.Fragment>
    );
  }

  render() {
    const { change, domain, sPanelFormSubmit, selectedInstallation, setParentState } = this.props;
    const formValues = this.props.formValues(formName);
    const selectedDomain = domain.find((d) => formValues.domain_id === d.id);
    const publicHTML = '/public_html';
    const initialPath = selectedDomain && selectedDomain.name && `/${selectedDomain.name}`;
    const prefetchPaths = initialPath && [`${initialPath}${publicHTML}`];
    const pathToReplace = selectedDomain && `/${selectedDomain.name}${publicHTML}`;

    return (
      <SGForm onSubmit={sPanelFormSubmit(formName)}>
        <Column smSpan="6" flex>
          <LogoTile
            type={WORDPRESS}
            selected={selectedInstallation === WORDPRESS}
            onClick={() => setParentState({ selectedInstallation: WORDPRESS })}
          />
        </Column>

        <Column smSpan="6" flex>
          <LogoTile
            type={WORDPRESS_WOOCOMMERCE}
            selected={selectedInstallation === WORDPRESS_WOOCOMMERCE}
            onClick={() => setParentState({ selectedInstallation: WORDPRESS_WOOCOMMERCE })}
          />
        </Column>

        {selectedInstallation && this.renderFields()}

        <SelectDirectory
          initialPath={initialPath}
          prefetch={prefetchPaths}
          expandedNodes={[publicHTML]}
          normalizeDirectoryData={normalizeDirectoryData}
          onChange={(path) => this.props.change('path', handleDefaultPathSelection(path, pathToReplace))}
        />
      </SGForm>
    );
  }
}

const WpMangeForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.WORDPRESS,
      fetchItemsAfterCreate: true,
      fetchEndpoint: API_RESOURCE.APP.endpoint,
      fetchResourceName: API_RESOURCE.APP.resourceName,
      formName
    }
  }
})(Form);

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, any>(undefined, mapDispatchToProps)(withSpanelForm(injectIntl(WpMangeForm)));
