import * as Actions from '../constants/actions';
import { FileManagerRootState } from './utils/internal-state-definition';

const DialogsReducer = {
  [Actions.FILE_MANAGER_SET_MESSAGE_DIALOG_CONTENT]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      messageDialog: action.payload
    };
  },
  [Actions.FILE_MANAGER_SET_CONFIRM_DIALOG_CONTENT]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      confirmDialog: action.payload
    };
  }
};
export default DialogsReducer;
