import { findAppNameByType } from '../../../core/utils/app-names';
import { APP_TYPES } from '../../../core/constants/application';
import { WEEBLY_PLANS_OPTIONS, WEEBLY_CLASS_NUMBER_TO_PLAN } from './constants';
import { WeeblyExtra, TableListedApp } from './types';
import { formatMessage } from '../../../core/translate';

export const generateNotification = ({ formName, appType, domainName, path }): SpanelNotification => {
  const intlValues = {
    application: findAppNameByType(appType),
    path: `${domainName.name}${path}`
  };

  return {
    type: 'form',
    formName,
    success: {
      intlKey: 'translate.page.app-manager.create.success-create.notification',
      intlValues
    },
    error: {
      intlKey: 'translate.page.app-manager.create.fail-create.notification',
      intlValues
    }
  };
};

export const mapToWPFormData = ({ email, username, password, language, ...other }: any) => ({
  ...other,
  admin_email: email,
  admin_name: username,
  admin_pass: password,
  lang: language
});

export const formatWeeblyAppData = (weebly: WeeblyExtra, mainDomainName: string): TableListedApp => {
  const weeblyPlanId = WEEBLY_CLASS_NUMBER_TO_PLAN[weebly.plan];

  const plan = WEEBLY_PLANS_OPTIONS.find(({ id }) => id === weeblyPlanId);
  const planLabel = plan && plan.label;

  return {
    id: `weebly-${weebly.currentSiteId}`,
    isWeebly: true,
    app_url: mainDomainName,
    admin_url: weebly.admin_url,
    app: `${findAppNameByType(APP_TYPES.WEEBLY)} ${formatMessage({ id: planLabel })}`
  };
};

export const getWeeblyAdminUrl = ({ buttons = [] }) => {
  const loginButton = buttons.find(({ name }) => name === 'login');

  if (loginButton && loginButton.additional_info) {
    return loginButton.additional_info.link;
  }
};
