import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';
import AutoResponderFields from '../autoresponder-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  onSubmit: Function;
  selectedDomain: any;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG;

const UpdateFieldsForm = ({ change, getFormValues, selectedDomain, validationUtils }: Props) => (
  <Grid>
    <AutoResponderFields
      isEdit
      selectedDomain={selectedDomain}
      values={getFormValues(formName)}
      changeFieldValue={change}
      validationUtils={validationUtils}
    />
  </Grid>
);

export default withSpanelForm(
  reduxForm({ form: formName })(UpdateFieldsForm)
);
