import { UPDATE_EMAIL_FILTERS_ORDER } from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';

export const updateEmailFilterOrder = ({ name, domain_id, filter_ids, _metaFields, _meta }) => ({
  type: UPDATE_EMAIL_FILTERS_ORDER,
  payload: {
    name,
    domain_id,
    filter_ids,
    _metaFields,
    _meta
  },
  requestTypeName: customRequestTypes.EMAIL_FILTER_ORDER
});
