import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from 'sg-styleguide';
import { injectIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../containers/sg-form';

import FormInput from '../../../../components/form-input';
import FormDropdown from '../../../../components/form-dropdown';

type Domains = {
  id: string;
  name: string;
};

type Props = {
  domainOptions: Domains[];
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

const formName = REDUX_FORM.CREATE_ITEM_LOCATION_PROTECT;

const matchExistingDomain = (path: string, domains: Domains[]) => {
  const domain = domains.find(({ name }) => new RegExp(name, 'i').test(path));

  if (domain) {
    const [
      matchedString,
      protocol,
      www,
      domainFound,
      relativePath
    ] = new RegExp(`(https?:\/\/)?(www.)?(${domain.name}\/)(.+)?`, 'i').exec(path);

    return `/${relativePath}`;
  }

  return path;
};

export const normalizePathInput = (path: string, domains: Domains[] = []) => {
  const modifiedPath = matchExistingDomain(path, domains);

  if (modifiedPath.charAt(0) === '/') {
    return modifiedPath;
  }

  return `/${modifiedPath}`;
};

const Form = ({ validationUtils, sPanelFormSubmit, domainOptions, intl }: Props) => {
  const { required, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="domain_id"
          type="text"
          options={domainOptions}
          optionValue="id"
          optionLabel="name"
          label={intl.formatMessage({ id: 'translate.page.protected.domain' })}
          placeholder={intl.formatMessage({ id: 'translate.page.protected.domain' })}
          searchable
          component={FormDropdown}
          validate={[required]}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="path"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.protected.path' })}
          placeholder={intl.formatMessage({ id: 'translate.page.protected.path' })}
          normalize={(path) => normalizePathInput(path, domainOptions)}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>
      <Column smSpan="12">
        <SGSubmitButton
          label={intl.formatMessage({ id: 'translate.page.protected.protect.button' })}
          formName={formName}
        />
      </Column>
    </SGForm>
  );
};

const ProtectedUrlsCreateForm = reduxForm({
  form: formName,
  initialValues: {
    realm: 'Protected folder',
    path: '/',
    _metaFields: {
      ...API_RESOURCE.LOCATION_PROTECT,
      formName
    }
  }
})(Form);

export default withSpanelForm(
  injectIntl(ProtectedUrlsCreateForm)
);
