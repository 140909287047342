export const sortAscendingByid = (items: any[]) => {
  const sortedList = items.sort((a, b) => {
    if (a.id === undefined || b.id === undefined) {
      return 0;
    }
    return parseInt(b.id, 10) - parseInt(a.id, 10);
  });

  return sortedList;
};

export const sortAscendingByMain = (items: any[]) =>
  items.sort((itemA, itemB) => {
    if (itemB.domain_type) {
      return itemB.domain_type === 'main_domain' ? 1 : -1;
    }

    return itemB.main ? 1 : -1;
  });
