import fileManagerDeleteDirs from '../../web/containers/file-manager/core/sagas/delete';
import fileManagerfetchDir from '../../web/containers/file-manager/core/sagas/fetch';
import fileManagerNavigate from '../../web/containers/file-manager/core/sagas/navigate';
import fileManagerPostRequests from '../../web/containers/file-manager/core/sagas/post';
import changeSite from './change-site';
import countries from './countries';
import createItem from './crud/create-item';
import deleteItem from './crud/delete-item';
import fetchItem from './crud/fetch-item';
import fetchItems from './crud/fetch-items';
import updateItem from './crud/update-item';
import fetchDir from './fetch-dir-by-path';
import formSubmission from './form-submission';
import login from './login';
import logout from './logout';
import nemoStore from './nemo-store';
import pageLoad from './page-load';
import backup from './pages/backup';
import dashboard from './pages/dashboard';
import emailAccounts from './pages/email-accounts';
import emailAuthentication from './pages/email-authentication';
import emailFilters from './pages/email-filters';
import resourceStats from './pages/resource-stats';
import wpAutoupdate from './pages/wp-autoupdate';
import wpStaging from './pages/wp-staging';
import genericRequests from './generic-requests';
import sgDialog from './sg-dialog';
import requestAuthorization from './request-authorization';
import spanelForms from './spanel-forms';
import support from './support';
import tasks from './tasks';
import sgSiteScanner from './pages/sg-sitescanner';
import notifications from './notifications';
import requestsCompletionHandling from './requests-completion';
import siteMetaApi from './site-meta-api';
import openNewTab from './open-new-tab';

export default function* sagas() {
  yield [
    createItem(),
    updateItem(),
    deleteItem(),
    fetchItem(),
    fetchItems(),
    fileManagerNavigate(),
    changeSite(),
    login(),
    logout(),
    pageLoad(),
    requestAuthorization(),
    fileManagerfetchDir(),
    fileManagerDeleteDirs(),
    fileManagerPostRequests(),
    emailFilters(),
    tasks(),
    wpStaging(),
    emailAccounts(),
    emailAuthentication(),
    wpAutoupdate(),
    spanelForms(),
    formSubmission(),
    resourceStats(),
    countries(),
    fetchDir(),
    nemoStore(),
    sgDialog(),
    dashboard(),
    backup(),
    genericRequests(),
    support(),
    sgSiteScanner(),
    notifications(),
    requestsCompletionHandling(),
    siteMetaApi(),
    openNewTab()
  ];
}
