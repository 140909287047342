import * as React from 'react';
import {
  BarChart,
  ChartContainer,
  ChartTooltip,
  ChartLegend,
  Dropdown,
  Flex,
  Grid,
  Link,
  Table,
  Title
} from 'sg-styleguide';

import { Keywords, Refs, SearchEngines, StatsType } from '../../../../core/definitions/stats';
import withLoadMore from '../../../containers/with-load-more';
import SGTable from '../../../components/sg-table';

const SOURCES_LOAD_MORE = {
  refs: 'refs',
  keyword: 'keywords'
};

const LOAD_MORE_SETTINGS = {
  loadStep: 10,
  initialLength: 10
};

const getSearchEngineLabel = (se: string) => {
  switch (true) {
    case se.includes('google'):
      return 'Google';
    case se.includes('yahoo'):
      return 'Yahoo';
    case se.includes('bing'):
      return 'Bing';
    case se.includes('duckduckgo'):
      return 'DuckDuckGo';
    case se.includes('yandex'):
      return 'Yandex';
    default:
      return se;
  }
};

type Props = {
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => SearchEngines[] | Refs[] | Keywords[];
  renderDatepicker: Function;
  // with load more
  onLoadMore: (id: string) => any;
  getAllLoaded: (id: string, items: any[]) => any[];
  shouldShowLoadMore: (id: string, items: any[]) => boolean;
  intl: Intl;
};

type State = {
  selectedSource: 'serefs' | 'refs' | 'keywords' | string;
};

class Sources extends React.Component<Props, State> {
  readonly state = {
    selectedSource: 'serefs'
  };

  render() {
    const { intl, statsMissing } = this.props;
    const sourceOptions = [
      { label: intl.formatMessage({ id: 'translate.page.stats.search-engines.label' }), value: 'serefs' },
      { label: intl.formatMessage({ id: 'translate.page.stats.referrers.label' }), value: 'refs' },
      { label: intl.formatMessage({ id: 'translate.page.stats.keywords.label' }), value: 'keywords' }
    ];

    return (
      <Grid gap="x-large">
        <Flex gutter="none">
          <Grid sm="4">
            {this.props.renderDatepicker()}
            <Dropdown
              label={intl.formatMessage({ id: 'translate.page.stats.select-source.label' })}
              size="small"
              options={sourceOptions}
              selectedValue={this.state.selectedSource}
              optionValue="value"
              optionLabel="label"
              onChange={(selectedSource) => this.setState({ selectedSource })}
            />
          </Grid>
        </Flex>
        {
          statsMissing ? (
            <SGTable data={[]} shadow={false} noDataMessage="translate.page.stats.no-stats.placeholder.message" />
          ) : this.renderStats()
        }
      </Grid>
    );
  }

  renderStats = () => {
    const {
      getStats,
      onLoadMore,
      getAllLoaded,
      shouldShowLoadMore,
      intl
    } = this.props;

    const enginesStats = getStats('serefs');
    const refsStats = getStats('refs');
    const keywordsStats = getStats('keywords');

    const legendElements = [{
      id: 'pages',
      label: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
      checked: true
    }, {
      id: 'hits',
      label: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
      checked: true
    }];

    switch (this.state.selectedSource) {
      case 'serefs':
        const elementsIds = ['pages', 'hits'];

        return (
          <ChartContainer
            legendCols="2"
            chartCols="10"
            title={intl.formatMessage({ id: 'translate.page.stats.search-engines.label' })}
            renderCustomLegendContent={() => (
              <ChartLegend
                data={legendElements}
                checkboxIcon="empty"
                labelKey="label"
              />
            )}
            renderChildren={() => {
              return (
                <BarChart
                  data={enginesStats}
                  yAxisWidth={90}
                  axisData={{
                    y: 'se'
                  }}
                  elementsIds={elementsIds}
                  filteredElementsIds={elementsIds}
                  tickFormatterY={getSearchEngineLabel}
                  renderTooltip={({ payload }: { payload: SearchEngines }) => (
                    <ChartTooltip
                      elementsIds={elementsIds}
                      filteredElementsIds={elementsIds}
                      payload={payload}
                      title={getSearchEngineLabel(payload.se)}
                      elementLabels={{
                        pages: `${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })}: `,
                        hits: `${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}: `
                      }}
                    />
                  )}
                />
              );
            }}
          />
        );
      case 'refs':
        return (
          <Table
            border="small"
            mobileLayout="row"
            showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.refs, refsStats)}
            onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.refs)}
            data={getAllLoaded(SOURCES_LOAD_MORE.refs, refsStats)}
            columns={[{
              header: intl.formatMessage({ id: 'translate.page.stats.page.label' }),
              accessor: 'url',
              render: (url) => (
                <Link href={url} target="_blank">
                  {url}
                </Link>
              )
            }, {
              header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
              accessor: 'hits',
              style: { textAlign: 'center' }
            }, {
              header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
              accessor: 'pages',
              style: { textAlign: 'right' }
            }]}
          />
        );
      case 'keywords':
        return (
          <Table
            border="small"
            mobileLayout="row"
            showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.keyword, keywordsStats)}
            onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.keyword)}
            data={getAllLoaded(SOURCES_LOAD_MORE.keyword, keywordsStats)}
            columns={[{
              header: intl.formatMessage({ id: 'translate.page.stats.keyword.label' }),
              accessor: 'keyword'
            }, {
              header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
              accessor: 'hits',
              style: { textAlign: 'right' }
            }]}
          />
        );
      default:
        return null;
    }
  };
}

export default withLoadMore(
  Sources, {
    id: SOURCES_LOAD_MORE.refs,
    ...LOAD_MORE_SETTINGS
  }, {
    id: SOURCES_LOAD_MORE.keyword,
    ...LOAD_MORE_SETTINGS
  }
);
