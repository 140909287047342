import * as React from 'react';
import { Button, Dropdown, Grid, Link, Section, Tab, Tabs, Text, textToHTML } from 'sg-styleguide';
import { openNewTabAction, OpenNewTabPayload } from '../../../core/actions/open-new-tab';
import { requestCustomDeployStaging, requestUnknownFiles } from '../../../core/actions/pages/wp-staging';
import { requestData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { getStagingApps, getWordpressApps } from '../../../core/selectors';

// COMPONENTS
import DateWithTime from '../../components/date-with-time';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGLink from '../../components/sg-link';
import SGTable from '../../components/sg-table';
import NoWPApplicationNotice from '../../containers/no-wordpress-app-notice';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../containers/sg-dialog';
import { SecondLevelTitle } from '../../containers/titles';
import VCS from '../../containers/visibility-control-service';
import ContextMenu from './context-menu';
import { CreateBox, CreateForm } from './create';
import CustomDeploy from './custom-deploy';
import IncludeFilesSelector from './include-files-selector';
import RenameStaging from './rename/rename-staging';
import ReplicateStaging from './replicate/replicate-staging';
import { CustomDeployResponse, OptionsStruct } from './types';
import { defaultCustomDeployData, formatCustomDeployData } from './utils';

type Props = {
  wordpressApps: WordpressApp[];
  getStagingApps: (sourceAppId: number) => WordpressApp[];
  actions: CrudActions;
  stagingApps: WordpressApp[];
  environment: { isPhone: boolean };
  items: {
    app: any[];
  };
  intl: Intl;
  router: any;
  requestUnknownFiles: (appId: number, onComplete) => void;
  requestCustomDeployStaging: (appId: number, onComplete) => void;
  requestData: RequestData;
  openSGDialog: Function;
  closeSGDialog: Function;
  updatePageData: Function;
  openNewTab: (payload: OpenNewTabPayload) => any;
};

type State = {
  selectedApp: WordpressApp;
  tableTab: TableTabs;
  stagingItemSelected: WordpressApp;
  stagingUnknownFiles: string[];
  createSubmitData: CreateItemPayload;
  availableBackups: AvailableBackup[];
  customDeployData: {
    data: {
      added: OptionsStruct;
      changed: OptionsStruct;
      deleted: OptionsStruct;
    },
    total: number;
    loaded: boolean;
  };
};

type AvailableBackup = {
  timestamp: string;
  tool: 'staging' | 'backup';
};

enum TableTabs {
  Staging = 'STAGING',
  Backup = 'BACKUP'
};

export const PROTECTED_URL_LINK_PLACEHOLDER = '__protectedUrlLink__';

class WpStaging extends React.Component<Props, State> {
  readonly state: State = {
    selectedApp: null,
    tableTab: TableTabs.Staging,
    availableBackups: [],
    stagingItemSelected: null,
    stagingUnknownFiles: null,
    createSubmitData: null,
    customDeployData: null
  };

  componentDidUpdate() {
    const { wordpressApps, actions } = this.props;
    const { selectedApp } = this.state;

    if (!selectedApp && wordpressApps[0]) {
      this.selectApp(wordpressApps[0]);
    }
  }

  render() {
    const { intl, wordpressApps, items } = this.props;
    const hasNoWPApps = items[API_RESOURCE.APP.resourceName] && wordpressApps.length === 0;

    return (
      <div>
        <PageHeader
          icon="presentational-wordpress-staging"
          title={intl.formatMessage({ id: 'translate.page.staging.title' })}
          instructions={
            intl.formatMessage(
              { id: 'translate.page.staging.info.text' },
              { protectedUrlLink: PROTECTED_URL_LINK_PLACEHOLDER }
            )
          }
          renderNoticeText={(text: string) => {
            const textParts = text.split(PROTECTED_URL_LINK_PLACEHOLDER);
            return (
              <Text>
                {textToHTML(textParts[0])}
                <SGLink
                  to="/protected-urls"
                  text={<Link>{intl.formatMessage({ id: 'translate.page.protected.title' })}</Link>}
                />
                {textToHTML(textParts[1])}
              </Text>
            );
          }}
        />
        <Section>
          {hasNoWPApps ? <NoWPApplicationNotice /> : this.renderPageContent()}
        </Section>
        {this.renderIncludeFilesDialog()}
        {this.renderFullDeployDialog()}
        {this.renderCustomDeployDialog()}
        {this.renderDestroyDialog()}
        {this.renderRenameDialog()}
        {this.renderReplicateDialog()}
      </div>
    );
  }

  selectApp = (app: WordpressApp) => {
    const { actions, wordpressApps } = this.props;
    this.setState({ selectedApp: app }, this.updateBackups);
  };

  updateBackups = () =>
    this.props.actions.fetchItem({
      ...API_RESOURCE.APP_LATEST,
      itemId: this.state.selectedApp && this.state.selectedApp.id
    }, ({ available_backups }) => {
      this.setState({
        availableBackups: available_backups || []
      });
    });

  onStagingAction = (actionId: string, app: WordpressApp) => {
    const { openSGDialog } = this.props;

    this.setState({ stagingItemSelected: app });

    switch (actionId) {
      case 'log-in-to-admin':
        return this.props.openNewTab({ url: `${app.app_url}/wp-admin`.replace('//', '/') });
      case 'full-deploy':
        return openSGDialog(DIALOGS.WP_STAGING_FULL_DEPLOY, app);
      case 'custom-deploy':
        openSGDialog(DIALOGS.WP_STAGING_CUSTOM_DEPLOY, app);
        return this.requestCustomDeploy(app);
      case 'destroy':
        return openSGDialog(DIALOGS.WP_STAGING_DESTROY, app);
      case 'replicate':
        return openSGDialog(REDUX_FORM.REPLICATE_WP_APP_STAGING, app);
      case 'rename':
        return openSGDialog(REDUX_FORM.CHANGE_WP_APP_STAGING_NAME, app);
      default:
        return;
    }
  };

  requestCustomDeploy = (app: WordpressApp) => {
    const { actions, requestCustomDeployStaging } = this.props;

    requestCustomDeployStaging(
      app && app.id,
      (data: CustomDeployResponse) => {
        this.setState({
          customDeployData: formatCustomDeployData(data)
        });
      }
    );
  };

  onCreateFormSubmit = (appId: number, formData: CreateItemPayload) =>
    (filesData) => {
      const { actions, openSGDialog } = this.props;
      const stagingUnknownFiles = filesData.unknown_files || [];

      const notification: SpanelNotification = {
        type: 'form',
        formName: REDUX_FORM.CREATE_STAGING,
        success: {
          intlKey: 'translate.page.staging.create.success.message',
          intlValues: {
            name: formData.label
          }
        },
        error: {
          intlKey: 'translate.page.staging.create.failure.message',
          intlValues: {
            name: formData.label
          }
        }
      };

      if (stagingUnknownFiles.length === 0) {
        actions.createItem({
          ...formData,
          app_id: appId,
          extra_files: [],
          _meta: {
            ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
            notification
          }
        });
      } else {
        this.setState({
          stagingUnknownFiles,
          createSubmitData: {
            ...formData,
            app_id: appId,
            _meta: {
              ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
              notification
            }
          }
        }, () => openSGDialog(DIALOGS.WP_STAGING_INCLUDE_FILES));
      }
    };

  restoreBackup = ({ timestamp, tool }: AvailableBackup) => {
    const { actions, intl } = this.props;
    const { selectedApp } = this.state;

    actions.updateItem({
      _timeout: 0,
      _metaFields: {
        ...API_RESOURCE.APP_RESTORE_BACKUP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.restore.success.notification'
          },
          error: {
            intlKey: 'translate.page.staging.restore.fail.notification'
          }
        }
      },
      id: selectedApp && selectedApp.id,
      timestamp,
      tool
    });
  };

  renderTabs = () => (
    <Tabs size="small" border="light">
      <VCS resourceName={API_RESOURCE.APP.resourceNameMetaApi} hasMethod="GET">
        <Tab
          data-e2e="wp-staging-tab-staging"
          active={this.state.tableTab === TableTabs.Staging}
          onClick={() => this.setState({ tableTab: TableTabs.Staging })}>
          {this.props.intl.formatMessage({ id: 'translate.page.staging.tab.staging.label' })}
        </Tab>
      </VCS>
      <Tab
        data-e2e="wp-staging-tab-backup"
        active={this.state.tableTab === TableTabs.Backup}
        onClick={() => this.setState({ tableTab: TableTabs.Backup })}>
        {this.props.intl.formatMessage({ id: 'translate.page.staging.tab.backup.label' })}
      </Tab>
    </Tabs>
  );

  renderPageContent() {
    const {
      intl,
      actions,
      wordpressApps,
      getStagingApps,
      requestUnknownFiles
    } = this.props;

    const { selectedApp, tableTab, availableBackups } = this.state;

    const backupsList = availableBackups
      .map(({ timestamp, ...other }) => ({ id: timestamp, timestamp, ...other }))
      .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));

    const appId = selectedApp && selectedApp.id;
    const stagingApps = getStagingApps(appId);

    const stagingSiteTableProps = {
      data: stagingApps,
      resources: [{
        resourceName: API_RESOURCE.APP.resourceName,
        methods: ['GET']
      }],
      rowResources: [{
        resourceName: API_RESOURCE.APP.resourceName,
        methods: ['PUT', 'DELETE']
      }, {
        resourceName: API_RESOURCE.APP_STAGING.resourceName,
        methods: ['POST', 'PUT', 'DELETE']
      }],
      columns: [{
        header: intl.formatMessage({ id: 'translate.page.staging.table.column.site' }),
        accessor: 'label',
        render: (value, { app_url }) =>
          <Link
            style={{ fontWeight: 'normal' }}
            target="_blank"
            href={app_url}
            title={app_url}
          >
            {value}
          </Link>
      }, {
        header: intl.formatMessage({ id: 'translate.page.staging.table.column.created' }),
        accessor: 'created',
        render: (value) => <DateWithTime date={value} />
      }, {
        header: intl.formatMessage({ id: 'translate.page.staging.table.last.deploy' }),
        accessor: 'last_deploy',
        render: (value) => value ? (
          <DateWithTime date={value} />
        ) : (
          <Text weight="bold">
            {intl.formatMessage({ id: 'translate.page.staging.table.never.label' })}
          </Text>
        )
      }, {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'actions',
        render: (_, app: WordpressApp) =>
          <ContextMenu
            onActionClicked={this.onStagingAction}
            app={app}
          />
      }]
    };

    const backupTableProps = {
      data: backupsList,
      resources: [{
        resourceName: API_RESOURCE.APP_LATEST.resourceName,
        methods: ['GET']
      }, {
        resourceName: API_RESOURCE.APP_RESTORE_BACKUP.resourceName,
        methods: ['PUT']
      }, {
        resourceName: API_RESOURCE.APP.resourceName,
        methods: ['GET']
      }],
      columns: [{
        header: intl.formatMessage({ id: 'translate.page.staging.table.column.created' }),
        accessor: 'timestamp',
        render: (value) => value && <DateWithTime date={value} />
      }, {
        // Dummy column to prevent resizing of the table from tab change
        accessor: 'timestamp',
        header: ' ',
        style: this.props.environment.isPhone ? { display: 'none' } : undefined,
        render: () => null
      }, {
        // Dummy column to prevent resizing of the table from tab change
        accessor: 'timestamp',
        header: ' ',
        style: this.props.environment.isPhone ? { display: 'none' } : undefined,
        render: () => null
      }, {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'tool',
        render: (tool, availableBackup: AvailableBackup) => (
          <Button type="outlined" color="secondary" onClick={() => this.restoreBackup(availableBackup)}>
            {intl.formatMessage({ id: 'translate.generic.restore' })}
          </Button>
        )
      }]
    };

    const tablePropsToUse = tableTab === TableTabs.Staging ? stagingSiteTableProps : backupTableProps;

    return (
      <Grid>
        <div>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.page.wp-staging.select.domain.label' })}
          </SecondLevelTitle>
          <Dropdown
            options={wordpressApps}
            selectedValue={wordpressApps && wordpressApps[0] && wordpressApps[0].id}
            optionValue="id"
            optionLabel="app_url"
            size="x-large"
            enableShadow
            onChange={(id, app) => this.selectApp(app)}
          />
        </div>
        <CreateBox>
          <CreateForm
            apps={wordpressApps}
            onSubmit={(data: CreateItemPayload) => requestUnknownFiles(
              appId,
              this.onCreateFormSubmit(appId, data)
            )}
          />
        </CreateBox>

        <SGTable
          title={intl.formatMessage({ id: 'translate.page.staging.manage.sites.title' })}
          renderBeforeTableContent={this.renderTabs}
          {...tablePropsToUse}
        />
      </Grid>
    );
  };

  renderIncludeFilesDialog = () => {
    const { actions, closeSGDialog } = this.props;
    const { stagingUnknownFiles, createSubmitData } = this.state;

    return (
      <IncludeFilesSelector
        items={stagingUnknownFiles}
        onSubmit={(files: string[]) => {
          closeSGDialog(DIALOGS.WP_STAGING_INCLUDE_FILES);

          actions.createItem({
            ...createSubmitData,
            extra_files: files
          });
        }}
        onCloseHandler={() => this.setState({
          stagingUnknownFiles: null,
          createSubmitData: null
        })}
      />
    );
  };

  renderFullDeployDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const app = this.state.stagingItemSelected;

    const intlValues = {
      siteName: app && app.label
    };

    const updatePayload: UpdateItemPayload = {
      id: app && app.id,
      push_to_live: 1,
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.deploy.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.deploy.fail',
            intlValues
          }
        }
      }
    };

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_FULL_DEPLOY}
        icon="deploy"
        state="warning"
        title={intl.formatMessage(
          { id: 'translate.page.staging.dialog.deploy.title' },
          { name: app && app.label }
        )}
        footer={this.renderConfirmDialogFooter(
          DIALOGS.WP_STAGING_FULL_DEPLOY,
          intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.button' }),
          () => actions.updateItem(
            updatePayload,
            () => {
              actions.fetchItems(API_RESOURCE.APP);
              this.updateBackups();
            }
          )
        )}
      >
        <Text>
          {intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.message' })}
        </Text>
      </SGDialog>
    );
  };

  renderCustomDeployDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { customDeployData, stagingItemSelected } = this.state;

    const id = stagingItemSelected && stagingItemSelected.id;
    const label = stagingItemSelected && stagingItemSelected.label;

    const intlValues = {
      siteName: label
    };

    const updatePayload: UpdateItemPayload = {
      id,
      push_to_live: 1,
      advanced: 1,
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.deploy.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.deploy.fail',
            intlValues
          }
        }
      }
    };

    const deployData = customDeployData || defaultCustomDeployData;

    return (
      <CustomDeploy
        name={label}
        customDeployData={deployData.data}
        dataLoaded={deployData.loaded}
        total={deployData.total}
        onCloseHandler={() => {
          this.setState({
            customDeployData: null
          });
        }}
        onSubmit={(data) => {
          closeSGDialog(DIALOGS.WP_STAGING_CUSTOM_DEPLOY);

          actions.updateItem({
            ...updatePayload,
            custom: data
          }, () => {
            actions.fetchItems(API_RESOURCE.APP);
            this.updateBackups();
          });
        }}
      />
    );
  };

  renderDestroyDialog = () => {
    const { intl, actions, closeSGDialog, updatePageData } = this.props;
    const app = this.state.stagingItemSelected;

    const intlValues = {
      siteName: app && app.label
    };

    const deletePayload: DeleteItemPayload = {
      itemId: app && app.id,
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.destroy.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.destroy.fail',
            intlValues
          }
        }
      }
    };

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_DESTROY}
        icon="destroy"
        state="warning"
        title={intl.formatMessage(
          { id: 'translate.page.staging.dialog.destroy.confirm.title' },
          { name: app && app.label }
        )}
        footer={this.renderConfirmDialogFooter(
          DIALOGS.WP_STAGING_DESTROY,
          intl.formatMessage({ id: 'translate.generic.confirm' }),
          () => actions.deleteItem(deletePayload, updatePageData as OnCompleteCallback)
        )}
      >
        <Text>
          {intl.formatMessage({ id: 'translate.page.staging.dialog.destroy.confirm.message' })}
        </Text>
      </SGDialog>
    );
  };

  renderRenameDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const app = this.state.stagingItemSelected;
    const id = app && app.id;
    const label = app && app.label;

    const intlValues = {
      siteName: label
    };

    const initialValues: UpdateItemPayload = {
      id,
      label,
      _metaFields: {
        ...API_RESOURCE.APP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.rename.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.rename.fail',
            intlValues
          }
        }
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_WP_APP_STAGING_NAME}
        icon="rename"
        title={intl.formatMessage(
          { id: 'translate.page.staging.dialog.rename.message' },
          { name: label }
        )}
        resources={[{
          resourceName: API_RESOURCE.APP.resourceName,
          methods: ['PUT']
        }]}
      >
        <RenameStaging
          initialValues={initialValues}
          onSubmit={(data: UpdateItemPayload) => actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.CHANGE_WP_APP_STAGING_NAME)
          )}
        />
      </SGDialogForm>
    );
  };

  renderReplicateDialog = () => {
    const { closeSGDialog, requestData, intl } = this.props;
    const app = this.state.stagingItemSelected;

    const initialValues: RequestDataPayload = {
      app_id: app && app.id,
      _metaFields: {
        endpoint: `${API_RESOURCE.APP_STAGING.endpoint}`,
        method: 'POST'
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.replicate.success.message'
          }
        }
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.REPLICATE_WP_APP_STAGING}
        icon="replicate"
        title={intl.formatMessage({ id: 'translate.page.staging.dialog.replicate.title' })}
        resources={[{
          resourceName: API_RESOURCE.APP_STAGING.resourceName,
          methods: ['POST']
        }]}
      >
        <ReplicateStaging
          initialValues={initialValues}
          onSubmit={(data) => {
            requestData(
              {
                payload: { ...data },
                requestTypeName: customRequestTypes.REQUEST_REPLICATE_STAGING
              });
            closeSGDialog(REDUX_FORM.REPLICATE_WP_APP_STAGING);
          }}
        />
      </SGDialogForm>
    );
  };

  renderConfirmDialogFooter = (dialogId: string, confirmLabel: string, onConfirm: Function) => (
    <React.Fragment>
      <SGDialogCancel id={dialogId} />
      <Button
        color="primary"
        onClick={() => {
          this.props.closeSGDialog(dialogId);
          onConfirm();
        }}
      >
        {confirmLabel}
      </Button>
    </React.Fragment>
  );
}

export default indexWithCRUD(
  (store) => ({
    wordpressApps: getWordpressApps(store),
    environment: store.environment,
    getStagingApps: (sourceAppId?) => getStagingApps(store, API_RESOURCE.APP.resourceName, sourceAppId)
  }),
  (dispatch) => ({
    requestUnknownFiles: (appId: number, onComplete: Function) =>
      dispatch(requestUnknownFiles(appId, onComplete)),
    requestCustomDeployStaging: (appId: number, onComplete: Function) =>
      dispatch(requestCustomDeployStaging(appId, onComplete)),
    requestData: (payload, onComplete?) => dispatch(requestData(payload, onComplete)),
    openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
    openNewTab: (payload) => dispatch(openNewTabAction(payload)),
    closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
  })
)(WpStaging, API_RESOURCE.APP);
