import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ContextMenu, ContextMenuItem, Icon, IconButton } from 'sg-styleguide';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { API_RESOURCE } from '../../../core/constants/api';
import { CONTEXT_MENU_ACTION } from './constants';

type Props = {
  app: WordpressApp;
  onContextMenuClick: (actionId: string, app: WordpressApp) => any;
  intl: Intl;
};

export const WpManageContextMenu = ({ onContextMenuClick, app, intl }: Props) => {
  const contextItems = [{
    label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.login.admin.panel' }),
    e2eAttr: 'wp-manage-context-menu-item-log-in-admin',
    icon: 'login',
    shortcut: <Icon color="dark" name="external-link" size="14" />,
    onClick: () => onContextMenuClick(CONTEXT_MENU_ACTION.LOG_IN_TO_ADMIN_PANEL, app)
  }, {
    vcsMethod: 'PUT',
    label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.update.admin.password' }),
    e2eAttr: 'wp-manage-context-menu-item-update-admin-password',
    icon: 'lock',
    onClick: () => onContextMenuClick(CONTEXT_MENU_ACTION.CHANGE_ADMIN_PASSWORD, app)
  }, {
    vcsMethod: 'PUT',
    label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.permissions' }),
    e2eAttr: 'wp-manage-context-menu-item-permissions',
    icon: 'key',
    onClick: () => onContextMenuClick(CONTEXT_MENU_ACTION.FIX_PERMISSIONS, app)
  }, {
    vcsMethod: 'PUT',
    label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.move' }),
    e2eAttr: 'wp-manage-context-menu-item-move',
    icon: 'move-to',
    onClick: () => onContextMenuClick(CONTEXT_MENU_ACTION.MOVE, app)
  }, {
    vcsMethod: 'DELETE',
    label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.delete' }),
    e2eAttr: 'wp-manage-context-menu-item-delete',
    icon: 'trash',
    onClick: () => onContextMenuClick(CONTEXT_MENU_ACTION.DELETE, app)
  }];

  return (
    <TableContextMenu entity={app} resourceName={API_RESOURCE.WORDPRESS.resourceName} items={contextItems} />
  );
};

export default injectIntl(WpManageContextMenu);
