import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon, Link, List, ListItem, Text } from 'sg-styleguide';
import { openNewTab } from 'sg-styleguide/lib/utils';
import { OpenNewTabPayload } from '../../../core/actions/open-new-tab';
import { MalwareReportEntry, NormalizedReport } from '../../../core/definitions/sg-sitescanner';

type Props = {
  intl?: Intl;
  report: NormalizedReport;
  icon: string;
  iconSize?: string;
  onMalwareDetails?: (malware: MalwareReportEntry[]) => any;
  openNewTab?: (payload: OpenNewTabPayload) => any;
};

export const WarningsList = ({ intl, report, icon, iconSize, onMalwareDetails, openNewTab }: Props) => {
  const reportTimeout = report.status === 'timeout';
  return (
    <List type="warning" padding={['small', 'none']}>
      {
        reportTimeout && (
          <ListItem icon={icon} iconSize={iconSize}>
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.report-timeout.label' })}
          </ListItem>
        )
      }
      {
        !reportTimeout && report && report.malware && report.malware.length > 0 && (
          <ListItem icon={icon} iconSize={iconSize}>
            {intl.formatMessage({
              id: 'translate.page.sg-sitescanner.malware-found.label'
            })} <Link
            onClick={() => onMalwareDetails(report.malware)}
            weight="bold">
            {intl.formatMessage({
              id: 'translate.page.sg-sitescanner.view-details.label'
            })} <Icon name="external-link" />
          </Link>
          </ListItem>
        )
      }
      {
        !reportTimeout && report && report.blacklist && (
          report.blacklist.map(({ details, url }) => (
            <ListItem icon={icon} iconSize={iconSize}>
              {details} <Link
              weight="bold"
              onClick={() => openNewTab({ url })}
            >
              {
                intl.formatMessage({
                  id: 'translate.page.sg-sitescanner.view-details.label'
                })
              } <Text color="light" tag="span">
              <Icon name="external-link" size="16" />
            </Text>
            </Link>
            </ListItem>
          ))
        )
      }
    </List>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTab(payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(
  injectIntl(WarningsList)
);
