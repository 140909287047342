import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import SelectDirectory from '../../../containers/dialogs/select-directory';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';

type Props = {
  domain: any[];
  intl: Intl;
  getFormValues: Function;
  change: Function;
  openSGDialog: Function;
};

const formName = REDUX_FORM.CHANGE_WP_MANAGE_MOVE_DIALOG;

const MoveInstallationForm = ({ change, domain, intl, getFormValues, openSGDialog }: Props) => {
  const formValues = getFormValues(formName);
  const selectedDomainPath = formValues && domain && domain.find((d) => d.id === formValues.domain_id);
  const initialPath = selectedDomainPath && selectedDomainPath.name && `/${selectedDomainPath.name}/public_html`;

  return (
    <Grid sm="2">
      <Field
        name="domain_id"
        label={intl.formatMessage({ id: 'translate.page.wp.manage.move.installation.select.domain' })}
        options={domain}
        optionValue="id"
        optionLabel="name"
        onChange={() => change('move_to', '')}
        component={FormDropdown}
      />

      <Field
        name="move_to"
        label={intl.formatMessage({ id: 'translate.page.wp.manage.move.installation.select.path' })}
        suffix={
          <Button
            action="button"
            color="dark"
            size="small"
            disabled={!selectedDomainPath}
            onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
          >
            {intl.formatMessage({ id: 'translate.generic.browse' })}
          </Button>
        }
        normalize={(path) => path.charAt(0) === '/' ? path : '/' + path}
        component={FormInput}
      />

      <SelectDirectory
        initialPath={initialPath}
        onChange={(path) => change('move_to', path.replace(initialPath, ''))}
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, any>(undefined, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: formName })(MoveInstallationForm)
    )
  )
);
