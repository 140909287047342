import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Accordion, Checkbox, Flex, FormLabel, Text, Tile } from 'sg-styleguide';
import { generatePathTruncationParts } from '../../../../core/utils/paths';
import { CustomDeployData, OptionsStruct } from '../types';
import { DataType, Selections } from './';

type OptionsSelectionsState = {
  fully: boolean;
  empty: boolean;
};

type Props = {
  options: CustomDeployData;
  onSelectionsChange: Function;
  intl: any;
};

type State = {
  selections: Selections,
  deletionAccepted: boolean;
  hasItemsToDelete: boolean;
};

const labelsMapping = {
  added: 'translate.page.staging.dialog.custom.deploy.label.to-be-added',
  changed: 'translate.page.staging.dialog.custom.deploy.label.to-be-updated',
  deleted: 'translate.page.staging.dialog.custom.deploy.label.to-be-deleted',
  dbs: 'translate.page.staging.dialog.custom.deploy.label.databases',
  files: 'translate.page.staging.dialog.custom.deploy.label.files-or-folders'
};

const getChildrenCount = ({ dbs = [], files = [] }: OptionsStruct): number =>
  dbs.length + files.length;

const isItemSelected = (selections: Selections, groupId, itemId: string) => Boolean(
  selections[groupId] &&
  selections[groupId][itemId] &&
  selections[groupId][itemId].state === true
);

const findSelectedCount = (items = [], selections: Selections = {}, groupId: string) =>
  items.filter((id) => isItemSelected(selections, groupId, id)).length;

const getAllChildren = (groupId: string, dataStruct: { [groupId: string]: OptionsStruct }) => {
  const output = {};
  Object.keys(dataStruct[groupId]).forEach((fieldType: string) => {
    dataStruct[groupId][fieldType].forEach((id) => output[id] = {
      state: true,
      dataType: fieldType
    });
  });
  return output;
};

export class CustomDeploySelector extends React.Component<Props, State> {
  readonly state: State = {
    selections: {},
    deletionAccepted: false,
    hasItemsToDelete: false
  };

  getOptionSelectionsState = (groupId): OptionsSelectionsState => {
    const allChildren = getAllChildren(groupId, this.props.options);
    const allChildrenCount = Object.keys(allChildren).length;
    const selectedChildrenCount = Object.keys(allChildren)
      .filter((itemId) => isItemSelected(this.state.selections, groupId, itemId)).length;

    return {
      fully: allChildrenCount === selectedChildrenCount,
      empty: selectedChildrenCount === 0
    };
  };

  toggleGroup = (groupId: string, state: boolean) => {
    const children = getAllChildren(groupId, this.props.options);

    this.setState({
      selections: {
        ...this.state.selections,
        [groupId]: Object.keys(children)
          .reduce((output, itemId) => ({
            ...output,
            [itemId]: {
              state,
              dataType: children[itemId].dataType
            }
          }), {})
      }
    }, this.onSelectionsUpdated);
  };

  toggleChild = (groupId: string, dataType: DataType, itemId: string, state: boolean) => this.setState({
    selections: {
      ...this.state.selections,
      [groupId]: {
        ...this.state.selections[groupId],
        [itemId]: {
          state,
          dataType
        }
      }
    }
  }, this.onSelectionsUpdated);

  onSelectionsUpdated = () => {
    const { deleted = {} } = this.props.options; // this.props.options;
    const itemsToDelete = Object.keys(deleted).reduce(
      (count: number, itemType: string) =>
        count + findSelectedCount(this.props.options.deleted[itemType], this.state.selections, 'deleted'),
      0);

    const hasItemsToDelete = itemsToDelete > 0;
    const areSelectionsValid = !hasItemsToDelete || this.state.deletionAccepted;
    this.setState({ hasItemsToDelete });
    this.props.onSelectionsChange(areSelectionsValid, this.state.selections);
  };

  render() {
    const { intl, options } = this.props;

    return (
      <React.Fragment>
        <Flex gutter="none" style={{ maxWidth: '100%' }}>
          <FormLabel>
            {intl.formatMessage({ id: 'translate.page.staging.dialog.custom.deploy.label' })}
          </FormLabel>
          <Tile
            padding={['x-small', 'medium']}
            style={{
              width: '100%',
              maxHeight: '380px',
              overflowY: 'auto'
            }}>
            {
              Object.keys(options).map((groupId) => {
                const childrenCount = getChildrenCount(this.props.options[groupId]);

                if (childrenCount === 0) {
                  return null;
                }

                return (
                  <Accordion
                    key={groupId}
                    padding="none"
                    title={() => (
                      <Flex margin="none" gutter="none">
                        <Text weight="extra-bold" color="darker">
                          {intl.formatMessage({ id: labelsMapping[groupId] })}
                        </Text>
                        <Text weight="extra-bold" color="light" style={{ marginLeft: '4px' }}>
                          ({childrenCount})
                        </Text>
                      </Flex>
                    )}
                    renderActions={() => {
                      const optionsSelectionsState = this.getOptionSelectionsState(groupId);
                      const checkboxIcon = (
                        optionsSelectionsState.fully ?
                          'check' :
                          !optionsSelectionsState.empty ?
                            'check-square' : undefined
                      );

                      return (
                        <Checkbox
                          checkIcon={checkboxIcon}
                          checked={!optionsSelectionsState.empty}
                          onChange={(e) => this.toggleGroup(groupId, e.target.checked)}
                        />
                      );
                    }}>
                    {
                      Object.keys(options[groupId]).map((itemsType) => {
                        const groupSelections = this.state.selections[groupId];

                        if (options[groupId][itemsType].length === 0) {
                          return null;
                        }

                        return (
                          <Flex margin="none" gutter="none" direction="column" key={`content-${itemsType}`}>
                            <Text transform="uppercase" align="left" size="small" weight="bold" color="lighter">
                              {intl.formatMessage({ id: labelsMapping[itemsType] })}
                            </Text>
                            {
                              options[groupId][itemsType].map((name = '') => {
                                const nameParts = generatePathTruncationParts(name, '.');
                                return (
                                  <Checkbox
                                    key={name}
                                    checked={isItemSelected(this.state.selections, groupId, name)}
                                    align="center"
                                    onChange={(e) =>
                                      this.toggleChild(groupId, itemsType as any, name, e.target.checked)
                                    }
                                  >
                                    <Text tag="span" align="left" truncate>
                                      {nameParts[0]} {nameParts[1]}
                                    </Text>
                                  </Checkbox>
                                );
                              })
                            }
                          </Flex>
                        );
                      })
                    }
                  </Accordion>
                );
              })
            }
          </Tile>
        </Flex>
        {
          this.state.hasItemsToDelete && (
            <Flex gutter="none" style={{ width: '100%' }}>
              <Checkbox
                checked={this.state.deletionAccepted}
                onChange={(e) =>
                  this.setState({ deletionAccepted: e.target.checked }, this.onSelectionsUpdated)
                }>
                <Text>
                  {intl.formatMessage({ id: 'translate.page.staging.dialog.custom.deploy.confirmation.label' })}
                </Text>
              </Checkbox>
            </Flex>
          )
        }
      </React.Fragment>
    );
  }
};

export default injectIntl(CustomDeploySelector);
