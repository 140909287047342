import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Column } from 'sg-styleguide';
import { injectIntl } from 'react-intl';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import FormInput from '../../../components/form-input';

type Props = {
  validationUtils: ValidationUtils;
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_WP_APP_STAGING_NAME;

export const Form = ({
  validationUtils,
  intl
}: Props) => (
  <Grid>
    <Column smSpan="12">
      <Field
        name="label"
        type="text"
        label={intl.formatMessage({ id: 'translate.page.staging.dialog.rename.input.label' })}
        component={FormInput}
        validate={[validationUtils.required]}
      />
    </Column>
  </Grid>
);

const RenameWpStaging = reduxForm({ form: formName })(Form);

export default withSpanelForm(
  injectIntl(RenameWpStaging)
);
