import * as React from 'react';
import { ContextMenu, ContextMenuItem, ContextMenuSeparator, IconButton } from 'sg-styleguide';
import './dynamic-toolbar.scss';

const { Fragment } = React;

class DynamicToolbar extends React.Component<any, any> {
  dynamicToolbarRef: any = {};
  dynamicToolbarContentRef: any = {};
  timeout: any;

  constructor(props) {
    super(props);

    this.state = {
      lastVisibleChildIndex: -1
    };

    this.setLastVisibleChildIndex = this.setLastVisibleChildIndex.bind(this);
    this.onResizeHandler = this.onResizeHandler.bind(this);
  }

  setLastVisibleChildIndex() {
    if (!this.dynamicToolbarContentRef) {
      return null;
    }

    const children = this.dynamicToolbarContentRef.children;
    const childrenClientRects = [];
    const dynamicToolbarClientRect = this.dynamicToolbarRef.getBoundingClientRect();

    for (const child of children) {
      childrenClientRects.push(child.getBoundingClientRect());
    }

    const lastVisibleChildIndex = childrenClientRects.findIndex((childClientRects) => (
      childClientRects.width + childClientRects.x > dynamicToolbarClientRect.width + dynamicToolbarClientRect.x
    ));

    if (lastVisibleChildIndex !== this.state.lastVisibleChildIndex) {
      this.setState({ lastVisibleChildIndex });
    }
  }

  onResizeHandler() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.setLastVisibleChildIndex, 300);
  }

  componentDidMount() {
    this.setLastVisibleChildIndex();

    window.addEventListener('resize', this.onResizeHandler);
  }

  componentWillUpdate() {
    this.setLastVisibleChildIndex();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeHandler);
  }

  createContextMenuItems(component, index) {
    const { disabled, icon, tooltip, onClick } = component.props;

    if (icon && tooltip && onClick) {
      return (
        <ContextMenuItem
          key={index}
          icon={icon}
          disabled={disabled}
          onClick={onClick}
        >
          {tooltip}
        </ContextMenuItem>
      );
    }

    return (
      <ContextMenuSeparator key={index} />
    );
  }

  render() {
    const { lastVisibleChildIndex } = this.state;
    const children = React.Children.toArray(this.props.children);
    const toolbarChildren = [];
    const contextMenuChildren = [];

    children.forEach((child, index) => {
      if (lastVisibleChildIndex === -1 || index < lastVisibleChildIndex) {
        return toolbarChildren.push(child);
      }

      contextMenuChildren.push(child);
    });

    return (
      <Fragment>
        <div
          className="file-manager-dynamic-toolbar"
          ref={(dynamicToolbar) => this.dynamicToolbarRef = dynamicToolbar}
          data-e2e="dynamic-toolbar"
        >
          <div
            className="file-manager-dynamic-toolbar__content"
            ref={(contentWrapper) => this.dynamicToolbarContentRef = contentWrapper}
          >
            {children.map((component: any, index) => {
              if (toolbarChildren[index]) {
                return component;
              }

              return React.cloneElement(component, { style: { visibility: 'hidden' } });
            })}
          </div>
        </div>

        {contextMenuChildren.length !== 0 && (
          <ContextMenu
            data-e2e="dynamic-toolbar-context-options"
            opener={<IconButton icon="dots" shape="circle" />}
          >
            {contextMenuChildren.map(this.createContextMenuItems)}
          </ContextMenu>
        )}
      </Fragment>
    );
  }
}

export default DynamicToolbar as any;
