/**
 * This conversion of the API is required because of backend changes
 *
 * New API - changes comming from the backend
 * Old API - the actual API we need in the frontend (we are using this interface in the pages)
 *
 * Notice (@Todo moving this one to the backend?):
 * These changes had to be done in the backend, since it's conceptually wrong frontend to deal with "location API"
 * (which is something there is no UI demand). Anyway we will quickly handle it as data transformer:
 *
 * Update:
 * After discussion with Niki we removed the location API so no need transformers any more
 * All data mutators in create and update sagas also has been removed
 *
 */

export const transformCountriesData = (data = []) =>
  data.reduce((output, country) => ({
    ...output,
    [country.code.toLowerCase()]: country
  }), {});
