import * as nemoStoreActions from '../constants/nemo-store';
import customRequestTypes from '../constants/custom-request-types';

export const retrieveDataFromNemo = (hash: string) => ({
  type: nemoStoreActions.RETRIEVE_DATA_FROM_NEMO_REQUESTED,
  hash
});

export const retrieveDataFromNemoSucceeded = (data: any) => ({
  type: nemoStoreActions.RETRIEVE_DATA_FROM_NEMO_SUCCEEDED,
  data
});

export const navigateToUA = (data: any) => ({
  type: nemoStoreActions.NAVIGATE_TO_UA,
  data,
  requestTypeName: customRequestTypes.NAVIGATING_TO_UA
});

export const storeDataInNemoSucceeded = (data: any) => ({
  type: nemoStoreActions.STORE_DATA_IN_NEMO_SUCCEEDED,
  data
});
