import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Column, Grid, Notice, Text } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import ROUTES from '../../../../core/constants/routes';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

const { resourceName, resourceNameMetaApi } = API_RESOURCE.MYSQL_DB_USER;
const formName = REDUX_FORM.CREATE_MYSQL_USERS;

type Props = {
  intl?: Intl;
  dbMysqlDb?: any[];
  location?: any;
  router?: any;
  onAddDatabaseToUser: Function;
  environment?: any;
  onSubmit: Function;
};

class Create extends React.Component<Props, void> {
  render() {
    const { intl, onAddDatabaseToUser, environment, onSubmit } = this.props;
    const { dbMysqlDb, router, location } = this.props;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.database_user.create.title' })}
          renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
            if (notification.state === 'error') {
              return renderDefaultNotificationTemplate();
            }

            return (
              <div style={{ textAlign: 'center' }}>
                {dbMysqlDb.length ? (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="add-to-database"
                    onClick={(event) => {
                      event.preventDefault();
                      onAddDatabaseToUser(notification.responseData);
                    }}
                  >
                    {intl.formatMessage({ id: 'translate.page.protected.user.manage.access' })}
                  </Button>
                ) : (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="add-to-database"
                    onClick={() => router.push(`${ROUTES.MYSQL_DATABASE}${location.search}`)}
                  >
                    {intl.formatMessage({ id: 'translate.page.database.create.button.database' })}
                  </Button>
                )}

                <MobileBreakElement />

                <Button
                  type="outlined"
                  color="secondary"
                  data-e2e="create-new"
                  onClick={removeNotification}
                >
                  {intl.formatMessage({ id: 'translate.page.database_user.create.new.user' })}
                </Button>
              </div>
            );
          }}
          resourceName={resourceName}
          formName={formName}
          resources={[{
            resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
            methods: ['POST']
          }]}
        >
          <Grid
            sm="12"
            padding={environment.isPhone ? 'medium' : 'large'}
            gap={environment.isPhone ? 'medium' : 'large'}
          >
            <Column smSpan="12">
              <Notice
                type="info"
                background="light"
                shadow={false}
              >
                <Text>{intl.formatMessage({ id: 'translate.page.database_user.create.notice.one.click' })}</Text>
              </Notice>
            </Column>

            <Column smSpan="12">
              <Button
                color="primary"
                action="button"
                data-e2e="create-box-submit"
                onClick={() => onSubmit({
                  _metaFields: {
                    ...API_RESOURCE.MYSQL_DB_USER
                  }
                })}
              >
                {intl.formatMessage({ id: 'translate.page.database_user.create.user' })}
              </Button>
            </Column>
          </Grid>
        </CreateBox>
      </VCS>
    );
  }
};

const mapStateToProps = (state) => ({
  environment: state.environment,
  dbMysqlDb: state.pageItems.dbMysqlDb || []
});

export default connect<{}, {}, Props>(mapStateToProps)(withRouter(injectIntl(Create)));
