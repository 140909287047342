import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { Label } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../../containers/dialogs';
import { SGDialogForm } from '../../../containers/sg-dialog';
import { FIREWALL_API_PROPS } from '../constants';
import { AddIPRange, FieldsForm } from './update';

type Props = {
  actions: CrudActions;
  children: any;
  dialog: any;
  items: any;
  intl: Intl;
  selectedZone: { id: number };
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: any;
  currentDeleteConformationDialogPayload: any;
};

class CloudflareIpAccessControl extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  renderAddIPDialog() {
    const { intl, dialog, closeSGDialog } = this.props;
    const addIPDialogPayload = dialog[REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG];

    return (
      <SGDialogForm
        name={REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.cloudflare.add.ip.range.title' })}
        resources={[{
          resourceName: API_RESOURCE.CLOUDFLARE_FIREWALL.resourceName,
          methods: ['POST']
        }]}
      >
        <AddIPRange
          initialValues={addIPDialogPayload}
          onSubmit={(data) => {
            const modifiedData = { ...data };

            modifiedData.fw_target = data.value.indexOf('/') > -1 ? FIREWALL_API_PROPS.RANGE : FIREWALL_API_PROPS.IP;

            this.props.actions.createItem(modifiedData, () => closeSGDialog(REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG));
          }}
        />
      </SGDialogForm>
    );
  }

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;

    return (
      <DeleteDialog
        title={this.props.intl.formatMessage({ id: 'translate.page.cloudflare.delete.ip.range.title' })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderUpdateDialog = () => {
    const { actions, intl, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;

    if (currentUpdatePayload === null) {
      return null;
    }

    return (
      <SGDialogForm
        name={REDUX_FORM.CLOUDFLARE_UPDATE_IP_RANGE_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.cloudflare.update.ip.range.title' })}
        resources={[{
          resourceName: API_RESOURCE.CLOUDFLARE_FIREWALL.resourceName,
          methods: ['PUT']
        }]}
      >
        <FieldsForm
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.CLOUDFLARE_UPDATE_IP_RANGE_DIALOG)
          )}
        />
      </SGDialogForm>
    );
  };

  renderStatusLabel = (status) => {
    const { intl } = this.props;
    const isBlocked = status === FIREWALL_API_PROPS.BLOCK;

    return (
      <Label
        type={isBlocked ? 'inactive-link' : 'active-link'}
        size="medium"
        padding={['inherit', 'inherit', 'inherit', 'none']}
      >
        {intl.formatMessage({
          id: isBlocked ?
            'translate.page.cloudflare.status.block' :
            'translate.page.cloudflare.status.whitelist'
        })}
      </Label>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const entityName = entity.value;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cloudflare.delete.ip.range.success.message'
          },
          error: {
            intlKey: 'translate.page.cloudflare.delete.ip.range.error.message'
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE_FIREWALL
      }
    };

    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE_FIREWALL
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cloudflare.update.ip.range.success.message'
          },
          error: {
            intlKey: 'translate.page.cloudflare.update.ip.range.error.message'
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.CLOUDFLARE_FIREWALL.resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          icon: 'edit',
          label: intl.formatMessage({ id: 'translate.generic.edit' }),
          e2eAttr: 'table-action-edit',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentUpdatePayload: updatePayload },
            () => openSGDialog(REDUX_FORM.CLOUDFLARE_UPDATE_IP_RANGE_DIALOG)
          )
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };

  render() {
    const { intl, items, selectedZone } = this.props;
    const firewallEntities = items[API_RESOURCE.CLOUDFLARE_FIREWALL.resourceName] || [];
    const filteredEntities = firewallEntities.filter((entity) => selectedZone && entity.zone_id === selectedZone.id);

    const columns = [{
      header: intl.formatMessage({ id: 'translate.page.cloudflare.ip.range' }),
      accessor: 'value'
    }, {
      header: intl.formatMessage({ id: 'translate.generic.status' }),
      accessor: 'fw_mode',
      render: this.renderStatusLabel
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderContextMenu
    }];

    return (
      <React.Fragment>
        <SGTable
          mobileLayout="card-flat"
          addOffsetOnMobile
          data={filteredEntities}
          columns={columns}
          shadow={false}
          resources={[{ resourceName: API_RESOURCE.CLOUDFLARE_FIREWALL.resourceName, methods: ['GET'] }]}
        />

        {this.renderAddIPDialog()}
        {this.renderDeleteConformationDialogComponent()}
        {this.renderUpdateDialog()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.pageItems,
  dialog: state.dialog
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  CloudflareIpAccessControl,
  API_RESOURCE.CLOUDFLARE_FIREWALL
);
