import { FILE_MANGER_API_FILE } from '../../web/containers/file-manager/core/constants/actions';
import { jsonResponse, formatRequestBody } from './utils';

type siteApi = () => Promise<Response>;
type siteApiParams = {
  endpoint: string,
  method: string
  body?: any
  state: any,
  urlParams?: any
  disableBodyStringify?: boolean
};

export const CustomError = function (object) {
  this.status = object.status;
  this.title = object.title;
  this.message = object.message;
  this.err_fields = object.err_fields || {};
};

const NewTasks = function (object) {
  this.status = object.status;
  this.data = object.data;
};

export function siteApi({
  endpoint,
  method = 'GET',
  body = {},
  state,
  urlParams = {},
  disableBodyStringify = false
}: siteApiParams): siteApi {
  const bodyToBeSend = disableBodyStringify ? body : formatRequestBody(body);
  const siteId: string = state.routing.locationBeforeTransitions.query.siteId;
  const siteToken: string = state.session.siteTokens[siteId].siteToken;
  const site: SiteItem = state.sites.items.find((siteObj: SiteItem) => siteObj.id === siteId && siteObj);
  const getParams: string = Object.keys(urlParams)
    .map((key) => `${key}=${ encodeURIComponent(urlParams[key])}&`)
    .join('');

  let newEndpoint = `https://${site.api_url}/api-sgcp/v00${endpoint}?${getParams}_site_token=${siteToken}`;

  // Bug when changing pages
  if (method === 'POST' && (window as any).timeout) {
    newEndpoint = `${newEndpoint}&_timeout=1&_wait_time=${(window as any).timeout.toString()}`;
  }

  return () => {
    let args: any = {
      headers: {
        Accept: 'application/json'
      },
      method
    };

    if (method !== 'GET') {
      args = {
        ...args,
        body: bodyToBeSend
      };
    }

    return fetch(newEndpoint, args)
      .catch((error) => {
        throw Error('An error occurred. Please try again later');
      })
      .then((response) => {
        if (endpoint === FILE_MANGER_API_FILE && response.status !== 400) {
          return response.text();
        }

        return jsonResponse(response);
      })
      .then((data) => {
        if (typeof data === 'string') {
          return data;
        }

        const json = data;

        switch (json.status) {
          case 200:
            return json;
          case 201:
            return json;
          case 202:
            throw new NewTasks(json);
          case 401:
            throw new CustomError(json);
          default:
            throw new CustomError(json);
        }
      });
  };
}
