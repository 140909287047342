export const WORDPRESS = 'WORDPRESS';
export const WORDPRESS_WOOCOMMERCE = 'WORDPRESS_WOOCOMMERCE';

export const CONTEXT_MENU_ACTION = {
  LOG_IN_TO_ADMIN_PANEL: 'LOG_IN_TO_ADMIN_PANEL',
  CHANGE_ADMIN_PASSWORD: 'CHANGE_ADMIN_PASSWORD',
  SECURE_ADMIN_PANEL: 'SECURE_ADMIN_PANEL',
  FIX_PERMISSIONS: 'FIX_PERMISSIONS',
  MOVE: 'MOVE',
  DELETE: 'DELETE'
};
