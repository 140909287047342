import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column, Notice } from 'sg-styleguide';
import { textToHTML } from 'sg-styleguide/lib/utils';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormFieldWrapper from '../../../../components/form-field-wrapper';
import FormInput from '../../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../../containers/sg-form';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
  availableSlots: number;
};

const formName = REDUX_FORM.CREATE_BACKUP;

const Form = ({ availableSlots, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      {availableSlots > 0 ? (
        <React.Fragment>
          <Column smSpan="6">
            <Field
              name="instant_label"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.backup.restore.create.instant.label' })}
              placeholder={intl.formatMessage({ id: 'translate.page.backup.restore.create.instant.label.placeholder' })}
              validate={[required, validationWithMetaApi]}
              component={FormInput}
            />
          </Column>

          <Column smSpan="6">
            <FormFieldWrapper
              label={intl.formatMessage({ id: 'translate.page.backup.restore.create.available.backups.label' })}
            >
              {availableSlots}
            </FormFieldWrapper>
          </Column>

          <Column smSpan="12">
            <SGSubmitButton formName={formName} />
          </Column>
        </React.Fragment>
      ) : (
        <Column smSpan="12">
          <Notice
            type="info"
            background="light"
            shadow={false}
            title={intl.formatMessage({ id: 'translate.page.backup.restore.create.not.available.notice.title' })}
          >
            {textToHTML(intl.formatMessage({ id: 'translate.page.backup.restore.create.not.available.notice.text' }))}
          </Notice>
        </Column>
      )}
    </SGForm>
  );
};

const BackupForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.BACKUP,
      formName
    }
  }
})(Form);

export default withSpanelForm(
  injectIntl(BackupForm)
);
