import * as React from 'react';
import { Grid, Text, GridColumns } from 'sg-styleguide';
import BackupPlanCard from '../../backup-plan-card';
import { BuyBackupTypes } from '../../../../../core/constants/common';

type Props = {
  backupOrderData: BackupOrderData;
  selectedType: BuyBackupTypes;
  onSelection: (type: BuyBackupTypes, state: boolean) => void;
  intl: Intl;
};

export const BuyBackup = ({ selectedType, onSelection, intl, backupOrderData }: Props) => {
  const { upgrade_plan, single_backup_price } = backupOrderData;
  const cycle = upgrade_plan && Object.keys(upgrade_plan)[0];
  const upgradePlanPaymentData = upgrade_plan && Object.values(upgrade_plan.price)[0];
  const upgradeDataAvailable = Boolean(upgrade_plan && cycle && upgradePlanPaymentData);

  const planCards = [single_backup_price, upgrade_plan].filter((value) => value !== undefined);
  const features = upgrade_plan && upgrade_plan.features.map(({ text }) => text) || [];

  return (
    <React.Fragment>
      <Text weight="bold">
        {intl.formatMessage({ id: 'translate.page.backup.restore.buy.backups.text' })}
      </Text>
      <br />
      <Grid sm={String(planCards.length) as GridColumns} gap="x-large">
        <BackupPlanCard
          name={BuyBackupTypes.singleBackup}
          icon="presentational-single-backup"
          multicolorIcon
          title={intl.formatMessage({ id: 'translate.page.backup.buy-backup.single-backup.title' })}
          subtitle={intl.formatMessage({ id: 'translate.page.backup.buy-backup.single-backup.info-text' })}
          singlePrice={single_backup_price}
          isSelected={selectedType === BuyBackupTypes.singleBackup}
          onSelect={(state: boolean) => onSelection(BuyBackupTypes.singleBackup, state)}
        />
        {
          upgradeDataAvailable && (
            <BackupPlanCard
              name={BuyBackupTypes.growBig}
              icon="product-crown"
              title={upgrade_plan.description}
              subtitle={upgrade_plan.subtitle}
              label={intl.formatMessage({ id: 'translate.generic.recommended' })}
              priceData={upgradePlanPaymentData}
              features={features}
              isSelected={selectedType === BuyBackupTypes.growBig}
              onSelect={(state: boolean) => onSelection(BuyBackupTypes.growBig, state)}
            />
          )
        }
      </Grid>
    </React.Fragment>
  );
};

export default BuyBackup;
