import * as React from 'react';
import { Box, Flex, Grid, Title } from 'sg-styleguide';
import SGTable from '../../../components/sg-table';
import PieChartStatistics from './pie-charts';
import { Browser, Os, StatsType } from '../../../../core/definitions/stats';
import { sortByValue, getStatsParameterSum } from '../../../../core/utils/stats-format';

type Props = {
  environment: {
    isPhone: boolean;
    isTablet: boolean;
  };
  getStats: (statsKey: StatsType) => any;
  statsMissing: boolean;
  renderDatepicker: Function;
  intl: Intl;
};

const appendChartLegendProps = (data = [], legendLabels) => data.map((item) => {
  const total = data.map((d) => d.hits).reduce((acc, value) => acc + value, 0);
  const percentage = (item.hits / total * 100).toFixed(2);

  return {
    ...item,
    total,
    checked: true,
    osLegendLabel: `${item.id} <em>(${item.hits})</em>`,
    browserLegendLabel: `${item.browser} <em>(${item.hits})</em>`
  };
});

const createChartArray = (data, key, legendLabels = {}) =>
  sortByValue(
    appendChartLegendProps(data, legendLabels),
    key
  );

class Technology extends React.Component<Props> {
  render() {
    const { getStats, renderDatepicker, intl, environment, statsMissing } = this.props;
    const legendLabels = {
      hits: intl.formatMessage({ id: 'translate.page.stats.hits.label' })
    };

    const osStats = createChartArray(getStats('os'), 'hits', legendLabels);
    const browserStats = createChartArray(getStats('ua'), 'hits', legendLabels);

    return (
      <Grid gap="x-large">
        <Flex gutter="none">
          <Box sm="3">
            {renderDatepicker()}
          </Box>
        </Flex>
        {
          statsMissing ? (
            <SGTable data={[]} shadow={false} noDataMessage="translate.page.stats.no-stats.placeholder.message" />
          ) : (
            <Grid gap="small" sm="2">
              <PieChartStatistics stats={osStats} type="os" environment={environment} />
              <PieChartStatistics stats={browserStats} type="browser" environment={environment} />
            </Grid>
          )
        }
      </Grid>
    );
  }
}

export default Technology;
