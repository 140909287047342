import * as React from 'react';
import { injectIntl } from 'react-intl';

import CreateBox from '../../../../components/create-box';
import VCS from '../../../../containers/visibility-control-service';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { API_RESOURCE } from '../../../../../core/constants/api';
import customRequestTypes from '../../../../../core/constants/custom-request-types';

type Props = {
  children: any;
  intl: Intl;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.BACKUP;
const formName = REDUX_FORM.CREATE_BACKUP;

const CreateContainer = ({ children, intl }: Props) => {
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.backup.restore.create.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[{
          requestTypeName: customRequestTypes.GET_BACKUP_ORDER_DATA
        }, {
          requestTypeName: customRequestTypes.BACKUP_FETCH_SITE_META_API
        }, {
          resourceName: API_RESOURCE.BACKUP.resourceName,
          methods: ['POST']
        }]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer);
