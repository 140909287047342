const dow = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];

const indexToMonthFallback = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// 7 and 0 have to be redundant don't delete
const indexToDowFallback = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

// min 0 - 59
const minRx = /^(0?\d|[1-5]\d)$/;
// hour 0 - 23
const hourRx = /^(0?\d|1\d|2[0-3])$/;
// day 1-31
const dayRx = /^([1-9]|0[1-9]|[1-2]\d|30|31)$/;
// month 1-12tO
const monthRx = /^(0?[1-9]|10|11|12)$/;
// dow 0-6 | words
const dowRx = /^(0?[0-7])$/;

// 1,2,3-4,5,n
const listWithRangeRx = /^\d+((\,\d+(\-\d+)?)|(\-\d+(\,\d+)?))+$/;
// 123/123
const numberOverNumberRx = /^\d+(\,\d+(\-\d+)?)+$/;
// 1-2
const rangeRx = /^\d+\-\d+$/;
// */123
const onEveryXSomethingRx = /^\*\/\d+$/;
// *
const onEveryRx = /^\*$/;

export const intervalsFallback = [
  {
    id: 1,
    name: 'Twice Per Hour (0,30 * * * *)',
    value: ['0,30', '*', '*', '*', '*']
  },
  {
    id: 2,
    name: 'Once Per Hour (0 * * * *)',
    value: ['0', '*', '*', '*', '*']
  },
  {
    id: 3,
    name: 'Twice Per Day (0 0,12 * * *)',
    value: ['0', '0,12', '*', '*', '*']
  },
  {
    id: 4,
    name: 'Once Per Day (0 0 * * *)',
    value: ['0', '0', '*', '*', '*']
  },
  {
    id: 5,
    name: 'Once Per Week (0 0 * * 0)',
    value: ['0', '0', '*', '*', '0']
  },
  {
    id: 6,
    name: 'On the 1st and the 15th of the Month (0 0 1,15 * *)',
    value: ['0', '0', '1,15', '*', '*']
  },
  {
    id: 7,
    name: 'Once Per Month (0 0 1 * *)',
    value: ['0', '0', '1', '*', '*']
  },
  {
    id: 8,
    name: 'Once Per Year (0 0 1 1 *)',
    value: ['0', '0', '1', '1', '*']
  },
  {
    id: 0,
    name: 'Select Manually',
    value: []
  }
];

export const originalColor = '#b0b0b0';

// TODO: REMOVE after update form refactor
export const getOriginalStyles = () => {
  return {
    minStyle: { color: originalColor },
    hourStyle: { color: originalColor },
    dayStyle: { color: originalColor },
    monStyle: { color: originalColor },
    dowStyle: { color: originalColor }
  };
};

export const translations = (intl) => {
  return {
    cron2text: (textId, params = {}) => intl.formatMessage(
      { id: `translate.page.cron.cron2text.${textId}` }, params
    ),
    indexToDow: [
      intl.formatMessage({ id: 'translate.generic.day.Sunday' }),
      intl.formatMessage({ id: 'translate.generic.day.Monday' }),
      intl.formatMessage({ id: 'translate.generic.day.Tuesday' }),
      intl.formatMessage({ id: 'translate.generic.day.Wednesday' }),
      intl.formatMessage({ id: 'translate.generic.day.Thursday' }),
      intl.formatMessage({ id: 'translate.generic.day.Friday' }),
      intl.formatMessage({ id: 'translate.generic.day.Saturday' }),
      intl.formatMessage({ id: 'translate.generic.day.Sunday' })
    ],
    indexToMonth: [
      '',
      intl.formatMessage({ id: 'translate.generic.months.January' }),
      intl.formatMessage({ id: 'translate.generic.months.February' }),
      intl.formatMessage({ id: 'translate.generic.months.March' }),
      intl.formatMessage({ id: 'translate.generic.months.April' }),
      intl.formatMessage({ id: 'translate.generic.months.May' }),
      intl.formatMessage({ id: 'translate.generic.months.June' }),
      intl.formatMessage({ id: 'translate.generic.months.July' }),
      intl.formatMessage({ id: 'translate.generic.months.August' }),
      intl.formatMessage({ id: 'translate.generic.months.September' }),
      intl.formatMessage({ id: 'translate.generic.months.October' }),
      intl.formatMessage({ id: 'translate.generic.months.November' }),
      intl.formatMessage({ id: 'translate.generic.months.December' })
    ],
    intervals: [
      {
        id: 1,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Twice_Per_Hour' }) + ' (0,30 * * * *)',
        value: ['0,30', '*', '*', '*', '*']
      },
      {
        id: 2,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Once_Per_Hour' }) + ' (0 * * * *)',
        value: ['0', '*', '*', '*', '*']
      },
      {
        id: 3,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Twice_Per_Day' }) + ' (0 0,12 * * *)',
        value: ['0', '0,12', '*', '*', '*']
      },
      {
        id: 4,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Once_Per_Day' }) + ' (0 0 * * *)',
        value: ['0', '0', '*', '*', '*']
      },
      {
        id: 5,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Once_Per_Week' }) + ' (0 0 * * 0)',
        value: ['0', '0', '*', '*', '0']
      },
      {
        id: 6,
        name: intl.formatMessage(
          { id: 'translate.page.cron.interval.On_the_1st_and_the_15th_of_the_Month' }
          ) + ' (0 0 1,15 * *)',
        value: ['0', '0', '1,15', '*', '*']
      },
      {
        id: 7,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Once_Per_Month' }) + ' (0 0 1 * *)',
        value: ['0', '0', '1', '*', '*']
      },
      {
        id: 8,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Once_Per_Year' }) + ' (0 0 1 1 *)',
        value: ['0', '0', '1', '1', '*']
      },
      {
        id: 0,
        name: intl.formatMessage({ id: 'translate.page.cron.interval.Select_Manually' }),
        value: []
      }
    ]
  };
};

export const intervalInputOnChange = (
  value = '',
  updateFieldsCall: Function | null = null,
  intl: Intl = null
) => {
  const parser = parseInterval(value.split(' '));
  const indexToStyle = ['minStyle', 'hourStyle', 'dayStyle', 'monStyle', 'dowStyle'];
  const colorStyles = {};
  const intervalsIntl = intl !== null ? translations(intl).intervals : intervalsFallback;

  let inputState = '';
  let validationMessage = '';
  let succesfullFields = 0;
  if (parser.intervaParts) {
    parser.intervaParts.forEach((p, i) => {
      if (p.isValid === undefined) {
        colorStyles[indexToStyle[i]] = { color: originalColor };
      } else {
        if (p.isValid === false) {
          inputState = 'error';
        } else {
          succesfullFields = succesfullFields + 1;
        }
        colorStyles[indexToStyle[i]] = { color: p.isValid ? 'green' : 'red' };
      }
    });
  }

  if (inputState !== 'error' && succesfullFields < 5 && succesfullFields > 0) {
    inputState = 'error';
  }

  if (succesfullFields === 5) {
    inputState = 'hint';
    validationMessage = toSentence(value.split(' '), intl);
  }

  const predefinedInterval = intervalsIntl.find(
    (interval) => JSON.stringify(interval.value) === JSON.stringify(parser.intervalArray)
  );

  if (updateFieldsCall !== null) {
    updateFieldsCall(parser.intervalArray);
  }

  return {
    intervalInputValue: value.split(' ').slice(0, 5).join(' '),
    inputState,
    validationMessage,
    ...colorStyles,
    selectedIntervalId: predefinedInterval ? predefinedInterval.id : 0
  };
};

export const looksLikeInterval = (cmdParts) => {
  if (cmdParts.length < 6) {
    return false;
  }

  /* tslint:disable */
  const looksLikeIntervalRx = /^\s*([0-9\,\-\*\?\/LW\#]|MON|TUE|WED|THU|FRI|SAT|SUN|JAN|FEB|MAR|APR|MAY|JUNE|JULY|AUG|SEPT|OCT|NOV|DEC)+\s*$/;
  /* tslint:enable */
  let partsFound = 0;
  cmdParts.slice(0, 5).forEach((p, i) => {
    if (looksLikeIntervalRx.test(p)) {
      partsFound = partsFound + 1;
    }
  });

  // allow one invalid interval component
  return partsFound >= 5;
};

const numToWord = (num) => {
  const n = parseInt(num.replace(/\D/g, ''), 10);

  if (n === 1) {
    return '';
  } else if (n === 2) {
    return '2nd';
  } else if (n === 3) {
    return '3rd';
  } else {
    return n + 'th';
  }

};

const parseEveryX = (str) => {
  return parseInt(str.replace(/\D/g, ''), 10);
};

function ucf(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toSentence = (parts, intl: Intl = null) => {
  const indexToDowIntl = intl !== null ? translations(intl).indexToDow : indexToDowFallback;
  const indexToMonthIntl = intl !== null ? translations(intl).indexToMonth : indexToMonthFallback;
  const t = intl !== null ? translations(intl).cron2text : (text) => text;
  const pad = (num) => {
    let s = num + '';
    if (s.length < 2) {
      s = '0' + s;
    }
    return s;
  };

  function minutes(minutes) {
    return minutes === '1' ? t('minute') : t('minutes');
  }

  function minutesBetween(hour1, hour2) {
    return `${t('between')} ${pad(hour1)}:00 ${t('and')} ${pad(hour2)}:59`;
  }

  function dayOfTheMonth(day1, day2 = null) {
    return day2 === null
      ? `${t('day')} ${day1} ${t('of the month')}`
      : `${t('day')} ${day1} ${t('through')} ${day2} ${t('of the month')}`;
  }

  function everyXdays(day) {
    return `${t('every')} ${day} ${t('days')}`;
  }

  let sentence = '';

  if (minRx.test(parts[0]) && hourRx.test(parts[1])) {
    // 1 2 ==> 02:01
    sentence += t('At') + ' ' + pad(parts[1]) + ':' + pad(parts[0]);
  } else if (minRx.test(parts[0]) && !hourRx.test(parts[1])) {
    sentence += `${t('At')} ${parts[0]} ${minutes(parts[0])} ${t('past the hour')}`;
  }

  /**
   * 1 Minutes
   */
  if (onEveryRx.test(parts[0])) {
    // *
    sentence += ucf(t('every')) + ' ' + t('minute');
  } else if (onEveryXSomethingRx.test(parts[0])) {
    // */2
    sentence += `${ucf(t('every'))} ${parseEveryX(parts[0])} ${minutes(parts[0])}`;
  } else if (rangeRx.test(parts[0])) {
    // 1-2
    sentence += ucf(t('minutes')) + ' ' + parts[0].split('-')[0] + ' ' + t('through') +
      ' ' + parts[0].split('-')[1] + ' ' + t('past the hour');
  } else if (listWithRangeRx.test(parts[0])) {
    // 1,2,3,4
    parts[0].split(',').forEach((part, i) => {
      const isLast = i === parts[0].split(',').length - 1;
      const isFirst = i === 0;
      const suffix = isLast ? ' ' + t('past the hour') : ',';
      const prefix = isLast ? `${t('and')} ` : '';
      sentence += isFirst ? `${t('At')} ${t('minutes')}` : '';

      if (minRx.test(part)) {
        sentence += ` ${prefix}${part}${suffix}`;
      } else if (rangeRx.test(part)) {
        sentence += ` ${prefix}${part.split('-')[0]} ${t('through')} ${part.split('-')[1]}${suffix}`;
      }
    });
  }

  /**
   * 2 Hours
   */
  if (!minRx.test(parts[0]) && hourRx.test(parts[1])) {
    // * 2
    sentence += `, ${minutesBetween(parts[1], parts[1])}`;
  } else if (onEveryXSomethingRx.test(parts[1])) {
    // */2
    sentence += `, ${t('of every')} ${numToWord(parts[1])} ${t('hour')}`;
  } else if (rangeRx.test(parts[1])) {
    // parts[1].split('-')[0]
    // 1-2
    sentence += `, ${minutesBetween(parts[1].split('-')[0], parts[1].split('-')[1])}`;
  } else if (listWithRangeRx.test(parts[1])) {
    sentence += ',';
    // 1,2,3,4
    parts[1].split(',').forEach((part, i) => {
      const isLast = i === parts[1].split(',').length - 1;
      const isFirst = i === 0;
      const suffix = isLast ? '' : ',';
      const prefix = isLast ? `${t('and')} ` : '';

      if (minRx.test(part)) {
        sentence += ` ${prefix}${minutesBetween(part, part)}${suffix}`;
      } else if (rangeRx.test(part)) {
        sentence += ` ${prefix}${minutesBetween(part.split('-')[0], part.split('-')[1])}${suffix}`;
      }
    });
  }

  /**
   * 3 Day
   */
  if (dayRx.test(parts[2])) {
    // 1 *
    sentence += `, ${t('on')} ${dayOfTheMonth(parts[2])}`;
  } else if (onEveryXSomethingRx.test(parts[2])) {
    // */2
    sentence += `, ${everyXdays(parseEveryX(parts[2]))}`;
  } else if (rangeRx.test(parts[2])) {
    // 1-2
    sentence += `, ${t('on')} ${dayOfTheMonth(parts[2].split('-')[0], parts[2].split('-')[1])}`;
  } else if (listWithRangeRx.test(parts[2])) {
    // 1,2,3,4
    parts[2].split(',').forEach((part, i) => {
      const isLast = i === parts[2].split(',').length - 1;
      const isFirst = i === 0;
      const suffix = isLast ? '' : ',';
      const prefix = isLast ? `${t('and')} ` : '';

      sentence += isFirst ? `, ${t('on')} ${t('day')}` : '';

      if (minRx.test(part)) {
        sentence += ` ${prefix}${part}${suffix}`;
      } else if (rangeRx.test(part)) {
        sentence += ` ${part.split('-')[0]} ${t('through')} ${part.split('-')[1]}${suffix}`;
      }
      sentence += isLast ? ` ${t('of the month')}` : '';
    });
  }

  /**
   * 5 Week Day
   */
  if (dowRx.test(parts[4])) {
    // sentence += dayRx.test(parts[2]) ? t('and') + ' ' : '';
    sentence += `, ${t('only')} ${t('on')} ${indexToDowIntl[parts[4]]}`;
  } else if (onEveryXSomethingRx.test(parts[4])) {
    // */2
    sentence += `, ${everyXdays(parseEveryX(parts[4]))} ${t('of the week')}`;
  } else if (rangeRx.test(parts[4])) {
    // 1-2
    sentence += `, ${t('only')} ${t('on')} ${indexToDowIntl[parts[4].split('-')[0]]} ` +
      `${t('through')} ${indexToDowIntl[parts[4].split('-')[1]]}`;
  } else if (listWithRangeRx.test(parts[4])) {
    // 1,2,3,4
    parts[4].split(',').forEach((part, i) => {
      const isLast = i === parts[4].split(',').length - 1;
      const isFirst =  i === 0;
      const suffix = isLast ? '' : ',';
      const prefix = isLast ? `${t('and')} ` : '';

      sentence += isFirst ? `, ${t('only')} ${t('on')}` : '';
      if (minRx.test(part)) {
        sentence += ` ${prefix}${indexToDowIntl[part]}${suffix}`;
      } else if (rangeRx.test(part)) {
        const day1 = indexToDowIntl[part.split('-')[0]];
        const day2 = indexToDowIntl[part.split('-')[1]];
        sentence += ` ${prefix}${day1} ${t('through')} ${day2}${suffix}`;
      }
    });
  }

  /**
   * 4 Month
   */
  if (monthRx.test(parts[3])) {
    sentence += `, ${t('only')} ${t('in')} ${indexToMonthIntl[parts[3]]}`;
  } else if (onEveryXSomethingRx.test(parts[3])) {
    // */2
    sentence += `, ${t('every')} ${parseEveryX(parts[3])} ${t('months')}`;
  } else if (rangeRx.test(parts[3])) {
    // 1-2
    const month1 = indexToMonthIntl[parts[3].split('-')[0]];
    const month2 = indexToMonthIntl[parts[3].split('-')[1]];
    sentence += `, ${month1} ${t('through')} ${month2}`;
  } else if (listWithRangeRx.test(parts[3])) {
    // 1,2,3,4
    parts[3].split(',').forEach((part, i) => {
      const isLast = i === parts[3].split(',').length - 1;
      const isFirst = i === 0;
      const prefix = isLast ? `${t('and')} ` : '';
      const suffix = isLast ? '' : ',';

      sentence += isFirst ? `, ${t('only')} ${t('in')}` : '';
      if (minRx.test(part)) {
        sentence += ` ${prefix}${indexToMonthIntl[part]}${suffix}`;
      } else if (rangeRx.test(part)) {
        sentence += ` ${prefix}${indexToMonthIntl[part.split('-')[0]]}` +
          ` ${t('through')} ${indexToMonthIntl[part.split('-')[1]]}${suffix}`;
      }
    });
  }

  return sentence;
};

function isInvalidRange(part, min, max) {
  const listOfParts = listWithRangeRx.test(part) ? part.split(',') : [part];

  for (const rowPart of listOfParts) {
    const parts = rowPart.split('-');

    if (Array.isArray(parts) && parts.length === 2 &&
      parts[0] === Number(parts[0]).toString() &&
      parts[1] === Number(parts[1]).toString() &&
      ((Number(parts[0]) < min) || (Number(parts[1]) > max) || Number(parts[0]) > Number(parts[1]))) {
      return true;
    }
  }

  return false;
}

export const parseInterval = (parts: string[]) => {
  const intervalString = parts.slice(0, 5).join(' ');
  const iParts = [{}, {}, {}, {}, {}] as any;
  let isValid = true;
  let isInvalid = false;

  // Regex hell goes here
  parts.slice(0, 5).forEach((part, i) => {
    let isValidPart = true;
    if (i === 0) {
      // min
      isValidPart = minRx.test(part);
      isInvalid = isInvalidRange(part, 0, 60);
    } else if (i === 1) {
      // hour
      isValidPart = hourRx.test(part);
      isInvalid = isInvalidRange(part, 0, 23);
    } else if (i === 2) {
      // day of month
      isValidPart = dayRx.test(part);
      isInvalid = isInvalidRange(part, 1, 31);
    } else if (i === 3) {
      // month
      isValidPart = (monthRx.test(part) || months.includes(part));
      isInvalid = isInvalidRange(part, 1, 12);
    } else if (i === 4) {
      // day of week
      isValidPart = (dowRx.test(part) || dow.includes(part));
      isInvalid = isInvalidRange(part, 0, 7);
    }

    // lists and ranges
    isValidPart = !isInvalid &&
      (
        isValidPart ||
      listWithRangeRx.test(part) ||
      numberOverNumberRx.test(part) ||
      onEveryXSomethingRx.test(part) ||
      onEveryRx.test(part) ||
        rangeRx.test(part)
      );

    if (!isValidPart) {
      isValid = false;
    }

    if (part !== '') {
      iParts[i].isValid = isValidPart;
    }
  });

  const parsedCommand = isValid ? parts.slice(5).join(' ') : '';

  return {
    intervaParts: iParts,
    intervalString,
    intervalArray: parts.slice(0, 5),
    intervalStatus: isValid ? 'valid' : 'invalid',
    parsedCommand
  };
};

export const parseCommandWithInterval = (cmd) => {
  const res = {
    intervalStatus: 'none',
    intervalString: null,
    intervalArray: [],
    parsedCommand: null
  };

  if (cmd === undefined) {
    return res;
  }

  const stripedCommand = cmd.trim();
  res.parsedCommand = stripedCommand;

  const commandParts = stripedCommand.split(' ');

  if (!looksLikeInterval(commandParts)) {
    return res;
  }

  return parseInterval(commandParts);
};
