import { createAction } from 'redux-actions';

import {
  FETCH_PENDING_TASKS_REQUESTED,
  SUBSCRIBE_FOR_TASK_COMPLETION,
  REQUEST_STARTED_BACKGROUND_TASK
} from '../constants/actions';

export const fetchPendingTasksRequested = createAction(FETCH_PENDING_TASKS_REQUESTED);

export const requestStartedBackgroundTask = (action) => ({
  type: REQUEST_STARTED_BACKGROUND_TASK,
  payload: { action }
});

export const subscribeForTaskCompletion = (
  taskId: string,
  onComplete: (response, task?: Task) => any,
  onFailure: (exception, task?: Task) => any
) => ({
  type: SUBSCRIBE_FOR_TASK_COMPLETION,
  taskId,
  onComplete,
  onFailure
});
