import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, FormLabel, Grid, Icon, Text } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import './email-directory-select.scss';

class DirectorySelect extends React.Component<any, any> {
  readonly state = {
    directories: [],
    selectedEmailId: null
  };

  getEmailFolders = (itemId) => {
    this.props.actions.fetchItem({
      itemId,
      ...API_RESOURCE.EMAIL_FOLDER
    });
  };

  componentDidMount() {
    const { selectedFilterEntity } = this.props;
    const isUser = Boolean(selectedFilterEntity.domain_id);

    if (isUser) {
      this.getEmailFolders(selectedFilterEntity.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedFilterEntity } = this.props;

    const directories = nextProps.emailFolder.find(({ id }) => {
      const domainId = selectedFilterEntity.domain_id;

      return domainId ? id === selectedFilterEntity.id : id === this.state.selectedEmailId;
    });

    if (directories) {
      this.createTreeData(directories.folders);
    }
  }

  createTreeData(data) {
    if (!data) {
      return;
    }

    const directories = data.reduce((prev, current) => {
      const valueToModified = { ...prev };
      const slittedPath = current.split('/');
      const dirName = slittedPath.pop();
      const parentPath = slittedPath.join('/') || '/';

      if (!valueToModified[current]) {
        valueToModified[current] = {
          _meta: {
            name: dirName,
            path: current,
            isExpanded: current === 'INBOX',
            isSelected: current === 'INBOX'
          },
          children: []
        };
      }

      if (!valueToModified[parentPath]) {
        valueToModified[parentPath] = {
          _meta: {
            name: dirName,
            path: parentPath,
            isExpanded: true
          },
          children: []
        };
      }

      valueToModified[parentPath].children.push(current);

      return valueToModified;
    }, {});

    this.setState({ directories });
  }

  handleFolderSelection(path) {
    const newDirectories = { ...this.state.directories };

    Object.keys(newDirectories).forEach((key) => newDirectories[key]._meta.isSelected = false);

    newDirectories[path]._meta.isExpanded = true;
    newDirectories[path]._meta.isSelected = !newDirectories[path]._meta.isSelected;

    this.setState({ directories: newDirectories });
  }

  handleExpandCollapse(path) {
    const newDirectories = { ...this.state.directories };
    newDirectories[path]._meta.isExpanded = !newDirectories[path]._meta.isExpanded;

    this.setState({ directories: newDirectories });
  }

  renderTree() {
    const { intl } = this.props;
    const { directories } = this.state;

    if (directories.length === 0) {
      return null;
    }

    return (
      <div>
        <FormLabel>
          {intl.formatMessage({ id: 'translate.generic.select.folder' })}
        </FormLabel>

        <div className="directory-select">
          {directories['/'].children.map((dir) => this.renderDirectory(dir))}
        </div>
      </div>
    );
  }

  renderDirectory = (dir, level = 0) => {
    const { directories } = this.state;
    const { onSelection } = this.props;
    const currentDir = directories[dir];
    const currentDirChildren = currentDir && currentDir.children || [];
    const hasChildren = currentDirChildren.length !== 0;
    const isExpanded = currentDir._meta.isExpanded;
    const isSelected = currentDir._meta.isSelected;

    const folderIconClasses = [
      'directory-select-item__label-icon',
      !hasChildren && 'directory-select-item__label-icon--empty',
      isSelected && 'directory-select-item__label-icon--selected'
    ].filter(Boolean).join(' ');

    return (
      <ul key={dir}>
        <li className="directory-select-item" style={{ paddingLeft: `${20 * level}px` }}>
          {hasChildren && (
            <Icon
              className="directory-select-item__arrow-icon"
              name={isExpanded ? 'arrow-down' : 'arrow-right'}
              size="10"
              onClick={() => this.handleExpandCollapse(currentDir._meta.path)}
            />
          )}

          <span
            className="directory-select-item__label"
            onClick={() => {
              this.handleFolderSelection(currentDir._meta.path);
              onSelection(currentDir._meta.path);
            }}
          >
            <Icon
              className={folderIconClasses}
              name="folder"
              size="18"
            />

            <Text
              color={isSelected ? 'darkest' : 'dark'}
              weight={isSelected ? 'bold' : 'medium'}
              className="directory-select-item__label-text"
            >{currentDir._meta.name}</Text>
            </span>
        </li>

        {isExpanded && (
          <li>
            {directories[dir].children.map((entity) => this.renderDirectory(entity, level + 1))}
          </li>
        )}
      </ul>
    );
  };

  render() {
    const { intl, email, selectedFilterEntity } = this.props;
    const isDomain = Boolean(selectedFilterEntity.domain_id === undefined);

    const emailSelectOptions = email
      .map((mail) => ({
        ...mail,
        fullEmail: `${mail.name}@${mail.domain_name}`
      }))
      .filter((mail) => mail.domain_id === selectedFilterEntity.id);

    return (
      <Grid>
        {isDomain && (
          <Dropdown
            label={intl.formatMessage({ id: 'translate.generic.select.user' })}
            options={emailSelectOptions}
            optionValue="id"
            optionLabel="fullEmail"
            groupBy="domain_name"
            searchable={emailSelectOptions.length > 1}
            onChange={(id) => {
              this.setState({ selectedEmailId: id });
              this.getEmailFolders(id);
            }}
          />
        )}

        {this.renderTree()}
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch)
});

const mapStateToProps = (state) => ({
  email: state.pageItems.email || [],
  emailFolder: state.pageItems.emailFolder || []
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DirectorySelect)) as any;
