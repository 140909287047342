import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PromoSystemTextParser } from 'sg-styleguide';

const PromoSystem = ({ intl, userData, ...props }) => (
  <PromoSystemTextParser
    translations={{
      excl: intl.formatMessage({ id: 'translate.generic.excluding' }),
      daySuffix: intl.formatMessage({ id: 'translate.generic.days.suffix' }),
      monthSuffix: intl.formatMessage({ id: 'translate.generic.months.suffix' }),
      yearSuffix: intl.formatMessage({ id: 'translate.generic.years.suffix' })
    }}
    userData={userData}
    {...props}
  />
);

export default connect(({ session }) => ({
  userData: {
    taxName: session && session.user.tax_name,
    currencySymbol: session && session.user.currency_symbol,
    currencySymbolBefore: session && session.user.currency_symbol_before,
    currency: session && session.user.currency
  }
}))(
  injectIntl(PromoSystem)
) as any;
