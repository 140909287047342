import 'whatwg-fetch';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { clientApi } from '../api/client';

import {
  REQUEST_SUPPORT_SUGGESTIONS,
  REQUEST_SUPPORT_SUGGESTIONS_SUCCESS,
  REQUEST_SUPPORT_SEARCH,
  REQUEST_SUPPORT_SEARCH_SUCCESS,
  REQUEST_ARTICLE,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLES,
  REQUEST_ARTICLES_SUCCESS
} from '../constants/actions';

import { handleNemoApiRequest } from './handle-nemo-api-request';

function* requestArticle(action) {
  try {
    const response = yield call(
      fetch,
      action.payload.article.api_host + '/' + action.payload.article.id,
      {
        headers: { Accept: 'application/json' },
        method: 'GET'
      }
    );

    const json = yield response.json();

    yield put({ type: REQUEST_ARTICLE_SUCCESS, data: json });
  } catch (e) {
    console.error('Failed to load article');
  }
}

function* getPinnedSaga({ context }) {
  const { session } = yield select();

  return yield call(
    clientApi(
      `/support/getpinned?context=${context}&_client_token=${session.clientToken}`,
      'GET'
    )
  );
}

function* requestArticles(action) {
  const store = yield select();

  const response = yield call(
    handleNemoApiRequest(getPinnedSaga, action),
    { context: action.payload.context }
  );

  yield put({ type: REQUEST_ARTICLES_SUCCESS, articles: response.data });
}

function* aisuggestSaga({ payload }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/support/aisuggest?_client_token=${session.clientToken}`, 'POST', payload)
  );
}

function* requestSuggestions(action) {
  const store = yield select();

  const response = yield call(
    handleNemoApiRequest(aisuggestSaga, action),
    { payload:  action.payload }
  );

  if (response) {
    yield put({ type: REQUEST_SUPPORT_SUGGESTIONS_SUCCESS, suggestions: response.data });
  }
}

function* aimatchesSaga({ payload }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/support/aimatches?_client_token=${session.clientToken}`, 'POST', payload)
  );
}

function* requestSearch(action) {
  const store = yield select();

  const response = yield call(
    handleNemoApiRequest(aimatchesSaga, action),
    { payload:  action.payload }
  );

  if (response) {
    yield put({ type: REQUEST_SUPPORT_SEARCH_SUCCESS, results: response.data });
  }
}

export default function* popup() {
  yield takeLatest(REQUEST_ARTICLE, requestArticle);
  yield takeLatest(REQUEST_ARTICLES, requestArticles);
  yield takeLatest(REQUEST_SUPPORT_SUGGESTIONS, requestSuggestions);
  yield takeLatest(REQUEST_SUPPORT_SEARCH, requestSearch);
}
