import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'sg-styleguide';
import RemoteSubmit from '../sg-form-util/remote-submit';

type Props = {
  e2eAttr?: string;
  formName: string;
  intl: Intl;
  label: string;
};

const SubmitButton = ({
  e2eAttr = 'dialog-submit',
  formName,
  intl,
  label
}: Props) => (
  <RemoteSubmit
    formName={formName}
    children={({ valid, pristine, spanelFormSubmit }) => (
      <Button
        color="primary"
        action="button"
        data-e2e={e2eAttr}
        onClick={spanelFormSubmit}
      >
        {label || intl.formatMessage({ id: 'translate.generic.confirm' })}
      </Button>
    )}
  />
);

export default injectIntl(SubmitButton);
