import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'sg-styleguide';
import { Icon } from 'sg-styleguide/';
import { RootState } from '../../../../core/reducers';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';
import { getEntityInfoNumber, getEntityIsExpanded, getEntityName, getEntityPath, getEntityType } from '../core/utils';
import DragSideNavigationItem from './draggable-entity';
import './side-navigation.scss';
import { LoaderContext } from '../../../contexts';

class SideNavigation extends React.Component<any, any> {
  renderLoader = ({ parentLoaderVisible }) => !parentLoaderVisible ? (
    <Loader className="content__loader">
      {this.props.intl.formatMessage({ id: 'translate.generic.loading' })}
    </Loader>
  ) : null;

  render() {
    const { entities, intl } = this.props;

    if (!entities['/']) {
      return (
        <LoaderContext.Consumer children={this.renderLoader} />
      );
    }

    return (
      <nav className="side-navigation">
        {entities['/'].map((domain) => this.renderNavigationList(domain))}
      </nav>
    );
  }

  renderNavigationList(entity, padding = 0) {
    const { contextNavigationEntity, selectedNavigationEntity, entities } = this.props;
    const domainName = getEntityName(entity);
    const entityPath = getEntityPath(entity);
    const isExpanded = getEntityIsExpanded(entity);
    const isSelected = selectedNavigationEntity &&
      getEntityInfoNumber(entity) === getEntityInfoNumber(selectedNavigationEntity);
    const items = entities[entityPath];
    let isHighlighted = false;

    if (contextNavigationEntity) {
      isHighlighted = entityPath === getEntityPath(contextNavigationEntity);
    }

    const sideNavigationLabelProps = {
      isExpanded,
      isSelected,
      isHighlighted,
      entity,
      padding
    };

    return (
      <ul key={domainName}>
        <li onContextMenu={(event) => {
          event.stopPropagation();

          const contextType = entityPath === domainName ?
            FILE_MANAGER_CONTEXT_MENU_TYPE.BASE :
            FILE_MANAGER_CONTEXT_MENU_TYPE.ENTITY;

          this.props.actions.onContextNavigationEntityClick(entity);
          this.props.openContextMenu(event, contextType);
        }}>
          <DragSideNavigationItem
            {...sideNavigationLabelProps}
            onArrowClick={this.toggleListItem.bind(this, { entity })}
            onLabelClick={this.handleItemClick.bind(this, { entity })}
            onDoubleClick={this.handleEntityDoubleClick.bind(this, {
              id: entityPath,
              entity
            })}
            handleDrop={this.props.dragAndDrop.moveDir}
          />
          {items && isExpanded && items.map((ent) => this.renderNavigationList(ent, padding + 1))}
        </li>
      </ul>
    );
  }

  fetchDir({ id }) {
    this.props.actions.fetchDir({
      urlParams: {
        id
      }
    });
  }

  toggleListItem({ entity }, event) {
    event.stopPropagation();
    const entityPath = getEntityPath(entity);

    this.props.actions.toggleNavigationList({ entity });

    if (getEntityIsExpanded(entity)) {
      this.fetchDir({ id: entityPath });
    }
  }

  handleItemClick({ entity }, event) {
    const entityPath = getEntityPath(entity);
    const entityType = getEntityType(entity);

    this.props.actions.selectContentRows([entity]);
    this.props.actions.onEntityClick({ entity });

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      return;
    }

    this.fetchDir({ id: entityPath });
  }

  handleEntityDoubleClick({ entity }, event) {
    if (this.props.codeEditorIsVisible) {
      this.props.actions.toggleCodeEditor();
    }

    this.props.actions.selectContentRows([entity]);
    this.props.actions.onEntityClick({ entity });

    if (getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      this.props.codeEditor.openFile(entity);
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  time: state.fileManager.time,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  entities: state.fileManager.entities,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(SideNavigation));
