import { browserHistory } from 'react-router';
import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import * as nemoStoreActions from '../actions/nemo-store';
import * as fetchActions from '../actions/fetch';
import * as sessionActions from '../actions/session';
import { storeSites } from '../actions/sites';
import { fetchSiteMetaApi } from '../actions/site-meta-api';
import { clientApi } from '../api/client';
import { fetchI18N } from '../api/i18n';
import { getUrlSiteId } from '../common/site-id';
import * as Actions from '../constants/actions';
import { changeI18N } from '../actions/i18n';
import { getTranslationsFileName, getCurrentSiteId, getSiteToken } from '../selectors';
import { siteTokenWillExpireSoon } from '../utils/session';
import { setClientTokenTTLIntoLocalStorage } from '../utils/set-localstorage-client-token-ttl';
import { handleNemoApiRequest } from './handle-nemo-api-request';
import { redirectToURL } from '../utils/redirect-to-url';

function* loadNemoStoredData({ hash }) {
  try {
    const response = yield call(clientApi(`/data/retrieve`, 'POST', { hash }));

    yield put(nemoStoreActions.retrieveDataFromNemoSucceeded(response));
    setClientTokenTTLIntoLocalStorage(response.session.tokenTTL);

    return response;
  } catch (e) {
    return null;
  }
}

function* getProfile({ token }) {
  return yield call(clientApi(
    `/profile/getauthenticatedresponse?_client_token=${encodeURIComponent(token)}`,
    'GET'
  ));
}

function* getSites() {
  const { session } = yield select();

  return yield call(
    clientApi(`/sites?_client_token=${session.clientToken}`, 'GET')
  );
}

function* updateTranslations() {
  const i18nFileName = yield select(getTranslationsFileName);

  try {
    const translations = yield call(fetchI18N(i18nFileName));
    yield put(changeI18N({ locale: i18nFileName, messages: translations }));
  } catch (e) {
    console.error(e);
  }
}

function* loadUserProfile({ clientToken, refreshToken }) {
  try {
    const response = yield call(getProfile, { token: clientToken });

    yield put(sessionActions.storeSessionData({
      data: {
        client_token: clientToken,
        refresh_token: refreshToken,
        profile: response.data.profile
      }
    }));

    return response;
  } catch (e) {
    return null;
  }
}

function* handleSitesInitialization(sites = []) {
  let siteId = getUrlSiteId();
  const urlSelectedSite = Boolean(sites.find(({ id }) => id === siteId));

  const [defaultSite, ...otherSites] = sites;

  if (!urlSelectedSite && defaultSite) {
    siteId = defaultSite.id;

    redirectToURL({
      urlParam: { siteId: defaultSite.id }
    });
  }

  yield put(storeSites(sites, siteId));

  const siteToken = yield select(getSiteToken, siteId);

  if (siteId) {
    if (!siteToken || siteTokenWillExpireSoon(siteToken)) {
      yield put(sessionActions.refreshSiteToken());

      const { siteTokenRefreshed, siteTokenRefreshFailed } = yield race({
        siteTokenRefreshed: take(Actions.REFRESH_SITE_TOKEN_SUCCEEDED),
        siteTokenRefreshFailed: take(Actions.REFRESH_SITE_TOKEN_FAILED)
      });
    }

    yield put(fetchSiteMetaApi());
  }
}

function* handlePageRedirect({ siteId, nemoRetrievedResponse }) {
  const redirectSiteId = nemoRetrievedResponse.siteId ? encodeURI(nemoRetrievedResponse.siteId) : siteId;
  const currentSearchParams = browserHistory.getCurrentLocation().query;
  const currentPathname = browserHistory.getCurrentLocation().pathname;

  redirectToURL({
    pathname: nemoRetrievedResponse.page || currentPathname,
    urlParam: {
      ...currentSearchParams,
      siteId: redirectSiteId
    },
    urlParamsToRemove: ['hash']
  });
}

function* pageLoad(action) {
  yield put(fetchActions.httpRequestStarted(action));

  try {
    const { payload } = action;
    const { redirectHash } = payload;
    const { session } = yield select();

    let nemoRetrievedResponse;
    // use tokens from LS, synced to store
    let clientToken = session.clientToken;
    let refreshToken = session.refreshToken;

    if (redirectHash) {
      nemoRetrievedResponse = yield call(loadNemoStoredData, { hash: redirectHash });

      if (!nemoRetrievedResponse) {
        throw new Error('Cannot retrieve nemo stored data.');
      }

      // overwrite tokens with retrieved response from nemo
      clientToken = nemoRetrievedResponse.session.token;
      refreshToken = nemoRetrievedResponse.session.refreshToken;
    }

    if (!clientToken) {
      yield put(sessionActions.logoutUser());
      return;
    }

    if (redirectHash) {
      const updatedProfile = yield call(loadUserProfile, { clientToken, refreshToken });

      if (!updatedProfile) {
        throw new Error('Failed to load profile data from nemo.');
      }
    }

    // non-blocking load of translations
    yield fork(updateTranslations);

    const sites = yield call(handleNemoApiRequest(getSites));

    if (!sites) {
      throw new Error('Failed to load sites from nemo.');
    }

    yield call(handleSitesInitialization, sites.data);

    if (nemoRetrievedResponse) {
      // handle specified from nemo store redirect
      const siteId = yield select(getCurrentSiteId);
      yield call(handlePageRedirect, { siteId, nemoRetrievedResponse });
    }

    yield put(fetchActions.httpRequestSucceeded(action));
  } catch (e) {
    console.error(e);
    yield put(fetchActions.httpRequestFailed(action));
  }
}

function* changeSite(): any {
  yield takeLatest(Actions.PAGE_LOAD, pageLoad);
}

export default changeSite;
