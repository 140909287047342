import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ChartContainer, ChartLegend, Columns, PieChart } from 'sg-styleguide';
import { Browser, Os } from '../../../../core/definitions/stats';
import { getStatsParameterSum } from '../../../../core/utils/stats-format';
import { formatBandwidthLabel } from '../utils';

type Props = {
  stats: any[];
  type: 'os' | 'browser' | 'requests' | 'bandwidth' | string;
  environment: {
    isTablet: boolean;
  };
  intl: Intl;
};

export const PieChartStatistics = ({ stats, intl, type, environment }: Props) => {
  const { isTablet } = environment;

  const legendLabels = {
    hits: intl.formatMessage({ id: 'translate.page.stats.hits.label' })
  };

  type SizeProps = {
    chartCols: Columns,
    legendCols: Columns
  };

  const sizeProps: SizeProps = {
    chartCols: '12',
    legendCols: '12'
  };

  switch (type) {
    case 'os':
      return (
        <ChartContainer
          {...sizeProps}
          title={`
            ${intl.formatMessage({ id: 'translate.page.stats.os.label' })} -
            ${getStatsParameterSum(stats, 'hits')} ${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}`
          }
          renderCustomLegendContent={() => (
            <ChartLegend
              data={stats}
              checkboxIcon="empty"
              labelKey="osLegendLabel"
            />
          )}
          renderChildren={() => (
            <PieChart
              data={stats}
              idKey="id"
              dataKey="hits"
              filteredElementsIds={stats.map(({ id }) => id)}
              style={{ maxWidth: '100%' }}
              renderTooltip={({ payload }: { payload: Os }) => {
                const percentage = (payload.hits / payload.total * 100).toFixed(2);
                return `${payload.id} - ${payload.hits} ${legendLabels.hits} (${percentage})%`;
              }}
            />
          )}
        />
      );
    case 'browser':
      return (
        <ChartContainer
          {...sizeProps}
          title={`
            ${intl.formatMessage({ id: 'translate.page.stats.browser.label' })} -
            ${getStatsParameterSum(stats, 'hits')} ${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}`
          }
          renderCustomLegendContent={() => (
            <ChartLegend
              data={stats}
              checkboxIcon="empty"
              labelKey="browserLegendLabel"
            />
          )}
          renderChildren={() => (
            <PieChart
              data={stats}
              idKey="browser"
              dataKey="hits"
              filteredElementsIds={stats.map(({ browser }) => browser)}
              style={{ maxWidth: '100%' }}
              renderTooltip={({ payload }: { payload: Browser }) => {
                const percentage = (payload.hits / payload.total * 100).toFixed(2);
                return `${payload.browser} - ${payload.hits} ${legendLabels.hits} (${percentage})%`;
              }}
            />
          )}
        />
      );
    case 'requests':
      return (
        <ChartContainer
          key={type}
          {...sizeProps}
          title={`
            ${intl.formatMessage({ id: `page.cloudflare.chart.${type}.title` })} -
            ${getStatsParameterSum(stats, 'countValue')}`
          }
          renderCustomLegendContent={() => (
            <ChartLegend
              data={stats}
              checkboxIcon="empty"
              labelKey="legendLabel"
            />
          )}
          renderChildren={() => (
            <PieChart
              data={stats}
              idKey="name"
              dataKey="value"
              filteredElementsIds={stats.map(({ name }) => name)}
              style={{ maxWidth: '100%' }}
              renderTooltip={({ payload }) => {
                const percentage = (payload.value).toFixed(2);
                return `${payload.name} - ${percentage}%`;
              }}
            />
          )}
        />
      );
    case 'bandwidth':
      return (
        <ChartContainer
          key={type}
          {...sizeProps}
          title={`
            ${intl.formatMessage({ id: `page.cloudflare.chart.${type}.title` })} -
            ${formatBandwidthLabel(getStatsParameterSum(stats, 'countValue'))}`
          }
          renderCustomLegendContent={() => (
            <ChartLegend
              data={stats}
              checkboxIcon="empty"
              labelKey="legendLabel"
            />
          )}
          renderChildren={() => (
            <PieChart
              data={stats}
              idKey="name"
              dataKey="value"
              filteredElementsIds={stats.map(({ name }) => name)}
              style={{ maxWidth: '100%' }}
              renderTooltip={({ payload }) => {
                const percentage = (payload.value).toFixed(2);
                return `${payload.name} - ${percentage}%`;
              }}
            />
          )}
        />
      );
    default:
      return null;
  }
};

export default injectIntl(PieChartStatistics);
