import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../core/reducers';
import { injectIntl } from 'react-intl';
import { Button, Dialog, Icon, Title, textToHTML } from 'sg-styleguide';
import { removeTaskNotification } from '../../../core/actions/notifications';

import {
  getTaskNotifications,
  isTaskSuccessful,
  getTaskSuccessMessage,
  getTaskErrorMessage
} from '../../../core/selectors/pending-tasks';

type Props = {
  pathname: string;
  taskNotifications?: Task[];
  intl?: Intl;
  removeTaskNotification?: typeof removeTaskNotification;
};

export const TaskNotifications = ({ taskNotifications = [], intl, removeTaskNotification }) => {
  return (
    <React.Fragment>
      {
        taskNotifications.map((taskNotification, i) => {
          const isSuccessful = isTaskSuccessful(taskNotification);

          return (
            <Dialog
              key={`taskNotifications-${i}`}
              open
              icon={isSuccessful ? 'success' : 'error'}
              state={isSuccessful ? 'active' : 'inactive'}
              title={intl.formatMessage({
                id: isSuccessful ?
                  'translate.generic.done' :
                  'translate.generic.error'
              })}
              footer={
                <Button
                  action="reset"
                  data-e2e="dialog-close"
                  onClick={() => removeTaskNotification(taskNotification.id)}
                >
                  {intl.formatMessage({ id: 'translate.generic.close' })}
                </Button>
              }
              data-e2e="long-task-complete-dialog"
            >
              {isSuccessful && textToHTML(getTaskSuccessMessage(taskNotification))}
              {!isSuccessful && textToHTML(getTaskErrorMessage(taskNotification))}
            </Dialog>
          );
        })
      }
    </React.Fragment>
  );
};

const mapStateToProps = ({ tasks, notifications }: RootState, { pathname }) => ({
  taskNotifications: getTaskNotifications({ notifications }, pathname)
});

export default connect<{}, {}, Props>(mapStateToProps, (dispatch) => ({
  removeTaskNotification: (id: string) => dispatch(removeTaskNotification(id))
}))(injectIntl(TaskNotifications));
