import {
  getFormSyncErrors,
  getFormMeta,
  isPristine,
  isValid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed
} from 'redux-form';

type FieldErrors = {
  [fieldName: string]: string;
};

export const getMetaDataByFormName = (store, formName: string) => ({
  /* TODO: inject meta data selectors here, if needed.
    check => https://redux-form.com/7.1.2/docs/api/selectors.md/ */
  pristine: isPristine(formName)(store),
  valid: isValid(formName)(store),
  syncErrors: getFormSyncErrors(formName)(store),
  fields: getFormMeta(formName)(store),
  submitting: isSubmitting(formName)(store),
  submitSucceeded: hasSubmitSucceeded(formName)(store),
  submitFailed: hasSubmitFailed(formName)(store)
});

export const selectReduxFormData = ({ form }, formName: string) => form[formName] || null;

export const selectFirstFormErrorField = (
  { form },
  formName: string
) => {
  const formData = selectReduxFormData({ form }, formName);

  const { syncErrors = {}, asyncErrors = {}, registeredFields = {} } = formData;

  const errorsNames: any = [
    ...Object.keys(syncErrors),
    ...Object.keys(asyncErrors)
  ];

  const errorsValues: any = [
    ...Object.values(syncErrors),
    ...Object.values(asyncErrors)
  ];

  for (const index in errorsNames) {
    if (!errorsNames[index]) {
      continue;
    }

    const fieldName = errorsNames[index];
    const fieldError = errorsValues[index];

    const isArrayOfFields = Boolean(
      fieldError &&
      typeof fieldError !== 'string' &&
      fieldError.length
    );

    if (isArrayOfFields) {
      for (const fieldErrorIndex in fieldError) {
        if (fieldError[fieldErrorIndex]) {
          return `${fieldName}[${fieldErrorIndex}]`;
        }
      }
    }

    const isFieldRegistered = Boolean(registeredFields[fieldName] && registeredFields[fieldName].count);

    if (isFieldRegistered) {
      return fieldName;
    }
  }
};

export const getFieldErrorsFromException = ({ message, err_fields }): FieldErrors => {
  if (err_fields) {
    const { err_meta = {}, ...fields } = err_fields;

    return Object.keys(fields).reduce((output, fieldName) => ({
      ...output,
      [fieldName]: message
    }), {});
  }

  return {};
};
