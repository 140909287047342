import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Checkbox, Flex, FlexProps, Grid, Icon, Placeholder, Text } from 'sg-styleguide';
import * as actions from '../../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormCheckbox from '../../../../components/form-checkbox';
import ErrorNotice from '../../../../components/form-error-notice';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms/index';
import { DIALOG_LIST_STYLES } from './constants';
import './styles.scss';

const LayoutListProps: Partial<FlexProps> = {
  align: 'center',
  gutter: 'none',
  margin: 'none'
};

const formName = REDUX_FORM.BACKUP_RESTORE_DATABASE_DIALOG;

const validateDatabases = (value): string => {
  if (Array.isArray(value) && (value.length === 0 || !value.map((v) => Boolean(v)).includes(true))) {
    return 'Field is Required';
  }

  return;
};

class RestoreEmailDialog extends React.Component<any, any> {
  componentWillMount() {
    const { actions, entity } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.BACKUP_RESTORE_DATABASE,
      urlParams: {
        id: entity.id
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.databases) !== JSON.stringify(this.props.databases)) {
      this.props.change('restore_data', new Array(nextProps.databases.length).fill(false));
    }
  }

  componentDidMount() {
    this.props.change('restore_data', new Array(this.props.databases.length).fill(false));
  }

  getDatabaseByIndex(index) {
    return this.props.databases[index];
  }

  getLeftItemPadding = () => {
    const { environment } = this.props;

    return environment.isPhone ? 'medium' : 'xx-large';
  };

  renderDatabaseList = (data) => {
    const { intl, getFormValues, getFormErrors } = this.props;
    const formValues = getFormValues(formName);
    const formErrors = getFormErrors(formName);

    const selectedDatabasesCount =
      (formValues && formValues.restore_data) ? formValues.restore_data.filter(Boolean).length : 0;

    const leftItemPadding = this.getLeftItemPadding();

    return (
      <React.Fragment>
        <div style={DIALOG_LIST_STYLES}>
          {data.fields.map((database, index) => {
            const db = this.getDatabaseByIndex(index);

            if (!db) {
              return null;
            }

            return (
              <Flex
                key={`${db}-${index}`}
                {...LayoutListProps}
              >
                <Grid padding={['xx-small', 'x-small', 'xx-small', leftItemPadding]}>
                  <Field
                    name={database}
                    component={FormCheckbox}
                  />

                </Grid>

                <Icon name="database-filled" color="lighter" size="24" />

                <Grid padding={['none', 'none', 'none', 'x-small']} align="center">
                  <Text>{db.db_name}</Text>
                </Grid>
              </Flex>
            );
          })}
        </div>

        <Flex border={['small', 'none', 'none', 'none']} {...LayoutListProps}>
          <Grid padding={['x-small', 'large', 'x-small', leftItemPadding]}>
            <Text
              align="left"
              color="light"
              transform="uppercase"
              size="small"
              weight="bold"
              className={formErrors.restore_data && this.props.anyTouched && 'sg-text-label--error'}
            >
              {intl.formatMessage(
                { id: 'translate.page.backup.restore.item.selected' },
                { count: selectedDatabasesCount }
              )}
            </Text>
          </Grid>
        </Flex>
      </React.Fragment>
    );
  };

  renderNoContentPlaceholder() {
    const { intl } = this.props;

    return (
      <Placeholder
        icon="product-database-wings"
        background="lighter"
        message={intl.formatMessage({ id: 'translate.page.backup.restore.databases.no.data.message' })}
      />
    );
  }

  renderDialogContent() {
    const { databases, intl, getFormValues, getFormErrors, submitFailed, validationUtils } = this.props;
    const formValues = getFormValues(formName);
    const formErrors = getFormErrors(formName);
    const errors = Object.keys(formErrors).map((key) => ({
      field: key,
      label: key === 'terms' ?
        intl.formatMessage({ id: 'translate.page.backup.restore.databases.error.notice.terms' }) :
        intl.formatMessage({ id: 'translate.page.backup.restore.databases.error.notice.restore_data' })
    }));

    const leftItemPadding = this.getLeftItemPadding();
    return (
      <React.Fragment>
        {submitFailed && (
          <Grid padding={['none', leftItemPadding, 'medium', leftItemPadding]}>
            <ErrorNotice
              title={intl.formatMessage({ id: 'translate.page.backup.restore.error.notice.title' })}
              errors={errors}
            />
          </Grid>
        )}

        <Flex background="light" {...LayoutListProps}>
          <Grid padding={['none', 'x-small', 'none', leftItemPadding]}>
            <Checkbox
              value="all"
              checked={formValues.restore_data && !formValues.restore_data.includes(false)}
              onChange={(event) => this.props.change('restore_data', event.target.checked ?
                databases :
                new Array(databases.length).fill(false)
              )}
            />
          </Grid>

          <Text color="light" size="small" weight="bold">
            {intl.formatMessage({ id: 'translate.generic.name' })}
          </Text>
        </Flex>

        <Grid padding={['none', 'none', 'none', 'none']}>
          <FieldArray
            name="restore_data"
            validate={[validateDatabases]}
            component={this.renderDatabaseList}
          />
        </Grid>

        <Flex {...LayoutListProps}>
          <Grid padding={['medium', 'large', 'large', leftItemPadding]}>
            <Field
              expand
              name="terms"
              validate={[validationUtils.required]}
              component={FormCheckbox}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.database.restore.terms' })}
            </Field>
          </Grid>
        </Flex>
      </React.Fragment>
    );
  }

  render() {
    const { databases } = this.props;

    return databases.length ? this.renderDialogContent() : this.renderNoContentPlaceholder();
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => ({
  environment: state.environment,
  databases: state.pageItems[API_RESOURCE.BACKUP_RESTORE_DATABASE.resourceName] || []
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: formName })(RestoreEmailDialog)
    )
  )
);
