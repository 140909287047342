import * as React from 'react';
import { destroy } from 'redux-form';
import { Grid, Notice, Section, Tab, Tabs, Text, Textarea } from 'sg-styleguide';
import { copyToClipboard, selectNodeTextContent } from 'sg-styleguide/lib/utils';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, MAIN_DOMAIN_ID, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import { reorderArray } from '../../../core/utils/reorder-array';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import { API_RESOURCE_MAP, DNSTypes } from './constants';
import {
  CreateBox,
  CreateFormA,
  CreateFormAAAA,
  CreateFormCNAME,
  CreateFormMX,
  CreateFormSRV,
  CreateFormTXT
} from './create';
import { DNS_FORM_BY_TYPE } from './create/box';
import ChangeDNS from './update/dns';

type Props = {
  intl: Intl;
  environment: {
    isPhone: boolean;
  };
  router: any;
  location: any;
  actions: any;
  items: any;
  domainName: string;
  dnsServers: string[];
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: {
    type: DNSTypes;
  };
  selectedDNSType: DNSTypes;
  [other: string]: any;
};

const endpoint: string = '/dns';
const resourceName: string = 'dns';
const itemName: string = 'Dns record';

const DNS_FORM_COMPONENT = {
  [DNSTypes.DNS_A]: CreateFormA,
  [DNSTypes.DNS_AAAA]: CreateFormAAAA,
  [DNSTypes.DNS_CNAME]: CreateFormCNAME,
  [DNSTypes.DNS_MX]: CreateFormMX,
  [DNSTypes.DNS_SRV]: CreateFormSRV,
  [DNSTypes.DNS_TXT]: CreateFormTXT
};

export const buildNsRecordsString =
  (records: string[], delimiter: string = '\n') =>
    `${records.join(delimiter)}`;

class DnsPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null,
    selectedDomain: {
      name: this.props.domainName,
      id: MAIN_DOMAIN_ID
    },
    selectedDNSType: DNSTypes.DNS_A
  };

  fetchCurrentDomain(domains = this.props.items.domain) {
    let res = {} as any;
    if (domains) {
      domains.forEach((d) => {
        if (d.main) {
          res = d;
        }
      });
    }
    return res;
  }

  pickerOptions() {
    const { items } = this.props;
    const currentDomain = this.fetchCurrentDomain(items.domain);
    const currentDomainAliases = this.arrangeOptionsData(items.domainAlias);

    return [].concat(currentDomain, currentDomainAliases);
  }

  arrangeOptionsData(data = []) {
    return data.sort((a, b) => {
      return (a.id - b.id);
    });
  }

  UCF = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleCopyToClipboard(value, node) {
    copyToClipboard(value, (isSuccessful) => {
      // isSuccessful in Safari with onCopy is aways false ...
      selectNodeTextContent(node);
    });
  }

  getDNSName = ({ name, type, proto, service }) => {
    const { domainName } = this.props;

    if (type === DNSTypes.DNS_SRV) {
      return `_${service}._${proto}.${domainName}.`;
    }

    if (!name) {
      return `${domainName}.`;
    }

    return name[name.length - 1] === '.' ? name : `${name}.${domainName}.`;
  };

  onDomainChanged = (id) => {
    const pickerOptions = this.pickerOptions();
    const domain = pickerOptions.find((item) => item.id === id);

    this.setState({
      selectedDomain: {
        name: domain.name,
        id
      }
    });
  };

  onCreateFormSubmit = (formData) => {
    const { selectedDNSType } = this.state;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: DNS_FORM_BY_TYPE[selectedDNSType],
          success: {
            intlKey: 'translate.page.dns.create.success.message',
            intlValues: { type: selectedDNSType }

          },
          error: {
            intlKey: 'translate.page.dns.create.error.message',
            intlValues: { type: selectedDNSType }
          }
        }
      }
    });
  };

  renderUpdateComponent = () => {
    const { domainName, actions, closeSGDialog, intl } = this.props;
    const { currentUpdatePayload } = this.state;
    const type = currentUpdatePayload && currentUpdatePayload.type;
    const name = currentUpdatePayload && this.getDNSName(currentUpdatePayload);

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_DNS_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.dns.update.title' }, { name })}
        resources={[
          { resourceName: API_RESOURCE.DNS_A.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_AAAA.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_CNAME.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_MX.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_SRV.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_TXT.resourceName, methods: ['PUT'] }
        ]}
      >
        <ChangeDNS
          domainName={domainName}
          type={type}
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_DNS_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const payload = deletePayload && deletePayload.payload;
    const name = deletePayload && this.getDNSName(deletePayload.entity);

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.dns.delete.dialog.title' }, { name })}
        onSubmit={() => this.props.actions.deleteItem(payload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;

    const dnsResourceMetaApi = 'dns-' + entity.type.toLowerCase();
    const dnsResource = 'dns' + this.UCF(entity.type.toLowerCase());
    const dnsEndpoint = '/dns-' + entity.type.toLowerCase();

    const metaFields = {
      resourceName: dnsResource,
      endpoint: dnsEndpoint,
      parentResourceName: 'dns',
      resourceNameMetaApi: dnsResourceMetaApi,
      entityName: entity.name || ''
    };

    const deletePayload = {
      entity: { ...entity },
      payload: {
        itemId: id,
        _metaFields: { ...metaFields },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.page.dns.delete.success.message'
            },
            error: {
              intlKey: 'translate.page.dns.delete.error.message'
            }
          }
        }
      }
    };

    const updatePayload = {
      _metaFields: {
        ...metaFields
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.dns.update.success.message'
          },
          error: {
            intlKey: 'translate.page.dns.update.error.message'
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={dnsResourceMetaApi}
        items={[{
          vcsMethod: 'PUT',
          label: intl.formatMessage({ id: 'translate.generic.edit' }),
          e2eAttr: 'table-action-edit',
          icon: 'edit',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentUpdatePayload: updatePayload },
            () => openSGDialog(REDUX_FORM.CHANGE_DNS_DIALOG)
          )
        }, {
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          icon: 'trash',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };

  renderTableData = () => {
    if (!this.props.items.dns) {
      return [];
    }

    return (
      this.props.items.dns.filter((r) => (
        r.domain_id === this.state.selectedDomain.id) && !['SOA', 'NS'].includes(r.type)
      )
    );
  };

  renderPageNotice = () => {
    const { intl, items, dnsServers } = this.props;
    const { name } = this.state.selectedDomain;
    const selectedDomain = name && items.domain.find((d) => d.name === name);
    const isSubdomain = selectedDomain && !selectedDomain.in_tld;

    if (isSubdomain) {
      return (
        <Notice
          type="warning"
          title={intl.formatMessage({ id: 'translate.page.dns.not-available-notice.title' })}
        >
          {intl.formatMessage({ id: 'translate.page.dns.not-available-notice.text' })}
        </Notice>
      );
    }

    return (
      <Notice
        type="warning"
        title={intl.formatMessage(
          { id: 'translate.page.dns.dns-notice.title' },
          { domain: name }
        )}
      >
        <Grid>
          <Text>
            {intl.formatMessage({ id: 'translate.page.dns.dns-notice.text' })}
          </Text>

          <Textarea
            readOnly
            label={intl.formatMessage({ id: 'translate.page.dns.dns-notice.ns-records.label' })}
          >
            {buildNsRecordsString(dnsServers)}
          </Textarea>
          <Text>
            {intl.formatMessage({ id: 'translate.page.dns.dns-notice.text-info' })}
          </Text>
        </Grid>
      </Notice>
    );
  };

  setCreateBoxTab = (dnsType: DNSTypes) => this.setState({ selectedDNSType: dnsType });

  renderCreateBoxTabs = () => {
    const { intl } = this.props;
    const { selectedDNSType } = this.state;

    return (
      <Tabs size="small" border="light">
        <Tab
          data-e2e="dns-tab-a"
          active={selectedDNSType === DNSTypes.DNS_A}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_A)}
        >
          {DNSTypes.DNS_A}
        </Tab>
        <Tab
          data-e2e="dns-tab-aaaa"
          active={selectedDNSType === DNSTypes.DNS_AAAA}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_AAAA)}
        >
          {DNSTypes.DNS_AAAA}
        </Tab>
        <Tab
          data-e2e="dns-tab-cname"
          active={selectedDNSType === DNSTypes.DNS_CNAME}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_CNAME)}
        >
          {DNSTypes.DNS_CNAME}
        </Tab>
        <Tab
          data-e2e="dns-tab-mx"
          active={selectedDNSType === DNSTypes.DNS_MX}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_MX)}
        >
          {DNSTypes.DNS_MX}
        </Tab>
        <Tab
          data-e2e="dns-tab-srv"
          active={selectedDNSType === DNSTypes.DNS_SRV}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_SRV)}
        >
          {DNSTypes.DNS_SRV}
        </Tab>
        <Tab
          data-e2e="dns-tab-txt"
          active={selectedDNSType === DNSTypes.DNS_TXT}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_TXT)}
        >
          {DNSTypes.DNS_TXT}
        </Tab>
      </Tabs>
    );
  };

  renderValueColumn = (value, { type, prio = null, service = null, weight = null, port = null }) => {
    const { intl } = this.props;

    const textWrapperProps = {
      truncate: true,
      onCopy: (event) => {
        this.handleCopyToClipboard(value, event.target);
      },
      onCut: (event) => {
        event.preventDefault();

        this.handleCopyToClipboard(value, event.target);
      }
    };

    const prefixTextProps = {
      color: 'light',
      tag: 'span',
      style: { userSelect: 'none', webkitUserSelect: 'none' }
    };

    switch (type) {
      case DNSTypes.DNS_A:
      case DNSTypes.DNS_AAAA:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.points-to.text' })}
            </Text>&nbsp;<Text tag="span">
            {value}
          </Text>
          </Text>
        );
      case DNSTypes.DNS_CNAME:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.is-an-alias-of.text' })}
            </Text>&nbsp;<Text tag="span">
            {value}
          </Text>
          </Text>
        );
      case DNSTypes.DNS_MX:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.mail-is-hosted-at.text' })}
            </Text>&nbsp;<Text tag="span">
            {value}
          </Text>&nbsp;<Text {...prefixTextProps}>
            {intl.formatMessage({ id: 'translate.page.dns.record.value.with-priority.text' })}
          </Text>&nbsp;<Text tag="span">
            {prio}
          </Text>
          </Text>
        );
      case DNSTypes.DNS_SRV:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-priority.text' })}
            </Text>
            &nbsp;
            <Text tag="span">
              {prio}
            </Text>

            <Text {...prefixTextProps}>
              ,&nbsp;
            </Text>

            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-weight.text' })}
            </Text>
            &nbsp;
            <Text tag="span">
              {weight}
            </Text>

            <Text {...prefixTextProps}>
              ,&nbsp;
            </Text>

            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-port.text' })}
            </Text>
            &nbsp;
            <Text tag="span">
              {port}
            </Text>

            <Text {...prefixTextProps}>
              &nbsp;
            </Text>

            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-value.text' })}
            </Text>
            &nbsp;
            <Text tag="span">
              {value}
            </Text>
          </Text>
        );
      default:
        return (
          <Text {...textWrapperProps}>
            {value}
          </Text>
        );
    }
  };

  render() {
    const { intl, actions, environment, domainName } = this.props;
    const { selectedDomain, selectedDNSType } = this.state;
    const CreateForm = DNS_FORM_COMPONENT[selectedDNSType];

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.type' }),
        accessor: 'type',
        style: { width: environment.isPhone ? 'auto' : '100px' }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: 'name',
        render: (name, entity) => this.getDNSName(entity)
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.value' }),
        accessor: 'value',
        render: this.renderValueColumn
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const pickerOptions = this.pickerOptions();
    const { name, id } = this.state.selectedDomain;
    const selectedDomainData = pickerOptions.find((domain) => domain.id === id);
    const shouldRenderPageContent = !(selectedDomainData && !selectedDomainData.sg_dns);

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: 'translate.page.dns.title' })}
          icon="presentational-dns-zone"
          instructions={intl.formatMessage({ id: 'translate.page.dns.instructions' })}
        />

        <Section>
          <Grid gap={shouldRenderPageContent ? 'medium' : 'x-large'}>
            <DomainSelect
              options={pickerOptions}
              onChange={this.onDomainChanged}
              searchValue={name}
              selectedValue={id}
              placeholder={intl.formatMessage({ id: 'translate.generic.pick.domain' })}
              searchByKey="name"
              optionValue="id"
              optionLabel="name"
            />
            {shouldRenderPageContent ? (
              <React.Fragment>
                <CreateBox
                  selectedDNSType={this.state.selectedDNSType}
                  apiResource={API_RESOURCE_MAP[this.state.selectedDNSType]}
                  renderBeforeBoxChildren={this.renderCreateBoxTabs()}
                >
                  <CreateForm
                    domainName={domainName}
                    selectedDNSType={this.state.selectedDNSType}
                    apiResource={API_RESOURCE_MAP[this.state.selectedDNSType]}
                    onSubmit={(data) => this.onCreateFormSubmit({ ...data, domain_id: selectedDomain.id })}
                  />
                </CreateBox>

                <VCS resourceName={resourceName} hasMethod="GET">
                  <SGTable
                    title={intl.formatMessage({ id: 'translate.page.dns.list.title' })}
                    data={this.renderTableData()}
                    columns={environment.isPhone ? reorderArray(columns, { from: 1, to: 0 }) : columns}
                    resources={[
                      { resourceName: API_RESOURCE.DNS.resourceName, methods: ['GET'] }
                    ]}
                    rowResources={[
                      { resourceName: API_RESOURCE.DNS_A.resourceName, methods: ['DELETE'] },
                      { resourceName: API_RESOURCE.DNS_AAAA.resourceName, methods: ['DELETE'] },
                      { resourceName: API_RESOURCE.DNS_CNAME.resourceName, methods: ['DELETE'] },
                      { resourceName: API_RESOURCE.DNS_MX.resourceName, methods: ['DELETE'] },
                      { resourceName: API_RESOURCE.DNS_SRV.resourceName, methods: ['DELETE'] },
                      { resourceName: API_RESOURCE.DNS_TXT.resourceName, methods: ['DELETE'] }
                    ]}
                    noDataMessage="translate.page.dns.sg-table.no-data.message"
                  />
                </VCS>
              </React.Fragment>
            ) : this.renderPageNotice()
            }
          </Grid>
        </Section>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  domainName: state.sites.currentDomainName,
  dnsServers: (
    state.siteMetaApi &&
    state.siteMetaApi.features &&
    state.siteMetaApi.features.preferred_dns_servers
  ) || []
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});
export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  DnsPage, {
    endpoint,
    itemName,
    resourceName
  },
  API_RESOURCE.DOMAIN,
  API_RESOURCE.DOMAIN_ALIAS
);
