import { RootState } from '../reducers';

export const getTranslationsFileName = (state: RootState) => {
  const lang = state && state.session && state.session.user && state.session.user.language;

  switch (lang) {
    case 'it_IT':
      return 'it_IT';
    case 'es_ES':
      return 'es';
    default:
      return 'en';
  }
};

export const getSiteToken = ({ session }: Partial<RootState>, siteId: string) =>
  session.siteTokens &&
  session.siteTokens[siteId] &&
  session.siteTokens[siteId].siteToken;

export const userDaysAfterCreation = ({ session }: Partial<RootState>): number => {
  const user = session && session.user;

  if (!user || !user.created) {
    return 0;
  }

  const userCreation = parseInt(user.created, 10) * 1000;
  const currentTime = +new Date();

  return Math.floor((currentTime - userCreation) / 1000 / 60 / 60 / 24);
};
