import * as React from 'react';
import { Grid, Section, Switch } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import indexWithCRUD from '../../components/indexWithCRUD';
import { findMainDomain, filterStagingDomains } from '../../../core/selectors/index';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import VCS from '../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create/';

type Props = {
  actions: CrudActions;
  listedDomains: any[];
  mainDomain: any;
  location: any;
  intl: Intl;
  openSGDialog: Function;
};

class DomainPage extends React.Component<Props, any> {
  readonly state = {
    currentDeletePayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_SUB_DOMAIN,
          success: {
            intlKey: 'translate.page.domain.create.success.message',
            intlValues: { name: formData.name }

          },
          error: {
            intlKey: 'translate.page.domain.create.error.message',
            intlValues: { name: formData.name }
          }
        }
      }
    });
  };

  render() {
    const { mainDomain, intl, listedDomains } = this.props;
    const mainDomainName = mainDomain && mainDomain.name;
    const domains = listedDomains.filter((d) => !d.main);

    const tableData = [{
      ...mainDomain,
      isWildCard: true,
      name: `*.${mainDomainName}`
    }].concat(domains);

    const columns = [
      { header: intl.formatMessage({ id: 'translate.generic.name' }), accessor: 'name' },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          icon="presentational-subdomain"
          title={intl.formatMessage({ id: 'translate.page.subdomain.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.subdomain.instructions' })}
        />
        <Section>
          <Grid>
            <CreateBox domainName={mainDomainName}>
              <CreateForm
                domainName={mainDomainName}
                onSubmit={(data) => this.onCreateFormSubmit({
                  ...data,
                  name: `${data.name}.${mainDomainName}`
                })}
              />
            </CreateBox>

            {this.renderDeleteConformationDialogComponent()}

            <VCS resourceName={API_RESOURCE.DOMAIN.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.subdomain.list.title' })}
                data={tableData}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] }]}
                rowResources={[{ resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['PUT', 'DELETE'] }]}
                noDataMessage="translate.page.subdomain.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>
      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const { currentDeletePayload } = this.state;
    const entityName = currentDeletePayload && currentDeletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.subdomain.delete.title' }, { domain: entityName })}
        onSubmit={() => this.props.actions.deleteItem(currentDeletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { actions, intl, openSGDialog, mainDomain } = this.props;
    const mainDomainName = mainDomain && mainDomain.name;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: entity.name,
      _metaFields: { ...API_RESOURCE.DOMAIN },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.subdomain.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.subdomain.delete.error.message'
          }
        }
      }
    };

    if (!mainDomain) {
      return null;
    }

    if (entity.isWildCard) {
      return (
        <Switch
          checked={mainDomain.settings.wildcard}
          data-e2e="subdomain-wildcard-switch"
          onChange={(event) => {
            const isEnabled = event.target.checked;
            const successEnableKey = isEnabled ?
              'translate.page.subdomain.wildcard.success.enable.message' :
              'translate.page.subdomain.wildcard.success.disable.message';
            const failEnableKey = isEnabled ?
              'translate.page.subdomain.wildcard.error.enable.message' :
              'translate.page.subdomain.wildcard.error.disable.message';

            actions.updateItem({
              _metaFields: {
                ...API_RESOURCE.DOMAIN
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: successEnableKey,
                    intlValues: { domain: mainDomainName }
                  },
                  error: {
                    intlKey: failEnableKey,
                    intlValues: { domain: mainDomainName }
                  }
                }
              },
              id: mainDomain.id,
              settings: {
                ...mainDomain.settings,
                wildcard: Number(isEnabled)
              }
            });
          }}
        />
      );
    }

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.DOMAIN.resourceName}
        items={[{
          vcsMethod: 'PUT',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeletePayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  mainDomain: findMainDomain(state),
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN.resourceName)
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(DomainPage, API_RESOURCE.DOMAIN);
