import 'whatwg-fetch';
import { buffers, END, eventChannel } from 'redux-saga';

interface XHRSiteAPI {
  endpoint: string;
  method: string ;
  body: any;
  state: any;
  urlParams: any;
}

export function xhrSiteApi({ endpoint, method = 'GET', body = null, state, urlParams = {} }: XHRSiteAPI) {
  const currentSiteId: string = state.routing.locationBeforeTransitions.query.siteId;
  const siteToken: string = state.session.siteTokens[currentSiteId].siteToken;
  const currentSiteObj: SiteItem = state.sites.items.find((siteObj: SiteItem) => siteObj.id === currentSiteId);

  const apiHost = `https://${currentSiteObj.api_url}/api-sgcp/v00`;
  const urlParamsStr = Object.keys(urlParams).map((k) => `${k}=${encodeURIComponent(urlParams[k])}`).join('&') || '';
  const xhrEndpoint = `${apiHost}${endpoint}?${urlParamsStr}&_site_token=${siteToken}`;

  return () => {
    return eventChannel((emitter) => {
      const xhr = new XMLHttpRequest();

      let lastLoaded = 0;
      const onProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          // TODO remove if not needed in "GET" single file
          const progress = event.loaded / event.total;
          const transferred = event.loaded - lastLoaded;
          lastLoaded = event.loaded;
          emitter({ progress, transferred });
        }
      };

      const onFailure = () => {
        emitter({ err: new Error('Upload failed') });
        emitter(END);
      };

      xhr.upload.addEventListener('progress', onProgress);
      xhr.upload.addEventListener('error', onFailure);
      xhr.upload.addEventListener('abort', onFailure);
      xhr.onreadystatechange = (event) => {
        const { readyState, response } = xhr;
        const status: any = xhr.status;

        if (readyState !== 4) {
          return;
        }

        // TODO need to spend some time to check different errors.
        if (status === 0) {
          emitter({ err: new Error('No internet connection') });
          emitter(END);
        }

        if (status === 400 || status === '400') {
          emitter({ err: new Error(JSON.parse(xhr.response).message) });
          emitter(END);
        }

        emitter({ response: JSON.parse(response || null) });
        emitter(END);
      };

      xhr.open(method, xhrEndpoint, true);
      xhr.send(body);

      return () => {
        xhr.upload.removeEventListener('progress', onProgress);
        xhr.upload.removeEventListener('error', onFailure);
        xhr.upload.removeEventListener('abort', onFailure);
        xhr.onreadystatechange = null;
        xhr.abort();
      };
    }, buffers.sliding(2));
  };
}
