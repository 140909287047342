import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid } from 'sg-styleguide/';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './notification/create';
import { ChangeCronNotification } from './notification/update';

type Props = {
  actions: {
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
  };
  items: any;
  location: any;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: UpdateItemPayload;
  currentDeletePayload: DeleteItemPayload;
};

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.CRON_NOTIFY;

class CronPage extends React.Component<Props, State> {
  readonly state: State = {
    currentUpdatePayload: null,
    currentDeletePayload: null
  };

  onCreateFormSubmit = (data) => {
    const { actions } = this.props;

    actions.createItem({
      ...data,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_ITEM_CRON_NOTIFY,
          success: {},
          error: {}
        }
      }
    });
  }

  render() {
    const { intl, actions, items } = this.props;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.page.cron.notification.email' }),
      accessor: 'email'
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderContextMenu
    }];

    return (
      <Grid>
        <CreateBox>
          <CreateForm onSubmit={this.onCreateFormSubmit} />
        </CreateBox>

        <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.cron.notification.title' })}
            data={items.cronNotify}
            columns={columns}
            resources={[{ resourceName: API_RESOURCE.CRON_NOTIFY.resourceName, methods: ['GET'] }]}
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialog()}
      </Grid>
    );
  };

  renderDeleteConformationDialog = () => {
    const { actions, intl } = this.props;
    const { currentDeletePayload } = this.state;

    return (
      <DeleteDialog
        title={
          intl.formatMessage({
            id: 'translate.page.cron-notifications.delete.title'
          }, {
            name: currentDeletePayload && currentDeletePayload.name
          })
        }
        onSubmit={() => actions.deleteItem({
          ...currentDeletePayload,
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.cron-job.notification.email.deleted',
                intlValues: {
                  name: currentDeletePayload.name
                }
              },
              error: {
                intlKey: 'translate.page.cron-job.notification.email.delete.failed',
                intlValues: {
                  name: currentDeletePayload.name
                }
              }
            }
          }
        })}
      />
    );
  };

  renderUpdateComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_CRON_NOTIFICATION}
        title={intl.formatMessage(
          { id: 'translate.page.cron-notifications.update.title' },
          { name: currentUpdatePayload && currentUpdatePayload.email }
        )}
        resources={[{
          resourceName: API_RESOURCE.CRON_NOTIFY.resourceName,
          methods: ['PUT']
        }]}
      >
        <ChangeCronNotification
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem({
            ...data,
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.cron-job.notification.email.editted',
                  intlValues: {
                    name: currentUpdatePayload.email
                  }
                },
                error: {
                  intlKey: 'translate.page.cron-job.notification.email.edit.failed',
                  intlValues: {
                    name: currentUpdatePayload.email
                  }
                }
              }
            }
          }, () => closeSGDialog(REDUX_FORM.CHANGE_CRON_NOTIFICATION))}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;

    const deletePayload = {
      itemId: id,
      name: entity.email,
      _metaFields: { ...API_RESOURCE.CRON_NOTIFY }
    };

    const updatePayload = {
      ...entity,
      _metaFields: { ...API_RESOURCE.CRON_NOTIFY }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          label: intl.formatMessage({ id: 'translate.generic.edit' }),
          e2eAttr: 'table-action-edit',
          icon: 'edit',
          visibleOnDesktop: true,
          onClick: () => this.setState({
            currentUpdatePayload: updatePayload
          }, () => openSGDialog(REDUX_FORM.CHANGE_CRON_NOTIFICATION, updatePayload))
        }, {
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          icon: 'trash',
          visibleOnDesktop: true,
          onClick: () => this.setState({
            currentDeletePayload: deletePayload
          }, () => openSGDialog(DIALOGS.GENERIC_DELETE, deletePayload))
        }]}
      />
    );
  };
}

export default indexWithCRUD(undefined, (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
}))(
  CronPage,
  API_RESOURCE.CRON_NOTIFY
);
