import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Flex, Icon, Spacer, Tile, Title } from 'sg-styleguide';
import { ToggleButton } from 'sg-styleguide/lib/composite';
import { WORDPRESS, WORDPRESS_WOOCOMMERCE } from '../constants';
import './logo-tile.scss';

type Props = {
  intl: Intl;
  onClick: Function;
  selected: boolean;
  icon?: any;
  title?: any;
  type?: string;
};

const ICON_PROPS_BY_TYPES = {
  [WORDPRESS]: {
    name: 'logo-wordpress',
    size: '60'
  },
  [WORDPRESS_WOOCOMMERCE]: {
    name: 'logo-wp-plus-woocommerce',
    height: '60px'
  }
};

const TITLES_BY_TYPES = {
  [WORDPRESS]: 'WordPress',
  [WORDPRESS_WOOCOMMERCE]: 'WordPress + WooCommerce'
};

const LogoTile = ({ intl, onClick, selected, type = WORDPRESS, icon, title }: Props) => {
  const classes = [
    'logo-tile',
    selected && 'logo-tile--selected'
  ].filter(Boolean).join(' ');

  return (
    <Tile className={classes} onClick={onClick}>
      <Flex direction="column" margin="none" gutter="none" align="center" justify="center" style={{ height: '100%' }}>
        {icon ? icon : <Icon multicolor {...ICON_PROPS_BY_TYPES[type]} />}

        {title ? title : (
          <Title level="3" color="dark" align="center">
            {TITLES_BY_TYPES[type]}
          </Title>
        )}

        <Spacer />

        <ToggleButton
          selected={selected}
          text={intl.formatMessage({ id: 'translate.generic.select' })}
          textSelected={intl.formatMessage({ id: 'translate.generic.selected' })}
          action="button"
        />
      </Flex>
    </Tile>
  );
};

export default injectIntl(LogoTile);
