import { takeLatest, select, put } from 'redux-saga/effects';
import * as Actions from '../constants/actions';
import { setClientTokenTTLIntoLocalStorage, clearTokenTTL } from '../utils/set-localstorage-client-token-ttl';
import { getDeviceInformation } from 'sg-styleguide/lib/utils';
import { REDUX_FORM } from '../constants/common';
import { redirectToLoginApp } from '../utils/redirect-to-login-app';
import * as sgDialogActions from '../actions/sg-dialog';
import * as requestActions from '../actions/fetch';

function* logoutSaga(action) {
  try {
    const { payload } = action;
    const { sessionExpired } = payload;

    yield put(requestActions.httpRequestStarted(action));

    clearTokenTTL();

    const { session, dialog, routing } = yield select();
    const deviceInformation = getDeviceInformation();
    const redirectHash = routing.locationBeforeTransitions.query.hash;

    const { clientToken } = session;
    const isClientSessionReady = typeof clientToken === 'string' && clientToken.length > 0;
    const isSupported = deviceInformation.browser && deviceInformation.browser.isSupported;

    if (!isClientSessionReady && !dialog[REDUX_FORM.LOGIN_DIALOG] && !redirectHash) {
      if (isSupported && !CONFIG.IS_RESELLER) {
        return redirectToLoginApp({ sessionExpired });
      } else {
        yield put(
          sgDialogActions.openSGDialog(REDUX_FORM.LOGIN_DIALOG)
        );
      }
    }

    yield put(requestActions.httpRequestSucceeded(action));
  } catch (e) {
    yield put(requestActions.httpRequestFailed(action, e));
    console.warn(e);
  }
}

function* logout(): any {
  yield takeLatest(Actions.LOGOUT, logoutSaga);
}

export default logout;
