import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

export type NotificationsState = {
  generic: SpanelNotification[];
  form: {
    [formName: string]: SpanelNotification;
  };
  task: {
    // tasks which completed with generic task notification
    [taskId: string]: Task;
  };
};

export const initialState: NotificationsState = {
  generic: [],
  form: {},
  task: {}
};

const reducerMap: any = {
  [Actions.CREATE_NOTIFICATION]: (state: NotificationsState, { payload }) => {
    const notification: SpanelNotification = payload;

    if (notification.type === 'generic') {
      return {
        ...state,
        generic: [...state.generic, notification]
      };
    }

    if (notification.type === 'form') {
      return {
        ...state,
        form: {
          ...state.form,
          [notification.formName]: {
            ...notification
          }
        }
      };
    }

    return state;
  },
  [Actions.REMOVE_NOTIFICATION]: (state: NotificationsState, { payload }) => {
    const { id, formName } = payload;

    return {
      ...state,
      generic: state.generic.filter((notification) => notification.id !== id),
      form: Object.keys(state.form).reduce((output, notificationFormName) => {
        const requestedToRemoveNotification = Boolean(
          notificationFormName === formName &&
          state.form[notificationFormName].id === id
        );

        if (!requestedToRemoveNotification) {
          return {
            ...output,
            [notificationFormName]: state.form[notificationFormName]
          };
        }

        return output;
      }, {})
    };
  },
  [Actions.CREATE_TASK_NOTIFICATION]: (state: NotificationsState, { payload }) => {
    return {
      ...state,
      task: {
        ...state.task,
        [payload.id]: payload
      }
    };
  },
  [Actions.REMOVE_TASK_NOTIFICATION]: (state: NotificationsState, { payload }) => {
    return {
      ...state,
      task: Object.keys(state.task).reduce((output, taskId: string) => {
        if (taskId !== payload.id) {
          return {
            ...output,
            [taskId]: state.task[taskId]
          };
        }

        return output;
      }, {})
    };
  },
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
