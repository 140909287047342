import * as Actions from '../constants/actions';

import { getEntityName } from '../utils';
import { uploadInitialState } from './utils/initial-state';
import { FileManagerRootState } from './utils/internal-state-definition';

const replaceEntityInArray = (array, entity) => {
  const newArray = [].concat(array);
  const entityIndex = array.findIndex((arrayEntity) => getEntityName(arrayEntity) === getEntityName(entity));

  if (entityIndex > -1) {
    newArray.splice(entityIndex, 1);
  }

  return newArray;
};

const UploadReducer = {
  [Actions.FILE_MANAGER_UPLOAD_FILES]: (state: FileManagerRootState, action: any) => {
    const { requested, pending } = state.uploader;
    const isFinished = requested.length + pending.length === 0;
    const files = action.payload;

    if (isFinished) {
      return {
        ...state,
        uploader: {
          ...uploadInitialState,
          requested: files
        }
      };
    } else {
      const newFailed = state.uploader.failed
        .filter((entity) => !files.find((ent) => getEntityName(entity) === getEntityName(ent)));

      const newRequested = state.uploader.requested
        .concat(files)
        // Remove duplicated entities
        .filter((entity, index, arr) => arr.findIndex((ent) =>
          getEntityName(entity) === getEntityName(ent)) === index
        );

      return {
        ...state,
        uploader: {
          ...state.uploader,
          failed: newFailed,
          requested: newRequested
        }
      };
    }
  },
  [Actions.FILE_MANAGER_UPLOAD_FILE_PENDING]: (state: FileManagerRootState, action: any) => {
    const payload = action.payload;
    return {
      ...state,
      uploader: {
        ...state.uploader,
        requested: state.uploader.requested.filter((entity) => getEntityName(entity) !== getEntityName(payload)),
        pending: state.uploader.pending.concat(payload)
      }
    };
  },
  [Actions.FILE_MANAGER_UPLOAD_FILE_SUCCEEDED]: (state: FileManagerRootState, action: any) => {
    const payload = action.payload;
    return {
      ...state,
      uploader: {
        ...state.uploader,
        pending: replaceEntityInArray(state.uploader.pending, payload),
        failed: replaceEntityInArray(state.uploader.failed, payload),
        uploaded: state.uploader.uploaded.concat([payload])
      }
    };
  },
  [Actions.FILE_MANAGER_UPLOAD_FILE_FAILED]: (state: FileManagerRootState, action: any) => {
    const payload = action.payload;
    const pendingFileIndex = state.uploader.pending
      .findIndex((entity) => entity.urlParams.filename === payload.urlParams.filename);
    const newPending = [].concat(state.uploader.pending);

    newPending.splice(pendingFileIndex, 1);

    return {
      ...state,
      uploader: {
        ...state.uploader,
        pending: newPending,
        failed: state.uploader.failed.concat([payload])
      }
    };
  },
  [Actions.FILE_MANAGER_CLEAR_UPLOAD_PROGRESS]: (state: FileManagerRootState, action: any) => {
    state.uploader.pending.forEach((entity) => entity.close());

    return {
      ...state,
      uploader: {
        ...state.uploader,
        ...uploadInitialState,
        requested: []
      }
    };
  },
  [Actions.FILE_MANAGER_TRANSFER_SIZE_CHANGE]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      uploader: {
        ...state.uploader,
        totalSizeTransferred: state.uploader.totalSizeTransferred + action.payload.transferred
      }
    };
  }
};
export default UploadReducer;
