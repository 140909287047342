import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import CreateBox from '../../../../components/create-box';
import VCS from '../../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl?: Intl;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.MYSQL_ACCESS;
const formName = REDUX_FORM.CREATE_MYSQL_ACCESS;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.database_access.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName: API_RESOURCE.MYSQL_ACCESS.resourceName,
        methods: ['POST']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
