import { applyMiddleware, compose, createStore, Store } from 'redux';
import reduxPersistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';
import invalidateLocalStorage from 'sg-styleguide/lib/utils/invalidate-local-storage';
import rootReducer, { RootState } from '../reducers';
import sagas from '../sagas';

// redux-localstorage is no longer supported and there doesn't
// exist a valid type definition for the package. In order for
// TS to work correctly it needs to be casted as any.
const persistState = reduxPersistState as any;

const environment: any = window || this;

function configureStore(initialState?: RootState): Store<RootState> {
  // Middlewares
  const sagaMiddleware = createSagaMiddleware();

  // increase revision you want to invalidate by 1
  invalidateLocalStorage({
    spanel_session: 1,
    spanel_i18n: 3,
    spanel_sites: 3
  }, 'spanel_invalidation');

  let enhancer;
  if (CONFIG.PLATFORM === 'native') {
    enhancer = compose(
      applyMiddleware(sagaMiddleware),
      environment.devToolsExtension ? environment.devToolsExtension() : (f) => f
    );
  } else {
    enhancer = compose(
      applyMiddleware(sagaMiddleware),
      persistState('session', { key: 'spanel_session' }),
      persistState('i18n', { key: 'spanel_i18n' }),
      persistState('sites', { key: 'spanel_sites' }),
      // persistState('tasks', { key: 'tasks' }),
      environment.devToolsExtension ? environment.devToolsExtension() : (f) => f
    );
  }

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(sagas as any);

  // Allowing redux dev tools to trigger sagas middleware
  const { devToolsExtension } = window as any;
  if (devToolsExtension) {
    devToolsExtension.updateStore(store);
  }

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore();
