import { RootState } from '../reducers';

export const getCurrentDomainName = ({ sites }: Partial<RootState>) => sites.currentDomainName || null;

export const getCurrentSite = ({ sites }: Partial<RootState>) => {
  const domainName = getCurrentDomainName({ sites });
  const { items = [] } = sites;
  return items.find(({ domain }) => domain === domainName);
};

export const getCurrentSiteId = ({ sites }: Partial<RootState>): string => {
  const currentSite = getCurrentSite({ sites });
  return currentSite && currentSite.id;
};

export const getCurrentSiteAccountId = ({ sites }: Partial<RootState>): string => {
  const currentSite = getCurrentSite({ sites });
  return currentSite && currentSite.account_id;
};
