import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Title, Button, Grid, Text, Column, Icon, List, ListItem, Link, Notice } from 'sg-styleguide';
import { NormalizedReport, MalwareReportEntry } from '../../../../core/definitions/sg-sitescanner';
import customRequestTypes from '../../../../core/constants/custom-request-types';

import CreateBox from '../../../components/create-box';
import WarningsList from '../warnings-list';

type Props = {
  intl?: Intl;
  lastReport: NormalizedReport;
  clean: boolean
  environment?: {
    isPhone: boolean;
  };
  onMalwareDetails: (malware: MalwareReportEntry[]) => any;
  onForceScan: Function;
  onCleanUp: Function;
};

export const SITESCANNER_SCAN_FORM = 'SITESCANNER_SCAN_FORM';

const renderStatus = ({ lastReport, clean, intl, onMalwareDetails }: Props) => {
  switch (true) {
    case clean:
      return (
        <Notice
          title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean.label' })}
          background="light"
          type="success"
        >
          <Text>
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean.desc' })}
          </Text>
        </Notice>
      );
    case lastReport.status === 'malware' || lastReport.status === 'blacklisted' :
      return (
        <Notice
          title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.threat-found.label' })}
          type="warning"
          background="light"
          shadow={false}
        >
          <WarningsList
            icon="dot"
            onMalwareDetails={onMalwareDetails}
            report={lastReport}
          />
        </Notice>
      );
    case lastReport.status === 'timeout' :
      return (
        <Notice
          title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.timeout.label' })}
          type="warning"
          background="light"
          shadow={false}
        >
          <WarningsList
            icon="dot"
            report={lastReport}
          />
        </Notice>
      );
    default:
      return null;
  }
};

const CreateContainer = (props: Props) => {
  const { intl, environment, onCleanUp, onForceScan, clean } = props;

  return (
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.create-box.title' })}
      formName={SITESCANNER_SCAN_FORM}
      resources={[
        { requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES },
        { requestTypeName: customRequestTypes.REQUEST_FORCE_SCAN }
      ]}
    >
      <Grid
        sm="12"
        padding={environment.isPhone ? 'medium' : 'large'}
        gap={environment.isPhone ? 'medium' : 'large'}
      >
        <Column smSpan="12">
          {renderStatus(props)}
        </Column>

        <Column smSpan="12">
          {
            !clean && (
              <Button
                color="primary"
                action="button"
                data-e2e="create-box-clean-up"
                onClick={onCleanUp}
              >
                {intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean-up.label' })}
              </Button>
            )
          }
          <Button
            color="primary"
            action="button"
            data-e2e="create-box-force-scan"
            onClick={onForceScan}
          >
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.force-scan.label' })}
          </Button>
        </Column>
      </Grid>
    </CreateBox>
  );
};

export default connect<any, any, Props>((state) => ({ environment: state.environment }))(
  injectIntl(CreateContainer)
);
