import * as React from 'react';
import { Box, ChartContainer, ChartTooltip, Flex, Grid, LineChart } from 'sg-styleguide';
import { Days, StatsType } from '../../../../core/definitions/stats';
import StatisticsLineChart from './line-charts';

type Props = {
  getStats: (statsKey: StatsType) => Days[];
  renderDatepicker: Function;
  intl: Intl;
};

export const Traffic = ({ getStats, renderDatepicker }: Props) => {
  const stats = getStats('days');

  return (
    <Grid gap="x-large">
      <Flex gutter="none">
        <Box sm="3">
          {renderDatepicker()}
        </Box>
      </Flex>
      <StatisticsLineChart stats={stats} type="unique-visitors"/>
      <StatisticsLineChart stats={stats} type="pageviews"/>
      <StatisticsLineChart stats={stats} type="average-number-of-pages"/>
      <StatisticsLineChart stats={stats} type="bandwidth"/>
    </Grid>
  );
};

export default Traffic;
