import * as ACTIONS from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

const defaultFetchSiteMetaApiParams = {
  requestTypeName: customRequestTypes.FETCH_SITE_META_API
};

export const fetchSiteMetaApi = ({ requestTypeName } = defaultFetchSiteMetaApiParams) => ({
  type: ACTIONS.FETCH_SITEMETAAPI_REQUESTED,
  requestTypeName
});

export const fetchSiteMetaApiSucceeded = (siteMetaApi) => ({
  type: ACTIONS.FETCH_SITEMETAAPI_SUCCEEDED,
  payload: {
    siteMetaApi
  }
});
