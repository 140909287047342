import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, ButtonsGroup, Flex, Text, TextProps } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import ListBox from '../../../components/list-box';
import PartialLoader from '../../../containers/partial-loader';
import { SGDialog, SGDialogCancel } from '../../../containers/sg-dialog';
import { SGSubmitButton } from '../../../containers/sg-form';
import MailClientForm from './mail-client-form';
import './autoconfigure.scss';

enum SettingsChoice {
  Autoconfigure = 'autoconfigure',
  Manual = 'manual'
};

const SETTINGS_CHOICE_OPTIONS: SettingsChoice[] = [
  SettingsChoice.Autoconfigure,
  SettingsChoice.Manual
];

type Props = {
  selectedDomain: any;
  emailId: string;
  userEmail: string;
  onSubmit: Function;
  onClose: Function;
  intl: Intl;
};

type State = {
  selectedIndex: 0 | 1;
  settingsChoice: SettingsChoice;
};

const MANUAL_CONFIG_VALUES = {
  ports: {
    imap: 993,
    pop3: 995,
    smtp: 465
  }
};

const flexWrapperStyle = { maxWidth: '100%', width: '100%' };
const textProps: Partial<TextProps> = { size: 'medium', color: 'dark' };

const initialState: State = {
  selectedIndex: 0,
  settingsChoice: SettingsChoice.Autoconfigure
};

class Autoconfigure extends React.Component<Props, State> {
  readonly state: State = initialState;

  renderFooter = () => {
    const { intl, onClose, onSubmit } = this.props;
    const { settingsChoice } = this.state;

    switch (this.state.settingsChoice) {
      case SettingsChoice.Autoconfigure:
        return (
          <div>
            <SGDialogCancel id={DIALOGS.EMAIL_AUTOCONFIGURE} />
            <SGSubmitButton
              color="primary"
              formName={REDUX_FORM.CHOOSE_MAIL_CLIENT_FORM}
            >
              {intl.formatMessage({ id: 'translate.generic.generate' })}
            </SGSubmitButton>
          </div>
        );
      default:
        return (
          <div>
            <SGDialogCancel
              id={DIALOGS.EMAIL_AUTOCONFIGURE}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
          </div>
        );
    }
  };

  renderAutoconfigureSelect = () => (
    <React.Fragment>
      <Flex style={flexWrapperStyle}>
        <Text align="center" {...textProps}>
          {this.props.intl.formatMessage({ id: 'translate.page.email.mail.config.desc' })}
        </Text>
      </Flex>
      <Flex style={{ ...flexWrapperStyle, marginBottom: 0 }}>
        <MailClientForm onSubmit={({ emailClientId }) => this.props.onSubmit(emailClientId)} />
      </Flex>
    </React.Fragment>
  );

  renderManualSettings = () => {
    const { selectedDomain } = this.props;
    const imapHost = selectedDomain && selectedDomain.config.imap_host;
    const smtpHost = selectedDomain && selectedDomain.config.smtp_host;

    return (
      <React.Fragment>
        <Flex style={flexWrapperStyle}>
          <Text {...textProps}>
            {this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.use.the.settings.text' })}
          </Text>
        </Flex>

        <Flex style={flexWrapperStyle}>
          <ListBox
            label={this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.label' })}
            data={[
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.username.label' }),
                value: this.props.userEmail
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.password.label' }),
                value: this.props.intl.formatMessage({ id: 'translate.page.email.use.user.password' })
              },
              {
                label: this.props.intl.formatMessage(
                  { id: 'translate.page.email.manual.settings.incoming.server.label' }
                ),
                value: imapHost
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.imap.port.label' }),
                value: MANUAL_CONFIG_VALUES.ports.imap
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.pop3.port.label' }),
                value: MANUAL_CONFIG_VALUES.ports.pop3
              },
              {
                label: this.props.intl.formatMessage(
                  { id: 'translate.page.email.manual.settings.outgoing.server.label' }
                ),
                value: smtpHost
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.smtp.port.label' }),
                value: MANUAL_CONFIG_VALUES.ports.smtp
              }
            ]}
          />
        </Flex>

        <Flex style={{ ...flexWrapperStyle, marginBottom: 0 }}>
          <Text {...textProps}>
            {this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.authentication.required.text' })}
          </Text>
        </Flex>
      </React.Fragment>
    );
  };

  render() {
    const { intl, emailId } = this.props;
    const { settingsChoice } = this.state;

    const buttons = [
      intl.formatMessage({ id: 'translate.page.email.settings.autoconfig' }),
      intl.formatMessage({ id: 'translate.page.email.settings.manual' })
    ];

    return (
      <SGDialog
        id={DIALOGS.EMAIL_AUTOCONFIGURE}
        icon="mail-settings"
        state="warning"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.email.pop.imap.settings.title' })}
        footer={this.renderFooter()}
        onCloseHandler={() => this.setState({ ...initialState })}
        resources={[{
          resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
          methods: ['GET']
        }]}
      >
        <PartialLoader
          resources={[{
            requestTypeName: customRequestTypes.EMAIL_AUTOCONFIGURE_DATA
          }, {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['PUT']
          }]}
        >
          <ButtonsGroup
            activeIndex={SETTINGS_CHOICE_OPTIONS.indexOf(this.state.settingsChoice)}
            buttons={buttons}
            color="secondary"
            size="medium"
            onChange={(index) =>
              this.setState({ settingsChoice: SETTINGS_CHOICE_OPTIONS[index] })
            }
          />
          {settingsChoice === SettingsChoice.Autoconfigure && this.renderAutoconfigureSelect()}
          {settingsChoice === SettingsChoice.Manual && this.renderManualSettings()}
        </PartialLoader>
      </SGDialog>
    );
  }
}

export default injectIntl(Autoconfigure);
