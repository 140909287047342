import * as React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Drawer, Layout, Page, Placeholder } from 'sg-styleguide';
import { NotSupportedBrowser } from 'sg-styleguide/lib/composite';
import 'sg-styleguide/lib/styles/main.scss';
import { getDeviceInformation } from 'sg-styleguide/lib/utils';
import { sendLog } from 'sg-styleguide/lib/utils/log';
import * as deviceActions from '../../../core/actions/device';
import * as fetchActions from '../../../core/actions/fetch';
import * as sessionActions from '../../../core/actions/session';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS, PAGE_LOAD_REQUESTS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { RootState } from '../../../core/reducers';
import '../../assets/styles/main.scss';
import Notifications from '../../containers/notifications';
import PartialLoader from '../../containers/partial-loader';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';
import TaskLoader from '../../containers/task-loader';
import ToolFinder from '../../containers/tool-finder/tool-finder';
import SGHeader from './header';
import LoginForm from './login-form';
import Navigation from './navigation';
import NoSitePlaceholder from './no-site-placeholder';
import ToolFinderButton from './tool-finder-button';

type Props = {
  dialog: any;
  deviceActions: any;
  sessionActions: typeof sessionActions;
  fetchActions: typeof fetchActions;
  session: any;
  routing: any;
  sites: any;
  i18n: any;
  login: Function;
  location: any;
  notifications: any;
  pageItems: any;
  router: any;
  redirectFromRemote: Function;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  drawerIsOpen: boolean;
  toolFinderIsOpen: boolean;
  selectedGroups: any[string];
  error: string;
  errorInfo: string;
};

const MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED = 1500;

class App extends React.Component<Props, State> {
  getDeviceInformation = getDeviceInformation();
  lastRoute;

  state = {
    drawerIsOpen: true,
    toolFinderIsOpen: false,
    selectedGroups: [],
    error: null,
    errorInfo: null
  };

  redirectHash() {
    const { routing } = this.props;

    return routing.locationBeforeTransitions.query.hash;
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any child components and re-renders with an error message
    this.setState({ error, errorInfo });

    let username = null;
    if (CONFIG.ERROR_LOG_USER) {
      const spanelSession = localStorage.getItem('spanel_session');
      if (spanelSession) {
        const JSONData = JSON.parse(spanelSession);
        if (JSONData && JSONData.session && JSONData.session.user && JSONData.session.user.username) {
          username = JSONData.session.user.username;
        }
      }
    }

    sendLog({
      app: 'spanel',
      error,
      errorInfo,
      data: JSON.stringify({
        url: window.location.href,
        username
      })
    });
  }

  componentWillMount() {
    const { sessionActions } = this.props;

    const pageLoadPayload = { redirectHash: this.redirectHash() };

    sessionActions.pageLoad(pageLoadPayload);
  }

  componentDidMount() {
    this.updateDrawerState();
    window.addEventListener('resize', this.updateDrawerState.bind(this));
    window.addEventListener('keydown', this.onKeyDownHandler.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDrawerState.bind(this));
    window.removeEventListener('keydown', this.onKeyDownHandler.bind(this));
  }

  toggleDrawer = () => {
    this.setState({ drawerIsOpen: !this.state.drawerIsOpen });
  };

  closeToolFinder = () => {
    this.setState({ toolFinderIsOpen: false });
  };

  renderPage() {
    const { session, login, sites, routing, closeSGDialog } = this.props;
    const { drawerIsOpen, toolFinderIsOpen } = this.state;
    const clientToken = session.clientToken;
    const currentSiteId = routing.locationBeforeTransitions.query.siteId;

    // Sessions dependency hell
    const isClientSessionReady: boolean = typeof clientToken === 'string' && clientToken.length > 0;
    const isSitesLoaded: boolean = typeof sites.items === 'object' && sites.items.length && !sites.items.loading;
    const siteToken = session.siteTokens;
    const isSiteTokenAvailable: boolean = siteToken && typeof siteToken[currentSiteId] === 'object';

    const siteSessionReady: boolean = isSitesLoaded && isClientSessionReady && isSiteTokenAvailable;
    let childrenToRender = null;

    if (!siteSessionReady && sites.items && sites.items.length === 0) {
      childrenToRender = <NoSitePlaceholder />;
    }

    if (siteSessionReady) {
      childrenToRender = this.props.children;
    }

    return (
      <React.Fragment>
        {toolFinderIsOpen && (
          <ToolFinder handleClose={this.closeToolFinder} router={this.props.router} />
        )}

        <Notifications />

        <SGHeader open={drawerIsOpen} toggleDrawer={this.toggleDrawer} />

        <TaskLoader />

        <PartialLoader
          resources={PAGE_LOAD_REQUESTS}
          position="sticky"
          showOnlyLoader
          initialLoadingState
        >
          <Page translate={drawerIsOpen} padding={['inherit', 'inherit', 'large', 'inherit']}>
            {childrenToRender}
          </Page>

          <Drawer open={drawerIsOpen} onClose={this.toggleDrawer}>
            <Navigation toggleDrawer={this.toggleDrawer} />

            <ToolFinderButton onClick={() => this.setState({ toolFinderIsOpen: true })} />
          </Drawer>
        </PartialLoader>

        <SGDialog
          id={REDUX_FORM.LOGIN_DIALOG}
          disableClose={!isClientSessionReady}
          resources={[{ requestTypeName: customRequestTypes.LOGIN }]}
        >
          <LoginForm onSubmit={(data) => login(data, () => closeSGDialog(REDUX_FORM.LOGIN_DIALOG))} />
        </SGDialog>

        <SGDialog
          id={DIALOGS.NEW_TAB_DIALOG}
          icon="warning"
          state="warning"
          title={this.props.i18n.messages['translate.new.tab.blocked.title']}
          footer={
            <SGDialogCancel
              id={DIALOGS.NEW_TAB_DIALOG}
              label={this.props.i18n.messages['translate.generic.close']}
            />
          }
        >
          {this.props.i18n.messages['translate.new.tab.blocked.message']}
        </SGDialog>
      </React.Fragment>
    );
  }

  renderContent() {
    const { error } = this.state;
    const isSupported = this.getDeviceInformation.browser && this.getDeviceInformation.browser.isSupported;

    if (error) {
      return (
        <div style={{ overflow: 'auto', height: '100%' }}>
          <Placeholder
            title={this.props.i18n.messages['translate.generic.error.page.title']}
            message={this.props.i18n.messages['translate.generic.error.page.subtitle']}
            icon="presentational-page-error"
            iconColor="secondary"
            background="lighter"
            size="large"
          />
        </div>
      );
    }

    return isSupported ? this.renderPage() : <NotSupportedBrowser />;
  }

  render() {
    const { i18n } = this.props;
    let messages = i18n.messages;

    if (process.env.APP_ENV !== 'production' && !i18n.showMessages) {
      messages = Object.keys(i18n.messages).map((m) => ({ m }));
    }

    return (
      <IntlProvider locale="en" messages={messages}>
        <div>
          <Layout
            context={{ config: { assetsPath: process.env.ASSETS_PATH } }}
            onInit={(device) => this.props.deviceActions.onPageInit(device)}
            onResize={(device) => this.props.deviceActions.onPageResize(device)}
          >
            {this.renderContent()}
          </Layout>
        </div>
      </IntlProvider>
    );
  }

  updateDrawerState() {
    if (window.innerWidth < MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED && this.state.drawerIsOpen === true) {
      this.setState({ drawerIsOpen: false });
    } else if (window.innerWidth > MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED && this.state.drawerIsOpen === false) {
      this.setState({ drawerIsOpen: true });
    }
  }

  onKeyDownHandler(event) {
    if (event.keyCode === 27) {
      this.setState({ toolFinderIsOpen: false });
    } else if (event.keyCode === 75 && event.ctrlKey) {
      this.setState({ toolFinderIsOpen: true });
      event.preventDefault();
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  dialog: state.dialog,
  session: state.session,
  routing: state.routing,
  sites: state.sites,
  i18n: state.i18n,
  notifications: state.notifications,
  pageItems: state.pageItems
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  login: (data, onComplete) => dispatch(sessionActions.loginUser(data, onComplete)),
  deviceActions: bindActionCreators(deviceActions as any, dispatch),
  sessionActions: bindActionCreators(sessionActions as any, dispatch),
  fetchActions: bindActionCreators(fetchActions as any, dispatch)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(App as any);
