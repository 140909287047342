import * as React from 'react';
import { injectIntl } from 'react-intl';
import Section from 'sg-styleguide/lib/components/layout/section';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import ROUTES from '../../../core/constants/routes';

interface PgsqlPageProps {
  intl: any;
  location: any;
  router: any;
  children: any;
}

export const PgsqlPage = ({ intl, location, router, children }: PgsqlPageProps) => (
  <React.Fragment>
    <PageHeader
      title={intl.formatMessage({ id: 'translate.page.pgsql.title' })}
      icon="presentational-pgsql-database"
      instructions={intl.formatMessage({ id: 'translate.page.pgsql.instructions' })}
    />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          database: intl.formatMessage({ id: 'translate.generic.databases' }),
          user: intl.formatMessage({ id: 'translate.generic.users' }),
          access: intl.formatMessage({ id: 'translate.generic.remote' })
        }}
        routesByTabId={{
          database: ROUTES.PGSQL_DATABASE,
          user: ROUTES.PGSQL_USER,
          access: ROUTES.PGSQL_ACCESS
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>
      {children}
    </Section>
  </React.Fragment>
);

export default injectIntl(PgsqlPage);
