import * as React from 'react';
import { Button, Grid, Link } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import ROUTES from '../../../../core/constants/routes';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../../containers/dialogs';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../containers/sg-dialog';
import VCS from '../../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create/index';
import UpdateProtectedUsers from './update/fields';

interface RedirectURLsProps {
  actions: any;
  items: any;
  location: any;
  intl: Intl;
  router: any;
  closeSGDialog: Function;
  openSGDialog: Function;
};

class ProtectedURLs extends React.Component<RedirectURLsProps, any> {
  readonly state = {
    currentDeleteConformationDialogPayload: null,
    currentManageProtectedUsersPayload: null,
    promptToCreateUser: false
  };

  /* filters */
  arrangeOptionsData(data) {
    return data.sort((a, b) => {
      return (a.id - b.id);
    });
  }

  pickerOptions() {
    const { items } = this.props;
    const allOptions = [];
    const pickerEndpoints = ['domain'];

    pickerEndpoints.forEach((endpointId) => {
      if (Object.keys(items).includes(endpointId)) {
        allOptions.push(...this.arrangeOptionsData(items[endpointId]));
      }
    });

    return allOptions;
  }

  updateProtectedUsers() {
    const { currentManageProtectedUsersPayload } = this.state;
    const entityName = currentManageProtectedUsersPayload && currentManageProtectedUsersPayload.path;
    const { intl, closeSGDialog } = this.props;

    const initialValues = {
      ...currentManageProtectedUsersPayload,
      user_ids: currentManageProtectedUsersPayload && currentManageProtectedUsersPayload.user_ids.concat(''),
      realm: 'Protected Folder'
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_PROTECTED_URLS_DIALOG}
        icon="add-user"
        title={intl.formatMessage({ id: 'translate.page.protected.urls.update.access.title' }, { name: entityName })}
        resources={[{
          resourceName: API_RESOURCE.LOCATION_PROTECT.resourceName,
          methods: ['PUT']
        }]}
      >
        <UpdateProtectedUsers
          initialValues={initialValues}
          onSubmit={(entity) => {
            this.props.actions.updateItem({
              ...entity,
              _metaFields: {
                ...entity._metaFields
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.protected.url.updated_msg',
                    intlValues: { account: `${entity.domain_name}${entity.path}` }
                  },
                  error: {
                    intlKey: 'translate.page.protected.url.failed_update_msg',
                    intlValues: { account: `${entity.domain_name}${entity.path}` }
                  }
                }
              }
            }, () => closeSGDialog(REDUX_FORM.CHANGE_PROTECTED_URLS_DIALOG));
          }}
        />
      </SGDialogForm>
    );
  }

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_ITEM_LOCATION_PROTECT,
          success: {
            intlKey: 'translate.page.protected.urls.create.success.message'
          },
          error: {
            intlKey: 'translate.page.protected.urls.create.error.message'
          }
        }
      }
    });
  };

  render() {
    const { items, intl, router, actions, location } = this.props;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.url' }),
      accessor: 'domain_name',
      render: (domain, entity) => {
        return (
          <div>{`${domain}${entity.path}`}</div>
        );
      }
    }, {
      header: intl.formatMessage({ id: 'translate.generic.users' }),
      accessor: 'user_ids',
      render: this.renderUsersBasedOnId
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderContextMenu
    }];

    return (
      <Grid>
        <CreateBox
          locationUser={items[API_RESOURCE.LOCATION_USER.resourceName]}
          invokeChangeUsersAccess={this.renderUpdateUsersDialog}
          onCreateUser={() => router.push(`${ROUTES.PROTECTED_USERS}${location.search}`)}
        >
          <CreateForm
            onSubmit={this.onCreateFormSubmit}
            domainOptions={this.pickerOptions()}
          />
        </CreateBox>

        {this.renderDeleteConformationDialogComponent()}
        {this.renderCreateUsersPromptDialog()}
        {this.updateProtectedUsers()}

        <VCS resourceName={API_RESOURCE.LOCATION_PROTECT.resourceName} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.protected.url.list.title' })}
            data={this.props.items.locationProtect || []}
            columns={columns}
            resources={[{ resourceName: API_RESOURCE.LOCATION_PROTECT.resourceName, methods: ['GET'] }]}
            noDataMessage="translate.page.protected.urls.sg-table.no-data.message"
          />
        </VCS>
      </Grid>
    );
  };

  renderUsersBasedOnId = (userIDs, entity) => {
    const { intl } = this.props;
    const justSelectedFormValues = new Set(userIDs);
    const locationUser = this.props.items.locationUser || [];
    const selectedUsers = locationUser.filter((obj) => justSelectedFormValues.has(obj.id));
    const count = selectedUsers.length;

    if (count === 0) {
      return null;
    }

    return (
      <Link
        onClick={() => this.renderUpdateUsersDialog(entity)}>
        {
          selectedUsers.length === 1 ?
            selectedUsers[0].username :
            `${count} ${intl.formatMessage({ id: 'translate.generic.users' })}`
        }
      </Link>
    );
  };

  renderUpdateUsersDialog = (entity) => {
    const { openSGDialog } = this.props;

    const manageProtectedUsersPayload = {
      initialValues: { user_ids: [''], realm: 'Protected Folder' },
      _metaFields: {
        fetchItemsAfterUpdate: true,
        ...API_RESOURCE.LOCATION_PROTECT
      },
      ...entity
    };

    this.setState(
      { currentManageProtectedUsersPayload: manageProtectedUsersPayload },
      () => openSGDialog(REDUX_FORM.CHANGE_PROTECTED_URLS_DIALOG)
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, items, openSGDialog } = this.props;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      fullPath: `${entity.domain_name}${entity.path}`,
      _metaFields: { ...API_RESOURCE.LOCATION_PROTECT },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.protected.url.deleted_msg',
            intlValues: { account: `${entity.domain_name}${entity.path}` }
          },
          error: {
            intlKey: 'translate.page.protected.url.failed_delete_msg',
            intlValues: { account: `${entity.domain_name}${entity.path}` }
          }
        }
      }
    };

    const manageProtectedUsersPayload = {
      _metaFields: {
        fetchItemsAfterUpdate: true,
        ...API_RESOURCE.LOCATION_PROTECT
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.protected.url.updated_msg',
            intlValues: { account: `${entity.domain_name}${entity.path}` }
          },
          error: {
            intlKey: 'translate.page.protected.url.failed_update_msg',
            intlValues: { account: `${entity.domain_name}${entity.path}` }
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.LOCATION_PROTECT.resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          icon: 'add-user',
          label: intl.formatMessage({ id: 'translate.page.protected.user.manage.access' }),
          e2eAttr: 'table-action-add-user',
          visibleOnDesktop: true,
          onClick: () => {
            if (items[API_RESOURCE.LOCATION_USER.resourceName].length > 0) {
              this.setState(
                { currentManageProtectedUsersPayload: manageProtectedUsersPayload },
                () => openSGDialog(REDUX_FORM.CHANGE_PROTECTED_URLS_DIALOG)
              );
            } else {
              this.setState(
                { promptToCreateUser: true },
                () => openSGDialog(DIALOGS.USER_CREATE_PROMPT)
              );
            }
          }
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.fullPath;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.protected.url.delete.title' }, { url: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderCreateUsersPromptDialog = () => {
    const { intl, router, closeSGDialog } = this.props;

    return (
      <SGDialog
        id={DIALOGS.USER_CREATE_PROMPT}
        state="info"
        icon="information"
        title={intl.formatMessage({ id: 'translate.page.protected.urls.no.user.dialog.title' })}
        footer={(
          <div>
            <SGDialogCancel id={DIALOGS.USER_CREATE_PROMPT} />
            <Button
              color="primary"
              action="reset"
              data-e2e="dialog-navigate"
              onClick={() => {
                router.push(`${ROUTES.PROTECTED_USERS}${location.search}`);
                this.setState(
                  { promptToCreateUser: false },
                  closeSGDialog(DIALOGS.USER_CREATE_PROMPT)
                );
              }}
            >
              {intl.formatMessage({ id: 'translate.page.protected.urls.create.user' })}
            </Button>
          </div>
        )}
      />
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default indexWithCRUD(undefined, mapDispatchToProps)(
  ProtectedURLs,
  API_RESOURCE.DOMAIN,
  API_RESOURCE.LOCATION_USER,
  API_RESOURCE.LOCATION_PROTECT
);
