export const generateRandomPath = (count: number) => {
  const possible = 'abcdefghijklmnopqrstuvwxyz';

  let text = '';
  let length = 0;

  do {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
    length = length + 1;
  } while (length < count);

  return text;
};
