import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Avatar, ContextMenu, ContextMenuItem, DrawerIcon, Header, Icon, Spacer } from 'sg-styleguide';
import { toggleTranslationKeys } from '../../../../core/actions/i18n';
import * as sessionActions from '../../../../core/actions/session';
import SupportPopup from '../../../containers/support-popup';
import './sg-header.scss';

const isNotProduction = process.env.APP_ENV !== 'production';

const SGHeader = ({ intl, open, logout, session, toggleDrawer, toggleTranslationKeys }: any) => {
  const staticURL = CONFIG.STATIC_URL + '/';
  const avatarURL = session.user.avatar && `${staticURL}${session.user.avatar}`;
  const firstName = session.user.first_name || '';
  const lastName = session.user.last_name || '';

  return (
    <Header className="sp-header">
      <div className="sp-header-wrapper">
        <DrawerIcon className="sg-header-drawer-icon" open={open} onClick={toggleDrawer} />

        <Icon name="logo-siteground" width="140" height="40" multicolor style={{ marginLeft: '10px' }} />

        {isNotProduction && (
          <div
            data-e2e="app-version"
            style={{ paddingLeft: '20px' }}
          >
            {`${process.env.APP_ENV} ${process.env.VERSION}`}
          </div>
        )}

        <Spacer />
        <SupportPopup />
        <ContextMenu
          opener={
            <Avatar key="avatar" className="sg-avatar" firstName={firstName} lastName={lastName} src={avatarURL} />
          }
        >
          <ContextMenuItem icon="cross" onClick={logout}>
            {intl.formatMessage({ id: 'translate.sg-header.logout' })}
          </ContextMenuItem>

          {isNotProduction && (
            <ContextMenuItem icon="product-assistance" onClick={toggleTranslationKeys}>
              Show/Hide translation keys
            </ContextMenuItem>
          )}
        </ContextMenu>
      </div>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  session: state.session
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(sessionActions.logoutUser()),
  toggleTranslationKeys: () => dispatch(toggleTranslationKeys())
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(SGHeader));
