import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Flex, Grid, Label, Notice, Text } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { DOMAIN_TYPES } from '../../../../core/constants/domains';
import FormDropdown from '../../../components/form-dropdown';
import FormTextarea from '../../../components/form-textarea';
import { SGForm } from '../../../containers/sg-form';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';
import { PREMIUM_LABEL } from '../constants';

const PREMIUM_SSL = 'premium';

type Domain = {
  id: string;
  name: string;
  domain_type: string;
};

type Props = {
  intl: Intl;
  form: string;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: Function;
  selectedDomainId: string;
  selectedCertificates: Array<{
    info: string;
    label: string;
  }>;
  domainSelectOptions: Domain[];
  formValues: Function;
  change: Function;
  onChangeDomain: (domainId: string) => any;
  openSGDialog: Function;
  goToUA: Function;
  getFormValues: Function;
  isUAEnabled: boolean;
};

class Create extends React.Component<Props, any> {
  componentDidUpdate(prevProps) {
    const { formValues, selectedDomainId, change, form } = this.props;
    const values = formValues(form);

    if (!values.domain_id && selectedDomainId) {
      change('domain_id', selectedDomainId);
    }

    if (this.isSubDomain(selectedDomainId) && !this.isSubDomain(prevProps.selectedDomainId)) {
      change('generate', null);
    }
  }

  hasCertLetsEncrypt(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert || !cert.info || !cert.info.cert) {
        return false;
      }

      return cert.info.cert.le === 1;
    });
  }

  hasCertLetsEncryptWildcard(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert || !cert.info || !cert.info.cert) {
        return false;
      }

      return cert.info.cert.wildcard === 1 && cert.label !== PREMIUM_LABEL;
    });
  }

  hasCertPremium(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert) {
        return false;
      }

      return cert.label === PREMIUM_LABEL;
    });

  }

  isSubDomain = (id) => {
    const { domainSelectOptions = [] } = this.props;

    return domainSelectOptions.find((d) => d.id === id && d.domain_type === DOMAIN_TYPES.sub_domain);
  };

  isSelectedDomainSub = () => {
    const { getFormValues, form } = this.props;
    const formValues = getFormValues(form);

    return this.isSubDomain(formValues.domain_id);
  };

  renderInstallBoxContent = () => {
    const { intl, sPanelFormSubmit, form, goToUA, selectedCertificates, isUAEnabled, validationUtils } = this.props;
    const formValues = this.props.getFormValues(form);
    const isCertLetsEncrypt = this.hasCertLetsEncrypt(selectedCertificates);
    const isCertWildcard = this.hasCertLetsEncryptWildcard(selectedCertificates);
    const isCertPremium = this.hasCertPremium(selectedCertificates);
    const { required } = validationUtils;

    const dropdownOptions: any = [{
      label: intl.formatMessage({ id: 'translate.page.ssl.lets.encrypt' }),
      value: 0,
      current: isCertLetsEncrypt,
      disabled: isCertPremium || isCertLetsEncrypt
    }, {
      label: intl.formatMessage({ id: 'translate.page.ssl.lets.encrypt.wildcard' }),
      value: 1,
      current: isCertWildcard,
      disabled: isCertPremium || isCertWildcard
    }];

    if (!this.isSelectedDomainSub()) {
      dropdownOptions.push({
        label: intl.formatMessage({ id: 'translate.page.ssl.premium.wildcard' }),
        value: PREMIUM_SSL,
        current: isCertPremium,
        disabled: isCertPremium
      });
    }

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="generate"
            label={intl.formatMessage({ id: 'translate.page.ssl.select.ssl' })}
            disableAutoSelect
            optionValue="value"
            optionLabel="label"
            optionDisabled="disabled"
            options={dropdownOptions}
            disabled={this.props.selectedDomainId === null || this.props.selectedDomainId === undefined}
            template={(option, { isOption }) => {
              if (!isOption) {
                return option.label;
              }

              return (
                <Flex margin="none" gutter="none" align="center" justify="space-between" wrap="nowrap">
                  <Text truncate style={{ lineHeight: 'inherit' }}>{option.label}</Text>

                  {option.current && (
                    <Label type="info">{intl.formatMessage({ id: 'translate.generic.current' })}</Label>
                  )}
                </Flex>
              );
            }}
            validate={[required]}
            component={FormDropdown}
          />
        </Column>

        <Column key="button" smSpan="12">
          {isCertPremium && (
            <Notice type="info" background="light" shadow={false}>
              {intl.formatMessage({ id: 'translate.page.ssl.install.premium.text' })}

              {!isUAEnabled && (
                <br />
              )}

              {!isUAEnabled && (
                intl.formatMessage({ id: 'translate.page.ssl.install.premium.no.ua.text' })
              )}
            </Notice>
          )}

          {!isCertPremium && (
            <Button
              color="primary"
              action="button"
              data-e2e={formValues.generate === PREMIUM_SSL ? 'create-box-submit-upgrade-to-plus' : 'create-box-submit'}
              onClick={(e) => formValues.generate === PREMIUM_SSL ? goToUA() : sPanelFormSubmit(form)(e)}
            >
              {intl.formatMessage({
                id:
                  formValues.generate === PREMIUM_SSL ?
                    'translate.page.ssl.order.ssl' :
                    'translate.page.ssl.get.ssl'
              })}
            </Button>
          )}
        </Column>
      </React.Fragment>
    );
  };

  renderImportBoxContent() {
    const { intl, validationUtils, sPanelFormSubmit, form } = this.props;
    const { required, validBase64Encode, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column key="content" smSpan="12">
          <Grid>
            <Field
              name="cert"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.crt' })}
              rows="4"
              validate={[required, validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />

            <Field
              name="key"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.key' })}
              rows="4"
              validate={[required, validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />

            <Field
              name="cabundle"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.cabundle' })}
              rows="4"
              validate={[validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />
          </Grid>
        </Column>

        <Column key="button" smSpan="12">
          <Button
            color="primary"
            action="button"
            data-e2e="create-box-submit"
            onClick={(e) => sPanelFormSubmit(form)(e)}
          >
            {intl.formatMessage({ id: 'translate.generic.create' })}
          </Button>
        </Column>
      </React.Fragment>
    );
  }

  renderCreateBoxTabContent() {
    const { form } = this.props;

    switch (form) {
      case REDUX_FORM.CREATE_INSTALL_SSL:
        return this.renderInstallBoxContent();
      case REDUX_FORM.CREATE_SSL_IMPORT:
        return this.renderImportBoxContent();
      default:
        return null;
    }
  }

  render() {
    const {
      domainSelectOptions,
      onChangeDomain,
      intl,
      validationUtils,
      sPanelFormSubmit,
      form
    } = this.props;

    const { required } = validationUtils;

    return (
      <SGForm onSubmit={sPanelFormSubmit(form)}>
        <Column smSpan="6">
          <Field
            disableAutoSelect
            name="domain_id"
            type="text"
            optionValue="id"
            optionLabel="name"
            options={domainSelectOptions}
            label={intl.formatMessage({ id: 'translate.page.ssl.select.domain' })}
            onChange={(ev, value) => onChangeDomain(value)}
            validate={[required]}
            component={FormDropdown}
          />
        </Column>

        {this.renderCreateBoxTabContent()}
      </SGForm>
    );
  }
}

const createFormToExport = (formName: string) => {
  const API = formName === REDUX_FORM.CREATE_INSTALL_SSL ? API_RESOURCE.SSL_LE : API_RESOURCE.SSL;

  return withSpanelForm(
    reduxForm({
      form: formName,
      initialValues: {
        _metaFields: { ...API }
      }
    })(injectIntl(Create))
  );
};

export const CreateFormInstall = createFormToExport(REDUX_FORM.CREATE_INSTALL_SSL);
export const CreateFormImport = createFormToExport(REDUX_FORM.CREATE_SSL_IMPORT);
