import * as React from 'react';
import { injectIntl } from 'react-intl';

import {
  BorderType,
  Button,
  Card,
  Container,
  copyToClipboard,
  Grid,
  List,
  ListItem,
  Section,
  Spacer,
  Switch,
  Tab,
  Tabs,
  Text,
  Tile,
  Toolbar
} from 'sg-styleguide';
import { navigateToUA } from '../../../core/actions/nemo-store';
import { createNotification } from '../../../core/actions/notifications';
import { openNewTabAction, OpenNewTabPayload } from '../../../core/actions/open-new-tab';
import * as sgSiteScanner from '../../../core/actions/pages/sg-sitescanner';
import { requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { EMAIL_NOTIFICATIONS_ROW_ID, sgSiteScannerDataTypes } from '../../../core/constants/sg-sitescanner';
import { MalwareReportEntry, NormalizedReport, NormalizedReports } from '../../../core/definitions/sg-sitescanner';
import { RootState } from '../../../core/reducers';
import { findMainDomain, getCurrentSiteId } from '../../../core/selectors';
import { isLastStatusClean, isReportStatusClean } from '../../../core/selectors/sg-sitescanner';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import { getToolMainEndpoint } from '../../../core/selectors/static-api';
import DateWithTime from '../../components/date-with-time';

import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import FeatureNotAvailable from '../../containers/dialogs/feature-not-available';
import PartialLoader from '../../containers/partial-loader';
import { SecondLevelTitle } from '../../containers/titles';
import CleanUp from './clean-up';
import { default as CreateBox, SITESCANNER_SCAN_FORM } from './create/box';
import MalwareDetailsDialog from './malware-details';
import WarningsList from './warnings-list';

type Props = {
  currentSiteId: string;
  mainDomainName: string;
  isLastStatusClean: boolean;
  emailReportingEnabled: boolean;
  intl: Intl;
  environment: {
    isPhone: boolean;
  };
  isUAEnabled: boolean;
  reports: NormalizedReports;
  navigateToUA: typeof navigateToUA;
  requestNemoData: typeof requestNemoData;
  requestSgSitescannerData: typeof sgSiteScanner.requestSgSitescannerData;
  forceScan: typeof sgSiteScanner.forceScan;
  requestSgSitescannerReports: typeof sgSiteScanner.requestSgSitescannerReports;
  setSgSitescannerEmailReporting: typeof sgSiteScanner.setSgSitescannerEmailReporting;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  createNotification: typeof createNotification;
  openNewTab: (payload: OpenNewTabPayload) => any;
};

type State = {
  isSiteScannerEnabled: boolean;
  upgradeId: string;
  tabActive: 'reports' | 'notifications' | string;
  selectedMalware: MalwareReportEntry[];
};

const SUCURI_URL = 'https://siteground.com/sucuri';

class SGSiteScanner extends React.Component<Props, State> {
  readonly state = {
    isSiteScannerEnabled: true,
    upgradeId: null,
    tabActive: 'reports',
    selectedMalware: null
  };

  toggleEmailReporting = (enable: boolean) => {
    const { requestNemoData, setSgSitescannerEmailReporting, intl, mainDomainName } = this.props;
    const { upgradeId } = this.state;
    const endpointSuffix = enable ? 'enable' : 'disable';
    const notificationProps = { site: mainDomainName };

    const successMessage = enable ?
      'translate.sg-sitescanner.reports.enabled.success' :
      'translate.sg-sitescanner.reports.disabled.success';

    const errorMessage = enable ?
      'translate.sg-sitescanner.reports.enabled.fail' :
      'translate.sg-sitescanner.reports.disabled.fail';

    setSgSitescannerEmailReporting(
      upgradeId,
      enable, {
        type: 'generic',
        success: {
          intlKey: successMessage,
          intlValues: notificationProps
        },
        error: {
          intlKey: errorMessage,
          intlValues: notificationProps
        }
      }
    );
  };

  handleCopyToClipboard = (value) => {
    const { intl, createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  };

  handleNavigateToUA = () => {
    const { navigateToUA, currentSiteId, isUAEnabled, openSGDialog } = this.props;

    if (isUAEnabled) {
      return navigateToUA({
        page: 'hackalert',
        siteId: currentSiteId
      });
    }

    openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
  };

  renderBeforeTableContent = () => (
    <React.Fragment>
      <Tabs key="TABS" size="small" border="light">
        <Tab
          data-e2e="php-settings-tab-reports"
          active={this.state.tabActive === 'reports'}
          onClick={() => this.setState({ tabActive: 'reports' })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.sg-sitescanner.reports.tab' })}
        </Tab>
        <Tab
          data-e2e="php-settings-tab-notifications"
          active={this.state.tabActive === 'notifications'}
          onClick={() => this.setState({ tabActive: 'notifications' })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.sg-sitescanner.notifications.tab' })}
        </Tab>
      </Tabs>
      <Grid padding="medium">
        <Text>{this.props.intl.formatMessage({
          id: this.state.tabActive === 'reports' ?
            'translate.page.sg-sitescanner.reports.desc' :
            'translate.page.sg-sitescanner.notifications.desc'
        })}</Text>
      </Grid>
    </React.Fragment>
  );

  renderPageContent = () => {
    const {
      isLastStatusClean,
      forceScan,
      openSGDialog,
      closeSGDialog,
      reports,
      intl,
      mainDomainName,
      environment
    } = this.props;

    const { upgradeId, tabActive } = this.state;
    const lastReport = Object.values(reports)[0];

    const reportsTableProps = {
      title: intl.formatMessage({ id: 'translate.page.sg-sitescanner.table.title' }),
      data: Object.values(reports),
      mobileLayout: 'card-flat',
      disableTableHead: true,
      columns: [{
        accessor: 'blacklist',
        render: (blacklist, entity: NormalizedReport) => <DateWithTime date={entity.date} />,
        style: !environment.isPhone ? { width: '20%' } : { width: '100%' }
      }, {
        accessor: 'blacklist',
        render: (_, entity: NormalizedReport) => {
          const reportStatusClean = isReportStatusClean(entity);

          if (reportStatusClean) {
            return (
              <List type="success">
                <ListItem icon="success" iconSize="16">
                  {intl.formatMessage({ id: 'translate.page.sg-sitescanner.table.no-threats-found.label' })}
                </ListItem>
              </List>
            );
          }

          return (
            <WarningsList
              icon="warning"
              iconSize="16"
              report={entity}
              onMalwareDetails={(malwareReport: MalwareReportEntry[]) => this.setState({
                selectedMalware: malwareReport
              }, () => openSGDialog(DIALOGS.SG_SITESCANNER_MALWARE_DETAILS))}
            />
          );
        }
      }, {
        accessor: 'blacklist',
        render: () => null,
        style: { width: '0' }
      }],
      resources: [{
        requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS
      }, {
        requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES
      }]
    };

    const notificationsTableProps = {
      title: intl.formatMessage({ id: 'translate.page.sg-sitescanner.table.title' }),
      data: [{ id: EMAIL_NOTIFICATIONS_ROW_ID, notificationsEnabled: this.props.emailReportingEnabled }],
      disableTableHead: true,
      columns: [{
        accessor: 'elnotificationsEnabled',
        render: () => (
          <Text>
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.email-notifications.label' })}
          </Text>
        )
      }, {
        accessor: 'notificationsEnabled',
        render: (value, entity) => (
          <Switch
            checked={value}
            data-e2e="toggle-email-reporting"
            onChange={() => this.toggleEmailReporting(!value)}
          />
        ),
        style: { textAlign: 'right' }
      }, {
        accessor: 'notificationsEnabled',
        render: () => null,
        style: { width: '0' }
      }],
      rowResources: [{ requestTypeName: customRequestTypes.REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS }]
    };

    const tableProps = tabActive === 'reports' ? reportsTableProps : notificationsTableProps;

    return (
      <Grid>
        <CreateBox
          lastReport={lastReport}
          clean={isLastStatusClean}
          onMalwareDetails={(malware: MalwareReportEntry[]) => this.setState({
            selectedMalware: malware
          }, () => openSGDialog(DIALOGS.SG_SITESCANNER_MALWARE_DETAILS))}
          onForceScan={() => forceScan(upgradeId, {
            type: 'form',
            formName: SITESCANNER_SCAN_FORM,
            success: {
              intlKey: 'translate.page.sg-sitescanner.scan-success.text',
              intlValues: {
                domain: mainDomainName
              }
            },
            error: {
              intlKey: 'translate.page.sg-sitescanner.scan-fail.text',
              intlValues: {
                domain: mainDomainName
              }
            }
          })}
          onCleanUp={() => openSGDialog(DIALOGS.SG_SITESCANNER_CLEAN_UP)}
        />
        <SGTable {...tableProps} renderBeforeTableContent={this.renderBeforeTableContent} />
        <CleanUp onProceed={
          () => [this.props.openNewTab({ url: SUCURI_URL }), closeSGDialog(DIALOGS.SG_SITESCANNER_CLEAN_UP)]
        } />
        <MalwareDetailsDialog
          malwareReport={this.state.selectedMalware}
          domainName={mainDomainName}
          handleCopyToClipboard={this.handleCopyToClipboard}
        />
      </Grid>
    );
  };

  renderActivationCart = ({ icon, title, text }) => {
    const { intl } = this.props;

    return (
      <Card
        iconName={icon}
        title={intl.formatMessage({ id: title })}
        text={intl.formatMessage({ id: text })}
        size="medium"
        padding="small"
        layout="vertical"
        outline="none"
        divided
      />
    );
  };

  renderActivation = () => {
    const { intl, environment } = this.props;

    const MOBILE_PADDING = ['x-large', 'medium'];
    const DESKTOP_PADDING = ['x-large', 'large'];

    const DESKTOP_TILE_BORDER: BorderType = ['none', 'small', 'none', 'none'];
    const MOBILE_TILE_BORDER: BorderType = ['none', 'none', 'small', 'none'];

    return (
      <React.Fragment>
        <FeatureNotAvailable />
        <div>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.sg-sitescanner.activate.title' })}
          </SecondLevelTitle>
          <Container padding="none">
            <Grid padding={environment.isPhone ? MOBILE_PADDING : DESKTOP_PADDING}>
              <Text>
                {intl.formatMessage({ id: 'translate.page.sg-sitescanner.protect-your-site.desc' })}
              </Text>

              <Grid gap="none" sm="3">
                <Tile border={environment.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER} padding="none">
                  {this.renderActivationCart({
                    icon: 'e2e',
                    title: 'translate.page.sg-sitescanner.daily-scans.label',
                    text: 'translate.page.sg-sitescanner.daily-scans.desc'
                  })}
                </Tile>
                <Tile border={environment.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER} padding="none">
                  {this.renderActivationCart({
                    icon: 'e2e',
                    title: 'translate.page.sg-sitescanner.scheduled-reports.label',
                    text: 'translate.page.sg-sitescanner.scheduled-reports.desc'
                  })}
                </Tile>
                <Tile border="none" padding="none">
                  {this.renderActivationCart({
                    icon: 'e2e',
                    title: 'translate.page.sg-sitescanner.immediate-alerts.label',
                    text: 'translate.page.sg-sitescanner.immediate-alerts.desc'
                  })}
                </Tile>
              </Grid>
            </Grid>

            <Toolbar>
              <Spacer />
              <Button color="primary" onClick={this.handleNavigateToUA}>
                {intl.formatMessage({ id: 'translate.page.sg-sitescanner.get-sitescanner.button.label' })}
              </Button>
            </Toolbar>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    const { isSiteScannerEnabled } = this.state;

    return (
      <React.Fragment>
        <PartialLoader
          showOnlyLoader
          resources={[
            { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
            { requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES }
          ]}
        >
          <PageHeader
            icon="e2e"
            title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.tool.title' })}
            instructions={intl.formatMessage({ id: 'translate.page.sg-sitescanner.tool.description' })}
          />
          <Section>
            {
              isSiteScannerEnabled ?
                this.renderPageContent() :
                this.renderActivation()
            }
          </Section>
        </PartialLoader>
      </React.Fragment>
    );
  }

  loadData = ({
                siteId,
                mainDomainName
              }) => {
    this.props.requestSgSitescannerData({
      siteId,
      mainDomainName,
      onSiteScannerAvailabilityReady: (upgradeId) => this.setState({
        upgradeId,
        isSiteScannerEnabled: Boolean(upgradeId)
      })
    });
  };

  componentDidUpdate(prevProps: Props) {
    const { currentSiteId, mainDomainName } = this.props;

    const shouldRequestData = Boolean(
      currentSiteId &&
      mainDomainName && (
        currentSiteId !== prevProps.currentSiteId ||
        mainDomainName !== prevProps.mainDomainName
      )
    );

    if (shouldRequestData) {
      this.loadData({ siteId: currentSiteId, mainDomainName });
    }
  }

  componentDidMount() {
    const { mainDomainName, currentSiteId } = this.props;

    if (mainDomainName && currentSiteId) {
      this.loadData({ mainDomainName, siteId: currentSiteId });
    }
  }
}

export default indexWithCRUD((state: RootState) => {
  const mainDomain = findMainDomain(state);

  return {
    currentSiteId: getCurrentSiteId(state),
    mainDomainName: mainDomain && mainDomain.name,
    isLastStatusClean: isLastStatusClean(state),
    reports: state.sgSiteScanner.data[sgSiteScannerDataTypes.reports] || {},
    emailReportingEnabled: Boolean(state.sgSiteScanner.data[sgSiteScannerDataTypes.emailReporting]),
    environment: state.environment,
    isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint('sg-sitescanner'), 'can_order_sitescanner')
  };
}, (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  navigateToUA: (redirectData) => dispatch(navigateToUA(redirectData)),
  requestNemoData: (args, onComplete) => dispatch(requestNemoData(args, onComplete)),
  requestSgSitescannerData: (siteId) => dispatch(
    sgSiteScanner.requestSgSitescannerData(siteId)
  ),
  forceScan: (upgradeId, notification) => dispatch(sgSiteScanner.forceScan(upgradeId, notification)),
  requestSgSitescannerReports: (upgradeId) => dispatch(
    sgSiteScanner.requestSgSitescannerReports(upgradeId)
  ),
  setSgSitescannerEmailReporting: (upgradeId, enable, notification) => dispatch(
    sgSiteScanner.setSgSitescannerEmailReporting(upgradeId, enable, notification)
  ),
  createNotification: (payload) => dispatch(createNotification(payload)),
  openNewTab: (payload) => dispatch(openNewTabAction(payload))
}))(
  injectIntl(SGSiteScanner),
  API_RESOURCE.DOMAIN
);
