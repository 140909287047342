const moveEntity = (arr, currentIndex, nextIndex) => {
  let oldIndex = currentIndex;
  let newIndex = nextIndex;
  const newArray = [].concat(arr);

  while (oldIndex < 0) {
    oldIndex += newArray.length;
  }
  while (newIndex < 0) {
    newIndex += newArray.length;
  }
  if (newIndex >= newArray.length) {
    let k = newIndex - newArray.length;

    // tslint:disable-next-line
    while ((k--) + 1) {
      newArray.push(undefined);
    }
  }
  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

  return newArray;
};

export {
  moveEntity
};
