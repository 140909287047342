import { sPanelFormSubmit } from '../../../core/actions/form-actions';

export const dispatchRemoteFormSubmit =
  (dispatch) =>
  (formName) =>
  (event) => {
    if (event && event.preventDefault) {
      // prevent default form submit behavior
      event.preventDefault();
    }

    // applying sPanel form submit behavior
    dispatch(sPanelFormSubmit(formName));
  };
