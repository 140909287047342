import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Box, Flex, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormInput from '../../../../components/form-input';
import FormRadioButton from '../../../../components/form-radio-button';
import { withSpanelForm } from '../../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: {
    _metaFields: {
      domainName: string;
    };
  };
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG;

const FieldsForm = ({ initialValues, intl, validationUtils }: Props) => (
  <React.Fragment>
    <Field
      name="value"
      label={intl.formatMessage({ id: 'translate.page.cloudflare.ip.range' })}
      validate={[validationUtils.required]}
      component={FormInput}
    />

    <Flex gutter="none" style={{ marginBottom: '0' }}>
      <Box sm="12" flex justify="flex-start" align="center">
        <Text color="light" size="small">
          {intl.formatMessage({ id: 'translate.page.cloudflare.select.status' })}
        </Text>
      </Box>

      <Box sm="12" flex justify="flex-start" align="center">
        <Field
          name="fw_mode"
          type="radio"
          value="whitelist"
          component={FormRadioButton}
        >
          {intl.formatMessage({ id: 'translate.page.cloudflare.status.whitelist' })}
        </Field>

        &nbsp;

        <Field
          name="fw_mode"
          type="radio"
          value="block"
          component={FormRadioButton}
        >
          {intl.formatMessage({ id: 'translate.page.cloudflare.status.block' })}
        </Field>
      </Box>
    </Flex>
  </React.Fragment>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(FieldsForm)
  )
);
