import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../core/actions/crud';
import { RootState } from '../../core/reducers';
import { scrollPageToTop } from '../../core/common/dom-utils';
import { IndexWithCrudPageContext } from '../contexts';

type MappedStateToProps = {
  items?: object;
  [otherProps: string]: any;
};

type MappedDsipatchToProps = {
  actions?: object;
  [otherProps: string]: any;
};

type ResourceItem = {
  resourceName: string;
  endpoint: string;
  itemName?: string;
  resourceNameMetaApi?: string;
};

type IndexHOCProps = {
  isIndexPage: boolean;
  lastManualFetchTimestamp: string;
  [other: string]: any;
};

const defaultMapStateToProps = (state: RootState, ...other): MappedStateToProps => ({
  items: state.pageItems
});

const defaultMapDispatchToProps = (dispatch): MappedDsipatchToProps => ({
  actions: bindActionCreators(actions as any, dispatch)
});

function indexWithCRUD(
  mapStateToProps = defaultMapStateToProps,
  mapDispatchToProps = defaultMapDispatchToProps,
  settings = { triggerReloadOnTaskCompleted: true }
) {
  return function indexHOC(WrappedComponent, ...fetchItemsParams: ResourceItem[]) {
    class IndexHOC extends React.Component<IndexHOCProps, any> {
      lastLocationSearch: string;

      componentWillUpdate() {
        // some components might not be routed
        if (this.props.location) {
          this.lastLocationSearch = this.props.location.search;
        }
      }

      componentDidUpdate(prevProps: IndexHOCProps) {
        if (this.props.location) {
          const locationChanged = Boolean(
            this.props.location.search !==
            this.lastLocationSearch
          );

          const refreshAfterTaskNotify = Boolean(
            settings.triggerReloadOnTaskCompleted &&
            prevProps.lastManualFetchTimestamp !==
            this.props.lastManualFetchTimestamp
          );

          if (locationChanged || refreshAfterTaskNotify) {
            this.fetchAll();
          }
        }
      }

      componentDidMount(): void {
        this.fetchAll();

        if (this.props.isIndexPage) {
          scrollPageToTop();
        }
      }

      render() {
        const { lastManualFetchTimestamp, ...props } = this.props;

        return (
          <IndexWithCrudPageContext.Provider value={{ isIndexPage: false }}>
            <WrappedComponent {...props} updatePageData={this.fetchAll} />
          </IndexWithCrudPageContext.Provider>
        );
      }

      fetchAll = () => fetchItemsParams.forEach(this.props.actions.fetchItems);
    }

    const IndexPage = ({ lastManualFetchTimestamp, ...props }) => (
      <IndexWithCrudPageContext.Consumer>
        {({ isIndexPage }) => (
          <IndexHOC
            {...props}
            lastManualFetchTimestamp={lastManualFetchTimestamp}
            isIndexPage={isIndexPage}
          />
        )}
      </IndexWithCrudPageContext.Consumer>
    );

    return connect(
      (state: RootState, ...other) => {
        return {
          ...defaultMapStateToProps(state),
          ...mapStateToProps(state, ...other),
          lastManualFetchTimestamp: state.indexWithCrud.lastManualFetchTimestamp
        };
      },
      (dispatch) => {
        return {
          ...defaultMapDispatchToProps(dispatch),
          ...mapDispatchToProps(dispatch)
        };
      }
    )(injectIntl(IndexPage)) as any;
  };
};

export default indexWithCRUD;
