import { handleActions } from 'redux-actions';
import {
  REQUEST_SUPPORT_SEARCH_SUCCESS,
  REQUEST_SUPPORT_SUGGESTIONS_SUCCESS,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLES_SUCCESS,
  SAVE_SUPPORT_STATE,
  CLEAR_SUPPORT_STATE
} from '../constants/actions';
import { RETRIEVE_DATA_FROM_NEMO_SUCCEEDED } from '../constants/nemo-store';

const initialState: any = {
  article: null,
  pinned: null,
  popular: [],
  suggestions: [],
  searchResults: [],
  popup: null
};

export default handleActions<any, any>({
  [REQUEST_ARTICLES_SUCCESS]: (state, action: any) => {
    return {
      ...state,
      pinned: (action.articles.top && action.articles.top.title) ? action.articles.top : state.pinned,
      popular: action.articles.list
    };
  },
  [REQUEST_ARTICLE_SUCCESS]: (state, action: any) => {
    return {
      ...state,
      article: {
        id: action.data.id,
        link: action.data.link,
        title: action.data.title,
        content: action.data.content
      }
    };
  },
  [REQUEST_SUPPORT_SEARCH_SUCCESS]: (state, action: any) => {
    return {
      ...state,
      searchResults: action.results
    };
  },
  [REQUEST_SUPPORT_SUGGESTIONS_SUCCESS]: (state, action: any) => {
    return {
      ...state,
      suggestions: action.suggestions
    };
  },
  [RETRIEVE_DATA_FROM_NEMO_SUCCEEDED]: (state, action: any) => {
    if (!action.data || !action.data.popup) {
      return state;
    }

    const popup = action.data.popup;
    const last = popup.state.history[popup.state.history.length - 1];

    return {
      ...state,
      article: last.article,
      pinned: last.pinned,
      popular: last.popular,
      suggestions: last.suggestions,
      searchResults: last.searchResults,
      popup
    };
  },
  [SAVE_SUPPORT_STATE]: (state, action: any) => {
    return {
      ...state,
      popup: action.payload.popup
    };
  },
  [CLEAR_SUPPORT_STATE]: () => initialState
}, initialState);
