import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Link, Section } from 'sg-styleguide';
import { openNewTab } from 'sg-styleguide/lib/utils';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { APP_TYPES } from '../../../core/constants/application';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import { getAutoinstalledApps, getCurrentSiteId, filterStagingDomains, findMainDomain } from '../../../core/selectors';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import { getToolMainEndpoint } from '../../../core/selectors/static-api';
import { findAppNameByType } from '../../../core/utils/app-names';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialog, SGDialogForm, SGDialogCancel } from '../../containers/sg-dialog';
import AddExistingApp from '../../containers/dialogs/add-existing-app';
import DeleteInstalledApp from '../../components/common-forms/delete-installed-wp-app';
import VCS from '../../containers/visibility-control-service';
import { PLUGINS, WEEBLY_CLASS_NUMBER_TO_PLAN } from './constants';
import { CreateBox, CreateForm } from './create';
import { generateNotification, mapToWPFormData, formatWeeblyAppData, getWeeblyAdminUrl } from './utils';
import { WeeblyExtra, TableListedApp } from './types';
import { requestNemoData } from '../../../core/actions/request-data';
import customRequestTypes from '../../../core/constants/custom-request-types';
import FeatureNotAvailable from '../../containers/dialogs/feature-not-available';

type Props = {
  actions: CrudActions;
  isPhone: boolean;
  intl: Intl;
  session: {
    user: { language: string }
  };
  isWeeblyUAEnabled: boolean;
  mainDomain: any;
  appInstallDomains: any[];
  autoinstalledApps: any[];
  currentSiteId: string;
  updatePageData: Function;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  requestNemoData: typeof requestNemoData;
};

type State = {
  selectedDomain: any;
  selectedAppType: string;
  selectedDeleteApp: {
    app: string;
    app_url: string;
    id: string;
  };
  weeblyExtra: WeeblyExtra;
};

const WORDPRESS_APP_TYPES = [
  APP_TYPES.WORDPRESS,
  APP_TYPES.WOO_COMMERCE
];

class AppManager extends React.Component<Props, State> {
  readonly state = {
    selectedAppType: null,
    selectedDomain: null,
    selectedDeleteApp: null,
    weeblyExtra: null
  };

  onChangeSelectedAppType = (selectedAppType: string) => this.setState({ selectedAppType });

  onCreateFormSubmitted = ({ app, wp_starter, ...formData }) => {
    const { updatePageData, actions } = this.props;
    const { selectedDomain } = this.state;

    if (formData.extra_content) {
      formData.extra_content = formData.extra_content ? 1 : 0;
    }

    const plugins = wp_starter ? [PLUGINS.WP_STARTER] : [];

    const meta = {
      ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
      notification: generateNotification({
        formName: REDUX_FORM.CREATE_APP_INSTALL,
        appType: app,
        domainName: selectedDomain,
        path: formData.path
      })
    };

    switch (app) {
      case APP_TYPES.WORDPRESS:
        return actions.createItem({
          ...mapToWPFormData(formData),
          plugins,
          _meta: meta,
          _metaFields: {
            ...API_RESOURCE.WORDPRESS,
            formName: REDUX_FORM.CREATE_APP_INSTALL
          }
        }, updatePageData as any);
      case APP_TYPES.WOO_COMMERCE:
        return actions.createItem({
          ...mapToWPFormData(formData),
          plugins: [APP_TYPES.WOO_COMMERCE, ...plugins],
          _meta: meta,
          _metaFields: {
            ...API_RESOURCE.WORDPRESS,
            formName: REDUX_FORM.CREATE_APP_INSTALL
          }
        }, updatePageData as any);
      default:
        return actions.createItem({
          ...formData,
          app,
          _meta: meta,
          _metaFields: {
            ...API_RESOURCE.AUTOINSTALL,
            formName: REDUX_FORM.CREATE_APP_INSTALL
          }
        }, updatePageData as any);
    }
  };

  storeAddedWeebly = ({ added_extras = [] }) => {
    const { currentSiteId } = this.props;
    const weeblyExtra = added_extras.find(({ name }) => name === APP_TYPES.WEEBLY);

    const weeblyClassNumber = (
      weeblyExtra &&
      weeblyExtra.additional_data &&
      weeblyExtra.additional_data.class_number
    );

    if (weeblyClassNumber) {
      this.setState({
        weeblyExtra: {
          currentSiteId,
          plan: weeblyClassNumber,
          admin_url: getWeeblyAdminUrl(weeblyExtra)
        }
      });
    }
  }

  activateFreeWeebly = () => {
    const { currentSiteId, requestNemoData, mainDomain } = this.props;

    requestNemoData({
      endpoint: '/order_extra',
      method: 'POST',
      body: {
        order: {
          association: currentSiteId,
          class_number: 1,
          cycle: 12,
          product: APP_TYPES.WEEBLY
        }
      },
      requestTypeName: customRequestTypes.REQUEST_INSTALL_WEEBLY,
      notification: {
        type: 'form',
        formName: REDUX_FORM.CREATE_APP_INSTALL,
        success: {
          intlKey: 'translate.page.app-installer.weebly-ordered.message'
        },
        error: {
          intlKey: 'translate.page.app-installer.weebly-order.failed',
          intlValues: {
            domain: mainDomain && mainDomain.name
          }
        }
      }
    });
  };

  loadWeeblyData = () => {
    const { currentSiteId, requestNemoData } = this.props;

    requestNemoData({
      endpoint: `/sites/${currentSiteId}/extras/weebly_sitebuilder`,
      method: 'GET',
      requestTypeName: customRequestTypes.REQUEST_LOAD_WEEBLY_EXTRAS
    }, this.storeAddedWeebly);
  };

  render() {
    const {
      intl,
      autoinstalledApps,
      appInstallDomains,
      openSGDialog,
      isPhone,
      session,
      updatePageData,
      mainDomain,
      isWeeblyUAEnabled
    } = this.props;

    const { selectedAppType, selectedDomain, weeblyExtra } = this.state;
    const userLang = session.user && session.user.language;

    const createApiResource = WORDPRESS_APP_TYPES.includes(selectedAppType) ?
      API_RESOURCE.WORDPRESS :
      API_RESOURCE.AUTOINSTALL;

    const tableListedApps: TableListedApp[] = weeblyExtra ?
      [formatWeeblyAppData(weeblyExtra, mainDomain && mainDomain.name), ...autoinstalledApps] :
      autoinstalledApps;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-autoinstaller"
          title={intl.formatMessage({ id: 'translate.page.app-manager.title' })}
          instructions={
            <FormattedMessage
              id="translate.page.app-manager.info-text"
              values={{
                addExistingAppLink: (
                  <Link onClick={() => openSGDialog(REDUX_FORM.ADD_EXISTING_APP)}>
                    <FormattedMessage id="translate.generic.clicking.here" />
                  </Link>
                )
              }}
            />
          }
        />
        <Section>
          <Grid>
            <VCS resourceName={createApiResource.resourceNameMetaApi} hasMethods={['POST']}>
              <CreateBox apiResource={createApiResource}>
                <CreateForm
                  domains={appInstallDomains}
                  userLang={userLang}
                  selectedAppType={selectedAppType}
                  onSelectAppType={this.onChangeSelectedAppType}
                  onSelectDomain={(d) => this.setState({ selectedDomain: d })}
                  activeWeebly={weeblyExtra}
                  activateFreeWeebly={this.activateFreeWeebly}
                  onSubmit={this.onCreateFormSubmitted}
                />
              </CreateBox>
            </VCS>

            <SGTable
              title={intl.formatMessage({ id: 'translate.page.app-manager.table.title' })}
              resources={[
                { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
                { requestTypeName: customRequestTypes.REQUEST_LOAD_WEEBLY_EXTRAS }
              ]}
              rowResources={[{
                resourceName: API_RESOURCE.APP.resourceName,
                methods: ['DELETE']
              }, {
                resourceName: API_RESOURCE.WORDPRESS.resourceName,
                methods: ['DELETE']
              }]}
              data={tableListedApps}
              columns={[{
                header: intl.formatMessage(
                  { id: 'translate.page.app-manager.manage-apps.table-head-website.title' }
                ),
                accessor: 'app_url',
                style: !isPhone ? {
                  width: '65%'
                } : null
              }, {
                header: intl.formatMessage(
                  { id: 'translate.page.app-manager.manage-apps.table-head-application.title' }
                ),
                accessor: 'app',
                render: (appType: string, { plugins }: Partial<TableListedApp>) => {
                  return plugins && plugins.includes(APP_TYPES.WOO_COMMERCE)
                    ? intl.formatMessage({ id: 'translate.page.app-manager-app.wordpress-and-woo' })
                    : findAppNameByType(appType);
                }
              }, {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'app',
                render: (app, entity: Partial<TableListedApp>) => {
                  return (
                    <TableContextMenu
                      entity={entity}
                      resourceName={API_RESOURCE.APP.resourceName}
                      items={[{
                        visibleOnDesktop: true,
                        label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.login.admin.panel' }),
                        e2eAttr: 'wp-manage-context-menu-item-log-in-admin',
                        icon: 'login',
                        shortcut: <Icon name="external-link" size="14" />,
                        onClick: () => openNewTab({ url: entity.admin_url })
                      }, {
                        vcsMethod: 'DELETE',
                        visibleOnDesktop: true,
                        label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.delete' }),
                        e2eAttr: 'wp-manage-context-menu-item-delete',
                        icon: 'trash',
                        onClick: () => {
                          if (entity.isWeebly) {
                            if (isWeeblyUAEnabled) {
                              return openSGDialog(DIALOGS.MESSAGE_DIALOG);
                            }

                            return openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
                          }

                          this.setState({
                            selectedDeleteApp: { app, id: entity.id, app_url: entity.app_url }
                          }, () => openSGDialog(DIALOGS.GENERIC_DELETE));
                        }
                      }]}
                    />
                  );
                }
              }]}
              noDataMessage="translate.page.app-manager.no-table-data.text"
            />
          </Grid>
        </Section>

        <AddExistingApp
          domain={appInstallDomains}
          title={intl.formatMessage({ id: 'translate.page.app-manager.add-existing-app.title' })}
          onApplicationAdded={updatePageData}
        />
        <FeatureNotAvailable />
        <SGDialog
          id={DIALOGS.MESSAGE_DIALOG}
          icon="warning"
          state="warning"
          title={intl.formatMessage({ id: 'translate.page.app-installer.delete-weebly.message' })}
          footer={
            <SGDialogCancel
              id={DIALOGS.MESSAGE_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.ok' })}
            />
          }
        />
        {this.renderAppDeleteConfirmation()}
      </React.Fragment>
    );
  }

  renderDeleteWpAppConfirmation = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { selectedDeleteApp } = this.state;

    const appType = selectedDeleteApp && selectedDeleteApp.app;
    const itemId = selectedDeleteApp && selectedDeleteApp.id;
    const appUrl = selectedDeleteApp && selectedDeleteApp.app_url;

    return (
      <SGDialogForm
        name={REDUX_FORM.DELETE_INSTALLED_WP_APP}
        icon="trash"
        state="inactive"
        title={intl.formatMessage(
          { id: 'translate.page.wp.manage.delete.title' },
          { url: selectedDeleteApp.app_url }
        )}
        resources={[{ resourceName: API_RESOURCE.WORDPRESS.resourceName, methods: ['DELETE'] }]}
      >
        <DeleteInstalledApp
          selectedApplication={selectedDeleteApp}
          onSubmit={({ keep_db, full_delete, ...formData }: DeleteItemPayload) => actions.deleteItem({
            ...formData,
            itemId,
            bodyParams: {
              delete_db: Number(!Boolean(keep_db)),
              full_delete: Number(Boolean(full_delete))
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.wp.manage.delete.success.message'
                },
                error: {
                  intlKey: 'translate.page.wp.manage.delete.error.message'
                }
              }
            }
          }, () => closeSGDialog(REDUX_FORM.DELETE_INSTALLED_WP_APP))}
        />
      </SGDialogForm>
    );
  }

  renderAppDeleteConfirmation = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { selectedDeleteApp } = this.state;

    const itemId = selectedDeleteApp && selectedDeleteApp.id;
    const appUrl = selectedDeleteApp && selectedDeleteApp.app_url;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.app-manager-app-delete-dialog.title' }, { site: appUrl })}
        onSubmit={() => actions.deleteItem({
          itemId,
          bodyParams: {
            full_delete: 1
          },
          _metaFields: { ...API_RESOURCE.APP },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.app-manager.delete-success.message'
              },
              error: {
                intlKey: 'translate.page.app-manager.delete-fail.message',
                intlValues: {
                  website: appUrl
                }
              }
            }
          }
        } as DeleteItemPayload, () => {
          actions.fetchItems(API_RESOURCE.APP);
          closeSGDialog(DIALOGS.GENERIC_DELETE);
        })}
      >
        {intl.formatMessage({ id: 'translate.page.app-manager-app-delete-dialog.message' })}
      </DeleteDialog>
    );
  };

  componentDidMount() {
    this.loadWeeblyData();
  }
}

export default indexWithCRUD((store: RootState) => ({
  isWeeblyUAEnabled: isACLActionEnabled(store, getToolMainEndpoint('app-installer'), 'can_order'),
  currentSiteId: getCurrentSiteId(store),
  mainDomain: findMainDomain(store),
  session: store.session || {},
  isPhone: store.environment.isPhone,
  appInstallDomains: filterStagingDomains(store),
  autoinstalledApps: getAutoinstalledApps(store)
}), (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  requestNemoData: (request, onComplete) => dispatch(requestNemoData(request, onComplete))
}))(
  AppManager,
  API_RESOURCE.DOMAIN,
  API_RESOURCE.APP
);
