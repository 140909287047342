import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
};

const formName = REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG;

const DiscardForm = ({ intl }: Props) => (
  <Field
    data-e2e="forward-update-discard_msg"
    name="discard_msg"
    type="text"
    label={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg' })}
    placeholder={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg' })}
    component={FormInput}
  />
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(DiscardForm)
  )
);
