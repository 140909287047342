import * as React from 'react';
import { injectIntl } from 'react-intl';

import { Icon, Text } from 'sg-styleguide/';

import './default-view.scss';
import { KEY } from '../../../../../core/constants/key-codes';
import { isCmdCtrlKey } from '../../../../../core/common/key-code-combinations';

class EntityView extends React.Component<any, any> {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDownHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler);
  }

  onKeyDownHandler = (event) => {
    const { keyCode } = event;
    const isCmdCtrlKeyPressed = isCmdCtrlKey(event);

    if (isCmdCtrlKeyPressed && keyCode === KEY.b) {
      event.preventDefault();
      this.props.toogleCodeEditor();
    }

    // TODO other command ...
    if (isCmdCtrlKeyPressed && keyCode === KEY.n) {
      event.stopPropagation();
      event.preventDefault();
      this.props.createFile();
    }
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="code-editor-default-view">
        <div className="code-editor-default-view__icon">
          <Icon name="file-outlined" size="60" />
        </div>
        <Text weight="bold" color="light" className="code-editor-default-view__text">
          Create a new file
          cmd + n
        </Text>
        <Text weight="bold" color="light" className="code-editor-default-view__text">
          Back to file explorer
          cmd + b
        </Text>
      </div>
    );
  }
};

export default injectIntl(EntityView);
