import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Button, Dialog, Icon, Spacer, Tab, Tabs } from 'sg-styleguide/';
import * as fileManagerActions from '../core/actions/file-manager';
import { RootState } from '../../../../core/reducers';
import { FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS } from '../core/constants/common';
import {
  getCodeEditorEntityContent,
  getCodeEditorEntityUpdatedContent,
  getEntityNameExtension,
  getEntityReadableName,
  isEntityActiveInCodeEditor
} from '../core/utils';

import DefaultView from './default-view';
import MonacoEditor from 'react-monaco-editor';

import './monaco-editor.scss';

const MONACO_EDITOR_ACTION = {
  FIND: 'actions.find',
  FIND_REPLACE: 'editor.action.startFindReplaceAction'
};

class Editor extends React.Component<any, any> {
  editor;
  timeout;

  constructor(props) {
    super(props);
    this.state = {
      tabIndexToBeDeleted: null
    };
  }

  editorDidMount = (editor, monaco) => {
    this.editor = editor;

    this.editor.addCommand(monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_S, this.props.saveFile);

    if (this.props.onRefsReady) {
      this.props.onRefsReady({
        openFindPanel: this.openFindPanel,
        openFindReplacePanel: this.openFindReplacePanel,
        downloadActiveTabContent: this.downloadActiveTabContent
      });
    }

    this.editor.focus();
  };

  downloadActiveTabContent = () => {
    const { files } = this.props;
    const activeEntity = files.find((entity) => isEntityActiveInCodeEditor(entity));
    const activeEntityUpdatedContent = getCodeEditorEntityUpdatedContent(activeEntity);
    const link = document.createElement('a');

    link.href = URL.createObjectURL(new Blob([activeEntityUpdatedContent], { type: 'text/plain' }));
    link.download = getEntityReadableName(activeEntity);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  openFindPanel = () => {
    this.editor.getAction(MONACO_EDITOR_ACTION.FIND).run();
  };

  openFindReplacePanel = () => {
    this.editor.getAction(MONACO_EDITOR_ACTION.FIND_REPLACE).run();
  };

  updateDimensions = () => {
    this.editor.layout();
  };

  onChange = (newValue, event) => {
    const modifiedFiles = [...this.props.files];

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const activeFileIndex = this.props.files.findIndex((file) => file._meta.isActiveInCodeEditor);

    modifiedFiles[activeFileIndex]._meta.updatedContent = newValue;

    this.timeout = setTimeout(() => this.props.actions.codeEditorOnFileChange(modifiedFiles), 300);
  };

  onTabClick = (tabIndex) => {
    this.props.actions.codeEditorChangeActiveTab(tabIndex);
  };

  componentDidMount() {
    window.addEventListener('monaco-editor-resize-handler', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('monaco-editor-resize-handler', this.updateDimensions);
  }

  render() {
    const { actions, files, createFile } = this.props;

    if (files.length) {
      return this.renderCodeEditor();
    }

    return (
      <DefaultView
        createFile={createFile}
        toogleCodeEditor={actions.toggleCodeEditor}
      />);
  }

  renderCodeEditor() {
    const { files, extension } = this.props;
    const activeTab = files.find((file) => file._meta.isActiveInCodeEditor);
    const activeTabContent = activeTab._meta.updatedContent;
    const activeTabExtension = getEntityNameExtension(activeTab);
    const editorLanguage = FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS[activeTabExtension] || 'plaintext';

    const options = {
      selectOnLineNumbers: true
    };

    return (
      <div className="code-editor">
        <Tabs className="code-editor-tabs" size="small">
          {this.renderCodeEditorTab()}
        </Tabs>

        <div className="code-editor-container">
          <MonacoEditor
            language={editorLanguage}
            value={activeTabContent}
            options={options}
            onChange={this.onChange}
            editorDidMount={this.editorDidMount}
          />
        </div>

      </div>
    );
  }

  renderCodeEditorTab = () => {
    const { files, onTabClose } = this.props;

    return files.map((file, index) => {
      const isTabContentModified = getCodeEditorEntityContent(file) !== getCodeEditorEntityUpdatedContent(file);
      const tabClasses = [
        'code-editor-tab',
        isTabContentModified && 'code-editor-tab--modified'
      ].filter(Boolean).join(' ');

      return (
        <Tab
          key={index}
          className={tabClasses}
          data-e2e={`code-editor-tab-${index}`}
          active={file._meta.isActiveInCodeEditor}
          onClick={this.onTabClick.bind(this, index)}
        >
          <div>
            {getEntityReadableName(file)}

            {isTabContentModified && (<strong>&nbsp;*</strong>)}

            <Icon
              name="cross"
              size="8"
              className="code-editor-tab-icon"
              onClick={(event) => {
                event.stopPropagation();
                onTabClose(file);
              }}
            />
          </div>
        </Tab>
      );
    });
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  files: state.fileManager.codeEditor.files
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)((injectIntl(Editor)));
