import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';

import CreateBox from '../../../../components/create-box';
import VCS from '../../../../containers/visibility-control-service';

type Props = {
  children: any;
  domainName: string;
  intl: any;
  renderBeforeBoxChildren: Function
};

const { resourceName } = API_RESOURCE.EMAIL_FORWARD;
const formName = REDUX_FORM.CREATE_EMAIL_DISCARD;

const CreateContainer = ({ children, domainName, intl, renderBeforeBoxChildren }: Props) => (
  <VCS resourceName={resourceName} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.emailForward.create.title' })}
      renderBeforeBoxChildren={renderBeforeBoxChildren}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName,
        methods: ['POST']
      }, {
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['GET']
      }, {
        resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
        methods: ['GET']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
