import * as React from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import { sPanelFocusField } from '../../../core/actions/form-actions';

import {
  isNumber,
  isRequired,
  isValidDomain,
  isValidPassword,
  isValidEmail,
  isValidMultipleEmails,
  validationWithMetaApi,
  validBase64Encode,
  validIp,
  validIpWithExtras,
  validPhpSettingValue
} from '../../../core/common/form-validations';
import { dispatchRemoteFormSubmit } from './remote-submit-util';

type Props = {
  siteMetaApi: SiteMetaApi,
  validationUtils: ValidationUtils;
  formValues: any;
  getFormValues: (forName) => any;
  sPanelFormSubmit: () => any;
  [otherProps: string]: any;
};

export const withSpanelForm = (FormComponent) => {
  return connect(
    ({ siteMetaApi, ...store }): Partial<Props> => ({
      siteMetaApi,
      formValues: (formName) => getFormValues(formName)(store) || {}, // TODO remove in favor of getFormValues
      formErrors: (formName) => getFormSyncErrors(formName)(store) || {}, // TODO remove in favor of getFormErrors
      getFormErrors: (formName) => getFormSyncErrors(formName)(store) || {},
      getFormValues: (formName) => getFormValues(formName)(store) || {},
      validationUtils: {
        /*
          TODO:
          ____________________________________

          Add new validation pure functions here,
          all MUST be of type `ValidationUtil`
          and don't forget to write unit tests for them ;)
        */
        required: isRequired,
        email: isValidEmail,
        emails: isValidMultipleEmails,
        password: isValidPassword,
        validationWithMetaApi,
        ip: validIp,
        ipWithExtras: validIpWithExtras,
        domain: isValidDomain,
        number: isNumber,
        validBase64Encode,
        validPhpSettingValue
      }
    }),
    (dispatch) => ({
      sPanelFormSubmit: dispatchRemoteFormSubmit(dispatch),
      sPanelFocusField: (fieldName, formName) => dispatch(
        sPanelFocusField(fieldName, formName)
      )
    })
  )(FormComponent) as any;
};

export default withSpanelForm;
