import * as React from 'react';
import { Button, Grid } from 'sg-styleguide';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { REDUX_FORM, DIALOGS } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import SelectDirectory from '../../../containers/dialogs/select-directory';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';
import { CREATE_BOX_DROPDOWN_VALUE_KEY } from '../constants';

type Props = {
  intl: Intl;
  domain: any[];
  validationUtils: ValidationUtils;
  change: Function;
  openSGDialog: Function;
};

const formName = REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG;

const AddAppForm = ({ intl, domain, validationUtils, openSGDialog, change }: Props) => (
  <Grid>
    <Field
      name="domain_id"
      label={intl.formatMessage({ id: 'translate.page.wp.autoupdate.add.manually.select.domain' })}
      options={domain}
      optionValue={CREATE_BOX_DROPDOWN_VALUE_KEY}
      optionLabel="name"
      component={FormDropdown}
    />

    <Field
      name="path"
      type="text"
      label={intl.formatMessage({ id: 'translate.page.wp.autoupdate.add.manually.add.path' })}
      validate={[validationUtils.required]}
      suffix={
        <Button
          action="button"
          color="dark"
          size="small"
          onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
        >
          {intl.formatMessage({ id: 'translate.generic.browse' })}
        </Button>
      }
      component={FormInput}
    />
    <SelectDirectory initialPath={'/'} onChange={(path) => change('path', path)} />
  </Grid>
);

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect(
  undefined,
  mapDispatchToProps
)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: formName })(AddAppForm)
    )
  )
) as any;
