import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Column, Text, Label } from 'sg-styleguide';
import { injectIntl } from 'react-intl';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import FormDropdown from '../../../components/form-dropdown';
import { PhpVersion } from '../types';

type Props = {
  phpVersions: PhpVersion[];
  getFormValues: Function;
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_PHP_VERSION;

export const Form = ({
  phpVersions = [],
  getFormValues,
  intl
}: Props) => {
  const formValues = getFormValues(formName);
  const versionValue = formValues && formValues.version_id;

  const visiblePhpVersions = phpVersions.filter(({ disabled, id }) => Boolean(
    !Boolean(disabled) ||
    id === versionValue
  ));

  return (
    <Grid>
      <Column smSpan="12">
        <Field
          name="version_id"
          label={intl.formatMessage(
            { id: 'translate.page.phpSettings.php_version.label' }
          )}
          options={visiblePhpVersions}
          optionValue="id"
          optionLabel="version"
          optionDisabled="disabled"
          template={(option: PhpVersion) => (
            <Text size="medium" style={{ lineHeight: '40px' }}>
              {option.version}
              {
                Boolean(option.default) && (
                  <Label type="instruction-link" size="medium" style={{ marginLeft: '5px' }}>
                    {intl.formatMessage(
                      { id: 'translate.page.phpSettings.recommended.label' }
                    )}
                  </Label>
                )
              }
            </Text>
          )}
          component={FormDropdown}
        />
      </Column>
    </Grid>
  );
};

const UpdatePhpVersion = reduxForm({ form: formName })(Form);

export default withSpanelForm(
  injectIntl(UpdatePhpVersion)
);
