import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from 'sg-styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import FormInput from '../../../components/form-input';
import FormPassword from '../../../components/form-password';

type Props = {
  domainName: string;
  intl: Intl;
  sPanelFormSubmit: (formName) => null;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CREATE_EMAIL;

const Form = ({ domainName, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, password, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="name"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.account.name' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.account.name.placeholder' })}
          suffix={`@${domainName}`}
          normalize={(value) => value.toLowerCase()}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          style={{ display: 'none' }}
          name="password"
          label="fake_password_to_prevent_autofill"
          data-e2e="fake-password"
          validate={[required, password]}
          component={FormPassword}
        />

        <Field
          name="password"
          label={intl.formatMessage({ id: 'translate.generic.password' })}
          validate={[required, password]}
          component={FormPassword}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const EmailForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.EMAIL,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(EmailForm));
