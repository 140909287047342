// TODO use constants for all routes
// import ROUTES from '../constants/routes';

export const groups = [
  {
    groupId: 'dashboard',
    title: 'translate.navigation.group.dashboard.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [],
    stateId: 'dashboard'
  },
  {
    groupId: 'sites',
    title: 'translate.navigation.group.site.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'filemanager',
        title: 'translate.navigation.page.filemanager.label',
        pageEndpoint: 'file',
        pageIcon: 'presentational-file-manager',
        pageTitle: 'translate.page.translate.file.manager.title',
        apiEndpoints: [
          'dir'
        ],
        keywords: [
          'file',
          'upload',
          'editor',
          'copy',
          'move',
          'folder',
          'permission',
          'archive',
          'code'
        ],
        parent: 'Site'
      },
      {
        stateId: 'ftp',
        title: 'translate.navigation.page.ftp.label',
        pageEndpoint: 'ftp',
        pageIcon: 'presentational-ftp',
        pageTitle: 'translate.page.ftp.title',
        apiEndpoints: [],
        keywords: [
          'ftp',
          'file',
          'upload',
          'download',
          'backup',
          'quota'
        ],
        parent: 'Site'
      },
      {
        stateId: 'app-installer',
        title: 'translate.navigation.page.installer.label',
        pageEndpoint: 'autoinstall',
        pageIcon: 'presentational-autoinstaller',
        pageTitle: 'translate.page.app-manager.title',
        apiEndpoints: [
          'wordpress'
        ],
        localPageTaskLoaders: {
          createBox: ['wordpress', 'autoinstall']
        },
        keywords: [
          'application',
          'app',
          'install',
          'site install',
          'site',
          'platform',
          'wordpress',
          'drupal',
          'magento',
          'wiki',
          'prestashop',
          'zencart',
          'opencart',
          'phpbb',
          'woocommerce',
          'smf',
          'moodle',
          'mediawiki',
          'vtiger',
          'osticket',
          'crm',
          'limesurvey',
          'survey',
          'phplist'
        ],
        parent: 'Site'
      },
      {
        stateId: 'mysql',
        title: 'translate.navigation.page.mysql.label',
        pageEndpoint: 'db-mysql_db',
        pageIcon: 'presentational-mysql-database',
        pageTitle: 'translate.page.mysql.title',
        apiEndpoints: [
          'db-mysql_grant',
          'db-mysql_user',
          'db-mysql_access'
        ],
        subStates: [
          'mysql-database',
          'mysql-user',
          'mysql-access',
          'mysql-phpmyadmin'
        ],
        keywords: [
          'Mysql',
          'Sql',
          'Db',
          'Database',
          'Dbs'
        ],
        parent: 'Site'
      },
      {
        stateId: 'pgsql',
        title: 'translate.navigation.page.pgsql.label',
        pageEndpoint: 'db-pgsql_db',
        pageIcon: 'presentational-pgsql-database',
        pageTitle: 'translate.page.pgsql.title',
        apiEndpoints: [
          'db-pgsql_grant',
          'db-pgsql_user',
          'db-pgsql_access'
        ],
        subStates: [
          'pgsql-database',
          'pgsql-user',
          'pgsql-access'
        ],
        keywords: [
          'Postgre',
          'PostgreSQL',
          'Sql',
          'Db',
          'Database',
          'Dbs'
        ],
        parent: 'Site'
      }
    ]
  },
  {
    groupId: 'security',
    title: 'translate.navigation.group.security.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'backup-restore',
        title: 'translate.navigation.page.backup_restore.label',
        pageIcon: 'presentational-backup-manager',
        pageTitle: 'translate.page.backup.restore.title',
        parent: 'Security',
        pageEndpoint: 'backup',
        apiEndpoints: [
          'backup-restore',
          'backup-dbs',
          'backup-emails',
          'backup-files'
        ],
        keywords: [
          'backup',
          'back up',
          'restore',
          'revert',
          'download'
        ],
        subStates: [
          'backup-restore-manage',
          'backup-restore-history'
        ]
      },
      {
        stateId: 'ssl',
        title: 'translate.navigation.page.ssl.label',
        pageIcon: 'presentational-ssl',
        pageTitle: 'translate.page.ssl.title',
        parent: 'Security',
        pageEndpoint: 'ssl',
        apiEndpoints: [
          'ssl',
          'domainAll'
        ],
        localPageTaskLoaders: {
          createBox: ['ssl']
        },
        keywords: [
          'SSL',
          'HTTPS',
          'certificate',
          'encrypt',
          'Let’s Encrypt',
          'Lets encrypt',
          'Let s encrypt'
        ]
      },
      {
        stateId: 'https-enforce',
        title: 'translate.navigation.page.https-enforce.label',
        pageIcon: 'presentational-https',
        pageTitle: 'translate.page.https-enforce.title',
        parent: 'Security',
        pageEndpoint: 'domain',
        apiEndpoints: [],
        keywords: [
          'https',
          'enforce',
          'domain',
          'external',
          'links',
          'rewrite'
        ]
      },
      {
        stateId: 'protected',
        subStates: [
          'protected-urls',
          'protected-users'
        ],
        title: 'translate.navigation.page.protected-urls.label',
        pageEndpoint: 'location',
        pageIcon: 'presentational-password-protected-urls',
        pageTitle: 'translate.page.protected.title',
        apiEndpoints: [
          'location-user'
        ],
        keywords: [
          'protected',
          'url',
          'location',
          'addr',
          'directory',
          'directories'
        ],
        parent: 'Security'
      },
      {
        stateId: 'location-ip-block',
        title: 'translate.navigation.page.block-ip.label',
        pageEndpoint: 'location-addr',
        pageIcon: 'presentational-ip-deny-manager',
        pageTitle: 'translate.page.locationIpBlock.title',
        apiEndpoints: [
          'location-user'
        ],
        keywords: [
          'block',
          'prevent',
          'ip'
        ],
        parent: 'Security'
      },
      {
        stateId: 'sg-sitescanner',
        title: 'translate.navigation.page.sg-sitescanner.label',
        pageEndpoint: 'domain',
        pageIcon: 'presentational-sg-sitescanner',
        pageTitle: 'page.sg-sitescanner.title',
        apiEndpoints: ['domain'],
        keywords: [],
        parent: 'Security'
      }
    ]
  },
  {
    groupId: 'speed',
    title: 'translate.navigation.group.speed.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'cloudflare',
        title: 'translate.navigation.page.cloudflare.label',
        pageEndpoint: 'cloudflare',
        pageIcon: 'presentational-cloudflare',
        pageTitle: 'translate.page.cloudflare.title',
        apiEndpoints: [
          'cloudflare-zone',
          'cloudflare-zone_settings',
          'cloudflare-firewall'
        ],
        keywords: [
          'cdn',
          'cache',
          'cloudflare',
          'caching',
          'bandwidth',
          'speed',
          'fast',
          'waf'
        ],
        parent: 'Speed'
      },
      {
        stateId: 'cacher',
        title: 'translate.navigation.page.caching.label',
        pageIcon: 'presentational-speed-caching',
        pageTitle: 'translate.page.superCacher.title',
        subStates: [
          'cacher-static-cache',
          'cacher-dynamic-cache',
          'cacher-memcached'
        ],
        pageEndpoint: 'domain-cache',
        apiEndpoints: [
          'uservice'
        ],
        keywords: [
          'cache',
          'caching',
          'memcached',
          'fast',
          'optimize',
          'optimise',
          'speed',
          'speed up',
          'cach'
        ],
        parent: 'Speed'
      }
    ]
  },
  {
    groupId: 'wordpress',
    title: 'translate.navigation.group.wordpress.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'wp-manage',
        title: 'translate.navigation.page.wordrpess-manager.label',
        pageIcon: 'presentational-wordpress-manage',
        pageTitle: 'translate.page.wp.manage.title',
        parent: 'WordPress',
        pageEndpoint: 'wordpress',
        apiEndpoints: [
          'app',
          'domain'
        ],
        localPageTaskLoaders: {
          createBox: ['wordpress']
        },
        keywords: [
          'wordpress',
          'wp',
          'woocommerce',
          'woo',
          'install',
          'wp-admin',
          'wp admin'
        ]
      },
      {
        stateId: 'wp-staging',
        title: 'translate.navigation.page.wordpress-staging.label',
        pageEndpoint: 'app-staging',
        pageIcon: 'presentational-wordpress-staging',
        pageTitle: 'translate.page.staging.title',
        apiEndpoints: [
          'app',
          'app-restore_backup'
        ],
        localPageTaskLoaders: {
          createBox: ['app-staging']
        },
        keywords: [
          'staging',
          'development',
          'stage',
          'deploy',
          'push',
          'production',
          'replicate',
          'test',
          'git',
          'live'
        ],
        parent: 'WordPress'
      },
      {
        stateId: 'wp-transfer',
        title: 'translate.navigation.page.wordpress-migrator.label',
        pageIcon: 'product-wordpress-transfer',
        pageTitle: 'translate.page.wp.transfer.title',
        parent: 'WordPress',
        pageEndpoint: 'wordpress-transfer',
        apiEndpoints: [
          'domain-all'
        ],
        keywords: [
          'transfer',
          'migrate',
          'migrator',
          'wordPress',
          'wp',
          'move'
        ]
      },
      {
        stateId: 'wp-autoupdate',
        title: 'translate.navigation.page.wordpress-autoupdate.label',
        pageIcon: 'presentational-wordpress-autoupdate',
        pageTitle: 'translate.page.wp.autoupdate.title',
        parent: 'WordPress',
        pageEndpoint: 'app',
        apiEndpoints: [
          'app-list',
          'domain'
        ],
        keywords: [
          'autoupdate',
          'update',
          'wordpress',
          'wp update',
          'word press',
          'wp'
        ]
      }
    ]
  },
  {
    groupId: 'domains',
    title: 'translate.navigation.group.doamin.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'parked-domains',
        title: 'translate.navigation.page.parked-domains.label',
        pageEndpoint: 'domain-alias',
        pageIcon: 'presentational-domain-parked',
        pageTitle: 'translate.page.domainAlias.title',
        apiEndpoints: [],
        keywords: [
          'park',
          'parked',
          'domain',
          'alias',
          'forward'
        ],
        parent: 'Domain'
      },
      {
        stateId: 'subdomain',
        title: 'translate.navigation.page.subdomains.label',
        pageEndpoint: 'domain',
        pageIcon: 'presentational-subdomain',
        pageTitle: 'translate.page.subdomain.title',
        apiEndpoints: [],
        keywords: [
          'subdomain',
          'sub',
          'domain',
          'alias'
        ],
        parent: 'Domain'
      },
      {
        stateId: 'redirect',
        title: 'translate.navigation.page.redirects.label',
        pageEndpoint: 'location-redirect',
        pageIcon: 'presentational-domain-redirect',
        pageTitle: 'translate.page.redirect.title',
        apiEndpoints: [
          'domain'
        ],
        keywords: [
          'redirect',
          'redirects',
          '301',
          '302',
          'forward',
          'domain redirect',
          'redirecting'
        ],
        parent: 'Domain'
      },
      {
        stateId: 'dns',
        title: 'translate.navigation.page.dns-zone-editor.label',
        pageEndpoint: 'dns',
        pageIcon: 'presentational-dns-zone',
        pageTitle: 'translate.page.dns.title',
        apiEndpoints: [
          'dns-txt',
          'dns-srv',
          'dns-cname',
          'dns-aaaa',
          'dns-mx',
          'dns-a'
        ],
        keywords: [
          'dns',
          'ns',
          'domain',
          'records',
          'a record'
        ],
        parent: 'Domain'
      }
    ]
  },
  {
    groupId: 'mail',
    title: 'translate.navigation.group.email.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'email',
        title: 'translate.navigation.page.email-accounts.label',
        pageEndpoint: 'email',
        pageIcon: 'presentational-email-account',
        pageTitle: 'translate.page.email.title',
        apiEndpoints: [
          'email'
        ],
        keywords: [
          'email',
          'mail',
          'mail box',
          'mailbox',
          'mail address',
          'address',
          'inbox',
          'emails',
          'mails',
          'box'
        ],
        parent: 'Email'
      },
      {
        stateId: 'email-forward',
        title: 'translate.navigation.page.email-forwarders.label',
        pageEndpoint: 'email-forward',
        pageIcon: 'presentational-email-forwarder',
        pageTitle: 'translate.page.emailForward.title',
        apiEndpoints: [
          'email-forward'
        ],
        keywords: [
          'Forward',
          'Discard',
          'Forwarder',
          'Discarder',
          'Filter',
          'Rule'
        ],
        parent: 'Email'
      },
      {
        stateId: 'email-autoresponder',
        title: 'translate.navigation.page.autoresponders.label',
        pageIcon: 'presentational-email-autoresponder',
        pageTitle: 'translate.page.emailAutoresponder.title',
        parent: 'Email',
        pageEndpoint: 'email-autoresponder',
        apiEndpoints: [
          'email',
          'domain',
          'domain-alias'
        ],
        keywords: [
          'autoresponder',
          'vacation mode',
          'vacation notice',
          'notice',
          'responder',
          'autoresponse'
        ]
      },
      {
        stateId: 'email-filters',
        title: 'translate.navigation.page.email-filters.label',
        pageIcon: 'presentational-email-filter',
        pageTitle: 'translate.page.emailFilter.title',
        parent: 'Email',
        pageEndpoint: 'email-filter',
        apiEndpoints: [
          'email',
          'domain',
          'domain-alias'
        ],
        keywords: [
          'Filter',
          'Filters',
          'Conditions',
          'Rules',
          'Automate',
          'auto',
          'Email rules',
          'Email filter',
          'Filtering',
          'Discard',
          'Forward'
        ]
      },
      {
        stateId: 'emailauth',
        title: 'translate.navigation.page.authentication.label',
        pageIcon: 'presentational-email-authentication',
        pageTitle: 'translate.page.email.auth.title',
        parent: 'Email',
        pageEndpoint: ['spf'],
        apiEndpoints: [
          'dkim',
          'domain-all'
        ],
        keywords: [
          'Authenticate',
          'Spoof',
          'Spoofing',
          'SPF',
          'Dkim',
          'Spam'
        ]
      },
      {
        stateId: 'spam-experts',
        title: 'SpamExperts',
        parent: 'Email',
        pageEndpoint: 'domain-all',
        pageIcon: 'presentational-spam-expert',
        pageTitle: 'translate.page.spam-experts.title',
        apiEndpoints: [],
        keywords: [
          'Spam',
          'Antispam',
          'Anti-spam',
          'Spam prevention',
          'Prevention',
          'Virus'
        ]
      }
    ]
  },
  {
    groupId: 'stats',
    title: 'translate.navigation.group.stats.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'resource-stats',
        title: 'translate.navigation.page.traffic-statistics.label',
        pageIcon: 'presentational-traffic-statistics',
        pageTitle: 'translate.page.stats.title',
        parent: 'Stats',
        pageEndpoint: 'webstats',
        apiEndpoints: [],
        keywords: [
          'statistics',
          'stats',
          'traffic',
          'bandwidth',
          'visits',
          'visitors',
          'hits',
          'Conditions',
          'Email rules',
          'Email filter',
          'Filtering',
          'Discard',
          'Forward'
        ]
      },
      {
        stateId: 'error-log',
        title: 'translate.navigation.page.error-logs.label',
        pageIcon: 'presentational-error-logs',
        pageTitle: 'translate.page.error-logs.title',
        parent: 'Stats',
        pageEndpoint: 'errorlogs',
        apiEndpoints: [
          'domain'
        ],
        keywords: [
          'error',
          'error log',
          'log',
          'weblog',
          'web log',
          'server log'
        ]
      }
    ]
  },
  {
    groupId: 'devs',
    title: 'translate.navigation.group.devs.label',
    pageIcon: 'TODO',
    pageTitle: 'TODO',
    items: [
      {
        stateId: 'git',
        title: 'translate.navigation.page.git.label',
        pageIcon: 'presentational-git',
        pageTitle: 'translate.page.git.title',
        parent: 'Devs',
        pageEndpoint: 'app-repo',
        apiEndpoints: [
          'app',
          'ssh',
          'ssh-key'
        ],
        localPageTaskLoaders: {
          createBox: ['app-repo']
        },
        keywords: [
          'Git',
          'Stage',
          'Repo',
          'Staging',
          'Branch',
          'Branching',
          'Production',
          'Development',
          'Develop'
        ]
      },
      {
        stateId: 'cron',
        title: 'translate.navigation.page.cron-jobs.label',
        pageEndpoint: 'cron',
        pageIcon: 'presentational-cron-job',
        pageTitle: 'translate.page.cron.title',
        apiEndpoints: [
          'cron-notify'
        ],
        subStates: [
          'cron-job',
          'cron-notification'
        ],
        keywords: [
          'job',
          'schedule',
          'automated',
          'command',
          'time'
        ],
        parent: 'Devs'
      },
      {
        stateId: 'php-settings',
        title: 'translate.navigation.page.php-settings.label',
        pageEndpoint: 'location-phpini_settings',
        pageIcon: 'presentational-php-manager',
        pageTitle: 'translate.page.phpSettings.title',
        apiEndpoints: [
          'php-variable',
          'php-version',
          'location-phpver',
          'domain'
        ],
        parent: 'Devs',
        keywords: [
          'php',
          'php versions',
          'php variables',
          'variables',
          'php 7'
        ]
      },
      {
        stateId: 'ssh',
        title: 'translate.navigation.page.ssh-key-manager.label',
        pageIcon: 'presentational-ssh',
        pageTitle: 'translate.page.ssh.title',
        keywords: [
          'keys',
          'secure',
          'shell',
          'ssh',
          'ip access',
          'private key',
          'public key',
          'console',
          'terminal',
          'ssh key'
        ],
        pageEndpoint: 'ssh',
        apiEndpoints: [
          'ssh-key'
        ],
        parent: 'Devs'
      }
    ]
  }
];
