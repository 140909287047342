import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { cn, Flex, Placeholder, Table, Title } from 'sg-styleguide';
import { removeSGTableRowSelection } from '../../../core/actions/sg-table';
import { deepclone } from '../../../core/common/deepclone';
import { RootState } from '../../../core/reducers/index';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';
import { HideOnMobile, ShowOnMobile, ToggleOnMobile } from '../../containers/device';
import PartialLoader from '../../containers/partial-loader';
import { SecondLevelTitle } from '../../containers/titles';
import './sg-table.scss';

type Props = {
  resources?: LoaderResource[];
  rowResources?: LoaderResource[];
  removeSGTableRowSelection: Function;
  requestedIds: string;
  renderBeforeTableContent?: Function;
  disableMobileArrange?: boolean;
  dealtItemCount?: number;
  onLoadMore?: (nextItemsCount: number) => any;
  [other: string]: any;
};

const dealtItemCount = 20;

class SGTable extends React.Component <Props, any> {
  readonly dealItemCount = this.props.dealtItemCount || dealtItemCount;

  readonly state = {
    itemsCountToRender: this.dealItemCount
  };

  handleMouseOver = () => {
    const { selectedTableRows, removeSGTableRowSelection } = this.props;
    if (document.querySelector('[data-component="table"]:hover') && selectedTableRows.length === 1) {
      removeSGTableRowSelection();
    }
  };

  createMobileDesktopColumnsOptions = () => {
    const { columns, reordable } = this.props;

    if (!columns) {
      return columns;
    }

    const mobileColumns = deepclone(columns);

    if (this.props.disableMobileArrange) {
      return mobileColumns;
    }

    const firstColumn = mobileColumns[0];
    // Remove the last column. We will include it into the first column
    const lastColumn = mobileColumns.pop();

    const initialFirstColumnRender = firstColumn.render ? firstColumn.render : (cellText, entity) => (cellText);
    const initialLastColumnRender = lastColumn.render ? lastColumn.render : (cellText, entity) => (cellText);

    // For now the first column will not have header.
    delete firstColumn.header;

    firstColumn.style = {
      fontWeight: 'bold',
      ...firstColumn.style
    };

    firstColumn.render = (cellText, entity) => {
      return [(
        <HideOnMobile key="hide-mobile">
          {initialFirstColumnRender(cellText, entity)}
        </HideOnMobile>
      ), (
        <ShowOnMobile key="show-mobile">
          <Flex
            justify="space-between"
            align="center"
            gutter="none"
            margin="none"
            style={{
              width: reordable ? 'calc(100% - 40px)' : '100%',
              flexWrap: 'nowrap'
            }}
          >
            <Title level="4" density="none" truncate>
              {initialFirstColumnRender(cellText, entity)}
            </Title>
            <div style={{ flexShrink: 0 }}>
              {initialLastColumnRender(entity[lastColumn.accessor], entity)}
            </div>
          </Flex>
        </ShowOnMobile>
      )];
    };

    return mobileColumns;
  };

  createDesktopColumnsOptions = () => {
    const { columns, data } = this.props;

    if (!columns || !data || data.length === 0) {
      return columns;
    }

    const desktopColumns = deepclone(columns);
    const lastColumn = desktopColumns[desktopColumns.length - 1];

    // Due to table-layout: fixed; it is necessary to put a fixed width on the last column so that it does not occupy
    // the same percentage in relation to the others. But this behavior is needed only if it is an action menu.
    let isActionMenu = false;
    try {
      const component = lastColumn && lastColumn.render(lastColumn.accessor, data[0]);
      isActionMenu = component && component.props && component.props.items;
    } catch (e) {
      console.warn(e);
    }

    lastColumn.style = {
      width: isActionMenu && '100px',
      maxWidth: isActionMenu && '100px', // reordable table
      paddingLeft: isActionMenu && '0px',
      textAlign: 'right',
      whiteSpace: 'nowrap',
      ...lastColumn.style
    };

    return desktopColumns;
  };

  render() {
    const {
      title,
      data,
      columns,
      intl,
      noDataTitle = 'translate.sg-table.default.title',
      noDataMessage,
      reordable,
      resources = [],
      onLoadMore = () => null,
      getAllLoaded,
      requestedIds,
      backgroundTasks,
      selectedTableRows,
      ...props
    } = this.props;

    const mobileColumns = this.createMobileDesktopColumnsOptions();
    const desktopColumns = this.createDesktopColumnsOptions();
    const { rowResources, addOffsetOnMobile, ...propsToPropagate } = props;
    const requestedIdsSet = new Set(JSON.parse(requestedIds));

    const commonProps = {
      className: cn('sg-table', addOffsetOnMobile && 'sg-table--mobile-offset'),
      data: data ? [...data].splice(0, this.state.itemsCountToRender) : [],
      rowKey: 'id',
      shadow: true,
      showLoadMore: data && data.length > this.state.itemsCountToRender,
      onLoadMore: () => {
        const nextItemsCount = this.state.itemsCountToRender + this.dealItemCount;

        onLoadMore(nextItemsCount);

        this.setState({ itemsCountToRender: nextItemsCount });
      },
      reordable,
      shouldRowLoading: (row) => requestedIdsSet.has(row.id),
      noDataProps: {
        message: noDataMessage && intl.formatMessage({ id: noDataMessage }),
        title: intl.formatMessage({ id: noDataTitle })
      },
      translation: {
        loadMoreText: intl.formatMessage({ id: 'translate.sg-table.load.more' })
      }
    };

    return (
      <div style={{ width: '100%' }}>
        {title && <SecondLevelTitle>{title}</SecondLevelTitle>}

        <div style={{ position: 'relative', minHeight: 120 }}>
          <PartialLoader resources={resources} position="absolute">
            <ToggleOnMobile>
              <Table columns={mobileColumns} {...commonProps} {...propsToPropagate} />
              <Table
                columns={desktopColumns}
                {...commonProps}
                {...propsToPropagate}
                selectedRows={selectedTableRows}
                onMouseOver={this.handleMouseOver}
              />
            </ToggleOnMobile>
          </PartialLoader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState, { resources = [], rowResources }: Partial<Props>) => {
  const resourcesToUse = rowResources || resources.map((r) => ({
    ...r,
    methods: ['DELETE']
  }));

  const { requested } = getBlockingRequests(store, resourcesToUse);

  return {
    // stirngified in order to use the react props -> render optimization,
    // instead of JSON.stringify compare of all props
    requestedIds: JSON.stringify(
      Array.from(new Set(requested.map(({ id }) => id)))
    ),
    selectedTableRows: store.table.selectedRows
  };
};

export default connect(mapStateToProps, { removeSGTableRowSelection })(
  injectIntl(SGTable)
);
