import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ButtonsGroup, Flex, IconButton, Text, Toolbar } from 'sg-styleguide';
import { RootState } from '../../../../core/reducers';
import ActionToolbar from '../action-toolbar';
import * as fileManagerActions from '../core/actions/file-manager';
import { getEntityByPath, getEntityParentPath, getEntityReadableName } from '../core/utils/index';
import './header.scss';

class FileManagerHeader extends React.Component<any, any> {
  render() {
    const { codeEditorIsVisible, environment } = this.props;

    return (
      <Toolbar density="compact" align="center" className="file-manager-header">
        {environment.isDesktop ? this.renderDesktop() : this.renderMobile()}
      </Toolbar>
    );
  }

  renderMobile() {
    const { fileManagerState, selectedNavigationEntity, selectedContentEntities } = this.props;
    const selectedNavigationEntityPath = selectedNavigationEntity && getEntityReadableName(selectedNavigationEntity);
    const entity = selectedNavigationEntity ?
      getEntityByPath(getEntityParentPath(selectedNavigationEntity), fileManagerState) : null;
    const count = selectedContentEntities.length;

    if (count > 0) {
      return (
        <Flex justify="space-between" margin="none" style={{ width: '100%' }} data-e2e="mobile-header">
          <Flex margin="none" gutter="none" align="center">
            <IconButton
              icon="cross"
              size="small"
              shape="circle"
              onClick={() => {
                this.props.actions.selectContentRows([]);
                this.props.actions.clearContextEntities();
              }}
            />

            <Text color="light" style={{ marginLeft: '10px' }}>
              {this.props.intl.formatMessage({ id: 'translate.file.manager.toolbar.selected.entities' }, { count })}
            </Text>
          </Flex>

          <IconButton
            icon="dots"
            onClick={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();
              this.props.openContextMenu(event);
            }}
          />
        </Flex>
      );
    }

    return (
      <React.Fragment>
        <IconButton
          key="back-button"
          icon="arrow-back"
          shape="circle"
          onClick={() => this.props.actions.onEntityClick({ entity })}
          data-e2e="back-button"
        />
        &nbsp;
        {selectedNavigationEntityPath ? selectedNavigationEntityPath : '/'}
      </React.Fragment>
    );
  }

  renderDesktop() {
    const { menuAction, codeEditor, codeEditorIsVisible, intl } = this.props;
    const activeIndex = codeEditorIsVisible ? 1 : 0;
    const buttons = [
      intl.formatMessage({ id: 'translate.generic.explorer' }),
      intl.formatMessage({ id: 'translate.generic.editor' })
    ];

    return (
      <React.Fragment>
        <ButtonsGroup
          activeIndex={activeIndex}
          buttons={buttons}
          color="secondary"
          size="small"
          onChange={this.props.actions.toggleCodeEditor}
          data-e2e="explorer-editor-switch"
        />

        <ActionToolbar menuAction={menuAction} codeEditor={codeEditor} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  environment: state.environment,
  fileManagerState: state.fileManager,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerHeader));
