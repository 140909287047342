import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Box, ChartContainer, ChartTooltip, Flex, Grid, LineChart } from 'sg-styleguide';
import { Days } from '../../../../core/definitions/stats';
import { formatBandwidthLabel, formatDate } from '../utils';
import { getStatsParameterSum } from '../../../../core/utils/stats-format';

type Props = {
  stats: any[];
  intl?: Intl;
  type: 'unique-visitors' | 'pageviews' | 'average-number-of-pages' | 'bandwidth' | string;
  userLang?: string;
};

const filterElementsProps = {
  pages: {
    elementsIds: ['visits'],
    filteredElementsIds: ['visits']
  },
  visits: {
    elementsIds: ['pages'],
    filteredElementsIds: ['pages']
  },
  pagesPerVisit: {
    elementsIds: ['pagesPerVisit'],
    filteredElementsIds: ['pagesPerVisit']
  },
  bwInMB: {
    elementsIds: ['bwInMB'],
    filteredElementsIds: ['bwInMB']
  }
};

export const LineChartsStatistics = ({ stats, intl, type, userLang }: Props) => {
  const tooltipLabels = {
    visits: `${intl.formatMessage({ id: 'translate.page.stats.unique-visitors.label' })}: `,
    pages: `${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })}: `,
    pagesPerVisit: `${intl.formatMessage({ id: 'translate.page.stats.average-number-of-pages.label' })}: `,
    bwInMB: `${intl.formatMessage({ id: 'translate.page.stats.bandwidth.label' })}: `
  };

  const formatDateString = (date) => formatDate(date, userLang);

  switch (type) {
    case 'unique-visitors':
      return (
        <ChartContainer
          title={`
            ${intl.formatMessage({ id: 'translate.page.stats.unique-visitors.label' })} -
            ${getStatsParameterSum(stats, 'visits')}
          `}
          renderChildren={() => (
            <React.Fragment>
              <LineChart
                syncId="days-stats"
                data={stats}
                xAxisKey="date"
                tickFormatterX={formatDateString}
                {...filterElementsProps.pages}
                renderTooltip={({ payload }: { payload: Days }) => (
                  <ChartTooltip
                    elementsIds={['visits']}
                    filteredElementsIds={['visits']}
                    payload={payload}
                    title={formatDate(payload.date, userLang)}
                    elementLabels={tooltipLabels}
                  />
                )}
              />
            </React.Fragment>
          )}
        />
      );
    case 'pageviews':
      return (
        <ChartContainer
          title={`
            ${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })} -
            ${getStatsParameterSum(stats, 'pages')}
          `}
          renderChildren={() => (
            <React.Fragment>
              <LineChart
                syncId="days-stats"
                data={stats}
                xAxisKey="date"
                tickFormatterX={formatDateString}
                {...filterElementsProps.visits}
                renderTooltip={({ payload }: { payload: Days }) => (
                  <ChartTooltip
                    elementsIds={['pages']}
                    filteredElementsIds={['pages']}
                    payload={payload}
                    title={formatDate(payload.date, userLang)}
                    elementLabels={tooltipLabels}
                  />
                )}
              />
            </React.Fragment>
          )}
        />
      );
    case 'average-number-of-pages':
      return (
        <ChartContainer
          title={intl.formatMessage({ id: 'translate.page.stats.average-number-of-pages.label' })}
          renderChildren={() => (
            <React.Fragment>
              <LineChart
                data={stats}
                xAxisKey="date"
                tickFormatterX={formatDateString}
                {...filterElementsProps.pagesPerVisit}
                renderTooltip={({ payload }: { payload: Days }) => (
                  <ChartTooltip
                    {...filterElementsProps.pagesPerVisit}
                    payload={payload}
                    title={formatDate(payload.date, userLang)}
                    elementLabels={tooltipLabels}
                  />
                )}
              />
            </React.Fragment>
          )}
        />
      );
    case 'bandwidth':
      return (
        <ChartContainer
          title={`
            ${intl.formatMessage({ id: 'translate.page.stats.bandwidth.label' })} -
            ${formatBandwidthLabel(
              getStatsParameterSum(stats, 'bwInMB')
            )}
          `}
          renderChildren={({ elementsIds, filteredElementsIds }) => (
            <React.Fragment>
              <LineChart
                data={stats}
                xAxisKey="date"
                tickFormatterX={formatDateString}
                {...filterElementsProps.bwInMB}
                renderTooltip={({ payload }: { payload: Days }) => (
                  <ChartTooltip
                    {...filterElementsProps.bwInMB}
                    payload={payload}
                    title={formatDate(payload.date, userLang)}
                    elementLabels={tooltipLabels}
                    formatValue={(key, value) => formatBandwidthLabel(value)}
                  />
                )}
              />
            </React.Fragment>
          )}
        />
      );
    default:
      return null;
  }
};

export default connect<any, any, Props>(({ session }) => ({
  userLang: session && session.user && session.user.language
}))(injectIntl(LineChartsStatistics));
