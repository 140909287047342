import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Section } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import VCS from '../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create';

interface DomainAliasProps {
  actions: CrudActions;
  items: any;
  location: any;
  currentDomainName: any;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
}

class DomainAliasPage extends React.Component<DomainAliasProps, any> {
  readonly state = {
    currentDeleteConformationDialogPayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_DOMAIN_ALIAS,
          success: {
            intlKey: 'translate.page.domainAlias.create.success.message',
            intlValues: { domain: formData.name }
          },
          error: {
            intlKey: 'translate.page.domainAlias.create.error.message',
            intlValues: { domain: formData.name }
          }
        }
      }
    });
  };

  render() {
    const { currentDomainName, intl } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.domain' }),
        accessor: 'name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          icon="presentational-domain-parked"
          title={intl.formatMessage({ id: 'translate.page.domainAlias.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.domainAlias.instructions' })}
        />
        <Section>
          <Grid>
            <CreateBox>
              <CreateForm
                onSubmit={(data) => this.onCreateFormSubmit({
                  ...data,
                  alias_to: currentDomainName
                })}
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.DOMAIN_ALIAS.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.domainAlias.list.title' })}
                data={this.props.items.domainAlias}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.domainAlias.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;
    const title = intl.formatMessage(
      { id: 'translate.page.domainAlias.delete.dialog.title' },
      { domain: entityName }
    );
    return (
      <DeleteDialog
        title={title}
        onSubmit={(data) => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: entity.name,
      _metaFields: { ...API_RESOURCE.DOMAIN_ALIAS },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.domainAlias.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.domainAlias.delete.error.message'
          }
        }
      }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.DOMAIN_ALIAS.resourceNameMetaApi}
        items={[{
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          icon: 'trash',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeleteConformationDialogPayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (state) => ({
  currentDomainName: state.sites.currentDomainName
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(indexWithCRUD()(
  DomainAliasPage,
  API_RESOURCE.DOMAIN_ALIAS
)) as any;
