import { handleActions } from 'redux-actions';
import { TRIGGER_PAGE_DATA_FETCH } from '../constants/actions';

export type IndexWithCrud = {
  lastManualFetchTimestamp: number;
};

const initialState: IndexWithCrud = {
  lastManualFetchTimestamp: 0
};

export default handleActions<any, any>({
  [TRIGGER_PAGE_DATA_FETCH]: (state, action: any) => ({
    ...state,
    lastManualFetchTimestamp: +new Date()
  })
}, initialState);
