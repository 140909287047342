import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Flex, Grid, Icon, Text, textToHTML } from 'sg-styleguide';
import PartialLoader from '../../containers/partial-loader';
import './setting-list-item.scss';

type SettingListItemProps = {
  environment: { isPhone: boolean };
  children: any;
  className?: string;
  disabled?: boolean;
  disableExpand?: boolean;
  title: any;
  info?: string;
  resources?: LoaderResource[];
};

const SettingListItem = ({
  children,
  className,
  disabled,
  disableExpand,
  environment,
  title,
  info,
  resources
}: SettingListItemProps) => {
  const { isPhone } = environment;
  const classes = [
    'settings-list-item-wrapper',
    disabled && 'settings-list-item-wrapper--disabled',
    className
  ].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <PartialLoader resources={resources} position="absolute" hideSpinner>
        <Accordion
          className="settings-list-item"
          padding={isPhone ? ['x-small', 'medium'] : ['x-small', 'large']}
          disableExpand={disableExpand}
          title={() => (
            <Flex align="center" justify="space-between" margin="none" gutter="none" style={{ width: '100%' }}>
              <Text key="title" weight="bold">
                {title}
              </Text>

              {!isPhone && (
                <Flex key="action" align="center" margin="none" gutter="none">
                  {children}
                </Flex>
              )}
            </Flex>
          )}
        >
          {info && (
            <Text size="small" color="light" style={{ maxWidth: '800px' }}>
              {textToHTML(info)}
            </Text>
          )}
        </Accordion>

        {isPhone && (
          <Grid padding={['none', 'medium', 'medium', 'medium']}>
            {children}
          </Grid>
        )}
      </PartialLoader>
    </div>
  );
};

export default connect<{}, {}, any>(({ environment }) => ({ environment }))(SettingListItem);
