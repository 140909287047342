import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import {
  Context,
  ContextMenu,
  ContextMenuItem,
  Flex,
  Grid,
  IconButton,
  Spacer,
  Text
} from 'sg-styleguide/lib/components/';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

class UpdateIPAdress extends React.Component<any, any> {
  allRenderedFields = [];

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues.activeItemIndex !== undefined) {
      this.allRenderedFields[initialValues.activeItemIndex].focus();
    }
  }

  renderMembers = ({ fields }) => {
    const { intl } = this.props;

    return (
      <React.Fragment>
        <Text size="small" color="light" weight="light" align="left">
          {intl.formatMessage({ id: 'translate.generic.ip.ip.range' })}
        </Text>
        <Grid>
          {fields.map((ip, index, array) => (
            <Flex wrap="nowrap" margin="none" gutter="none" align="center" key={ip}>
              <Field
                name={ip}
                type="text"
                onRefsReady={(ipRef) => {
                  this.allRenderedFields.push(ipRef);
                }}
                component={FormInput}
              />

              <Spacer size="x-small" />

              <Context.Consumer>
                {({ device }) => device.isPhone ? (
                  <React.Fragment>
                    <ContextMenu opener={<IconButton shape="circle" icon="dots" />}>
                      <ContextMenuItem
                        icon="circle-plus"
                        onClick={() => fields.insert(index + 1, '')}
                      >
                        {intl.formatMessage({ id: 'translate.ssh.manage.ip.add.new' })}
                      </ContextMenuItem>

                      <ContextMenuItem
                        icon="circle-minus"
                        onClick={() => {
                          if (index === 0 && array.length === 1) {
                            fields.insert(index + 1, '');
                          }

                          fields.remove(index);
                        }}
                      >
                        {intl.formatMessage({ id: 'translate.ssh.manage.ip.remove' })}
                      </ContextMenuItem>
                    </ContextMenu>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IconButton
                      icon="circle-minus"
                      shape="circle"
                      onClick={() => {
                        if (index === 0 && array.length === 1) {
                          fields.insert(index + 1, '');
                        }

                        fields.remove(index);
                      }}
                    />

                    <IconButton
                      icon="circle-plus"
                      shape="circle"
                      onClick={() => fields.insert(index + 1, '')}
                    />
                  </React.Fragment>
                )}
              </Context.Consumer>
            </Flex>
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <FieldArray
        name="from"
        component={this.renderMembers}
      />
    );
  }
}

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: REDUX_FORM.SSH_CHANGE_IP })(UpdateIPAdress)
  )
);
