import { LocalTaskLoaderType } from '../constants/common';
import * as menuItems from '../../static-api/menu-items';

export const pathnameMatchingStateId = (stateId: string, pathname: string): boolean =>
  stateId === pathname || '/' + stateId === pathname;

export const findGroupItemByStateId = (group, stateId: string) =>
  group.items.find((item) => item.stateId === stateId);

export const pathnameMatchingSubState = (substates: string[] = [], pathname): boolean =>
  substates.filter((stateId: string) => pathnameMatchingStateId(stateId, pathname)).length > 0;

export const getApiEndpointsForCurrentRoute = (pathname: string) => {
  let res = [];

  menuItems.groups.forEach((group: any) => {
    group.items.forEach((item) => {
      if (!item.pageEndpoint || !item.apiEndpoints) {
        return;
      }

      const isRelatedSubstate = pathnameMatchingSubState(item.subStates, pathname);
      const isRelatedState = pathnameMatchingStateId(item.stateId, pathname);

      if (isRelatedState || isRelatedSubstate) {
        res = [
          ...item.apiEndpoints,
          item.pageEndpoint
        ];
      }
    });
  });

  return res;
};

export const getLocalTaskLoaderEndpointsForCurrentRoute = (
  pathname: string,
  localTaskLoaderType?: LocalTaskLoaderType
) => {

  const pageGroup: any = menuItems.groups.find((group: any) =>
    group.items.find(({ stateId, subStates = [] }) =>
      pathnameMatchingStateId(stateId, pathname) ||
      pathnameMatchingSubState(subStates, pathname)
    )
  );

  if (pageGroup && pageGroup.items) {
    const pageData = pageGroup.items.find(({ stateId, subStates = [] }) =>
      pathnameMatchingStateId(stateId, pathname) ||
      pathnameMatchingSubState(subStates, pathname)
    );

    if (localTaskLoaderType && pageData.localPageTaskLoaders) {
      return {
        [localTaskLoaderType]: pageData.localPageTaskLoaders[localTaskLoaderType] || []
      };
    }

    return pageData.localPageTaskLoaders || {};
  }

  return {};
};

export const getToolMainEndpoint = (stateId: string) => {
  // TODO: map all stateIds in constants
  const itemGroup = menuItems.groups.find((group: any) => findGroupItemByStateId(group, stateId));
  const item = itemGroup && findGroupItemByStateId(itemGroup, stateId);

  if (item && item.pageEndpoint) {
    return item.pageEndpoint;
  }

  return null;
};
