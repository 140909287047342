import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from 'sg-styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../containers/sg-form';
import FormInput from '../../../../components/form-input';

type Props = {
  domainName: string,
  intl: Intl;
  sPanelFormSubmit: (formName) => void;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CREATE_EMAIL_DISCARD;

const Form = ({ domainName, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          data-e2e="forward-crate-name"
          name="name"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.emailForward.discard.name' })}
          placeholder={intl.formatMessage({ id: 'translate.page.emailForward.name.placeholder' })}
          suffix={`@${domainName}`}
          normalize={(value) => value.toLowerCase()}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <Field
          data-e2e="forward-crate-discard_msg"
          name="discard_msg"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg' })}
          placeholder={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg.placeholder' })}
          validate={[validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const EmailForwardForm = reduxForm({
  form: formName,
  initialValues: {
    is_discard: 1,
    _metaFields: {
      ...API_RESOURCE.EMAIL_FORWARD,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(EmailForwardForm));
