import { call, put, race, take } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import * as sessionActions from '../actions/session';
import * as ACTIONS from '../constants/actions';

export function handleNemoApiRequest(saga, action?: NemoRequestAction) {
  return function* callSaga(...params) {

    if (action) {
      yield put(fetchActions.httpRequestStarted(action));
    }

    try {
      const response: APIResponse = yield call(saga, ...params);

      if (action) {
        yield put(fetchActions.httpRequestSucceeded(action, response));
      }

      return response;

    } catch (e) {
      // handle unauthorized error from NEMO
      if (e.status === 401) {
        yield put(sessionActions.refreshClientToken());

        const { tokenUpdated, tokenUpdateFailed } = yield race({
          tokenUpdated: take(ACTIONS.REFRESH_CLIENT_TOKEN_SUCCEEDED),
          tokenUpdateFailed: take(ACTIONS.REFRESH_CLIENT_TOKEN_FAILED)
        });

        if (tokenUpdated) {
          // Retry call after token is updated
          return yield call(callSaga, ...params);
        }

        if (action) {
          yield put(fetchActions.httpRequestFailed(action, new Error('Refresh client token failed.')));
        }

        return null;
      }

      // handled other errors from nemo
      if (action) {
        yield put(fetchActions.httpRequestFailed(action, e));
      }

      return null;
    }
  };
}
