import * as React from 'react';
import { injectIntl } from 'react-intl';
import { FormLabel, Text, Tile } from 'sg-styleguide';
import './list-box.scss';

type DataType = { value: any, label: any };
type Props = {
  label?: any;
  data: DataType[]
};

const ListBox = ({ label, data }: Props) => (
  <div className="list-box-wrapper">
    {label && (
      <FormLabel>
        {label}
      </FormLabel>
    )}
    <Tile className="list-box" padding="small">
      {data.map((d) => (
        <Text align="left" color="dark">
          <strong>
            {d.label}:&nbsp;
          </strong>
          {d.value}
        </Text>
      ))}
    </Tile>
  </div>
);

export default ListBox;
