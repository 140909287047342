import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Dropdown, Flex, getDate, Grid, Link, Notice, Text, Tile, Title, TitleProps } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import { bytesToMB } from '../../../../core/utils/stats-format';
import SGTable from '../../../components/sg-table';
import PartialLoader from '../../../containers/partial-loader';
import PieChartStatistics from '../../../pages/resource-stats/technology/pie-charts';
import { formatBandwidthLabel } from '../../../pages/resource-stats/utils';
import './statistics.scss';

const REQUEST = 'requests';
const BANDWIDTH = 'bandwidth';

const DATA_TITLE_PROPS: Partial<TitleProps> = {
  level: '3',
  density: 'cozy'
};

const round = (num, decimalPoint = 2) => (Math.round(num * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint));

const getChartCountValue = (type: string, value: number) => {
  if (type === BANDWIDTH) {
    return formatBandwidthLabel(value);
  }

  return value;
};

class CloudflareStatistics extends React.Component<any, any> {
  readonly state = {
    dateRangeDropdownValue: this.getDateRangeDropdownOptions()[1].value
  };

  componentWillMount() {
    this.fetchSelectedZoneStatistic();
  }

  componentWillReceiveProps(nextProps) {
    const { selectedZone } = this.props;

    if (nextProps.selectedZone && nextProps.selectedZone !== selectedZone) {
      this.fetchSelectedZoneStatistic(nextProps);
    }
  }

  getDateRangeDropdownOptions() {
    const { intl, selectedZone } = this.props;
    const singleDayInSeconds = 24 * 60 * 60;

    let res = [
      {
        value: 30 * singleDayInSeconds,
        label: intl.formatMessage({ id: 'translate.page.cloudflare.last.days' }, { days: 30 })
      }, {
        value: 7 * singleDayInSeconds,
        label: intl.formatMessage({ id: 'translate.page.cloudflare.last.days' }, { days: 7 })
      }, {
        value: 2 * singleDayInSeconds,
        label: intl.formatMessage({ id: 'translate.page.cloudflare.last.hours' }, { hours: 48 })
      }, {
        value: singleDayInSeconds,
        label: intl.formatMessage({ id: 'translate.page.cloudflare.last.hours' }, { hours: 24 })
      }
    ];

    if (selectedZone && selectedZone.cf_plus) {
      res = [
        ...res,
        {
          value: 0.5 * singleDayInSeconds,
          label: intl.formatMessage({ id: 'translate.page.cloudflare.last.hours' }, { hours: 12 })
        }, {
          value: 0.25 * singleDayInSeconds,
          label: intl.formatMessage({ id: 'translate.page.cloudflare.last.hours' }, { hours: 6 })
        }
      ];
    }

    return res;
  }

  getStatistics() {
    const { cloudflareZoneStatistic, selectedZone } = this.props;
    return cloudflareZoneStatistic.find((statistic) => parseInt(statistic.id, 10) === parseInt(selectedZone.id, 10));
  }

  fetchSelectedZoneStatistic = (props = this.props) => {
    const { selectedZone } = props;

    if (selectedZone) {
      this.props.actions.fetchItem({
        ...API_RESOURCE.CLOUDFLARE_ZONE_STATISTICS,
        itemId: selectedZone.id,
        urlParams: {
          get_stats: 1,
          seconds: this.state.dateRangeDropdownValue
        }
      });
    }
  };

  renderChart = (type) => {
    const { intl, environment } = this.props;
    const { isTablet } = environment;
    const statistics = this.getStatistics();

    if (!statistics) {
      return null;
    }

    const cached = statistics.totals[type].cached;
    const uncached = statistics.totals[type].uncached;
    const total = statistics.totals[type].all;

    if (total === 0) {
      return null;
    }

    const uncachedCountValue = type === BANDWIDTH ? bytesToMB(uncached) : uncached;
    const cachedCountValue = type === BANDWIDTH ? bytesToMB(cached) : cached;

    const data = [{
      name: intl.formatMessage({ id: 'translate.page.cloudflare.statistics.uncached.label' }),
      legendLabel: `
        ${intl.formatMessage({ id: 'translate.page.cloudflare.statistics.uncached.label' })}
        <em>(${getChartCountValue(type, uncachedCountValue)})</em>
      `,
      value: uncached / total * 100,
      countValue: uncachedCountValue,
      label: round(uncached / total * 100, 1) + '%',
      checked: true
    }, {
      name: intl.formatMessage({ id: 'translate.page.cloudflare.statistics.cached.label' }),
      legendLabel: `
        ${intl.formatMessage({ id: 'translate.page.cloudflare.statistics.cached.label' })}
        <em>(${getChartCountValue(type, cachedCountValue)})</em>
      `,
      value: cached / total * 100,
      countValue: cachedCountValue,
      label: round(cached / total * 100, 1) + '%',
      checked: true
    }];

    return (
      <PieChartStatistics
        stats={data}
        type={type}
        environment={environment}
      />
    );
  };

  renderDateRange() {
    const { environment, intl } = this.props;
    const { dateRangeDropdownValue } = this.state;
    const statistics = this.getStatistics();

    if (!statistics) {
      return null;
    }

    return (
      <Flex className="cloudflare-statistics-header" gutter="large" margin="none">
        <Box
          sm="12"
          flex
          align={environment.isPhone ? null : 'flex-end'}
          direction={environment.isPhone ? 'column' : 'row'}
        >
          <Dropdown
            label={intl.formatMessage({ id: 'translate.page.cloudflare.date.range' })}
            size="small"
            inline
            options={this.getDateRangeDropdownOptions()}
            optionValue="value"
            optionLabel="label"
            selectedValue={dateRangeDropdownValue}
            onChange={(value) => this.setState({ dateRangeDropdownValue: value }, this.fetchSelectedZoneStatistic)}
            style={{ width: 'auto' }}
          />

          <Text style={{ padding: '5px' }}>
            {getDate(+new Date(statistics.totals.since))}
            &nbsp;-&nbsp;
            {getDate(+new Date(statistics.totals.until))}
          </Text>
        </Box>
      </Flex>
    );
  }

  renderPageViews() {
    const { environment, intl } = this.props;
    const statistics = this.getStatistics();
    const { isPhone } = environment;

    if (!statistics || !statistics.totals) {
      return null;
    }

    const searchEngine = statistics.totals.pageviews.search_engine;
    const crawlers = Object.keys(searchEngine)
      .map((name) => name ? searchEngine[name] : 0)
      .reduce((accumulator, currentValue) => (accumulator + currentValue), 0);

    return (
      <Grid padding={['none', isPhone ? 'medium' : 'large']}>
        {!isPhone && (
          <Tile padding={['none', 'medium']} className="pave-veiews-title" style={{ borderBottom: 'none' }}>
            <Title level="5" density="cozy">
              {intl.formatMessage({ id: 'translate.page.cloudflare.page.views.title' })}
            </Title>
          </Tile>
        )}

        <Grid sm="3" gap="none">
          {isPhone && (
            <Tile padding={isPhone ? 'medium' : 'large'} className="pave-veiews-title">
              <Title level="5" density="cozy">
                {intl.formatMessage({ id: 'translate.page.cloudflare.page.views.title' })}
              </Title>
            </Tile>
          )}

          <Tile padding={isPhone ? 'medium' : 'large'}>
            <Text weight="bold">{intl.formatMessage({ id: 'translate.page.cloudflare.regular.traffic' })}</Text>
            <Title {...DATA_TITLE_PROPS}>{statistics.totals.pageviews.all}</Title>
          </Tile>

          <Tile padding={isPhone ? 'medium' : 'large'}>
            <Text weight="bold">{intl.formatMessage({ id: 'translate.page.cloudflare.crawlers.bots' })}</Text>
            <Title {...DATA_TITLE_PROPS}>{crawlers}</Title>
          </Tile>

          <Tile padding={isPhone ? 'medium' : 'large'}>
            <Text weight="bold">{intl.formatMessage({ id: 'translate.page.cloudflare.threats' })}</Text>
            <Title {...DATA_TITLE_PROPS}>{statistics.totals.threats.all}</Title>
          </Tile>
        </Grid>
      </Grid>
    );
  }

  renderChartsSection() {
    const { environment, intl } = this.props;
    const statistics = this.getStatistics();
    const { isPhone } = environment;

    if (!statistics) {
      return null;
    }

    const pageviews = statistics.totals.pageviews.all;

    if (pageviews === 0) {
      return null;
    }

    const charts = [REQUEST, BANDWIDTH];

    return (
      <Grid padding={['none', isPhone ? 'medium' : 'large']} sm="2" gap="large">
        {charts.map(this.renderChart)}
      </Grid>
    );
  }

  render() {
    const { environment, cloudflareZoneStatistic, cloudflare, selectedZone } = this.props;
    const emptyTable = (
      <SGTable
        data={[]}
        shadow={false}
        resources={[{ resourceName: API_RESOURCE.CLOUDFLARE_ZONE_STATISTICS.resourceName, methods: ['GET'] }]}
      />
    );

    if (cloudflareZoneStatistic.length === 0 || !selectedZone) {
      return emptyTable;
    }

    const lastData = this.getStatistics();

    if (!lastData || parseInt(lastData.id, 10) !== parseInt(selectedZone.id, 10)) {
      return emptyTable;
    }

    return (
      <Grid gap={environment.isPhone ? 'medium' : 'xx-large'} style={{ position: 'relative' }}>
        <PartialLoader
          position="absolute"
          resources={[{ resourceName: API_RESOURCE.CLOUDFLARE_ZONE_STATISTICS.resourceName, methods: ['GET'] }]}
        >
          {this.renderDateRange()}
          {this.renderPageViews()}
          {this.renderChartsSection()}

          <Grid padding={['none', 'large', 'large', 'large']}>
            <Notice
              background="light"
              type="instruction"
              shadow={false}
              border={false}
            >
              <FormattedMessage
                id="translate.page.cloudflare.statistics.info.box"
                values={{
                  email: cloudflare[0].email,
                  cloudflareLink: (
                    <Link href="https://www.cloudflare.com/forgot-password" target="_blank">
                      <FormattedMessage id="translate.page.cloudflare.info.box.cloudflare.account" />
                    </Link>
                  ),
                  hereLink: (
                    <Link href="https://www.cloudflare.com/login" target="_blank">
                      <FormattedMessage id="translate.page.cloudflare.info.box.here" />
                    </Link>
                  )
                }}
              />
            </Notice>
          </Grid>
        </PartialLoader>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => ({
  environment: state.environment,
  cloudflare: state.pageItems.cloudflare || [],
  cloudflareZoneStatistic: state.pageItems.cloudflareZoneStatistic || []
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CloudflareStatistics));
