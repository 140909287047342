import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Placeholder } from 'sg-styleguide';
import { navigateToUA } from '../../../../core/actions/nemo-store';
import { PAGE_LOAD_REQUESTS } from '../../../../core/constants/common';
import PartialLoader from '../../../containers/partial-loader';

type Props = {
  intl?: Intl;
  navigateToUA?: Function;
};

const NoSitePlaceholder = ({ intl, navigateToUA }: Props) => {
  const isReseller = CONFIG.IS_RESELLER;

  return (
    <PartialLoader
      showOnlyLoader
      resources={PAGE_LOAD_REQUESTS}
    >
      <Placeholder
        title={intl.formatMessage({ id: 'translate.no.site.placeholder.title' })}
        message={intl.formatMessage({
          id: isReseller ?
            'translate.no.site.placeholder.reseller.message' :
            'translate.no.site.placeholder.message'
        })}
        iconColor="secondary"
        background="lighter"
        size="large"
      >
        {!isReseller && (
          <Button color="secondary" onClick={navigateToUA}>
            {intl.formatMessage({ id: 'translate.no.site.placeholder.redirect.button' })}
          </Button>
        )}
      </Placeholder>
    </PartialLoader>

  );
};

const mapStateToProps = (store) => ({
  i18n: store.i18n // rerender when the i18n is ready
});

const mapDispatchToProps = (dispatch) => ({
  navigateToUA: () => dispatch(navigateToUA({ command: 'redirect' }))
});

export default connect<{}, {}, Props>(mapStateToProps, mapDispatchToProps)(injectIntl(NoSitePlaceholder));
