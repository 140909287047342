import * as React from 'react';
import Tab from 'sg-styleguide/lib/components/tabs/tab';
import Tabs from 'sg-styleguide/lib/components/tabs/tabs';
import { getActiveTab } from '../../core/common/route-utils';

type TabDataById = {
  [tabId: string]: string;
};

type Props = {
  location: {
    pathname: string;
    search: string;
  };
  tabLabelsByTabId: TabDataById;
  routesByTabId: TabDataById;
  onNavigate: (path: string, tabId: string) => void;
};

export const PageTabs = ({
  location,
  tabLabelsByTabId,
  routesByTabId,
  onNavigate
}: Props) => {
  const tabs = Object.keys(tabLabelsByTabId);
  const activeTab = getActiveTab(location, routesByTabId);

  return (
    <Tabs border="light">
      {Object.keys(tabLabelsByTabId).map((tabId: string) =>
        <Tab
          key={tabId}
          active={activeTab === tabId}
          data-e2e={`page-tab-${tabId}`}
          onClick={() => onNavigate(`${routesByTabId[tabId]}${location.search}`, routesByTabId[tabId])}
        >
          {tabLabelsByTabId[tabId]}
        </Tab>
      )}
    </Tabs>
  );
};

export default PageTabs;
