import { browserHistory } from 'react-router';
import { put, takeLatest } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import * as Actions from '../constants/actions';
import { redirectToURL } from '../utils/redirect-to-url';

function* changeCurrentSite(action) {
  try {
    yield put(fetchActions.httpRequestStarted(action));
    const { siteId } = action.payload;

    const urlParams = browserHistory.getCurrentLocation().query;
    if (urlParams.siteId !== siteId) {
      redirectToURL({ urlParam: { siteId } });

      window.location.reload();
      return;
    }

    yield put(fetchActions.httpRequestSucceeded(action));
  } catch (e) {
    console.error(e);
    yield put(fetchActions.httpRequestFailed(action, e));
  }
}

function* changeSite(): any {
  yield takeLatest(Actions.CHANGE_CURRENT_SITE, changeCurrentSite);
}

export default changeSite;
