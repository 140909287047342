import {
  UpgradeEntries,
  ReportsResponse,
  NormalizedReports,
  ReportDataRaw,
  MalwareReportEntry,
  BlacklistReportEntry
} from '../definitions/sg-sitescanner';

const URL_MATCHER =
  /: ((https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/;

const DETAILS_MATCHER = /(\*?)(.+)(Details:) ([\s\S]+)/;

export const getPrimaryDomainSiteScannerUpgradeId = (
  entries: UpgradeEntries[] = [],
  primaryDomainName: string
): string => {
  const entry = entries.find(({ domain }) => domain === primaryDomainName);
  return entry && entry.upgrade;
};

export const getEmailReportsStatus = (
  entries: UpgradeEntries[] = [],
  primaryDomainName: string
): boolean => {
  const entry = entries.find(({ domain }) => domain === primaryDomainName);
  return Boolean(entry && entry.email_reporting);
};

export const transformDate = (date: string): number => parseInt(date.split('.')[0], 10);

export const normalizeMalware = (malwareReport): MalwareReportEntry[] =>
  malwareReport ? malwareReport.map(([urlData, threatAndDetails]) => {
    const urlMatched = urlData && urlData.match(URL_MATCHER);
    const threatDataMatched = threatAndDetails && threatAndDetails.match(DETAILS_MATCHER);

    return {
      url: urlMatched && urlMatched[1],
      threat: threatDataMatched && threatDataMatched[2],
      details: threatDataMatched ? `MATCHED: ${threatDataMatched[4]}` : threatAndDetails
    };
  }) : [];

export const normalizeBlacklist = (blacklistReport): BlacklistReportEntry[] =>
  blacklistReport ? blacklistReport.map(([reportText, url]) => ({
    url,
    details: reportText
  })) : [];

export const normalizeReportsData = (response: ReportsResponse): NormalizedReports => {
  let output: NormalizedReports = {};

  if (!response.data || !response.data[0]) {
    return output;
  }

  const { data } = response;

  output = data.reduce((outputData, { malware_report, blacklist_report, date, status }) => {
    return {
      ...outputData,
      [`report-${date}`]: {
        date: transformDate(date),
        malware: status === 'timeout' ? [] : normalizeMalware(malware_report),
        blacklist: status === 'timeout' ? [] : normalizeBlacklist(blacklist_report),
        status
      }
    };
  }, {});

  return output;
};
