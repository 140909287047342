import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Dialog, Flex, Grid, Icon, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import Checkbox from '../../../components/form-checkbox';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-spanel-forms/index';

type Props = {
  handleSubmit: () => void;
};

const LoginForm = ({ handleSubmit }: Props) => (
  <form onSubmit={handleSubmit}>
    <Grid gap="large">
      <Grid>
        <Text align="center" color="dark">
          <Icon name="circle-plus" size="80" />
        </Text>
        <Text>Please enter your email address and password</Text>
      </Grid>

      <Field
        name="username"
        type="text"
        label="Username"
        size="x-large"
        component={FormInput}
      />

      <Field
        name="password"
        type="password"
        label="Password"
        size="x-large"
        component={FormInput}
      />

      <Field
        name="fetch_sites"
        type="hidden"
        placeholder="Password"
        size="x-large"
        style={{ display: 'none' }}
        component={FormInput}
      />

      <Flex margin="none" gutter="none" align="flex-end" direction="column">
        <Button
          id="login-submit"
          color="secondary"
          size="x-large"
          onClick={handleSubmit}
          style={{ alignSelf: 'stretch' }}
        >
          Login
        </Button>

        <Field name="remember_me" type="checkbox" component={Checkbox}>Remember Me</Field>
      </Flex>
    </Grid>
  </form>
);

export default withSpanelForm(
  reduxForm({
    form: REDUX_FORM.LOGIN_DIALOG,
    initialValues: {
      username: 'nemo_api@siteground.com',
      password: '',
      fetch_sites: 1,
      remember_me: false
    }
  })(LoginForm)
);
