import * as React from 'react';
import { Checkbox } from 'sg-styleguide';
import './form-checkbox.scss';

const FormCheckbox = ({ input, meta, ...props }) => {
  const { value, ...inputProps } = input;
  const touchedOrActive = (meta.touched || meta.active);
  const touchedOrHasValue = (meta.touched || Boolean(input.value));
  const isChecked = value;
  const hasError = !meta.valid && touchedOrActive && touchedOrHasValue;

  return (
    <Checkbox
      data-e2e={`form-checkbox-${input.name}`}
      checked={isChecked}
      {...inputProps}
      {...props}
      className={hasError ? 'form-checkbox--error' : ''}
    />
  );
};

export default FormCheckbox;
