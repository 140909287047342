import { handleActions } from 'redux-actions';
import * as ACTIONS from '../constants/actions';

const initialState: any = {
  loading: true
};

export default handleActions<any, any>({
  [ACTIONS.FETCH_SITEMETAAPI_SUCCEEDED]: (state, { payload }) => {
    return payload.siteMetaApi.data;
  },
  [ACTIONS.LOGOUT]: () => initialState
}, initialState);
