import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { Button, Column, Label, Link, Notice, Text, Title } from 'sg-styleguide';
import { API_RESOURCE } from '../../../core/constants/api';
import { PAGE_NOTIFICATION_SUBTITLE_PROPS, REDUX_FORM } from '../../../core/constants/common';
import MobileBreakElement from '../../components/break-element';
import CreateBox from '../../components/create-box';
import FormCheckbox from '../../components/form-checkbox';
import FormDropdown from '../../components/form-dropdown';
import FormFieldWrapper from '../../components/form-field-wrapper/form-field-wrapper';
import InformationIcon from '../../components/information-icon/information-icon';
import { SGForm, SGSubmitButton } from '../../containers/sg-form';
import { withSpanelForm } from '../../containers/sg-spanel-forms';
import VCS from '../../containers/visibility-control-service';
import cloudflare from './cloudflare';

import { DOMAIN_SELECT_NAME, ZONE_SETTING_ID } from './constants';
// TODO: delete

const formName = REDUX_FORM.CREATE_CLOUDFLARE_ZONE;

type Props = {
  domainPickerOptions: any[];
  nemoStoreRetrieve: {
    page?: string;
    domain?: string;
  };
  cloudflareZonePending: any[];
  cloudflareZoneSettings: any[];
  selectedZone: {
    id: string;
    cf_plus: number;
    name: string;
    zone_check_data: {
      sub_domains: {
        www: {
          cname: string;
          redirect_ok: string;
        }
      };
      cf_resolve_to: string;
    }
  };
  goToUA: Function;
  defaultSelectedDomainName: string;
  formValues: Function;
  change: Function;
  getFormValues: Function;
  onDeleteCloudflareZone: Function;
  onChangeDomain: (domain: string) => void;
  cloudflareAccountNotification: any;
  intl: Intl;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
  routing: any;
  session: any;
};

class CreateZone extends React.Component<Props, any> {
  componentWillMount() {
    const { change, nemoStoreRetrieve, onChangeDomain } = this.props;
    if (nemoStoreRetrieve && nemoStoreRetrieve.page === 'cloudflare' && nemoStoreRetrieve.domain) {
      change(DOMAIN_SELECT_NAME, nemoStoreRetrieve.domain);
      onChangeDomain(nemoStoreRetrieve.domain);
    }
  }

  isZoneDataFull() {
    const { selectedZone } = this.props;

    if (!selectedZone || !selectedZone.zone_check_data.sub_domains || !selectedZone.zone_check_data.sub_domains.www) {
      return false;
    }

    return true;
  }

  isCNAMEConfigured() {
    const { selectedZone } = this.props;
    return this.isZoneDataFull() && Boolean(selectedZone.zone_check_data.sub_domains.www.cname);
  }

  isCFResolveConfigured() {
    const { selectedZone } = this.props;
    return this.isZoneDataFull() && Boolean(selectedZone.zone_check_data.cf_resolve_to);
  }

  isWWWConfigured() {
    return this.isCNAMEConfigured() && this.isCFResolveConfigured();
  }

  isWWWRedirectConfigured() {
    const { selectedZone } = this.props;
    return this.isZoneDataFull() && Boolean(selectedZone.zone_check_data.sub_domains.www.redirect_ok);
  }

  isZonePendingInAvalon() {
    const { cloudflareZonePending, getFormValues } = this.props;
    const formValues = getFormValues(formName);

    return Boolean(cloudflareZonePending.find((zone) => zone.name === formValues[DOMAIN_SELECT_NAME]));
  }

  isZonePendingBySettings() {
    const { selectedZone } = this.props;

    return selectedZone && (!this.isWWWRedirectConfigured() || !this.isWWWConfigured());
  }

  isZoneActive() {
    const { cloudflareZoneSettings, selectedZone } = this.props;
    const devModeSetting = cloudflareZoneSettings.find((setting) => setting.id === ZONE_SETTING_ID.DEVELOPMENT_MODE);

    return selectedZone && devModeSetting && selectedZone.id === devModeSetting.zone_id;
  }

  isSelectedDomainSubdomain() {
    const { domainPickerOptions, formValues } = this.props;
    const values = formValues(formName);

    if (!values[DOMAIN_SELECT_NAME]) {
      return null;
    }

    const selectedDomain = domainPickerOptions.find(({ name }) => name === values[DOMAIN_SELECT_NAME]);
    const isSubdomain = selectedDomain && !selectedDomain.in_tld;

    return isSubdomain;
  }

  renderWWWCheckNotice() {
    const { selectedZone, intl } = this.props;

    if (!selectedZone || this.isWWWConfigured()) {
      return null;
    }

    return (
      <Column xsSpan="12" smSpan="12">
        <Notice
          title={intl.formatMessage({ id: 'translate.page.cloudflare.www.check.notice.title' })}
          background="light"
          type="warning"
          shadow={false}
        >
          <Text color="dark">
            {intl.formatMessage({ id: 'translate.page.cloudflare.www.check.notice.text' })}
          </Text>

          <br />

          {!this.isCNAMEConfigured() && (
            <Text weight="bold">
              {intl.formatMessage(
                { id: 'translate.page.cloudflare.www.check.notice.cname.text' },
                { domain: selectedZone.name }
              )}
            </Text>
          )}

          {!this.isCFResolveConfigured() && (
            <Text weight="bold">
              {intl.formatMessage(
                { id: 'translate.page.cloudflare.www.check.notice.resolve.text' },
                { zoneName: selectedZone.name }
              )}
            </Text>
          )}
        </Notice>
      </Column>
    );
  }

  renderWWWRedirectCheckNotice() {
    const { selectedZone, intl } = this.props;

    if (!selectedZone || this.isWWWRedirectConfigured()) {
      return null;
    }

    return (
      <Column xsSpan="12" smSpan="12">
        <Notice
          title={intl.formatMessage({ id: 'translate.page.cloudflare.redirect.check.notice.title' })}
          background="light"
          type="warning"
          shadow={false}
        >
          <Text color="dark">
            <FormattedMessage
              id="translate.page.cloudflare.redirect.check.notice.text"
              values={{
                link: (
                  <Link
                    href="https://www.siteground.com/tutorials/cloudflare/redirect-www/#How_to_reconfigure_manually"
                    target="_blank">
                    <FormattedMessage id="translate.page.cloudflare.redirect.check.notice.link.text" />
                  </Link>
                )
              }}
            />
          </Text>
        </Notice>
      </Column>
    );
  }

  renderFeatureUnavailableForDomain() {
    const { intl } = this.props;

    return this.isSelectedDomainSubdomain() ? (
      <Column xsSpan="12" smSpan="12">
        <Notice
          title={intl.formatMessage({ id: 'translate.page.cloudflare.feature-not-available-for-domain.notice.title' })}
          background="light"
          type="warning"
          shadow={false}
        >
          <Text color="dark">
            <FormattedMessage id="translate.page.cloudflare.feature-not-available-for-domain.notice.text" />
          </Text>
        </Notice>
      </Column>
    ) : null;
  }

  renderZoneStatus() {
    const { intl, cloudflareZoneSettings } = this.props;
    const devModeSetting = cloudflareZoneSettings.find((setting) => setting.id === ZONE_SETTING_ID.DEVELOPMENT_MODE);
    let labelType = 'default-outlined';
    let label = 'translate.generic.inactive';

    if (this.isZonePendingInAvalon()) {
      labelType = 'warning-outlined';
      label = 'translate.page.cloudflare.status.pending.activation';
    }

    if (this.isZoneActive()) {
      if (devModeSetting.value === 'off') {
        labelType = 'active-outlined';
        label = 'translate.generic.active';
      }

      if (devModeSetting.time_remaining) {
        labelType = 'default-outlined';
        label = 'translate.generic.paused';
      }
    }

    if (this.isZonePendingBySettings()) {
      labelType = 'warning-outlined';
      label = 'translate.generic.warning';
    }

    return (
      <FormFieldWrapper label={intl.formatMessage({ id: 'translate.generic.status' })}>
        <Label type={labelType}>{intl.formatMessage({ id: label })}</Label>
      </FormFieldWrapper>
    );
  }

  renderZonePlan() {
    const { intl, selectedZone } = this.props;
    let label = 'translate.page.cloudflare.label.plan.none';

    if (!this.isZoneActive()) {
      return null;
    }

    if (selectedZone) {
      label = selectedZone.cf_plus === 1 ?
        'translate.page.cloudflare.label.plan.paid' :
        'translate.page.cloudflare.label.plan.free';
    }

    return (
      <FormFieldWrapper label={intl.formatMessage({ id: 'translate.generic.plan' })}>
        <Text>
          {intl.formatMessage({ id: label })}
        </Text>
      </FormFieldWrapper>
    );
  }

  renderZoneActionButtons() {
    const { intl, selectedZone, goToUA, onDeleteCloudflareZone } = this.props;
    const shouldShowCompleteActivationLabel = this.isZonePendingInAvalon();

    if (selectedZone) {
      return (
        <React.Fragment>
          {selectedZone && (
            <Button
              color="primary"
              type="outlined"
              action="button"
              data-e2e="create-box-deactivate"
              onClick={onDeleteCloudflareZone}
            >
              {intl.formatMessage({ id: 'translate.generic.deactivate' })}
            </Button>
          )}

          <MobileBreakElement />

          {!selectedZone.cf_plus && (
            <Button
              color="primary"
              action="button"
              data-e2e="create-box-submit-upgrade-to-plus"
              onClick={goToUA}
            >
              {intl.formatMessage({ id: 'translate.page.cloudflare.upgrade.to.plus' })}
            </Button>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SGSubmitButton
          type="outlined"
          formName={formName}
          e2eAttr="create-box-activate"
          label={intl.formatMessage({
            id: shouldShowCompleteActivationLabel ?
              'translate.page.cloudflare.complete.activation' :
              'translate.page.cloudflare.ativate.free'
          })}
        />

        <MobileBreakElement />

        {!shouldShowCompleteActivationLabel && (
          <Button
            color="primary"
            action="button"
            data-e2e="create-box-submit-upgrade-to-plus"
            onClick={goToUA}
          >
            {intl.formatMessage({ id: 'translate.page.cloudflare.ativate.plus' })}
          </Button>
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      intl,
      selectedZone,
      onChangeDomain,
      validationUtils,
      sPanelFormSubmit
    } = this.props;
    const { required } = validationUtils;

    return (
      <VCS resourceName={API_RESOURCE.CLOUDFLARE_ZONE.resourceName} hasMethod="POST">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.translate.cloudflare.create.title' })}
          resourceName={API_RESOURCE.CLOUDFLARE_ZONE.resourceName}
          formName={formName}
          // renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          //   if (notification.state === 'error') {
          //     return renderDefaultNotificationTemplate();
          //   }
          //
          //   return (
          //     <React.Fragment>
          //       <Title key="text" {...PAGE_NOTIFICATION_SUBTITLE_PROPS}>
          //         {intl.formatMessage({ id: 'translate.generic.whats.next' })}
          //       </Title>
          //
          //       <Button
          //         key="button"
          //         type="outlined"
          //         color="secondary"
          //         data-e2e="protect-new"
          //         onClick={removeNotification}
          //       >
          //         {intl.formatMessage({ id: 'translate.page.cloudflare.select.plan' })}
          //       </Button>
          //     </React.Fragment>
          //   );
          // }}
          resources={[
            { resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['DELETE'] },
            { resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['POST'] }
          ]}
        >
          <SGForm onSubmit={sPanelFormSubmit(formName)} gridProps={{ xs: '12' }}>
            {this.renderWWWCheckNotice()}
            {this.renderWWWRedirectCheckNotice()}
            {this.renderFeatureUnavailableForDomain()}

            <Column xsSpan="12" smSpan="6">
              <Field
                name={DOMAIN_SELECT_NAME}
                label={intl.formatMessage({ id: 'translate.generic.domain' })}
                options={this.props.domainPickerOptions}
                optionValue="name"
                optionLabel="name"
                onChange={(ev, value) => onChangeDomain(value)}
                validate={[required]}
                component={FormDropdown}
                disableAutoSelect
              />
            </Column>

            {
              !this.isSelectedDomainSubdomain() && (
                <React.Fragment>
                  <Column xsSpan="6" smSpan="3">
                    {this.renderZoneStatus()}
                  </Column>

                  <Column xsSpan="6" smSpan="3">
                    {this.renderZonePlan()}
                  </Column>

                  {!selectedZone && (
                    <Column xsSpan="12" smSpan="12">
                      <Field
                        name="force_www"
                        decoration="dotted"
                        expand
                        component={FormCheckbox}
                      >
                        {intl.formatMessage({ id: 'translate.page.cloudflare.redirect.to.www.label' })}
                        &nbsp;
                        <InformationIcon
                          tooltip={intl.formatMessage({ id: 'translate.page.cloudflare.redirect.to.www.tooltip' })}
                          tooltipSize="medium"
                        />
                      </Field>
                    </Column>
                  )}

                  <Column xsSpan="12" smSpan="12">
                    {this.renderZoneActionButtons()}
                  </Column>
                </React.Fragment>
              )
            }
          </SGForm>
        </CreateBox>
      </VCS>
    );
  }

  componentDidUpdate(prevProps) {
    const { formValues, defaultSelectedDomainName, domainPickerOptions, change } = this.props;
    const values = formValues(formName);

    /* We  keep the state of last selected domain in the page,
      If the form is re-mounted, the value will be restored with the last selected

      TEMP. solution, until we have a proper domain select component
    */

    if (!values[DOMAIN_SELECT_NAME] && defaultSelectedDomainName) {
      const selectedDomain = domainPickerOptions.find(({ name }) => name === defaultSelectedDomainName);
      if (selectedDomain && selectedDomain.name) {
        change(DOMAIN_SELECT_NAME, selectedDomain.name);
      }
    }
  }
}

const createFormRedux = reduxForm({
  form: formName,
  initialValues: {
    force_www: true,
    _metaFields: {
      ...API_RESOURCE.CLOUDFLARE_ZONE,
      formName,
      disableAutoReset: true
    }
  }
})(CreateZone);

const mapStateToProps = (state) => ({
  nemoStoreRetrieve: state.nemoStore.retrieve,
  cloudflareZonePending: state.pageItems.cloudflareZonePending || [],
  cloudflareZoneSettings: state.pageItems.cloudflareZoneSettings || [],
  session: state.session,
  routing: state.routing
});

export default withSpanelForm(connect(mapStateToProps)(injectIntl(createFormRedux)) as any);
