import * as FormActions from '../constants/form-actions';

export const sPanelFormSubmit = (formName: string) => ({
  type: FormActions.SPANEL_FORM_SUBMIT,
  formName
});

export const sPanelFocusField = (fieldName: string, formName: string) => ({
  type: FormActions.SPANEL_INPUT_FOCUS,
  fieldName,
  formName
});
