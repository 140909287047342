import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const domainName = (sites, siteId) => {
  return sites
    .filter((item) => item.id === siteId)
    .map((item) => item.domain)
    .pop();
};

export const initialState: any = {
  items: [],
  loading: true,
  currentDomainName: null
};

export default handleActions<any, any>({
  [Actions.STORE_SITES]: (state, { payload }) => {
    return {
      items: payload.sites,
      loading: false,
      currentDomainName: domainName(payload.sites, payload.siteId)
    };
  },
  [Actions.LOGOUT]: () => initialState
}, initialState);
