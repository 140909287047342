import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Column, copyToClipboard, Flex, Grid, Text, Textarea } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import * as notificationActions from '../../../../core/actions/notifications';
import { API_RESOURCE } from '../../../../core/constants/api';
import { RootState } from '../../../../core/reducers';

const fetchItemParams = {
  ...API_RESOURCE.SSH_KEY
};

class ReadPrivateSSH extends React.Component<any, any> {
  textAreaRef = null;

  constructor(props) {
    super(props);

    this.hasNeededItem = this.hasNeededItem.bind(this);
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
  }

  hasNeededItem() {
    const currentElementId = this.props.currentElement.id;
    const singleItemsResourceArray = this.props.items[fetchItemParams.resourceName];

    if (singleItemsResourceArray === undefined) {
      return false;
    }

    if (singleItemsResourceArray.find((item) => item.id === currentElementId) === undefined) {
      return false;
    }
    return true;
  }

  getItemFromSingleItemsResourceArray() {
    const currentElementId = this.props.currentElement.id;
    const singleItemsResourceArray = this.props.items[fetchItemParams.resourceName];

    return singleItemsResourceArray.find((item) => item.id === currentElementId);
  }

  componentWillMount() {
    const isIdCashed = this.hasNeededItem();
    const { id, key_priv_exist } = this.props.currentElement;

    if (!isIdCashed && Boolean(key_priv_exist)) {
      const fetchItemParamsClone = Object.assign(fetchItemParams, { itemId: id });
      this.props.actions.fetchItem(fetchItemParamsClone);
    }
  }

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.page.ssh.copy.private.key.success'
        },
        error: {
          intlKey: 'translate.page.ssh.copy.private.key.error'
        }
      });
    });
  }

  render() {
    if (!this.hasNeededItem()) {
      return null;
    }

    const { intl } = this.props;
    const privateKey = this.getItemFromSingleItemsResourceArray().key_priv;

    return (
      <Grid sm="12">
        <Column smSpan="12">
            <Textarea
              rows="14"
              readOnly
              disabled
              value={privateKey}
              onRefsReady={
                (textarea) => {
                  this.textAreaRef = textarea;
                }
              }
              label={
                <Flex align="center" justify="space-between" margin="none" gutter="none">
                  {intl.formatMessage({ id: 'translate.page.ssh.view.private.key' })}
                  <Text color="secondary" onClick={this.handleCopyToClipboard}>
                    {this.props.intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                  </Text>
                </Flex>
              }
            />
        </Column>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch),
  createNotification: (payload) => dispatch(notificationActions.createNotification(payload))
});

const mapStateToProps = (state: RootState) => ({
  items: state.pageItems
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ReadPrivateSSH)) as any;
