export const getDbEntityLabelByName = (entityLabel: string = '', entityResourceData = []) => {
  const dbData = entityResourceData.find(({ name }) => name === entityLabel);
  return dbData ? dbData.label : entityLabel;
};

export const generateEntitiesCountLabel = (
  entities: any[],
  singleLabel: string,
  multiplesLabel: string
) => {
  switch (true) {
    case entities.length === 0:
      return '';
    case entities.length === 1:
      return `${entities.length} ${singleLabel}`;
    default:
      return `${entities.length} ${multiplesLabel}`;
  }
};
