import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Context,
  copyToClipboard,
  Flex,
  Grid,
  Label,
  Link,
  Notice,
  Switch,
  Text,
  Textarea
} from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import { createNotification } from '../../../../core/actions/notifications';
import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import PartialLoader from '../../../containers/partial-loader';

type Props = {
  actions: {
    fetchItems: FetchItems;
    updateItem: UpdateItem;
  };
  createNotification: Function;
  environment: { isPhone: boolean };
  dkim: [{
    name: string;
    sg_dns: number;
    dkim_entry?: string;
    dkim?: number;
  }];
  intl: Intl;
  selectedDomain: {
    id: string;
    link: string;
    name: string;
    domain_type: string;
  };
};

const PaddingBox = ({ children }) => (
  <Context.Consumer>
    {({ device }) => (
      <Grid padding={device.isPhone ? 'medium' : 'large'}>
        {children}
      </Grid>
    )}
  </Context.Consumer>
);

class DKIM extends React.Component<Props, any> {
  componentDidMount() {
    if (this.props.selectedDomain) {
      this.fetchDKIM();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDomain.id !== this.props.selectedDomain.id) {
      this.fetchDKIM();
    }
  }

  fetchDKIM() {
    this.props.actions.fetchItems({
      ...API_RESOURCE.DKIM,
      urlParams: {
        id: this.props.selectedDomain.id
      }
    });
  }

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  toggleActivation = (cell, entity) => {
    const { intl } = this.props;

    const apiToUse = this.props.selectedDomain.domain_type === 'alias' ?
      API_RESOURCE.DOMAIN_ALIAS :
      API_RESOURCE.DOMAIN;

    this.props.actions.updateItem({
      id: cell,
      settings: { dkim: entity.dkim ? 0 : 1 },
      _metaFields: {
        ...apiToUse
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: entity.dkim ?
              'translate.page.email.auth.dkim.success.deactivate' :
              'translate.page.email.auth.dkim.success.activate'
          },
          error: {
            intlKey: entity.dkim ?
              'translate.page.email.auth.dkim.fail.deactivate' :
              'translate.page.email.auth.dkim.fail.activate'
          }
        }
      }
    }, () => this.fetchDKIM());
  };

  renderRecordsNotice() {
    const { intl, dkim, selectedDomain } = this.props;

    if (!dkim[0]) {
      return null;
    }

    const { sg_dns, dkim_entry } = dkim[0];

    if (sg_dns === 0 && dkim[0].dkim === 1) {
      return (
        <PaddingBox>
          <Notice
            type="warning"
            title={intl.formatMessage({ id: 'translate.page.email.auth.dkim.records.notice.title' })}
          >
            <Grid>
              <FormattedMessage
                id={'translate.page.email.auth.dkim.records.notice.info'}
                values={{
                  domain: (
                    <Link href={selectedDomain.link} target="_blank">
                      {selectedDomain.name}
                    </Link>
                  )
                }}
              />

              {dkim_entry && (
                <Textarea
                  readOnly
                  label={
                    <Flex align="center" justify="space-between" margin="none" gutter="none">
                      {intl.formatMessage({ id: 'translate.page.email.auth.dkim.recommended.record' })}

                      <Text color="secondary" onClick={() => this.handleCopyToClipboard(dkim_entry)}>
                        {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                      </Text>
                    </Flex>
                  }
                  value={dkim_entry}
                />
              )}
            </Grid>
          </Notice>
        </PaddingBox>
      );
    }

    return null;
  }

  render() {
    const { intl, dkim, environment } = this.props;
    const isPhone = environment.isPhone;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.domain' }),
      accessor: 'name'
    }, {
      header: intl.formatMessage({ id: 'translate.generic.status' }),
      accessor: 'sg_dns',
      render: (cell, entity) => (
        <Label
          size="medium"
          type={entity.dkim ? 'active-link' : 'inactive-link'}
          padding={['inherit', 'inherit', 'inherit', 'none']}
        >
          {intl.formatMessage({ id: entity.dkim ? 'translate.generic.active' : 'translate.generic.inactive' })}
        </Label>
      )
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'name',
      render: (cell, entity) => (
        isPhone ? (
          <Switch
            checked={Boolean(entity.dkim)}
            onChange={() => this.toggleActivation(cell, entity)}
          />
        ) : (
          <Button
            color="secondary"
            type="outlined"
            onClick={() => this.toggleActivation(cell, entity)}
          >
            {intl.formatMessage({ id: entity.dkim ? 'translate.generic.deactivate' : 'translate.generic.activate' })}
          </Button>
        )
      )
    }];

    return (
      <div style={{ position: 'relative' }}>
        <PartialLoader
          position="absolute"
          resources={[
            { resourceName: API_RESOURCE.DKIM.resourceName, methods: ['GET'] },
            { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET', 'PUT'] },
            { resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName, methods: ['PUT'] }
          ]}
        >
          <PaddingBox>
            <Text>
              {intl.formatMessage({ id: 'translate.page.email.auth.dkim.info' })}
            </Text>
          </PaddingBox>

          <Grid padding={this.props.environment.isPhone ? ['none', 'medium'] : 'none'}>
            <SGTable
              columns={columns}
              data={dkim}
              shadow={false}
              mobileLayout="card-flat"
            />
          </Grid>

          {this.renderRecordsNotice()}
        </PartialLoader>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  createNotification: (payload) => dispatch(createNotification(payload))
});

const mapStateToProps = (state) => ({
  environment: state.environment,
  dkim: state.pageItems.dkim || []
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(DKIM)) as any;
