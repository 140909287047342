import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as sgDialogActions from '../../../../../core/actions/sg-dialog';
import { downloadFile } from '../../../../../core/api/download-file';

import { siteApi } from '../../../../../core/api/site';
import * as BaseConstantsForActions from '../../../../../core/constants/actions';
import { DIALOGS } from '../../../../../core/constants/common';
import handleAvalonApiRequest from '../../../../../core/sagas/handle-avalon-api-request';
import * as FileManagerConstants from '../constants/actions';

const Actions = { ...BaseConstantsForActions, ...FileManagerConstants };

interface FetchArgs {
  payload: {
    urlParams: {
      filename: string;
    };
    entity: any;
  };
}

function* fetchDirSaga(action: FetchArgs) {
  const state = yield select();
  const { urlParams } = action.payload;

  const response = yield call(siteApi({
    endpoint: '/dir',
    method: 'GET',
    state,
    urlParams
  }));

  yield put({ type: Actions.FILE_MANAGER_FETCH_DIR_SUCCEEDED, items: response.data, urlParams });

  return response;
}

// TODO types
function* fetchDirsSaga(action) {
  const state = yield select();
  const { entries } = action.payload;

  const response = yield call(siteApi({
    endpoint: '/dir-list',
    method: 'POST',
    state,
    body: {
      entries
    }
  }));

  yield put({ type: Actions.FILE_MANAGER_FETCH_DIRS_SUCCEEDED, data: response.data });

  return response;
}

function* fetchFileSaga(action: FetchArgs) {
  const state = yield select();
  const { urlParams, entity } = action.payload;

  const response = yield call(siteApi({
    endpoint: '/file',
    method: 'GET',
    state,
    urlParams
  }));

  yield put({
    type: Actions.FILE_MANAGER_FETCH_FILE_SUCCEEDED,
    payload: {
      ...entity,
      _meta: {
        ...entity._meta,
        content: response,
        updatedContent: response,
        isActiveInCodeEditor: true
      }
    },
    urlParams
  });

  return response;
}

function* downloadFileSaga(action: FetchArgs) {
  const state = yield select();
  const { urlParams } = action.payload;

  const isNewTabOpened = downloadFile('/file', state, urlParams);

  if (!isNewTabOpened) {
    yield put(sgDialogActions.openSGDialog(DIALOGS.NEW_TAB_DIALOG));
  }
}

function* fetchDir(): any {
  yield takeEvery(Actions.FILE_MANAGER_FETCH_DIR_REQUESTED, handleAvalonApiRequest(fetchDirSaga));
  yield takeEvery(Actions.FILE_MANAGER_FETCH_DIRS_REQUESTED, handleAvalonApiRequest(fetchDirsSaga));
  yield takeEvery(Actions.FILE_MANAGER_FETCH_FILE_REQUESTED, handleAvalonApiRequest(fetchFileSaga));
  yield takeEvery(Actions.FILE_MANAGER_DOWNLOAD_FILE, handleAvalonApiRequest(downloadFileSaga));
}

export default fetchDir;
