import 'whatwg-fetch';

type fetchI18N = () => Promise<Response>;

export function fetchI18N(fileName: string): fetchI18N {
  return () => {
    const url = `${CONFIG.TRANSLATIONS_HOST}/${fileName}.json`;

    return fetch(url)
      .catch((error) => {
        throw Error('An error occurred. Please try again later');
      })
      .then((response) => {
        if (!response.ok && response.status !== 422) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => json);
  };
}
