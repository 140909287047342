import * as React from 'react';
import { connect } from 'react-redux';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import { Link } from 'sg-styleguide';
import DateWithTime from '../../../components/date-with-time';
import { navigateToFMPath } from '../../../containers/file-manager/core/actions/file-manager';

import ROUTES from '../../../../core/constants/routes';
import { API_RESOURCE } from '../../../../core/constants/api';
import { RESTORE_TYPE, DEST_DIR } from '../constants';

interface BackupRestoreHistoryProps {
  intl: any;
  items: any;
  navigateToFMPath: Function;
  router: {
    push: Function;
    location: {
      search: string;
    };
  };
}

const restoreItemsMap = {
  full: 'translate.page.backup.restore.history.item.full',
  files: 'translate.page.backup.restore.history.item.files',
  emails: 'translate.page.backup.restore.history.item.emails',
  dbs: 'translate.page.backup.restore.history.item.dbs'
};

const BackupRestoreHistoryPage = ({
  items,
  intl,
  navigateToFMPath,
  router,
  ...otherProps
}: BackupRestoreHistoryProps) => {
  const restoreHistory = items[API_RESOURCE.BACKUP_RESTORE.resourceName] || [];
  const columns = [
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restore.type' }),
      accessor: 'restore_type',
      render: (type) => intl.formatMessage({ id: restoreItemsMap[type] })
    }, {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.backup.type' }),
      accessor: 'instant_backup',
      render: (backupType) => (
        backupType === 1 ? intl.formatMessage(
          { id: 'translate.generic.manual' }) : intl.formatMessage({ id: 'translate.generic.system' }
          )
      )
    }, {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.to' }),
      accessor: 'restore_type',
      render: (cell, entity) => {
        if (cell === RESTORE_TYPE.FILE && entity.restore_data.files.dst_dir !== '/') {
          const folderPath = entity.restore_data.files.dst_dir.replace(DEST_DIR, '');

          return (
            <Link onClick={() => navigateToFMPath(folderPath)}>
              {entity.restore_data.files.dst_dir}
            </Link>
          );
        }

        return intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.to.default.label' });
      }
    }, {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.start.time' }),
      accessor: 'ts_start',
      render: (date, entity) => <DateWithTime date={date} />
    }, {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restore.end.date' }),
      accessor: 'backup_id',
      render: (date, entity) => <DateWithTime date={parseInt(date, 10)} />,
      style: { textAlign: 'left' }
    }
  ];

  return (
    <SGTable
      title={intl.formatMessage({ id: 'translate.page.backup.restore.history.list.title' })}
      data={restoreHistory}
      resources={[{ resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName, methods: ['GET'] }]}
      columns={columns}
      noDataMessage="translate.page.backup.restore.history.no.data"
    />
  );
};

export default indexWithCRUD(undefined, (dispatch) => ({
  navigateToFMPath: (paths) => dispatch(navigateToFMPath(paths))
}))(
  BackupRestoreHistoryPage,
  API_RESOURCE.BACKUP_RESTORE
);
