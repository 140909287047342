import { RootState } from '../reducers';

export const getCurrentPathname = ({ routing }: Partial<RootState>) => (
  routing &&
  routing.locationBeforeTransitions &&
  routing.locationBeforeTransitions.pathname
);

export const getLocationSearch = ({ routing }: Partial<RootState>) => (
  routing &&
  routing.locationBeforeTransitions &&
  routing.locationBeforeTransitions.search
);
