import * as React from 'react';
import { BarChart, Box, ChartContainer, ChartLegend, ChartTooltip, Flex, Grid } from 'sg-styleguide';
import { Countries, StatsType } from '../../../../core/definitions/stats';
import PartialLoader from '../../../containers/partial-loader';
import SGTable from '../../../components/sg-table';
import customRequestTypes from '../../../../core/constants/custom-request-types';

type Props = {
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => any[];
  getCountryNameByCode: (countryCode: string) => string;
  renderDatepicker: Function;
  intl: Intl;
};

export const Audience = ({
 statsMissing,
 getStats,
 renderDatepicker,
 getCountryNameByCode,
 intl
}: Props) => {
  const countriesStats: Countries[] = getStats('country');
  const elementsIds = ['pages', 'hits'];

  return (
    <Grid gap="x-large">
      <Flex gutter="none">
        <Box sm="3">
          {renderDatepicker()}
        </Box>
      </Flex>
      <div style={{ width: '100%' }}>
        <PartialLoader
          position="absolute"
          resources={[{ requestTypeName: customRequestTypes.COUNTRIES }]}
        >
          {
            statsMissing ? (
              <SGTable data={[]} shadow={false} noDataMessage="translate.page.stats.no-stats.placeholder.message" />
            ) : (
              <ChartContainer
                legendCols="2"
                chartCols="10"
                title={intl.formatMessage({ id: 'translate.page.stats.countries.label' })}
                renderCustomLegendContent={() => (
                  <ChartLegend
                    data={[{
                      id: 'pages',
                      label: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                      checked: true
                    }, {
                      id: 'hits',
                      label: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
                      checked: true
                    }]}
                    checkboxIcon="empty"
                    labelKey="label"
                  />
                )}
                renderChildren={() => {
                  return (
                    <BarChart
                      data={countriesStats}
                      yAxisWidth={80}
                      axisData={{
                        y: 'country'
                      }}
                      tickFormatterY={getCountryNameByCode}
                      elementsIds={elementsIds}
                      filteredElementsIds={elementsIds}
                      renderTooltip={({ payload }: { payload: Countries }) => (
                        <ChartTooltip
                          elementsIds={elementsIds}
                          filteredElementsIds={elementsIds}
                          payload={payload}
                          title={getCountryNameByCode(payload.country)}
                          elementLabels={{
                            pages: `${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })}: `,
                            hits: `${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}: `
                          }}
                        />
                      )}
                    />
                  );
                }}
              />
            )
          }
        </PartialLoader>
      </div>
    </Grid>
  );
};

export default Audience;
