import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.SSH_CHANGE_KEY_NAME;

const ChangeKeyName = ({ intl, validationUtils }: Props) => (
  <Grid>
    <Field
      name="comment"
      type="text"
      label={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
      placeholder={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
      validate={[validationUtils.required]}
      component={FormInput}
    />
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(ChangeKeyName)
  )
);
