import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, Field, getFormValues } from 'redux-form';
import { Button, Context, Grid, Label, Link, Notice, Section, Tab, Table, Tabs } from 'sg-styleguide';
import { addSiteManually } from '../../../core/actions/pages/wp-autoupdate';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { getLatestWordpressInfo, getAllWordpressApps } from '../../../core/selectors';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import NoWPApplicationNotice from '../../containers/no-wordpress-app-notice';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../containers/sg-dialog';
import { SecondLevelTitle } from '../../containers/titles';
import VCS from '../../containers/visibility-control-service';
import { CREATE_BOX_DROPDOWN_VALUE_KEY, WP_SITE_STATUS } from './constants';
import { CreateBox, CreateForm } from './create';
import WPAutoupdateLastBackup from './last-backup';
import WPAutoupdateSettings from './settings';
import { AddAppForm } from './update';
import './wp-autoupdate.scss';

interface WPAutoupdatePageProps {
  actions: CrudActions;
  items: any;
  router: {
    push: Function;
  };
  location: {
    search: string;
  };
  intl: Intl;
  wordpressApps: any[];
  wordpressInfo: any;
  createFormValues: any;
  changeCreateFormFieldValue: any;
  addSiteManually: any;
  domain: any;
  hasAppsData: boolean;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
}

const TAB = {
  SETTINGS: 'SETTINGS',
  LAST_BACKUP: 'LAST_BACKUP'
};

const PaddingBox = ({ children, className }) => (
  <Context.Consumer>
    {({ device }) => (
      <Grid className={className} padding={device.isPhone ? 'medium' : 'large'}>
        {children}
      </Grid>
    )}
  </Context.Consumer>
);

class WPAutoupdatePage extends React.Component<WPAutoupdatePageProps, any> {
  readonly state = {
    activeTab: TAB.SETTINGS
  };

  getSelectedSite() {
    const { wordpressApps, createFormValues } = this.props;

    if (!createFormValues) {
      return null;
    }

    return wordpressApps
      .find((app) => app[CREATE_BOX_DROPDOWN_VALUE_KEY] === createFormValues[CREATE_BOX_DROPDOWN_VALUE_KEY]);
  }

  getSiteStatus = (site) => {
    const { wordpressInfo } = this.props;

    if (!site || !wordpressInfo) {
      return null;
    }

    if (site.version === wordpressInfo.version.latest) {
      return WP_SITE_STATUS.UP_TO_DATE;
    }

    if (site.scheduled_for_update === 1) {
      return WP_SITE_STATUS.UPDATE_SCHEDULED;
    }

    if (site.skip_version !== null) {
      return WP_SITE_STATUS.SKIP_UPDATE;
    }

    if (site.scheduled_for_update === 0) {
      return WP_SITE_STATUS.AUTOUPDATE_DISABLED;
    }

    return null;
  };

  renderStatusLabel(site) {
    const { intl } = this.props;

    if (!site) {
      return null;
    }

    const siteStatus = this.getSiteStatus(site);
    let labelType;
    let label;

    switch (siteStatus) {
      case WP_SITE_STATUS.UP_TO_DATE:
        labelType = 'active-outlined';
        label = 'translate.page.wp.autoupdate.status.up.to.date';
        break;
      case WP_SITE_STATUS.UPDATE_SCHEDULED:
        labelType = 'active-outlined';
        label = 'translate.page.wp.autoupdate.status.update.scheduled';
        break;
      case WP_SITE_STATUS.AUTOUPDATE_DISABLED:
        labelType = 'inactive-outlined';
        label = 'translate.page.wp.autoupdate.status.auto.update.disabled';
        break;
      case WP_SITE_STATUS.SKIP_UPDATE:
        labelType = 'default-outlined';
        label = 'translate.page.wp.autoupdate.status.last.update.skipped';
        break;
      default:
        return null;
    }

    return (
      <Label type={labelType}>{intl.formatMessage({ id: label })}</Label>
    );
  }

  renderSelectedSiteLabel() {
    return this.renderStatusLabel(this.getSelectedSite());
  }

  renderAddManualDialog() {
    const { addSiteManually, intl, domain, closeSGDialog } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.add.manually.title' })}
        icon="new-staging"
        state="active"
        resources={[{ requestTypeName: customRequestTypes.ADD_APP_MANUALLY }]}
      >
        <AddAppForm
          domain={domain}
          initialValues={{
            _metaFields: {
              ...API_RESOURCE.APP
            }
          }}
          onSubmit={(data) => {
            const domainObj = domain.find((d) => d.id === data.domain_id);
            const domainName = domainObj && domainObj.name;
            const modifiedData = {
              ...data,
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey:  'translate.page.wp.autoupdate.add.manually.success.message',
                    intlValues: { domain: domainName }
                  },
                  error: {
                    intlKey: 'translate.page.wp.autoupdate.add.manually.error.message',
                    intlValues: { domain: domainName }
                  }
                }
              }
            };

            addSiteManually(modifiedData, () => closeSGDialog(REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG));
          }}
        />
      </SGDialogForm>
    );
  }

  renderListDialog() {
    const { intl, changeCreateFormFieldValue, wordpressApps, closeSGDialog } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.domain' }),
        accessor: 'app_url'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.status' }),
        accessor: 'id',
        render: (cell, entity) => this.renderStatusLabel(wordpressApps.find((app) => app.id === cell))
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.version' }),
        accessor: 'version'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: (cell, entity) => (
          <Button
            color="secondary"
            type="outlined"
            size="small"
            onClick={() => {
              changeCreateFormFieldValue(entity[CREATE_BOX_DROPDOWN_VALUE_KEY]);
              closeSGDialog(REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.manage' })}
          </Button>
        )
      }
    ];

    return (
      <SGDialog
        id={REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG}
        state="warning"
        icon="new-staging"
        size="x-large"
        density="none"
        title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.list.dialog.title' })}
        footer={
          <SGDialogCancel id={REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG} />
        }
      >
        <Table data={wordpressApps} columns={columns} headerBackground="white" />
      </SGDialog>
    );
  }

  renderTabs() {
    const { intl } = this.props;
    const { activeTab } = this.state;

    return (
      <Tabs size="small">
        <Tab
          active={activeTab === TAB.SETTINGS}
          data-e2e="settings"
          onClick={() => this.setState({ activeTab: TAB.SETTINGS })}
        >
          {intl.formatMessage({ id: 'translate.page.wp.autoupdate.tab.setting' })}
        </Tab>
        <Tab
          active={activeTab === TAB.LAST_BACKUP}
          data-e2e="last-backup"
          onClick={() => this.setState({ activeTab: TAB.LAST_BACKUP })}
        >
          {intl.formatMessage({ id: 'translate.page.wp.autoupdate.last.backup' })}
        </Tab>
      </Tabs>
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;
    const selectedSite = this.getSelectedSite();

    switch (activeTab) {
      case  TAB.SETTINGS:
        return <WPAutoupdateSettings selectedSite={selectedSite} />;
      case  TAB.LAST_BACKUP:
        return <WPAutoupdateLastBackup selectedSite={selectedSite} />;
      default:
        return null;
    }
  }

  renderPageContent() {
    const { intl, wordpressApps, wordpressInfo } = this.props;

    return (
      <Grid>
        <CreateBox>
          <CreateForm
            wordpressApps={wordpressApps}
            wordpressInfo={wordpressInfo}
            getSiteStatus={this.getSiteStatus}
            selectedSite={this.getSelectedSite()}
            selectedSiteLabel={this.renderSelectedSiteLabel()}
          />
        </CreateBox>

        <VCS resourceName={API_RESOURCE.APP.resourceNameMetaApi} hasMethod="GET">
          <div>
            <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.wp.autoupdate.list.title' })}</SecondLevelTitle>

            <div className="wp-autoupdate-settings">
              {this.renderTabs()}

              {this.renderTabContent()}

              <PaddingBox className="more-settings-notice">
                <Notice
                  background="light"
                  type="instruction"
                  shadow={false}
                  border={false}
                >
                  <FormattedMessage
                    id="translate.page.wp.autoupdate.more.settings.info.box"
                    values={{
                      allSiteLink: (
                        <Link onClick={() => this.props.openSGDialog(REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG)}>
                          <FormattedMessage id="translate.generic.here" />
                        </Link>
                      ),
                      addSiteManually: (
                        <Link onClick={() => this.props.openSGDialog(REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG)}>
                          <FormattedMessage id="translate.generic.here" />
                        </Link>
                      )
                    }}
                  />
                </Notice>
              </PaddingBox>

              {this.renderAddManualDialog()}
              {this.renderListDialog()}
            </div>
          </div>
        </VCS>
      </Grid>
    );
  }

  render() {
    const { intl, wordpressApps, hasAppsData } = this.props;
    const hasWordpressApps = wordpressApps.length > 0;
    const showPageContent = !hasAppsData || (hasAppsData && hasWordpressApps);

    return (
      <div>
        <PageHeader
          icon="presentational-wordpress-autoupdate"
          title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.wp.autoupdate.info' })}
        />

        <Section>
          {showPageContent ? this.renderPageContent() : <NoWPApplicationNotice />}
        </Section>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  addSiteManually: bindActionCreators(addSiteManually, dispatch),
  changeCreateFormFieldValue: (value) =>
    dispatch(change(REDUX_FORM.CREATE_WP_AUTOUPDATE, CREATE_BOX_DROPDOWN_VALUE_KEY, value))
});

const mapStateToProps = (store) => ({
  createFormValues: getFormValues(REDUX_FORM.CREATE_WP_AUTOUPDATE)(store),
  domain: store.pageItems.domain || [],
  wordpressApps: getAllWordpressApps(store),
  wordpressInfo: getLatestWordpressInfo(store),
  hasAppsData: Boolean(store.pageItems[API_RESOURCE.APP.resourceName])
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  indexWithCRUD()(
    WPAutoupdatePage,
    API_RESOURCE.APP,
    API_RESOURCE.APP_LATEST,
    API_RESOURCE.DOMAIN
  )
);
