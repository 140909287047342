import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { RootState } from '../../../../core/reducers';
import { getFileMangerAllUploadEntities } from '../core/reducers/utils/upload';
import './upload-progress.scss';

import { Icon, IconButton, Label, Text, textToHTML } from 'sg-styleguide';

class UploadProgress extends React.Component<any, any> {
  getProgress() {
    const { uploader, totalSizeTransferred, allUploaderEntities } = this.props;
    const totalSize = allUploaderEntities.map((entity) => entity.file.size).reduce((a, b) => a + b, 0);

    let progress = (totalSizeTransferred / totalSize || 0) * 100;

    if (progress > 100 && uploader.requested.length + uploader.pending.length !== 0) {
      progress = 98;
    }

    if (progress > 100 && uploader.requested.length + uploader.pending.length === 0) {
      progress = 100;
    }

    return progress;
  }

  render() {
    const { intl, uploader, allUploaderEntities, onClose, onFailedClick } = this.props;
    const { failed } = uploader;
    const totalCount = allUploaderEntities.length;

    const progress = this.getProgress();
    const progressText = progress >= 100 && failed.length === 0 ?
      intl.formatMessage({ id: 'translate.file.manager.upload.progress.successfully.loaded' }, { total: totalCount }) :
      intl.formatMessage({ id: 'translate.file.manager.upload.progress.uploading' }, { total: totalCount });

    return (
      <div className="upload-progress" data-e2e="upload-progress">
        <div className="upload-progress__indicator" style={{ width: `${progress}%` }} />

        <div className="upload-progress__content">
          <Text color="dark" size="small">{textToHTML(progressText)}</Text>
          {failed.length !== 0 && (
            <Text
              color="dark"
              size="small"
              onClick={onFailedClick}
              data-e2e="failed-label"
            >
              <Label type="inactive" className="upload-progress__content-failed-label">
                {`${failed.length} ${intl.formatMessage({ id: 'translate.generic.failed' })}`}
              </Label>
            </Text>
          )}

          <Text
            color="dark"
            size="small"
            weight="bold"
            className="upload-progress__content-close-text"
            data-e2e="close-text"
            onClick={onClose}
          >
            {intl.formatMessage({ id: progress === 100 ? 'translate.generic.close' : 'translate.generic.cancel' })}
          </Text>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state: RootState) => ({
  uploader: state.fileManager.uploader,
  totalSizeTransferred: state.fileManager.uploader.totalSizeTransferred,
  allUploaderEntities: getFileMangerAllUploadEntities(state)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(UploadProgress));
