import { createAction } from 'redux-actions';
import * as Actions from '../constants/actions';

export const httpRequestStarted =
  createAction(Actions.HTTP_REQUEST_STARTED, (requestedAction) => ({ requestedAction }));

export const httpRequestSucceeded = (requestedAction, response?, task?: Task) => ({
  type: Actions.HTTP_REQUEST_SUCCEEDED,
  payload: {
    requestedAction,
    response,
    task
  }
});

export const httpRequestFailed = (requestedAction, exception?, task?: Task) => ({
  type: Actions.HTTP_REQUEST_FAILED,
  payload: {
    requestedAction,
    exception,
    task
  }
});
