import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Grid, Link, Notice, Text } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../core/constants/common';
import ROUTES from '../../../core/constants/routes';
import { RootState } from '../../../core/reducers/index';

type Props = {
  intl: Intl;
  locationSearch: string;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  router: {
    push: Function;
  }
};

const NoWPApplicationNotice = ({ intl, locationSearch, openSGDialog, closeSGDialog, router }: Props) => (
  <Grid padding={['medium', 'none', 'none', 'none']}>
    <Notice
      type="info"
      title={intl.formatMessage({ id: 'translate.notice.no.wp.application.title' })}
    >
      <Text>
        <FormattedMessage
          id="translate.notice.no.wp.application.text"
          values={{
            link: <Link onClick={() => router.push(`${ROUTES.WP_MANAGE}${locationSearch}`)}>{intl.formatMessage(
              { id: 'translate.page.wp.manage.title' }
            )}</Link>
          }}
        />
      </Text>

      <Button
        type="link"
        color="secondary"
        onClick={() => {
          closeSGDialog(REDUX_FORM.ADD_EXISTING_APP);
          router.push(`${ROUTES.WP_MANAGE}${locationSearch}`);
        }}
      >
        {intl.formatMessage({ id: 'translate.notice.no.wp.application.install.new' })}
      </Button>

      <Button
        type="link"
        color="secondary"
        onClick={() => {
          openSGDialog(REDUX_FORM.ADD_EXISTING_APP);
          router.push(`${ROUTES.WP_MANAGE}${locationSearch}`);
        }}
      >
        {intl.formatMessage({ id: 'translate.notice.no.wp.application.add.existing' })}
      </Button>
    </Notice>
  </Grid>
);

const mapStateToProps = (store: RootState) => ({
  locationSearch: store.routing.locationBeforeTransitions.search
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(NoWPApplicationNotice)));
