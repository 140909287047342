export const defaultFieldsArray = ['', '', '', '', ''];

export const fullfillCronFields = (
  fieldsArr = defaultFieldsArray,
  changeAction: (fieldId: string, value: string) => any
) => {
  changeAction('min', fieldsArr[0]);
  changeAction('hour', fieldsArr[1]);
  changeAction('day', fieldsArr[2]);
  changeAction('mon', fieldsArr[3]);
  changeAction('dow', fieldsArr[4]);
};
