import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link, List, ListItem, Title } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.LOCATION_REDIRECT;
const formName = REDUX_FORM.CREATE_REDIRECT;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.redirect.create.title' })}
      resourceName={resourceName}
      formName={formName}
      renderInfo={({ titleProps, listProps, listItemProps }) => (
        <React.Fragment>
          <Title {...titleProps}>{intl.formatMessage({ id: 'translate.create.box.default.info.box.title' })}</Title>
          <List {...listProps} >
            <ListItem {...listItemProps}>
              <Link>{intl.formatMessage({ id: 'translate.page.domain.create.link.create.domain.redirects' })}</Link>
            </ListItem>
            <ListItem {...listItemProps}>
              <Link>{intl.formatMessage({ id: 'translate.page.domain.create.link.domain.redirects.info' })}</Link>
            </ListItem>
            <ListItem {...listItemProps}>
              <Link>{intl.formatMessage({ id: 'translate.page.domain.create.link.type.of.domain.redirects' })}</Link>
            </ListItem>
          </List>
        </React.Fragment>
      )}
      resources={[{
        resourceName: API_RESOURCE.LOCATION_REDIRECT.resourceName,
        methods: ['POST']
      }, {
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['GET']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
