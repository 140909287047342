import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createNotification } from '../../actions/notifications';

import * as sgSiteScannerActions from '../../actions/pages/sg-sitescanner';
import { clientApi } from '../../api/client';

import {
  FORCE_SCAN,
  REQUEST_SG_SITESCANNER_DATA,
  REQUEST_SG_SITESCANNER_REPORTS,
  SET_SG_SITESCANNER_REPORTS
} from '../../constants/actions';
import { sgSiteScannerDataTypes } from '../../constants/sg-sitescanner';

import * as sgSiteScannerTypes from '../../definitions/sg-sitescanner';
import { isACLActionEnabled } from '../../selectors/site-meta-api';
import { getToolMainEndpoint } from '../../selectors/static-api';

import {
  getEmailReportsStatus,
  getPrimaryDomainSiteScannerUpgradeId,
  normalizeReportsData
} from '../../utils/sg-sitescanner';
import { handleNemoApiRequest } from '../handle-nemo-api-request';

function* getSgSiteScannerUpgrades({ siteId }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/sg-site-scanner/upgrades/${siteId}?_client_token=${session.clientToken}`, 'GET')
  );
}

function* getSgSiteScannerReports({ upgradeId }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/sg-site-scanner/reports/${upgradeId}?_client_token=${session.clientToken}`, 'GET')
  );
}

function* requestForceScan({ upgradeId }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/sg-site-scanner/force-scan/${upgradeId}?_client_token=${session.clientToken}`, 'GET')
  );
}

function* requestSetSgSitescannerReports({ enable, upgradeId }) {
  const { session } = yield select();
  const endpointSuffix = enable ? 'enable' : 'disable';

  return yield call(
    clientApi(
      `/sg-site-scanner/email-reporting/${upgradeId}/${endpointSuffix}?_client_token=${session.clientToken}`,
      'GET'
    )
  );
}

function* requestPageData(action: NemoRequestAction) {
  const { siteId, mainDomainName, onSiteScannerAvailabilityReady } = action.payload;

  // trigger request for site upgrades
  const upgradesResponse: sgSiteScannerTypes.UpgradeEntriesResponse = yield call(
    handleNemoApiRequest(getSgSiteScannerUpgrades, action),
    { siteId }
  );

  if (upgradesResponse) {
    const sgSiteScanneAvailableUpgrade = getPrimaryDomainSiteScannerUpgradeId(
      upgradesResponse.data.upgrade_entries,
      mainDomainName
    );

    const emailReportsEnabled = getEmailReportsStatus(upgradesResponse.data.upgrade_entries, mainDomainName);

    onSiteScannerAvailabilityReady(sgSiteScanneAvailableUpgrade);

    yield put(sgSiteScannerActions.storeSgSitescannerData(
      sgSiteScannerDataTypes.emailReporting,
      emailReportsEnabled
    ));

    if (sgSiteScanneAvailableUpgrade) {
      const isUAAvailable = yield select(
        isACLActionEnabled,
        getToolMainEndpoint('domain'),
        'can_order'
      );

      yield put(sgSiteScannerActions.storeSgSitescannerData(
        sgSiteScannerDataTypes.upgradeEntries,
        upgradesResponse.data.upgrade_entries
      ));

      yield put(sgSiteScannerActions.requestSgSitescannerReports(sgSiteScanneAvailableUpgrade));
    }
  }
}

function* forceScan(action: NemoRequestAction) {
  const { onComplete, payload } = action;
  const { upgradeId } = payload;
  const { session } = yield select();

  const response = yield call(
    handleNemoApiRequest(requestForceScan, action),
    { upgradeId }
  );
}

function* requestReports(action: NemoRequestAction) {
  const { payload } = action;
  const { upgradeId } = payload;

  const reportsResponse: sgSiteScannerTypes.ReportsResponse = yield call(
    handleNemoApiRequest(getSgSiteScannerReports, action),
    { upgradeId }
  );

  if (reportsResponse) {
    yield put(sgSiteScannerActions.storeSgSitescannerData(
      sgSiteScannerDataTypes.reports,
      normalizeReportsData(reportsResponse)
    ));
  }
}

function* setSgSitescannerReports(action: NemoRequestAction) {
  const { payload } = action;
  const { enable, upgradeId, successMessage, errorMessage } = payload;

  const response = yield call(
    handleNemoApiRequest(requestSetSgSitescannerReports, action),
    { upgradeId, enable }
  );

  if (response) {
    yield put(sgSiteScannerActions.storeSgSitescannerData(
      sgSiteScannerDataTypes.emailReporting,
      enable
    ));
  }
}

function* sgSiteScanner(): any {
  yield takeLatest(REQUEST_SG_SITESCANNER_DATA, requestPageData);
  yield takeLatest(REQUEST_SG_SITESCANNER_REPORTS, requestReports);
  yield takeLatest(FORCE_SCAN, forceScan);
  yield takeLatest(SET_SG_SITESCANNER_REPORTS, setSgSitescannerReports);
}

export default sgSiteScanner;
