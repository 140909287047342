import { WEEBLY_PLANS, WEEBLY_PLANS_OPTIONS, WEEBLY_CLASS_NUMBER_TO_PLAN } from '../constants';

export const NotSupportedYet = ({ children }) => null;

export const getCmsIconSize = ({ isPhone, isDesktop }) => {
  if (isPhone) {
    return '40';
  }

  if (isDesktop) {
    return '60';
  }

  return '50';
};

export const WEEBLY_PLANS_INDEXES = [...Object.values(WEEBLY_PLANS)];

export const generateWeeblyPlanOptions = (selected: string) => {
  const weeblyPlanId = WEEBLY_CLASS_NUMBER_TO_PLAN[selected];

  const options = WEEBLY_PLANS_OPTIONS.map(({ id, label }, optionIndex) => {
    const current = weeblyPlanId === id;

    return {
      id,
      label,
      current,
      disabled: Boolean(
        current || (
          weeblyPlanId &&
          optionIndex < WEEBLY_PLANS_INDEXES.indexOf(weeblyPlanId)
        )
      )
    };
  });

  return options;
};
