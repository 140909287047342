import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Button, ContextMenu, ContextMenuItem, Flex, IconButton, Spacer, Switch } from 'sg-styleguide';
import * as actions from '../../../core/actions/crud';
import * as domainActions from '../../../core/actions/domainCache';
import { requestData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { RootState } from '../../../core/reducers/index';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { SecondLevelTitle } from '../../containers/titles';
import VCS from '../../containers/visibility-control-service';

interface DomainCacheProps {
  actions: CrudActions;
  domainActions: typeof domainActions;
  items: any;
  environment: {
    isPhone: boolean;
  };
  location: any;
  intl: Intl;
  requestData: typeof requestData;
}

const itemName: string = 'DomainCache account';

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.DOMAIN_CACHE;

class DomainCachePage extends React.Component<DomainCacheProps, any> {
  readonly state = {
    currentUpdatePayload: null
  };

  render() {
    if (!this.props.items.domainCache) {
      return null;
    }

    return this.renderDomainCacheItems();
  }

  renderDomainCacheItems = () => {
    const { intl, environment, items } = this.props;
    const isPhone = environment.isPhone;

    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.name' }),
      accessor: 'name',
      style: !isPhone ? { width: '70%' } : null
    }, {
      header: intl.formatMessage({ id: 'translate.generic.static_cache_enabled' }),
      accessor: 'static_cache_enabled',
      render: this.renderCacheToggle
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderCacheFlush,
      align: false
    }];

    return (
      <div>
        <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
          <Flex
            margin="none"
            gutter="none"
            align="center"
            style={isPhone ? { paddingRight: '20px' } : undefined}
          >
            <SecondLevelTitle>
              {intl.formatMessage({ id: 'translate.page.domainCache.title' })}
            </SecondLevelTitle>
            <Spacer />
            {this.renderTableHeaderActions()}
          </Flex>

          <SGTable
            data={items.domainCache}
            columns={columns}
            resources={[
              { resourceName: API_RESOURCE.DOMAIN_CACHE.resourceName, methods: ['GET'] },
              { requestTypeName: customRequestTypes.REQUEST_DATA }
            ]}
            rowResources={[{ resourceName: API_RESOURCE.DOMAIN_CACHE.resourceName, methods: ['PUT'] }]}
          />
        </VCS>
      </div>
    );
  };

  renderTableHeaderActions() {
    const { environment } = this.props;
    const isPhone = environment.isPhone;

    if (isPhone) {
      return (
        <ContextMenu
          opener={<IconButton shape="circle" size="medium" icon="dots" data-e2e="table-actions" />}
        >
          {this.renderCacheFlushAll()}
          {this.renderCacheDisableAll()}
        </ContextMenu>
      );
    }

    return (
      <React.Fragment>
        {this.renderCacheFlushAll()}
        {this.renderCacheDisableAll()}
      </React.Fragment>
    );
  }

  renderCacheDisableAll = () => {
    const { environment, intl, requestData } = this.props;
    const isPhone = environment.isPhone;

    const disableRowCachePayload: RequestDataPayload = {
      _metaFields: {
        method: 'POST',
        resourceName,
        endpoint
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.domainCache.cache_disabled_for_all'
          },
          error: {
            intlKey: 'translate.page.domainCache.cache_disabled_for_all.failed'
          }
        }
      },
      static_cache_enabled: 0
    };

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod={'POST'} key="disable-cache">
        {isPhone ? (
          <ContextMenuItem
            onClick={() => requestData(
              { payload: disableRowCachePayload },
              () => this.props.domainActions.disableAllDomainCache()
            )}
          >
            {this.props.intl.formatMessage({ id: 'translate.page.domainCache.disable_cache_all' })}
          </ContextMenuItem>
        ) : (
          <Button
            color="primary"
            size="small"
            style={{ marginLeft: '10px' }}
            data-e2e="disable-all-cache"
            onClick={() => requestData(
              { payload: disableRowCachePayload },
              () => this.props.domainActions.disableAllDomainCache()
            )}
          >
            {this.props.intl.formatMessage({ id: 'translate.page.domainCache.disable_cache_all' })}
          </Button>
        )}
      </VCS>
    );
  };

  renderCacheFlushAll = () => {
    const { environment, intl, requestData } = this.props;
    const isPhone = environment.isPhone;

    const flushRowCachePayload: RequestDataPayload = {
      _metaFields: {
        method: 'POST',
        resourceName,
        endpoint
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.domainCache.cache_flushed_all'
          },
          error: {
            intlKey: 'translate.page.domainCache.cache_flushed_all.failed'
          }
        }
      },
      flush_cache: 1
    };

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod={'POST'} key="flush-cache">
        {isPhone ? (
          <ContextMenuItem onClick={() => requestData({ payload: flushRowCachePayload })}>
            {this.props.intl.formatMessage({ id: 'translate.page.domainCache.flush_cache_all' })}
          </ContextMenuItem>
        ) : (
          <Button
            color="primary"
            size="small"
            data-e2e="flush-all-cache"
            onClick={() => requestData({ payload: flushRowCachePayload })}
          >
            {this.props.intl.formatMessage({ id: 'translate.page.domainCache.flush_cache_all' })}
          </Button>
        )}
      </VCS>
    );
  };

  renderCacheFlush = (id, entity) => {
    const { actions, intl } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          icon: 'flush',
          label: intl.formatMessage({ id: 'translate.page.domainCache.flush_cache' }),
          e2eAttr: 'table-action-flush-cache',
          visibleOnDesktop: true,
          onClick: () => actions.updateItem({
            _metaFields: {
              resourceName,
              endpoint,
              dontChangeItemsState: true
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.domainCache.cache_flushed',
                  intlValues: { entityName: entity.name }
                },
                error: {
                  intlKey: 'translate.page.domainCache.cache_flushed.failed',
                  intlValues: { entityName: entity.name }
                }
              }
            },
            flush_cache: 1,
            id: entity.id
          })
        }]}
      />
    );
  };

  renderCacheToggle = (id, entity) => {
    const { actions } = this.props;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod={'PUT'}>
        <Switch checked={entity.static_cache_enabled} onChange={(event) => {
          const cacheEnabled = Boolean(event.target.checked);

          actions.updateItem({
            id: entity.id,
            static_cache_enabled: cacheEnabled ? 1 : 0,
            _metaFields: { resourceName, endpoint },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: cacheEnabled ?
                    'translate.page.superCacher.static.enable.success.notification' :
                    'translate.page.superCacher.static.disable.success.notification',
                  intlValues: { domain: entity.name }
                },
                error: {
                  intlKey: cacheEnabled ?
                    'translate.page.superCacher.static.enable.fail.notification' :
                    'translate.page.superCacher.static.disable.fail.notification',
                  intlValues: { domain: entity.name }
                }
              }
            }
          });
        }} />
      </VCS>
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment
});

const mapDispatchToProps = (dispatch) => ({
  requestData: (data, callback) => dispatch(requestData(data, callback)),
  actions: bindActionCreators(actions as any, dispatch),
  domainActions: bindActionCreators(domainActions as any, dispatch)
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(DomainCachePage, API_RESOURCE.DOMAIN_CACHE);
