import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { Button, Flex, Grid, Icon, Text, Tile, Tree, FormLabel } from 'sg-styleguide';
import { fetchDirByPath } from '../../../core/actions/fetch-dir-by-path';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';

type Props = {
  id?: string;
  fetchDirByPath: Function;
  onChange: Function;
  intl: Intl;
  dialog: any;
  dir: any;
  handleClose: Function;
  initialPath: string;
  expandedNodes?: string[];
  prefetch?: string[];
  closeSGDialog: Function;
  normalizeDirectoryData?: Function;
  title?: string;
  nodesParentFolder?: {
    name: string;
    path: string;
    value: string;
  }
};

type State = {
  selectedFolder: string,
  expanded: any[]
};

const initialState = {
  selectedFolder: '',
  expanded: []
};

class SelectDirectory extends React.Component<Props, State> {
  readonly state = initialState;
  readonly dialogId = this.props.id || DIALOGS.SELECT_DIRECTORY;

  componentDidMount() {
    const { dialog } = this.props;

    if (dialog[this.dialogId]) {
      this.fetchInitialDir();
    }
  }

  componentDidUpdate(prevProps) {
    const { dialog } = this.props;

    if (dialog[this.dialogId] && dialog[this.dialogId] !== prevProps.dialog[this.dialogId]) {
      this.fetchInitialDir();
    }
  }

  fetchInitialDir() {
    const { expandedNodes, initialPath, fetchDirByPath, prefetch } = this.props;

    if (initialPath) {
      fetchDirByPath(initialPath);
    }

    if (prefetch) {
      prefetch.forEach((path) => fetchDirByPath(path));
    }

    if (expandedNodes) {
      this.setState({ expanded: expandedNodes });
    }
  }

  mapDataToTree = (entity, data) => {
    const entityPath = entity._meta.path;
    const items = data[entityPath];

    return {
      entity,
      value: entity.n,
      label: this.renderTreeNode,
      forceShowToggleIcon: true,
      showCheckbox: false,
      children: items && items.filter((ent) => ent.t !== 'f').map((ent) => this.mapDataToTree(ent, data))
    };
  };

  renderTreeNode = (entity) => {
    const isSelected = entity._meta.path === this.state.selectedFolder;

    return (
      <Flex
        align="center"
        gutter="none"
        margin="none"
        wrap="nowrap"
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(event) => this.setState({ selectedFolder: entity._meta.path })}
      >
        <Icon name="folder" size="18" color={isSelected ? 'secondary' : 'lighter'} multicolor />

        <Text color={isSelected ? 'secondary' : undefined} style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
          {entity.n.length > 1 ? entity.n.replace(/.*?\//, '') : entity.n}
        </Text>
      </Flex>
    );
  };

  wrapNodesInParentFolder = (nodes) => [{
    entity: {
      n: this.props.nodesParentFolder.name,
      t:'f',
      _meta: {
        path: this.props.nodesParentFolder.path
      }
    },
    value: this.props.nodesParentFolder.value,
    label: this.renderTreeNode,
    forceShowToggleIcon: true,
    showCheckbox: false,
    children: nodes
  }]

  render() {
    const { dir, initialPath, intl, closeSGDialog, normalizeDirectoryData, nodesParentFolder, title } = this.props;
    const { selectedFolder } = this.state;

    if (!dir[initialPath]) {
      return null;
    }

    let nodes = dir[initialPath]
      .filter((ent) => ent.t !== 'f')
      .map((ent) => this.mapDataToTree(ent, dir));

    if (nodesParentFolder) {
      nodes = this.wrapNodesInParentFolder(nodes);
    }

    if (normalizeDirectoryData) {
      nodes = normalizeDirectoryData(nodes);
    }

    return (
      <SGDialog
        id={this.dialogId}
        icon="folder"
        state="warning"
        size="x-large"
        title={title || intl.formatMessage({ id: 'translate.browse.folder.dialog.title' })}
        footer={
          <div>
            <SGDialogCancel id={this.dialogId} />

            <Button
              color="primary"
              data-e2e="dialog-submit"
              disabled={!selectedFolder}
              onClick={() => {
                this.props.onChange(selectedFolder.replace('//', '/'));
                closeSGDialog(this.dialogId);
              }}
            >
              {intl.formatMessage({ id: 'translate.generic.select' })}
            </Button>
          </div>
        }
        resources={[{ requestTypeName: customRequestTypes.SELECT_DIRECTORY_FETCH }]}
        onCloseHandler={() => this.setState({ ...initialState })}
      >
        <Grid gap="none">
          <FormLabel>
            {intl.formatMessage({ id: 'translate.generic.select.folder' })}
          </FormLabel>

          <Tile>
            <Tree
              nodes={nodes}
              expanded={this.state.expanded}
              onExpand={(expanded, data) => {
                if (data.expanded) {
                  this.props.fetchDirByPath(data.entity._meta.path);
                }

                this.setState({ expanded });
              }}
              style={{ width: 'auto' }}
            />
          </Tile>
        </Grid>
      </SGDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchDirByPath: bindActionCreators(fetchDirByPath, dispatch),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (store) => ({
  dir: store.dir,
  dialog: store.dialog
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(SelectDirectory));
