import * as Actions from '../constants/actions';

import { getEntity } from '../utils';
import { FileManagerRootState } from './utils/internal-state-definition';

const NavigationReducer = {
  [Actions.FILE_MANAGER_ON_SIDE_NAVIGATION_ENTITY_CLICK]: (state: FileManagerRootState, action: any) => {
    const { entity } = action.payload;
    const selectedEntity = getEntity(entity, state);

    const newState = {
      ...state,
      time: +new Date()
    };

    if (selectedEntity) {
      selectedEntity._meta.isExpanded = true;
    }
    // TODO ask Niki if he want single click expand/collapse
    // selectedEntity._meta.isExpanded = newState.entities[selectedEntityPath] ? true : false;

    return {
      ...newState,
      selectedContentEntities: [],
      selectedNavigationEntity: selectedEntity
    };
  },
  [Actions.FILE_MANAGER_TOGGLE_NAVIGATION_LIST]: (state: FileManagerRootState, action: any) => {
    const { entity } = action.payload;
    const selectedEntity = getEntity(entity, state);
    const newState = {
      ...state,
      time: +new Date()
    };

    selectedEntity._meta.isExpanded = !selectedEntity._meta.isExpanded;

    return newState;
  }
};
export default NavigationReducer;
