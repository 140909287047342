import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Title, Button } from 'sg-styleguide';

import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';
import { REDUX_FORM } from '../../../../core/constants/common';
import { API_RESOURCE } from '../../../../core/constants/api';
import customRequestTypes from '../../../../core/constants/custom-request-types';

type Props = {
  children: any;
  intl?: Intl;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.APP_STAGING;
const formName = REDUX_FORM.CREATE_STAGING;

const CreateContainer = ({
  children,
  intl
}: Props) => {
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.staging.create.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[{
          requestTypeName: customRequestTypes.REQUEST_UKNOWN_WP_FILES
        }, {
          resourceName: API_RESOURCE.APP_STAGING.resourceName,
          methods: ['POST']
        }]}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          switch (true) {
            case (notification.state === 'error'):
              return renderDefaultNotificationTemplate();
            case (notification.state === 'success'):
              return (
                <div>
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="create-new"
                    onClick={removeNotification}
                  >
                    {intl.formatMessage({ id: 'translate.generic.create.new' })}
                  </Button>
                </div>
              );
            default:
              return null;
          }
        }}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer) as (props: Props) => any;
