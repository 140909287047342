import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import { Icon, Text } from 'sg-styleguide/';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_DND_TYPE } from '../core/constants/common';
import Entity from './entity';

const dragItemSource = {
  beginDrag(props) {
    return { entity: props.entity };
  }
};

const dropItemSource = {
  canDrop(props, monitor, component) {
    const isDragSourceFile = monitor.getItem().entity.t === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const isDropTargetFile = props.entity.t === FILE_MANAGER_API_RESPONSE_DIR.FILE;

    if (isDragSourceFile && isDropTargetFile) {
      return false;
    }

    if (!isDragSourceFile && isDropTargetFile) {
      return false;
    }

    return true;
  },

  drop(props, monitor, component) {
    const dragSourceEntity = monitor.getItem().entity;
    const dropTargetEntity = props.entity;

    // Don't replace items with themselves
    if (dragSourceEntity === dropTargetEntity) {
      return;
    }

    return props.handleDrop({ dragSourceEntity, dropTargetEntity });
  }
};

const collectDrag = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

const collectDrop = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

class DragItem extends React.Component<any, any> {
  render() {
    const { isDragging, isOver, connectDragSource, connectDropTarget, canDrop, entity } = this.props;

    const wrapperClasses = [
      'content__row',
      'content__row--clickabel',
      isOver && canDrop && 'content__row--dropzone'
    ].filter(Boolean).join(' ');

    const isTextBold = isOver && canDrop ? 'bold' : 'medium';

    return connectDragSource(connectDropTarget(
      <div className={wrapperClasses} onDoubleClick={this.props.onDoubleClick}>
        <Entity entity={entity} textWeight={isTextBold} />
      </div>
    ));
  }
}

let DragContentItem = DragSource(FILE_MANAGER_DND_TYPE.ENTITY, dragItemSource, collectDrag)(DragItem);
DragContentItem = DropTarget(FILE_MANAGER_DND_TYPE.ENTITY, dropItemSource, collectDrop)(DragContentItem);

export default DragContentItem;
