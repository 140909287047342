import * as React from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { dispatchRemoteFormSubmit } from '../sg-form-util/remote-submit-util';
import { SGDialog, SGDialogCancel, SGDialogSubmit } from './';

type Props = {
  name: string;
  // Dialog UI Props
  size?: string;
  title?: any;
  state?: string;
  icon?: string;
  align?: string;
  density?: string;
  footer?: any;
  // other
  submitLabel?: string;
  children: any;
  resources?: LoaderResource[];
  onSubmit?: (e) => void;
  remoteFormSubmit?: (formName) => (e) => void;
  destroyForm?: (formName) => void;
  onCloseHandler?: Function;
};

const SGDialogForm = ({
  children,
  name,
  size,
  title,
  state = 'warning',
  icon = 'edit',
  align,
  density,
  footer,
  resources = [],
  remoteFormSubmit,
  submitLabel,
  destroyForm,
  onCloseHandler
}: Props) => (
  <SGDialog
    id={name}
    size={size}
    title={title}
    state={state}
    icon={icon}
    align={align}
    density={density}
    resources={resources}
    footer={footer || (
      <React.Fragment>
        <SGDialogCancel id={name} />
        <SGDialogSubmit formName={name} label={submitLabel} />
      </React.Fragment>
    )}
    onCloseHandler={() => {
      destroyForm(name);

      if (onCloseHandler) {
        onCloseHandler();
      }
    }}
  >
    <form onSubmit={remoteFormSubmit(name)} name={name} data-e2e={`dialog-form-${name}`}>
      {children}
    </form>
  </SGDialog>
);

export default connect<any, any, Partial<Props>>(undefined, (dispatch) => ({
  remoteFormSubmit: dispatchRemoteFormSubmit(dispatch),
  destroyForm: (formName) => dispatch(destroy(formName))
}))(SGDialogForm);
