import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Button, Column, Dialog, Flex, Grid, Text } from 'sg-styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../containers/sg-form';
import FormInput from '../../../../components/form-input';
import FormTextarea from '../../../../components/form-textarea';

type Props = {
  change: Function;
  intl: any;
  focus: Function;
  formValues: Function;
  formErrors: Function;
  validationUtils: {
    required: () => null;
    validationWithMetaApi: () => null;
  };
  sPanelFormSubmit: (formName) => null;
  sPanelFocusField: (fieldName, formName) => null;
};

type State = {
  isErrorFileDialogVisible: boolean;
};

const formName = REDUX_FORM.CREATE_SSH;
const UPLOAD_INPUT_ID = 'ssh-upload-input';
const KEY_PUB_FIELD_NAME = 'key_pub';
const COMMENT_FIELD_NAME = 'comment';

class Form extends React.Component <Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isErrorFileDialogVisible: false
    };
  }

  getCommentFromSSHString(SSHString) {
    const rgxFrom = new RegExp(/from\s?=\s?"(.*?)"/g);
    const rgxSSHType = new RegExp(/(ssh-dss|ssh-rsa|ecdsa-sha2-nistp256|ecdsa-sha2-nistp384|ecdsa-sha2-nistp521)/);

    const SSHArray = SSHString.split(' ')
      .filter((el) => el.length !== 0 && !rgxFrom.test(el) && !rgxSSHType.test(el));

    return SSHArray.length > 1 ? SSHArray.pop() : '';
  }

  setCommentValue(text) {
    const { formErrors, formValues, change } = this.props;
    const syncErrors = formErrors(formName);
    const fieldValues = formValues(formName);

    if (!syncErrors) {
      return;
    }

    const label = this.getCommentFromSSHString(text);
    const strippedLabel = label.replace(/[\n\r]/g, '');
    const shouldSetCommentValue =
      !fieldValues[COMMENT_FIELD_NAME] || fieldValues[COMMENT_FIELD_NAME].includes(strippedLabel);

    if (!syncErrors[KEY_PUB_FIELD_NAME] && shouldSetCommentValue && strippedLabel.length > 0) {
      change(COMMENT_FIELD_NAME, strippedLabel);
    }
  }

  createInputForUpload = () => {
    const tempInput = document.createElement('input');
    tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.id = UPLOAD_INPUT_ID;
    tempInput.type = 'file';
    tempInput.onchange = this.uploadSSHFile;
    document.body.appendChild(tempInput);
    tempInput.click();
  };

  deleteInputForUpload() {
    document.body.removeChild(document.getElementById(UPLOAD_INPUT_ID));
  }

  uploadSSHFile = (event) => {
    event.preventDefault();

    const { change, sPanelFocusField } = this.props;
    const fileContent = event.currentTarget.files[0];
    const fileReader = new FileReader();

    this.deleteInputForUpload();

    if (fileContent.size < 2000) {
      fileReader.onload = ({ currentTarget }: any) => {
        let fileTextContent: string = currentTarget.result || '';
        fileTextContent = fileTextContent.trim();

        sPanelFocusField(KEY_PUB_FIELD_NAME, formName);
        change(KEY_PUB_FIELD_NAME, fileTextContent);

        this.setCommentValue(fileTextContent);
      };

      return fileReader.readAsText(fileContent, 'UTF-8');
    }

    this.setState({ isErrorFileDialogVisible: true });
    change(formName, KEY_PUB_FIELD_NAME, '');
  };

  renderFileValidationDialog = () => {
    const { isErrorFileDialogVisible } = this.state;
    const { intl } = this.props;

    if (!isErrorFileDialogVisible) {
      return null;
    }

    return (
      <Dialog
        open
        icon="error"
        state="inactive"
        size="medium"
        title={intl.formatMessage({ id: 'translate.page.ssh.file.translate.validation.error.title' })}
        onCloseHandler={() => this.setState({ isErrorFileDialogVisible: false })}
        footer={
          <Button
            data-e2e="dialog-close"
            onClick={() => this.setState({ isErrorFileDialogVisible: false })}
          >
            {intl.formatMessage({ id: 'translate.generic.close' })}
          </Button>
        }
      >
        <Grid sm="12">
          <Column smSpan="12">
            {intl.formatMessage({ id: 'translate.page.ssh.file.translate.validation.error' })}
          </Column>
        </Grid>
      </Dialog>
    );
  };

  render() {
    const { intl, validationUtils, sPanelFormSubmit } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <SGForm onSubmit={sPanelFormSubmit(formName)}>
        <Column smSpan="6">
          <Field
            name={COMMENT_FIELD_NAME}
            type="text"
            label={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
            placeholder={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <Field
            name={KEY_PUB_FIELD_NAME}
            type="text"
            label={
              <Flex justify="space-between" gutter="none" margin="none">
                {intl.formatMessage({ id: 'translate.page.ssh.key_pub' })}
                <Text
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    this.createInputForUpload();
                  }}
                >
                  {intl.formatMessage({ id: 'translate.page.ssh.select.key.from.file' })}
                </Text>
              </Flex>
            }
            placeholder={intl.formatMessage({ id: 'translate.page.ssh.key_pub' })}
            validate={[required, validationWithMetaApi]}
            onChange={(event, newValue) => this.setCommentValue(newValue)}
            component={FormTextarea}
          />
        </Column>

        <Column smSpan="12">
          <SGSubmitButton
            formName={formName}
            label={intl.formatMessage({ id: 'translate.generic.import' })}
          />
        </Column>

        {this.renderFileValidationDialog()}
      </SGForm>
    );
  };
}

const SSHForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.SSH,
      fetchEndpoint: '/ssh',
      fetchResourceName: 'ssh',
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(SSHForm));
