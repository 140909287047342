// if we hit any performance issues can be refactored
export const deepclone = (obj) => {
  let copy;

  if (null == obj || 'object' !== typeof obj) {
    return obj;
  };

  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  if (obj instanceof Array) {
    copy = [];
    let i;
    const len = obj.length;
    for (i = 0; i < len; i += 1) {
      copy[i] = deepclone(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    copy = {};
    for (const attr of Object.keys(obj)) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = deepclone(obj[attr]);
      }
    }
    return copy;
  }

};
