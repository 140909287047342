import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';

import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: Intl;
};

const { resourceName } = API_RESOURCE.EMAIL;
const formName = REDUX_FORM.CREATE_EMAIL;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceName} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.email.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName: API_RESOURCE.EMAIL.resourceName,
        methods: ['POST']
      }, {
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['GET']
      }, {
        resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
        methods: ['GET']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
