import { HostingTypes } from 'sg-styleguide/lib/utils';
import { call, put, select, takeLatest, take, race } from 'redux-saga/effects';
import { clientApi } from '../../api/client';

import {
  GET_BACKUP_ORDER_DATA,
  CREATE_BACKUP,
  BACKUP_ORDER_DATA_LOADED,
  CONFIRM_BACKUP_UPGRADE,
  CANCEL_BACKUP_UPGRADE
} from '../../constants/actions';

import handleAvalonApiRequest from '../handle-avalon-api-request';
import { getCurrentSiteId, getCurrentSiteAccountId } from '../../selectors';
import { createItem } from '../../actions/crud';
import * as backupActions from '../../actions/pages/backup';
import { navigateToUA } from '../../actions/nemo-store';
import * as siteMetaApiSelectors from '../../selectors/site-meta-api';
import { getToolMainEndpoint } from '../../selectors/static-api';
import { updateSiteMetaApi } from '../site-meta-api';
import { handleNemoApiRequest } from '../handle-nemo-api-request';
import customRequestTypes from '../../constants/custom-request-types';

function* singleBackupOptions({ siteId }) {
  const { session } = yield select();
  return yield call(clientApi(
    `/order_backup/singlebackupoptions/${siteId}=?_client_token=${session.clientToken}`, 'GET'
  ));
}

function* getBackupOrderData(action) {
  const state = yield select();
  const siteId = getCurrentSiteId(state);

  const response = yield call(handleNemoApiRequest(singleBackupOptions, action), { siteId });

  if (response) {
    yield put(backupActions.backupOrderDataLoaded(response.data));
  }
}

const NEED_UPGRADE_PLAN_TYPES = [HostingTypes.START_UP];
const NO_NEED_UPGRADE_PLAN_TYPES = [HostingTypes.GROW_BIG, HostingTypes.GO_GEEK, HostingTypes.CLOUD];

function* createBackup(action) {
  const { payload } = action;

  const {
    onUpgradeRequired,
    createFormData,
    onUANotAvailable
  } = payload;

  yield put(backupActions.getBackupOrderData());

  const backupOrderResponse = yield take(BACKUP_ORDER_DATA_LOADED);
  const { current_plan } = backupOrderResponse.data;

  yield call(updateSiteMetaApi, { requestTypeName: customRequestTypes.BACKUP_FETCH_SITE_META_API });

  const state = yield select();
  const siteId = getCurrentSiteId(state);
  const availableSlots = siteMetaApiSelectors.getAvailableSlots(state);
  const userHasAvailableSlots: boolean = availableSlots > 0;

  switch (true) {
    case NO_NEED_UPGRADE_PLAN_TYPES.includes(current_plan):
      if (userHasAvailableSlots) {
        yield put(createItem(createFormData));
        return;
      }
    case NEED_UPGRADE_PLAN_TYPES.includes(current_plan):
      if (userHasAvailableSlots) {
        yield put(createItem(createFormData));
        return;
      }

      const isUAAvailable = yield select(
        siteMetaApiSelectors.isACLActionEnabled,
        getToolMainEndpoint('backup-restore'),
        'can_order'
      );

      if (!isUAAvailable) {
        return onUANotAvailable();
      }

      // no available slots
      onUpgradeRequired(backupOrderResponse.data);

      const { confirmed, rejected } = yield race({
        confirmed: take(CONFIRM_BACKUP_UPGRADE),
        rejected: take(CANCEL_BACKUP_UPGRADE)
      });

      if (confirmed) {
        const { planType } = confirmed.payload;
        const accountId = yield select(getCurrentSiteAccountId);

        yield put(
          navigateToUA({
            page: 'backup-order',
            siteId,
            planType,
            accountId
          })
        );
      }

      return;
    default:
      throw new Error(`Unknown plan type: ${current_plan}`);
  }
}

function* backup(): any {
  yield takeLatest(GET_BACKUP_ORDER_DATA, getBackupOrderData);
  yield takeLatest(CREATE_BACKUP, createBackup);
}

export default backup;
