import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { copyToClipboard, Flex, Grid, Link, Section, Text, Textarea } from 'sg-styleguide';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import ROUTES from '../../../core/constants/routes';
import { RootState } from '../../../core/reducers';
import { findAppNameByType } from '../../../core/utils/app-names';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create';

type Props = {
  items: {
    app: any[];
    appRepo: any[];
  };
  actions: {
    createItem: CreateItem;
    deleteItem: DeleteItem;
  };
  createNotification: Function;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
  locationSearch: string;
  router: {
    push: Function;
  },
  dialogGitViewRepo: any;
};

type State = {
  deleteDialogPayload: any;
};

class Git extends React.Component<Props, State> {
  readonly state = {
    deleteDialogPayload: null
  };

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  onCreateFormSubmit = (formData) => {
    const { app = [] } = this.props.items;
    const selectedApp = app.find((a) => a.id === formData.id);
    const domain = selectedApp && selectedApp.app_url;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_GIT,
          success: {
            intlKey: 'translate.page.git.create.success-create.notification',
            intlValues: { domain }
          },
          error: {
            intlKey: 'translate.page.git.create.fail-create.notification',
            intlValues: { domain }
          }
        }
      }
    });

  };

  goToSSH = () => this.props.router.push(`${ROUTES.SSH}${this.props.locationSearch}`);

  renderDeleteDialog() {
    const { intl } = this.props;
    const { deleteDialogPayload } = this.state;
    const domain = deleteDialogPayload && deleteDialogPayload.app_url;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.git.delete.dialog.title' }, { domain })}
        icon="destroy"
        onSubmit={() => this.props.actions.deleteItem({
          itemId: deleteDialogPayload.id,
          _metaFields: {
            ...API_RESOURCE.APP_REPO
          },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.git.delete.success.message',
                intlValues: { domain }
              },
              error: {
                intlKey: 'translate.page.git.delete.error.message',
                intlValues: { domain }
              }
            }
          }
        })}
      />
    );
  }

  renderViewGitDialog() {
    const { intl, dialogGitViewRepo } = this.props;
    const domain = dialogGitViewRepo.app_url;
    const gitURL = dialogGitViewRepo.git_url;
    const ignoredFiles = dialogGitViewRepo.ignored_repo_files ? dialogGitViewRepo.ignored_repo_files : [];

    return (
      <SGDialog
        id={DIALOGS.GIT_VIEW_REPO}
        state="warning"
        icon="file-outlined"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.git.view.repo.dialog.title' }, { domain })}
        footer={(
          <SGDialogCancel
            id={DIALOGS.GIT_VIEW_REPO}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        )}
        resources={[
          { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.APP_REPO.resourceName, methods: ['PUT'] }
        ]}
      >
        <Grid>
          <Textarea
            readOnly
            label={
              <Flex align="center" justify="space-between" margin="none" gutter="none">
                {intl.formatMessage({ id: 'translate.page.git.view.repo.clone.label' })}

                <Text color="secondary" onClick={() => this.handleCopyToClipboard(gitURL)}>
                  {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                </Text>
              </Flex>
            }
            value={gitURL}
          />

          {
            ignoredFiles.length > 0 && (
              <Textarea
                readOnly
                label={intl.formatMessage({ id: 'translate.page.git.view.repo.excluded.label' })}
              >
                {ignoredFiles.join('\n')}
              </Textarea>
            )
          }

          <Text align="left">
            <FormattedMessage
              id="translate.page.git.view.repo.ssh.key.message"
              values={{
                link: (
                  <Link onClick={this.goToSSH}>
                    <FormattedMessage id="translate.page.git.view.repo.ssh.key.link.text" />
                  </Link>
                )
              }}
            />
          </Text>
        </Grid>
      </SGDialog>
    );
  }

  renderContextMenu = (cell, entity) => {
    const { openSGDialog, intl } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.APP_REPO.resourceName}
        items={[{
          vcsMethod: 'GET',
          label: intl.formatMessage({ id: 'translate.page.git.sg-table.action.view' }),
          e2eAttr: 'git-context-menu-item-view',
          icon: 'file-outlined',
          onClick: () => this.setState({}, () => openSGDialog(DIALOGS.GIT_VIEW_REPO, entity))
        }, {
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.page.git.sg-table.action.delete' }),
          e2eAttr: 'git-context-menu-item-delete',
          icon: 'destroy',
          onClick: () => this.setState({
            deleteDialogPayload: { ...entity }
          }, () => openSGDialog(DIALOGS.GENERIC_DELETE))
        }]}
      />
    );
  };

  render() {
    const { intl, items, actions } = this.props;
    const { appRepo = [], app = [] } = items;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-git"
          title={intl.formatMessage({ id: 'translate.page.git.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.git.info' })}
        />

        <Section>
          <Grid>
            <VCS resourceName={API_RESOURCE.APP_REPO.resourceNameMetaApi} hasMethods={['POST']}>
              <CreateBox>
                <CreateForm
                  app={app}
                  onSubmit={this.onCreateFormSubmit}
                />
              </CreateBox>
            </VCS>

            <SGTable
              title={intl.formatMessage({ id: 'translate.page.git.table.title' })}
              resources={[{
                resourceName: API_RESOURCE.APP_REPO.resourceName,
                methods: ['GET']
              }]}
              data={appRepo.filter((a) => a.have_git_repo)}
              columns={[{
                header: intl.formatMessage({ id: 'translate.page.git.sg-table-head-site.title' }),
                accessor: 'app_url'
              }, {
                header: intl.formatMessage({ id: 'translate.page.git.sg-table-head-application.title' }),
                accessor: 'app',
                render: (appType: string) => findAppNameByType(appType)
              }, {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'app',
                render: this.renderContextMenu
              }]}
              noDataMessage="translate.page.git.no-table-data.text"
            />
          </Grid>
        </Section>

        {this.renderDeleteDialog()}
        {this.renderViewGitDialog()}
      </React.Fragment>
    );
  }
}

export default indexWithCRUD(
  (store: RootState) => ({
    app: API_RESOURCE.APP.resourceName,
    locationSearch: store.routing.locationBeforeTransitions.search,
    dialogGitViewRepo: store.dialog[DIALOGS.GIT_VIEW_REPO] || {}
  }),
  (dispatch) => ({
    createNotification: (payload) => dispatch(createNotification(payload)),
    openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
    closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
  })
)(
  Git,
  API_RESOURCE.APP,
  API_RESOURCE.APP_REPO
);
