import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Text } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';
import { CREATE_BOX_STATES } from '../constants';

type Props = {
  children: any;
  intl?: Intl;
  selectedTab: string;
  renderBeforeBoxChildren: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.SSL;

export const FORM_NAME_BY_TAB_ID = {
  [CREATE_BOX_STATES.INSTALL]: REDUX_FORM.CREATE_INSTALL_SSL,
  [CREATE_BOX_STATES.IMPORT]: REDUX_FORM.CREATE_SSL_IMPORT
};

const CreateContainer = ({
  children,
  intl,
  selectedTab,
  renderBeforeBoxChildren
}: Props) => {
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.ssl.create.title' })}
        resourceName={resourceName}
        formName={FORM_NAME_BY_TAB_ID[selectedTab]}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        renderCustomNotification={({ notification, renderDefaultNotificationTemplate }) => {
          if (notification.state === 'error') {
            return (
              <Grid>
                <Text size="large" align="center">
                  {notification.responseError.message}
                </Text>

                <div style={{ textAlign: 'center' }}>
                  {renderDefaultNotificationTemplate()}
                </div>
              </Grid>
            );
          }

          return renderDefaultNotificationTemplate();
        }}
        resources={[{
          resourceName: API_RESOURCE.SSL.resourceName,
          methods: ['POST']
        }, {
          resourceName: API_RESOURCE.SSL_LE.resourceName,
          methods: ['POST']
        }, {
          resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
          methods: ['GET']
        }]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer);
