import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Spacer, Table } from 'sg-styleguide';
import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog } from '../../../containers/sg-dialog';
import Entity from '../content/entity';

const CodeEditorCloseTabsConfirmationDialog = ({ files = [], intl, closeSGDialog, handleSave, handleDontSave }) => {
  const columns = [{
    header: 'File Name',
    accessor: 'file',
    render: (file, entity) => {
      return (
        <Entity entity={entity} />
      );
    }
  }];

  return (
    <SGDialog
      id={DIALOGS.FILE_MANAGER_CONFIRM_CLOSE}
      density="none"
      title={intl.formatMessage({ id: 'translate.file.manager.code.editor.close.tab.title' })}
      icon="cross"
      state="inactive"
      footer={(
        <div style={{ display: 'inherit', width: '100%' }}>
          <Button
            data-e2e="footer-button-no-save"
            onClick={() => {
              closeSGDialog();
              handleDontSave();
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.dont.save' })}
          </Button>
          <Spacer />

          <Button
            data-e2e="footer-button-close-dialog"
            onClick={closeSGDialog}
          >
            {intl.formatMessage({ id: 'translate.generic.close' })}
          </Button>

          <Button
            data-e2e="footer-button-save"
            color="primary"
            onClick={(e) => {
              handleSave();
              closeSGDialog();
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.save' })}
          </Button>
        </div>
      )}
    >
      <Table columns={columns} data={files} density="large" mobileLayout="row" />
    </SGDialog>
  );
};

export default injectIntl(CodeEditorCloseTabsConfirmationDialog);
