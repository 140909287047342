import * as React from 'react';

import { Icon } from 'sg-styleguide';

import './information-icon.scss';

const InformationIcon = (props) => {
  return (
    <Icon name="information" className="info-icon" size="12" {...props} />
  );
};

export default InformationIcon;
