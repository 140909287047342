import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState = {
  clientRefreshToken: null,
  clientToken: null,
  currentSiteId: null,
  hasError: false,
  isLoading: false,
  siteTokens: {},
  user: {}
};

const reducerMap: any = {
  [Actions.STORE_SESSION_DATA]: (state, { payload }) => {
    const { session } = payload;

    return {
      ...state,
      clientRefreshToken: session.data.refresh_token,
      clientToken: session.data.client_token,
      hasError: false,
      isLoading: false,
      user: session.data.profile || {}
    };
  },

  [Actions.REFRESH_CLIENT_TOKEN_SUCCEEDED]: (state, action: { session: APIResponseClientToken }) => ({
    ...state,
    clientToken: action.session.data.client_token
  }),

  [Actions.LOGIN_FAILED]: (state) => {
    return Object.assign({}, state, {
      hasError: true,
      isLoading: false
    });
  },

  [Actions.LOGOUT]: (state) => {
    return Object.assign({}, state, {
      clientRefreshToken: null,
      clientToken: null,
      hasError: false,
      isLoading: false,
      siteTokens: {},
      user: {}
    });
  },

  [Actions.REFRESH_SITE_TOKEN_SUCCEEDED]: (state, { payload }) => {
    const { siteId } = payload;
    const token: APIResponseSiteToken = payload.siteToken;

    return Object.assign({}, state, {
      siteTokens: {
        [siteId]: {
          localSiteId: token.data.local_site_id,
          siteToken: token.data.site_token
        }
      }
    });
  }
};

export default handleActions<any, any>(reducerMap, initialState);
