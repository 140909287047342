import * as React from 'react';
import { API_RESOURCE } from '../../../core/constants/api';
import indexWithCRUD from '../../components/indexWithCRUD';
import PartialLoader from '../../containers/partial-loader';
import Cloudflare from './cloudflare';
import './cloudflare.scss';
import LandingPage from './landing-page';

type CloudflareProps = {
  items: {
    cloudflare?: any[];
  };
};

class CloudflarePage extends React.Component<CloudflareProps, any> {
  render() {
    const { cloudflare } = this.props.items;
    let contentToRender = null;

    if (cloudflare) {
      contentToRender = cloudflare.length > 0 ? <Cloudflare /> : <LandingPage />;
    }

    return (
      <PartialLoader
        showOnlyLoader
        resources={[
          { resourceName: API_RESOURCE.CLOUDFLARE.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName, methods: ['GET'] }
        ]}
      >
        {contentToRender}
      </PartialLoader>
    );
  };
}

export default indexWithCRUD()(
  CloudflarePage,
  API_RESOURCE.CLOUDFLARE,
  API_RESOURCE.CLOUDFLARE_ZONE_PENDING,
  API_RESOURCE.CLOUDFLARE_ZONE,
  API_RESOURCE.DOMAIN,
  API_RESOURCE.DOMAIN_ALIAS
);
