import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid, Flex, Notice, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import { API_RESOURCE } from '../../../core/constants/api';
import FormCheckbox from '../../components/form-checkbox';
import { withSpanelForm } from '../../containers/sg-spanel-forms';

type Props = {
  intl: Intl;
};

const formName = REDUX_FORM.DELETE_INSTALLED_WP_APP;

const DeleteWpForm = ({ intl }: Props) => (
  <Grid>
    <Notice
      type="error"
      background="light"
      border={false}
      shadow={false}
    >
      {intl.formatMessage({ id: 'translate.page.wp.manage.delete.notice' })}
    </Notice>

    <Flex direction="column" gutter="none" margin="none" style={{ textAlign: 'start' }}>
      <Field
        name="keep_db"
        component={FormCheckbox}
      >
        {intl.formatMessage({ id: 'translate.page.wp.manage.delete.database.checkbox' })}
      </Field>

      <Field
        name="full_delete"
        component={FormCheckbox}
      >
        {intl.formatMessage({ id: 'translate.page.wp.manage.delete.staging.checkbox' })}
      </Field>
    </Flex>
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({
      form: formName,
      initialValues: {
        fetchAfterDelete: {
          ...API_RESOURCE.APP
        },
        _metaFields: {
          ...API_RESOURCE.WORDPRESS,
          formName
        }
      }
    })(DeleteWpForm)
  )
);
