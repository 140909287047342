import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Flex, FormLabel, Text, Tile } from 'sg-styleguide';
import { DIALOGS } from '../../../core/constants/common';
import { generatePathTruncationParts } from '../../../core/utils/paths';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';

type Props = {
  items: string[];
  onSubmit: Function;
  onCloseHandler: () => void;
  intl: Intl;
};

type State = {
  items: {
    [path: string]: boolean;
  }
};

export const initialState = {
  items: {}
};

const FilesSelector = ({ items, onToggleItem, className = '' }) => (
  <Tile
    className={className}
    padding={['x-small', 'medium']}
    style={{ maxHeight: '200px', overflowY: 'auto' }}
  >
    {
      items && items.map((path: string) => {
        const pathParts: string[] = generatePathTruncationParts(path);

        return (
          <div key={`select-file-${path}`}>
            <Checkbox
              style={{ width: '100%' }}
              align="center"
              onChange={(e) => onToggleItem(path, e.target.checked)}
            >
              <Flex wrap="nowrap" gutter="none" margin="none">
                <div>
                  <Text align="left" truncate>
                    {pathParts[0]}
                  </Text>
                </div>
                <Text align="left" style={{ minWidth: 'auto' }}>
                  {pathParts[1]}
                </Text>
              </Flex>
            </Checkbox>
          </div>
        );
      })
    }
  </Tile>
);

class IncludeFilesSelector extends React.Component<Props, State> {
  readonly state: State = initialState;

  onToggleItem = (path: string, state) => this.setState({
    items: {
      ...this.state.items,
      [path]: state
    }
  });

  getSelectedFiles = (): string[] =>
    Object
      .keys(this.state.items)
      .filter((path: string) => Boolean(this.state.items[path]));

  render() {
    const { items, onSubmit, onCloseHandler, intl } = this.props;

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_INCLUDE_FILES}
        icon="new-staging"
        size="large"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.staging.dialog.include-files.title' })}
        subTitle={intl.formatMessage({ id: 'translate.page.staging.dialog.include-files.message' })}
        onCloseHandler={() => this.setState({ ...initialState }, onCloseHandler)}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.WP_STAGING_INCLUDE_FILES} />
            <Button
              color="primary"
              onClick={() => onSubmit(this.getSelectedFiles())}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
      >
        <div style={{ width: '100%' }}>
          <FormLabel>
            {intl.formatMessage({ id: 'translate.page.staging.dialog.include-files.box-label' })}
          </FormLabel>
          <FilesSelector
            items={items}
            onToggleItem={this.onToggleItem}
          />
        </div>
      </SGDialog>
    );
  }
}

export default injectIntl(IncludeFilesSelector);
