import * as React from 'react';
import { Container, DatePicker, Dropdown, Flex, Grid, Link, Table } from 'sg-styleguide';
import { StatsType } from '../../../../core/definitions/stats';
import withLoadMore from '../../../containers/with-load-more';
import SGTable from '../../../components/sg-table';

type Props = {
  domain: string;
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => any;
  renderDatepicker: Function;
  // with load more
  onLoadMore: (id: string) => any;
  getAllLoaded: (id: string, items: any[]) => any[];
  shouldShowLoadMore: (id: string, items: any[]) => boolean;
  intl: Intl;
};

type State = {
  selectedSource: string;
};

const SOURCES_LOAD_MORE = {
  pages: 'pages',
  errors: 'errors'
};

const LOAD_MORE_SETTINGS = {
  loadStep: 10,
  initialLength: 10
};

const ERRORS_MAPPING = {
  400: '400 Bad Request',
  401: '401 Unauthorized',
  402: '402 Payment Required',
  403: '403 Forbidden',
  404: '404 Not Found',
  405: '405 Method Not Allowed',
  406: '406 Not Acceptable',
  500: '407 Proxy Authentication Required',
  501: '501 Not Implemented',
  502: '502 Bad Gateway',
  503: '503 Service Unavailable',
  504: '504 Gateway Timeout',
  505: '505 HTTP Version Not Supported',
  506: '506 Variant Also Negotiates',
  507: '507 Insufficient Storage',
  508: '508 Loop Detected',
  510: '510 Not Extended',
  511: '511 Network Authentication Required'
};

class Behaviour extends React.Component<Props, State> {
  readonly state = {
    selectedSource: 'pages'
  };

  renderContent() {
    const {
      domain,
      getStats,
      onLoadMore,
      getAllLoaded,
      shouldShowLoadMore,
      intl
    } = this.props;

    const { selectedSource } = this.state;

    const pagesStats = getStats('pages');
    const errorsStats = getStats('errors');

    return (
      <React.Fragment>
        {
          selectedSource === 'pages' ? (
            <Table
              border="small"
              mobileLayout="row"
              showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.pages, pagesStats)}
              onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.pages)}
              data={getAllLoaded(SOURCES_LOAD_MORE.pages, pagesStats)}
              columns={[{
                header: intl.formatMessage({ id: 'translate.page.stats.page.label' }),
                accessor: 'url',
                render: (url) => (
                  <Link href={`//${domain}${url}`} target="_blank">
                    {`${domain}${url}`}
                  </Link>
                )
              }, {
                header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                accessor: 'pages',
                style: { textAlign: 'right' }
              }]}
            />
          ) : (
            <Table
              border="small"
              mobileLayout="row"
              showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.errors, errorsStats)}
              onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.errors)}
              data={getAllLoaded(SOURCES_LOAD_MORE.errors, errorsStats)}
              columns={[{
                header: intl.formatMessage({ id: 'translate.page.stats.code.label' }),
                accessor: 'error',
                render: (errCode) => ERRORS_MAPPING[errCode] || errCode
              }, {
                header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                accessor: 'hits',
                style: { textAlign: 'right' }
              }]}
            />
          )
        }
      </React.Fragment>
    );
  }

  render() {
    const { renderDatepicker, intl, statsMissing } = this.props;
    const { selectedSource } = this.state;

    const sourceOptions = [
      { label: intl.formatMessage({ id: 'translate.page.stats.most-visited-pages' }), value: 'pages' },
      { label: intl.formatMessage({ id: 'translate.page.stats.http-status-codes' }), value: 'errors' }
    ];

    return (
      <Grid gap="x-large">
        <Flex gutter="none">
          <Grid sm="4">
            {renderDatepicker()}
            <Dropdown
              label={intl.formatMessage({ id: 'translate.page.stats.select-source.label' })}
              size="small"
              options={sourceOptions}
              selectedValue={selectedSource}
              optionValue="value"
              optionLabel="label"
              onChange={(source) => this.setState({ selectedSource: source })}
            />
          </Grid>
        </Flex>
        {statsMissing ? (
          <SGTable
            data={[]}
            shadow={false}
            noDataMessage="translate.page.stats.no-stats.placeholder.message"
          />
        ) : this.renderContent()}
      </Grid>
    );
  }
}

export default withLoadMore(
  Behaviour, {
    id: SOURCES_LOAD_MORE.errors,
    ...LOAD_MORE_SETTINGS
  }, {
    id: SOURCES_LOAD_MORE.pages,
    ...LOAD_MORE_SETTINGS
  }
);
