import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Icon, Text } from 'sg-styleguide/';

import * as fileManagerActions from '../core/actions/file-manager';
import { RootState } from '../../../../core/reducers';
import { getEntityPath, getEntityReadableName } from '../core/utils';

import './breadcrumbs.scss';

class Breadcrumb extends React.Component<any, any> {
  getBreadcrumbPath() {
    const { selectedNavigationEntity, selectedContentEntities } = this.props;

    if (!selectedNavigationEntity) {
      return null;
    }

    const entityToUse = selectedContentEntities.length === 1 ? selectedContentEntities[0] : selectedNavigationEntity;
    return getEntityPath(entityToUse);
  }

  getBreadcrumbEntities() {
    const { entities } = this.props;
    const breadcrumbPath = this.getBreadcrumbPath();

    if (!breadcrumbPath) {
      return [];
    }

    return breadcrumbPath
      .split('/')
      .map((entityName, index, breadcrumbArray) => {
        const entityParentPath = breadcrumbArray.slice(0, index).join('/') || '/';
        const entityParentItems = entities[entityParentPath];
        const breadcrumbEntityPath = `${entityParentPath}/${entityName}`.replace('//', '/');

        return entityParentItems.find((entity) => getEntityPath(entity) === breadcrumbEntityPath);
      })
      .filter(Boolean);
  }

  render() {
    const breadcrumbEntities = this.getBreadcrumbEntities();

    return (
      <div className="breadcrumbs">
        {breadcrumbEntities.map((entity, index, array) => (
          <div className="breadcrumb" key={index} data-e2e="breadcrumb-entity-item">
            <Text
              color="light"
              size="small"
              weight="bold"
              truncate
              onClick={() => this.props.actions.onEntityClick({ entity })}
            >
              {getEntityReadableName(entity)}
            </Text>

            {index !== array.length - 1 && (
              <Icon
                name="arrow-right"
                size="10"
                className="breadcrumb__icon"
                onClick={() => this.props.actions.onEntityClick({ entity })}
              />
            )}
          </div>
        ))
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  entities: state.fileManager.entities,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Breadcrumb);
