import { delay } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { siteApi } from '../../../../../core/api/site';
import { xhrSiteApi } from '../../../../../core/api/xhr-site';
import * as BaseConstantsForActions from '../../../../../core/constants/actions';
import handleAvalonApiRequest from '../../../../../core/sagas/handle-avalon-api-request';
import { fetchDirs, transferSizeChange } from '../actions/file-manager';
import * as FileManagerConstants from '../constants/actions';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../constants/common';
import {
  getEntityByPath,
  getEntityInfoNumber,
  getEntityParentPath,
  getEntityPath,
  getEntityType,
  getParentPath,
  isEntityKnownInCodeEditor
} from '../utils';

const Constants = { ...BaseConstantsForActions, ...FileManagerConstants };

interface UploadFileArgs {
  payload: {
    urlParams: {
      filename: string;
    },
    file: any,
    _meta: any
  };
}

interface FileManagerPostRequestArgs {
  payload: {
    body: object
    clearFileManagerStore: object
    endpoint: string;
    urlParams: {
      filename: string;
      entries: any[],
      dest: string
    };
    entity?: any;
  };
}

function* uploadFileSaga(action: UploadFileArgs) {
  const state = yield select();
  const { urlParams, file, _meta } = action.payload;
  const formData = new FormData();
  try {
    formData.append('file', new File([file], _meta.name));
  } catch (e) {
    // Edge 16 ... but not IE 11 ...
    formData.append('file', new Blob([file]));
  }

  const request = yield call(xhrSiteApi({
    endpoint: '/file',
    method: 'POST',
    body: formData,
    state,
    urlParams
  }));

  const pending = { ...action.payload, close: request.close };
  yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_PENDING, payload: pending });

  while (true) {
    const { progress = 0, transferred = 0, err, response } = yield take(request);

    if (err) {
      yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_FAILED, payload: action.payload });
      return;
    }

    if (response) {
      yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_SUCCEEDED, payload: action.payload });

      // TODO check with Niki what will be the final behavior when file is uploaded.
      // Currently this request is blocking the next chunk of files.
      yield put({
        type: Constants.FILE_MANAGER_FETCH_DIR_REQUESTED,
        payload: {
          urlParams: {
            id: getEntityPath(state.fileManager.selectedNavigationEntity)
          }
        }
      });

      return;
    }

    // TODO maybe i will need the progress for single file 'GET'
    yield put(transferSizeChange({ transferred, progress }));
  }
}

function* parallelUploadFileSaga(action) {
  const type = action.type;
  const files = action.payload;

  yield files.map((file) => call(uploadFileSaga, { type, payload: file }));
}

function* sequentialUploadFileSaga(action) {
  const type = action.type;
  let state = yield select();

  while (state.fileManager.uploader.requested.length > 0) {
    yield call(parallelUploadFileSaga, {
      type,
      payload: state.fileManager.uploader.requested.slice(0, 2)
    });

    // Makes sure that the store is updated when the user cancels the requests.
    yield delay(500);
    state = yield select();
  }
}

function* saveFileSaga(action) {
  const state = yield select();
  const { entity, urlParams, file, _meta, autoOpen, autoClose } = action.payload;
  const formData = new FormData();

  try {
    formData.append('file', new File([file], _meta.name));
  } catch (e) {
    // Edge 16 ... but not IE 11 ...
    formData.append('file', new Blob([file]));
  }

  const response = yield call(siteApi({
    endpoint: '/file',
    method: 'POST',
    body: formData,
    state,
    urlParams,
    disableBodyStringify: true
  }));

  if (entity) {
    yield put({
      type: Constants.FILE_MANAGER_SAVE_FILE_SUCCEEDED,
      payload: { entity }
    });
  }

  const newFileParentPath = urlParams.filename.substr(0, urlParams.filename.lastIndexOf('/')) || '/';
  yield put({
    type: Constants.FILE_MANAGER_FETCH_DIR_REQUESTED,
    payload: {
      urlParams: {
        id: entity ? getEntityParentPath(entity) : newFileParentPath
      }
    }
  });

  const selectedNavigationEntity = state.fileManager.selectedNavigationEntity;
  if (getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    yield put({
      type: Constants.FILE_MANAGER_FETCH_DIR_REQUESTED,
      payload: {
        urlParams: {
          id: getEntityPath(selectedNavigationEntity)
        }
      }
    });
  }

  yield take(Constants.FILE_MANAGER_FETCH_DIR_SUCCEEDED);

  const canOpenInCodeEditor = isEntityKnownInCodeEditor(urlParams.filename);
  if (autoOpen && canOpenInCodeEditor) {
    const newState = yield select();
    yield put({
      type: Constants.FILE_MANAGER_FETCH_FILE_REQUESTED,
      payload: {
        urlParams,
        entity: getEntityByPath(urlParams.filename, newState.fileManager)
      }
    });
  }

  if (autoOpen && !canOpenInCodeEditor) {
    yield put({
      type: Constants.FILE_MANAGER_SET_MESSAGE_DIALOG_CONTENT,
      payload: {
        title: 'translate.file.manager.file.created.but.not.allowed.for.editing'
      }
    });
  }

  if (autoClose) {
    yield put({
      type: Constants.FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE,
      payload: { ...entity }
    });
  }

  return response;
}

function* fileManagerPostRequestSaga(action: FileManagerPostRequestArgs) {
  const state = yield select();
  const { body = {}, endpoint, urlParams, entity, clearFileManagerStore = {} } = action.payload;

  const response = yield call(siteApi({
    endpoint,
    method: 'POST',
    body: {
      ...body,
      ...urlParams
    },
    state
  }));

  yield put({
    type: Constants.FILE_MANAGER_CLEAR_STORE_PROPERTIES,
    payload: clearFileManagerStore
  });

  if (endpoint === Constants.FILE_MANGER_API_DIR_MOVE) {
    yield put({
      type: Constants.FILE_MANAGER_UPDATE_AFTER_MOVE,
      payload: {
        oldInfoNumber: getEntityInfoNumber(entity),
        newInfoNumber: response.data.i
      }
    });
  }

  /**
   * Update FM folders
   */
  const selectedNavigationEntity = state.fileManager.selectedNavigationEntity;
  const responseData = response.data;

  let entityPath;
  if (entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    entityPath = getEntityPath(entity);
  }

  let entityParentPath;
  if (entity) {
    entityParentPath = getEntityParentPath(entity);
  }

  let selectedNavigationEntityPath;
  if (selectedNavigationEntity && getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    selectedNavigationEntityPath = getEntityPath(selectedNavigationEntity);
  }

  let selectedNavigationEntityParentPath;
  if (selectedNavigationEntity) {
    selectedNavigationEntityParentPath = getEntityParentPath(selectedNavigationEntity);
  }

  const responseEntityPath = responseData && responseData.id;
  const responseEntity = responseEntityPath && getEntityByPath(responseEntityPath, state.fileManager);
  const isResponseEntityFile = responseEntity && getEntityType(responseEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;

  const uniquePaths = new Set([
    entityPath,
    entityParentPath,
    selectedNavigationEntityPath,
    selectedNavigationEntityParentPath,
    !isResponseEntityFile && responseEntityPath,
    responseEntity && getParentPath(responseEntityPath)
  ].filter(Boolean));

  yield put(fetchDirs({
    entries: Array.from(uniquePaths)
  }));

  return response;
}

function* postRequest(): any {
  yield takeEvery(Constants.FILE_MANAGER_POST_REQUEST, handleAvalonApiRequest(fileManagerPostRequestSaga));
  yield takeEvery(Constants.FILE_MANAGER_SAVE_FILE, handleAvalonApiRequest(saveFileSaga));
  yield takeEvery(Constants.FILE_MANAGER_UPLOAD_FILES, handleAvalonApiRequest(sequentialUploadFileSaga));
}

export default postRequest;
