import { RootState } from '../reducers';
import { DOMAIN_TYPES } from '../constants/domains';
import { API_RESOURCE } from '../constants/api';

/* Domain data store selectors */
export const findMainDomain = (
  { pageItems }: RootState,
  resourceName = API_RESOURCE.DOMAIN.resourceName
) => pageItems[resourceName] ? pageItems[resourceName].find((domain) => domain.main === 1) : null;

export const filterStagingDomains = (
  { pageItems }: Partial<RootState>,
  resourceName = API_RESOURCE.DOMAIN.resourceName
) => pageItems[resourceName] ? pageItems[resourceName].filter(({ settings }) => settings && !settings.staging) : [];

export const getSpamExpertsDomains = ({ pageItems }: Partial<RootState>) => {
  const allDomains = pageItems[API_RESOURCE.DOMAIN_ALL.resourceName];
  const availableTypes = [DOMAIN_TYPES.main, DOMAIN_TYPES.alias];

  if (allDomains) {
    return allDomains.filter(({ settings, domain_type }) => Boolean(
      settings.se_enabled ||
      availableTypes.includes(domain_type)
    ));
  }

  return [];
};
