import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';

type Props = {
  domainName: string;
  intl: any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => null;
};

const formName = REDUX_FORM.CREATE_SUB_DOMAIN;

class Form extends React.Component<Props, {}> {
  validateSubDomain = (name, fields, formProps, fieldName) => {
    const { domainName, validationUtils } = this.props;

    return validationUtils.validationWithMetaApi(`${name}.${domainName}`, fields, formProps, fieldName);
  };

  render() {
    const { domainName, intl, validationUtils, sPanelFormSubmit } = this.props;
    const { required } = validationUtils;

    return (
      <SGForm onSubmit={sPanelFormSubmit(formName)}>
        <Column smSpan="6">
          <Field
            name="name"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            suffix={`.${domainName}`}
            normalize={(value) => value.toLowerCase()}
            validate={[required, this.validateSubDomain]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.create' })} />
        </Column>
      </SGForm>
    );
  }
}

const SubDomainForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.DOMAIN,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(SubDomainForm));
