import { handleActions } from 'redux-actions';
import * as Actions from '../../../../../core/constants/actions';

import { initialFileManagerState } from './utils/initial-state';

import CodeEditorReducer from './code-editor';
import ContextMenuReducer from './context-menu';
import DialogsReducer from './dialogs';
import FetchEntitiesReducer from './fetch-entities';
import NavigationReducer from './navigation';
import ResetReducer from './reset';
import OtherReducer from './others';
import UploadReducer from './upload';

export default handleActions({
  ...FetchEntitiesReducer,
  ...NavigationReducer,
  ...CodeEditorReducer,
  ...UploadReducer,
  ...DialogsReducer,
  ...OtherReducer,
  ...ResetReducer,
  ...ContextMenuReducer,
  [Actions.LOGOUT]: () => initialFileManagerState
}, initialFileManagerState);
