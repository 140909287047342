import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { clientApi } from '../api/client';
import { siteApi } from '../api/site';
import * as fetchActions from '../actions/fetch';
import * as ACTIONS from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';
import { fetchSiteMetaApi, fetchSiteMetaApiSucceeded } from '../actions/site-meta-api';
import handleAvalonApiRequest from './handle-avalon-api-request';

export function* handleUpdateSiteMetaApi(action) {
  const state = yield select();

  const siteMetaApi = yield call(siteApi({
    endpoint: '/endpoint',
    method: 'GET',
    state
  }));

  yield put(fetchSiteMetaApiSucceeded(siteMetaApi));
}

export function* updateSiteMetaApi({ requestTypeName }) {
  const requestType = requestTypeName || customRequestTypes.FETCH_SITE_META_API;
  yield put(fetchSiteMetaApi({ requestTypeName: requestType }));

  const { payload } = yield take(ACTIONS.FETCH_SITEMETAAPI_SUCCEEDED);
  return payload.siteMetaApi;
}

export default function* siteMetaApi() {
  yield takeLatest(ACTIONS.FETCH_SITEMETAAPI_REQUESTED, handleAvalonApiRequest(handleUpdateSiteMetaApi));
}
