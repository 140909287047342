import { call, select, takeEvery } from 'redux-saga/effects';
import { REQUEST_DATA, REQUEST_NEMO_DATA } from '../constants/actions';
import { siteApi } from './../api/site';
import { clientApi } from './../api/client';
import handleAvalonApiRequest from './handle-avalon-api-request';
import { handleNemoApiRequest } from './handle-nemo-api-request';

function* handleRequestData(action: RequestDataAction) {
  const state = yield select();
  const { endpoint } = action.payload._metaFields;
  const { urlParams } = action.payload;
  const method = action.payload._metaFields.method || 'POST';

  const response = yield call(siteApi({
    endpoint,
    method,
    body: action.payload,
    state,
    urlParams
  }));

  return response;
}

function* getNemoData({ endpoint, method, body }) {
  const { session } = yield select();

  const response = yield call(
    clientApi(`${endpoint}?_client_token=${session.clientToken}`, method, body)
  );

  return response;
}

function* handleRequestNemoData(action: RequestNemoDataAction) {
  const state = yield select();
  const { payload, onComplete } = action;
  const { endpoint, method, body } = payload;

  const response = yield call(
    handleNemoApiRequest(getNemoData, action as NemoRequestAction), { method, body, endpoint }
  );
}

function* genericRequests(): any {
  yield takeEvery(REQUEST_DATA, handleAvalonApiRequest(handleRequestData));
  yield takeEvery(REQUEST_NEMO_DATA, handleRequestNemoData);
}

export default genericRequests;
