import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import FormDropdown from '../form-dropdown';
import FormInput from '../form-input';
import SelectDirectory from '../../containers/dialogs/select-directory';
import { withSpanelForm } from '../../containers/sg-spanel-forms';
import { normalizeDirectoryData } from '../../../core/utils/paths';

type Props = {
  domain: any[];
  getFormValues?: Function;
  onSubmit: Function;
  openSGDialog?: Function;
  change?: Function;
  intl?: Intl;
};

const formName = REDUX_FORM.ADD_EXISTING_APP;
const DEFAULT_PATH = '/';

const AddExistingApplicationForm = ({ change, domain, intl, getFormValues, openSGDialog }: Props) => {
  const formValues = getFormValues(formName);
  const selectedDomainPath = formValues && domain && domain.find((d) => d.id === formValues.domain_id);
  const initialSelectDirectoryPath = selectedDomainPath && `/${selectedDomainPath.name}/public_html`;

  return (
    <Grid>
      <Field
        name="domain_id"
        label={intl.formatMessage({ id: 'translate.page.wp.manage.add.application.domain' })}
        options={domain}
        optionValue="id"
        optionLabel="name"
        onChange={() => change('path', DEFAULT_PATH)}
        component={FormDropdown}
      />

      <Field
        name="path"
        label={intl.formatMessage({ id: 'translate.page.wp.manage.add.application.path' })}
        normalize={(path) => path.charAt(0) === DEFAULT_PATH ? path : DEFAULT_PATH + path}
        suffix={
          <Button
            action="button"
            color="dark"
            size="small"
            disabled={!selectedDomainPath}
            onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY_ADD_APP)}
          >
            {intl.formatMessage({ id: 'translate.generic.browse' })}
          </Button>
        }
        component={FormInput}
      />

      <SelectDirectory
        id={DIALOGS.SELECT_DIRECTORY_ADD_APP}
        initialPath={initialSelectDirectoryPath}
        expandedNodes={[DEFAULT_PATH]}
        normalizeDirectoryData={normalizeDirectoryData}
        nodesParentFolder={{
          name: DEFAULT_PATH,
          path: DEFAULT_PATH,
          value: DEFAULT_PATH
        }}
        onChange={(path) => change('path', path.replace(initialSelectDirectoryPath, ''))}
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({
        form: formName,
        initialValues: {
          path: DEFAULT_PATH
        }
      })(AddExistingApplicationForm)
    )
  )
);
