import * as React from 'react';
import { Button, Grid, Link } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import ROUTES from '../../../../core/constants/routes';
import { generateEntitiesCountLabel, getDbEntityLabelByName } from '../../../../core/utils/db-entities';
import { formatCheckboxesData } from '../../../../core/utils/db-forms';
import { AddUserToDb, EditDbLabel, EditDbUser } from '../../../components/common-forms';
import DbGrantsList from '../../../components/db-grants-list';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../../containers/dialogs';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../containers/sg-dialog';
import VCS from '../../../containers/visibility-control-service';
import withRouteTo from '../../../containers/with-route-to';
import Create from './create';

type Props = {
  actions: CrudActions;
  items: any;
  location: any;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: any;
  currentDeletePayload: any;
  addUserPayload: any;
  editUserPayload: any;
  selectedDbPayload: any;
};

const RouteButton = withRouteTo(Button);

class DbMysqlDbPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeletePayload: null,
    addUserPayload: null,
    editUserPayload: null,
    selectedDbPayload: null
  };

  openManageUsersDialog = ({ id, name }) => {
    this.setState({
      selectedDbPayload: { id, name }
    }, () => this.props.openSGDialog(DIALOGS.LIST_DB_ENTITIES));
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_MYSQL_DATABASE,
          success: {
            intlKey: 'translate.page.database.created_msg'
          },
          error: {
            intlKey: 'translate.page.database.created.error.message'
          }
        }
      }
    });
  };

  render() {
    const { intl, items, openSGDialog } = this.props;
    const { dbMysqlGrant = [], dbMysqlDb = [] } = items;
    const databases = dbMysqlDb.map((database) => ({
      ...database,
      usersWithAccess: dbMysqlGrant.filter(({ db_id }) => db_id === database.id)
    }));

    return (
      <Grid>
        <Create
          onSubmit={this.onCreateFormSubmit}
          onAddUserToDatabase={(entity) => this.setState({
            addUserPayload: entity
          }, () => openSGDialog(REDUX_FORM.ADD_USER_TO_DB))}
        />

        <VCS resourceName={API_RESOURCE.MYSQL_DB.resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.database.list.title' })}
            data={databases}
            columns={[
              { header: intl.formatMessage({ id: 'translate.generic.name' }), accessor: 'name' },
              { header: intl.formatMessage({ id: 'translate.generic.label' }), accessor: 'label' },
              {
                header: intl.formatMessage({ id: 'translate.generic.users' }),
                accessor: 'id',
                render: (id, { name, usersWithAccess }) => ((
                  <Link
                    onClick={() => this.openManageUsersDialog({ id, name })}
                  >
                    {generateEntitiesCountLabel(
                      usersWithAccess,
                      intl.formatMessage({ id: 'translate.generic.user' }),
                      intl.formatMessage({ id: 'translate.generic.users' })
                    )}
                  </Link>
                ))
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'id',
                render: this.renderContextMenu
              }
            ]}
            resources={[{
              resourceName: API_RESOURCE.MYSQL_DB.resourceName,
              methods: ['GET']
            }, {
              resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
              methods: ['GET']
            }, {
              resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
              methods: ['GET']
            }]}
            noDataMessage="translate.page.database.sg-table.no-data.message"
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderDatabaseUsers()}
        {this.renderDatabaseAddUserComponent()}
        {this.renderDatabaseEditUserComponent()}
        {this.renderDeleteConfirmation()}
        {this.renderNoUsersDialog()}
      </Grid>
    );
  }

  renderDatabaseUsers = () => {
    const { intl, items, openSGDialog } = this.props;
    const { selectedDbPayload } = this.state;
    const dbId = selectedDbPayload && selectedDbPayload.id;
    const dbName = selectedDbPayload && selectedDbPayload.name;
    const grants = items && items.dbMysqlGrant || [];
    const users = grants.filter(({ db_id }) => db_id === dbId);

    return (
      <SGDialog
        id={DIALOGS.LIST_DB_ENTITIES}
        title={intl.formatMessage({ id: 'translate.page.mysql.users-list-dialog.title' }, { name: dbName })}
        size="x-large"
        density="none"
        icon="edit"
        state="warning"
        footer={
          <SGDialogCancel
            id={DIALOGS.LIST_DB_ENTITIES}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
      >
        <DbGrantsList
          entityType="user"
          entities={users}
          getLabelByName={(label) => getDbEntityLabelByName(label, items.dbMysqlUser)}
          renderContextMenu={(user) => (
            <TableContextMenu
              resourceName={API_RESOURCE.MYSQL_DB_GRANT.resourceNameMetaApi}
              items={[{
                vcsMethod: 'PUT',
                icon: 'product-server',
                label: intl.formatMessage({ id: 'translate.page.protected.user.manage.access' }),
                e2eAttr: 'table-action-provide-user',
                visibleOnDesktop: true,
                onClick: () => this.setState({
                  editUserPayload: {
                    grant: user,
                    db_id: user.db_id,
                    reversed: 1
                  }
                }, () => openSGDialog(REDUX_FORM.EDIT_DB_USER))
              }]}
            />
          )}
        />
      </SGDialog>
    );
  };

  renderUpdateComponent = () => {
    const { currentUpdatePayload } = this.state;
    const { intl, closeSGDialog } = this.props;
    const name = currentUpdatePayload && currentUpdatePayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_LABEL}
        title={intl.formatMessage({ id: 'translate.page.database.update.title' }, { name })}
        resources={[{
          resourceName: API_RESOURCE.MYSQL_DB.resourceName,
          methods: ['PUT']
        }]}
      >
        <EditDbLabel
          initialValues={currentUpdatePayload}
          onSubmit={(data) => this.props.actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.EDIT_DB_LABEL)
          )}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseAddUserComponent = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const { addUserPayload } = this.state;
    const name = addUserPayload && addUserPayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.ADD_USER_TO_DB}
        icon="product-server"
        title={intl.formatMessage({ id: 'translate.page.database.add.user.title' }, { name })}
        resources={[{
          resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
          methods: ['POST']
        }]}
      >
        <AddUserToDb
          rowData={addUserPayload}
          dbUsers={items[API_RESOURCE.MYSQL_DB_USER.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ checkboxes, dbuser_id }) => {
            actions.createItem({
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.database_access.add.user.success.message',
                    intlValues: { name }
                  },
                  error: {
                    intlKey: 'translate.page.database_access.add.user.error.message',
                    intlValues: { name }
                  }
                }
              },
              _metaFields: {
                ...API_RESOURCE.MYSQL_DB_GRANT
              },
              db_id: addUserPayload && addUserPayload.id,
              dbuser_id,
              grants: {
                perms: formatCheckboxesData(checkboxes)
              }
            }, () => closeSGDialog(REDUX_FORM.ADD_USER_TO_DB));
          }}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseEditUserComponent = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const grant = this.state.editUserPayload && this.state.editUserPayload.grant;
    const name = grant && grant.db_name;
    const userName = grant && grant.dbuser_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_USER}
        icon="profile"
        title={intl.formatMessage({ id: 'translate.page.database_user.edit_access_by_user' }, { name })}
        resources={[{
          resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
          methods: ['PUT', 'DELETE']
        }]}
      >
        <EditDbUser
          userData={this.state.editUserPayload}
          dbUsers={items[API_RESOURCE.MYSQL_DB_USER.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ grantForEdit, checkboxes }) => {
            const perms: string[] = formatCheckboxesData(checkboxes);

            if (perms.length > 0) {
              actions.updateItem({
                id: grantForEdit.id,
                db_id: grantForEdit.db_id,
                dbuser_id: grantForEdit.dbuser_id,
                grants: {
                  perms: formatCheckboxesData(checkboxes)
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.database_access.add.user.success.message',
                      intlValues: { name: userName }
                    },
                    error: {
                      intlKey: 'translate.page.database_access.add.user.error.message',
                      intlValues: { name: userName }
                    }
                  }
                },
                _metaFields: {
                  ...API_RESOURCE.MYSQL_DB_GRANT
                }
              }, () => closeSGDialog(REDUX_FORM.EDIT_DB_USER));
            } else {
              actions.deleteItem({
                ...grantForEdit,
                itemId: grantForEdit.id,
                _metaFields: { ...API_RESOURCE.MYSQL_DB_GRANT },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.database_access.add.user.success.message',
                      intlValues: { name }
                    },
                    error: {
                      intlKey: 'translate.page.database_access.add.user.error.message',
                      intlValues: { name }
                    }
                  }
                }
              }, () => closeSGDialog(REDUX_FORM.EDIT_DB_USER));
            }
          }}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConfirmation = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeletePayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.database.delete.dialog.title' }, { name: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderNoUsersDialog = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialog
        id={DIALOGS.MYSQL_NO_USERS}
        title={intl.formatMessage({ id: 'translate.page.database.no.users.title' })}
        icon="add-user"
        state="info"
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.MYSQL_NO_USERS}
              label={intl.formatMessage({ id: 'translate.generic.cancel' })}
            />

            <RouteButton
              color="primary"
              toPage={ROUTES.MYSQL_USER}
              onClick={() => closeSGDialog(DIALOGS.MYSQL_NO_USERS)}
            >
              {intl.formatMessage({ id: 'translate.page.database.create.button.create.user' })}
            </RouteButton>
          </React.Fragment>
        }
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const name = entity.name;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name,
      _metaFields: { ...API_RESOURCE.MYSQL_DB },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.delete.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.delete.error.message',
            intlValues: { name }
          }
        }
      }
    };

    const updatePayload: UpdateItemPayload = {
      _metaFields: { ...API_RESOURCE.MYSQL_DB },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.change.label.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.change.label.error.message',
            intlValues: { name }
          }
        }
      },
      ...entity
    };
    const addPayload = { ...entity };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.MYSQL_DB.resourceNameMetaApi}
        items={[{
          vcsMethod: 'POST',
          icon: 'add-user',
          label: intl.formatMessage({ id: 'translate.page.database.add.user' }),
          e2eAttr: 'table-action-add-user',
          visibleOnDesktop: true,
          onClick: () => {
            if (this.props.items.dbMysqlUser.length === 0) {
              openSGDialog(DIALOGS.MYSQL_NO_USERS);

              return;
            }

            if (entity.usersWithAccess.length === this.props.items.dbMysqlUser.length) {
              this.openManageUsersDialog({ id, name });

              return;
            }

            this.setState({ addUserPayload: addPayload }, () => openSGDialog(REDUX_FORM.ADD_USER_TO_DB));
          }
        }, {
          vcsMethod: 'PUT',
          icon: 'edit',
          label: intl.formatMessage({ id: 'translate.page.database.edit' }),
          e2eAttr: 'table-action-edit',
          onClick: () => this.setState(
            { currentUpdatePayload: updatePayload },
            () => openSGDialog(REDUX_FORM.EDIT_DB_LABEL)
          )
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          onClick: () => this.setState({
            currentDeletePayload: deletePayload
          }, () => openSGDialog(DIALOGS.GENERIC_DELETE))
        }]}
      />
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default indexWithCRUD(undefined, mapDispatchToProps)(
  DbMysqlDbPage,
  API_RESOURCE.MYSQL_DB,
  API_RESOURCE.MYSQL_DB_GRANT,
  API_RESOURCE.MYSQL_DB_USER
);
