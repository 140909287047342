import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Title } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { PAGE_NOTIFICATION_SUBTITLE_PROPS, REDUX_FORM } from '../../../../../core/constants/common';
import MobileBreakElement from '../../../../components/break-element';
import CreateBox from '../../../../components/create-box';
import VCS from '../../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: Intl;
  location: any[];
  invokeChangeURLAccess: Function;
  onCreateUser: Function;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.LOCATION_USER;
const formName = REDUX_FORM.CREATE_ITEM_LOCATION_USER;

const CreateContainer = ({ children, intl, location = [], invokeChangeURLAccess, onCreateUser }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.protected.user.title' })}
      resourceName={resourceName}
      formName={formName}
      renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
        switch (true) {
          case (notification.state === 'error'):
            return renderDefaultNotificationTemplate();
          case (notification.state === 'success'):
            return (
              <div style={{ textAlign: 'center' }}>
                <Title {...PAGE_NOTIFICATION_SUBTITLE_PROPS}>
                  {intl.formatMessage({ id: 'translate.generic.whats.next' })}
                </Title>
                {location.length === 0 && (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="protect-url"
                    onClick={onCreateUser}
                  >
                    {intl.formatMessage({ id: 'translate.page.protected.user.protect.url' })}
                  </Button>
                )}
                {location.length > 0 && (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="manage-access"
                    onClick={() => {
                      invokeChangeURLAccess(notification.responseData);
                      removeNotification();
                    }}
                  >
                    {intl.formatMessage({ id: 'translate.page.protected.user.manage.access' })}
                  </Button>
                )}

                <MobileBreakElement />

                <Button
                  type="outlined"
                  color="secondary"
                  data-e2e="create-new-user"
                  onClick={removeNotification}
                >
                  {intl.formatMessage({ id: 'translate.page.protected.create.new.user' })}
                </Button>
              </div>
            );
          default:
            return null;
        }
      }}
      resources={[{
        resourceName: API_RESOURCE.LOCATION_USER.resourceName,
        methods: ['POST']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
