import { UPDATE_EMAIL_AUTHENTICATION_SPF } from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';

export const updateSPF = (payload, onComplete) => ({
  type: UPDATE_EMAIL_AUTHENTICATION_SPF,
  payload,
  requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF,
  onComplete
});

export const updateSPFApprovalPolicy = (payload, onComplete) => ({
  type: UPDATE_EMAIL_AUTHENTICATION_SPF,
  payload,
  requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY,
  onComplete
});
