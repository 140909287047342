import { PRIORITY } from '../../constants/common';

export const sortByTypeAndName = (a, b) => {
  if (PRIORITY[a.t] < PRIORITY[b.t]) {
    return 1;
  }
  if (PRIORITY[a.t] > PRIORITY[b.t]) {
    return -1;
  }

  if (PRIORITY[a.t] === PRIORITY[b.t]) {
    if (a.n < b.n) {
      return -1;
    }
    if (a.n > b.n) {
      return 1;
    }
    return 0;
  }
};
