import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_LOCATION_IP_BLOCK_DIALOG;

const ChangeIPForm = ({ intl, validationUtils }: Props) => (
  <Grid>
    <Field
      name="addr"
      type="text"
      label={intl.formatMessage({ id: 'translate.generic.ip.ip.range' })}
      validate={[validationUtils.required, validationUtils.ipWithExtras]}
      component={FormInput}
    />
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(ChangeIPForm)
  )
);
