import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Text, Title } from 'sg-styleguide';
import FirstLevelTitle from '../../../containers/titles/first-level-title';
import './welcome-section.scss';

type Props = {
  name: string;
  intl?: Intl;
  userDaysAfterCreation: number;
};

const WelcomeSection = ({ intl, name, userDaysAfterCreation }: Props) => {
  const imgPath = process.env.IMG_PATH;
  return (
    <div>
      <FirstLevelTitle>
        {intl.formatMessage({ id: 'translate.page.dashboard.title' }, { name })}
      </FirstLevelTitle>

      {
        userDaysAfterCreation <= 7 && (
          <div className="welcome__content-wrapper">
            <div className="welcome__background-wrapper">
              <img
                className="welcome__background-image"
                sizes="100vw"
                srcSet={`
                  /${imgPath}/dashboard/header_768.jpg 768w,
                  /${imgPath}/dashboard/header_1200.jpg 768w,
                  /${imgPath}/dashboard/header_768@2x.jpg 1536w,
                  /${imgPath}/dashboard/header_1200@2x.jpg 2400w`}
                src={`/${imgPath}/dashboard/header_1200.jpg`}
                alt="Dashboard header image"
              />
            </div>

           <div className="welcome__content">
             <Title className="welcome__title" color="white" align="center" density="none">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.title' })}
             </Title>

             <Text className="welcome__text" color="white" align="center">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.message' })}
             </Text>

             <Button className="welcome__button" color="light" size="large">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.button' })}
             </Button>
           </div>
          </div>
        )
      }
    </div>
  );
};

export default injectIntl(WelcomeSection) as (props: Props) => any;
