import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Dropdown, Flex, Icon, Text } from 'sg-styleguide';
import { changeCurrentSite } from '../../../../core/actions/session';
import { RootState } from '../../../../core/reducers';

import './site-select.scss';

interface SiteSelectProps {
  changeCurrentSite: (value) => void;
  sites: any;
  routing: any;
  currentSiteId: string;
  className?: string;
}

const SiteSelect = ({ sites, routing, changeCurrentSite }: SiteSelectProps) => {
  const siteId: string = routing.locationBeforeTransitions.query.siteId;

  let currentSiteId;

  if (sites.loading || sites.items === undefined) {
    return null;
  }

  if (siteId) {
    currentSiteId = sites.items
      .filter((site: SiteItem) => site.id === siteId)
      .map((site: SiteItem) => site.id)
      .pop();
  } else { // This case is when we open the page without siteId url parameter
    currentSiteId = sites.items[0];
  }

  return (
    <div className="sp-site-select">
      <Dropdown
        data-e2e="site-select"
        selectedValue={currentSiteId}
        options={sites.items}
        optionValue="id"
        optionLabel="domain"
        theme="brown"
        onChange={(value) => changeCurrentSite(value)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCurrentSite: (siteId: string) => dispatch(changeCurrentSite(siteId))
});

const mapStateToProps = (state: RootState) => ({
  sites: state.sites,
  routing: state.routing
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
  form: 'siteSelect'
})(SiteSelect));
