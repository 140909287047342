import { take, put, race, select, takeLatest } from 'redux-saga/effects';
import * as FileManagerConstants from '../constants/actions';
import * as FileManagerActions from '../actions/file-manager';
import ROUTES from '../../../../../core/constants/routes';
import { getCurrentSiteId } from '../../../../../core/selectors/index';
import { browserHistory } from 'react-router';
import { getEntityByPath } from '../utils';

const generatePreloadedFolders = (path: string) =>
  path.split('/').reduce((output, folderName) => {
    if (folderName.length) {
      const nextPath = `${output.join('')}/${folderName}`;
      return [...output, nextPath];
    }

    return output;
  }, []);

function* navigateToFMPath({ payload }) {
  const { path } = payload;
  const siteId = yield select(getCurrentSiteId);

  browserHistory.replace(`${ROUTES.FILE_MANAGER}?siteId=${encodeURI(siteId)}`);

  const entries = ['/', ...generatePreloadedFolders(path)];

  yield put(FileManagerActions.fetchDirs({ entries }));

  const { succeeded, failed } = yield race({
    succeeded: take(FileManagerConstants.FILE_MANAGER_FETCH_DIRS_SUCCEEDED),
    failed: take(FileManagerConstants.FILE_MANAGER_FETCH_DIRS_FAILED)
  });

  if (succeeded) {
    const { fileManager } = yield select();
    const selectedEntity = getEntityByPath(path, fileManager);

    if (selectedEntity) {
      yield put(FileManagerActions.onEntityClick({ entity: selectedEntity }));
    } else {
      console.error(`Missing entity at ${path}`);
    }
  }
}

function* navigate(): any {
  yield takeLatest(FileManagerConstants.FILE_MANAGER_NAVIGATE_TO_PATH, navigateToFMPath);
}

export default navigate;
