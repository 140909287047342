import { RootState } from '../reducers';

/* PHP settings store selectors */
export const getAvailableLocationPhpSettings = (
  { pageItems }: RootState,
  resourceName = API_RESOURCE.PHP_LOCATION_INI_SETTINGS.resourceName
) =>
  pageItems[resourceName] ?
    pageItems[resourceName].filter((setting) =>
      Object.keys(setting).includes('id')
    ) : [];

import { API_RESOURCE } from '../constants/api';

export const getPhpVariablesById = (
  { pageItems }: RootState,
  resourceName = API_RESOURCE.PHP_VARIABLE.resourceName
) =>
  pageItems[resourceName] ?
    pageItems[resourceName].reduce((output, variable) => {
      output[variable.id] = variable;
      return output;
    }, {}) : {};

export const getLocationPhpIniById = (
  { pageItems }: RootState,
  resourceName = API_RESOURCE.PHP_LOCATION_INI.resourceName
) =>
  pageItems[resourceName] ?
    pageItems[resourceName].reduce((output, locationData) => {
      output[locationData.domain_id] = {
        ...locationData,
        settings: locationData.settings.reduce((settingsOutput, setting) => {
          settingsOutput[setting.id] = setting;
          return settingsOutput;
        }, {})
      };
      return output;
    }, {}) : {};

export const getDefaultPhpVersion = (
  { pageItems }: RootState,
  resourceName = API_RESOURCE.PHP_VERSION.resourceName
) =>
  pageItems[resourceName] ?
    pageItems[resourceName].find(
      (version) => version.default && version.default === 1 ? version : null
    ) : null;

export const getLocationPhpVersionByDomainId = (
  { pageItems },
  resourceName = API_RESOURCE.PHP_LOCATION_VERSION.resourceName
) =>
  pageItems[resourceName] ?
    pageItems[resourceName].reduce((output, locationPhpVersions) => {
      output[locationPhpVersions.domain_id] = locationPhpVersions;
      return output;
    }, {}) : {};
