import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Section } from 'sg-styleguide';
import { navigateToUA } from '../../../core/actions/nemo-store';
import { requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import { HostingTypes } from 'sg-styleguide/lib/utils';
import customRequestTypes from '../../../core/constants/custom-request-types';
import ROUTES from '../../../core/constants/routes';
import { getCurrentSiteAccountId, getCurrentSiteId } from '../../../core/selectors';
import { isACLActionEnabled, isDynamicCacheEnabled, isMemcacheEnabled } from '../../../core/selectors/site-meta-api';
import { getToolMainEndpoint } from '../../../core/selectors/static-api';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import FeatureNotAvailable from '../../containers/dialogs/feature-not-available';
import * as Dialogs from './dialogs';
import { UpgradeResponseAccountPlans } from './types';

type Props = {
  dynamicCacheEnabled?: boolean;
  memcacheEnabled?: boolean;
  isUAEnabled?: boolean;
  accountId: string;
  openSGDialog?: Function;
  closeSGDialog?: Function;
  requestNemoData?: RequestNemoData;
  siteId: string;
  navigateToUA: Function;
  intl: Intl;
  router: any;
  location: {
    search: string;
    pathname: string;
  };
  children: any;
};

type State = {
  upgradeResponse: UpgradeResponseAccountPlans;
};

const STATIC_CACHE = 'cacher-static-cache';
const DYNAMIC_CACHE = 'cacher-dynamic-cache';
const MEMCACHED = 'cacher-memcached';

const ROUTES_WITH_CACHE_FEATURES = [ROUTES.CACHER_DYNAMIC_CACHE, ROUTES.CACHER_MEMCACHED];

export class SuperCacher extends React.Component<Props, State> {
  readonly state = {
    upgradeResponse: null
  };

  isCacheDisabled = (cacherRoute) => Boolean(
    (cacherRoute === ROUTES.CACHER_DYNAMIC_CACHE && !this.props.dynamicCacheEnabled) ||
    (cacherRoute === ROUTES.CACHER_MEMCACHED && !this.props.memcacheEnabled)
  );

  handleTabSwitch = (cacherRoute) => {
    const {
      openSGDialog,
      closeSGDialog,
      isUAEnabled,
      requestNemoData,
      accountId,
      router
    } = this.props;

    if (!ROUTES_WITH_CACHE_FEATURES.includes(cacherRoute)) {
      return;
    }

    if (this.isCacheDisabled(cacherRoute)) {
      router.push(`${ROUTES.CACHER_STATIC_CACHE}${location.search}`);

      if (isUAEnabled) {
        openSGDialog(DIALOGS.CACHE_UPGRADE);

        requestNemoData({
          endpoint: `/order_upgrade/${accountId}`,
          method: 'GET',
          requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS
        }, (upgradeResponse: UpgradeResponseAccountPlans) => {
          const { current_plan } = upgradeResponse;

          if (current_plan === HostingTypes.START_UP) {
            return this.setState({ upgradeResponse });
          }

          closeSGDialog(DIALOGS.CACHE_UPGRADE);
          openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
        });

      } else {
        openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
      }
    }
  };

  render() {
    const {
      intl,
      router,
      location,
      children,
      siteId,
      accountId,
      navigateToUA
    } = this.props;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-speed-caching"
          iconColor="primary"
          title={intl.formatMessage({ id: 'translate.page.superCacher.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.superCacher.instructions' })}
        />
        <Section>
          <PageTabs
            location={location}
            tabLabelsByTabId={{
              [STATIC_CACHE]: intl.formatMessage({ id: 'translate.page.superCacher.staticCache' }),
              [DYNAMIC_CACHE]: intl.formatMessage({ id: 'translate.page.superCacher.dynamicCache' }),
              [MEMCACHED]: intl.formatMessage({ id: 'translate.page.superCacher.memcached' })
            }}
            routesByTabId={{
              [STATIC_CACHE]: ROUTES.CACHER_STATIC_CACHE,
              [DYNAMIC_CACHE]: ROUTES.CACHER_DYNAMIC_CACHE,
              [MEMCACHED]: ROUTES.CACHER_MEMCACHED
            }}
            onNavigate={(route, pathname) => {
              router.push(route);
              this.handleTabSwitch(pathname);
            }}
          />
        </Section>

        <Section>
          {children}
        </Section>

        <FeatureNotAvailable />
        <Dialogs.CacheUpgrade
          onPlanConfirmed={({ planType }) => navigateToUA({
            page: 'upgrade-order',
            siteId,
            planType,
            accountId
          })}
          upgradeData={this.state.upgradeResponse}
        />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { router, location } = this.props;

    if (this.isCacheDisabled(location.pathname)) {
      router.push(`${ROUTES.CACHER_STATIC_CACHE}${location.search}`);
    }
  }
};

export default connect<any, any, Props>(({ siteMetaApi, sites }) => ({
  dynamicCacheEnabled: isDynamicCacheEnabled({ siteMetaApi }),
  memcacheEnabled: isMemcacheEnabled({ siteMetaApi }),
  isUAEnabled: isACLActionEnabled({ siteMetaApi }, getToolMainEndpoint('cacher'), 'can_order'),
  accountId: getCurrentSiteAccountId({ sites }),
  siteId: getCurrentSiteId({ sites })
}), (dispatch) => ({
  navigateToUA: ({ page, siteId, planType, accountId }) => dispatch(
    navigateToUA({ page, siteId, planType, accountId })
  ),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  requestNemoData: ({ endpoint, method, body, requestTypeName }, onComplete) => dispatch(
    requestNemoData({ endpoint, method, body, requestTypeName }, onComplete)
  )
}))(
  injectIntl(SuperCacher)
);
