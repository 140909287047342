import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

export type Webstats = {
  [domainId: string]: {
    [dateId: string]: any;
  };
};

const initialState: Webstats = {};

const reducerMap: any = {
  [Actions.DOMAIN_STATS_REQUESTED]: (state: Webstats, { payload }) => ({
    ...state,
    [payload.domainId]: {
      ...state[payload.domainId],
      [payload.dateId]: {
        serefs: [],
        keywords: [],
        refs: [],
        country: [],
        days: [],
        os: [],
        ua: [],
        no_stats: 0
      }
    }
  }),
  [Actions.DOMAIN_STATS_SUCCEEDED]: (state: Webstats, { payload }) => ({
    ...state,
    [payload.domainId]: {
      ...state[payload.domainId],
      [payload.dateId]: payload.data
    }
  }),
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
