import { createAction } from 'redux-actions';
import customRequestTypes from '../../constants/custom-request-types';

import { REQUEST_UNKNOWN_FILES, REQUEST_CUSTOM_DEPLOY_STAGING } from '../../constants/actions';

export const requestUnknownFiles = (appId: number, onComplete: Function) => ({
  type: REQUEST_UNKNOWN_FILES,
  payload: { appId },
  requestTypeName: customRequestTypes.REQUEST_UKNOWN_WP_FILES,
  onComplete
});

export const requestCustomDeployStaging = (appId: number, onComplete: Function) => ({
  type: REQUEST_CUSTOM_DEPLOY_STAGING,
  payload: { appId },
  requestTypeName: customRequestTypes.REQUEST_CUSTOM_DEPLOY_STAGING,
  onComplete
});
