import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Flex, Text } from 'sg-styleguide';
import { deepclone } from '../../../../core/common/deepclone';
import { DIALOGS } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { SGDialog, SGDialogCancel } from '../../../containers/sg-dialog';
import CustomDeploySelector from '../custom-deploy/custom-deploy-selector';
import { CustomDeployData } from '../types';

export type DataType = 'dbs' | 'files';
export type Selections = {
  [groupId: string]: {
    [id: string]: {
      state: boolean;
      dataType: DataType;
    };
  };
};

type Props = {
  name: string;
  dataLoaded: boolean;
  total: number;
  customDeployData: CustomDeployData;
  onCloseHandler: () => void;
  onSubmit: Function;
  intl: Intl;
};

type State = {
  step: string;
  continueAllowed: boolean;
  submitData: Selections
};

const STEPS = ['SELECT_ITEMS', 'CONFIRM'];

export const submitStructure = {
  files: {
    copy: [],
    delete: []
  },
  db: {
    copy: [],
    delete: []
  }
};

export const formatSubmitData = (submitData: Selections) => {
  const submission = deepclone(submitStructure);

  Object.keys(submitData).forEach((groupId) => {
    const group = groupId === 'deleted' ? 'delete' : 'copy';

    Object.keys(submitData[groupId]).forEach((itemId) => {
      if (submitData[groupId][itemId].state === true) {
        const dataType = submitData[groupId][itemId].dataType === 'dbs' ? 'db' : 'files';
        submission[dataType][group].push(itemId);
      }
    });
  });

  return submission;
};

export const getStepId = (props: Props) =>
  props.total === 0 && props.dataLoaded ?
    'CONFIRM' :
    'SELECT_ITEMS';

const initialState = {
  step: 'SELECT_ITEMS',
  continueAllowed: true,
  submitData: {}
};

class CustomDeploy extends React.Component<Props, State> {
  readonly state: State = {
    ...initialState,
    step: getStepId(this.props)
  };

  renderFooter = () => {
    const { intl, onSubmit } = this.props;
    const { step, continueAllowed, submitData } = this.state;

    switch (step) {
      case 'SELECT_ITEMS':
        return (
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.WP_STAGING_CUSTOM_DEPLOY} />
            <Button
              color="primary"
              disabled={!continueAllowed}
              onClick={() => this.setState({
                step: 'CONFIRM',
                continueAllowed: true
              })}
            >
              {intl.formatMessage({ id: 'translate.generic.next' })}
            </Button>
          </React.Fragment>
        );
      case 'CONFIRM':
        return (
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.WP_STAGING_CUSTOM_DEPLOY} />
            <Button
              color="primary"
              onClick={() => {
                onSubmit(
                  formatSubmitData(submitData)
                );
              }}
            >
              {intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.button' })}
            </Button>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  render() {
    const { intl, name } = this.props;

    const DIALOG_PROPS_BY_STEP_NAME = {
      SELECT_ITEMS: {
        size: 'large',
        title: intl.formatMessage({ id: 'translate.page.staging.dialog.custom.deploy.message' }, { name })
      },
      CONFIRM: {
        size: 'medium',
        title: intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.title' }, { name })
      }
    };

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_CUSTOM_DEPLOY}
        icon="custom-deploy"
        state="warning"
        {...DIALOG_PROPS_BY_STEP_NAME[this.state.step]}
        footer={this.renderFooter()}
        resources={[{
          requestTypeName: customRequestTypes.REQUEST_CUSTOM_DEPLOY_STAGING
        }]}
        onCloseHandler={() => {
          this.setState({ ...initialState });
          this.props.onCloseHandler();
        }}
      >
        {this.state.step === 'SELECT_ITEMS' && (
          <CustomDeploySelector
            options={this.props.customDeployData}
            onSelectionsChange={
              (valid: boolean, data: Selections) =>
                this.setState({
                  continueAllowed: valid,
                  submitData: data
                })
            }
          />
        )}

        {this.state.step === 'CONFIRM' && (
          <Text>
            {intl.formatMessage({ id: 'translate.page.staging.dialog.custom.deploy.confirm.message' })}
          </Text>
        )}
      </SGDialog>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const dataLoadChanged = Boolean(
      this.props.dataLoaded !==
      prevProps.dataLoaded
    );

    if (this.state.step === 'SELECT_ITEMS' && dataLoadChanged) {
      this.setState({ step: getStepId(this.props) });
    }
  }
}

export default injectIntl(CustomDeploy);
