import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Dialog } from 'sg-styleguide';

import {
  closeSGDialog,
  subscribeSGDialogOpened,
  subscribeSGDialogClosed,
  unsubscribeSGDialogState
} from '../../../core/actions/sg-dialog';

import { RootState } from '../../../core/reducers/index';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';
import PartialLoader from '../partial-loader';

type Props = {
  id: string;
  // ui props
  size?: string;
  title?: any;
  subTitle?: any;
  state?: string;
  icon?: string;
  align?: string;
  density?: string;
  disableClose?: boolean;
  footer?: any;
  children?: any;
  onCloseHandler?: () => void;
  // connected props
  initialOpenedState?: boolean;
  requested?: number;
  resources?: LoaderResource[];
  closeSGDialog?: (id) => void;
  subscribeSGDialogOpened?: (id: string, onOpened: Function) => void;
  subscribeSGDialogClosed?: (id: string, onClosed: Function) => void;
  unsubscribeSGDialogState?: (id: string) => void;
};

type State = {
  opened: boolean;
  startedClosing: boolean;
};

class SGDialog extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    resources: [],
    onCloseHandler: () => null
  };

  readonly state = {
    opened: this.props.initialOpenedState,
    startedClosing: false
  };

  render() {
    const {
      align,
      children,
      closeSGDialog,
      density,
      disableClose,
      footer,
      icon,
      id,
      onCloseHandler,
      resources,
      requested,
      size,
      state,
      title,
      subTitle
    } = this.props;

    const { opened, startedClosing } = this.state;
    const shouldDisableClose = requested > 0 && !startedClosing;

    if (opened || startedClosing) {
      return (
        <Dialog
          align={align}
          density={density}
          disableClose={disableClose || shouldDisableClose}
          footer={footer}
          icon={icon}
          size={size}
          state={state}
          title={title}
          subTitle={subTitle}
          triggerClose={startedClosing}
          data-e2e={`sg-dialog-${id}`}
          onCloseHandler={() => {
            closeSGDialog(id);
            onCloseHandler();
          }}
        >
          <PartialLoader
            resources={resources}
            closeDelay={500}
          >
            {children}
          </PartialLoader>
        </Dialog>
      );
    }

    return null;
  }

  componentDidMount() {
    const { id, subscribeSGDialogOpened, subscribeSGDialogClosed, unsubscribeSGDialogState } = this.props;

    subscribeSGDialogOpened(id, () => this.setState({
      opened: true,
      startedClosing: false
    }));

    subscribeSGDialogClosed(id, () => {
      const previouslyOpened = this.state.opened;

      this.setState({
        opened: false,
        startedClosing: previouslyOpened
      });
    });
  }

  componentWillUnmount() {
    const { id, unsubscribeSGDialogState } = this.props;
    unsubscribeSGDialogState(id);
  }
}

const mapStateToProps = (store: RootState, { id, resources = [] }: Props): Partial<Props> => {
  const { requested } = getBlockingRequests(store, resources);

  return {
    initialOpenedState: Boolean(store.dialog[id]),
    requested: requested.length
  };
};

const mapDispatchToProps = (dispatch) => ({
  subscribeSGDialogOpened: (id, onOpened) => dispatch(subscribeSGDialogOpened(id, onOpened)),
  subscribeSGDialogClosed: (id, onClosed) => dispatch(subscribeSGDialogClosed(id, onClosed)),
  unsubscribeSGDialogState: (id) => dispatch(unsubscribeSGDialogState(id)),
  closeSGDialog: (id) => dispatch(closeSGDialog(id))
});

export default connect<{}, {}, Props>(mapStateToProps, mapDispatchToProps)(SGDialog);
