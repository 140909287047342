import * as React from 'react';
import { connect } from 'react-redux';
import { formatMessage } from '../../../core/translate';
import { formattedCurrency } from 'sg-styleguide';

type Props = {
  price: string | number;
  currencySymbol: string;
  currencySymbolBefore: boolean;
  suffix?: any;
  period?: number | string;
  className?: string;
  prefix?: string;
};

const getPeriodAbbreviation = (period) => {
  switch (period.toString()) {
    case '1':
      return formatMessage({ id: 'translate.generic.months.suffix' });
    case '12':
      return formatMessage({ id: 'translate.generic.years.suffix' });
    case '24':
      return formatMessage({ id: 'translate.generic.multiple.years.suffix' });
    default:
      return null;
  }
};

const FormattedCurrency = ({
  price,
  suffix,
  className,
  prefix = '',
  period,
  currencySymbol,
  currencySymbolBefore
}: Props) => {
  return (
    <span className={className ? className : ''}>
      {prefix}
      {formattedCurrency(price, currencySymbol, currencySymbolBefore)}
      {period && (getPeriodAbbreviation(period))}
      {suffix}
    </span>
  );
};

const mapStateToProps = (state): Partial<Props> => ({
  currencySymbol: state.session && state.session.user.currency_symbol,
  currencySymbolBefore: state.session && state.session.user.currency_symbol_before
});

export default connect<any, any, Partial<Props>>(mapStateToProps)(FormattedCurrency);
