import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import FormInput from '../../components/form-input';
import { withSpanelForm } from '../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
};

const formName = REDUX_FORM.EDIT_DB_LABEL;

const EditDbLabel = ({ intl }: Props) => (
  <Grid>
    <Field
      name="label"
      type="text"
      label={intl.formatMessage({ id: 'translate.generic.label' })}
      component={FormInput}
    />
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(EditDbLabel)
  )
);
