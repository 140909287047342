import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Notification as SGNotification, textToHTML } from 'sg-styleguide';
import { Notifications as SGNotifications } from 'sg-styleguide/lib/components/notifications';
import * as notificationActions from '../../core/actions/notifications';
import { RootState } from '../../core/reducers';
import * as NotificationsSelectors from '../../core/selectors/notifications';

interface NotificationsProps {
  notifications?: SpanelNotification[];
  removeNotification?: typeof notificationActions.removeNotification;
  intl?: Intl;
}

const getNotificationSuccessMessage = (notification: SpanelNotification, intl: Intl) => {
  if (!notification.success || !notification.success.intlKey) {
    return null;
  }

  return intl.formatMessage(
    { id: notification.success.intlKey },
    { ...notification.responseData, ...notification.success.intlValues }
  );
};

const getNotificationErrorMessage = (notification: SpanelNotification, intl: Intl) => {
  if (!notification.error || !notification.error.intlKey) {
    return null;
  }

  return intl.formatMessage(
    { id: notification.error.intlKey },
    { ...notification.error.intlValues, backendError: notification.responseError.message }
  );
};

class Notifications extends React.Component<NotificationsProps, any> {
  removeNotification = ({ id }: SpanelNotification) =>
    this.props.removeNotification({ id });

  getMessage = (notification: SpanelNotification) => {
    return NotificationsSelectors.isNotificationSuccess(notification) ?
      getNotificationSuccessMessage(notification, this.props.intl) :
      getNotificationErrorMessage(notification, this.props.intl);
  };

  render() {
    const { notifications, intl } = this.props;

    return (
      <SGNotifications>
        {notifications.map((notification) =>
          <SGNotification
            key={notification.id}
            type={NotificationsSelectors.isNotificationSuccess(notification) ? 'success' : 'error'}
            // TODO: add prop in notification for success title
            title={NotificationsSelectors.isNotificationSuccess(notification) ? 'success' : 'fail'}
            message={textToHTML(this.getMessage(notification))}
            onClose={() => this.removeNotification(notification)}
          />
        )}
      </SGNotifications>
    );
  }
}

const mapStateToProps = (state: RootState) => ({ notifications: state.notifications.generic });

export default connect<{}, {}, NotificationsProps>(
  mapStateToProps, { removeNotification: notificationActions.removeNotification }
)(
  injectIntl(Notifications)
);
