export default {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REQUEST_DATA: 'REQUEST_DATA',
  REQUEST_ACCOUNT_UPGRADE_PLANS: 'REQUEST_ACCOUNT_UPGRADE_PLANS',
  GET_DASHBOARD_PIN_TILES: 'GET_DASHBOARD_PIN_TILES',
  SET_DASHBOARD_PIN_TILES: 'SET_DASHBOARD_PIN_TILES',
  CHANGE_CURRENT_SITE: 'CHANGE_CURRENT_SITE',
  PAGE_LOAD: 'PAGE_LOAD',
  NAVIGATING_TO_UA: 'NAVIGATING_TO_UA',
  REDIRECT_FROM_REMOTE: 'REDIRECT_FROM_REMOTE',
  COUNTRIES: 'COUNTRIES',
  ADD_APP_MANUALLY: 'ADD_APP_MANUALLY',
  DOMAIN_STATS: 'DOMAIN_STATS',
  EMAIL_AUTOCONFIGURE_DATA: 'EMAIL_AUTOCONFIGURE_DATA',
  EMAIL_FILTER_ORDER: 'EMAIL_FILTER_ORDER',
  EMAIL_AUTHENTICATION_SPF: 'EMAIL_AUTHENTICATION_SPF',
  EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY: 'EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY',
  REQUEST_UKNOWN_WP_FILES: 'REQUEST_UKNOWN_WP_FILES',
  REQUEST_CUSTOM_DEPLOY_STAGING: 'REQUEST_CUSTOM_DEPLOY_STAGING',
  REQUEST_REPLICATE_STAGING: 'REQUEST_REPLICATE_STAGING',
  SELECT_DIRECTORY_FETCH: 'SELECT_DIRECTORY_FETCH',
  GET_BACKUP_ORDER_DATA: 'GET_BACKUP_ORDER_DATA',
  MEMCACHE_FLUSH_ALL: 'MEMCACHE_FLUSH_ALL',
  FETCH_SITE_META_API: 'FETCH_SITE_META_API',
  BACKUP_FETCH_SITE_META_API: 'BACKUP_FETCH_SITE_META_API',
  GET_WEBMAIL_LOGIN_DATA: 'GET_WEBMAIL_LOGIN_DATA',
  GET_SITE_SCANNER_UPGRADES: 'GET_SITE_SCANNER_UPGRADES',
  GET_SITE_SCANNER_REPORTS: 'GET_SITE_SCANNER_REPORTS',
  REQUEST_FORCE_SCAN: 'REQUEST_FORCE_SCAN',
  REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS: 'REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS',
  REQUEST_LOAD_WEEBLY_EXTRAS: 'REQUEST_LOAD_WEEBLY_EXTRAS',
  REQUEST_INSTALL_WEEBLY: 'REQUEST_INSTALL_WEEBLY',

  FILE_MANAGER_DELETE: 'FILE_MANAGER_DELETE',
  FILE_MANAGER_POST: 'FILE_MANAGER_POST',
  FILE_MANAGER_SAVE: 'FILE_MANAGER_SAVE',
  FILE_MANAGER_FETCH_FILE: 'FILE_MANAGER_FETCH_FILE',
  FILE_MANAGER_FETCH_DIR: 'FILE_MANAGER_FETCH_DIR',
  FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES: 'FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZE',

  SUPPORT_LOADING: 'SUPPORT_LOADING'
};
