import { browserHistory } from 'react-router';

type Props = {
  pathname?: string;
  urlParam?: any;
  urlParamsToRemove?: any;
};

export const redirectToURL = ({ pathname, urlParam, urlParamsToRemove = [] }: Props) => {
  const currentLocation = browserHistory.getCurrentLocation();
  const params = {
    ...currentLocation.query,
    ...urlParam
  };

  urlParamsToRemove.forEach((key) => {
    delete params[key];
  });

  browserHistory.push({
    pathname: pathname || currentLocation.pathname,
    search: '?' + new URLSearchParams(params).toString()
  });
};
