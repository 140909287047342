import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as BaseConstantsForActions from '../../../../../core/constants/actions';
import * as FileManagerConstants from '../constants/actions';
import { siteApi } from '../../../../../core/api/site';
import { getEntityParentPath } from '../utils';
import handleAvalonApiRequest from '../../../../../core/sagas/handle-avalon-api-request';

const Actions = { ...BaseConstantsForActions, ...FileManagerConstants };

interface DeleteDirsArgs {
  payload: {
    urlParams: {
      filename: string;
    },
    entity: any;
    clearFileManagerStore: any;
  };
}

function* deleteDirsSaga(action: DeleteDirsArgs) {
  const state = yield select();
  const { entity, clearFileManagerStore, urlParams } = action.payload;

  const response = yield call(siteApi({
    endpoint: '/dir/1',
    method: 'DELETE',
    state,
    body: urlParams
  }));

  yield put({
    type: Actions.FILE_MANAGER_FETCH_DIR_REQUESTED,
    payload: {
      urlParams: {
        id: getEntityParentPath(entity)
      }
    }
  });

  yield put({
    type: Actions.FILE_MANAGER_CLEAR_STORE_PROPERTIES,
    payload: clearFileManagerStore
  });

  return response;
}

function* deleteDirs(): any {
  yield takeEvery(Actions.FILE_MANAGER_DELETE_DIRS_REQUESTED, handleAvalonApiRequest(deleteDirsSaga));
}

export default deleteDirs;
