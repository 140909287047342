import * as React from 'react';
import { Title, TitleProps } from 'sg-styleguide';

const SecondLevelTitle = ({ children, className, density = 'cozy', style }: Partial<TitleProps>) => {
  return (
    <Title className={className} level="4" density={density} weight="light" style={style}>
      {children}
    </Title>
  );
};

export default SecondLevelTitle;
