import { createAction } from 'redux-actions';
import * as Actions from '../constants/actions';
import customRequestTypes from '../../../../../core/constants/custom-request-types';

export const clearFileManagerStore = createAction(Actions.FILE_MANAGER_CLEAR_STORE_PROPERTIES);
export const closeCodeEditor = createAction(Actions.FILE_MANAGER_CODE_EDITOR_CLOSE);
export const toggleCodeEditor = createAction(Actions.FILE_MANAGER_CODE_EDITOR_TOGGLE);
export const codeEditorChangeActiveTab = createAction(Actions.FILE_MANAGER_CODE_EDITOR_CHANGE_ACTIVE_TAB);
export const codeEditorOnFileChange = createAction(Actions.FILE_MANAGER_CODE_EDITOR_ON_FILE_CHANGE);
export const codeEditorOnTabClose = createAction(Actions.FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE);

export const focusCodeEditorEntity = (entityId) => ({
  type: Actions.FILE_MANAGER_CODE_EDITOR_FOCUS_ENTITY,
  payload: {
    entityId
  }
});

export const deleteDir = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_DELETE_DIRS_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_DELETE,
  onComplete
});

export const downloadFile = createAction(Actions.FILE_MANAGER_DOWNLOAD_FILE);

export const fetchDir = (payload) => ({
  type: Actions.FILE_MANAGER_FETCH_DIR_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR
});

export const fetchDirWithFolderSizes = (payload) => ({
  type: Actions.FILE_MANAGER_FETCH_DIR_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES
});

export const fetchDirs = createAction(Actions.FILE_MANAGER_FETCH_DIRS_REQUESTED);

export const fetchFile = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_FETCH_FILE_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_FILE
});

export const fileManagerPostRequest = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_POST_REQUEST,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_POST,
  onComplete
});

export const markForCopy = createAction(Actions.FILE_MANAGER_MARK_FOR_COPY);
export const onEntityClick = createAction(Actions.FILE_MANAGER_ON_SIDE_NAVIGATION_ENTITY_CLICK);
export const selectContentRows = createAction(Actions.FILE_MANAGER_SELECT_CONTENT_ROWS);
export const onContextNavigationEntityClick = createAction(Actions.FILE_MANAGER_ON_CONTEXT_NAVIGATION_ENTITY_CLICK);
export const clearContextEntities = createAction(Actions.FILE_MANAGER_CLEAR_CONTEXT_ENTITIES);
export const setContextMenuPosition = createAction(Actions.FILE_MANAGER_SET_CONTEXT_MENU_POSITION);
export const toggleNavigationList = createAction(Actions.FILE_MANAGER_TOGGLE_NAVIGATION_LIST);
export const uploadFiles = createAction(Actions.FILE_MANAGER_UPLOAD_FILES);

export const saveFile = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_SAVE_FILE,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_SAVE,
  onComplete
});

export const changePermission = createAction(Actions.FILE_MANAGER_CHANGE_ENTITY_PERMISSIONS);
export const transferSizeChange = createAction(Actions.FILE_MANAGER_TRANSFER_SIZE_CHANGE);
export const clearUploadProgress = createAction(Actions.FILE_MANAGER_CLEAR_UPLOAD_PROGRESS);

export const navigateToFMPath = createAction(
  Actions.FILE_MANAGER_NAVIGATE_TO_PATH,
  (path) => ({ path })
);
