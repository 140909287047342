import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Actions from '../../constants/actions';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* fetchItemSaga(action: FetchItemAction) {
  const state = yield select();
  const { resourceName, endpoint, itemId, urlParams = {} } = action.payload;

  const response = yield call(
    siteApi({
      endpoint: endpoint + '/' + encodeURIComponent(itemId),
      method: 'GET',
      body: { _metaResourceName: resourceName },
      state,
      urlParams
    })
  );

  yield put({ type: Actions.FETCH_ITEM_SUCCEEDED, item: response.data, resourceName });

  return response;
}

function* fetchItem(): any {
  yield takeEvery(Actions.FETCH_ITEM_REQUESTED, handleAvalonApiRequest(fetchItemSaga));
}

export default fetchItem;
