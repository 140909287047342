import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Flex } from 'sg-styleguide';
import MobileBreakElement from '../../../components/break-element';

const CustomNotification = (props) => {
  const { intl, notification, removeNotification, invokeChangeIpList, type } = props;

  if (notification.state === 'error') {
    return props.defaultTemplate({ buttonText: intl.formatMessage({ id: 'translate.generic.close' }) });
  }

  return (
    <Flex direction="column" justify="center" margin="none" gutter="none">
      <div style={{ textAlign: 'center' }}>
        <Button
          color="secondary"
          type="outlined"
          onClick={(event) => {
            event.preventDefault();
            removeNotification();
            invokeChangeIpList(notification.responseData);
          }}
        >
          {intl.formatMessage({ id: 'translate.page.ssh.manage_ip_access' })}
        </Button>

        <MobileBreakElement />

        <Button type="outlined" color="secondary" onClick={removeNotification}>
          {type === 'import' && intl.formatMessage({ id: 'translate.page.ssh.import_new' }) ||
          intl.formatMessage({ id: 'translate.page.ssh.generate_new' })}
        </Button>
      </div>
    </Flex>
  );

};

export default injectIntl(CustomNotification);
