import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Section } from 'sg-styleguide';

import ROUTES from '../../../core/constants/routes';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import BackupNotAvailable from './backup-not-available';
import { isSiteRecentlyCreated } from '../../../core/selectors/site-meta-api';

type Props = {
  children: any;
  location: any;
  router: any;
  intl: Intl;
  backupToolAvailable: boolean;
};

const SITE_CREATION_TO_BACKUP_AVAILABILITY_HOURS = 6;

const BackupRestorePage = ({ children, intl, location, router, backupToolAvailable }: Props) => (
  <React.Fragment>
    <PageHeader
      title={intl.formatMessage({ id: 'translate.page.backup.restore.title' })}
      icon="presentational-backup-manager"
      instructions={intl.formatMessage({ id: 'translate.page.backup.restore.info' })}
    />

    {
      backupToolAvailable && (
        <Section>
          <PageTabs
            location={location}
            tabLabelsByTabId={{
              createRestore: intl.formatMessage({ id: 'translate.page.backup.restore.create.tab.title' }),
              restoreHistory: intl.formatMessage({ id: 'translate.page.backup.restore.history.tab.title' })
            }}
            routesByTabId={{
              createRestore: ROUTES.BACKUP_RESTORE_MANAGE,
              restoreHistory: ROUTES.BACKUP_RESTORE_HISTORY
            }}
            onNavigate={router.push}
          />
        </Section>
      )
    }

    <Section>
      {backupToolAvailable ? children : <BackupNotAvailable />}
    </Section>
  </React.Fragment>
);

export default connect<{}, {}, Props>(({ siteMetaApi }) => ({
  backupToolAvailable: !isSiteRecentlyCreated(
    { siteMetaApi },
    SITE_CREATION_TO_BACKUP_AVAILABILITY_HOURS
  )
}))(injectIntl(BackupRestorePage));
