import * as React from 'react';
import { Grid, Section } from 'sg-styleguide';
import { getDashboardTiles } from '../../../core/actions/pages/dashboard';
import { requestDomainStats } from '../../../core/actions/pages/resource-stats';
import { RootState } from '../../../core/reducers';
import { getStats } from '../../../core/selectors/resource-stats';
import { userDaysAfterCreation } from '../../../core/selectors';
import { getDateId } from '../../../core/utils/stats-format';
import indexWithCRUD from '../../components/indexWithCRUD';
import StatisticSection from './statistic-section';
import TileSection from './tile-section';
import WelcomeSection from './welcome-section';

type Props = {
  intl: Intl;
  router: any;
  getDashboardTiles: Function;
  domainName: string;
  locationSearch: string;
  session: {
    user: {
      first_name: string;
    }
  };
  stats: any[];
  currentDateId: string;
  userDaysAfterCreation: number;
  requestDomainStats: (domainId: any, dateId: string) => any;
};

class Dashboard extends React.Component<Props, any> {
  fetchStatistics() {
    const { domainName, currentDateId } = this.props;

    this.props.requestDomainStats(domainName, currentDateId);
  }

  fetchAll() {
    this.fetchStatistics();
    this.props.getDashboardTiles();
  }

  componentDidMount() {
    const { domainName } = this.props;

    if (domainName) {
      this.fetchAll();
    }
  }

  componentDidUpdate(prevProps) {
    const { domainName } = this.props;

    if (prevProps.domainName !== domainName) {
      this.fetchAll();
    }
  }

  render() {
    const { router, session, domainName, stats, locationSearch, userDaysAfterCreation } = this.props;
    const firstName = session && session.user && session.user.first_name;

    return (
      <Section>
        <Grid>
          <WelcomeSection name={firstName} userDaysAfterCreation={userDaysAfterCreation} />

          <TileSection router={router} />

          <StatisticSection domain={domainName} stats={stats} router={router} locationSearch={locationSearch} />
        </Grid>
      </Section>
    );
  }
}

const mapStateToProps = ({ environment, session, webstats, sites, routing }: RootState) => {
  const domainName = sites && sites.currentDomainName;
  const currentDateId = getDateId(+new Date() / 1000);

  const stats = getStats({ webstats })(
    currentDateId,
    domainName,
    'days'
  );

  return {
    environment,
    session,
    stats,
    currentDateId,
    domainName,
    locationSearch: routing.locationBeforeTransitions.search,
    userDaysAfterCreation: userDaysAfterCreation({ session })
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestDomainStats: (domainId: string, dateId: string) =>
    dispatch(requestDomainStats(domainId, dateId)),
  getDashboardTiles: () => dispatch(getDashboardTiles())
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(Dashboard);
