import { APP_TYPES_LABELS_BY_ID, APP_ICONS_BY_TYPE } from '../constants/application';
import { formatMessage } from '../translate';

export const findAppNameByType = (type: string) => {
  const typeName = Object
    .keys(APP_TYPES_LABELS_BY_ID)
    .find((appName) => Boolean(type.match(new RegExp(appName, 'gi'))));

  if (typeName) {
    return formatMessage({ id: APP_TYPES_LABELS_BY_ID[typeName] });
  }

  return type;
};

export const findAppIconByType = (type: string) => {
  const typeName = Object
    .keys(APP_ICONS_BY_TYPE)
    .find((appType: string) => Boolean(type.match(new RegExp(appType, 'gi'))));

  return APP_ICONS_BY_TYPE[typeName];
};
