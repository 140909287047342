import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'sg-styleguide';
import { closeSGDialog } from '../../../core/actions/sg-dialog';

type Props = {
  closeSGDialog?: (id) => void;
  e2eAttr?: string;
  id: string;
  intl?: Intl;
  label?: string;
};

const CancelButton = ({
  closeSGDialog,
  e2eAttr = 'dialog-close',
  id,
  intl,
  label
}: Props) => (
  <Button
    action="button"
    data-e2e={e2eAttr}
    onClick={() => closeSGDialog(id)}
  >
    {label || intl.formatMessage({ id: 'translate.generic.cancel' })}
  </Button>
);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  closeSGDialog: (id) => dispatch(closeSGDialog(id))
});

export default connect<{}, {}, Props>(mapStateToProps, mapDispatchToProps)(injectIntl(CancelButton));
