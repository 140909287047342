// Polyfills
/* tslint:disable */
require('formdata-polyfill');
/* tslint:enable */
import * as objectAssignPolyfill from 'es6-object-assign';

objectAssignPolyfill.polyfill();

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { syncHistoryWithStore } from 'react-router-redux';
import Root from './web/containers/root';
import { browserHistory } from 'react-router';

import store from './core/store';

const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('root'));

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./web/containers/root', () => {
    // tslint:disable
    const NextRoot = require('./web/containers/root').default;
    // tslint:enable

    ReactDOM.render(
      <AppContainer>
        <NextRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('root'));
  });
}
