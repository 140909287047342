import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Dialog, IconButton, Table } from 'sg-styleguide';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../../core/reducers/index';
import { SGDialog, SGDialogCancel } from '../../../containers/sg-dialog';

const MessageDialog = ({ dialog, intl }) => {
  const title = dialog[DIALOGS.FILE_MANAGER_MESSAGE] &&
    intl.formatMessage({ id: dialog[DIALOGS.FILE_MANAGER_MESSAGE].title });
  const message = dialog[DIALOGS.FILE_MANAGER_MESSAGE] && dialog[DIALOGS.FILE_MANAGER_MESSAGE].message;

  return (
    <SGDialog
      id={DIALOGS.FILE_MANAGER_MESSAGE}
      icon="warning"
      state="warning"
      title={title}
      footer={
        <SGDialogCancel id={DIALOGS.FILE_MANAGER_MESSAGE} />
      }
    >
      {message}
    </SGDialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  dialog: state.dialog
});

export default connect<{}, {}, any>(mapStateToProps)(
  injectIntl(MessageDialog)
);
