import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: any = {
  navi: { selectedGroup: 'sites' }
};

const reducerMap: any = {
  [Actions.NAVIGATION_CHANGE]: (state, action) => {
    return Object.assign({}, state, {
      navi: { selectedGroup: action.payload }
    });
  }
};

export default handleActions(reducerMap, initialState);
