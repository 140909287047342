// File Manager API Endpoint

export const FILE_MANGER_API_FILE = '/file';
export const FILE_MANGER_API_DIR = '/dir';
export const FILE_MANGER_API_DIR_COPY = '/dir-copy';
export const FILE_MANGER_API_DIR_MOVE = '/dir-move';
export const FILE_MANGER_API_DIR_RENAME = '/dir-rename';
export const FILE_MANGER_API_DIR_ARCHIVE = '/dir-archive';
export const FILE_MANGER_API_DIR_EXTRACT = '/dir-extract';
export const FILE_MANGER_API_CHANGE_PERMISSIONS = '/dir-perms';

// File Manager Actions
export const FILE_MANAGER_SET_MESSAGE_DIALOG_CONTENT = 'FILE_MANAGER_SET_MESSAGE_DIALOG_CONTENT';
export const FILE_MANAGER_SET_CONFIRM_DIALOG_CONTENT = 'FILE_MANAGER_SET_CONFIRM_DIALOG_CONTENT';

export const FILE_MANAGER_CODE_EDITOR_CLOSE = 'FILE_MANAGER_CLOSE_CODE_EDITOR';
export const FILE_MANAGER_CODE_EDITOR_TOGGLE = 'FILE_MANAGER_CODE_EDITOR_TOGGLE';
export const FILE_MANAGER_CODE_EDITOR_ON_FILE_CHANGE = 'FILE_MANAGER_CODE_EDITOR_ON_FILE_CHANGE';
export const FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE = 'FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE';
export const FILE_MANAGER_CODE_EDITOR_CHANGE_ACTIVE_TAB = 'FILE_MANAGER_CODE_EDITOR_CHANGE_ACTIVE_TAB';
export const FILE_MANAGER_CODE_EDITOR_FOCUS_ENTITY = 'FILE_MANAGER_CODE_EDITOR_FOCUS_ENTITY';

export const FILE_MANAGER_ON_SIDE_NAVIGATION_ENTITY_CLICK = 'FILE_MANAGER_ON_SIDE_NAVIGATION_ENTITY_CLICK';

export const FILE_MANAGER_SAVE_FILE = 'FILE_MANAGER_SAVE_FILE';
export const FILE_MANAGER_SAVE_FILE_SUCCEEDED = 'FILE_MANAGER_SAVE_FILE_SUCCEEDED';
export const FILE_MANAGER_SAVE_FILE_FAILED = 'FILE_MANAGER_SAVE_FILE_FAILED';

export const FILE_MANAGER_UPLOAD_FILES = 'FILE_MANAGER_UPLOAD_FILES';
export const FILE_MANAGER_UPLOAD_FILE_PENDING = 'FILE_MANAGER_UPLOAD_FILE_PENDING';
export const FILE_MANAGER_UPLOAD_FILE_SUCCEEDED = 'FILE_MANAGER_UPLOAD_FILE_SUCCEEDED';
export const FILE_MANAGER_UPLOAD_FILE_FAILED = 'FILE_MANAGER_UPLOAD_FILE_FAILED';

export const FILE_MANAGER_UPDATE_AFTER_MOVE = 'FILE_MANAGER_UPDATE_AFTER_MOVE';

export const FILE_MANAGER_TRANSFER_SIZE_CHANGE = 'FILE_MANAGER_TRANSFER_SIZE_CHANGE';
export const FILE_MANAGER_CLEAR_UPLOAD_PROGRESS = 'FILE_MANAGER_CLEAR_UPLOAD_PROGRESS';

export const FILE_MANAGER_ON_CONTEXT_NAVIGATION_ENTITY_CLICK = 'FILE_MANAGER_ON_CONTEXT_NAVIGATION_ENTITY_CLICK';
export const FILE_MANAGER_SELECT_CONTENT_ROWS = 'FILE_MANAGER_SELECT_CONTENT_ROWS';
export const FILE_MANAGER_DOWNLOAD_FILE = 'FILE_MANAGER_DOWNLOAD_FILE';
export const FILE_MANAGER_TOGGLE_NAVIGATION_LIST = 'FILE_MANAGER_TOGGLE_NAVIGATION_LIST';

export const FILE_MANAGER_FETCH_DIR_REQUESTED = 'FILE_MANAGER_FETCH_DIR_REQUESTED';
export const FILE_MANAGER_FETCH_DIR_SUCCEEDED = 'FILE_MANAGER_FETCH_DIR_SUCCEEDED';
export const FILE_MANAGER_FETCH_DIRS_REQUESTED = 'FILE_MANAGER_FETCH_DIRS_REQUESTED';
export const FILE_MANAGER_FETCH_DIRS_SUCCEEDED = 'FILE_MANAGER_FETCH_DIRS_SUCCEEDED';
export const FILE_MANAGER_FETCH_DIRS_FAILED = 'FILE_MANAGER_FETCH_DIRS_FAILED';

export const FILE_MANAGER_DELETE_DIRS_REQUESTED = 'FILE_MANAGER_DELETE_DIRS_REQUESTED';

export const FILE_MANAGER_FETCH_FILE_REQUESTED = 'FILE_MANAGER_FETCH_FILE_REQUESTED';
export const FILE_MANAGER_FETCH_FILE_SUCCEEDED = 'FILE_MANAGER_FETCH_FILE_SUCCEEDED';

export const FILE_MANAGER_MARK_FOR_COPY = 'FILE_MANAGER_MARK_FOR_COPY';

export const FILE_MANAGER_POST_REQUEST = 'FILE_MANAGER_POST_REQUEST';
export const FILE_MANAGER_CLEAR_STORE_PROPERTIES = 'FILE_MANAGER_CLEAR_STORE_PROPERTIES';
export const FILE_MANAGER_SET_CONTEXT_MENU_POSITION = 'FILE_MANAGER_SET_CONTEXT_MENU_POSITION';
export const FILE_MANAGER_CLEAR_CONTEXT_ENTITIES = 'FILE_MANAGER_CLEAR_CONTEXT_ENTITIES';

export const FILE_MANAGER_CHANGE_ENTITY_PERMISSIONS = 'FILE_MANAGER_CHANGE_ENTITY_PERMISSIONS';
export const FILE_MANAGER_NAVIGATE_TO_PATH = 'FILE_MANAGER_NAVIGATE_TO_PATH';
