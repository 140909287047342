import { takeEvery } from 'redux-saga/effects';
import * as ACTIONS from '../constants/actions';

export const handleRequestActionCallback = (action, response) => {
  const actionSuccessCallback = (
    action &&
    action.onComplete &&
    typeof action.onComplete === 'function'
  );

  if (actionSuccessCallback) {
    action.onComplete(response && response.data);
  }
};

export default function* () {
  yield takeEvery(ACTIONS.HTTP_REQUEST_SUCCEEDED, function* ({ payload }) {
    const { requestedAction, response } = payload;

    handleRequestActionCallback(requestedAction, response);
  });
}
