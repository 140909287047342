import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, getDate, getTime, Grid, Notice, Text } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS } from '../../../../core/constants/common';
import { getLatestWordpressInfo } from '../../../../core/selectors/index';
import SettingListItem from '../../../components/setting-list-item';
import { SGDialog, SGDialogCancel } from '../../../containers/sg-dialog';

class WPAutoupdateSettings extends React.Component<any, any> {
  readonly state = {
    restoreDialog: null
  };

  render() {
    const { selectedSite, intl, wordpressInfo } = this.props;

    if (!selectedSite) {
      return null;
    }

    const lastBackup = wordpressInfo && wordpressInfo.backup && wordpressInfo.backup.ts;
    if (!lastBackup) {
      return null;
    }

    return (
      <SettingListItem title={`${getDate(lastBackup)} ${getTime(lastBackup)}`} disableExpand>
        <Button
          color="secondary"
          type="outlined"
          size="small"
          onClick={() => this.setState({ restoreDialog: true })}
        >
          {intl.formatMessage({ id: 'translate.generic.restore' })}
        </Button>

        {this.renderRestoreDialog()}
      </SettingListItem>
    );
  }

  renderRestoreDialog() {
    const { restoreDialog } = this.state;
    const { actions, intl, selectedSite, closeSGDialog } = this.props;

    const title = intl.formatMessage(
      { id: 'translate.page.wp.autoupdate.restore.last.backup.title' },
      { domain: selectedSite.app_url }
    );

    return (
      <SGDialog
        id={DIALOGS.WP_AUTOUPDATE_LAST_BACKUP}
        title={title}
        icon="logo-wordpress"
        onCloseHandler={() => this.setState({ restoreDialog: null })}
        resources={[{
          resourceName: API_RESOURCE.APP_RESTORE_BACKUP.resourceName,
          methods: ['PUT']
        }]}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.WP_AUTOUPDATE_LAST_BACKUP} />

            <Button
              color="primary"
              data-e2e="dialog-submit"
              onClick={() => actions.updateItem({
                id: selectedSite.id,
                _metaFields: {
                  ...API_RESOURCE.APP_RESTORE_BACKUP
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.wp.autoupdate.restore.last.backup.success.message'
                    },
                    error: {
                      intlKey: 'translate.page.wp.autoupdate.restore.last.backup.error.message'
                    }
                  }
                }
              }, () => closeSGDialog(DIALOGS.WP_AUTOUPDATE_LAST_BACKUP))}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
      >
        <Grid>
          <Notice
            background="light"
            type="warning"
            shadow={false}
            border={false}
          >
            {intl.formatMessage({ id: 'translate.page.wp.autoupdate.restore.last.backup.notice' })}
          </Notice>

          <Text align="left">
            {intl.formatMessage({ id: 'translate.page.wp.autoupdate.restore.last.backup.message' })}
          </Text>
        </Grid>
      </SGDialog>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (store) => ({
  wordpressInfo: getLatestWordpressInfo(store)
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WPAutoupdateSettings)) as any;
