import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, IconButton, Table } from 'sg-styleguide';
import { DIALOGS } from '../../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../../containers/sg-dialog';
import Entity from '../../content/entity';
import './failed-upload-files.scss';

const ROWS_TO_RENDER = 20;

class FailedUploadFilesDialog extends React.Component<any, any> {
  readonly state = {
    maxRowsToRender: ROWS_TO_RENDER
  };

  render() {
    const { intl, files = [], handleSubmit, handleClose } = this.props;
    const { maxRowsToRender } = this.state;
    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.file.name' }),
      accessor: 'file',
      render: (file, entity) => {
        return (
          <Entity entity={entity} showFullPath />
        );
      }
    }];
    const filesCount = files.length;

    return (
      <SGDialog
        id={DIALOGS.FILE_MANAGER_FAILED_UPLOAD}
        state="inactive"
        density="none"
        size="large"
        title={`${intl.formatMessage({ id: 'translate.file.manager.failed.upload.files.dialog.title' })}`}
        footer={
          <React.Fragment>
            {filesCount > maxRowsToRender && (
              <Button
                data-e2e="footer-button-show-more"
                style={{ marginRight: 'auto' }}
                onClick={() => this.setState({ maxRowsToRender: filesCount })}
              >
                {intl.formatMessage(
                  { id: 'translate.file.manager.failed.upload.files.show.more' },
                  { fileCount: filesCount - ROWS_TO_RENDER })
                }
              </Button>
            )}

            <SGDialogCancel id={DIALOGS.FILE_MANAGER_FAILED_UPLOAD} />
            <Button
              data-e2e="footer-button-retry-all"
              color="primary"
              onClick={() => {
                handleSubmit();
                handleClose();
              }}
            >{intl.formatMessage({ id: 'translate.generic.retry.all' })}</Button>
          </React.Fragment>
        }
      >
        <Table
          className="table-flex"
          columns={columns}
          data={files.slice(0, maxRowsToRender)}
          density="large"
        />
      </SGDialog>
    );
  }
}

export default injectIntl(FailedUploadFilesDialog);
