import * as React from 'react';
import { List, ListItem, Notice, Switch } from 'sg-styleguide';
import { requestData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import customRequestTypes from '../../../core/constants/custom-request-types';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../containers/visibility-control-service';

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.USERVICE;

type Props = {
  intl: Intl;
  items: {
    uservice: any[];
  };
  actions: {
    deleteItem: DeleteItem;
    createItem: CreateItem;
  };
  requestData: RequestData;
  isPhone: boolean;
};

const MEMCACHED_IP = '127.0.0.1';
const MEMCACHED_PORT = '11211';

class MemcachedPage extends React.Component<Props, any> {
  getFeatureitem = () => {
    const { items = {} } = this.props;
    const data = items[resourceName] || [];

    return data[0];
  };

  isFeatureEnabled = () => {
    const featureData = this.getFeatureitem();

    return Boolean(
      featureData &&
      featureData.type === 'memcached'
    );
  };

  getFeatureId = () => {
    const featureData = this.getFeatureitem();
    return featureData ? featureData.id : 'mock-id';
  };

  renderCacheToggle = (enabled: boolean, { id }) => {
    const { intl, actions } = this.props;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod={'PUT'}>
        <Switch checked={enabled} onChange={(event) => {
          const cacheEnabled = Boolean(event.target.checked);

          const meta: { notification: SpanelNotification } = {
            notification: {
              type: 'generic',
              success: {
                intlKey: cacheEnabled ?
                  'translate.page.superCacher.memcached.enable.success.notification' :
                  'translate.page.superCacher.memcached.disable.success.notification'
              },
              error: {
                intlKey: cacheEnabled ?
                  'translate.page.superCacher.memcached.enable.fail.notification' :
                  'translate.page.superCacher.memcached.disable.fail.notification'
              }
            }
          };

          if (cacheEnabled) {
            actions.createItem({
              _metaFields: {
                ...API_RESOURCE.USERVICE,
                itemId: id
              },
              _meta: meta,
              type: 'memcached',
              settings: {}
            });
          } else {
            actions.deleteItem({
              _meta: meta,
              _metaFields: { ...API_RESOURCE.USERVICE },
              itemId: id
            });
          }
        }} />
      </VCS>
    );
  };

  renderInfoNotice = () => (
    <Notice
      type="instruction"
      title={this.props.intl.formatMessage({ id: 'translate.page.superCacher.notice.title' })}
      shadow={false}
      background="light"
    >
      <List type="instruction">
        <ListItem icon="dot">
          <strong>
            {this.props.intl.formatMessage({ id: 'translate.page.superCacher.memcached.ip.label' })}
          </strong> {MEMCACHED_IP}
        </ListItem>
        <ListItem icon="dot">
          <strong>
            {this.props.intl.formatMessage({ id: 'translate.page.superCacher.memcached.port.label' })}
          </strong> {MEMCACHED_PORT}
        </ListItem>
      </List>
    </Notice>
  );

  render() {
    const { intl, isPhone, actions, requestData } = this.props;
    const tableData = [{ enabled: this.isFeatureEnabled(), id: this.getFeatureId(), rowKeyId: 'allSites' }];

    return (
      <SGTable
        rowKey="rowKeyId"
        title={intl.formatMessage({ id: 'translate.page.superCacher.memcached' })}
        resources={[
          { resourceName, methods: ['GET'] },
          { requestTypeName: customRequestTypes.MEMCACHE_FLUSH_ALL }
        ]}
        rowResources={[{
          resourceName, methods: ['DELETE', 'POST']
        }]}
        data={tableData}
        columns={[{
          header: intl.formatMessage({ id: 'translate.generic.name' }),
          accessor: 'enabled',
          render: () => intl.formatMessage({ id: 'translate.page.superCacher.all.sites.label' }),
          style: !isPhone ? { width: '75%' } : null
        }, {
          header: intl.formatMessage({ id: 'translate.generic.static_cache_enabled' }),
          accessor: 'enabled',
          render: this.renderCacheToggle
        }, {
          header: intl.formatMessage({ id: 'translate.generic.actions' }),
          accessor: 'enabled',
          render: () => (
            <TableContextMenu
              resourceName={resourceNameMetaApi}
              items={[{
                vcsMethod: 'PUT',
                icon: 'flush',
                label: intl.formatMessage({ id: 'translate.page.domainCache.flush_cache' }),
                e2eAttr: 'table-action-flush-cache',
                visibleOnDesktop: true,
                disabled: !this.isFeatureEnabled(),
                onClick: () => requestData({
                  requestTypeName: customRequestTypes.MEMCACHE_FLUSH_ALL,
                  payload: {
                    _metaFields: {
                      endpoint: `${API_RESOURCE.USERVICE.endpoint}/${this.getFeatureId()}`,
                      method: 'PUT'

                    },
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.domainCache.cache_flushed_for_all'
                        },
                        error: {
                          intlKey: 'translate.page.domainCache.memcached_flushed_all.failed'
                        }
                      }
                    }
                  }
                })
              }]}
            />
          )
        }]}
        renderAfterTableContent={this.isFeatureEnabled() ? this.renderInfoNotice : undefined}
      />
    );
  }
}

export default indexWithCRUD(({ environment, siteMetaApi }) => ({
  isPhone: environment.isPhone
}), (dispatch) => ({
  requestData: ({ payload, requestTypeName }) => dispatch(requestData({ payload, requestTypeName }))
}))(
  MemcachedPage,
  API_RESOURCE.USERVICE
);
