import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from 'sg-styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import AutoResponderFields from '../autoresponder-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  validationUtils: ValidationUtils;
  selectedDomain: any;
  sPanelFormSubmit: (formName) => null;
};

const formName = REDUX_FORM.CREATE_EMAIL_AUTORESPONDER;

const Form = ({ getFormValues, validationUtils, change, selectedDomain, sPanelFormSubmit }: Props) => (
  <SGForm onSubmit={sPanelFormSubmit(formName)}>
    <AutoResponderFields
      values={getFormValues(formName)}
      selectedDomain={selectedDomain}
      changeFieldValue={change}
      validationUtils={validationUtils}
    />

    <Column smSpan="12">
      <SGSubmitButton formName={formName} />
    </Column>
  </SGForm>
);

const EmailAutoResponderForm = reduxForm({
  form: formName,
  initialValues: {
    wait_time: 24 * 60 * 60, // 24h in seconds
    _metaFields: {
      ...API_RESOURCE.EMAIL_AUTORESPONDER,
      formName
    }
  }
})(Form);

export default withSpanelForm(EmailAutoResponderForm);
