import { RootState } from '../reducers';

export const getSuccessMessage = (notification: SpanelNotification) => (
  notification.success &&
  notification.success.intlKey
);

export const getSuccessValues = (notification: SpanelNotification) => (
  notification.success &&
  notification.success.intlValues
) || {};

export const getErrorMessage = (notification: SpanelNotification) => (
  notification.error &&
  notification.error.intlKey
);

export const getErrorValues = (notification: SpanelNotification) => (
  notification.error &&
  notification.error.intlValues
) || {};

export const getResponseMessage = (notification: SpanelNotification) => {
  if (isNotificationSuccess(notification)) {
    return notification.responseData;
  }
};

export const isNotificationSuccess = (notification: SpanelNotification) =>
  notification && Boolean(notification.state === 'success');

export const findFormNotification = (state: RootState, { formName }) =>
  state.notifications.form[formName] || null;
