export function jsonResponse(response) {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  } else {
    throw Error('Backend issue occurred. Please try again later');
  }
};

export const formatRequestBody = (body: object = {}) => {
  if (Object.keys(body).length === 0) {
    return null;
  }

  const formattedBody = Object.keys(body).reduce((output, field) => {
    const isMetaField = field !== '_meta' && field.includes('_meta');

    if (isMetaField) {
      return output;
    }

    return {
      ...output,
      [field]: body[field]
    };
  }, {});

  return JSON.stringify(formattedBody);
};
