import * as React from 'react';
import Progress from 'sg-styleguide/lib/components/progress';
import Flex from 'sg-styleguide/lib/components/flex-box/flex';
import Icon from 'sg-styleguide/lib/components/icon';

import './quota-progress.scss';
import { BYTES_IN_MB } from '../../../core/constants/common';

const byteMap = {
  mb: BYTES_IN_MB
};

const getColorByPercentage = (percent) => {
  if (percent <= 70) {
    return 'green';
  }

  if (percent > 70 && percent <= 90) {
    return 'orange';
  }

  if (percent > 90) {
    return 'red';
  }
};

const getParsedQuota = ({ quota, type }) => {
  const parsedQuota = `${(quota / byteMap[type]).toFixed(0)} ${type.toUpperCase()}`;

  return (
    <span style={{ display: 'inline-block', minWidth: '60px', paddingLeft: '10px' }}>{parsedQuota}</span>
  );
};

const getParsedUsedQuota = ({ usedQuota = 0, type }) => {
  const parsedMax = `${(usedQuota / byteMap[type]).toFixed(0)} ${type.toUpperCase()}`;

  return (
    <span className="quota-progress__text">{parsedMax}</span>
  );
};

const QuotaProgress = ({ usedQuota = 0, quota, type = 'mb', ...props }: any) => {
  const isQuotaUnlimited = quota === 0;
  const percent = isQuotaUnlimited ? 100 : Math.min(usedQuota / quota * 100, 100);
  const color = isQuotaUnlimited ? 'blue' : getColorByPercentage(percent);

  return (
    <Flex className="quota-progress" gutter="none" align="center" margin="none">
      {isQuotaUnlimited && <Icon className="quota-progress__icon" name="infinite" size="20" />}
      {!isQuotaUnlimited && getParsedUsedQuota({ usedQuota, type })}
      <Progress percent={percent} color={color} />
      {isQuotaUnlimited && <Icon className="quota-progress__icon" name="infinite" size="20" />}
      {!isQuotaUnlimited && getParsedQuota({ quota, type })}
    </Flex>
  );
};

export default QuotaProgress;
