import { REQUEST_DATA, REQUEST_NEMO_DATA } from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

export const requestData: RequestData = (
  { payload, requestTypeName },
  onComplete = null
) => ({
  type: REQUEST_DATA,
  payload,
  onComplete,
  requestTypeName: requestTypeName ? requestTypeName : customRequestTypes.REQUEST_DATA
});

export const requestNemoData: RequestNemoData = ({
  endpoint,
  method = 'GET',
  body = {},
  notification,
  requestTypeName
}, onComplete = null) => ({
  type: REQUEST_NEMO_DATA,
  payload: {
    endpoint,
    method,
    body
  },
  notification,
  onComplete,
  requestTypeName
});
