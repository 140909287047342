const MATCHERS = [
  [/^winxp$/i, () => 'Windows XP'],
  [/^winunknown$/i, () => 'Windows Unknown'],
  [/^winphone$/i, () => 'Windows Phone'],
  [/^winnt$/i, () => 'Windows NT'],
  [/^(win)([0-9.]+)/i, (regex: RegExp, name: string) => {
    const match = name.match(regex);

    if (match) {
      const [matched, windows, version] = match;
      return `Windows ${version}`;
    }

    return name;
  }],
  [/ubuntu/i, () => 'Ubuntu'],
  [/android/i, () => 'Android'],
  [/^ios_iphone/i, () => 'iOS iPhone'],
  [/^ios_ipad/i, () => 'iOs iPad'],
  [/^(macosx)([0-9]+)/i, (regex: RegExp, name: string) => {
    const match = name.match(regex);

    if (match) {
      const [matched, macosx, version] = match;
      return `Mac OS X ${version}`;
    }

    return name;
  }],
  [/^([A-Z]+)([0-9]+)/i, (regex: RegExp, name: string): string => {
    const match = name.match(regex);

    if (match) {
      const formattedName = capitalize(match[1]);
      const version = match[2];
      return `${formattedName} ${version}`;
    }

    return name;
  }],
  [/^([A-Z]+)$/i, (regex, name) => capitalize(name)]
];

export const capitalize = (name: string = '') => {
  const [firstLetter, ...other] = name.split('');
  return [
    firstLetter.toUpperCase(),
    ...other
  ].join('');
};

export const formatUserAgentName = (name: string) => {
  for (const index in MATCHERS) {
    if (!MATCHERS.hasOwnProperty(index)) {
      continue;
    }

    const [regex, formatter]: any = MATCHERS[index];
    const regexp = new RegExp(regex);

    if (regexp.test(name)) {
      return formatter(regexp, name);
    }
  }

  return name;
};
