import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Checkbox, Grid, RadioButton, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import { initialFormState } from '../../../core/constants/db-forms';
import FormDropdown from '../../components/form-dropdown';
import { withSpanelForm } from '../../containers/sg-form-util/with-spanel-form';

type DBData = {
  id: string;
  name: string;
  label: string;
};

type DBGrant = {
  dbuser_id: string;
  db_id: string;
};

type Props = {
  intl: Intl;
  dbData: DBData[];
  dbGrant: DBGrant[];
  rowData: {
    id: string;
  };
  change: Function;
  getFormValues: Function;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.ADD_DB_TO_USER;

export class AddDbTouser extends React.Component<Props, any> {
  static defaultProps: Partial<Props> = {
    dbData: [],
    dbGrant: []
  };

  handleCheckboxChange = (event, index) => {
    const { change, getFormValues } = this.props;

    const { checkboxes } = getFormValues(formName);
    checkboxes[index].checked = event.target.checked;

    change('checkboxes', checkboxes);
  };

  handleRadioButtonsChange = (event) => {
    const { change, getFormValues } = this.props;

    const formValues = getFormValues(formName);

    const radiobuttons = formValues.radiobuttons.map((radiobutton) => {
      return {
        ...radiobutton,
        checked: radiobutton.value === event.target.value
      };
    });

    const checkboxes = formValues.checkboxes.map((checkbox) => {
      if (radiobuttons[0].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: true
        };
      } else if (radiobuttons[1].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: !!['SELECT'].includes(checkbox.label)
        };
      } else {
        return {
          ...checkbox,
          disabled: false,
          checked: false
        };
      }
    });

    change('radiobuttons', radiobuttons);
    change('checkboxes', checkboxes);
  };

  renderCheckboxes = () => {
    const { intl, getFormValues } = this.props;
    const { checkboxes = [] } = getFormValues(formName);

    return (
      <div>
        <Grid sm="3" gap="none">
          {checkboxes.map((c, i) => (
            <Checkbox
              key={c.label}
              name={c.label}
              onChange={(e) => this.handleCheckboxChange(e, i)}
              data-e2e={`privileges-option-${c.label}`}
              {...c}
            >
              {c.label}
            </Checkbox>
          ))}
        </Grid>
      </div>
    );
  };

  renderRadioButtons = () => {
    const { intl, getFormValues } = this.props;
    const { radiobuttons = [] } = getFormValues(formName);

    return (
      <div>
        <Text align="left" color="light" size="small">
          {intl.formatMessage({ id: 'translate.page.database.privileges' })}
        </Text>

        <Grid sm="3">
          {radiobuttons.map((radiobutton, i) =>
            <RadioButton
              key={radiobutton.value}
              name="privileges"
              onChange={this.handleRadioButtonsChange}
              value={radiobutton.value}
              data-e2e={`privileges-${radiobutton.value}`}
              {...radiobutton}
            >
              {intl.formatMessage({ id: radiobutton.label })}
            </RadioButton>
          )}
        </Grid>
      </div>
    );
  };

  getDbOptions = () => {
    const { dbData, dbGrant, rowData } = this.props;

    if (!rowData) {
      return [];
    }

    const databaseGrantId = dbGrant
      .filter((item) => item.dbuser_id === rowData.id)
      .map((item) => item.db_id);

    const databases = dbData.filter((database) => !databaseGrantId.includes(database.id));

    return databases.map((database) => ({
      ...database,
      name: database.label.length > 0 ? `${database.name} (${database.label})` : database.name
    }));
  };

  render() {
    const { intl, validationUtils } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Field
            name="db_id"
            label={intl.formatMessage({ id: 'translate.page.database.select-database.label' })}
            options={this.getDbOptions()}
            optionValue="id"
            optionLabel="name"
            disableAutoSelect
            validate={[validationUtils.required]}
            component={FormDropdown}
          />
          {this.renderRadioButtons()}
          {this.renderCheckboxes()}
        </Grid>
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { change } = this.props;
    change('radiobuttons', initialFormState.radiobuttons);
    change('checkboxes', initialFormState.checkboxes);
  }
};

export default injectIntl(
  withSpanelForm(
    reduxForm({
      form: formName
    })(AddDbTouser)
  )
);
