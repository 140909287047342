import { call, put, select, takeLatest } from 'redux-saga/effects';
import { clientApi } from '../../api/client';

import {
  GET_DASHBOARD_PIN_ITEMS,
  GET_DASHBOARD_PIN_ITEMS_SUCCEEDED,
  SET_DASHBOARD_PIN_ITEMS
} from '../../constants/actions';

import { handleNemoApiRequest } from '../handle-nemo-api-request';

function* getPreferencesNemo() {
  const { session } = yield select();

  return yield call(clientApi(
    `/user/get/preferences?_client_token=${session.clientToken}`,
    'POST',
    { names: ['spanel_dashboard_tiles'] }
  ));
}

function* getPinItems(action) {
  const response = yield call(handleNemoApiRequest(getPreferencesNemo, action));

  if (response.status === 200) {
    yield put({ type: GET_DASHBOARD_PIN_ITEMS_SUCCEEDED, data: response.data });
  }

  return response;
}

function* setPinItemsNemo({ payload }) {
  const { session } = yield select();

  return yield call(clientApi(
    `/user/set/preferences?_client_token=${session.clientToken}`, 'POST', payload
  ));
}

function* setPinItems(action) {
  const state = yield select();
  return yield call(handleNemoApiRequest(setPinItemsNemo, action), { payload: action.payload });
}

function* Dashboard(): any {
  yield takeLatest(GET_DASHBOARD_PIN_ITEMS, getPinItems);
  yield takeLatest(SET_DASHBOARD_PIN_ITEMS, setPinItems);
}

export default Dashboard;
