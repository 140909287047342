import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';
import { DNSTypes } from '../constants';
import DNSFields from '../dns-fields';

type Props = {
  intl: Intl;
  onSubmit: Function;
  sPanelFormSubmit: (formName) => null;
  type: DNSTypes;
  getFormValues: Function;
  domainName: string;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_DNS_DIALOG;

class ChangeDNS extends React.Component<Props, any> {
  render() {
    const { domainName, intl, type, validationUtils, getFormValues } = this.props;

    return (
      <Grid>
        <DNSFields
          domainName={domainName}
          dnsType={type}
          validationUtils={validationUtils}
          intl={intl}
          formName={formName}
          getFormValues={getFormValues}
        />
      </Grid>
    );
  };
}

export default injectIntl(
  withSpanelForm(
    reduxForm({
      form: formName
    })(ChangeDNS)
  )
);
