import * as React from 'react';
import { Button, Flex, Icon, Label, Link, Section, Text } from 'sg-styleguide';
import { openNewTab } from 'sg-styleguide/lib/utils';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { getAppHost } from '../../../core/common/route-utils';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import { getSpamExpertsDomains } from '../../../core/selectors';
// COMPONENTS
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import VCS from '../../containers/visibility-control-service';
import MxRecords from './mx-records';

type SpamExpertsDomain = {
  id: number;
  name: string;
  se_mx: number;
  settings: {
    se_enabled: number;
  }
};

type Props = {
  items: {};
  actions: {
    createItem: CreateItem;
  };
  routing: {
    locationBeforeTransitions: {
      pathname: string;
      search: string;
    }
  };
  openSGDialog: Function;
  closeSGDialog: Function;
  spamExpertsDomains: SpamExpertsDomain[];
  intl: Intl;
};

type State = {
  viewDetailsPayload: string;
};

export const isInactive = (seEnabled: number) => seEnabled === 0;
export const isPending = (seEnabled: number, seMx: number) => seEnabled && seMx === 0;
export const isActive = (seEnabled: number, seMx: number) => seEnabled && seMx === 1;

const tableStatusStyles = { marginLeft: '10px' };

export const generateLogoutUrl = (
  host: string,
  pathname: string,
  query: string
) => `${host}${pathname}${query}`;

class SpamExperts extends React.Component<Props, State> {
  readonly state = {
    viewDetailsPayload: null
  };

  renderStatus = (seMx: number, { settings, name }: Partial<SpamExpertsDomain>) => {
    const { intl, openSGDialog } = this.props;

    switch (true) {
      case isActive(settings.se_enabled, seMx):
        return (
          <Label size="medium" type="active-link" padding={['inherit', 'inherit', 'inherit', 'none']}>
            {intl.formatMessage({ id: 'translate.page.spam-experts.status.active' })}
          </Label>
        );
      case isPending(settings.se_enabled, seMx):
        return (
          <Flex gutter="none" margin="none" align="center">
            <Label size="medium" type="default-link" padding={['inherit', 'inherit', 'inherit', 'none']}>
              {intl.formatMessage({ id: 'translate.page.spam-experts.status.pending' })}
            </Label>
            <Flex gutter="none" margin="none" align="center" style={tableStatusStyles}>
              <Icon name="warning" color="warning" size="14" />
              &nbsp;
              <Text color="warning" size="small">
                {intl.formatMessage({ id: 'translate.page.spam-experts.errors.mx-records' })}
              </Text>
              &nbsp;
              <Link color="secondary" style={tableStatusStyles}>
                <Text
                  color="secondary"
                  weight="bold"
                  size="small"
                  onClick={() => this.setState({
                    viewDetailsPayload: name
                  }, () => openSGDialog(DIALOGS.MX_RECORDS))}
                >
                  {intl.formatMessage({ id: 'translate.generic.view.details' })}
                </Text>
              </Link>
            </Flex>
          </Flex>
        );
      case isInactive(settings.se_enabled):
      default:
        return (
          <Label size="medium" type="inactive-link" padding={['inherit', 'inherit', 'inherit', 'none']}>
            {intl.formatMessage({ id: 'translate.page.spam-experts.status.inactive' })}
          </Label>
        );
    }
  };

  renderActions = (seMx: number, { id, name, settings }: Partial<SpamExpertsDomain>) => {
    const { intl } = this.props;

    switch (true) {
      case isActive(settings.se_enabled, seMx):
        return (
          <Button
            onClick={() => this.login(name, id)}
            color="secondary"
            size="medium"
            type="outlined"
          >
            {intl.formatMessage({ id: 'translate.page.spam-experts.actions.login' })}
          </Button>
        );
      case isPending(settings.se_enabled, seMx):
        return (
          <Label size="medium" type="light-link" padding={['inherit', 'inherit', 'inherit', 'none']}>
            {intl.formatMessage({ id: 'translate.page.spam-experts.actions.login' })}
          </Label>
        );
      case isInactive(settings.se_enabled):
      default:
        return (
          <Button color="secondary" size="medium" type="outlined">
            {intl.formatMessage({ id: 'translate.page.spam-experts.actions.activate' })}
          </Button>
        );
    }
  };

  render() {
    const { intl, spamExpertsDomains, closeSGDialog } = this.props;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-spam-expert"
          title={intl.formatMessage({ id: 'translate.page.spam-experts.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.spam-experts.info-text' })}
        />
        <VCS resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi} hasMethods={['GET']}>
          <Section>
            <SGTable
              title={intl.formatMessage({ id: 'translate.page.spam-experts.table.title' })}
              data={spamExpertsDomains}
              resources={[{
                resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
                methods: ['GET']
              }]}
              rowResources={[{
                resourceName: API_RESOURCE.SPAM_EXPERTS_LOGIN.resourceName,
                methods: ['POST']
              }]}
              columns={[{
                header: intl.formatMessage({ id: 'translate.generic.domain' }),
                accessor: 'name'
              }, {
                header: intl.formatMessage({ id: 'translate.generic.status' }),
                accessor: 'se_mx',
                render: this.renderStatus
              }, {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'se_mx',
                render: this.renderActions
              }]}
            />
          </Section>
        </VCS>

        <MxRecords
          domainName={this.state.viewDetailsPayload}
          onClose={() => closeSGDialog(DIALOGS.MX_RECORDS)}
        />
      </React.Fragment>
    );
  }

  login = (domainName: string, itemId) => {
    const { actions, routing } = this.props;

    const logoutUrl = generateLogoutUrl(
      getAppHost(),
      routing.locationBeforeTransitions.pathname,
      routing.locationBeforeTransitions.search
    );

    actions.createItem({
      domain_name: domainName,
      logout_url: logoutUrl,
      _metaFields: {
        itemId,
        ...API_RESOURCE.SPAM_EXPERTS_LOGIN
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {},
          error: {}
        }
      }
    }, ({ login_url }) => openNewTab(login_url));
  };
}

export default indexWithCRUD(({ environment, pageItems, routing }: RootState) => ({
  environment,
  routing,
  spamExpertsDomains: getSpamExpertsDomains({ pageItems })
}), (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
}))(
  SpamExperts,
  API_RESOURCE.DOMAIN_ALL
);
