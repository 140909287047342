// TODO
// import { config } from 'sg-bootstrap-spa';

export default {
  // SPANEL ROUTES
  INDEX: '/',
  DASHBOARD: '/dashboard',
  BACKUP: '/backup-restore',
  BACKUP_RESTORE_MANAGE: '/backup-restore-manage',
  BACKUP_RESTORE_HISTORY: '/backup-restore-history',
  CLOUDFLARE: '/cloudflare',
  EMAIL: '/email',
  EMAIL_AUTHENTICATION: '/emailauth',
  EMAIL_AUTORESPONDER: '/email-autoresponder',
  EMAIL_FILTERS: '/email-filters',
  EMAIL_FORWARD: '/email-forward',
  SPAM_EXPERTS: '/spam-experts',
  SSH: '/ssh',
  CRON: '/cron',
  CRON_JOB: '/cron-job',
  CRON_NOTIFICATION: '/cron-notification',
  PHP_SETTINGS: '/php-settings',
  LOCATION_IP_BLOCK: '/location-ip-block',
  REDIRECT: '/redirect',
  CACHER: '/cacher',
  CACHER_STATIC_CACHE: '/cacher-static-cache',
  CACHER_DYNAMIC_CACHE: '/cacher-dynamic-cache',
  CACHER_MEMCACHED: '/cacher-memcached',
  DNS: '/dns',
  MYSQL: '/mysql',
  MYSQL_DATABASE: '/mysql-database',
  MYSQL_USER: '/mysql-user',
  MYSQL_ACCESS: '/mysql-access',
  MYSQL_PHP_MY_ADMIN: '/mysql-phpmyadmin',
  PGSQL: '/pgsql',
  PGSQL_DATABASE: '/pgsql-database',
  PGSQL_USER: '/pgsql-user',
  PGSQL_ACCESS: '/pgsql-access',
  PROTECTED: '/protected',
  PROTECTED_URLS: '/protected-urls',
  PROTECTED_USERS: '/protected-users',
  SUBDOMAIN: '/subdomain',
  PARKED_DOMAINS: '/parked-domains',
  FTP: '/ftp',
  FILE_MANAGER: '/filemanager',
  WP_AUTOUPDATE: '/wp-autoupdate',
  WP_MANAGE: '/wp-manage',
  WP_STAGING: '/wp-staging',
  WP_TRANSFER: '/wp-transfer',
  SSL: '/ssl',
  RESOURCE_STATS: '/resource-stats',
  HTTPS_ENFORCE: '/https-enforce',
  ERROR_LOGS: '/error-log',
  APP_MANAGER: '/app-installer',
  GIT: '/git',
  SG_SITESCANNER: '/sg-sitescanner'
};
