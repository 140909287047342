import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IconButton, IconButtonProps } from 'sg-styleguide/';
import { getFileMangerToolbarPermission, RootState } from '../../../../core/reducers';

import * as fileManagerActions from '../core/actions/file-manager';

import './action-toolbar.scss';

import DynamicToolbar from './dynamic-toolbar';

const iconsDefaultProps: Partial<IconButtonProps> = {
  size: 'large',
  shape: 'circle'
};

class FileManagerActionButtons extends React.Component<any, any> {
  render() {
    const { codeEditorIsVisible } = this.props;

    return codeEditorIsVisible ? this.renderCodeEditorHeader() : this.renderMainActionButton();
  }

  renderMainActionButton() {
    const { menuAction, intl } = this.props;
    const {
      canCreateDir,
      canCreateFile,
      canEdit,
      canCopy,
      canDownload,
      canPaste,
      canMove,
      canRename,
      canDelete,
      canExtract,
      canArchive,
      canChangePermissions,
      isProtected,
      canShowFolderSize
    } = this.props.fileMangerActionPermission;

    return (
      <DynamicToolbar>
        <IconButton
          icon="file-new"
          tooltip={intl.formatMessage({ id: 'translate.generic.new.file' })}
          data-e2e="header-new-file"
          onClick={() => menuAction.createFile()}
          disabled={!canCreateFile}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="folder-new"
          tooltip={intl.formatMessage({ id: 'translate.generic.new.folder' })}
          data-e2e="header-new-folder"
          onClick={() => menuAction.createDir()}
          disabled={!canCreateDir}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="file-upload"
          tooltip={intl.formatMessage({ id: 'translate.generic.file.upload' })}
          data-e2e="header-upload-file"
          onClick={() => menuAction.openFileUpload()}
          disabled={!canCreateFile}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="folder-upload"
          tooltip={intl.formatMessage({ id: 'translate.generic.folder.upload' })}
          data-e2e="header-upload-folder"
          onClick={() => menuAction.openFolderUpload()}
          disabled={!canCreateDir}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="edit"
          tooltip={intl.formatMessage({ id: 'translate.generic.edit' })}
          data-e2e="header-edit"
          onClick={() => this.props.codeEditor.openFile()}
          disabled={!canEdit}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="rename"
          tooltip={intl.formatMessage({ id: 'translate.generic.rename' })}
          data-e2e="header-rename"
          onClick={() => this.props.menuAction.renameDir()}
          disabled={isProtected || !canRename}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="copy"
          tooltip={intl.formatMessage({ id: 'translate.file.manager.copy' })}
          data-e2e="header-copy"
          onClick={() => this.props.menuAction.markForCopy()}
          disabled={!canCopy}
          {...iconsDefaultProps}
        />

        {/*copy to*/}

        <IconButton
          icon="paste"
          tooltip={intl.formatMessage({ id: 'translate.generic.paste' })}
          data-e2e="header-paste"
          onClick={() => this.props.menuAction.pasteDir()}
          disabled={!canPaste}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="move"
          tooltip={intl.formatMessage({ id: 'translate.generic.move' })}
          data-e2e="header-move"
          onClick={() => this.props.menuAction.moveDir()}
          disabled={isProtected || !canMove}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="download"
          tooltip={intl.formatMessage({ id: 'translate.generic.download' })}
          data-e2e="header-download"
          onClick={() => this.props.menuAction.downloadFile()}
          disabled={!canDownload}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="trash"
          tooltip={intl.formatMessage({ id: 'translate.generic.delete' })}
          data-e2e="header-delete"
          onClick={() => this.props.menuAction.deleteDir()}
          disabled={isProtected || !canDelete}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="archive"
          tooltip={intl.formatMessage({ id: 'translate.generic.archive' })}
          data-e2e="header-archive"
          onClick={() => this.props.menuAction.archiveDir()}
          disabled={!canArchive}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="extract"
          tooltip={intl.formatMessage({ id: 'translate.generic.extract' })}
          data-e2e="header-extract"
          onClick={() => this.props.menuAction.extractDir()}
          disabled={!canExtract}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="key"
          tooltip={intl.formatMessage({ id: 'translate.generic.change.permissions' })}
          data-e2e="header-change-permissions"
          onClick={() => this.props.menuAction.changeEntityPermissions()}
          disabled={isProtected || !canChangePermissions}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="folder-size"
          tooltip={intl.formatMessage({ id: 'translate.generic.show.folder.size' })}
          data-e2e="header-show-folder-size"
          onClick={() => this.props.menuAction.fetchDirWithFolderSize()}
          disabled={!canShowFolderSize}
          {...iconsDefaultProps}
        />
      </DynamicToolbar>
    );
  }

  renderCodeEditorHeader() {
    const { intl, codeEditorFiles, menuAction, codeEditor } = this.props;
    const { canCreateFile } = this.props.fileMangerActionPermission;

    return (
      <DynamicToolbar>
        <IconButton
          icon="file-new"
          tooltip={intl.formatMessage({ id: 'translate.generic.new.file' })}
          data-e2e="header-new-file"
          disabled={!canCreateFile}
          onClick={() => menuAction.createFile()}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="save"
          tooltip={intl.formatMessage({ id: 'translate.generic.save' })}
          data-e2e="header-save"
          onClick={() => this.props.menuAction.saveFile()}
          disabled={codeEditorFiles.length === 0}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="tabs-close"
          tooltip={intl.formatMessage({ id: 'translate.file.manager.close.all.tabs' })}
          data-e2e="header-close-all-tabs"
          onClick={() => codeEditor.closeTabsWithConfirmation()}
          disabled={codeEditorFiles.length === 0}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="search"
          tooltip={intl.formatMessage({ id: 'translate.generic.search' })}
          data-e2e="header-search"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.openFindPanel}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="replace"
          tooltip={intl.formatMessage({ id: 'translate.generic.replace' })}
          data-e2e="header-replace"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.openFindReplacePanel}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="download"
          tooltip={intl.formatMessage({ id: 'translate.generic.download' })}
          data-e2e="header-download"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.downloadActiveTabContent}
          {...iconsDefaultProps}
        />
      </DynamicToolbar>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  codeEditorFiles: state.fileManager.codeEditor.files,
  fileMangerActionPermission: getFileMangerToolbarPermission(state)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerActionButtons));
