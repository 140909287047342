import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'sg-styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import SelectDirectory from '../../../containers/dialogs/select-directory';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';
import { normalizeDirectoryData } from '../../../../core/utils/paths';

type Props = {
  change: Function;
  intl: Intl;
  validationUtils: ValidationUtils;
  openSGDialog: Function;
};

const formName = REDUX_FORM.CHANGE_HOME_DIALOG;

const defaultPath = '/';

const ChangeHome = ({ change, intl, validationUtils, openSGDialog }: Props) => (
  <React.Fragment>
    <Field
      name="home"
      type="text"
      label={intl.formatMessage({ id: 'translate.generic.directory' })}
      suffix={
        <Button
          data-e2e="button-browse-select-directory"
          action="button"
          color="dark"
          size="small"
          onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
        >
          {intl.formatMessage({ id: 'translate.generic.browse' })}
        </Button>
      }
      validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
      component={FormInput}
    />

    <SelectDirectory
      initialPath={defaultPath}
      onChange={(path) => change('home', path)}
      expandedNodes={[defaultPath]}
      nodesParentFolder={{
        name: defaultPath,
        path: defaultPath,
        value: defaultPath
      }}
      normalizeDirectoryData={normalizeDirectoryData}
    />
  </React.Fragment>
);

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, any>(undefined, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: formName })(ChangeHome)
    )
  )
);
