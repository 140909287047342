import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { blur, change, Field, reduxForm } from 'redux-form';
import { Checkbox, Flex, Grid, Input } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../../../core/constants/common';
import { RootState } from '../../../../../../core/reducers';
import FormCheckbox from '../../../../../components/form-checkbox';
import FormInput from '../../../../../components/form-input';
import { withSpanelForm } from '../../../../sg-spanel-forms/index';
import * as fileManagerActions from '../../../core/actions/file-manager';

import { FILE_MANAGER_API_RESPONSE_DIR } from '../../../core/constants/common';
import {
  getBinaryPermissions,
  getEntityInfoNumber,
  getEntityParentPath,
  getEntityPermissions,
  getEntityReadableName,
  getEntityType,
  getOctalPermission
} from '../../../core/utils';

import './chmod-permissions.scss';

class ChmodPermissionsForm extends React.Component<any, any> {
  permInput;
  form;
  state = {
    permissionsCheckboxes: {
      owner: [{
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: false
      }],
      group: [{
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: false
      }],
      all: [{
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: false
      }, {
        size: 'medium',
        checked: true
      }]
    },
    recursive: false
  };

  setDialogState = (entity) => {
    const { entities } = this.props;
    const permissionsCheckboxes = { ...this.state.permissionsCheckboxes };
    let perms = getEntityPermissions(entity);

    if (entities.length > 1) {
      perms = this.hasOnlyFilesSelected() ? '600' : '700';
    }

    this.setState({ permissionsCheckboxes: getBinaryPermissions(perms, permissionsCheckboxes) });
    this.props.change('dir_perms', perms);
    this.props.change('file_perms', perms);
  };

  componentDidMount() {
    const { entities, fetchDir } = this.props;

    fetchDir(getEntityParentPath(entities[0]));
    this.setDialogState(entities[0]);
  }

  hasFoldersSelected = () => {
    return this.props.entities.some((entity) => (
      getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY
    ));
  };

  hasFilesSelected = () => {
    return this.props.entities.some((entity) => (
      getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE
    ));
  };

  hasOnlyFilesSelected = () => {
    return this.hasFilesSelected() && !this.hasFoldersSelected();
  };

  hasOnlyFoldersSelected = () => {
    return this.hasFoldersSelected() && !this.hasFilesSelected();
  };

  hasFilesAndFoldersSelected = () => {
    return this.hasFoldersSelected() && this.hasFilesSelected();
  };

  render() {
    const { entities, intl, validationUtils } = this.props;
    const selectedEntitiesCount = entities.length;

    const title = selectedEntitiesCount === 1 ?
      intl.formatMessage(
        { id: 'translate.file.manager.permissions.dialog.title.single.entity' },
        { entityName: getEntityReadableName(entities[0]) }
      ) :
      intl.formatMessage(
        { id: 'translate.file.manager.permissions.dialog.title.multiple.entities' },
        { entitiesCount: selectedEntitiesCount }
      );
    const hasOnlyFilesSelected = this.hasOnlyFilesSelected();
    // TODO mobile design ?
    return (
      <React.Fragment>
        <Grid sm="4" autoflow="column">
          <Flex className="chmod-table--column" direction="column" gutter="none">
            <div className="chmod-table--header" />
            <div className="chmod-table-side-header">{intl.formatMessage({ id: 'translate.generic.read' })}</div>
            <div className="chmod-table-side-header">{intl.formatMessage({ id: 'translate.generic.write' })}</div>
            <div className="chmod-table-side-header">{intl.formatMessage({ id: 'translate.generic.execute' })}</div>
          </Flex>
          {this.renderPermissionsChmodTable()}
        </Grid>

        <Field
          name={hasOnlyFilesSelected ? 'file_perms' : 'dir_perms'}
          label={intl.formatMessage({ id: 'translate.page.permissions.value' })}
          onRefsReady={
            (permInput) => {
              this.permInput = permInput;
            }
          }
          validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
          onChange={(event) => {
            const { change } = this.props;
            const permissionsCheckboxes = getBinaryPermissions(event.target.value, this.state.permissionsCheckboxes);

            this.setState({ permissionsCheckboxes });

            change('file_perms', getOctalPermission(permissionsCheckboxes));
            change('dir_perms', getOctalPermission(permissionsCheckboxes));
          }}
          component={FormInput}
        />

        {!hasOnlyFilesSelected && (
          <Flex className="chmod-recursive-checkbox" gutter="none" margin="none">
            <Field
              name="recursive"
              disabled={!this.hasFoldersSelected()}
              component={FormCheckbox}
            >
              {intl.formatMessage({ id: 'translate.generic.recursive' })}
            </Field>
          </Flex>
        )}
      </React.Fragment>
    );
  }

  renderPermissionsChmodTable = () => {
    const { intl } = this.props;
    const { permissionsCheckboxes } = this.state;

    return Object.keys(permissionsCheckboxes).map((key, i) =>
      <Flex className="chmod-table--column" direction="column" key={i}  align="center" gutter="none" margin="none">
        <div className="chmod-table--header">{intl.formatMessage({ id: 'translate.generic.' + key })}</div>
        {permissionsCheckboxes[key].map((checkbox, j) => {
          return (
            <Checkbox key={j} {...checkbox} onChange={() => this.handleCheckboxOnChange(key, j)} />
          );
        })}
      </Flex>
    );
  };

  handleCheckboxOnChange = (key, j) => {
    const permissionsCheckboxesClone: any = { ...this.state.permissionsCheckboxes };
    permissionsCheckboxesClone[key][j].checked = !permissionsCheckboxesClone[key][j].checked;
    this.setState({
      permissionsCheckboxes: permissionsCheckboxesClone
    });

    this.props.change('file_perms', getOctalPermission(permissionsCheckboxesClone));
    this.props.change('dir_perms', getOctalPermission(permissionsCheckboxesClone));
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  fileManagerEntities: state.fileManager.entities
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: REDUX_FORM.FILE_MANAGER_PERMISSIONS })(ChmodPermissionsForm)
    )
  )
);
