import {
  GET_BACKUP_ORDER_DATA,
  CREATE_BACKUP,
  CONFIRM_BACKUP_UPGRADE,
  BACKUP_ORDER_DATA_LOADED,
  CANCEL_BACKUP_UPGRADE
} from '../../constants/actions';

import customRequestTypes from '../../constants/custom-request-types';

export const getBackupOrderData = () => ({
  type: GET_BACKUP_ORDER_DATA,
  requestTypeName: customRequestTypes.GET_BACKUP_ORDER_DATA
});

export const backupOrderDataLoaded = (data) => ({ type: BACKUP_ORDER_DATA_LOADED, data });

export const createBackup = (createFormData: CreateItemPayload, {
  onUpgradeRequired,
  onUANotAvailable
}) => ({
  type: CREATE_BACKUP,
  payload: {
    onUpgradeRequired,
    onUANotAvailable,
    createFormData
  }
});

export const confirmBackupUpgrade = ({ planType }) => ({
  type: CONFIRM_BACKUP_UPGRADE,
  payload: {
    planType
  }
});

export const cancelBackupUpgrade = () => ({
  type: CANCEL_BACKUP_UPGRADE
});
