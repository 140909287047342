import * as React from 'react';
import { Field } from 'redux-form';
import { Column } from 'sg-styleguide';
import FormDropdown from '../../components/form-dropdown';
import FormInput from '../../components/form-input';
import { DNSTypes } from './constants';
import { findPredefinedTTLOption, getTTLOptions } from './utils';

type Props = {
  dnsType: DNSTypes;
  validationUtils: ValidationUtils;
  getFormValues: Function;
  domainName: string;
  formName: string;
  intl: Intl;
};

export class DNSFields extends React.Component<Props, {}> {
  generateTTLOptions = () => {
    const { formName, getFormValues, intl } = this.props;
    const formValues = getFormValues(formName);

    const ttlValue = formValues && formValues.ttl;
    const ttlPredefinedOption = findPredefinedTTLOption(ttlValue);

    if (!ttlValue || ttlPredefinedOption) {
      return getTTLOptions();
    }

    return [{
      seconds: ttlValue,
      label: `${ttlValue} ${intl.formatMessage({ id: 'translate.generic.time.seconds' })}`
    }].concat(getTTLOptions());
  };

  renderAField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.ipv4-address.label' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderAAAAField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.ipv6-address.label' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderCNAMEField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[required, validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.resolves-to.label' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderMXField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="prio"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.prio' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.destination.label' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderSRVField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="service"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.service' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="proto"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.proto' })}
            options={[
              { label: 'TCP', value: 'tcp' },
              { label: 'UDP', value: 'udp' },
              { label: 'TLS', value: 'tls' }
            ]}
            optionValue="value"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="prio"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.prio' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="weight"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.weight' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="port"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.port' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.target.label' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderTXTField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={this.generateTTLOptions()}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.value' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  render() {
    const { dnsType } = this.props;

    switch (dnsType) {
      case DNSTypes.DNS_A:
        return this.renderAField();
      case DNSTypes.DNS_AAAA:
        return this.renderAAAAField();
      case DNSTypes.DNS_CNAME:
        return this.renderCNAMEField();
      case DNSTypes.DNS_MX:
        return this.renderMXField();
      case DNSTypes.DNS_SRV:
        return this.renderSRVField();
      case DNSTypes.DNS_TXT:
        return this.renderTXTField();
      default:
        return this.renderAField();
    }
  }
};

export default DNSFields;
