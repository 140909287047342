const readFile = (entry) => new Promise((resolve) => {
  entry.file((file) => {
    file.spanelFullPathForAPI = entry.fullPath;

    resolve(file);
  });
});

const dirTraverse = (dirEntry) => {
  const reader = dirEntry.createReader();

  return new Promise((resolve) => {
    const iterationAttempts = [];

    (function readEntries() {
      reader.readEntries((entries) => {
        if (entries.length === 0) {
          return resolve(Promise.all(iterationAttempts));
        }

        iterationAttempts.push(Promise.all(entries.map((entry) => {
          if (entry.isFile) {
            return readFile(entry);
          }

          return dirTraverse(entry);
        })));

        readEntries();
      }, (error) => console.error(error));
    })();
  });
};

export default dirTraverse;
