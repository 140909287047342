import { RootState } from '../reducers';
import { sgSiteScannerDataTypes } from '../constants/sg-sitescanner';
import * as sgSiteScannerTypes from '../definitions/sg-sitescanner';

export const isReportStatusClean = (lastReport: sgSiteScannerTypes.NormalizedReport) => {
  if (!lastReport) {
    return true;
  }

  const { blacklist, malware, status } = lastReport;

  return Boolean(
    status !== 'timeout' &&
    blacklist.length === 0 &&
    malware.length === 0
  );
};

export const isLastStatusClean = (state: RootState) => {
  const { sgSiteScanner } = state;
  const reports: sgSiteScannerTypes.NormalizedReports = sgSiteScanner.data[sgSiteScannerDataTypes.reports];

  if (!reports) {
    return true;
  }

  return isReportStatusClean(Object.values(reports)[0]);
};
