import * as React from 'react';
import { injectIntl } from 'react-intl';
import { cn, Container, Flex, Grid, Icon, IconButton, Spacer, Tile, Title } from 'sg-styleguide';
import './dashboard-tile.scss';

type Props = {
  intl: Intl;
  isInEditView: boolean;
  isTilePinned: boolean;
  handlePinClick: (event) => void;
  onClick: (event) => void;
  item: {
    pageIcon: string;
    pageTitle: string;
    stateId: string;
  };
};

type State = {
  triggerClose: boolean;
  didMount: boolean;
};

class DashboardTile extends React.Component<Props, State> {
  readonly state = {
    triggerClose: false,
    didMount: false
  };

  componentDidMount() {
    this.setState({ didMount: true });
  }

  renderTileContent = () => {
    const { item, isInEditView, handlePinClick, isTilePinned, intl } = this.props;
    const { pageIcon, pageTitle } = item;

    const iconClasses = cn(
      'dashboard-tile__pin-icon',
      isTilePinned && 'dashboard-tile__pin-icon--pinned'
    );

    return (
      <React.Fragment>
        {isInEditView && (
          <IconButton
            className={iconClasses}
            icon={isTilePinned ? 'pin-filled' : 'pin'}
            shape="circle"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({ triggerClose: true }, () => setTimeout(handlePinClick, 300));
            }}
          />
        )}

        <Flex direction="column" align="center" margin="none" gutter="none">
          <Icon size="90" name={pageIcon} multicolor />

          {pageTitle && (
            <Title className="dashboard-tile__title" level="5" density="none" align="center">
              {intl.formatMessage({ id: pageTitle })}
            </Title>
          )}
        </Flex>
      </React.Fragment>
    );
  };

  render() {
    const { isInEditView, onClick } = this.props;
    const { didMount, triggerClose } = this.state;
    const classes = cn(
      'dashboard-tile',
      !isInEditView && 'dashboard-tile--clickable',
      isInEditView && didMount && 'dashboard-tile--animation-mount',
      isInEditView && triggerClose && 'dashboard-tile--animation-unmount'
    );

    return (
      <div className={classes} onClick={onClick}>
        {isInEditView ? (
          <Tile className="dashboard-tile__content">
            {this.renderTileContent()}
          </Tile>
        ) : (
          <Container className="dashboard-tile__content">
            {this.renderTileContent()}
          </Container>
        )}
      </div>
    );
  };
}

export default injectIntl(DashboardTile);
