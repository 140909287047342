import { getDeviceInformation, scrollToElement } from 'sg-styleguide/lib/utils';

const isParentOfHiddenElement = (node) => {
  let currentNode = node;
  while (currentNode && currentNode.nodeName !== 'BODY') {
    if (currentNode.style.display === 'none' || currentNode.style.visibility === 'hidden') {
      return true;
    }

    currentNode = currentNode.parentNode;
  }

  return false;
};

export const focusFormInput = (fieldName, formName) => {
  const form = document.querySelector(`[name^="${formName}"]`);
  const fields: any = form ?
    form.querySelectorAll(`[name^="${fieldName}"], [data-name^="${fieldName}"]`) :
    document.querySelectorAll(`[name^="${fieldName}"], [data-name^="${fieldName}"]`);

  const field: any = Array
    .from(fields)
    .find((element: any) => !isParentOfHiddenElement(element));

  if (field) {
    field.focus();
  }
};

export const removeFocusFromCurrentElement = () => {
  const activeElement = document.activeElement as any;
  activeElement.blur();
};

export const scrollPageToTop = () => {
  const [page, ...other] = Array.from(document.querySelectorAll('[data-component="page"]'));

  if (page) {
    page.scrollTop = 0;
  }
};

const isElementInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrollToElementWrapper = (el, target) => {
  const device = getDeviceInformation();

  if (isElementInViewport(el)) {
    return;
  }

  if (device.isDevicePhone) {
    try {
      target.scrollTop = el.offsetTop + el.offsetHeight;
    } catch (e) {
      console.warn(e);
    }
  } else {
    scrollToElement(el, { behavior: 'smooth', block: 'end' });
  }
};
