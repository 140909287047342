import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Navigation, NavigationList, NavigationListItem } from 'sg-styleguide';
import { navigateToUA } from '../../../../core/actions/nemo-store';
import ROUTES from '../../../../core/constants/routes';
import { RootState } from '../../../../core/reducers';
import { hasPermission } from '../../../../core/selectors/site-meta-api';
import * as menuItems from '../../../../static-api/menu-items';
import SGLink from '../../../components/sg-link';
import SiteSelect from '../site-select';
import './navigation.scss';

type Props = {
  intl?: Intl;
  location?: any;
  router?: { push: Function };
  routing?: any;
  siteMetaApi?: any;
  toggleDrawer: Function;
  navigateToUA?: Function;
  i18n?: any;
};

type State = {
  selectedGroups: string[];
};

const MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED = 1500;
const DASHBOARD_GROUP_ID = 'dashboard';

const ICONS = {
  dashboard: 'dashboard',
  sites: 'websites',
  security: 'lock',
  speed: 'rocket',
  wordpress: 'wordpress',
  domains: 'domain',
  mail: 'mail',
  stats: 'statistics',
  devs: 'devs'
};

class SGNavigation extends React.Component<Props, State> {
  readonly state = {
    selectedGroups: []
  };

  componentDidMount() {
    this.expandNavigation();
  }

  componentDidUpdate() {
    this.expandNavigation();
  }

  getPathNameWhitOutSlash() {
    const { routing } = this.props;

    if (!routing || !routing.locationBeforeTransitions || !routing.locationBeforeTransitions.pathname) {
      return;
    }

    return routing.locationBeforeTransitions.pathname.replace(/^\//, '');
  }

  expandNavigation = () => {
    const selectedGroup = menuItems.groups
      .find((group: any) => group.items.find((item) => {
        const path = this.getPathNameWhitOutSlash();
        return item.stateId === path || (item.subStates && item.subStates.includes(path));
      }));

    if (!selectedGroup) {
      return;
    }

    const shouldExpandNavigation = !this.state.selectedGroups.includes(selectedGroup.groupId);

    if (shouldExpandNavigation) {
      this.selectNavigationGroup();
    }
  };

  selectNavigationGroup() {
    const activeGroup = menuItems.groups.find((group: any) => {
      if (!group.items) {
        return false;
      }

      return group.items.find((item) => {
        const pathsToCheck = [item.stateId].concat(item.subStates);
        return Boolean(pathsToCheck.find(this.shouldSelectNavigationEntity));
      });
    });

    if (activeGroup) {
      const selectedGroups = [...Array.from(new Set(this.state.selectedGroups.concat(activeGroup.groupId)))];
      this.setState({ selectedGroups });
    }
  }

  handleNavigationClick = (groupId) => {
    const { selectedGroups } = this.state;
    const isItemSelected = selectedGroups.find(((item) => item === groupId));

    if (groupId === DASHBOARD_GROUP_ID) {
      this.onNavigationLinkClicked();
    }

    if (isItemSelected) {
      return this.setState({ selectedGroups: selectedGroups.filter((items) => items !== groupId) });
    }

    return this.setState({ selectedGroups: selectedGroups.concat(groupId) });
  };

  shouldSelectNavigationEntity = (path) => {
    const { routing } = this.props;

    if (!routing || !routing.locationBeforeTransitions || !routing.locationBeforeTransitions.pathname || !path) {
      return false;
    }

    const pathname = routing.locationBeforeTransitions.pathname.replace(/^\//, '');
    const isDefaultPageSelected = !pathname && path === ROUTES.DASHBOARD.replace(/^\//, '');

    return (path.replace(/^\//, '') === pathname) || isDefaultPageSelected;
  };

  onNavigationLinkClicked = () => {
    if (window.innerWidth < MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED) {
      this.props.toggleDrawer();
    }
  };

  render() {
    const { intl, navigateToUA } = this.props;

    return (
      <Navigation>
        <NavigationList
          key="ua-redirect-link"
          className="ua-redirect-link"
          title={
            <span className="ua-redirect-link__text">
              {intl.formatMessage({ id: 'translate.navigate-to-ua-link.label' })}
            </span>
          }
          icon="arrow-back"
          iconSize="12"
          onClick={navigateToUA}
          active={false}
        />
        <SiteSelect />
        {this.renderNavigation()}
      </Navigation>
    );
  }

  renderNavigation() {
    const { intl } = this.props;

    return menuItems.groups.map((group) => {
      const itemTranslatedTitle = intl.formatMessage({ id: group.title });

      return (
        <NavigationList
          key={group.groupId}
          title={itemTranslatedTitle}
          icon={ICONS[group.groupId]}
          open={group.items && group.items.length > 0 && this.state.selectedGroups.includes(group.groupId)}
          data-e2e={`navigation-group-${group.groupId}`}
          onClick={() => {
            this.handleNavigationClick(group.groupId);
            if (group.stateId) {
              this.props.router.push(`${group.stateId}${this.props.location.search}`);
            }
          }}
          active={this.shouldSelectNavigationEntity(group.stateId)}
        >
          {this.renderNavigationListItem(group)}
        </NavigationList>
      );
    });
  }

  renderNavigationListItem(group) {
    const { intl } = this.props;

    return group.items.map((item) => {
      const itemTranslatedTitle = intl.formatMessage({ id: item.title });

      const pathsToCheck = [item.stateId].concat(item.subStates);
      const isActive = Boolean(pathsToCheck.find(this.shouldSelectNavigationEntity));

      if (!hasPermission(this.props.siteMetaApi, item.pageEndpoint)) {
        return null;
      }

      return (
        <NavigationListItem
          key={item.stateId}
          active={isActive}
        >
          <SGLink
            to={item.stateId}
            text={itemTranslatedTitle}
            data-e2e={`navigation-list-item-${item.stateId}`}
            onClick={this.onNavigationLinkClicked}
          />
        </NavigationListItem>
      );
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  siteMetaApi: state.siteMetaApi,
  routing: state.routing,
  i18n: state.i18n // needed for show/hide translations logic
});

export default connect<{}, {}, Props>(mapStateToProps, (dispatch) => ({
  navigateToUA: () => dispatch(navigateToUA({ command: 'redirect' }))
}))(withRouter(injectIntl(SGNavigation)));
