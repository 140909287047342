import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ContextMenu, ContextMenuItem, Icon, IconButton } from 'sg-styleguide';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { API_RESOURCE } from '../../../core/constants/api';

type Props = {
  app: WordpressApp;
  onActionClicked: (actionId: string, app: WordpressApp) => void;
  intl: Intl;
};

export const WpStagingContextMenu = ({ onActionClicked, app, intl }: Props) => {
  const contextItems = [{
    vcsMethod: 'PUT',
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.full.deploy' }),
    e2eAttr: 'wp-staging-context-menu-item-full-deploy',
    icon: 'deploy',
    onClick: () => onActionClicked('full-deploy', app)
  }, {
    vcsMethod: 'PUT',
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.custom.deploy' }),
    e2eAttr: 'wp-staging-context-menu-item-custom-deploy',
    icon: 'custom-deploy',
    onClick: () => onActionClicked('custom-deploy', app)
  }, {
    vcsMethod: 'DELETE',
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.destroy' }),
    e2eAttr: 'wp-staging-context-menu-item-destroy',
    icon: 'destroy',
    onClick: () => onActionClicked('destroy', app)
  }, {
    vcsMethod: 'POST',
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.replicate' }),
    e2eAttr: 'wp-staging-context-menu-item-replicate',
    icon: 'replicate',
    onClick: () => onActionClicked('replicate', app)
  }, {
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.login.admin.panel' }),
    e2eAttr: 'wp-staging-context-menu-item-log-in-admin',
    icon: 'login',
    shortcut: <Icon color="dark" name="external-link" size="14" />,
    onClick: () => onActionClicked('log-in-to-admin', app)
  }, {
    label: intl.formatMessage({ id: 'translate.page.staging.table.action.rename' }),
    e2eAttr: 'wp-staging-context-menu-item-rename',
    icon: 'rename',
    onClick: () => onActionClicked('rename', app)
  }];

  return (
    <TableContextMenu
      entity={app}
      resourceName={API_RESOURCE.APP.resourceNameMetaApi}
      items={contextItems}
    />
  );
};

export default injectIntl(WpStagingContextMenu);
