import * as Actions from '../constants/actions';
import { FileManagerRootState } from './utils/internal-state-definition';
import { getEntityInfoNumber } from '../utils/index';

const SelectionsReducer = {
  [Actions.FILE_MANAGER_SELECT_CONTENT_ROWS]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      // TODO what will be the behavior of the right context menu and context selection ???
      contextContentEntities: action.payload,
      selectedContentEntities: action.payload,
      contextNavigationEntity: null
    };
  },
  [Actions.FILE_MANAGER_MARK_FOR_COPY]: (state, action: any) => {
    return {
      ...state,
      markedForCopy: action.payload
    };
  },
  [Actions.FILE_MANAGER_UPDATE_AFTER_MOVE]: (state: FileManagerRootState, action: any) => {
    const { newInfoNumber, oldInfoNumber } = action.payload;
    const newState = { ...state };

    newState.codeEditor.files = newState.codeEditor.files.map((file) => {
      if (getEntityInfoNumber(file) === oldInfoNumber) {
        file.i = newInfoNumber;
      }

      return file;
    });

    if (getEntityInfoNumber(newState.selectedNavigationEntity) === oldInfoNumber) {
      newState.selectedNavigationEntity.i = newInfoNumber;
    }

    return newState;
  }
};
export default SelectionsReducer;
