import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Notice, Text } from 'sg-styleguide';

const RestoreFullSiteForm = ({ intl }) => (
  <Grid>
    <Notice
      background="light"
      type="warning"
      shadow={false}
      border={false}
    >
      {intl.formatMessage({ id: 'translate.page.backup.restore.full.site.restore.notice' })}
    </Notice>

    <Text align="left">
      {intl.formatMessage({ id: 'translate.page.backup.restore.full.site.restore.message' })}
    </Text>
  </Grid>
);

export default injectIntl(RestoreFullSiteForm);
