import { getDeviceInformation } from 'sg-styleguide/lib/utils';
import * as Actions from '../constants/actions';
import { MAIN_DOMAIN } from '../constants/common';
import { getEntityInfoNumber, getEntityIsExpanded, getEntityPath } from '../utils';
import { FileManagerRootState } from './utils/internal-state-definition';
import { sortByTypeAndName } from './utils/sort';

const applyNewEntityData = (state, dirs) => {
  const entities = {};

  dirs.forEach((dir) => {
    if (!Array.isArray(dir.data)) {
      console.error(dir.data.message);
      return;
    }

    entities[dir.base] = dir.data
      .sort(sortByTypeAndName)
      .map((item) => {
        // SPANEL-1274
        const previewsDataForEntity = state.entities[dir.base] &&
          state.entities[dir.base].find((ent) => getEntityInfoNumber(ent) === getEntityInfoNumber(item));
        const isExpanded = previewsDataForEntity ? getEntityIsExpanded(previewsDataForEntity) : false;

        return ({
          ...item,
          _meta: {
            isExpanded,
            isProtected: Boolean(item.sys),
            path: (dir.base + item.n).replace('//', '/')
          }
        });
      });
  });

  return entities;
};

const fetchDirSucceed = (state: FileManagerRootState, dirs) => {
  const newState: FileManagerRootState = {
    ...state,
    entities: {
      ...state.entities,
      ...applyNewEntityData(state, dirs)
    },
    time: +new Date()
  };

  // Auto select the main domain.
  if (!newState.selectedNavigationEntity) {
    const isDesktop = getDeviceInformation().isDesktop;

    if (isDesktop) {
      const selectedNavEntity = newState.entities['/'].find((entity) => entity.sys === MAIN_DOMAIN);

      // We can have site without main domain!
      newState.selectedNavigationEntity = selectedNavEntity ? selectedNavEntity : newState.entities['/'][0];
      newState.selectedNavigationEntity._meta.isExpanded = true;
    } else {
      newState.selectedNavigationEntity = null;
    }
  }

  // Update selectedNavigationEntity (rename parent) // TODO create selector for selectedNavigationEntity
  Object.keys(newState.entities).forEach((key) => {
    const selectedNavigationEntity = newState.selectedNavigationEntity && newState.entities[key].find((entity) =>
      getEntityInfoNumber(entity) === getEntityInfoNumber(newState.selectedNavigationEntity)
    );

    if (selectedNavigationEntity) {
      newState.selectedNavigationEntity = selectedNavigationEntity;
    }
  });

  // Update open files in code editor
  newState.codeEditor.files = newState.codeEditor.files.map((file) => {
    let updatedFile;

    Object.keys(newState.entities).forEach((key) => {
      updatedFile = newState.entities[key].find((entity) => getEntityInfoNumber(entity) === getEntityInfoNumber(file));
    });

    if (!updatedFile) {
      return file;
    }

    return {
      ...file,
      ...updatedFile,
      _meta: {
        ...file._meta,
        ...updatedFile._meta
      }
    };
  });

  return newState;
};

const FetchEntitiesReducer = {
  [Actions.FILE_MANAGER_FETCH_DIR_SUCCEEDED]: (state: FileManagerRootState, action: any) => {
    return fetchDirSucceed(state, [{
      base: action.urlParams.id,
      data: action.items
    }]);
  },
  [Actions.FILE_MANAGER_FETCH_DIRS_SUCCEEDED]: (state: FileManagerRootState, action: any) => {
    return fetchDirSucceed(state, action.data);
  },
  [Actions.FILE_MANAGER_FETCH_FILE_SUCCEEDED]: (state: FileManagerRootState, action: any) => {
    const newFile = action.payload;
    const files = state.codeEditor.files.map((file) => {
      file._meta.isActiveInCodeEditor = false;

      return file;
    });

    const currentFileIndex = files.findIndex((file) => getEntityPath(file) === getEntityPath(newFile));

    if (currentFileIndex !== -1) {
      files[currentFileIndex] = newFile;
    } else {
      files.push(newFile);
    }

    return {
      ...state,
      codeEditor: {
        ...state.codeEditor,
        files,
        isVisible: true
      }
    };
  }
};

export default FetchEntitiesReducer;
