import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Icon } from 'sg-styleguide';

import './tool-finder-button.scss';

const ToolFinderButton = ({ intl, onClick }) => {
  return (
    <div className="tool-finder" onClick={onClick}>
      <Icon size="14" name="search" />
      &nbsp;
      {intl.formatMessage({ id: 'translate.tool.finder.text' })}
    </div>
  );
};

export default injectIntl(ToolFinderButton);
