// TODO remove when the other pages are done

import FormWrapper from '../sg-form-util/with-spanel-form';
import RemoteSubmitWrapper from '../sg-form-util/remote-submit';
import FormMetadataWrapper from '../sg-form-util/with-redux-form-metadata';

export const withSpanelForm = FormWrapper;
export const RemoteSubmit = RemoteSubmitWrapper;
export const withFormMetadata = FormMetadataWrapper;

export { default as CreateBoxSubmitButton } from '../sg-form/submit-button';
