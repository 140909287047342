import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dropdown } from 'sg-styleguide';
import { RootState } from '../../../core/reducers/index';

import { findMainDomain } from '../../../core/selectors';
import SecondLevelTitle from '../../containers/titles/second-level-title';

type Props = {
  intl?: Intl;
  domain?: any;
  mainDomain?: any;
  domainResourceName?: string;
  options: any;
  selectedValue: any;
  onChange: Function;
  [propageteProps: string]: any;
};

class DomainSelect extends React.Component<Props, any> {
  static defaultProps: Partial<Props> = {
    onChange: () => null
  };

  componentDidMount() {
    if (this.props.mainDomain) {
      this.props.onChange(this.props.mainDomain.id);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (JSON.stringify(this.props.mainDomain) !== JSON.stringify(nextProps.mainDomain)) {
      this.props.onChange(nextProps.mainDomain.id);
    }
  }

  render() {
    const { intl, mainDomain } = this.props;

    if (!mainDomain) {
      return null;
    }

    const toPropagate = {
      ...this.props,
      selectedValue: this.props.selectedValue !== null ? this.props.selectedValue : this.props.mainDomain.id,
      onChange: (v) => {
        this.props.onChange(v);
      }
    };

    const { options } = this.props;

    return (
      <div className="domain-select">
        <SecondLevelTitle>
          {intl.formatMessage({ id: 'translate.generic.select.domain' })}
        </SecondLevelTitle>

        <Dropdown
          data-e2e="domain-select"
          options={options}
          size="x-large"
          searchable={options.length > 1}
          enableShadow
          {...toPropagate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, { domainResourceName }: Props) => ({
  domain: state.pageItems.domain,
  mainDomain: findMainDomain(state, domainResourceName)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect<any, any, Props>(mapStateToProps, mapDispatchToProps)(injectIntl(DomainSelect));
