import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid, Notice, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';

type Props = {
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG;

const FixPermissionsForm = ({ intl }: Props) => (
  <Grid>
    <Notice
      type="warning"
      background="light"
      border={false}
      shadow={false}
    >
      {intl.formatMessage({ id: 'translate.page.wp.manage.fix.permissions.notice' })}
    </Notice>
    <Text align="left">
      {intl.formatMessage({ id: 'translate.page.wp.manage.fix.permissions.text' })}
    </Text>
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(FixPermissionsForm)
  )
);
