import * as Actions from '../constants/actions';
import { FileManagerRootState } from './utils/internal-state-definition';

const ContextMenuReducer = {
  [Actions.FILE_MANAGER_ON_CONTEXT_NAVIGATION_ENTITY_CLICK]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      contextNavigationEntity: action.payload,
      contextContentEntities: []
    };
  },
  [Actions.FILE_MANAGER_CLEAR_CONTEXT_ENTITIES]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      contextNavigationEntity: null,
      contextContentEntities: []
    };
  },
  [Actions.FILE_MANAGER_SET_CONTEXT_MENU_POSITION]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      contextMenu: action.payload
    };
  }
};
export default ContextMenuReducer;
