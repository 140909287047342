import * as React from 'react';

import { CommonProps, Icon, Text } from 'sg-styleguide/';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_ICONS } from '../../core/constants/common';

import { getEntityNameExtension, getEntityParentPath, getEntityReadableName, getEntityType } from '../../core/utils';

import './entity.scss';

interface EntityProps extends CommonProps {
  entity: any;
  textWeight: any;
  size: any;
  hideName: boolean;
  showFullPath: boolean;
}

// TODO refactor Entity to have only one size and use custom css for default-view
const Entity: React.FunctionComponent = (props: Partial<EntityProps>) => {
  const { entity, textWeight, size, hideName, showFullPath } = props;

  const isFile = getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
  const icon = FILE_MANAGER_ICONS[getEntityType(entity)];
  const iconSize = size === 'medium' ? '28' : '60';
  const itemExtension = getEntityNameExtension(entity);
  const itemName = getEntityReadableName(entity);
  const itemPath = getEntityParentPath(entity);
  const entityNameWeight = showFullPath ? 'bold' : textWeight;

  const classes = [
    'file-manager-entity',
    size && `file-manager-entity--size-${size}`
  ].filter(Boolean).join(' ');

  const iconClasses = [
    'file-manager-entity-icon',
    isFile && 'file-manager-entity-icon--file-extension'
  ].filter(Boolean).join(' ');

  const nameClasses = [
    'file-manager-entity-text',
    showFullPath && 'file-manager-entity-text--with-full-path'
  ].filter(Boolean).join(' ');
  return (
    <div className={classes}>
      <div className={iconClasses} data-ext={isFile ? `.${itemExtension}` : undefined}>
        <Icon name={icon} size={iconSize} multicolor={isFile} />
      </div>

      {showFullPath && (
        <div className="file-manager-entity-wrapper-truncated">
          <Text color="dark" weight={textWeight} truncate>{itemPath}</Text>
          <Text color="dark" weight={textWeight}>/</Text>
        </div>
      )}

      {!hideName && (
        <Text className={nameClasses} color="dark" weight={entityNameWeight} truncate>{itemName}</Text>
      )}
    </div>
  );
};

Entity.defaultProps = {
  textWeight: 'medium',
  size: 'medium',
  hideName: false,
  showFullPath: false
};

export default Entity as any;
