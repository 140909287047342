import { GET_DASHBOARD_PIN_ITEMS, SET_DASHBOARD_PIN_ITEMS } from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';

export const getDashboardTiles = (): NemoRequestAction => ({
  type: GET_DASHBOARD_PIN_ITEMS,
  requestTypeName: customRequestTypes.GET_DASHBOARD_PIN_TILES
});

export const setDashboardTiles = (payload, onComplete): NemoRequestAction => ({
  type: SET_DASHBOARD_PIN_ITEMS,
  payload,
  onComplete,
  requestTypeName: customRequestTypes.SET_DASHBOARD_PIN_TILES
});
