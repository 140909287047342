import { call, select, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { API_RESOURCE } from '../../constants/api';
import { REQUEST_AUTOCONFIGURE_DATA } from '../../constants/actions';

function* requestAutoconfigureData({ payload }) {
  const { emailId, emailClientId, onDataLoaded } = payload;
  const clientId = emailClientId.includes('outlook') ? 'outlook' : emailClientId;

  const state = yield select();

  const response = yield call(siteApi({
    endpoint: `${API_RESOURCE.EMAIL.endpoint}/${emailId}`,
    method: 'PUT',
    body: {
      config: clientId
    },
    state
  }));

  const hasValidResponse = Boolean(
    response.data &&
    Number(response.data.success) === 1 &&
    response.data.config_path &&
    response.data.config_path.length > 0
  );

  if (hasValidResponse) {
    onDataLoaded(response.data.config_path);
  }

  return response;
}

function* emailAccounts(): any {
  yield takeLatest(REQUEST_AUTOCONFIGURE_DATA, handleAvalonApiRequest(requestAutoconfigureData));
}

export default emailAccounts;
