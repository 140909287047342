import { reset, startAsyncValidation, stopAsyncValidation } from 'redux-form';
import { put, select, takeEvery, call } from 'redux-saga/effects';
import * as Actions from '../constants/actions';
import * as SpanelFormActions from '../actions/form-actions';
import { selectFirstFormErrorField, selectReduxFormData } from '../selectors/form-selectors';
import { getFieldErrorsFromException } from '../selectors';

// UTILS
export const getFormNameFromActionPayload = (action) =>
  action &&
  action.payload &&
  action.payload._metaFields &&
  action.payload._metaFields.formName;

export function* isValidationErrorMatchingInput(requestAction, { message, err_fields }) {
  const formName = getFormNameFromActionPayload(requestAction);

  if (formName) {
    const formFieldErrors = getFieldErrorsFromException({ message, err_fields });
    const formData = yield select(selectReduxFormData, formName);

    if (formData && formData.registeredFields) {
      // finding a match between redux-form registered fields and BE err_fields
      const errorField = Object.keys(formFieldErrors)
        .find((fieldName) => Object.keys(formData.registeredFields).includes(fieldName));

      return Boolean(errorField);
    }
  }

  return false;
}

// HANDLING OF FORM REQUEST STARTED
export function* handleFormSubmitStarted({ payload }) {
  const { requestedAction } = payload;
  const formName = getFormNameFromActionPayload(requestedAction);

  if (formName) {
    yield put(startAsyncValidation(formName));
  }
}

// HANDLING OF FORM REQUEST COMPLETED WITH SUCCESS
export function* handleFormSubmitCompletion({ payload }) {
  const { requestedAction } = payload;

  const formName = getFormNameFromActionPayload(requestedAction);

  const shouldResetForm = Boolean(
    formName &&
    !requestedAction.payload._metaFields.disableAutoReset
  );

  if (shouldResetForm) {
    yield put(reset(formName));
  }
};

// HANDLING OF FORM REQUEST FAIL
export function* handleFormSubmitFailed({ payload }) {
  const { requestedAction, exception } = payload;
  const formName = getFormNameFromActionPayload(requestedAction);
  const validationErrorsMatchingFormInput = yield call(isValidationErrorMatchingInput, requestedAction, exception);

  if (formName && validationErrorsMatchingFormInput) {
    const formFieldErrors = getFieldErrorsFromException(exception);
    yield put(stopAsyncValidation(formName, formFieldErrors));
    const errorField: string = yield select(selectFirstFormErrorField, formName);
    yield put(SpanelFormActions.sPanelFocusField(errorField, formName));
  }
}

function* formSubmission(): any {
  yield takeEvery(Actions.HTTP_REQUEST_STARTED, handleFormSubmitStarted);
  yield takeEvery(Actions.HTTP_REQUEST_SUCCEEDED, handleFormSubmitCompletion);
  yield takeEvery(Actions.HTTP_REQUEST_FAILED, handleFormSubmitFailed);
}

export default formSubmission;
