import { handleActions } from 'redux-actions';
import * as ACTION_TYPES from '../constants/actions';
import { STATUS } from '../constants/http-requests';
import { isRequestDataMatching } from '../selectors/partial-loader';

export type HttpRequests = {
  specified: HTTPRequestState[];
  unspecified: number;
};

const initialState: HttpRequests = {
  specified: [],
  unspecified: 0
};

const crudRequestActionsMethod = {
  [ACTION_TYPES.CREATE_ITEM_REQUESTED]: 'POST',
  [ACTION_TYPES.FETCH_ITEMS_REQUESTED]: 'GET',
  [ACTION_TYPES.FETCH_ITEM_REQUESTED]: 'GET',
  [ACTION_TYPES.UPDATE_ITEM_REQUESTED]: 'PUT',
  [ACTION_TYPES.DELETE_ITEM_REQUESTED]: 'DELETE'
};

const extractRequestData = (action: any) => {
  const { type, payload = {}, requestTypeName } = action;
  const { resourceName, _metaFields = {}, itemId, id } = payload;
  const resourceItemId = itemId || id || _metaFields.itemId;

  return {
    requestTypeName,
    resourceName: payload.resourceName || _metaFields.resourceName,
    method: crudRequestActionsMethod[type],
    id: resourceItemId
  };
};

export const updateExistingRequests = (state: HttpRequests, nextRequestState: HTTPRequestState) => {
  const allOtherSpecified = state
    .specified
    .filter((existingRequestState) => !isRequestDataMatching(nextRequestState, existingRequestState, true));

  return {
    ...state,
    specified: allOtherSpecified.concat(nextRequestState)
  };
};

export const updateRequestStatus = (
  state: HttpRequests,
  nextRequestState: HTTPRequestState
) => {
  const customRequest = Boolean(nextRequestState.requestTypeName);
  const crudRequest = Boolean(
    nextRequestState.resourceName &&
    nextRequestState.method
  );

  if (customRequest || crudRequest) {
    return updateExistingRequests(state, nextRequestState);
  }

  // fallback for unspecified requests, TODO: handle silent requests
  const counterUpdate = nextRequestState.status === STATUS.REQUESTED ? 1 : -1;
  return {
    ...state,
    unspecified: Math.max(0, state.unspecified + counterUpdate)
  };
};

export default handleActions({
  [ACTION_TYPES.HTTP_REQUEST_STARTED]: (state: HttpRequests, action: any) => {
    return updateRequestStatus(state, {
      ...extractRequestData(action.payload.requestedAction),
      status: STATUS.REQUESTED,
      response: null
    });
  },
  [ACTION_TYPES.HTTP_REQUEST_SUCCEEDED]: (state: HttpRequests, action: any) => {
    return updateRequestStatus(state, {
      ...extractRequestData(action.payload.requestedAction),
      status: STATUS.SUCCEEDED,
      response: null
    });
  },
  [ACTION_TYPES.HTTP_REQUEST_FAILED]: (state: HttpRequests, action: any) => {
    return updateRequestStatus(state, {
      ...extractRequestData(action.payload.requestedAction),
      status: STATUS.FAILED,
      response: action.payload.exception
    });
  },
  [ACTION_TYPES.LOGOUT]: () => initialState
}, initialState);
