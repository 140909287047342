const languageToLocaleMapping = {
  en: 'en',
  es_ES: 'es',
  it_IT: 'it'
};

export const createFormatter = (language: string) =>  new Intl.DateTimeFormat(
  languageToLocaleMapping[language] || languageToLocaleMapping.en, { month: 'short' }
);

export const formatDate = (date: string | number, language: string): string => {
  const dateInstance = new Date(date);
  const dateValue = dateInstance.getUTCDate();
  const formatter = createFormatter(language);

  return `${formatter.format(dateInstance)} ${String(dateValue).padStart(2, '0')}`;
};

export const formatDateToMonthYear = (date: string | number, language) => {
  const dateInstance = new Date(date);
  const formatter = createFormatter(language);

  return `${formatter.format(dateInstance)}, ${dateInstance.getFullYear()}`;
};

export const formatBandwidthLabel = (sizeMB: number): string => {
  const gbSize = 1024;

  if (sizeMB > gbSize) {
    const valueAsString = (sizeMB / gbSize).toFixed(1);
    const gbValue = parseFloat(valueAsString);
    const gbValueInt = parseInt(valueAsString, 10);

    return gbValueInt === gbValue ? `${gbValueInt} GB` : `${gbValue} GB`;
  }

  return `${parseFloat(sizeMB.toFixed(1))} MB`;
};
