import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Box, cn, Container, Context, Flex, Icon, Section, Text, textToHTML } from 'sg-styleguide';
import { FirstLevelTitle } from '../../../web/containers/titles';
import { HideOnMobile } from '../../containers/device';
import './page-header.scss';

type Props = {
  icon: string;
  iconColor?: string;
  instructions: string;
  title: string;
  intl?: Intl;
  render?: Function;
  renderNoticeText?: Function;
  device: { width: number; isDesktop: boolean; };
};

type State = {
  showReadMore: boolean;
  isTextHigherThenMaxLineHeight: boolean;
};

const MAX_TEXT_HEIGHT = 44;

class PageHeader extends React.Component<Props, State> {
  readonly state = {
    showReadMore: true,
    isTextHigherThenMaxLineHeight: false
  };

  textRef = React.createRef() as any;

  componentDidMount() {
    if (!this.textRef.current) {
      return;
    }

    const isTextHigherThenMaxLineHeight = this.textRef.current.getBoundingClientRect().height > MAX_TEXT_HEIGHT;
    this.setState({
      isTextHigherThenMaxLineHeight
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.textRef.current) {
      return;
    }

    if (prevProps.device.width !== this.props.device.width) {
      const isTextHigherThenMaxLineHeight = this.textRef.current.getBoundingClientRect().height > MAX_TEXT_HEIGHT;
      this.setState({
        isTextHigherThenMaxLineHeight
      });
    }
  }

  render() {
    const { showReadMore, isTextHigherThenMaxLineHeight } = this.state;
    const {
      device,
      intl,
      icon,
      iconColor,
      instructions,
      render,
      title,
      renderNoticeText = (text: string) => textToHTML(text)
    } = this.props;
    const shouldTruncateText = isTextHigherThenMaxLineHeight && showReadMore && !device.isDesktop;
    const textClasses = cn(
      'page-header__text',
      shouldTruncateText && 'page-header__text--overflow'
    );

    return (
      <Section className="page-header">
        <FirstLevelTitle>{title}</FirstLevelTitle>

        {render && render()}

        {!render && (
          <Container>
            <Flex gutter="none" margin="none">
              <HideOnMobile>
                <Box sm="2" flex align="center" justify="center">
                  <Icon name={icon} multicolor size="64" color={iconColor} />
                </Box>
              </HideOnMobile>

              <Box sm="10">
                <Text>
                   <span
                     ref={this.textRef}
                     className={textClasses}
                     style={shouldTruncateText ? { maxHeight: `${MAX_TEXT_HEIGHT}px` } : undefined}
                   >
                     {typeof instructions === 'string' ? renderNoticeText(instructions) : instructions}
                   </span>
                </Text>

                {shouldTruncateText && (
                  <Text
                    className="page-header__toggle-button"
                    color="secondary"
                    onClick={() => this.setState({ showReadMore: false })}
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'translate.generic.read.more' })}
                  </Text>
                )}

                {!showReadMore && (
                  <Text
                    className="page-header__toggle-button"
                    color="secondary"
                    onClick={() => this.setState({ showReadMore: true })}
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'translate.generic.read.less' })}
                  </Text>
                )}
              </Box>
            </Flex>
          </Container>
        )}
      </Section>
    );
  }
}

export default injectIntl((props) => (
  <Context.Consumer>
    {({ device }) => (
      <PageHeader device={device} {...props} />
    )}
  </Context.Consumer>
));
