import * as React from 'react';
import { Grid, IconButton, Section, Tab, Tabs } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import {
  getAvailableLocationPhpSettings,
  getDefaultPhpVersion,
  getLocationPhpIniById,
  getLocationPhpVersionByDomainId,
  getPhpVariablesById
} from '../../../core/selectors';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import PhpIniValue from './php-variables/php-ini-value';
import { PhpVersion, SettingApiType } from './types';
import { ChangePhpIniSetting, ChangePhpVersion } from './update';
import { apiToFormValueConversion, formToApiValueConversion } from './utils';

type Props = {
  actions: CrudActions;
  environment: {
    isPhone: boolean;
  };
  items: {
    locationPhpiniSettings: any[];
    domain: any[];
    phpVariableDataById: {
      [variableId: string]: {
        name: string;
        id: string;
        type: SettingApiType;
      }
    },
    locationPhpiniByDomainId: object;
    phpVersions: PhpVersion[];
    locationPhpVersionByDomainId: object;
  };
  mainDomain: {
    id: number;
    name: string;
  };
  defaultPhpVersion: PhpVersion;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  selectedDomainId: number;
  tabActive: string;
  selectedEditableVariable: {
    id: string;
    value: string;
  };
  selectedVariableToRestore: {
    id: string;
  };
};

const tabs = ['version', 'variables'];

class PhpSettings extends React.Component<Props, State> {
  readonly state: State = {
    selectedDomainId: null,
    tabActive: tabs[0],
    selectedEditableVariable: null,
    selectedVariableToRestore: null
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      JSON.stringify(nextState) !== JSON.stringify(this.state)
    );
  }

  handleDomainSelect = (domainId: number) => {
    this.setState({ selectedDomainId: domainId });
    this.fetchPhpIniSettings(domainId);
  };

  fetchPhpIniSettings = (domainId: number) => {
    const { actions } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.PHP_LOCATION_INI_SETTINGS,
      urlParams: {
        domain_id: domainId
      }
    });

    actions.fetchItems({
      ...API_RESOURCE.PHP_LOCATION_INI,
      id: domainId
    });
  };

  updatePhpVersion = ({ version_id }) => {
    const { actions, items, intl, closeSGDialog } = this.props;
    const domainData = this.getSelectedDomainData();
    const hasSetPhpVersion = Boolean(
      items.locationPhpVersionByDomainId[this.state.selectedDomainId]
    );

    const meta = {
      _metaFields: { ...API_RESOURCE.PHP_LOCATION_VERSION },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.phpSettings.version_updated',
            intlValues: { domain: domainData.name }
          },
          error: {
            intlKey: 'translate.page.phpSettings.failed_update_php_version',
            intlValues: { domain: domainData.name }
          }
        }
      }
    };

    if (hasSetPhpVersion) {
      actions.updateItem(
        {
          ...meta as UpdateItemPayload,
          id: items.locationPhpVersionByDomainId[this.state.selectedDomainId].id,
          version_id
        },
        () => closeSGDialog(REDUX_FORM.CHANGE_PHP_VERSION)
      );
    } else {
      actions.createItem(
        {
          ...meta as CreateItemPayload,
          domain_id: this.state.selectedDomainId,
          path: '/', // path is always '/' according to current backend implementation
          version_id
        },
        () => closeSGDialog(REDUX_FORM.CHANGE_PHP_VERSION)
      );
    }
  };

  updatePhpSettings = ({ settingName, settingType, settingFormValue }) => {
    const { items, actions, intl, closeSGDialog } = this.props;

    const settingData = items.locationPhpiniSettings.find(
      (variable) => variable.id === settingName
    );

    const updatedSettingData = {
      id: settingName,
      value: formToApiValueConversion(settingFormValue, settingType)
    };

    if (settingData.value === updatedSettingData.value) {
      // value is changed to its default value, should be called restore default
      return this.restoreDefaultPhpSetting(settingName);
    }

    const metaFields = {};

    const domainHasSetPhpIni = Boolean(
      items.locationPhpiniByDomainId[this.state.selectedDomainId]
    );

    if (domainHasSetPhpIni) {
      actions.updateItem({
        id: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
        settings: this.prepareVariablesArrayToUpdate(updatedSettingData),
        recursive: '1', // legacy api param
        _metaFields: {
          ...API_RESOURCE.PHP_LOCATION_INI
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.page.phpSettings.php_variable_updated',
              intlValues: { variableName: settingName }
            },
            error: {
              intlKey: 'translate.page.phpSettings.failed_update_php_variable',
              intlValues: { variableName: settingName }
            }
          }
        }
      }, () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING));
    } else {
      actions.createItem({
        _metaFields: {
          ...API_RESOURCE.PHP_LOCATION_INI
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {},
            error: {}
          }
        },
        domain_id: this.state.selectedDomainId,
        recursive: '1', // legacy api param
        path: '/', // path is always '/' according to current backend implementation
        settings: [updatedSettingData]
      }, () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING));
    }
  };

  restoreDefaultPhpSetting = (id: string) => {
    const { actions, items, closeSGDialog } = this.props;
    const settings = this.prepareVariablesArrayToRestoreDefault({ id });

    if (settings.length === 0) {
      const hasValuesToRestore = items.locationPhpiniByDomainId[this.state.selectedDomainId];

      if (hasValuesToRestore) {
        actions.deleteItem({
          itemId: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
          _metaFields: { ...API_RESOURCE.PHP_LOCATION_INI },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.phpSettings.php_variable_updated',
                intlValues: { variableName: id }
              },
              error: {
                intlKey: 'translate.page.phpSettings.failed_update_php_variable',
                intlValues: { variableName: id }
              }
            }
          }
        }, () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING));
      } else {
        closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING);
      }
    } else {
      actions.updateItem({
        id: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
        settings: this.prepareVariablesArrayToRestoreDefault({ id }),
        recursive: '1', // legacy api param
        _metaFields: { ...API_RESOURCE.PHP_LOCATION_INI },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.page.phpSettings.php_variable_updated',
              intlValues: { variableName: id }
            },
            error: {
              intlKey: 'translate.page.phpSettings.failed_update_php_variable',
              intlValues: { variableName: id }
            }
          }
        }
      }, () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING));
    }
  };

  getSettingDataForCurrentDomain = ({ user_value, ...otherSettingData }: any) => {
    const { items } = this.props;
    const { selectedDomainId } = this.state;
    const { locationPhpiniByDomainId } = items;

    if (locationPhpiniByDomainId[selectedDomainId]) {
      /* if the variable is set for the currently selected domain, return the object with user_value,
        it will be merged with the data returned for the variable from /location-phpini_settings  GET,
        because the /location-phpini_settings  GET, returns user_value if it is set for any of the domains
        (really strange API behaviour) */
      if (locationPhpiniByDomainId[selectedDomainId].settings[otherSettingData.id]) {
        return {
          ...otherSettingData,
          user_value: locationPhpiniByDomainId[selectedDomainId].settings[otherSettingData.id].value
        };
      }
    }

    return {
      // ommiting the user_value, if it is not for the current domainId
      ...otherSettingData
    };
  };

  getSelectedDomainData = () =>
    this.props.items.domain &&
    this.props.items.domain.find(({ id }) => id === this.state.selectedDomainId);

  getSelectedPhpVersionData = (): PhpVersion => {
    const { items, defaultPhpVersion } = this.props;

    const defaultVersion = defaultPhpVersion ? defaultPhpVersion.id : null;

    const defaultPhpVersionSelectedId = (
      items.locationPhpVersionByDomainId[this.state.selectedDomainId] ?
        items.locationPhpVersionByDomainId[this.state.selectedDomainId].version_id :
        defaultVersion
    );

    return items.phpVersions.find(({ id }) => String(id) === String(defaultPhpVersionSelectedId));
  };

  prepareVariablesArrayToUpdate = ({ id, value }) => {
    const otherSetSettings: any[] = this.prepareVariablesArrayToRestoreDefault({ id });
    const { type } = this.props.items.phpVariableDataById[id];
    return [...otherSetSettings, { id, value }];
  };

  prepareVariablesArrayToRestoreDefault = ({ id }) => {
    /* When restoring to default value,
      the api needs all variables which have
      user values except the reset one */
    const { items } = this.props;
    const { selectedDomainId } = this.state;

    if (items.locationPhpiniByDomainId[selectedDomainId]) {
      const { settings } = items.locationPhpiniByDomainId[selectedDomainId];
      return Object.keys(settings).reduce((output, settingId: string) => {
        if (settingId === id) {
          return output;
        }

        return [...output, { ...settings[settingId] }];
      }, []);
    }

    return [];
  };

  render() {
    const { actions, items, intl } = this.props;
    const { selectedDomainId, selectedEditableVariable } = this.state;
    const phpVersionData = this.getSelectedPhpVersionData();

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-php-manager"
          title={intl.formatMessage({ id: 'translate.page.phpSettings.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.phpSettings.page.info.text' })}
        />
        <Section>
          <Grid>
            <DomainSelect
              options={items.domain}
              selectedValue={selectedDomainId}
              optionValue="id"
              optionLabel="name"
              onChange={this.handleDomainSelect}
            />
            {
              this.state.tabActive === 'version' && (
                <VCS resourceName={API_RESOURCE.PHP_LOCATION_VERSION.resourceNameMetaApi} hasMethod="GET">
                  <SGTable
                    title={intl.formatMessage({ id: 'translate.page.phpSettings.settings.subtitle' })}
                    data={phpVersionData ? [phpVersionData] : []}
                    columns={this.getVersionColumns()}
                    renderBeforeTableContent={this.renderTabs}
                    resources={[
                      { resourceName: API_RESOURCE.PHP_LOCATION_VERSION.resourceName, methods: ['GET'] },
                      { resourceName: API_RESOURCE.PHP_VERSION.resourceName, methods: ['GET', 'DELETE'] },
                      { resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName, methods: ['PUT', 'POST', 'DELETE'] }
                    ]}
                  />
                </VCS>
              )
            }
            {
              this.state.tabActive === 'variables' && (
                <VCS resourceName={API_RESOURCE.PHP_LOCATION_INI.resourceNameMetaApi} hasMethod="GET">
                  {
                    Boolean(phpVersionData.disabled) ? (
                      <SGTable
                        title={intl.formatMessage({ id: 'translate.page.phpSettings.settings.subtitle' })}
                        data={[]}
                        noDataTitle="translate.page.phpSettings.missing.php-version-table.label"
                        columns={this.getVariablesColumns()}
                        renderBeforeTableContent={this.renderTabs}
                      />
                    ) : (
                      <SGTable
                        title={intl.formatMessage({ id: 'translate.page.phpSettings.settings.subtitle' })}
                        data={this.props.items.locationPhpiniSettings}
                        columns={this.getVariablesColumns()}
                        resources={[
                          {
                            resourceName: API_RESOURCE.PHP_LOCATION_INI_SETTINGS.resourceName,
                            methods: ['GET', 'DELETE']
                          },
                          {
                            resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
                            methods: ['GET', 'DELETE', 'POST', 'PUT']
                          }
                        ]}
                        renderBeforeTableContent={this.renderTabs}
                      />
                    )
                  }
                </VCS>
              )
            }
            {this.renderChangePhpSettingDialog()}
            {this.renderUpdatePhpVersionDialog()}
            {this.renderRestoreVariableDialog()}
          </Grid>
        </Section>
      </React.Fragment>
    );
  };

  renderTabs = () => {
    const { environment } = this.props;
    const { isPhone } = environment;
    const tabsPadding = isPhone ? ['none', 'small'] : undefined;

    return (
      <Tabs size="small" border="light">
        <Tab
          data-e2e="php-settings-tab-version"
          active={this.state.tabActive === 'version'}
          onClick={() => this.setState({ tabActive: 'version' })}
          padding={tabsPadding}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.phpSettings.version.tab' })}
        </Tab>
        <Tab
          data-e2e="php-settings-tab-variables"
          active={this.state.tabActive === 'variables'}
          onClick={() => this.setState({ tabActive: 'variables' })}
          padding={tabsPadding}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.phpSettings.variables.tab' })}
        </Tab>
      </Tabs>
    );
  };

  renderUpdatePhpVersionDialog = () => {
    const { intl, items, defaultPhpVersion } = this.props;
    const domainData = this.getSelectedDomainData();
    const phpVersionData = this.getSelectedPhpVersionData();

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_PHP_VERSION}
        title={intl.formatMessage(
          { id: 'translate.page.phpSettings.change_version.title' },
          { siteName: domainData && domainData.name }
        )}
        resources={[{
          resourceName: API_RESOURCE.PHP_LOCATION_VERSION.resourceName,
          methods: ['PUT', 'POST']
        }]}
      >
        <ChangePhpVersion
          phpVersions={items.phpVersions}
          initialValues={{
            _metaFields: API_RESOURCE.PHP_LOCATION_VERSION,
            version_id: phpVersionData && phpVersionData.id,
            id: this.state.selectedDomainId
          }}
          onSubmit={this.updatePhpVersion}
        />
      </SGDialogForm>
    );
  };

  renderChangePhpSettingDialog() {
    const { items, intl } = this.props;
    const selectedVariable = this.state.selectedEditableVariable;

    const settingData = selectedVariable && items.phpVariableDataById[selectedVariable.id];
    const settingName = settingData && settingData.name;
    const settingType = settingData && settingData.type;
    const settingApiValue = selectedVariable && selectedVariable.value;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_PHP_INI_SETTING}
        title={intl.formatMessage({
          id: 'translate.page.phpSettings.edit_variable'
        }, { variableName: settingName })}
        resources={[{
          resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
          methods: ['PUT', 'POST', 'DELETE']
        }]}
      >
        <ChangePhpIniSetting
          initialValues={{
            settingName,
            settingType,
            settingApiValue,
            settingFormValue: apiToFormValueConversion(settingApiValue, settingType)
          }}
          onSubmit={this.updatePhpSettings}
        />
      </SGDialogForm>
    );
  }

  renderRestoreVariableDialog = () => {
    const { intl } = this.props;
    const { selectedVariableToRestore } = this.state;
    const variable = selectedVariableToRestore;

    return (
      <DeleteDialog
        icon="restore"
        title={intl.formatMessage(
          { id: 'translate.page.phpSettings.confirm_restore' },
          { variableName: variable && variable.id }
        )}
        onSubmit={() => this.restoreDefaultPhpSetting(variable && variable.id)}
      />
    );
  };

  renderPhpSettingsContextMenu = (value, entity) => {
    const { id, can_set, ...otherSettingData } = entity;
    const { user_value } = this.getSettingDataForCurrentDomain({ id, ...otherSettingData });
    const hasUserValue = user_value !== undefined;
    const { intl, openSGDialog } = this.props;

    const contextMenuItems = [{
      vcsMethod: 'PUT',
      icon: 'edit',
      label: Boolean(can_set) ?
        intl.formatMessage({ id: 'translate.page.phpSettings.edit.tooltip' }) :
        intl.formatMessage({ id: 'translate.page.phpSettings.cannot_edit.tooltip' }),
      e2eAttr: 'table-action-edit',
      visibleOnDesktop: true,
      disabled: !can_set,
      onClick: () => {
        const changeSettingPayload = {
          id,
          value: hasUserValue ? user_value : value
        };

        this.setState({
          selectedEditableVariable: changeSettingPayload
        }, () => this.props.openSGDialog(
          REDUX_FORM.CHANGE_PHP_INI_SETTING,
          changeSettingPayload
        ));
      }
    }];

    const userValueRestoreButton = {
      vcsMethod: 'PUT',
      icon: 'restore',
      e2eAttr: 'restore-default-value',
      label: intl.formatMessage({ id: 'translate.page.phpSettings.restore_default.tooltip' }),
      visibleOnDesktop: true,
      disabled: !can_set,
      onClick: () => this.setState({
        selectedVariableToRestore: { id }
      }, () => openSGDialog(DIALOGS.GENERIC_DELETE))
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.PHP_LOCATION_INI.resourceName}
        items={hasUserValue ? [userValueRestoreButton, ...contextMenuItems] : contextMenuItems}
      />
    );
  };

  getVariablesColumns = () => [{
    header: this.props.intl.formatMessage({ id: 'translate.generic.name' }),
    accessor: 'id'
  }, {
    header: this.props.intl.formatMessage({ id: 'translate.generic.value' }),
    accessor: 'value',
    style: {
      wordBreak: 'break-all'
    },
    render: (value, settingData) => {
      return (
        <PhpIniValue
          value={value}
          data={this.getSettingDataForCurrentDomain(settingData)}
          onAddValue={() => {
            const changeSettingPayload = {
              id: settingData.id,
              value
            };

            this.setState({
              selectedEditableVariable: changeSettingPayload
            }, () => this.props.openSGDialog(
              REDUX_FORM.CHANGE_PHP_INI_SETTING,
              changeSettingPayload
            ));
          }}
          type={
            this.props.items.phpVariableDataById[settingData.id] &&
            this.props.items.phpVariableDataById[settingData.id].type
          }
          customText={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.custom.label' })}
          addValueText={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.add_value.link' })}
        />
      );
    }
  }, {
    header: this.props.intl.formatMessage({ id: 'translate.generic.actions' }),
    accessor: 'value',
    render: this.renderPhpSettingsContextMenu
  }];

  getVersionColumns = () => {
    const { openSGDialog } = this.props;

    return [{
      header: this.props.intl.formatMessage({ id: 'translate.page.phpSettings.version.column.title' }),
      accessor: 'version'
    }, {
      header: this.props.intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'actions',
      render: (value, data) => (
        <VCS resourceName={API_RESOURCE.PHP_LOCATION_VERSION.resourceNameMetaApi} hasMethod="PUT">
          <IconButton
            shape="circle"
            size="medium"
            icon="edit"
            data-e2e="table-action-edit"
            tooltip={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.change.version.tooltip' })}
            onClick={() => openSGDialog(REDUX_FORM.CHANGE_PHP_VERSION)}
          />
        </VCS>
      )
    }];
  };
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  items: {
    domain: state.pageItems.domain || [],
    // TODO: apply sort, when requirement is available
    // filtered /location-phpini_settings data (some variables not listed)
    locationPhpiniSettings: getAvailableLocationPhpSettings(state),
    // mapped /php-variable by id, fetched to access the type of the variables
    phpVariableDataById: getPhpVariablesById(state),
    locationPhpiniByDomainId: getLocationPhpIniById(state),
    phpVersions: state.pageItems.phpVersions || [],
    locationPhpVersionByDomainId: getLocationPhpVersionByDomainId(state)
  },
  defaultPhpVersion: getDefaultPhpVersion(state)
});

export default indexWithCRUD(mapStateToProps, (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
}))(
  PhpSettings,
  API_RESOURCE.PHP_VARIABLE,
  API_RESOURCE.PHP_VERSION,
  API_RESOURCE.PHP_LOCATION_VERSION,
  API_RESOURCE.DOMAIN
);
