import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'sg-styleguide';

type Props = {
  children: any;
  gridProps: any;
  environment: {
    isPhone: boolean
  };
  onSubmit: () => null;
};

const FormGrid = ({ children, environment, onSubmit, gridProps }: Props) => (
  <form onSubmit={onSubmit}>
    <Grid
      sm="12"
      padding={environment.isPhone ? 'medium' : 'large'}
      gap={environment.isPhone ? 'medium' : 'large'}
      {...gridProps}
    >
      {children}
    </Grid>
  </form>
);

const mapStateToProps = (store) => ({
  environment: store.environment
});

export default connect(mapStateToProps)(FormGrid) as any;
