import { SettingApiType } from './types';
/*
  API types and values
  ______________________________

  API setting type bool
    - '0', '1' values (No, Yes);
  ______________________________

  API setting type number ->
    - number fields;
  ______________________________

  API setting type string ->
    - string fields;
    - 'On', 'Off' values;
*/

const onOffValuesTypes = ['string']; // number in some cases (BUG in the api)
export const API_BOOLEAN_TYPES = ['bool', 'string'];

export const formToApiValueConversion = (value: string, apiType: SettingApiType) => {

  switch (value) {
    case 'true':
      return onOffValuesTypes.includes(apiType) ? 'On' : '1';
    case 'false':
      return onOffValuesTypes.includes(apiType) ? 'Off' : '0';
    default:
      return value;
  }
};

export const apiToFormValueConversion = (value: string | number, apiType: SettingApiType) => {
  if (API_BOOLEAN_TYPES.includes(apiType)) {
    switch (value) {
      case 'On':
      case '1':
      case 1:
        return 'true';
      case 'Off':
      case '0':
      case 0:
        return 'false';
      default:
        return value;
    }
  }

  return value;
};
