import * as React from 'react';
import RadioButton from 'sg-styleguide/lib/components/radio-button';

const FormRadioButton = ({ input, meta, ...props }) => {
  return (
    <RadioButton data-e2e={`form-radio-${input.name}`} {...input} {...props} />
  );
};

export default FormRadioButton;
