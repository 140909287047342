import { getEntityType, isEntityProtected } from '../../utils';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../constants/common';

// https://jira.siteground.com/browse/SPANEL-854
const defaultPermissions = {
  // Only Folders
  canCreateDir: false,
  canCreateFile: false,
  canUploadFile: false,
  canUploadDir: false,

  // Only Files
  canDownload: false,
  canExtract: false,
  canRename: false,

  // Files and Folders
  canArchive: false,
  canCopy: false,
  canDelete: false,
  canMove: false,
  canPaste: false,
  canChangePermissions: false,

  // Code Editor
  canSave: false,

  isProtected: false
};

export const getFileMangerToolbarPermission = (state) => {
  const { codeEditor, selectedContentEntities, selectedNavigationEntity } = state.fileManager;
  const isCodeEditorVisible = codeEditor.isVisible;
  const isArchiveRegex = /\.(zip|rar|7z|tar.gz)$/;

  const isOnlyFilesSelectedInContent = selectedContentEntities.every((entity) => (
    entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE
  ));
  const isOnlyFoldersSelectedInContent = selectedContentEntities.every((entity) => (
    entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY
  ));

  const selectedNavigationEntityType = selectedNavigationEntity && getEntityType(selectedNavigationEntity);
  const isFileSelectedInNavigation = selectedNavigationEntityType === FILE_MANAGER_API_RESPONSE_DIR.FILE;
  // TODO change this check with symlinks in mind
  const isFolderSelectedInNavigation = selectedNavigationEntityType === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY;

  // Default permissions
  const toolbarPermissions = {
    ...defaultPermissions,
    canCreateDir: isFolderSelectedInNavigation,
    canCreateFile: isCodeEditorVisible || isFolderSelectedInNavigation,
    canUploadFile: isFolderSelectedInNavigation,
    canUploadDir: isFolderSelectedInNavigation,
    canEdit: isFileSelectedInNavigation,
    canDownload: isFileSelectedInNavigation,
    canDelete: isFileSelectedInNavigation,
    canChangePermissions: isFileSelectedInNavigation,
    canSave: isCodeEditorVisible,
    isProtected: isEntityProtected(selectedNavigationEntity),
    canShowFolderSize: selectedNavigationEntityType !== FILE_MANAGER_API_RESPONSE_DIR.FILE
  };

  /**
   * NAVIGATION
   */

  if (isFileSelectedInNavigation) {
    toolbarPermissions.canCopy = true;
    toolbarPermissions.canMove = true;
    toolbarPermissions.canRename = true;
  }

  if (isFolderSelectedInNavigation) {
    toolbarPermissions.canPaste = Boolean(state.fileManager.markedForCopy);
  }

  /**
   * CONTENT
   */

  // Only files
  if (selectedContentEntities.length > 0 && isOnlyFilesSelectedInContent) {
    toolbarPermissions.canExtract = isArchiveRegex.test(selectedContentEntities[0].n);
    toolbarPermissions.isProtected = isEntityProtected(selectedContentEntities[0]);
  }

  // Single entity
  if (selectedContentEntities.length === 1) {
    toolbarPermissions.canRename = true;
    toolbarPermissions.canEdit = getEntityType(selectedContentEntities[0]) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
  }

  // Files and Folders
  if (selectedContentEntities.length > 0) {
    toolbarPermissions.canDownload = isOnlyFilesSelectedInContent;

    toolbarPermissions.canArchive = true;
    toolbarPermissions.canCopy = true;
    toolbarPermissions.canDelete = true;
    toolbarPermissions.canMove = true;
    toolbarPermissions.canChangePermissions = true;
    toolbarPermissions.isProtected = Boolean(selectedContentEntities.find((entity) => isEntityProtected(entity)));
  }

  return toolbarPermissions;
};

export const getFileMangerContextPermission = (state) => {
  const {
    contextNavigationEntity,
    contextContentEntities,
    markedForCopy,
    selectedNavigationEntity
  } = state.fileManager;
  const isArchiveRegex = /\.(zip|rar|7z|tar.gz)$/;

  const prioritizesSelection = contextContentEntities.length ? contextContentEntities : contextNavigationEntity;

  const isEntityFile = (entity) => {
    if (!entity) {
      return false;
    }
    return getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
  };
  const isEntityFolder = (entity) => {
    if (!entity) {
      return false;
    }
    return getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY;
  };

  const isOnlyFilesContextClickedInContent = contextContentEntities.every((entity) => (
    entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE)
  );
  const isOnlyFoldersContextClickedInContent = contextContentEntities.every((entity) => (
    entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY)
  );

  const isFileRightClickedInNavigation = isEntityFile(contextNavigationEntity);
  const isFolderRightClickedInNavigation = isEntityFolder(contextNavigationEntity);

  const isFolderSelectedInNavigation = isEntityFolder(selectedNavigationEntity);

  // Default permissions
  const permissions = {
    ...defaultPermissions,
    // Only Folders
    canCreateDir: isFolderSelectedInNavigation,
    canCreateFile: isFolderSelectedInNavigation,
    canUploadFile: isFolderSelectedInNavigation,
    canUploadDir: isFolderSelectedInNavigation,

    // Only Files
    canEdit: isFileRightClickedInNavigation,
    canDownload: isFileRightClickedInNavigation,
    canExtract: contextContentEntities.length === 1 && isArchiveRegex.test(contextContentEntities[0].n),
    canRename: contextContentEntities.length === 1 || contextNavigationEntity,

    // Files and Folders
    canArchive: (!isFolderRightClickedInNavigation && !isFileRightClickedInNavigation),
    canCopy: prioritizesSelection,
    canDelete: prioritizesSelection,
    canMove: prioritizesSelection,
    canPaste: markedForCopy &&
    (isFolderRightClickedInNavigation || isFolderSelectedInNavigation && !isFileRightClickedInNavigation),
    canChangePermissions: prioritizesSelection,

    isProtected: isEntityProtected(contextNavigationEntity),
    canShowFolderSize: isEntityFolder(contextNavigationEntity)
  };

  if (contextContentEntities.length > 0) {
    permissions.canDownload = isOnlyFilesContextClickedInContent;
    permissions.isProtected = isEntityProtected(contextContentEntities[0]);
  }

  if (contextContentEntities.length === 1) {
    permissions.canEdit = isEntityFile(contextContentEntities[0]);
    permissions.canPaste = markedForCopy && isEntityFolder(contextContentEntities[0]);
  }

  if (contextContentEntities.length > 1) {
    permissions.canPaste = false;
  }

  return permissions;
};
