import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'sg-styleguide';
import { closeSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../sg-dialog';

type Props = {
  id?: string;
  title?: string;
  density?: string;
  icon?: string;
  intl?: Intl;
  children?: any;
  onSubmit: Function;
  closeSGDialog?: (id) => void;
};

const DeleteDialog = ({
  children,
  density,
  closeSGDialog,
  icon = 'trash',
  intl,
  onSubmit,
  title,
  id =  DIALOGS.GENERIC_DELETE
}: Props) => (
  <SGDialog
    id={id}
    icon={icon}
    density={density}
    state="inactive"
    title={title}
    footer={
      <React.Fragment>
        <SGDialogCancel id={id} />

        <Button
          data-e2e="dialog-submit"
          color="primary"
          onClick={() => {
            onSubmit();
            closeSGDialog(id);
          }}
        >
          {intl.formatMessage({ id: 'translate.generic.confirm' })}
        </Button>
      </React.Fragment>
    }
  >
    {children || intl.formatMessage({ id: 'translate.conformation.dialog.message' })}
  </SGDialog>
);

export default connect<{}, {}, Props>(undefined, (dispatch) => ({
  closeSGDialog: (id) => dispatch(closeSGDialog(id))
}))(
  injectIntl(DeleteDialog)
);
