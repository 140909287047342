import * as Actions from '../constants/sg-dialog';

export const openSGDialog = (id, payload?) => ({ type: Actions.OPEN_SG_DIALOG, id, payload });
export const closeSGDialog = (id) => ({ type: Actions.CLOSE_SG_DIALOG, id });

export const subscribeSGDialogOpened = (id: string, onOpened: Function) => ({
  type: Actions.SUBSCRIBE_SG_DIALOG_OPENED,
  id,
  onOpened
});

export const subscribeSGDialogClosed = (id: string, onClosed: Function) => ({
  type: Actions.SUBSCRIBE_SG_DIALOG_CLOSED,
  id,
  onClosed
});

export const unsubscribeSGDialogState = (id: string) => ({
  type: Actions.UNSUBSCRIBE_SG_DIALOG_STATE,
  id
});
