import { isPasswordValid } from 'sg-styleguide/lib/utils';
import { formatMessage } from '../translate';

export const NO_ERROR = undefined;
export const INVALID_FIELD_VALUE = formatMessage({ id: 'translate.validations.invalid_field' });
export const PASSWORD_VALIDATION_ERROR_MAP = {
  short: formatMessage({ id: 'translate.validations.short_password' }),
  invalid: 'validation error'
};

/* tslint:disable */
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* tslint:enable */
/*
  UTILS declarations:
  *
  *
  All utils must be type `ValidationUtil` and return error string or NO_ERROR
*/
export const isRequired: ValidationUtil = (value): string => {
  if (Array.isArray(value) && value.length === 0) {
    return formatMessage({ id: 'translate.validations.invalid_field' });
  }

  if (value === undefined || value === null || value === '' || value === false) {
    return formatMessage({ id: 'translate.validations.invalid_field' });
  }

  return NO_ERROR;
};

export const isNumber: ValidationUtil = (value) => {
  if (isNaN(value)) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }

  return NO_ERROR;
};

export const isValidDomain: ValidationUtil = (domain) => {
  const re = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+$/);

  if (!domain.match(re)) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }

  return NO_ERROR;
};

export const isValidEmail: ValidationUtil = (email) => {
  if (emailRegExp.test(email)) {
    return NO_ERROR;
  }

  return formatMessage({ id: 'translate.validations.invalid_email' });
};

export const isValidMultipleEmails: ValidationUtil = (value, fields) => {
  let error = NO_ERROR;

  if (value && Array.isArray(value)) {
    value.forEach((email) => {
      if (!emailRegExp.test(email.trim())) {
        error = formatMessage({ id: 'translate.validations.invalid_email_in_list' });
      }
    });
  }

  return error;
};

export const isValidPassword: ValidationUtil = (password) => {
  const validation = isPasswordValid(password);

  if (validation.isPasswordToShort) {
    return PASSWORD_VALIDATION_ERROR_MAP.short;
  }

  if (validation.isPasswordValid === false) {
    return PASSWORD_VALIDATION_ERROR_MAP.invalid;
  }

  return NO_ERROR;
};

export const validateIntegerField = (value: any, { min, max }) => {
  const intValue = parseInt(value, 10);
  const isValidNumber = String(intValue) === String(value);

  if (isValidNumber) {
    const notInRange = Boolean(intValue < min || intValue > max);

    return notInRange ? INVALID_FIELD_VALUE : NO_ERROR;
  }

  return value ? INVALID_FIELD_VALUE : NO_ERROR;
};

export const validationWithMetaApi: ValidationUtil =
  (value: any, { _metaFields }, { siteMetaApi }, fieldName: string): string => {
    if (siteMetaApi.loading) {
      return NO_ERROR;
    }

    const requestedResource = _metaFields && _metaFields.resourceNameMetaApi;

    if (!requestedResource || typeof siteMetaApi.endpoints[requestedResource].input_fields[fieldName] !== 'object') {
      return NO_ERROR;
    }

    // Validate Integers
    const validateAsInt = Boolean(
      requestedResource &&
      siteMetaApi.endpoints[requestedResource].input_fields &&
      siteMetaApi.endpoints[requestedResource].input_fields[fieldName] &&
      siteMetaApi.endpoints[requestedResource].input_fields[fieldName].type === 'integer'
    );

    if (validateAsInt) {
      const { min, max } = siteMetaApi.endpoints[requestedResource].input_fields[fieldName];
      return validateIntegerField(value, { min, max });
    }

    // Regexp
    if (siteMetaApi.endpoints[requestedResource].input_fields[fieldName].regex !== undefined) {
      const rx = new RegExp(siteMetaApi.endpoints[requestedResource].input_fields[fieldName].regex);
      return rx.test(value) ? undefined : INVALID_FIELD_VALUE;
    } else if (siteMetaApi.endpoints[requestedResource].input_fields[fieldName].label !== undefined) {
      const label = siteMetaApi.endpoints[requestedResource].input_fields[fieldName].label;
      if (siteMetaApi.labels[label] !== undefined && siteMetaApi.labels[label].regex !== undefined) {
        const rx = new RegExp(siteMetaApi.labels[label].regex);
        return rx.test(value) ? NO_ERROR : siteMetaApi.labels[label].message;
      }
    }
  };

export const rxIP = new RegExp(/^[A-Fa-f0-9.\-:*]+$/);
// TODO IP4 / IP6 ?!?!@?
export const validIp = (value) => !rxIP.test(value) ? INVALID_FIELD_VALUE : NO_ERROR;

export const rxIPWithExtras = new RegExp(/^[A-Fa-f0-9.\-\%\/:*]+$/);

export const validIpWithExtras: ValidationUtil = (value) =>
  !rxIPWithExtras.test(value) ?
    INVALID_FIELD_VALUE :
    NO_ERROR;

export const validBase64Encode = (value) => {
  try {
    btoa(value);
  } catch (e) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }
};

export const validPhpSettingValue: ValidationUtil = (value, { settingType }) => {
  switch (settingType) {
    case 'number':
      return /^\s*-?[0-9]+\s*$/.test(value) ? NO_ERROR : INVALID_FIELD_VALUE;
    case 'decimal':
      return /^\s*-?[0-9]+(\.[0-9]+)?\s*$/.test(value) ? NO_ERROR : INVALID_FIELD_VALUE;
    case 'size':
      return /^\s*[0-9]+[KMG]?\s*$/.test(value) ? NO_ERROR : INVALID_FIELD_VALUE;
    case 'color':
      return /^\s*#?[0-9A-Fa-f]{6}\s*$/.test(value) ? NO_ERROR : INVALID_FIELD_VALUE;
    case 'path':
      return /^\s*(\/?[a-zA-Z_\-=\.0-9]*)+\s*$/.test(value) ? NO_ERROR : INVALID_FIELD_VALUE;
    default:
      return NO_ERROR;
  }
};
