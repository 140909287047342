import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { CLOSE_SG_DIALOG, OPEN_SG_DIALOG } from '../constants/sg-dialog';

const initialState: any = {};

export default handleActions<any, any>({
  [OPEN_SG_DIALOG]: (state, { id, payload }: any) => ({
    ...state,
    [id]: { ...payload }
  }),
  [CLOSE_SG_DIALOG]: (state, { id }: any) => ({
    ...state,
    [id]: undefined
  }),
  [Actions.LOGOUT]: () => initialState
}, initialState);
