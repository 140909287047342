import { IntlProvider } from 'react-intl';
import store from './store';

type FormatMessageObject = {
  id: any,
  defaultMessage?: string,
  description?: string
};

export const formatMessage = (message: FormatMessageObject, values?: any) => {
  const state = store.getState();
  const { intl } = new IntlProvider({ locale: state.i18n.locale, messages: state.i18n.messages }, {}).getChildContext();
  return intl.formatMessage(message, values);
};
