import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Actions from '../../constants/actions';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* updateItemSaga(action: UpdateItemAction) {
  const state = yield select();

  const {
    resourceName,
    endpoint,
    dontChangeItemsState,
    fetchItemsAfterUpdate,
    parentResourceName,
    fetchResourceName,
    fetchEndpoint
  } = action.payload._metaFields;

  const response = yield call(siteApi({
    endpoint: endpoint + '/' + encodeURIComponent(action.payload.id),
    method: 'PUT',
    body: {
      ...action.payload // includes _meta
    },
    state,
    urlParams: action.payload.urlParams
  }));

  const updatedItem = Object.assign({}, response.data);

  yield put({
    type: Actions.UPDATE_ITEM_SUCCEEDED,
    item: updatedItem,
    resourceName,
    dontChangeItemsState,
    parentResourceName
  });

  if (fetchItemsAfterUpdate) {
    const myResourceName = fetchResourceName ? fetchResourceName : resourceName;
    const myEndpoint = fetchEndpoint ? fetchEndpoint : endpoint;
    yield put({
      type: Actions.FETCH_ITEMS_REQUESTED,
      payload: { resourceName: myResourceName, endpoint: myEndpoint }
    });
  }

  return response;
}

function* updateItem(): any {
  yield takeEvery(Actions.UPDATE_ITEM_REQUESTED, handleAvalonApiRequest(updateItemSaga));
}

export default updateItem;
