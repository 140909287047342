import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Notice, Text } from 'sg-styleguide';

type Props = {
  intl: Intl;
};

const BackupNotAvailable = ({ intl }: Props) => (
  <Grid padding={['medium', 'none', 'none', 'none']}>
    <Notice
      type="info"
      title={intl.formatMessage({ id: 'translate.page.backup.feature-not-available.title' })}
    >
      <Text>
        {intl.formatMessage({ id: 'translate.page.backup.not-available-notice.text' })}
      </Text>
    </Notice>
  </Grid>
);

export default injectIntl(BackupNotAvailable);
