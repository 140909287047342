import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Text } from 'sg-styleguide';
import { openNewTabAction, OpenNewTabPayload } from '../../../../core/actions/open-new-tab';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

type Props = {
  children: any;
  selectedInstallation: string;
  intl?: Intl;
  setParentState?: (object) => any;
  openNewTab?: (payload: OpenNewTabPayload) => any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.WORDPRESS;
const formName = REDUX_FORM.CREATE_WP_MANAGE;

const CreateContainer = ({ children, intl, selectedInstallation, setParentState, openNewTab }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.wp.manage.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName: API_RESOURCE.WORDPRESS.resourceName,
        methods: ['POST', 'GET']
      }, {
        resourceName: API_RESOURCE.APP.resourceName,
        methods: ['GET']
      }, {
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['GET']
      }]}
      renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
        if (notification.state === 'error') {
          return renderDefaultNotificationTemplate();
        }

        if (notification.state === 'success' && selectedInstallation !== '') {
          setParentState({ selectedInstallation: '' });
        }

        const appUrl = notification.responseData.app_url;
        return (
          <div style={{ textAlign: 'center' }}>
            <Text size="x-large" weight="extra-bold" color="dark" align="center" style={{ marginBottom: '10px' }}>
              {intl.formatMessage({ id: 'translate.generic.whats.next' })}
            </Text>

            <Button
              type="outlined"
              color="secondary"
              data-e2e="control-panel"
              onClick={() => openNewTab({ url: appUrl })}
            >
              {intl.formatMessage({ id: 'translate.page.wp.manage.create.success.control.panel.label' })}
            </Button>

            <MobileBreakElement />

            <Button
              type="outlined"
              color="secondary"
              data-e2e="view-site"
              onClick={() => openNewTab({ url: `${appUrl}/wp-admin` })}
            >
              {intl.formatMessage({ id: 'translate.page.wp.manage.create.success.view.site.label' })}
            </Button>
          </div>
        );
      }}
    >
      {children}
    </CreateBox>
  </VCS>
);

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTabAction(payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(
  injectIntl(CreateContainer)
);
