import {
  FILE_MANAGER_CODE_EDITOR_FORBIDDEN_FORMATS,
  FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS
} from '../constants/common';

import { FileManagerRootState } from '../reducers/utils/internal-state-definition';

export const getEntity = (entity, fileManagerState) => {
  if (!entity) {
    return null;
  }

  const stateToUse = fileManagerState;

  const entityPath = getEntityPath(entity);
  const entityParentPath = entityPath.substr(0, entityPath.lastIndexOf('/')) || '/';

  return stateToUse.entities[entityParentPath] &&
    stateToUse.entities[entityParentPath].find((ent) => getEntityPath(ent) === getEntityPath(entity));
};

export const getEntityByPath = (entityPath, fileManagerState) => {
  // TODO refactor fileManagerState to be just entities
  const stateToUse = fileManagerState;
  const entityParentPath = entityPath.substr(0, entityPath.lastIndexOf('/')) || '/';

  return stateToUse.entities[entityParentPath] &&
    stateToUse.entities[entityParentPath].find((entity) => getEntityPath(entity) === entityPath);
};

export const getEntityInfoNumber = (entity) => {
  return entity.i;
};

export const getEntityName = (entity) => {
  return entity.n;
};

export const getEntityNameExtension = (entity) => {
  return getEntityReadableName(entity).split('.').pop();
};

export const getEntityReadableName = (entity) => {
  return entity.n.split('/').pop();
};

export const getEntityPath = (entity) => {
  return entity._meta.path;
};

export const getParentPath = (entityPath) => {
  return entityPath.substr(0, entityPath.lastIndexOf('/')) || '/';
};

export const getEntityParentPath = (entity) => {
  const entityPath = getEntityPath(entity);

  return getParentPath(entityPath);
};

export const getEntityType = (entity) => {
  return entity.t;
};

export const getEntitySize = (entity) => {
  return entity.s;
};

export const getEntityIsExpanded = (entity) => {
  return entity._meta.isExpanded;
};

export const getEntityPermissions = (entity) => {
  return entity.p;
};

export const getOctalPermission = (permissionsCheckboxes) => {
  let octalValue = '';
  Object.keys(permissionsCheckboxes).forEach((key) => {
    const booleanCheckboxesValues = permissionsCheckboxes[key].map((checkbox) => Number(checkbox.checked)).join('');
    octalValue += parseInt(booleanCheckboxesValues, 2).toString(8);
  });

  return octalValue;
};

export const getBinaryPermissions = (octalValue, permissionsCheckboxes) => {
  const modifiedPermissionsCheckboxes = { ...permissionsCheckboxes };

  Object.keys(modifiedPermissionsCheckboxes).forEach((key, i) => {
    modifiedPermissionsCheckboxes[key].map((checkbox, j) => {
      let octalValueNumeric: any = Number(octalValue[i]).toString(2);
      octalValueNumeric = octalValueNumeric.length < 3 ? octalValueNumeric.padStart(3, '0') : octalValueNumeric;

      if (!checkbox.disabled) {
        checkbox.checked = Boolean(parseInt(octalValueNumeric[j], 10));
      }

      return checkbox;
    });
  });

  return modifiedPermissionsCheckboxes;
};

export const getCodeEditorEntityContent = (entity) => {
  return entity._meta.content;
};

export const getCodeEditorEntityUpdatedContent = (entity) => {
  return entity._meta.updatedContent;
};

export const isEntityProtected = (entity) => {
  if (entity && entity.sys) {
    return entity.sys > 0;
  }

  return false;
};

export const isEntityActiveInCodeEditor = (entity) => {
  return entity._meta.isActiveInCodeEditor;
};

export const isEntityForbiddenInCodeEditor = (entity) => {
  const name = typeof entity === 'string' ? entity : getEntityName(entity);
  const extension = name.substr(name.lastIndexOf('.') + 1, name.length);
  return Object.keys(FILE_MANAGER_CODE_EDITOR_FORBIDDEN_FORMATS).includes(extension.toLowerCase());
};

export const isEntityKnownInCodeEditor = (entity) => {
  const name = typeof entity === 'string' ? entity : getEntityName(entity);
  const extension = name.substr(name.lastIndexOf('.') + 1, name.length);

  return Object.keys(FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS).includes(extension.toLowerCase());
};

export const isEntityOpenedInCodeEditor = ({ codeEditor }: FileManagerRootState, entityId: string | number): boolean =>
  Boolean(codeEditor.files.find(({ i }) => i === entityId));

export const isEntityUpdatedInCodeEditor = ({ codeEditor }: FileManagerRootState, entityId): boolean => {
  const storedEntity = codeEditor.files.find(({ i }) => i === entityId);

  return Boolean(
    storedEntity &&
    getCodeEditorEntityContent(storedEntity) !==
    getCodeEditorEntityUpdatedContent(storedEntity)
  );
};
