import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, Flex, FlexProps, Grid, Icon, Placeholder, Text } from 'sg-styleguide';
import * as actions from '../../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import ErrorNotice from '../../../../components/form-error-notice';
import { withSpanelForm } from '../../../../containers/sg-spanel-forms/index';
import { DIALOG_LIST_STYLES } from './constants';
import './styles.scss';

const LayoutListProps: Partial<FlexProps> = {
  align: 'center',
  gutter: 'none',
  margin: 'none'
};

const formName = REDUX_FORM.BACKUP_RESTORE_EMAILS_DIALOG;

class RestoreEmailDialogForm extends React.Component<any, any> {
  componentWillMount() {
    const { actions, entity } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.BACKUP_RESTORE_EMAIL,
      urlParams: {
        id: entity.id
      }
    });
  }

  getLeftItemPadding = () => {
    const { environment } = this.props;

    return environment.isPhone ? 'medium' : 'xx-large';
  };

  getEmailsDomain() {
    const { emails } = this.props;
    const dataToRender = {};

    emails.forEach((email) => {
      const domain = email.mail_account.split('@').pop();

      if (dataToRender[domain] === undefined) {
        dataToRender[domain] = true;
      }
    });

    return Object.keys(dataToRender);
  }

  renderEmailList = (email, index, array) => {
    const formValues = this.props.getFormValues(formName);

    return (
      <Flex
        key={email}
        {...LayoutListProps}
      >
        <Grid padding={['xx-small', 'x-small', 'xx-small', this.getLeftItemPadding()]}>
          <Checkbox
            value={email}
            checked={formValues.restore_data && formValues.restore_data.includes(email)}
            onChange={(event) => this.props.change('restore_data', event.target.checked ?
              formValues.restore_data.concat(email) :
              formValues.restore_data.filter((emailAccount) => emailAccount !== email))
            }
          />
        </Grid>

        <Icon name="mail-filled" color="lighter" size="24" />

        <Grid padding={['none', 'none', 'none', 'x-small']} align="center">
          <Text>{email}</Text>
        </Grid>
      </Flex>
    );
  };

  renderContent = ({ input, meta, groups, emails, intl }) => {
    const leftItemPadding = this.getLeftItemPadding();
    const emailCount = input.value ? input.value.length : 0;
    const touchedOrActive = (meta.touched || meta.active);
    const touchedOrHasValue = (meta.touched || Boolean(input.value));
    const hasError = !meta.valid && touchedOrActive && touchedOrHasValue;

    return (
      <div>
        <Flex background="light" {...LayoutListProps}>
          <Grid padding={['none', 'x-small', 'none', leftItemPadding]}>
            <Checkbox
              value="all"
              checked={emailCount === emails.length}
              onChange={(event) => input.onChange(event.target.checked ? emails : [])}
            />
          </Grid>

          <Text color="light" size="small" weight="bold">
            {intl.formatMessage({ id: 'translate.generic.name' })}
          </Text>
        </Flex>

        <Grid padding={['none', 'none', 'none', 'none']} style={DIALOG_LIST_STYLES} gap="none">
          {groups.map((group, index) => (
            <React.Fragment key={index}>
              <Flex key={group} align="center" margin="none" gutter="none">
                <Grid padding={['xx-small', 'large', 'xx-small', leftItemPadding]}>
                  <Text color="light" weight="bold" transform="uppercase">{group}</Text>
                </Grid>
              </Flex>

              {emails
                .filter((email) => (email.indexOf(`@${group}`) > -1))
                .map(this.renderEmailList)
              }
            </React.Fragment>
          ))}
        </Grid>

        <Flex border={['small', 'none', 'none', 'none']} {...LayoutListProps}>
          <Grid padding={['x-small', 'large', 'large', leftItemPadding]}>
            <Text
              align="left"
              color="light"
              transform="uppercase"
              size="small"
              weight="bold"
              className={hasError ? 'sg-text-label--error' : ''}
            >
              {intl.formatMessage(
                { id: 'translate.page.backup.restore.item.selected' },
                { count: emailCount }
              )}
            </Text>
          </Grid>
        </Flex>
      </div>
    );
  };

  renderNoContentPlaceholder() {
    const { intl } = this.props;

    return (
      <Placeholder
        icon="product-envelope-wings"
        background="lighter"
        message={intl.formatMessage({ id: 'translate.page.backup.restore.emails.no.data.message' })}
      />
    );
  }

  renderDialogContent() {
    const { emails, intl, getFormErrors, submitFailed } = this.props;
    const groups = this.getEmailsDomain();
    const mappedEmails = emails.map((email) => email.mail_account);
    const leftItemPadding = this.getLeftItemPadding();
    const formErrors = getFormErrors(formName);
    const errors = Object.keys(formErrors).length && [{
      field: 'restore_data',
      label: intl.formatMessage({ id: 'translate.page.backup.restore.emails.error.notice.restore_data' })
    }];

    return (
      <div>
        {submitFailed && (
          <Grid padding={['none', leftItemPadding, 'medium', leftItemPadding]}>
            <ErrorNotice
              title={intl.formatMessage({ id: 'translate.page.backup.restore.error.notice.title' })}
              errors={errors}
            />
          </Grid>
        )}

        <Field
          name="restore_data"
          groups={groups}
          emails={mappedEmails}
          intl={intl}
          validate={[this.props.validationUtils.required]}
          component={this.renderContent}
        />
      </div>
    );
  }

  render() {
    const { emails } = this.props;

    return emails.length ? this.renderDialogContent() : this.renderNoContentPlaceholder();
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => ({
  environment: state.environment,
  emails: state.pageItems[API_RESOURCE.BACKUP_RESTORE_EMAIL.resourceName] || []
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    withSpanelForm(
      reduxForm({ form: formName })(RestoreEmailDialogForm)
    )
  )
);
