import { call, put, select, take, takeEvery, fork, race } from 'redux-saga/effects';
import * as ACTIONS from '../constants/actions';
import * as sessionActions from '../actions/session';
import { clientApi } from '../api/client';
import { getCurrentSiteId } from '../selectors';

export function* watchClientTokenUpdate() {
  let isUpdatingClientToken = false;

  yield takeEvery(ACTIONS.REFRESH_CLIENT_TOKEN, function* () {
    if (isUpdatingClientToken) {
      return;
    }

    isUpdatingClientToken = true;

    try {
      const state = yield select();

      const clientTokenData = yield call(clientApi(`/auth/${CONFIG.NEMO_CLIENT_TOKEN_API}`, 'POST', {
        refresh_token: state.session.clientRefreshToken
      }));

      yield put(sessionActions.refreshClientTokenSucceeded(clientTokenData));
    } catch (e) {
      yield put(sessionActions.logoutUser({ sessionExpired: true }));
      yield put(sessionActions.refreshClientTokenFailed());
    }

    isUpdatingClientToken = false;
  });
}

export function* watchSiteTokenUpdate() {
  let isUpdatingSiteToken = false;

  yield takeEvery(ACTIONS.REFRESH_SITE_TOKEN, function* () {
    if (isUpdatingSiteToken) {
      return;
    }

    isUpdatingSiteToken = true;

    try {
      const { session } = yield select();

      const siteId = yield select(getCurrentSiteId);

      const siteToken: APIResponseSiteToken = yield call(
        clientApi(
          `/auth/sites/${siteId}/token?_client_token=${session.clientToken}`,
          'GET'
        )
      );

      yield put(sessionActions.refreshSiteTokenSucceeded(siteId, siteToken));
    } catch (e) {
      console.error('FAILED_TO_REFRESH_SITE_TOKEN');
      yield put(sessionActions.refreshSiteTokenFailed());
    }

    isUpdatingSiteToken = false;
  });
}

function* watchRefreshAllTokens({ payload }) {
  const { actionToRetry } = payload;

  yield put(sessionActions.refreshClientToken());

  const { clientTokenRefreshed, clientTokenFailed } = yield race({
    clientTokenRefreshed: take(ACTIONS.REFRESH_CLIENT_TOKEN_SUCCEEDED),
    clientTokenFailed: take(ACTIONS.REFRESH_CLIENT_TOKEN_FAILED)
  });

  if (clientTokenRefreshed) {
    yield put(sessionActions.refreshSiteToken());

    const { siteTokenRefreshed, siteTokenFailed } = yield race({
      siteTokenRefreshed: take(ACTIONS.REFRESH_SITE_TOKEN_SUCCEEDED),
      siteTokenFailed: take(ACTIONS.REFRESH_SITE_TOKEN_FAILED)
    });

    if (siteTokenRefreshed && actionToRetry) {
      yield put(actionToRetry);
    }
  }
}

function* siteRequestAuthorizationFailed(): any {
  yield fork(watchClientTokenUpdate);
  yield fork(watchSiteTokenUpdate);

  yield takeEvery(ACTIONS.SITE_REQUEST_AUTHORIZATION_FAILED, watchRefreshAllTokens);
}

export default siteRequestAuthorizationFailed;
