import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Flex, getDate, Grid, Label, Link, Section, Tab, Table, Tabs, Text } from 'sg-styleguide';
import { navigateToUA } from '../../../core/actions/nemo-store';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import { getCurrentSiteId } from '../../../core/selectors';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import { getToolMainEndpoint } from '../../../core/selectors/static-api';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import { CREATE_BOX_STATES, IMPORTED_LABEL, PREMIUM_LABEL } from './constants';
import { CreateBox, CreateFormImport, CreateFormInstall, FORM_NAME_BY_TAB_ID } from './create';
import { ViewCertificate } from './update';

const SSL_CERT_STORE_KEY = 'ssl-cert';

const CREATE_FORM_COMPONENT_BY_TAB_ID = {
  [CREATE_BOX_STATES.INSTALL]: CreateFormInstall,
  [CREATE_BOX_STATES.IMPORT]: CreateFormImport
};

type SSLProps = {
  actions: CrudActions;
  navigateToUA: Function;
  domainAll: any[];
  ssl: any;
  sslCertificates: any;
  location: any;
  intl: Intl;
  isUAEnabled: boolean;
  siteId: string;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  viewCertificateDomainsDialogPayload: any;
  renewCertificateDialogPayload: any;
  deleteCertificateDialogPayload: any;
  selectedDomainId: number;
  selectedTab: string;
};

class SSLPage extends React.Component<SSLProps, State> {
  readonly state = {
    viewCertificateDomainsDialogPayload: null,
    renewCertificateDialogPayload: null,
    deleteCertificateDialogPayload: null,
    selectedDomainId: null,
    selectedTab: CREATE_BOX_STATES.INSTALL
  };

  getCertificateLabel(cert) {
    const { intl } = this.props;

    if (!cert || !cert.issuer) {
      return null;
    }

    const { issuer } = cert;
    let certOrganization = '';

    if (cert.label === IMPORTED_LABEL) {
      certOrganization += `${intl.formatMessage({ id: 'translate.generic.imported' })} - `;
    }

    certOrganization += issuer.split('/O=').pop().split('/').shift();

    if (!cert.info || !cert.info.cert) {
      return certOrganization;
    }

    if (cert.info.cert.wildcard) {
      certOrganization += ' Wildcard';
    } else if (cert.info.cert.self) {
      certOrganization += ' Self Signed';
    }

    return certOrganization;
  }

  getSelectedCertificates() {
    const { domainAll, ssl } = this.props;
    const { selectedDomainId } = this.state;

    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);

    if (!selectedDomain || ssl.length === 0) {
      return null;
    }

    return ssl.filter((cert) => {
      const installOn = []
        .concat(cert.installed_on_wild)
        .concat(cert.installed_on)
        .filter(Boolean)
        .map((c) => c.name);

      const domainsMatched = cert.domains_matched ? cert.domains_matched.map((domain) => domain.name) : [];

      return installOn.includes(selectedDomain.name) && domainsMatched.includes(selectedDomain.name);
    });
  }

  onCreateFormSubmitted = (data: CreateItemPayload) => {
    const { domainAll } = this.props;
    const { selectedTab, selectedDomainId } = this.state;
    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);
    const formName = FORM_NAME_BY_TAB_ID[selectedTab];
    const modifiedData = { ...data };

    let ssl = 'SSL';

    if (formName === REDUX_FORM.CREATE_INSTALL_SSL) {
      ssl = this.props.intl.formatMessage({
        id: modifiedData.wildcard ?
          'translate.page.ssl.lets.encrypt.wildcard' :
          'translate.page.ssl.lets.encrypt'
      });

      modifiedData.wildcard = modifiedData.generate;
      delete modifiedData.generate;
    }

    if (modifiedData.cert) {
      modifiedData.cert = btoa(modifiedData.cert);
    }

    if (modifiedData.key) {
      modifiedData.key = btoa(modifiedData.key);
    }

    if (modifiedData.cabundle) {
      modifiedData.cabundle = btoa(modifiedData.cabundle);
    }

    if (modifiedData.cert || modifiedData.key) {
      modifiedData.label = IMPORTED_LABEL;
    }

    this.props.actions.createItem({
      ...modifiedData,
      _meta: {
        ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
        notification: {
          type: 'form',
          formName: FORM_NAME_BY_TAB_ID[selectedTab],
          success: {
            intlKey: 'translate.page.ssl.create.success.message',
            intlValues: {
              domain: selectedDomain && selectedDomain.name,
              ssl
            }
          },
          error: {
            intlKey: 'translate.page.ssl.create.failed.message',
            intlValues: {
              domain: selectedDomain && selectedDomain.name,
              ssl
            }
          }
        }
      }
    }, () => {
      this.props.actions.fetchItems(API_RESOURCE.DOMAIN_ALL);
      this.props.actions.fetchItems(API_RESOURCE.SSL);
    });
  };

  goToUA = () => {
    const { domainAll, siteId } = this.props;
    const { selectedDomainId } = this.state;
    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);

    this.props.navigateToUA({
      page: 'ssl', //  TODO way to share constants between projects ?
      siteId,
      domainName: selectedDomain && selectedDomain.name
    });
  };

  renderSSLName = (cell, entity) => {
    const { intl, openSGDialog } = this.props;
    const { installed_on, installed_on_wild } = entity;
    const installedOn = []
      .concat(installed_on)
      .concat(installed_on_wild)
      .filter(Boolean)
      .filter((e) => e.name !== entity.name);

    if (installedOn.length === 0) {
      return cell;
    }

    return (
      <div>
        <Flex wrap="nowrap" gutter="none" margin="none">
          <Text truncate>
            {cell}
          </Text>

          &nbsp;

          {Boolean(installedOn.length) && (
            <Text style={{ flexShrink: 0 }}>
              <Link
                onClick={() => this.setState(
                  { viewCertificateDomainsDialogPayload: installedOn },
                  () => openSGDialog(REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG)
                )}
              >
                + {installedOn.length} {intl.formatMessage({ id: 'translate.generic.more' })}
              </Link>
            </Text>
          )}
        </Flex>
      </div>
    );
  };

  renderSSLStatus = (cell) => {
    const { intl } = this.props;

    return (
      <Label type={cell.err ? 'inactive-link' : 'active-link'} padding={['inherit', 'none']}>
        {intl.formatMessage({
          id: cell.err ? 'translate.generic.expired' : 'translate.generic.active'
        })}
      </Label>
    );
  };

  render() {
    const { intl, ssl = [], domainAll, openSGDialog } = this.props;
    const { selectedDomainId, selectedTab } = this.state;
    const CreateForm = CREATE_FORM_COMPONENT_BY_TAB_ID[selectedTab];

    const columns = [{
      header: intl.formatMessage({ id: 'translate.generic.name' }),
      accessor: 'name',
      render: this.renderSSLName
    }, {
      header: intl.formatMessage({ id: 'translate.generic.certificate' }),
      accessor: 'info',
      render: (cell, entity) => this.getCertificateLabel(entity)
    }, {
      header: intl.formatMessage({ id: 'translate.generic.status' }),
      accessor: 'info',
      render: this.renderSSLStatus
    }, {
      header: intl.formatMessage({ id: 'translate.page.ssl.expires.on' }),
      accessor: 'not_after',
      render: (cell) => getDate(cell)
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: this.renderContextMenu
    }];

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: 'translate.page.ssl.title' })}
          icon="presentational-ssl"
          instructions={intl.formatMessage({ id: 'translate.page.ssl.info' })}
        />

        <Section>
          <Grid>
            <CreateBox
              selectedTab={selectedTab}
              renderBeforeBoxChildren={this.renderCreateBoxTabs()}
            >
              <CreateForm
                domainSelectOptions={domainAll}
                onChangeDomain={(domainId: string) => this.setState({ selectedDomainId: parseInt(domainId, 10) })}
                selectedDomainId={selectedDomainId}
                selectedCertificates={this.getSelectedCertificates()}
                goToUA={this.goToUA}
                onSubmit={this.onCreateFormSubmitted}
                openSGDialog={openSGDialog}
                isUAEnabled={this.props.isUAEnabled}
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.SSL.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.ssl.list.title' })}
                data={ssl.filter((s) => s.installed_on || s.installed_on_wild)}
                columns={columns}
                rowKey="domain_name"
                resources={[{ resourceName: API_RESOURCE.SSL.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.ssl.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderViewCertificateDomainsDialog()}
        {this.renderViewCertificateDialog()}
        {this.renderRenewLECertificateDialog()}
        {this.renderRenewPremiumCertificateDialog()}
        {this.renderDeleteDialog()}
        {this.renderIsPremiumDeleteDialog()}
      </div>
    );
  };

  renderCreateBoxTabs = () => {
    const { intl } = this.props;
    const { selectedTab } = this.state;

    return (
      <Tabs size="small" border="light">
        <Tab
          active={selectedTab === CREATE_BOX_STATES.INSTALL}
          onClick={() => this.setState({ selectedTab: CREATE_BOX_STATES.INSTALL })}>
          {intl.formatMessage({ id: 'translate.page.ssl.tab.install' })}
        </Tab>

        <Tab
          active={selectedTab === CREATE_BOX_STATES.IMPORT}
          onClick={() => this.setState({ selectedTab: CREATE_BOX_STATES.IMPORT })}>
          {intl.formatMessage({ id: 'translate.page.ssl.tab.import' })}
        </Tab>
      </Tabs>
    );
  };

  renderViewCertificateDomainsDialog() {
    const { intl } = this.props;
    const { viewCertificateDomainsDialogPayload } = this.state;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG}
        icon="glob"
        state="warning"
        density="none"
        title={intl.formatMessage({ id: 'translate.page.ssl.domains.dialog.title' })}
        footer={
          <SGDialogCancel
            id={REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[{
          resourceName: API_RESOURCE.SSL.resourceName,
          methods: ['GET']
        }]}
      >
        <Table
          mobileLayout="row"
          data={viewCertificateDomainsDialogPayload}
          columns={[{
            accessor: 'name',
            render: (name) => <Text align="left">{name}</Text>
          }]}
        />
      </SGDialog>
    );
  }

  renderViewCertificateDialog() {
    const { intl, sslCertificates } = this.props;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_CERTIFICATE_DIALOG}
        icon="ssl"
        state="warning"
        size="x-large"
        title={intl.formatMessage({ id: 'translate.page.ssl.view.certificate.title' })}
        footer={
          <SGDialogCancel
            id={REDUX_FORM.SSL_CERTIFICATE_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[{ resourceName: SSL_CERT_STORE_KEY, methods: ['GET'] }]}
      >
        <ViewCertificate sslCertificates={sslCertificates} />
      </SGDialog>
    );
  }

  renderRenewLECertificateDialog() {
    const { intl } = this.props;
    const { renewCertificateDialogPayload } = this.state;
    const entity = renewCertificateDialogPayload && renewCertificateDialogPayload.entity;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_RENEW_LE_DIALOG}
        icon="renew"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ssl.renew.le.dialog.title' })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={REDUX_FORM.SSL_RENEW_LE_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
            <Button
              color="primary"
              onClick={() => this.props.actions.updateItem({
                id: entity.id,
                _metaFields: {
                  ...API_RESOURCE.SSL_LE
                }
              })}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
        resources={[
          { resourceName: API_RESOURCE.SSL_LE.resourceName, methods: ['PUT'] }
        ]}
      >
        <Text>
          {intl.formatMessage({ id: 'translate.page.ssl.renew.le.dialog.content' })}
        </Text>
      </SGDialog>
    );
  }

  renderRenewPremiumCertificateDialog() {
    const { intl } = this.props;
    const { renewCertificateDialogPayload } = this.state;
    const entity = renewCertificateDialogPayload && renewCertificateDialogPayload.entity;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG}
        icon="renew"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ssl.renew.premium.dialog.title' })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
            <Button
              color="primary"
              onClick={this.goToUA}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
      >
        {intl.formatMessage({ id: 'translate.page.ssl.renew.premium.dialog.content' })}
      </SGDialog>
    );
  }

  renderDeleteDialog = () => {
    const { intl } = this.props;
    const deletePayload = this.state.deleteCertificateDialogPayload;
    const entity = deletePayload ? deletePayload.entity : {};
    const title = intl.formatMessage(
      { id: 'translate.page.ssl.delete.dialog.title' },
      { ssl: entity.name, domain: entity.domain_name }
    );

    return (
      <DeleteDialog title={title} onSubmit={() => this.props.actions.deleteItem(deletePayload)}>
        {intl.formatMessage({ id: 'translate.page.ssl.delete.dialog.content' })}
      </DeleteDialog>
    );
  };

  renderIsPremiumDeleteDialog = () => {
    const deletePayload = this.state.deleteCertificateDialogPayload;
    const { intl } = this.props;

    const entity = deletePayload ? deletePayload.entity : {};

    const title = intl.formatMessage(
      { id: 'translate.page.ssl.delete.dialog.title' },
      { ssl: entity.name, domain: entity.domain_name }
    );

    return (
      <SGDialog
        id={DIALOGS.SSL_PREMIUM_DELETE}
        icon="trash"
        state="warning"
        title={title}
        footer={
          <SGDialogCancel
            id={DIALOGS.SSL_PREMIUM_DELETE}
            label={intl.formatMessage({ id: 'translate.generic.ok' })}
          />
        }
      >
        <FormattedMessage
          id="translate.page.ssl.delete.dialog.premium.content"
          values={{
            link: (
              <Link href="https://siteground.com/" target="_blank">
                <FormattedMessage id="translate.page.ssl.billing.section" />
              </Link>
            )
          }}
        />
      </SGDialog>
    );
  };

  renderContextMenu = (id, entity: any) => {
    const { intl, openSGDialog } = this.props;
    const deletePayload = { itemId: id, entity, _metaFields: { ...API_RESOURCE.SSL } };
    const isPremium = entity.label === PREMIUM_LABEL;
    const isImported = entity.label === IMPORTED_LABEL;
    const canRenew = new Date(entity.not_after * 1000).setDate(-30) > new Date().getTime();

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.SSL.resourceName}
        items={[{
          vcsMethod: 'PUT',
          icon: 'ssl',
          label: intl.formatMessage({ id: 'translate.page.ssl.view.certificate' }),
          onClick: () => {
            this.props.actions.clearResource(SSL_CERT_STORE_KEY);

            this.props.actions.fetchItem({
              itemId: entity.id,
              urlParams: {
                cert_data: 1
              },
              ...API_RESOURCE.SSL,
              resourceName: SSL_CERT_STORE_KEY
            });

            openSGDialog(REDUX_FORM.SSL_CERTIFICATE_DIALOG);
          }
        }, {
          vcsMethod: 'PUT',
          icon: 'renew',
          label: intl.formatMessage({ id: 'translate.generic.renew' }),
          disabled: isImported || canRenew,
          onClick: () => this.setState(
            { renewCertificateDialogPayload: { entity } },
            () => openSGDialog(isPremium ?
              REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG :
              REDUX_FORM.SSL_RENEW_LE_DIALOG)
          )
        }, {
          vcsMethod: 'DELETE',
          icon: 'trash',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          onClick: () => this.setState(
            { deleteCertificateDialogPayload: deletePayload },
            () => isPremium ? openSGDialog(DIALOGS.SSL_PREMIUM_DELETE) : openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  navigateToUA: (data) => dispatch(navigateToUA(data)),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (state: RootState) => ({
  domainAll: state.pageItems[API_RESOURCE.DOMAIN_ALL.resourceName] || [],
  ssl: state.pageItems[API_RESOURCE.SSL.resourceName] || [],
  sslCertificates: state.pageItems[SSL_CERT_STORE_KEY] || [],
  isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint('ssl'), 'can_order'),
  siteId: getCurrentSiteId(state)
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  SSLPage,
  API_RESOURCE.SSL,
  API_RESOURCE.DOMAIN_ALL
);
