import * as React from 'react';
import { Container, copyToClipboard, Flex, Grid, Placeholder, Section, Text, Textarea } from 'sg-styleguide';
import { createNotification } from '../../../core/actions/notifications';
import { API_RESOURCE } from '../../../core/constants/api';
import { RootState } from '../../../core/reducers';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../containers/partial-loader';
import SecondLevelTitle from '../../containers/titles/second-level-title';
import VCS from '../../containers/visibility-control-service';

type Props = {
  actions: {
    fetchItems: FetchItems;
  };
  createNotification: Function;
  environment: any;
  errorlogs: {
    error_log: any[];
  };
  domain: any[];
  intl: Intl;
};

type  State = {
  selectedDomainId: number;
};

class ErrorLogs extends React.Component<Props, State> {
  readonly state = {
    selectedDomainId: null
  };
  textareaRef = null;

  componentDidUpdate(prevProps) {
    if (!this.textareaRef) {
      return;
    }

    if (JSON.stringify(prevProps.errorlogs) !== JSON.stringify(this.props.errorlogs)) {
      this.textareaRef.scrollTop = this.textareaRef.scrollHeight;
    }
  }

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  render() {
    const { environment, errorlogs, domain, intl } = this.props;
    const errors = errorlogs.error_log && errorlogs.error_log.map((error) => `[${error.source}]${error.line}`).join('');
    const shouldRenderErrors = errorlogs.error_log && errorlogs.error_log.length;
    const logPadding = environment.isPhone ? 'medium' : 'large';

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-error-logs"
          title={intl.formatMessage({ id: 'translate.page.error-logs.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.error-logs.instructions' })}
        />

        <VCS resourceName={API_RESOURCE.ERRORLOGS.resourceNameMetaApi} hasMethods={['GET']}>
          <Section>
            <Grid>
              <DomainSelect
                selectedValue={this.state.selectedDomainId}
                options={domain}
                optionValue="id"
                optionLabel="name"
                onChange={(id) => {
                  this.setState({ selectedDomainId: id });
                  this.props.actions.fetchItems({
                    ...API_RESOURCE.ERRORLOGS,
                    urlParams: {
                      domain_id: id
                    }
                  });
                }}
              />

              <VCS resourceName={API_RESOURCE.ERRORLOGS.resourceName} hasMethod="GET">
                <div>
                  <SecondLevelTitle>
                    {intl.formatMessage({ id: 'translate.page.error-logs.list.title' })}
                  </SecondLevelTitle>

                  <Container padding={shouldRenderErrors ? logPadding : 'none'} style={{ position: 'relative' }}>
                    <PartialLoader
                      position="absolute"
                      resources={[{ resourceName: API_RESOURCE.ERRORLOGS.resourceName, methods: ['GET'] }]}
                    >
                      {shouldRenderErrors ? (
                        <Textarea
                          readOnly
                          rows="20"
                          value={errors}
                          onRefsReady={(textarea) => {
                            this.textareaRef = textarea;
                          }}
                          label={
                            <Flex align="center" justify="space-between" margin="none" gutter="none">
                              {intl.formatMessage({ id: 'translate.page.error-logs.label' })}

                              <Flex margin="none" gutter="none">
                                <Text
                                  color="secondary"
                                  transform="capitalize"
                                  onClick={() => this.props.actions.fetchItems({
                                    ...API_RESOURCE.ERRORLOGS,
                                    urlParams: {
                                      domain_id: this.state.selectedDomainId
                                    }
                                  })}
                                  style={{ marginRight: '20px' }}
                                >
                                  {intl.formatMessage({ id: 'translate.generic.refresh' })}
                                </Text>

                                <Text color="secondary" onClick={() => this.handleCopyToClipboard(errors)}>
                                  {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                                </Text>
                              </Flex>
                            </Flex>
                          }
                        />
                      ) : (
                        <Placeholder
                          icon="product-thumb-up"
                          title={intl.formatMessage({ id: 'translate.page.error-logs.no.data.title' })}
                          message={intl.formatMessage({ id: 'translate.page.error-logs.no.data.message' })}
                        />
                      )}
                    </PartialLoader>
                  </Container>
                </div>
              </VCS>
            </Grid>
          </Section>
        </VCS>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  environment: store.environment,
  errorlogs: store.pageItems.errorlogs ? store.pageItems.errorlogs[0] : {},
  domain: store.pageItems.domain || []
});

const mapDispatchToProps = (dispatch) => ({
  createNotification: (payload) => dispatch(createNotification(payload))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  ErrorLogs,
  API_RESOURCE.DOMAIN
);
