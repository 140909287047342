import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues } from 'redux-form';
import { Dropdown, Switch } from 'sg-styleguide';
import * as actions from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingListItem from '../../../components/setting-list-item';
import PartialLoader from '../../../containers/partial-loader';
import './settings.scss';

type Props = {
  actions: {
    updateItem: UpdateItem;
  },
  appSettings: any[];
  intl: Intl;
};

const WPAutoupdateSettings = ({ actions, appSettings, intl }: Props) => {
  const selectedSiteId = appSettings[0] && appSettings[0].app_id;
  const settings = appSettings.reduce((accumulator, current) => ({
    ...accumulator,
    app_id: current.app_id,
    [current.id]: current.value
  }), {});

  const releaseData = [{
    value: 0,
    label: intl.formatMessage({ id: 'translate.generic.immediate' })
  }, {
    value: 24 * 60 * 60,
    label: `24 ${intl.formatMessage({ id: 'translate.generic.hours' })}`
  }, {
    value: 48 * 60 * 60,
    label: `48 ${intl.formatMessage({ id: 'translate.generic.hours' })}`
  }, {
    value: 72 * 60 * 60,
    label: `72 ${intl.formatMessage({ id: 'translate.generic.hours' })}`
  }];

  return (
    <div style={{ position: 'relative' }}>
      <PartialLoader
        position="absolute"
        resources={[{ resourceName: API_RESOURCE.APP_SETTINGS.resourceName, methods: ['GET'] }]}
      >
        <SettingListItem
          title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.major.release.label' })}
          info={intl.formatMessage({ id: 'translate.page.wp.autoupdate.major.release.info' })}
          resources={[{
            resourceName: API_RESOURCE.APP_SETTINGS.resourceName,
            methods: ['PUT'],
            id: 'major_update_delay'
          }]}
        >
          <div style={{ minWidth: '150px' }}>
            <Dropdown
              options={releaseData}
              optionValue="value"
              optionLabel="label"
              selectedValue={settings.major_update_delay}
              size="small"
              onChange={(value) => actions.updateItem({
                id: 'major_update_delay',
                app_id: selectedSiteId,
                value,
                _metaFields: {
                  ...API_RESOURCE.APP_SETTINGS
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.wp.autoupdate.major.release.success.message'
                    },
                    error: {
                      intlKey: 'translate.page.wp.autoupdate.major.release.error.message'
                    }
                  }
                }
              })}
            />
          </div>
        </SettingListItem>

        <SettingListItem
          title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.minor.release.label' })}
          info={intl.formatMessage({ id: 'translate.page.wp.autoupdate.minor.release.info' })}
          resources={[{
            resourceName: API_RESOURCE.APP_SETTINGS.resourceName,
            methods: ['PUT'],
            id: 'minor_update_delay'
          }]}
        >
          <div style={{ minWidth: '150px' }}>
            <Dropdown
              options={releaseData}
              optionValue="value"
              optionLabel="label"
              selectedValue={settings.minor_update_delay}
              size="small"
              onChange={(value) => actions.updateItem({
                id: 'minor_update_delay',
                app_id: selectedSiteId,
                value,
                _metaFields: {
                  ...API_RESOURCE.APP_SETTINGS
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.wp.autoupdate.minor.release.success.message'
                    },
                    error: {
                      intlKey: 'translate.page.wp.autoupdate.minor.release.error.message'
                    }
                  }
                }
              })}
            />
          </div>
        </SettingListItem>

        <SettingListItem
          title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.update.plugins.label' })}
          info={intl.formatMessage({ id: 'translate.page.wp.autoupdate.update.plugins.info' })}
          resources={[{
            resourceName: API_RESOURCE.APP_SETTINGS.resourceName,
            methods: ['PUT'],
            id: 'scheduled_wp_plugins'
          }]}
        >
          <Switch
            checked={settings.scheduled_wp_plugins}
            onChange={(event) => actions.updateItem({
              id: 'scheduled_wp_plugins',
              app_id: selectedSiteId,
              value: Number(event.target.checked),
              _metaFields: {
                ...API_RESOURCE.APP_SETTINGS
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.wp.autoupdate.update.plugins.success.message'
                  },
                  error: {
                    intlKey: 'translate.page.wp.autoupdate.update.plugins.error.message'
                  }
                }
              }
            })}
          />
        </SettingListItem>
      </PartialLoader>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (store) => ({
  appSettings: store.pageItems.appSettings || []
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WPAutoupdateSettings)) as any;
