import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../containers/sg-form-util/with-spanel-form';

type Props = {
  change: Function;
  intl: Intl;
  onSubmit: Function;
  selectedFilterEntity: any;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_DOMAIN_REDIRECT_DIALOG;

const UpdateFieldsForm = ({ change, intl, selectedFilterEntity, validationUtils }: Props) => (
  <Grid>
    <Field
      name="permanent"
      options={[
        { id: 1, type: intl.formatMessage({ id: 'translate.page.redirect.type_permanent' }) },
        { id: 0, type: intl.formatMessage({ id: 'translate.page.redirect.type_temporary' }) }
      ]}
      optionValue="id"
      optionLabel="type"
      label={intl.formatMessage({ id: 'translate.page.redirect.redirect.type' })}
      placeholder={intl.formatMessage({ id: 'translate.page.redirect.redirect.type' })}
      component={FormDropdown}
    />

    <Field
      name="redirect_to"
      type="text"
      label={intl.formatMessage({ id: 'translate.page.redirect.redirect.to' })}
      validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
      component={FormInput}
    />
  </Grid>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(UpdateFieldsForm)
  )
);
