import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Actions from '../../constants/actions';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* fetchItemsSaga(action: FetchItemsAction) {
  const state = yield select();
  const { resourceName, endpoint, urlParams } = action.payload;

  const response = yield call(siteApi({
    endpoint,
    method: 'GET',
    body: { _metaResourceName: resourceName },
    state,
    urlParams
  }));

  yield put({ type: Actions.FETCH_ITEMS_SUCCEEDED, items: response.data, resourceName });

  return response;
}

function* fetchItems(): any {
  yield takeEvery(Actions.FETCH_ITEMS_REQUESTED, handleAvalonApiRequest(fetchItemsSaga));
}

export default fetchItems;
