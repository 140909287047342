import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Flex, Section, Text } from 'sg-styleguide/';
import FileManager from '../../containers/file-manager';
import { toggleCodeEditor } from '../../containers/file-manager/core/actions/file-manager';
import { FirstLevelTitle } from '../../containers/titles';
import './file-manager.scss';

class FileManagerPage extends React.Component<any, any> {
  render() {
    // TODO  check if propsToPropagate is needed
    const { actions, codeEditorIsVisible, environment, intl, ...propsToPropagate } = this.props;
    const isDesktop = environment.isDesktop;

    return (
      <Section className="file-manager-section-expanded" density={isDesktop ? 'cozy' : 'none'}>
        <Flex margin="none" justify="space-between" align="center">
          <FirstLevelTitle>
            {intl.formatMessage({ id: 'translate.page.translate.file.manager.title' })}
          </FirstLevelTitle>

          {!isDesktop && (
            <Text
              color="secondary"
              transform="uppercase"
              style={{ cursor: 'pointer' }}
              onClick={() => actions.toggleCodeEditor()}
            >
              {intl.formatMessage(
                { id: codeEditorIsVisible ? 'translate.generic.explorer' : 'translate.generic.editor' }
              )}
            </Text>
          )}
        </Flex>

        <FileManager {...propsToPropagate} />
      </Section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ toggleCodeEditor }, dispatch)
});

const mapStateToProps = (state) => ({
  environment: state.environment,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerPage));
