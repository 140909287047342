import { RootState } from '../reducers';
import { API_RESOURCE } from '../constants/api';

export const hasPermission = (siteMetaApi: SiteMetaApi, pageEndpoint: string) => {
  if (siteMetaApi.loading) {
    return false;
  }

  const endpoint = siteMetaApi.endpoints && siteMetaApi.endpoints[pageEndpoint];
  return endpoint && endpoint.methods && Object.keys(endpoint.methods).length > 0 &&
    (endpoint.methods.GET || endpoint.methods.POST || endpoint.methods.PUT || endpoint.methods.DELETE);
};

export const getAvailableSlots = ({ siteMetaApi, pageItems }: Partial<RootState>): number => {
  if (siteMetaApi.loading) {
    return 0;
  }

  const items = pageItems[API_RESOURCE.BACKUP.resourceName] || [];
  const manualBackups = items.filter((backup) => (backup.instant_backup)).length;

  const { n_backups_allowed, max_user_backups } = siteMetaApi.features;
  return Math.min((max_user_backups - manualBackups), n_backups_allowed);
};

export const isSiteRecentlyCreated = ({ siteMetaApi }: Partial<RootState>, hoursBehind: number): boolean => {
  if (siteMetaApi.loading) {
    return true;
  }

  const { features } = siteMetaApi;
  const { site_created } = features;
  const siteCreation = site_created * 1000;

  const currentTime = new Date();
  const siteCreationTimeMargin = +currentTime.setHours(currentTime.getHours() - hoursBehind);

  return Boolean(siteCreationTimeMargin <= siteCreation);
};

export const isDynamicCacheEnabled = ({ siteMetaApi }: Partial<RootState>): boolean => {
  if (siteMetaApi.loading) {
    return false;
  }

  return Boolean(
    siteMetaApi.features &&
    siteMetaApi.features.dynamic_cache
  );
};

export const isMemcacheEnabled = ({ siteMetaApi }: Partial<RootState>): boolean => {
  if (siteMetaApi.loading) {
    return false;
  }

  return Boolean(
    siteMetaApi.features &&
    siteMetaApi.features.memcache_allowed
  );
};

export const isACLActionEnabled = ({ siteMetaApi }: Partial<RootState>, endpoint: string, role: RoleAction) => {
  if (siteMetaApi.loading) {
    return false;
  }

  return Boolean(
    siteMetaApi.endpoints &&
    siteMetaApi.endpoints[endpoint] &&
    siteMetaApi.endpoints[endpoint].meta &&
    Boolean(siteMetaApi.endpoints[endpoint].meta[role])
  );
};
