import * as React from 'react';
import { connect } from 'react-redux';

import { Flex, Icon, Link, Text } from 'sg-styleguide/lib/components';
import { SupportPopup as Support } from 'sg-styleguide/lib/composite';
import { RootState } from '../../../core/reducers';
import * as Actions from '../../../core/actions/support';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';

type StateProps = {
  article: any;
  pinned: any;
  popular: any[];
  suggestions: any[];
  searchResults: any[];
  popup: any;
  requests: any;
};

type DispatchProps = {
  requestArticles: Function;
  requestArticle: Function;
  requestSuggestions: Function;
  requestSearch: Function;
  saveSupportState: Function;
  clearSupportState: Function;
};

type State = {
  isOpen: boolean;
};

class SupportPopup extends React.PureComponent<StateProps & DispatchProps, State> {
  iconRef = React.createRef();
  popupRef = React.createRef() as any;
  state = {
    isOpen: false
  };

  openPopup = () => {
    this.setState({ isOpen: true });
    this.props.requestArticles(window.location.pathname);
  }

  closePopup = () => {
    this.setState({ isOpen: false });
    this.props.clearSupportState();
  }

  componentDidMount() {
    if (this.props.popup) {
      this.setState({ isOpen: true });
    }
  }

  componentDidUpdate() {
    if (this.props.popup) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const { requested } = this.props.requests;
    const iconRect = this.iconRef.current ? (this.iconRef.current as any).getBoundingClientRect() : null;

    return (
      <React.Fragment>
        <Icon name="question-mark" size="30" onClick={this.openPopup} ref={this.iconRef} />

        {this.state.isOpen && (
          <Support
            ref={this.popupRef}
            isLoading={requested.length > 0}
            article={this.props.article}
            pinned={this.props.pinned}
            popular={this.props.popular}
            related={this.props.popular}
            searchResults={this.props.searchResults}
            suggestions={this.props.suggestions}
            onNavigate={this.props.requestArticle}
            onSearch={this.props.requestSearch}
            onSuggest={this.props.requestSuggestions}
            onClose={this.closePopup}
            initialState={this.props.popup}
            onUpdate={this.props.saveSupportState}
            style={{
              top: iconRect.bottom + 10,
              right: window.innerWidth - iconRect.right
            }}
            helpLink={(
              <Flex gutter="none" margin="none" shrink="none" justify="center">
                <Link href={CONFIG.UA_URL + '/support'}>
                  <Text tag="span" color="secondary" weight="bold">View Help Center</Text>
                </Link>
              </Flex>
            )}
          />
        )}
    </React.Fragment>
    );
  }
}

const mapStore = (store: RootState) => ({
  article: store.support.article,
  pinned: store.support.pinned,
  popular: store.support.popular,
  suggestions: store.support.suggestions,
  searchResults: store.support.searchResults,
  popup: store.support.popup,
  requests: getBlockingRequests(store, [{ requestTypeName: customRequestTypes.SUPPORT_LOADING }])
});

export default connect<StateProps, DispatchProps, {}>(mapStore, Actions)(SupportPopup);
