import * as React from 'react';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import * as ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'sg-styleguide';
import * as crudActions from '../../../core/actions/crud';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { RootState } from '../../../core/reducers';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../containers/sg-dialog';
import { DeleteDialog } from '../dialogs';
import PartialLoader from '../partial-loader';
import Breadcrumbs from './breadcrumbs';
import MonacoEditor from './code-editor';
import FileManagerContent from './content';
import FileManagerContextMenu from './context-menu';
import * as fileManagerActions from './core/actions/file-manager';
import * as Constants from './core/constants/actions';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_CONTEXT_MENU_TYPE } from './core/constants/common';
import { initialFileManagerState } from './core/reducers/utils/initial-state';

import {
  getCodeEditorEntityContent,
  getCodeEditorEntityUpdatedContent,
  getEntityByPath,
  getEntityInfoNumber,
  getEntityName,
  getEntityParentPath,
  getEntityPath,
  getEntityReadableName,
  getEntitySize,
  getEntityType,
  isEntityActiveInCodeEditor,
  isEntityForbiddenInCodeEditor,
  isEntityKnownInCodeEditor,
  isEntityOpenedInCodeEditor,
  isEntityUpdatedInCodeEditor
} from './core/utils';

import {
  CodeEditorCloseTabsConfirmationDialog,
  ConfirmDialog,
  FailedUploadFilesDialog,
  MessageDialog,
  UploadFilesConfirmationDialog
} from './dialogs';
import {
  ArhiveForm,
  ChmodPermissionsForm,
  CreateDirForm,
  CreateFileForm,
  MoveEntityForm,
  RenameEntityForm
} from './dialogs/form';
import './file-manager.scss';
import Footer from './footer';
import Header from './header';
import SideNavigation from './side-navigation';

const ReactDOMWithoutTypes: any = ReactDOM; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20062

class FileManager extends React.Component<any, any> {
  filemanagerDOM = null;
  splitContainerRef = null;
  startPanelRef = null;
  endPanelRef = null;
  splitterRef = null;
  fileUploadInput = null;
  folderUploadInput = null;
  editor = null;

  state = {
    archiveDialog: null,
    deleteDialog: null,
    moveDialog: null,
    createFileDialog: null,
    createDirectoryDialog: null,
    renameDialog: null,
    permissionsDialog: null,
    uploadConfirmationDialog: null,
    failedUploadFilesDialog: null,
    closeCodeEditorTabsConfirmationDialog: null,
    fileManagerHeight: 'auto'
  };

  fetchDir = (path) => {
    this.props.actions.fetchDir({
      urlParams: {
        id: path
      }
    });
  };

  componentDidMount() {
    const { router, entities } = this.props;

    if (entities['/'] === undefined) {
      this.fetchDir('/');
    }

    if (this.splitterRef) {
      this.splitterRef.addEventListener('mousedown', this.splitContainerMouseDownHandler, false);
    }

    if (this.splitContainerRef) {
      this.splitContainerRef.addEventListener('mouseup', this.splitContainerMouseUpHandler, false);
    }

    this.setFileManagerHeight();
  }

  componentWillUnmount() {
    if (this.splitterRef) {
      this.splitterRef.removeEventListener('mousedown', this.splitContainerMouseDownHandler, false);
    }
    if (this.splitContainerRef) {
      this.splitContainerRef.removeEventListener('mouseup', this.splitContainerMouseUpHandler, false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.environment.height !== this.props.environment.height) {
      this.setFileManagerHeight(nextProps);
    }

    if (nextProps.selectedNavigationEntity && !this.props.selectedNavigationEntity) {
      this.fetchDir(getEntityPath(nextProps.selectedNavigationEntity));
    }

    if (nextProps.currentDomainName !== this.props.currentDomainName) {
      this.props.actions.clearFileManagerStore(initialFileManagerState);
      this.fetchDir('/');
    }
  }

  setFileManagerHeight = (props = this.props) => {
    const spaceToBottom = props.environment.isDesktop ? 20 : 0;
    const environmentHeight = props.environment.height;
    const FMBoundingClientRect = this.filemanagerDOM.getBoundingClientRect();
    this.setState({ fileManagerHeight: `${environmentHeight - FMBoundingClientRect.top - spaceToBottom}px` });
  };

  render() {
    const { menuAction, codeEditor } = this.getAction();
    return (
      <div
        ref={(filemanager) => this.filemanagerDOM = filemanager}
        className="file-manager"
        style={{ height: this.state.fileManagerHeight }}
        data-e2e="file-manager"
      >
        <PartialLoader
          position="absolute"
          message={this.props.intl.formatMessage({
            id: 'translate.file.manager.fetch.folder.with.size.loader.message'
          })}
          resources={[
            { requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES }
          ]}
        >
          {this.props.environment.isDesktop ? this.renderDesktop() : this.renderMobile()}

          {this.props.contextMenu && (
            ReactDOMWithoutTypes.createPortal(
              <FileManagerContextMenu
                menuAction={menuAction}
                codeEditor={codeEditor}
                onClose={this.closeContextMenu}
                {...this.props.contextMenu}
              />,
              document.body
            )
          )}

          {this.renderDialogs()}

          <input
            ref={(input) => this.fileUploadInput = input}
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={(event) => {
              this.uploadFiles(event);
              event.target.value = '';
            }}
          />

          <input
            ref={(input) => this.folderUploadInput = input}
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={(event) => {
              this.uploadFiles(event);
              event.target.value = '';
            }}
          />
        </PartialLoader>
      </div>
    );
  }

  renderMobile() {
    const { codeEditorIsVisible, selectedContentEntities } = this.props;
    const { menuAction, codeEditor } = this.getAction();

    return (
      <React.Fragment>
        {!codeEditorIsVisible && this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderDesktop() {
    return (
      <React.Fragment>
        {this.renderHeader()}
        {this.renderBreadcrumb()}
        {this.renderPanel()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderHeader() {
    const { menuAction, codeEditor } = this.getAction();

    return (
      <Header
        key="header"
        menuAction={menuAction}
        codeEditor={codeEditor}
        openContextMenu={this.openContextMenu}
      />
    );
  }

  renderBreadcrumb() {
    return <Breadcrumbs key="breadcrumbs" />;
  }

  renderPanel() {
    return (
      <article
        ref={(splitContainer) => this.splitContainerRef = splitContainer}
        key="panel"
        className="panel"
      >
        {this.renderNavigation()}

        <div
          ref={(splitter) => this.splitterRef = splitter}
          className="panel__splitter"
        />

        {this.renderContent()}
      </article>
    );
  }

  renderNavigation() {
    const { dragAndDrop, codeEditor } = this.getAction();
    const { contextNavigationEntity } = this.props;

    const classes = [
      'panel__start',
      contextNavigationEntity && 'panel-disable-scroll'
    ].filter(Boolean).join(' ');

    return (
      <aside
        ref={(panelStart) => this.startPanelRef = panelStart}
        className={classes}
        onContextMenu={(event) => this.openContextMenu(event, FILE_MANAGER_CONTEXT_MENU_TYPE.BASE)}
      >
        <SideNavigation
          domains={this.props.domains}
          dragAndDrop={dragAndDrop}
          codeEditor={codeEditor}
          openContextMenu={this.openContextMenu}
        />
      </aside>
    );
  }

  renderContent() {
    const { contextMenu, codeEditorFileContent, codeEditorFileExtension, codeEditorIsVisible } = this.props;
    const { dragAndDrop, codeEditor, menuAction } = this.getAction();

    const classes = [
      'panel__end',
      (codeEditorIsVisible || contextMenu) && 'panel-disable-scroll'
    ].filter(Boolean).join(' ');

    return (
      <section
        ref={(endPanel) => this.endPanelRef = endPanel}
        className={classes}
      >
        <PartialLoader
          position="absolute"
          resources={[
            { requestTypeName: customRequestTypes.FILE_MANAGER_SAVE },
            { requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_FILE }
          ]}
        >
          {!codeEditorIsVisible && (
            <FileManagerContent
              dragAndDrop={dragAndDrop}
              codeEditor={codeEditor}
              openContextMenu={this.openContextMenu}
            />
          )}

          {codeEditorIsVisible && (
            <MonacoEditor
              onRefsReady={(editor) => {
                this.editor = editor;
              }}
              code={codeEditorFileContent}
              extension={codeEditorFileExtension}
              saveFile={menuAction.saveFile}
              createFile={menuAction.createFile}
              onTabClose={(entity) => codeEditor.closeTabWithConfirmation(entity)}
            />
          )}
        </PartialLoader>
      </section>
    );
  }

  renderFooter() {
    return (
      <Footer
        key="footer"
        onFailedClick={() => this.setState(
          { failedUploadFilesDialog: true },
          () => this.props.openSGDialog(DIALOGS.FILE_MANAGER_FAILED_UPLOAD)
        )}
        openContextMenu={this.openContextMenu}
      />
    );
  }

  renderDialogs() {
    return (
      <div>
        {this.renderArchiveDialog()}
        {this.renderChmodPermissionsDialog()}
        {this.renderCodeEditorCloseTabsConfirmationDialog()}
        {this.renderCreateDirectoryDialog()}
        {this.renderCreateFileDialog()}
        {this.renderDeleteDialog()}
        {this.renderFailedUploadFilesDialog()}
        {this.renderMoveDialog()}
        {this.renderRenameDialog()}
        {this.renderUploadFilesConfirmationDialog()}
        <MessageDialog />
        <ConfirmDialog />
      </div>
    );
  }

  renderArchiveDialog() {
    const { archiveDialog } = this.state;
    const { intl } = this.props;
    const entities = archiveDialog ? archiveDialog.entities : [];
    let fileCount = 0;
    let folderCount = 0;

    entities.forEach((entity) => {
      if (getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
        fileCount += 1;
      } else {
        folderCount += 1;
      }
    });

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_ARHIVE}
        icon="archive"
        title={intl.formatMessage({ id: 'translate.file.manager.archive.dialog.title' }, { fileCount, folderCount })}
        submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
        resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
      >
        <ArhiveForm
          initialValues={archiveDialog}
          onSubmit={(data) => {
            this.archiveDir({ filename: data.filename, entities });
          }}
        />
      </SGDialogForm>
    );
  }

  renderChmodPermissionsDialog() {
    const { intl, closeSGDialog } = this.props;
    const { permissionsDialog } = this.state;
    const entities = permissionsDialog ? permissionsDialog.entities : [];
    const selectedEntitiesCount = entities.length;

    const title = selectedEntitiesCount === 1 ?
      intl.formatMessage(
        { id: 'translate.file.manager.permissions.dialog.title.single.entity' },
        { entityName: getEntityReadableName(entities[0]) }
      ) :
      intl.formatMessage(
        { id: 'translate.file.manager.permissions.dialog.title.multiple.entities' },
        { entitiesCount: selectedEntitiesCount }
      );

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_PERMISSIONS}
        icon="key"
        title={title}
        resources={[{
          requestTypeName: customRequestTypes.FILE_MANAGER_POST
        }, {
          requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR
        }]}
      >
        <ChmodPermissionsForm
          fetchDir={this.fetchDir}
          initialValues={{
            _metaFields: {
              resourceNameMetaApi: 'dir-perms',
              endpoint: Constants.FILE_MANGER_API_CHANGE_PERMISSIONS
            }
          }}
          entities={permissionsDialog && permissionsDialog.entities}
          onSubmit={(data) => {
            const entries = entities.map((key) => getEntityPath(key));
            const hasOnlyFoldersSelected = entities.every((entity) => (
              getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY
            ));
            const hasOnlyFilesSelected = entities.every((entity) => (
              getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE
            ));

            const params: any = {
              endpoint: Constants.FILE_MANGER_API_CHANGE_PERMISSIONS,
              urlParams: {
                entries,
                file_perms: data.file_perms,
                dir_perms: data.dir_perms,
                recursive: Number(Boolean(data.recursive))
              },
              entity: entities[0],
              clearFileManagerStore: {
                // should be clear, because selectedContentEntities is not updated with dir-fetch
                selectedContentEntities: []
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.file.manager.change.permissions.success.message',
                    intlValues: { total: entities.length }
                  },
                  error: {
                    intlKey: 'translate.file.manager.change.permissions.error.message',
                    intlValues: { total: entities.length }
                  }
                }
              }
            };

            if (hasOnlyFilesSelected) {
              delete params.urlParams.dir_perms;
            }

            if (hasOnlyFoldersSelected && !data.recursive) {
              delete params.urlParams.file_perms;
            }

            this.props.actions.fileManagerPostRequest(params, () => closeSGDialog(REDUX_FORM.FILE_MANAGER_PERMISSIONS));
          }}
        />
      </SGDialogForm>
    );
  }

  renderCodeEditorCloseTabsConfirmationDialog() {
    const { closeSGDialog } = this.props;
    const { closeCodeEditorTabsConfirmationDialog } = this.state;
    const { codeEditor } = this.getAction();

    return (
      <CodeEditorCloseTabsConfirmationDialog
        closeSGDialog={() => closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE)}
        handleSave={() => codeEditor.saveTabContent(closeCodeEditorTabsConfirmationDialog)}
        handleDontSave={() => {
          if (closeCodeEditorTabsConfirmationDialog.length > 1) {
            codeEditor.closeAllTabs();
          } else {
            codeEditor.closeTab(closeCodeEditorTabsConfirmationDialog[0]);
          }
        }}
        files={closeCodeEditorTabsConfirmationDialog}
      />
    );
  }

  renderCreateDirectoryDialog() {
    const { createDirectoryDialog } = this.state;
    const { intl } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_CREATE_DIR}
        icon="folder-new"
        state="active"
        title={intl.formatMessage({ id: 'translate.file.manager.create.directory.dialog.title' })}
        submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
        resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
      >
        <CreateDirForm
          initialValues={createDirectoryDialog}
          onSubmit={this.createDir}
        />
      </SGDialogForm>
    );
  }

  renderCreateFileDialog() {
    const { createFileDialog } = this.state;
    const { intl } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_CREATE_FILE}
        icon="file-new"
        state="active"
        title={intl.formatMessage({ id: 'translate.file.manager.create.file.dialog.title' })}
        submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
        resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_SAVE }]}
      >
        <CreateFileForm
          initialValues={createFileDialog}
          onSubmit={this.createFile}
        />
      </SGDialogForm>
    );
  }

  renderDeleteDialog() {
    const { deleteDialog } = this.state;
    const { intl } = this.props;
    const entities = deleteDialog && deleteDialog.entities;
    const entityName = entities && getEntityReadableName(entities[0]);
    const entitiesCount = entities && entities.length;
    const codeEditorEntitiesThatNeedToBeClosed = deleteDialog && deleteDialog.codeEditorEntitiesThatNeedToBeClosed;

    return (
      <DeleteDialog
        title={intl.formatMessage(
          {
            id: entitiesCount === 1 ?
              'translate.file.manager.delete.dialog.title.single.entity' :
              'translate.file.manager.delete.dialog.title.multiple.entities'
          },
          { entityName, count: entitiesCount })}
        onSubmit={() => this.deleteDir(entities, codeEditorEntitiesThatNeedToBeClosed)}
      />
    );
  }

  renderFailedUploadFilesDialog() {
    const { failedUploadFilesDialog } = this.state;

    return (
      <FailedUploadFilesDialog
        files={this.props.uploader.failed}
        handleClose={() => this.props.closeSGDialog(DIALOGS.FILE_MANAGER_FAILED_UPLOAD)}
        handleSubmit={() => this.props.actions.uploadFiles(this.props.uploader.failed)}
      />
    );
  }

  renderMoveDialog() {
    const { moveDialog } = this.state;
    const { intl, openSGDialog } = this.props;

    let entities;
    let entity;
    let entityName;
    let entityPath;
    let initialValues;
    let title;

    if (moveDialog) {
      entities = moveDialog.entities;
      entity = entities[0];
      entityName = getEntityReadableName(entity);
      entityPath = getEntityPath(entity).replace(getEntityName(entity), '');
      initialValues = { ...moveDialog, dest: entityPath };
      title = entities.length > 1
        ? intl.formatMessage({ id: 'translate.file.manager.move.multiple.dialog.title' }, { count: entities.length })
        : intl.formatMessage({ id: 'translate.file.manager.move.dialog.title' }, { entityName });
    }

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_MOVE_ENTITY}
        icon="move"
        title={title}
        resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
      >
        <MoveEntityForm
          openSGDialog={openSGDialog}
          initialValues={initialValues}
          onSubmit={(data) => this.moveDir({ dest: data.dest, entities })}
        />
      </SGDialogForm>
    );
  }

  renderRenameDialog() {
    const { renameDialog } = this.state;

    const { intl } = this.props;
    const entity = renameDialog && renameDialog.entity;
    const entityName = entity && getEntityReadableName(entity);

    return (
      <SGDialogForm
        name={REDUX_FORM.FILE_MANAGER_RENAME_ENTITY}
        icon="rename"
        title={intl.formatMessage({ id: 'translate.file.manager.rename.dialog.title' }, { entityName })}
        resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
      >
        <RenameEntityForm
          initialValues={{ ...renameDialog, id: entityName }}
          onSubmit={(data) => this.renameDir({ id: data.id, entity })}
        />
      </SGDialogForm>
    );
  }

  renderUploadFilesConfirmationDialog() {
    const { intl, actions, closeSGDialog } = this.props;
    const uploadConfirmationDialog = this.state.uploadConfirmationDialog || [];

    return (
      <SGDialog
        id={DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD}
        icon="warning"
        density="none"
        size="large"
        state="inactive"
        title={intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.dialog.title' })}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD} />

            <Button
              color="primary"
              data-e2e="footer-button-confirm"
              onClick={() => {
                const validFilesForUpload = uploadConfirmationDialog.filter((file) => file._meta.isValid);
                actions.uploadFiles(validFilesForUpload);
                closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD);
              }}
            >
              {intl.formatMessage({ id: 'translate.generic.ok' })}
            </Button>
          </React.Fragment>
        }
      >
        <UploadFilesConfirmationDialog
          files={uploadConfirmationDialog.filter((file) => file._meta.isValid === false)}
        />
      </SGDialog>
    );
  }

  openContextMenu = (event, type) => {
    event.preventDefault();
    const clientX = event.clientX;
    const clientY = event.clientY;

    if (this.props.contextMenu && this.props.contextMenu.clientX) {
      // wait for context to close and then reopen it.
      setTimeout(() => {
        this.props.actions.setContextMenuPosition({ clientX, clientY, type });
      }, 400);
    } else {
      this.props.actions.setContextMenuPosition({ clientX, clientY, type });
    }
  };

  closeContextMenu = (event) => {
    if (!this.props.contextMenu) {
      return;
    }

    this.props.actions.setContextMenuPosition(null);

    if (event && event.nativeEvent) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  getCodeEditorEntitiesThatNeedToBeClosed() {
    const { codeEditorFiles } = this.props;
    const prioritizedEntities = this.getPrioritizedEntities();

    const codeEditorFilesToClose = [];

    prioritizedEntities.forEach((entity) => {
      codeEditorFiles.forEach((ent) => {
        if (getEntityPath(ent).indexOf(getEntityPath(entity)) !== -1) {
          codeEditorFilesToClose.push(ent);
        }
      });
    });

    return codeEditorFilesToClose;
  }

  getAction: any = () => ({
    menuAction: {
      // Per domain
      createDir: () => {
        this.setState({
          createDirectoryDialog: {
            _metaFields: {
              resourceNameMetaApi: 'dir',
              endpoint: Constants.FILE_MANGER_API_DIR
            }
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_DIR));
      },
      createFile: () => {
        this.setState({
          createFileDialog: {
            _metaFields: {
              resourceNameMetaApi: 'file',
              endpoint: Constants.FILE_MANGER_API_FILE
            }
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE));
      },

      openFileUpload: () => {
        this.openFileUpload();
      },

      openFolderUpload: () => {
        this.openFolderUpload();
      },

      // Only Folder
      fetchDirWithFolderSize: () => {
        this.fetchDirWithFolderSize();
      },

      // Only Files
      downloadFile: () => {
        this.downloadFile();
      },
      extractDir: () => {
        this.extractDir();
      },
      renameDir: () => {
        this.setState({
          renameDialog: {
            entity: this.getPrioritizedEntities()[0],
            _metaFields: {
              resourceNameMetaApi: 'dir-rename',
              endpoint: Constants.FILE_MANGER_API_DIR_RENAME
            }
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_RENAME_ENTITY));
      },

      // Files and Folders
      archiveDir: () => {
        this.setState({
          archiveDialog: {
            entities: this.getPrioritizedEntities(),
            _metaFields: {
              resourceNameMetaApi: 'file',
              endpoint: Constants.FILE_MANGER_API_DIR_ARCHIVE
            }
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_ARHIVE));
      },
      markForCopy: () => {
        this.markForCopy();
      },
      deleteDir: () => {
        this.setState({
          deleteDialog: {
            entities: this.getPrioritizedEntities(),
            codeEditorEntitiesThatNeedToBeClosed: this.getCodeEditorEntitiesThatNeedToBeClosed()
          }
        }, () => this.props.openSGDialog(DIALOGS.GENERIC_DELETE));
      },
      moveDir: () => {
        this.setState({
          moveDialog: {
            entities: this.getPrioritizedEntities(),
            _metaFields: {
              resourceNameMetaApi: Constants.FILE_MANGER_API_DIR_MOVE.replace('/', ''),
              endpoint: Constants.FILE_MANGER_API_DIR_MOVE
            }
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY));
      },
      pasteDir: () => {
        this.pasteDir();
      },
      changeEntityPermissions: () => {
        this.setState({
          permissionsDialog: {
            entities: this.getPrioritizedEntities()
          }
        }, () => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_PERMISSIONS));
      },

      // Code Editor
      saveFile: () => {
        this.saveFile();
      }
    },
    dragAndDrop: {
      moveDir: (options) => {
        this.moveDirByDragAndDrop(options);
      },
      uploadFiles: (options) => {
        this.uploadFilesByDragAndDrop(options);
      }
    },
    codeEditor: {
      openFile: (file) => {
        this.openFile(file);
      },

      downloadActiveTabContent: () => {
        this.editor.downloadActiveTabContent();
      },

      openFindPanel: () => {
        this.editor.openFindPanel();
      },

      openFindReplacePanel: () => {
        this.editor.openFindReplacePanel();
      },

      closeTab: (entity) => {
        this.props.actions.codeEditorOnTabClose(entity);
      },

      closeAllTabs: () => {
        const { codeEditorFiles } = this.props;

        codeEditorFiles.forEach((entity) => {
          this.props.actions.codeEditorOnTabClose(entity);
        });
      },

      closeTabWithConfirmation: (entity) => {
        if (getCodeEditorEntityContent(entity) !== getCodeEditorEntityUpdatedContent(entity)) {
          return this.setState({
            closeCodeEditorTabsConfirmationDialog: [entity]
          }, () => this.props.openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE));
        }

        this.props.actions.codeEditorOnTabClose(entity);
      },

      closeTabsWithConfirmation: () => {
        const entities = this.props.codeEditorFiles;
        const dirtyEntities = [];
        const pureEntities = [];

        entities.forEach((entity) => {
          if (getCodeEditorEntityContent(entity) !== getCodeEditorEntityUpdatedContent(entity)) {
            dirtyEntities.push(entity);
          } else {
            pureEntities.push(entity);
          }
        });

        if (dirtyEntities.length > 0) {
          this.setState({
            closeCodeEditorTabsConfirmationDialog: dirtyEntities
          }, () => this.props.openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE));
        }

        pureEntities.forEach((entity) => this.props.actions.codeEditorOnTabClose(entity));
      },

      saveTabContent: (entities) => {
        entities.forEach((entity) => {
          const fileName = getEntityReadableName(entity);
          this.props.actions.saveFile({
            urlParams: {
              filename: getEntityPath(entity)
            },
            file: getCodeEditorEntityUpdatedContent(entity),
            entity,
            autoClose: true,
            _meta: {
              name: fileName,
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.file.manager.save.file.success.message',
                  intlValues: { name: fileName }
                },
                error: {
                  intlKey: 'translate.file.manager.save.file.error.message',
                  intlValues: { name: fileName }
                }
              }
            }
          });
        });
      }
    }
  });

  getPrioritizedEntities = () => {
    const {
      contextContentEntities,
      contextNavigationEntity,
      selectedContentEntities,
      selectedNavigationEntity
    } = this.props;

    if (contextContentEntities.length) {
      return contextContentEntities;
    }

    if (contextNavigationEntity) {
      return [contextNavigationEntity];
    }

    if (selectedContentEntities.length) {
      return selectedContentEntities;
    }

    if (selectedNavigationEntity) {
      return [selectedNavigationEntity];
    }

    return [];
  };

  archiveDir = ({ filename, entities }) => {
    const { intl, selectedNavigationEntity } = this.props;
    const entries = entities.map((entity) => getEntityReadableName(entity));
    const entityPath = getEntityPath(selectedNavigationEntity);

    this.props.actions.fileManagerPostRequest({
      endpoint: Constants.FILE_MANGER_API_DIR_ARCHIVE,
      urlParams: {
        entries,
        start_dir: entityPath,
        output_file: `${entityPath}/${filename}.zip`
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.archive.dir.success.message',
            intlValues: { name: `${filename}.zip` }
          },
          error: {
            intlKey: 'translate.file.manager.archive.dir.error.message',
            intlValues: { name: `${filename}.zip` }
          }
        }
      }
    }, () => this.props.closeSGDialog(REDUX_FORM.FILE_MANAGER_ARHIVE));
  };

  markForCopy = () => {
    const { intl } = this.props;
    const entities = this.getPrioritizedEntities();

    this.props.actions.markForCopy(entities);

    this.props.createNotification({
      type: 'generic',
      state: 'success',
      success: {
        intlKey: 'translate.file.manager.marked.for.copy.success',
        intlValues: { total: entities.length }
      },
      error: {}
    });
  };

  createDir = (data) => {
    const { closeSGDialog, selectedNavigationEntity } = this.props;
    const { id } = data;

    this.props.actions.fileManagerPostRequest({
      ...data,
      endpoint: Constants.FILE_MANGER_API_DIR,
      urlParams: {
        safe: 1,
        entries: [`${getEntityPath(selectedNavigationEntity)}/${id}`]
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.create.dir.success.message',
            intlValues: { name: id }
          },
          error: {
            intlKey: 'translate.file.manager.create.dir.error.message',
            intlValues: { name: id }
          }
        }
      }
    }, () => closeSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_DIR));
  };

  createFile = (formData) => {
    const fileName = formData.filename;
    const { closeSGDialog, codeEditorIsVisible, selectedNavigationEntity } = this.props;
    const isNavigationEntityFile = getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const newFileParentPath = isNavigationEntityFile ?
      getEntityParentPath(selectedNavigationEntity) :
      getEntityPath(selectedNavigationEntity);

    this.props.actions.saveFile({
      ...formData,
      urlParams: {
        safe: 1,
        filename: `${newFileParentPath}/${fileName}`
      },
      file: '',
      _meta: {
        name: fileName,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.create.file.success.message',
            intlValues: { name: fileName }
          },
          error: {
            intlKey: 'translate.file.manager.create.file.error.message',
            intlValues: { name: fileName }
          }
        }
      },
      autoOpen: codeEditorIsVisible
    }, () => closeSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE));
  };

  deleteDir = (entities, codeEditorEntitiesThatNeedToBeClosed) => {
    const { intl, selectedNavigationEntity, fileManagerState } = this.props;
    const clearFileManagerStore: any = {
      selectedContentEntities: []
      // TODO delete all entities with path ...
    };

    const entries = entities.map((entity) => getEntityPath(entity));

    const selectedNavigationEntityForDelete = entities.find((entity) =>
      getEntityPath(entity) === getEntityPath(selectedNavigationEntity)
    );

    if (selectedNavigationEntityForDelete) {
      const entityParentPath = getEntityParentPath(selectedNavigationEntityForDelete);
      clearFileManagerStore.selectedNavigationEntity = getEntityByPath(entityParentPath, fileManagerState);
    }

    codeEditorEntitiesThatNeedToBeClosed.forEach((entity) => {
      this.props.actions.codeEditorOnTabClose(entity);
    });

    this.props.actions.deleteDir({
      urlParams: {
        entries
      },
      entity: entities[0],
      clearFileManagerStore,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.delete.success.message',
            intlValues: { total: entries.length }
          },
          error: {
            intlKey: 'translate.file.manager.delete.error.message',
            intlValues: { total: entries.length }
          }
        }
      }
    }, () => this.props.closeSGDialog(DIALOGS.GENERIC_DELETE));
  };

  downloadFile = () => {
    const prioritizedEntities = this.getPrioritizedEntities();

    prioritizedEntities.forEach((entity) => {
      this.props.actions.downloadFile({
        urlParams: {
          filename: getEntityPath(entity)
        }
      });
    });
  };

  extractDir = () => {
    const { intl } = this.props;
    const prioritizedEntities = this.getPrioritizedEntities();

    prioritizedEntities.forEach((entity) => {
      this.props.actions.fileManagerPostRequest({
        endpoint: Constants.FILE_MANGER_API_DIR_EXTRACT,
        urlParams: {
          id: getEntityPath(entity),
          dest: getEntityPath(entity).replace(/\.[^\.]+$/, '')
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.file.manager.extract.dir.success.message',
              intlValues: { name: getEntityReadableName(entity) }
            },
            error: {
              intlKey: 'translate.file.manager.extract.dir.error.message',
              intlValues: { name: getEntityReadableName(entity) }
            }
          }
        }
      });
    });
  };

  moveDir = ({ dest, entities }) => {
    const { intl, closeSGDialog, selectedNavigationEntity, fileManagerState } = this.props;
    const clearFileManagerStore: any = {};
    const entries = entities.map((entity) => getEntityPath(entity));
    const selectedNavigationEntityForDelete = entities.find((entity) =>
      getEntityPath(entity) === getEntityPath(selectedNavigationEntity)
    );

    if (selectedNavigationEntityForDelete) {
      const entityParentPath = getEntityParentPath(selectedNavigationEntityForDelete);
      clearFileManagerStore.selectedNavigationEntity = getEntityByPath(entityParentPath, fileManagerState);
    }

    this.props.actions.fileManagerPostRequest({
      endpoint: Constants.FILE_MANGER_API_DIR_MOVE,
      urlParams: {
        safe: 1,
        entries,
        dest
      },
      entity: entities[0],
      clearFileManagerStore,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.move.dir.success.message',
            intlValues: { total: entries.length, destination: dest }
          },
          error: {
            intlKey: 'translate.file.manager.move.dir.error.message',
            intlValues: { total: entries.length, destination: dest }
          }
        }
      }
    }, () => {
      this.props.actions.clearFileManagerStore(
        { selectedContentEntities: initialFileManagerState.selectedContentEntities }
      );
      closeSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY);
    });
  };

  moveDirByDragAndDrop = ({ dragSourceEntity, dropTargetEntity }) => {
    const { intl, selectedContentEntities } = this.props;
    const entries = selectedContentEntities.length === 0 ?
      [getEntityPath(dragSourceEntity)] :
      selectedContentEntities.map((entity) => getEntityPath(entity));

    this.props.actions.fileManagerPostRequest({
      endpoint: Constants.FILE_MANGER_API_DIR_MOVE,
      urlParams: {
        safe: 1,
        entries,
        dest: getEntityPath(dropTargetEntity)
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.move.dir.success.message',
            intlValues: { total: entries.length, destination: getEntityPath(dropTargetEntity) }
          },
          error: {
            intlKey: 'translate.file.manager.move.dir.error.message',
            intlValues: { total: entries.length, destination: getEntityPath(dropTargetEntity) }
          }
        }
      },

      // TODO refactor with better way for updateing the parent dir (like getEntityParentPath)
      entity: selectedContentEntities.length === 0 ? dragSourceEntity : selectedContentEntities[0]
    });
  };

  pasteDir = () => {
    const { intl } = this.props;
    const prioritizedEntity = this.getPrioritizedEntities()[0];
    const isEntityFile = getEntityType(prioritizedEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const dest = isEntityFile ? getEntityParentPath(prioritizedEntity) : getEntityPath(prioritizedEntity);

    this.props.markedForCopy.forEach((entity) => {
      this.props.actions.fileManagerPostRequest({
        endpoint: Constants.FILE_MANGER_API_DIR_COPY,
        urlParams: {
          safe: 1,
          entries: [getEntityPath(entity)],
          dest
        },
        clearFileManagerStore: {
          markedForCopy: null
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.file.manager.paste.success.message',
              intlValues: { total: this.props.markedForCopy.length }
            },
            error: {
              intlKey: 'translate.file.manager.paste.error.message',
              intlValues: { total: this.props.markedForCopy.length }
            }
          }
        }
      });
    });
  };

  uploadFiles = (event) => {
    const { intl, siteMetaApiLabels, selectedNavigationEntity } = this.props;
    const filenameRegex = new RegExp(siteMetaApiLabels.filename.regex);
    // The map is needed when the files comes from native input.
    // Then they are FileList not an array
    const files = Object.keys(event.target.files).map((key) => event.target.files[key]);

    const result = files.map((file) => {
      const fileName = file.webkitRelativePath || file.spanelFullPathForAPI || file.name;
      const meta = {
        isValid: true,
        name: fileName,
        path: fileName
      };

      if (filenameRegex.test(fileName) === false) {
        // Invalid name
        meta.isValid = false;
      }

      // TODO magic number
      if (file.size > 16 * 1024 * 1024) {
        meta.isValid = false;
      }

      return {
        urlParams: {
          safe: 1,
          filename: `${getEntityPath(selectedNavigationEntity)}/${fileName}`
        },
        file,
        _meta: meta,
        t: FILE_MANAGER_API_RESPONSE_DIR.FILE,
        n: meta.name // TODO needed for getEntity... think a way to refactor
      };
    });

    if (result.find((file) => file._meta.isValid === false)) {
      this.setState(
        { uploadConfirmationDialog: result },
        () => this.props.openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD)
      );
    } else {
      this.props.actions.uploadFiles(result);
    }
  };

  uploadFilesByDragAndDrop = (files) => {
    this.uploadFiles({ target: { files } });
  };

  openFile(ent) {
    const entity = ent ? ent : this.getPrioritizedEntities()[0];

    const fetchFile = () => {
      this.props.actions.fetchFile({
        urlParams: {
          filename: getEntityPath(entity)
        },
        entity
      });
    };

    switch (true) {
      case isEntityForbiddenInCodeEditor(entity):
        return this.props.openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
          title: 'translate.file.manager.file.cant.be.edited.not_allowed'
        });
      case !isEntityKnownInCodeEditor(entity):
        return this.props.openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM, {
          title: 'translate.file.manager.file.cant.be.edited.unknown',
          message: 'translate.file.manager.file.cant.be.edited.unknown.message',
          onConfirm: () => fetchFile()
        });
      case Boolean((getEntitySize(entity) > (10 * 1024 * 1024))):
        return this.props.openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
          title: 'translate.file.manager.file.cant.be.edited.size_limit'
        });
      case this.props.isEntityOpenedInCodeEditor(getEntityInfoNumber(entity)):
        this.props.actions.focusCodeEditorEntity(getEntityInfoNumber(entity));

        if (!this.props.isEntityUpdatedInCodeEditor(getEntityInfoNumber(entity))) {
          fetchFile();
        }

        return;
      default:
        fetchFile();
    }
  }

  openFileUpload = () => {
    this.fileUploadInput.click();
  };

  openFolderUpload = () => {
    // https://github.com/facebook/react/issues/3468
    this.folderUploadInput.setAttribute('directory', true);
    this.folderUploadInput.setAttribute('webkitdirectory', true);

    this.folderUploadInput.click();
  };

  renameDir = ({ id, entity }) => {
    const { intl, closeSGDialog } = this.props;

    this.props.actions.fileManagerPostRequest({
      endpoint: Constants.FILE_MANGER_API_DIR_RENAME,
      urlParams: {
        id: getEntityPath(entity),
        safe: 1,
        dest: `${getEntityParentPath(entity)}/${id}`
      },
      entity,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.rename.dir.success.message'
          },
          error: {
            intlKey: 'translate.file.manager.rename.dir.error.message'
          }
        }
      }
    }, () => closeSGDialog(REDUX_FORM.FILE_MANAGER_RENAME_ENTITY));
  };

  saveFile = () => {
    const { intl, codeEditorFiles } = this.props;
    const modifiedFile = codeEditorFiles.find((file) => isEntityActiveInCodeEditor(file));
    const fileName = getEntityReadableName(modifiedFile);

    this.props.actions.saveFile({
      urlParams: {
        filename: getEntityPath(modifiedFile)
      },
      file: getCodeEditorEntityUpdatedContent(modifiedFile),
      entity: modifiedFile,
      _meta: {
        name: fileName,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.save.file.success.message',
            intlValues: { name: getEntityReadableName(modifiedFile) }
          },
          error: {
            intlKey: 'translate.file.manager.save.file.error.message',
            intlValues: { name: getEntityReadableName(modifiedFile) }
          }
        }
      }
    });
  };

  fetchDirWithFolderSize = () => {
    const { intl, contextNavigationEntity, selectedNavigationEntity } = this.props;

    this.props.actions.fetchDirWithFolderSizes({
      urlParams: {
        id: getEntityPath(contextNavigationEntity ? contextNavigationEntity : selectedNavigationEntity),
        dir_sizes: 1
      },
      _meta: {
        notification: {
          type: 'generic',
          error: {
            intlKey: 'translate.file.manager.show.folder.size.error.message'
          }
        }
      }
    });
  };

  splitContainerMouseDownHandler = (event) => {
    this.splitContainerRef.addEventListener('mousemove', this.splitContainerMouseMoveHandler, false);
  };

  splitContainerMouseMoveHandler: any = (event) => {
    this.startPanelRef.style.width = `${event.clientX - this.startPanelRef.getBoundingClientRect().left}px`;
  };

  splitContainerMouseUpHandler = (event) => {
    this.splitContainerRef.removeEventListener('mousemove', this.splitContainerMouseMoveHandler, false);
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...crudActions, ...fileManagerActions }, dispatch),

  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),

  createNotification: (payload) => dispatch(createNotification(payload))
});

const mapStateToProps = (state: RootState) => ({
  currentDomainName: state.sites.currentDomainName,

  fileManagerState: state.fileManager,
  entities: state.fileManager.entities,
  environment: state.environment,

  codeEditorFiles: state.fileManager.codeEditor.files,
  codeEditorFileContent: state.fileManager.codeEditor.fileContent,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  codeEditorUserSelection: state.fileManager.codeEditor.userSelection,
  updatedFileContent: state.fileManager.codeEditor.updatedFileContent,

  contextMenu: state.fileManager.contextMenu,
  markedForCopy: state.fileManager.markedForCopy,

  contextContentEntities: state.fileManager.contextContentEntities,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,

  uploader: state.fileManager.uploader,

  siteMetaApiLabels: state.siteMetaApi.labels,
  isEntityOpenedInCodeEditor: (entityId) => isEntityOpenedInCodeEditor(state.fileManager, entityId),
  isEntityUpdatedInCodeEditor: (entityId) => isEntityUpdatedInCodeEditor(state.fileManager, entityId)
});

/**
 * Monkey pacth of React DND HTML5Backend for handling dnd of folders
 * @param manager
 * @returns {any}
 */
function makeFolderAwareHTML5Backend(manager) {
  const backend = HTML5Backend(manager);
  const orig = backend.handleTopDropCapture;
  backend.handleTopDropCapture = function (event) {
    /**
     * The try - catch block is needed for IE,
     * because reading event.dataTransfer.items leeds to error <Permission Denied>
     */
    try {
      if (backend.currentNativeSource) {
        backend.currentNativeSource.item.items = event.dataTransfer.items;
      }
    } catch (e) {
      console.error(e);
    }
    return orig(event);
  };
  return backend;
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    DragDropContext(makeFolderAwareHTML5Backend)(FileManager)
  )
);
