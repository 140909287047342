import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: any = {};

export default handleActions({
  [Actions.PAGE_INIT]: (state, action: any) => ({
    ...state,
    ...action.payload
  }),
  [Actions.PAGE_RESIZE]: (state, action: any) => ({
    ...state,
    ...action.payload
  })
}, initialState);
