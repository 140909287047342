import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Table } from 'sg-styleguide';
import Entity from '../content/entity';

const UploadCofirmationDialog = ({ intl, files = [] }) => {
  const columns = [{
    header: 'File Name',
    accessor: 'file',
    render: (file, entity) => {
      return (
        <Entity entity={entity} />
      );
    }
  }];

  return (
    <Table columns={columns} data={files} density="large" />
  );
};

export default injectIntl(UploadCofirmationDialog);
