import * as React from 'react';
import { connect } from 'react-redux';

const Hide = ({ children, env }) => env.isPhone ? null : children;

const Show = ({ children, env }) => env.isPhone ? children : null;

const Toggle = ({ children, env }) => env.isPhone ? children[0] : children[1];

const mapState = (state) => ({ env: state.environment });

export const HideOnMobile = connect(mapState)(Hide);

export const ShowOnMobile = connect(mapState)(Show);

export const ToggleOnMobile = connect(mapState)(Toggle);
