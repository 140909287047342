import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { Box, Button, Column, Grid, Flex, Icon, Text, Title, Label, Link, Notice } from 'sg-styleguide';

import {
  APP_TYPES,
  APP_TYPES_INFO_TEXT_BY_ID,
  APPS_LIST
} from '../../../../core/constants/application';

import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { JOOMLA_LANGUAGES, WP_LANGUAGES } from '../../../../core/constants/languages';
import { generateRandomPath } from '../../../../core/utils/path-generation';
import { formatPathInput, handleDefaultPathSelection, normalizeDirectoryData } from '../../../../core/utils/paths';
import { getCurrentSiteId } from '../../../../core/selectors';
import { isACLActionEnabled } from '../../../../core/selectors/site-meta-api';
import { getToolMainEndpoint } from '../../../../core/selectors/static-api';

import { navigateToUA } from '../../../../core/actions/nemo-store';

import FormCheckbox from '../../../components/form-checkbox';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import FormPassword from '../../../components/form-password';
import SelectDirectory from '../../../containers/dialogs/select-directory';
import { SGForm, SGSubmitButton } from '../../../containers/sg-form';
import { withSpanelForm } from '../../../containers/sg-spanel-forms';
import { FIELDS, VISUAL_VERIFICATIONS, WEEBLY_PLANS, WEEBLY_PLANS_OPTIONS } from '../constants';
import { findAppNameByType, findAppIconByType } from '../../../../core/utils/app-names';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { NotSupportedYet, getCmsIconSize, generateWeeblyPlanOptions } from './utils';
import { WeeblyExtra } from '../types';

type Props = {
  // connected props
  intl?: Intl;
  currentSiteId?: string;
  domains?: any[];
  openSGDialog?: Function;
  userLang?: string;
  isDesktop?: boolean;
  isPhone?: boolean;
  canOrderWeebly?: boolean;
  navigateToUA?: typeof navigateToUA;
  form?: string;
  validationUtils?: ValidationUtils;
  change?: Function;
  reset?: Function;
  getFormValues?: (formName: string) => any;
  sPanelFormSubmit?: (fieldName: string) => any;
  // props from parent
  activeWeebly?: WeeblyExtra;
  selectedAppType: string;
  onSelectAppType: Function;
  onSelectDomain: Function;
  activateFreeWeebly: Function;
  onSubmit: Function;
};

export class Form extends React.Component<Props, {}> {
  onSelectedWeebly = () => {
    const { change, activeWeebly } = this.props;

    if (activeWeebly) {
      const planOptions = generateWeeblyPlanOptions(activeWeebly && activeWeebly.plan);
      const nextAvailablePlan = planOptions.find(({ disabled }) => !disabled);

      change(FIELDS.WEEBLY_PLAN, nextAvailablePlan ? nextAvailablePlan.id : activeWeebly.plan);
    }
  };

  onAppTypeSelected = (ev, appType) => {
    const { selectedAppType, onSelectAppType } = this.props;
    const appTypeHasChanged = Boolean(selectedAppType !== appType);

    if (appTypeHasChanged) {
      onSelectAppType(appType);
      this.resetFormState(appType);

      if (appType === APP_TYPES.WEEBLY) {
        this.onSelectedWeebly();
      }
    }
  };

  resetFormState = (appType: string) => {
    const { reset, change, userLang } = this.props;
    const lang = userLang === 'en' ? 'en_US' : userLang;

    reset();

    change(FIELDS.LANGUAGE, lang);
    change(FIELDS.APP_TYPE, appType);

    // initialize default custom fields

    switch (appType) {
      case APP_TYPES.WORDPRESS:
      case APP_TYPES.WOO_COMMERCE:
        change(FIELDS.INSTALL_WP_STARTER, true);
        break;
      case APP_TYPES.MAGENTO:
        change(FIELDS.ADMIN_PATH, generateRandomPath(8));
        break;
      case APP_TYPES.PRESTASHOP:
        change(FIELDS.ADMIN_FOLDER, generateRandomPath(8));
        break;
      case APP_TYPES.OPEN_CART:
        change(FIELDS.DATA_DIRECTORY, generateRandomPath(8));
        break;
      case APP_TYPES.SMF:
        // Not supported for now:
        // change(FIELDS.VISUAL_VERIFICATION, 'medium');
        break;
      case APP_TYPES.MOODLE:
        change(FIELDS.DATA_DIRECTORY, generateRandomPath(8));
        break;
      case APP_TYPES.V_TIGER:
        change(FIELDS.ADMIN_USERNAME, 'admin');
        break;
      default:
        return;
    }
  };

  renderSelectApp = () => {
    const { validationUtils, intl } = this.props;
    const { required } = validationUtils;
    const appList = APPS_LIST.map(({ group, ...data }) => ({
      ...data,
      group: intl.formatMessage({ id: group })
    }));

    return (
      <Column smSpan="12">
        <Field
          label={intl.formatMessage({ id: 'translate.page.app-manager.create.select-app.label' })}
          name={FIELDS.APP_TYPE}
          options={appList}
          optionValue="id"
          optionLabel="label"
          groupBy="group"
          disableAutoSelect
          validate={[required]}
          onChange={this.onAppTypeSelected}
          disableGroupReorder
          template={({ id, label }) => (
            <Flex align="center" margin="none" gutter="none">
              <Icon
                size="20"
                name={findAppIconByType(id)}
                multicolor
              />
              <span style={{ marginLeft: '10px' }}>
                {intl.formatMessage({ id: label })}
              </span>
            </Flex>
          )}
          component={FormDropdown}
        />
      </Column>
    );
  };

  renderInfoBox = () => {
    const { intl, selectedAppType, isDesktop, isPhone, canOrderWeebly, currentSiteId, navigateToUA } = this.props;

    return (
      <Column smSpan="12">
        <Flex background="light" margin="none" padding="medium" direction="row">
          <Box>
            <Icon
              style={{ marginTop: '10px' }}
              size={getCmsIconSize({ isPhone, isDesktop })}
              name={findAppIconByType(selectedAppType)}
              multicolor
            />
          </Box>
          <Box style={{ width: isPhone ? '70%' : '80%' }}>
            <Title level="6" density="compact" color="dark">
              {findAppNameByType(selectedAppType)}
            </Title>
            <Text>
              <FormattedMessage
                id={APP_TYPES_INFO_TEXT_BY_ID[selectedAppType]}
                values={{
                  link: selectedAppType === APP_TYPES.WEEBLY && canOrderWeebly ? (
                    <Link onClick={() => navigateToUA({
                      page: 'sitebuilder',
                      siteId: currentSiteId
                    })}>
                      {intl.formatMessage({ id: 'translate.page.app-manager.compare-weebly-plans' })}
                    </Link>
                  ) : null
                }}
              />
            </Text>
          </Box>
        </Flex>
      </Column>
    );
  };

  renderApplicationSetup = () => {
    const {
      validationUtils,
      openSGDialog,
      onSelectDomain,
      intl,
      change,
      domains = [],
      form,
      getFormValues,
      selectedAppType,
      activeWeebly
    } = this.props;

    const { required } = validationUtils;
    const values = getFormValues(form);
    const selectedDomain = domains.find(({ id }) => id === values.domain_id);

    const initialPath = selectedDomain && selectedDomain.name && `/${selectedDomain.name}`;
    const publicHTML = '/public_html';
    const prefetchPaths = initialPath && [`${initialPath}${publicHTML}`];
    const pathToReplace = selectedDomain && `/${selectedDomain.name}${publicHTML}`;

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Title level="6" density="none" color="dark">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.application.setup' })}
          </Title>
        </Column>
        {
          selectedAppType !== APP_TYPES.WEEBLY && (
            <Column smSpan="6">
              <Field
                label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.domain' })}
                name={FIELDS.DOMAIN_ID}
                options={domains}
                optionValue="id"
                optionLabel="name"
                validate={[required]}
                onChange={(event, value) => {
                  const domain = domains.find((d) => d.id === value);
                  onSelectDomain(domain);
                  change(FIELDS.URL_PATH, formatPathInput());
                }}
                component={FormDropdown}
              />
            </Column>
          )
        }
        <Column smSpan="6">
          {
            selectedAppType === APP_TYPES.WEEBLY ? (
              <Field
                label={intl.formatMessage({ id: 'translate.page.app-manager.weebly-plan.select' })}
                name={FIELDS.WEEBLY_PLAN}
                options={generateWeeblyPlanOptions(activeWeebly && activeWeebly.plan)}
                optionValue="id"
                optionLabel="label"
                optionDisabled="disabled"
                validate={[required]}
                component={FormDropdown}
                template={({ id, label, current }) => (
                  <Flex align="center" margin="none" gutter="none" justify="space-between">
                    <span>
                      {intl.formatMessage({ id: label })}
                    </span>
                    {current && (
                      <Label type="info">
                        {intl.formatMessage({ id: 'translate.page.app-manager.weebly-label.current' })}
                      </Label>
                    )}
                  </Flex>
                )}
              />
            ) : (
              <Field
                label={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path' })}
                name={FIELDS.URL_PATH}
                suffix={
                  <Button
                    action="button"
                    color="dark"
                    size="small"
                    data-e2e="browse-directory-path"
                    onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY_INSTALL_PATH)}
                  >
                    {intl.formatMessage({ id: 'translate.generic.browse' })}
                  </Button>
                }
                normalize={formatPathInput}
                component={FormInput}
              />
            )
          }

          <SelectDirectory
            id={DIALOGS.SELECT_DIRECTORY_INSTALL_PATH}
            initialPath={initialPath}
            prefetch={prefetchPaths}
            expandedNodes={[publicHTML]}
            normalizeDirectoryData={normalizeDirectoryData}
            onChange={(path) => change(
              FIELDS.URL_PATH,
              handleDefaultPathSelection(path, `${initialPath}${publicHTML}`)
            )}
          />
        </Column>
      </React.Fragment>
    );
  };

  renderCustomFields = () => {
    const { validationUtils, intl, selectedAppType } = this.props;
    const { required, email } = validationUtils;

    switch (selectedAppType) {
      case APP_TYPES.WORDPRESS:
      case APP_TYPES.WOO_COMMERCE:
        return (
          <React.Fragment>
            <Column smSpan="6">
              {this.renderSelectLanguage()}
            </Column>
            <Column smSpan="6">
              {this.renderEnableWPStarter()}
            </Column>
          </React.Fragment>
        );
      case APP_TYPES.JOOMLA:
        return (
          <React.Fragment>
            <Column smSpan="6">
              {this.renderSelectLanguage(JOOMLA_LANGUAGES)}
            </Column>
            <Column smSpan="6">
              {this.renderSampleData()}
            </Column>
          </React.Fragment>
        );
      case APP_TYPES.MAGENTO:
        return (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-admin-path.label' })}
                name={FIELDS.ADMIN_PATH}
                component={FormInput}
              />
            </Column>
            <Column smSpan="6">
              {this.renderSampleData()}
            </Column>
          </React.Fragment>
        );
      case APP_TYPES.PRESTASHOP:
        return (
          <Column smSpan="6">
            <Field
              label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-admin-folter.label' })}
              name={FIELDS.ADMIN_FOLDER}
              component={FormInput}
            />
          </Column>
        );
      case APP_TYPES.OPEN_CART:
        return (
          <Column smSpan="6">
            <Field
              label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-data-dir.label' })}
              name={FIELDS.DATA_DIRECTORY}
              component={FormInput}
            />
          </Column>
        );
      case APP_TYPES.PHP_BB:
        return (
          <React.Fragment>
            <Column smSpan="6">
              {this.renderBoardEmail()}
            </Column>
          </React.Fragment>
        );
      case APP_TYPES.SMF:
        return (
          <React.Fragment>
            <Column smSpan="6">
              {this.renderBoardEmail()}
            </Column>
            <NotSupportedYet>
              <Column smSpan="6">
                <Field
                  label="Select visual verification"
                  name={FIELDS.VISUAL_VERIFICATION}
                  options={VISUAL_VERIFICATIONS}
                  optionValue="id"
                  optionLabel="label"
                  component={FormDropdown}
                />
              </Column>
            </NotSupportedYet>
          </React.Fragment>
        );
      case APP_TYPES.MEDIAWIKI:
        return (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-wiki-email.label' })}
                name={FIELDS.WIKI_EMAIL}
                component={FormInput}
                validate={[email]}
              />
            </Column>
            <NotSupportedYet>
              <Column smSpan="6">
                <Flex gutter="none" margin="none" align="flex-end">
                  <Field
                    name={FIELDS.ENABLE_UPLOADS}
                    component={FormCheckbox}
                  >
                    {intl.formatMessage({ id: 'translate.page.app-manager.create.input-enable-uploads.label' })}
                  </Field>
                </Flex>
              </Column>
            </NotSupportedYet>
          </React.Fragment>
        );
      case APP_TYPES.MOODLE:
        return (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-data-dir.label' })}
                name={FIELDS.DATA_DIRECTORY}
                component={FormInput}
              />
            </Column>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  renderSelectLanguage = (languages: any[] = WP_LANGUAGES) => {
    const { intl, validationUtils } = this.props;
    const { required } = validationUtils;

    return (
      <Field
        label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.language' })}
        name={FIELDS.LANGUAGE}
        options={languages}
        optionValue="id"
        optionLabel="label"
        component={FormDropdown}
      />
    );
  };

  renderEnableWPStarter = () => {
    const { intl } = this.props;

    return (
      <Flex gutter="none" align="flex-end">
        <Field
          name={FIELDS.INSTALL_WP_STARTER}
          decoration="dotted"
          component={FormCheckbox}
        >
          {intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter.text' })}
        </Field>
      </Flex>
    );
  };

  renderBoardEmail = () => {
    const { validationUtils, intl } = this.props;
    const { email } = validationUtils;

    return (
      <Field
        label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-board-contact-email.label' })}
        name={FIELDS.BOARD_EMAIL}
        component={FormInput}
        validate={[email]}
      />
    );
  };

  renderSampleData = () => {
    const { intl } = this.props;

    return (
      <Flex gutter="none" margin="none" align="flex-end" style={{ height: '100%' }}>
        <Field
          expand
          name={FIELDS.SAMPLE_DATA}
          component={FormCheckbox}
        >
          {intl.formatMessage({
            id: 'translate.page.app-manager.create.input-install-dummy-content.label'
          })}
        </Field>
      </Flex>
    );
  };

  renderAdminInfo = () => {
    const { validationUtils, intl, selectedAppType } = this.props;
    const { required, email, password, validationWithMetaApi } = validationUtils;

    if (selectedAppType === APP_TYPES.WEEBLY) {
      return null;
    }

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Title level="6" density="none" color="dark">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.admin.info' })}
          </Title>
        </Column>
        <Column smSpan="6">
          <Field
            label={intl.formatMessage({ id: 'translate.generic.username' })}
            name={FIELDS.ADMIN_USERNAME}
            disabled={selectedAppType === APP_TYPES.V_TIGER}
            component={FormInput}
            validate={[required, validationWithMetaApi]}
          />
        </Column>
        <Column smSpan="6">
          <Field
            style={{ display: 'none' }}
            name={FIELDS.ADMIN_PASSWORD}
            label="fake_password_to_prevent_autofill"
            data-e2e="fake-password"
            component={FormPassword}
          />
          <Field
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            name={FIELDS.ADMIN_PASSWORD}
            validate={[required, password]}
            component={FormPassword}
          />
        </Column>
        <Column smSpan="6">
          <Field
            label={intl.formatMessage({ id: 'translate.generic.email' })}
            name={FIELDS.ADMIN_EMAIL}
            component={FormInput}
            validate={[required, email, validationWithMetaApi]}
          />
        </Column>
      </React.Fragment>
    );
  };

  renderSubmitSection = () => {
    const {
      selectedAppType,
      form,
      intl,
      getFormValues,
      canOrderWeebly,
      currentSiteId,
      navigateToUA,
      activeWeebly
    } = this.props;

    const values = getFormValues(form);

    if (selectedAppType === APP_TYPES.WEEBLY) {
      const weeblyPlan = values[FIELDS.WEEBLY_PLAN];

      if (weeblyPlan !== WEEBLY_PLANS.FREE && !canOrderWeebly) {
        return (
          <Column smSpan="12">
            <Notice
              type="warning"
              background="light"
              shadow={false}
              title={intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.title' })}
            >
              {intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.text' })}
            </Notice>
          </Column>
        );
      }

      switch (values[FIELDS.WEEBLY_PLAN]) {
        case WEEBLY_PLANS.FREE:
          return (
            <Column smSpan="12">
              <Button color="primary" action="load-data" onClick={(e) => {
                e.preventDefault();
                this.props.activateFreeWeebly();
              }}>
                {intl.formatMessage({ id: 'translate.generic.install' })}
              </Button>
            </Column>
          );
        default:
          if (activeWeebly && activeWeebly.plan === WEEBLY_PLANS.BUSSINESS) {
            return (
              <Column smSpan="12">
                <Notice type="warning" background="light" shadow={false}>
                  <Text>
                    {intl.formatMessage({ id: 'translate.page.app-manager.weebly-warning-max-plan.warning' })}
                  </Text>
                </Notice>
              </Column>
            );
          }

          return (
            <React.Fragment>
              <Column smSpan="12">
                <Notice type="warning" background="light" shadow={false}>
                  <Text>
                    {intl.formatMessage({ id: 'translate.page.app-manager.weebly-navigate-to-ua.warning' })}
                  </Text>
                </Notice>
              </Column>
              <Column smSpan="12">
                <Button
                  color="primary"
                  data-e2e="buy-weebly-ua"
                  disabled={!canOrderWeebly}
                  action="navigate"
                  onClick={(e) => {
                    e.preventDefault();

                    navigateToUA({
                      page: 'sitebuilder',
                      siteId: currentSiteId,
                      planType: weeblyPlan
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'translate.page.app-installer.go-to-ua.button' })}
                </Button>
              </Column>
            </React.Fragment>
          );
      }
    }

    return (
      <Column smSpan="12">
        <SGSubmitButton formName={form} label={intl.formatMessage({ id: 'translate.generic.install' })} />
      </Column>
    );
  };

  render() {
    const { selectedAppType, isPhone } = this.props;

    const {
      validationUtils,
      sPanelFormSubmit,
      intl,
      form,
      getFormValues
    } = this.props;

    const values = getFormValues(form);

    return (
      <SGForm onSubmit={sPanelFormSubmit(form)} name={form}>
        {this.renderSelectApp()}
        {values[FIELDS.APP_TYPE] && (
          <React.Fragment>
            {!isPhone && this.renderInfoBox()}
            {this.renderApplicationSetup()}
            {this.renderCustomFields()}
            {this.renderAdminInfo()}
          </React.Fragment>
        )}
        {this.renderSubmitSection()}
      </SGForm>
    );
  }
}

const CreateApp = reduxForm({ form: REDUX_FORM.CREATE_APP_INSTALL })(Form);

export default connect<any, any, Props>(
  ({ environment, sites, pageItems, siteMetaApi }) => ({
    canOrderWeebly: isACLActionEnabled({ siteMetaApi }, getToolMainEndpoint('app-installer'), 'can_order'),
    currentSiteId: getCurrentSiteId({ sites }),
    isDesktop: environment.isDesktop,
    isPhone: environment.isPhone
  }),
  (dispatch) => ({
    openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
    navigateToUA: (redirectData) => dispatch(navigateToUA(redirectData))
  })
)(
  withSpanelForm(
    injectIntl(CreateApp)
  )
);
