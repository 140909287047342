import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link, List, ListItem, Title } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';
import { DNSTypes } from '../constants';

type Props = {
  apiResource: {
    resourceName: string
    resourceNameMetaApi: string
  };
  selectedDNSType: DNSTypes;
  children: any;
  intl?: Intl;
  renderBeforeBoxChildren: any;
};

export const DNS_FORM_BY_TYPE = {
  [DNSTypes.DNS_A]: REDUX_FORM.CREATE_DNS_A,
  [DNSTypes.DNS_AAAA]: REDUX_FORM.CREATE_DNS_AAAA,
  [DNSTypes.DNS_CNAME]: REDUX_FORM.CREATE_DNS_CNAME,
  [DNSTypes.DNS_MX]: REDUX_FORM.CREATE_DNS_MX,
  [DNSTypes.DNS_SRV]: REDUX_FORM.CREATE_DNS_SRV,
  [DNSTypes.DNS_TXT]: REDUX_FORM.CREATE_DNS_TXT
};

const CreateContainer = ({ apiResource, children, intl, selectedDNSType, renderBeforeBoxChildren }: Props) => {
  const formName = DNS_FORM_BY_TYPE[selectedDNSType];

  return (
    <VCS resourceName={apiResource.resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.dns.create.title' })}
        resourceName={apiResource.resourceName}
        formName={formName}
        renderInfo={({ titleProps, listProps, listItemProps }) => (
          <React.Fragment>
            <Title {...titleProps}>{intl.formatMessage({ id: 'translate.create.box.default.info.box.title' })}</Title>
            <List {...listProps} >
              <ListItem {...listItemProps}>
                <Link
                  href="https://www.siteground.com/tutorials/cpanel/parked-domains/#Add_a_Parked_Domain"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'translate.page.dns.create.link.add.dns.record' })}
                </Link>
              </ListItem>
              <ListItem {...listItemProps}>
                <Link
                  href="https://www.siteground.com/tutorials/cpanel/parked-domains/#Add_a_Parked_Domain"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'translate.page.dns.create.link.manage.dns.records' })}
                </Link>
              </ListItem>
              <ListItem {...listItemProps}>
                <Link
                  href="https://www.siteground.com/tutorials/cpanel/parked-domains/#Add_a_Parked_Domain"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'translate.page.dns.create.link.what.is.dns' })}
                </Link>
              </ListItem>
            </List>
          </React.Fragment>
        )}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        resources={[{
          resourceName: apiResource.resourceName,
          methods: ['POST']
        }]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer);
