import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as nemoStoreActions from '../actions/nemo-store';
import { clientApi } from '../api/client';
import { NAVIGATE_TO_UA, RETRIEVE_DATA_FROM_NEMO_REQUESTED } from '../constants/nemo-store';
import handleAvalonApiRequest from './handle-avalon-api-request';
import { handleNemoApiRequest } from './handle-nemo-api-request';
import { httpRequestStarted } from '../actions/fetch';

function* dataStore({ support, data }) {
  const { session } = yield select();

  return yield call(clientApi(
    `/data/store?_client_token=${session.clientToken}`,
    'POST',
    {
      ...data,
      command: 'redirect',
      session: {
        token: session.clientToken,
        refreshToken: session.clientRefreshToken,
        tokenTTL: session.clientTokenTtl
      },
      popup: support.popup
    }
  ));
}

function* dataRetrieve({ hash }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/data/retrieve?_client_token=${session.clientToken}`, 'POST', { hash })
  );
}

function* navigateToUA(action) {
  const state = yield select();
  const { data } = action;

  yield put(httpRequestStarted(action));

  const response = yield call(
    handleNemoApiRequest(dataStore), {
      support: state.support,
      data
    }
  );

  if (response) {
    window.location.href = `${CONFIG.UA_URL}/?hash=${response.data.hash}`;
  }
}

export function* retrieveDataFromNemo(action) {
  const state = yield select();
  const { hash } = action;

  const response = yield call(handleNemoApiRequest(dataRetrieve, action), { hash });

  if (response) {
    yield put(nemoStoreActions.retrieveDataFromNemoSucceeded(response));
  }
}

function* nemoStore(): any {
  yield takeLatest(NAVIGATE_TO_UA, navigateToUA);
  yield takeLatest(RETRIEVE_DATA_FROM_NEMO_REQUESTED, retrieveDataFromNemo);
}

export default nemoStore;
