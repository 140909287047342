import * as React from 'react';
import { change, getFormValues } from 'redux-form';
import { Grid, Label, LabelTypes, Section } from 'sg-styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { RootState } from '../../../core/reducers';
import { findMainDomain, filterStagingDomains } from '../../../core/selectors';
import DateWithTime from '../../components/date-with-time';
import DomainSelect from '../../components/domain-select/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import VCS from '../../containers/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import UpdateFieldsForm from './update/fields';

interface EmailAutoresponderProps {
  actions: CrudActions;
  items: any;
  intl: Intl;
  mainDomain: any;
  listedDomains: any[];
  createFormValues: any;
  changeUpdateDialogFieldValue: any;
  changeCreateFormFieldValue: any;
  openSGDialog: Function;
  closeSGDialog: Function;
}

export enum AutoresponderStatus {
  Inactive = 'inactive',
  Active = 'active',
  Scheduled = 'scheduled'
};

export const getAutoresponderStatus = (start: number, end: number): AutoresponderStatus => {
  const now = +new Date() / 1000;
  const unlimitedEndDate = Boolean(end === 0);

  switch (true) {
    case start < now && (now < end || unlimitedEndDate):
      return AutoresponderStatus.Active;
    case end < now:
      return AutoresponderStatus.Inactive;
    case now < start:
      return AutoresponderStatus.Scheduled;
    default:
  }
};

class EmailAutoresponderPage extends React.Component<EmailAutoresponderProps, any> {
  readonly state = {
    selectedDomain: null,
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  arrangeOptionsData(data) {
    return data.sort((a, b) => {
      return (a.id - b.id);
    });
  }

  pickerOptions() {
    const { listedDomains } = this.props;
    return this.arrangeOptionsData(listedDomains);
  }

  filterTabledData() {
    const { selectedDomain } = this.state;
    const domainId = selectedDomain && selectedDomain.id;
    const options = this.props.items.emailAutoresponder || [];

    return options.filter((option) => option.domain_id === domainId);
  }

  handleDomainChange = (id) => {
    const { createFormValues, changeCreateFormFieldValue, listedDomains } = this.props;
    const domain = listedDomains.find((item) => item.id === id);

    if (createFormValues.email_from && createFormValues.email_from.length > 0) {
      const emailName = createFormValues.email_from.split('@').shift();

      changeCreateFormFieldValue('email_from', `${emailName}@${domain.name}`);
    }

    this.setState({
      selectedDomain: {
        name: domain.name,
        id
      }
    });
  };

  onCreateFormSubmit = (formData) => {
    const { selectedDomain } = this.state;
    const entityName = `${formData.name}@${selectedDomain && selectedDomain.name}`;

    const modifiedData = {
      ...formData,
      domain_id: selectedDomain && selectedDomain.id,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_EMAIL_AUTORESPONDER,
          success: {
            intlKey: 'translate.page.emailAutoresponder.created_msg',
            intlValues: { name: entityName }
          },
          error: {
            intlKey: 'translate.page.emailAutoresponder.failed_create_msg',
            intlValues: { name: entityName }
          }
        }
      }
    };

    this.props.actions.createItem(modifiedData);
  };

  renderAutoResponderStatus = (entity, row) => {
    const { intl } = this.props;
    const status = getAutoresponderStatus(row.ts_start, row.ts_stop);

    type LabelTypesByStatus = {
      [key: string]: LabelTypes
    };

    const LabelTypesByStatus: LabelTypesByStatus = {
      [AutoresponderStatus.Inactive]: 'inactive-link',
      [AutoresponderStatus.Active]: 'active-link',
      [AutoresponderStatus.Scheduled]: 'default-link'
    };

    const LabelTextByStatus = {
      [AutoresponderStatus.Inactive]: intl.formatMessage({ id: 'translate.generic.inactive' }),
      [AutoresponderStatus.Active]: intl.formatMessage({ id: 'translate.generic.active' }),
      [AutoresponderStatus.Scheduled]: intl.formatMessage({ id: 'translate.generic.scheduled' })
    };

    return (
      <Label
        type={LabelTypesByStatus[status]}
        padding={['inherit', 'inherit', 'inherit', 'none']}
        size="medium"
      >
        {LabelTextByStatus[status]}
      </Label>
    );
  };

  render() {
    const { intl, actions } = this.props;
    const { selectedDomain } = this.state;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.email' }),
        accessor: 'name',
        render: (name, entity) => `${name}@${entity.domain_name}`
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.subject' }),
        accessor: 'email_subject'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.status' }),
        render: this.renderAutoResponderStatus,
        accessor: 'active'
      },
      {
        header: intl.formatMessage({ id: 'translate.page.emailAutoresponder.start.date' }),
        render: (entity: any) => {
          if (Number.isNaN(parseInt(entity, 10)) || entity === 0) {
            return '';
          }

          return <DateWithTime date={entity} />;
        },
        accessor: 'ts_start'
      },
      {
        header: intl.formatMessage({ id: 'translate.page.emailAutoresponder.end.date' }),
        render: (entity: any) => {
          if (Number.isNaN(parseInt(entity, 10)) || entity === 0) {
            return intl.formatMessage({ id: 'translate.page.emailAutoresponder.always_active' });
          }

          return <DateWithTime date={entity} />;
        },
        accessor: 'ts_stop'
      },
      { header: 'Actions', accessor: 'id', render: this.renderContextMenu, align: 'right' }
    ];

    return (
      <div>
        <PageHeader
          icon="presentational-email-autoresponder"
          title={intl.formatMessage({ id: 'translate.page.emailAutoresponder.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.emailAutoresponder.info' })}
        />

        <Section>
          <Grid>
            <DomainSelect
              options={this.pickerOptions()}
              selectedValue={selectedDomain && selectedDomain.id}
              optionValue="id"
              optionLabel="name"
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              onChange={this.handleDomainChange}
            />

            {selectedDomain && (
              <CreateBox>
                <CreateForm
                  selectedDomain={selectedDomain}
                  onSubmit={(data) => this.onCreateFormSubmit({
                    ...data,
                    domain_id: selectedDomain.id,
                    ts_start: data.ts_start || Math.floor(new Date().getTime() / 1000)
                  })}
                />
              </CreateBox>
            )}

            {this.renderUpdateComponent()}
            {this.renderDeleteConformationDialogComponent()}

            {selectedDomain && (
              <VCS resourceName={API_RESOURCE.EMAIL_AUTORESPONDER.resourceNameMetaApi} hasMethod="GET">
                <SGTable
                  title={intl.formatMessage({ id: 'translate.page.emailAutoresponder.list.title' })}
                  data={this.filterTabledData()}
                  noDataMessage="translate.page.emailAutoresponder.sg-table.no-data.message"
                  resources={[{ resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName, methods: ['GET'] }]}
                  columns={columns}
                />
              </VCS>
            )}
          </Grid>
        </Section>
      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.entityName;

    return (
      <DeleteDialog
        title={
          this.props.intl.formatMessage(
            { id: 'translate.page.emailAutoresponder.delete.dialog.title' },
            { entityName })
        }
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderUpdateComponent = () => {
    const { actions, closeSGDialog, intl } = this.props;
    const { currentUpdatePayload, selectedDomain } = this.state;
    const entityName = currentUpdatePayload && currentUpdatePayload.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.emailAutoresponder.update.title' }, { email: entityName })}
        size="x-large"
        resources={[{
          resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName,
          methods: ['PUT']
        }]}
      >
        <UpdateFieldsForm
          initialValues={currentUpdatePayload}
          selectedDomain={selectedDomain}
          onSubmit={(data) =>
            actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const { selectedDomain } = this.state;
    const entityName = `${entity.name}@${selectedDomain.name}`;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName,
      _metaFields: { ... API_RESOURCE.EMAIL_AUTORESPONDER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.emailAutoresponder.deleted_msg',
            intlValues: { email: entityName }
          },
          error: {
            intlKey: 'translate.page.emailAutoresponder.failed_delete_msg',
            intlValues: { email: entityName }
          }
        }
      }
    };

    const updatePayload = {
      entityName,
      _metaFields: {
        ...API_RESOURCE.EMAIL_AUTORESPONDER
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.emailAutoresponder.updated_msg',
            intlValues: { email: entityName }
          },
          error: {
            intlKey: 'translate.page.emailAutoresponder.failed_update_msg',
            intlValues: { email: entityName }
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL_AUTORESPONDER.resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          label: intl.formatMessage({ id: 'translate.generic.edit' }),
          e2eAttr: 'table-action-edit',
          icon: 'edit',
          visibleOnDesktop: true,
          onClick: () =>
            this.setState({ currentUpdatePayload: updatePayload },
              () => openSGDialog(REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG)
            )
        }, {
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          icon: 'trash',
          visibleOnDesktop: true,
          onClick: () =>
            this.setState({ currentDeleteConformationDialogPayload: deletePayload },
              () => openSGDialog(DIALOGS.GENERIC_DELETE)
            )
        }]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  mainDomain: findMainDomain(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  createFormValues: getFormValues(REDUX_FORM.CREATE_EMAIL_AUTORESPONDER)(state) || {} // TODO use withFormMetadata
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id)),
  changeCreateFormFieldValue: (field, value) => {
    dispatch(change(REDUX_FORM.CREATE_EMAIL_AUTORESPONDER, field, value));
  }
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  EmailAutoresponderPage,
  API_RESOURCE.EMAIL_AUTORESPONDER,
  API_RESOURCE.EMAIL,
  API_RESOURCE.DOMAIN_ALL
);
