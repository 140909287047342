import * as React from 'react';
import './setting-list.scss';

import { Flex, Text } from 'sg-styleguide';

const SettingsList = ({ children, title }) => {
  return (
    <div>
      {title && (
        <Flex className="setting-list-title" align="center" margin="none" background="light">
          <Text color="light" weight="bold">{title}</Text>
        </Flex>
      )}
      {children}
    </div>
  );
};

export default SettingsList;
