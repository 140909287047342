import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'sg-styleguide';
import SGTable from '../../components/sg-table';

type Props = {
  entityType: 'user' | 'database';
  entities: any[];
  getLabelByName: (name: string) => string;
  renderContextMenu: (entityData) => any;
  intl: Intl;
};

export const DbGrantsList = ({ entityType, entities, getLabelByName, renderContextMenu, intl }: Props) => {
  const isTypeDatabase = entityType === 'database';
  const tableProps = {
    shadow: false,
    data: entities,
    columns: [{
      header: intl.formatMessage({ id: isTypeDatabase ? 'translate.generic.database' : 'translate.generic.user' }),
      accessor: isTypeDatabase ? 'db_name' : 'dbuser_name'
    }, {
      header: intl.formatMessage({ id: 'translate.generic.label' }),
      accessor: isTypeDatabase ? 'db_name' : 'dbuser_name',
      render: (name = '', entity) => getLabelByName(name)
    }, {
      header: intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'id',
      render: (id, data) => renderContextMenu(data)
    }]
  };

  return (
    <SGTable {...tableProps} />
  );
};

export default injectIntl(DbGrantsList);
