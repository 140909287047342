import { RouteActions, routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import fileManager from '../../web/containers/file-manager/core/reducers';
import * as fromFileManager from '../../web/containers/file-manager/core/reducers/utils/permissions';
import countries, { Countries } from './countries';
import environment from './environment';
import dir from './fetch-dir-by-path';
import httpRequests, { HttpRequests } from './http-requests';
import i18n from './i18n';
import menuItems from './menu-items';
import siteMetaApi from './meta-api';
import nemoStore, { NemoStore } from './nemo-store';
import notifications, { NotificationsState } from './notifications';
import pageItems from './page-items';
import dashboard from './page/dashboard';
import sgSiteScanner, { SgSiteScanner } from './page/sg-sitescanner';
import session from './session';
import sgDialog from './sg-dialog';
import sgTable from './sg-table';
import sites from './sites';
import support from './support';
import tasks from './tasks';
import webstats, { Webstats } from './webstats';
import indexWithCrud, { IndexWithCrud } from './index-with-crud';

export interface RootState {
  environment: any;
  siteMetaApi: SiteMetaApi;
  httpRequest: any;
  routing: RouteActions;
  menuItems: any;
  session: any;
  sites: any;
  form: any;
  pageItems: any;
  notifications: NotificationsState;
  fileManager: any;
  i18n: {
    locale: string,
    messages: {}
  };
  tasks: Task[];
  tasksLoader: any;
  dashboard: any;
  webstats: Webstats;
  countries: Countries;
  dialog: any;
  table: any;
  nemoStore: NemoStore;
  httpRequests: HttpRequests;
  support: any;
  sgSiteScanner: SgSiteScanner;
  siteId: any;
  indexWithCrud: IndexWithCrud;
}

export default combineReducers<RootState>({
  environment,
  siteMetaApi,
  routing,
  menuItems,
  sites,
  session,
  pageItems,
  countries,
  dashboard,
  webstats,
  notifications,
  form,
  i18n,
  fileManager,
  tasks,
  dir,
  nemoStore,
  dialog: sgDialog,
  table: sgTable,
  httpRequests,
  support,
  sgSiteScanner,
  indexWithCrud
});

export const getFileMangerContextPermission = (state) => fromFileManager.getFileMangerContextPermission(state);
export const getFileMangerToolbarPermission = (state) => fromFileManager.getFileMangerToolbarPermission(state);
