import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Box, Flex, Text } from 'sg-styleguide';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormRadioButton from '../../../../components/form-radio-button';
import { withSpanelForm } from '../../../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: {
    _metaFields: {
      domainName: string;
    };
  };
};

const formName = REDUX_FORM.CLOUDFLARE_UPDATE_IP_RANGE_DIALOG;

const FieldsForm = ({ initialValues, intl }: Props) => (
  <Flex gutter="none" style={{ marginBottom: '0' }}>
    <Box sm="12" flex justify="flex-start" align="center">
      <Text color="light" size="small">
        {intl.formatMessage({ id: 'translate.page.cloudflare.select.status' })}
      </Text>
    </Box>

    <Box sm="12" flex justify="flex-start" align="center">
      <Field
        name="fw_mode"
        type="radio"
        value="whitelist"
        component={FormRadioButton}
      >
        {intl.formatMessage({ id: 'translate.page.cloudflare.status.whitelist' })}
      </Field>

      &nbsp;

      <Field
        name="fw_mode"
        type="radio"
        value="block"
        component={FormRadioButton}
      >
        {intl.formatMessage({ id: 'translate.page.cloudflare.status.block' })}
      </Field>
    </Box>
  </Flex>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(FieldsForm)
  )
);
