import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { Flex, Grid, Link, Section, Tab, Tabs, Text } from 'sg-styleguide';
import * as actions from '../../../core/actions/crud';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { HideOnMobile, ShowOnMobile } from '../../containers/device';
import { DeleteDialog } from '../../containers/dialogs';
import { SGDialogForm } from '../../containers/sg-dialog';
import { findMainDomain, filterStagingDomains } from '../../../core/selectors';
import VCS from '../../containers/visibility-control-service';
import { CreateDiscardBox, CreateDiscardForm } from './create/discard';
import { CreateForwardBox, CreateForwardForm } from './create/forward';
import { DiscardForm, ForwardForm } from './update';

const { resourceNameMetaApi } = API_RESOURCE.EMAIL_FORWARD;
export const DEFAULT_ENTITY_NAME = '*';

interface EmailForwardProps {
  actions: CrudActions;
  items: any;
  location: any;
  intl: Intl;
  mainDomain: any;
  listedDomains: any[];
  recipients: any;
  openSGDialog: Function;
  closeSGDialog: Function;
}

interface EmailForwardState {
  createState: string;
  discardDialogPayload: any;
  forwardDialogPayload: any;
  currentDeletePayload: any;
  selectedDomain: any;
}

class EmailForwardPage extends React.Component<EmailForwardProps, EmailForwardState> {
  readonly state = {
    createState: 'forward',
    discardDialogPayload: null,
    forwardDialogPayload: null,
    currentDeletePayload: null,
    selectedDomain: null
  };

  onDomainChanged = (domainId) => {
    const { listedDomains } = this.props;
    const domain = listedDomains.find(({ id }) => id === domainId);

    this.setState({
      selectedDomain: {
        name: domain.name,
        id: domain.id
      }
    });
  };

  onCreateFormSubmit = (formData) => {
    const { actions } = this.props;
    const { selectedDomain, createState } = this.state;

    const isForwardTabActive = createState === 'forward';
    const domainName = selectedDomain && selectedDomain.name;
    const domainId = selectedDomain && selectedDomain.id;

    const modifiedData = {
      ...formData,
      domain_id: domainId,
      _meta: {
        notification: {
          type: 'form',
          formName: isForwardTabActive ?
            REDUX_FORM.CREATE_EMAIL_FORWARD :
            REDUX_FORM.CREATE_EMAIL_DISCARD,
          success: {
            intlKey: isForwardTabActive ?
              'translate.page.emailForward.create.forward.success.message' :
              'translate.page.emailForward.create.discard.success.message',
            intlValues: { account: `${formData.name}@${domainName}` }
          },
          error: {
            intlKey: isForwardTabActive ?
              'translate.page.emailForward.create.forward.error.message' :
              'translate.page.emailForward.create.discard.error.message',
            intlValues: { account: `${formData.name}@${domainName}` }
          }
        }
      }
    };

    actions.createItem(modifiedData);
  };

  arrangeOptionsData(data) {
    return data.sort((a, b) => {
      return (a.id - b.id);
    });
  }

  pickerOptions() {
    const { listedDomains } = this.props;
    return this.arrangeOptionsData(listedDomains);
  }

  filterTabledData() {
    const { emailForward = [] } = this.props.items;
    const { selectedDomain } = this.state;

    const domainId = selectedDomain && selectedDomain.id;

    const optionsByDomain = emailForward.filter((option) => option.domain_id === domainId);
    const defaultIndex = optionsByDomain.findIndex((element) => element.name === DEFAULT_ENTITY_NAME);

    if (defaultIndex > 0) {
      const defaultElement = optionsByDomain[defaultIndex];
      optionsByDomain.splice(defaultIndex, 1);
      optionsByDomain.unshift(defaultElement);
    }

    return optionsByDomain;
  }

  renderTabs() {
    const { intl, openSGDialog } = this.props;

    return (
      <Tabs size="small" border="light">
        <Tab
          data-e2e="email-forward-tab-forward"
          active={this.state.createState === 'forward'}
          onClick={() => this.setState({ createState: 'forward' })}>
          {intl.formatMessage({ id: 'translate.page.emailForward.forward' })}
        </Tab>

        <Tab
          data-e2e="email-forward-tab-discard"
          active={this.state.createState === 'discard'}
          onClick={() => this.setState({ createState: 'discard' })}>
          {intl.formatMessage({ id: 'translate.page.emailForward.discard' })}
        </Tab>
      </Tabs>
    );
  }

  renderTableDestination = (name, entity) => {
    const { intl, openSGDialog } = this.props;

    if (entity.is_discard) {
      return (
        <FormattedMessage
          id="translate.page.emailForward.discard.destination.text"
          values={{
            message: entity.discard_msg === '' ?
              intl.formatMessage({ id: 'translate.page.emailForward.discard.destination.no.message' }) :
              entity.discard_msg
          }}
        />
      );
    }

    const destinationMails = new Set(entity.destination);

    return (
      <FormattedMessage
        id="translate.page.emailForward.forward.destination.text"
        values={{
          destination: (
            <Link
              onClick={() => {
                this.setState({
                  forwardDialogPayload: {
                    _metaFields: { ...API_RESOURCE.EMAIL_FORWARD },
                    ...entity
                  }
                }, () => openSGDialog(REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG));
              }}
            >
              {
                destinationMails.size === 1 ?
                  entity.destination[0] :
                  intl.formatMessage(
                    { id: 'translate.page.emailForward.forward.destination.multiple.text' },
                    { count: destinationMails.size }
                  )
              }
            </Link>
          )
        }}
      />
    );
  };

  render() {
    const { intl, openSGDialog } = this.props;
    const { selectedDomain, createState } = this.state;

    const domainName = selectedDomain && selectedDomain.name;
    const domainId = selectedDomain && selectedDomain.id;

    const isForwardTabActive = createState === 'forward';
    const isDiscardTabActive = createState === 'discard';
    const pickerOptions = this.pickerOptions();

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.page.emailForward.name' }),
        accessor: 'name',
        render: (name, e) => {

          return (
            <Flex align="center" gutter="none" margin="none" wrap="nowrap">
              <ShowOnMobile>
                <Text size="x-large" weight="bold" truncate>
                  {`${name}@${e.domain_name}`}
                </Text>
              </ShowOnMobile>

              <HideOnMobile>
                <Text truncate>
                  {`${name}@${e.domain_name}`}
                </Text>
              </HideOnMobile>

              &nbsp;

              {e.name === DEFAULT_ENTITY_NAME && (
                <Text color="secondary" weight="bold" transform="uppercase" style={{ flexShrink: 0 }}>
                  {intl.formatMessage({ id: 'translate.generic.default' })}
                </Text>
              )}
            </Flex>
          );
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.page.emailForward.destination.table.label' }),
        accessor: 'destination',
        render: this.renderTableDestination
      },
      { header: 'Actions', accessor: 'id', render: this.renderContextMenu }
    ];

    return (
      <div>
        <PageHeader
          icon="presentational-email-forwarder"
          title={intl.formatMessage({ id: 'translate.page.emailForward.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.emailForward.info' })}
        />

        <Section>
          <Grid>
            <DomainSelect
              options={pickerOptions}
              onChange={this.onDomainChanged}
              selectedValue={domainId}
              searchValue={domainName}
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              placeholder={intl.formatMessage({ id: 'translate.generic.pick.domain' })}
              searchByKey="name"
              optionValue="id"
              optionLabel="name"
            />

            {isForwardTabActive && (
              <CreateForwardBox
                domainName={domainName}
                renderBeforeBoxChildren={this.renderTabs()}
              >
                <CreateForwardForm
                  domainName={domainName}
                  onSubmit={this.onCreateFormSubmit}
                />
              </CreateForwardBox>
            )}

            {isDiscardTabActive && (
              <CreateDiscardBox
                domainName={domainName}
                renderBeforeBoxChildren={this.renderTabs()}
              >
                <CreateDiscardForm
                  domainName={domainName}
                  onSubmit={this.onCreateFormSubmit}
                />
              </CreateDiscardBox>
            )}

            <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.emailForward.list.title' })}
                data={this.filterTabledData()}
                columns={columns}
                resources={[{
                  resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
                  methods: ['GET']
                }, {
                  resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
                  methods: ['GET']
                }, {
                  resourceName: API_RESOURCE.DOMAIN.resourceName,
                  methods: ['GET']
                }]}
                noDataMessage="translate.page.emailForward.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderForwardDialog()}
        {this.renderDiscardDialog()}
        {this.renderDeleteConformationDialogComponent()}

      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const { currentDeletePayload } = this.state;

    const name = currentDeletePayload && currentDeletePayload.entityName;

    const title = currentDeletePayload && currentDeletePayload.entity.is_discard ?
      intl.formatMessage({ id: 'translate.page.emailForward.discard.delete.title' }, { name }) :
      intl.formatMessage({ id: 'translate.page.emailForward.forward.delete.title' }, { name });

    return (
      <DeleteDialog
        title={title}
        onSubmit={() => this.props.actions.deleteItem(currentDeletePayload)}
      />
    );
  };

  renderDiscardDialog = () => {
    const { actions, closeSGDialog, intl } = this.props;
    const { discardDialogPayload } = this.state;
    const name = discardDialogPayload && discardDialogPayload.name;
    const domainName = discardDialogPayload && discardDialogPayload.domain_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG}
        icon="email-discard"
        title={
          intl.formatMessage(
            { id: 'translate.page.emailForward.update.discard.title' },
            { name: `${name}@${domainName}` }
          )
        }
        resources={[{
          resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
          methods: ['PUT']
        }]}
      >
        <DiscardForm
          initialValues={discardDialogPayload}
          onSubmit={(data) => actions.updateItem(
            data,
            () => closeSGDialog(REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG)
          )}
        />
      </SGDialogForm>
    );
  };

  renderForwardDialog = () => {
    const { actions, closeSGDialog, intl } = this.props;
    const { forwardDialogPayload } = this.state;
    const name = forwardDialogPayload && forwardDialogPayload.name;
    const domainName = forwardDialogPayload && forwardDialogPayload.domain_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG}
        icon="email-forward"
        title={
          intl.formatMessage(
            { id: 'translate.page.emailForward.update.forward.title' },
            { name: `${name}@${domainName}` }
          )
        }
        resources={[{
          resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
          methods: ['PUT']
        }]}
      >
        <ForwardForm
          initialValues={forwardDialogPayload}
          onSubmit={(data) => {
            actions.updateItem(data, () => closeSGDialog(REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG));
          }}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const entityName = `${entity.name}@${entity.domain_name}`;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName,
      _metaFields: { ...API_RESOURCE.EMAIL_FORWARD },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: entity.is_discard ?
              'translate.page.emailForward.discard.delete.success.message' :
              'translate.page.emailForward.forward.delete.success.message',
            intlValues: { name: entityName }
          },
          error: {
            intlKey: entity.is_discard ?
              'translate.page.emailForward.discard.delete.error.message' :
              'translate.page.emailForward.forward.delete.error.message',
            intlValues: { name: entityName }
          }
        }
      },
      entity
    };

    const updateNotificationMessage = {
      notification: {
        type: 'generic',
        success: {
          intlKey: entity.is_discard ?
            'translate.page.emailForward.discard.update.success.message' :
            'translate.page.emailForward.forward.update.success.message',
          intlValues: { name: entityName }
        },
        error: {
          intlKey: entity.is_discard ?
            'translate.page.emailForward.discard.update.error.message' :
            'translate.page.emailForward.forward.update.error.message',
          intlValues: { name: entityName }
        }
      }
    };
    const updatePayload = {
      _metaFields: { ...API_RESOURCE.EMAIL_FORWARD },
      _meta: {
        ...updateNotificationMessage
      },
      ...entity
    };

    if (entity.name === DEFAULT_ENTITY_NAME) {
      /* Forse payload to be discard/forward */
      const { is_discard, ...newEntity } = entity;
      const meta = {
        _metaFields: { ...API_RESOURCE.EMAIL_FORWARD },
        _meta: {
          ...updateNotificationMessage
        }
      };

      const forwardUpdatePayload = {
        is_discard: 0,
        ...meta,
        ...newEntity
      };

      const discardUpdatePayload = {
        is_discard: 1,
        ...meta,
        ...newEntity
      };

      return (
        <TableContextMenu
          entity={entity}
          resourceName={resourceNameMetaApi}
          items={[{
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.emailForward.forward' }),
            icon: 'email-forward',
            onClick: () => this.setState(
              { forwardDialogPayload: forwardUpdatePayload },
              () => openSGDialog(REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG)
            )
          }, {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.emailForward.discard' }),
            icon: 'email-discard',
            onClick: () => this.setState(
              { discardDialogPayload: discardUpdatePayload },
              () => openSGDialog(REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG)
            )
          }]}
        />
      );
    }

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[{
          vcsMethod: 'PUT',
          label: intl.formatMessage({ id: 'translate.generic.edit' }),
          e2eAttr: 'table-action-edit',
          icon: 'edit',
          visibleOnDesktop: true,
          onClick: () => {
            entity.is_discard ?
              this.setState(
                { discardDialogPayload: updatePayload },
                () => openSGDialog(REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG)
              ) :
              this.setState(
                { forwardDialogPayload: updatePayload },
                () => openSGDialog(REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG)
              );
          }
        }, {
          vcsMethod: 'DELETE',
          label: intl.formatMessage({ id: 'translate.generic.delete' }),
          e2eAttr: 'table-action-delete',
          icon: 'trash',
          visibleOnDesktop: true,
          onClick: () => this.setState(
            { currentDeletePayload: deletePayload },
            () => openSGDialog(DIALOGS.GENERIC_DELETE)
          )
        }]}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  mainDomain: findMainDomain(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN_ALL.resourceName)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  EmailForwardPage,
  API_RESOURCE.EMAIL_FORWARD,
  API_RESOURCE.DOMAIN_ALL
);
