import * as React from 'react';
import { Button, Container, copyToClipboard, Grid, Link, Section, Tab, Tabs } from 'sg-styleguide';
import { API_RESOURCE } from '../../../core/constants/api';
import { RootState } from '../../../core/reducers';
import { filterStagingDomains } from '../../../core/selectors';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SecondLevelTitle from '../../containers/titles/second-level-title';
import VCS from '../../containers/visibility-control-service';
import DKIM from './dkim';
import SPF from './spf';

type Props = {
  intl: Intl;
  listedDomains: any[];
};

type State = {
  activeTab: string;
  selectedDomainId: number;
};

const TAB = {
  SPF: 'SPF',
  DKIM: 'DKIM'
};

class EmailAuthentication extends React.Component<Props, State> {
  readonly state = {
    activeTab: TAB.SPF,
    selectedDomainId: null
  };

  getSelectedDomainInfo() {
    const { listedDomains } = this.props;
    const { selectedDomainId } = this.state;
    const selectedDomain = listedDomains.find((d) => d.id === selectedDomainId);

    return {
      ...selectedDomain,
      link: selectedDomain && (
        selectedDomain.cert_id ? `https://${selectedDomain.name}` : `http://${selectedDomain.name}`
      )
    };
  }

  renderTabs() {
    const { activeTab } = this.state;

    return (
      <Tabs size="small" border="light">
        <Tab
          active={activeTab === TAB.SPF}
          data-e2e="settings"
          onClick={() => this.setState({ activeTab: TAB.SPF })}
        >
          SPF
        </Tab>

        <Tab
          active={activeTab === TAB.DKIM}
          data-e2e="ip-access-control"
          onClick={() => this.setState({ activeTab: TAB.DKIM })}
        >
          DKIM
        </Tab>
      </Tabs>
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;

    switch (activeTab) {
      case  TAB.SPF:
        return <SPF selectedDomain={this.getSelectedDomainInfo()} />;
      case  TAB.DKIM:
        return <DKIM selectedDomain={this.getSelectedDomainInfo()}/>;
      default:
        return null;
    }
  }

  render() {
    const { listedDomains, intl } = this.props;

    return (
      <div>
        <PageHeader
          icon="presentational-email-authentication"
          title={intl.formatMessage({ id: 'translate.page.email.auth.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.email.auth.info' })}
        />

        <Section>
          <Grid>
            <DomainSelect
              selectedValue={this.state.selectedDomainId}
              options={listedDomains}
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              optionValue="id"
              optionLabel="name"
              onChange={(id) => this.setState({ selectedDomainId: id })}
            />

            <VCS resourceName={API_RESOURCE.SPF.resourceName} hasMethod="GET">
              <div>
                <SecondLevelTitle>
                  {intl.formatMessage({ id: 'translate.page.email.auth.list.title' })}
                </SecondLevelTitle>

                <Container padding="none">
                  {this.renderTabs()}
                  {this.renderTabContent()}
                </Container>
              </div>
            </VCS>
          </Grid>
        </Section>
      </div>
    );
  };
}

const mapStateToProps = ({ pageItems }: RootState) => ({
  listedDomains: filterStagingDomains({ pageItems }, API_RESOURCE.DOMAIN_ALL.resourceName)
});

export default indexWithCRUD(mapStateToProps)(
  EmailAuthentication,
  API_RESOURCE.DOMAIN_ALL
);
