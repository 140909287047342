import * as React from 'react';
import { List, ListItem, Notice } from 'sg-styleguide';

type Props = {
  errors?: any[];
  title?: string;
};

const ErrorNotice = ({ errors, title }: Props) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <Notice
      type="error"
      title={title}
      background="light"
      shadow={false}
    >
      <List>
        {errors.map((error) => (
          <ListItem icon="dot" key={error.field}>
            {error.label}
          </ListItem>
        ))}
      </List>
    </Notice>
  );
};

export default ErrorNotice;
