import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: any = [];

const reducerMap: any = {
  [Actions.ADD_TASK]: (state, action) => [...state, action.payload],
  [Actions.PENDING_TASK_COMPLETED]: (state, action: any) => {
    return [
      ...state.filter((task) => task.id !== action.task.id),
      action.task
    ];
  },
  [Actions.PENDING_TASK_MISSING]: (state, action: any) => {
    return [...state.filter((task) => task.id !== action.taskId)];
  },
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
