import 'whatwg-fetch';
import * as sessionActions from '../actions/session';
import store from '../store';
import { jsonResponse, formatRequestBody } from './utils';
import { CustomError } from './site';

type clientApi = () => Promise<Response>;

export function clientApi(
  url: string,
  method: string = 'GET',
  body = {}
  ): clientApi {
  const apiHost = CONFIG.API.HOST;
  const newUrl = apiHost + url;

  return () => {
    let args: any = {
      headers: {
        Accept: 'application/json'
      },
      method
    };

    if (method === 'POST' || method === 'PUT') {
      args = {
        ...args,
        body: formatRequestBody(body)
      };
    }

    return fetch(newUrl, args)
      .catch((error) => {
        throw Error('An error occurred. Please try again later');
      })
      .then((response) => {

        if (!response.ok && response.status !== 422) {
          const error = new CustomError(response);
          error.message = response.statusText;
          throw error;
        }

        return jsonResponse(response);
      })
      .then((json) => json);
  };
}
