import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormInput from '../../../../components/form-input';
import { withSpanelForm } from '../../../sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
};

const RenameEntityForm = ({ intl, validationUtils }: Props) => (
  <Field
    name="id"
    label={intl.formatMessage({ id: 'translate.generic.name' })}
    autoFocus
    validate={[validationUtils.required]}
    component={FormInput}
  />
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: REDUX_FORM.FILE_MANAGER_RENAME_ENTITY })(RenameEntityForm)
  )
);
