import * as React from 'react';
import Slider from 'sg-styleguide/lib/components/slider';

const FormSlider = ({ input, meta, ...props }: any) => {
  return (
    <Slider data-e2e={`form-slider-${input.name}`} {...input} {...meta} {...props} />
  );
};

export default FormSlider;
