import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'sg-styleguide';
import { DIALOGS, REDUX_FORM } from '../../../../../core/constants/common';
import FormInput from '../../../../components/form-input';
import SelectDirectory from '../../../dialogs/select-directory';
import { withSpanelForm } from '../../../sg-form-util/with-spanel-form';

type Props = {
  change: Function;
  intl: Intl;
  validationUtils: ValidationUtils;
  openSGDialog: Function;
};

const MoveEntityForm = ({ intl, validationUtils, change, openSGDialog }: Props) => (
  <React.Fragment>
    <Field
      name="dest"
      label={intl.formatMessage({ id: 'translate.file.manager.folder' })}
      autoFocus
      suffix={
        <Button
          action="button"
          color="dark"
          size="small"
          onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
          data-e2e="footer-button-browse"
        >
          {intl.formatMessage({ id: 'translate.generic.browse' })}
        </Button>
      }
      component={FormInput}
    />

    <SelectDirectory
      initialPath={'/'}
      onChange={(path) => change('dest', path.replace('//', '/'))}
    />
  </React.Fragment>
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: REDUX_FORM.FILE_MANAGER_MOVE_ENTITY })(MoveEntityForm)
  )
);
