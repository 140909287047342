import { STATUS } from '../constants/http-requests';
import { RootState } from '../reducers';

export const matchingRequestDataField = (
  nextRequestState: HTTPRequestState,
  existingRequestState: HTTPRequestState,
  fieldkey: string
) => Boolean(
  nextRequestState[fieldkey] &&
  nextRequestState[fieldkey] === existingRequestState[fieldkey]
);

export const isRequestDataMatching = (
  nextRequestState: HTTPRequestState,
  existingRequestState: HTTPRequestState,
  exactMatch = false // flag for explicit match by all crud params (id, method, resourceName)
): boolean => {
  const matchingResourceName = matchingRequestDataField(nextRequestState, existingRequestState, 'resourceName');
  const matchingMethod = matchingRequestDataField(nextRequestState, existingRequestState, 'method');
  const matchingId = matchingRequestDataField(nextRequestState, existingRequestState, 'id');

  const matchById = (exactMatch && nextRequestState.id && existingRequestState.id);

  return Boolean(
    // matching by requestTypeName
    matchingRequestDataField(nextRequestState, existingRequestState, 'requestTypeName') ||
    // case with PUT, GET requests, which could have optional id
    Boolean(!matchById && nextRequestState.id === undefined && (matchingMethod && matchingResourceName)) ||
    // matching all params fetchItem, deleteItem, updateItem(id)
    Boolean(matchingId && matchingMethod && matchingResourceName)
  );
};

export const findMatchingRequests = (requests: HTTPRequestState[], resourceData: HTTPRequestState) => {
  return requests.filter(
    (requestState: HTTPRequestState) =>
      isRequestDataMatching(resourceData, requestState)
  );
};

export const getBlockingRequests = (
  { httpRequests }: Partial<RootState>,
  resources: LoaderResource[] = []
) => {
  let blockingRequests: HTTPRequestState[] = [];

  resources.forEach((resource) => {
    const { methods = [], resourceName, requestTypeName, id } = resource;
    const resourceData = { resourceName, requestTypeName, id };

    if (requestTypeName) {
      blockingRequests = blockingRequests.concat(
        findMatchingRequests(httpRequests.specified, resourceData)
      );
    } else {
      methods.forEach((method) => {
        blockingRequests = blockingRequests.concat(
          findMatchingRequests(
            httpRequests.specified, {
              ...resourceData,
              method
            }
          )
        );
      });
    }
  });

  const requested = blockingRequests.filter((request) => request.status === STATUS.REQUESTED);
  const failed = blockingRequests.filter((request) => request.status === STATUS.FAILED);

  return {
    requested,
    failed
  };
};
