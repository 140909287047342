import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Accordion, Button, Container, Flex, Grid, IconButton, Placeholder, Spacer, Toolbar } from 'sg-styleguide';
import { setDashboardTiles } from '../../../../core/actions/pages/dashboard';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { RootState } from '../../../../core/reducers/index';
import * as menuItems from '../../../../static-api/menu-items';
import PartialLoader from '../../../containers/partial-loader';
import SecondLevelTitle from '../../../containers/titles/second-level-title';
import DashboardTile from '../dashboard-tile';

type Props = {
  intl: Intl;
  dashboardPreferences: { spanel_dashboard_tiles: string[] };
  router: any;
  locationSearch: string;
  setDashboardTiles: Function;
};

type State = {
  isInEditView: boolean;
  pinnedItems: string[];
  itemsBeforeEdit: string[];
};

class TileSection extends React.Component<Props, State> {
  readonly state = {
    isInEditView: false,
    itemsBeforeEdit: [],
    pinnedItems: this.props.dashboardPreferences.spanel_dashboard_tiles
  };

  componentDidUpdate(prevProps, prevState) {
    const { dashboardPreferences } = this.props;
    const dashTiles = dashboardPreferences.spanel_dashboard_tiles;
    const prevDashTiles = prevProps.dashboardPreferences.spanel_dashboard_tiles;

    if (dashTiles !== prevDashTiles) {
      this.setState({ pinnedItems: dashTiles });
    }
  }

  getPinnedTiles() {
    const { pinnedItems } = this.state;

    return pinnedItems;
  }

  openEditView = () => {
    this.setState({ isInEditView: true, itemsBeforeEdit: this.state.pinnedItems });
  };

  closeEditView = () => {
    this.setState({ isInEditView: false });
  };

  pinTileToFavorites = (stateId) => {
    this.setState({
      pinnedItems: this.state.pinnedItems.concat(stateId)
    });
  };

  unPinTileToFavorites = (stateId) => {
    this.setState({
      pinnedItems: this.getPinnedTiles().filter((state) => state !== stateId)
    });
  };

  savePinnedTilesToFavorites = () => {
    const { pinnedItems } = this.state;

    this.props.setDashboardTiles({
      preferences: { spanel_dashboard_tiles: pinnedItems }
    }, this.closeEditView);
  };

  dismissPinnedTileToFavorites = () => {
    this.setState({ pinnedItems: this.state.itemsBeforeEdit, isInEditView: false });
  };

  navigateToPage = (stateId) => {
    const { router, locationSearch } = this.props;

    router.push(`/${stateId}${locationSearch}`);
  };

  isTilePinned = (stateId) => {
    return this.getPinnedTiles().includes(stateId);
  };

  renderNoPinnedTilesPlaceholder() {
    const { intl } = this.props;

    return (
      <Placeholder
        shadow
        title={intl.formatMessage({ id: 'translate.page.dashboard.tiles.no.pinned.tiles.message' })}
      >
        <Button color="primary" onClick={() => this.openEditView()}>
          {intl.formatMessage({ id: 'translate.page.dashboard.tiles.no.pinned.tiles.button.label' })}
        </Button>
      </Placeholder>
    );
  }

  renderTile = (item) => {
    const { isInEditView } = this.state;

    if (!item) {
      return null;
    }

    const { stateId } = item;
    const isTilePinned = this.isTilePinned(stateId);

    return (
      <DashboardTile
        key={stateId}
        item={item}
        isInEditView={isInEditView}
        isTilePinned={isTilePinned}
        handlePinClick={() => isTilePinned ? this.unPinTileToFavorites(stateId) : this.pinTileToFavorites(stateId)}
        onClick={() => !isInEditView && this.navigateToPage(stateId)}
      />
    );
  };

  renderPinnedTiles = () => {
    const { isInEditView } = this.state;
    const shouldRenderEmptyPlaceholder = !isInEditView && this.getPinnedTiles().length === 0;

    if (shouldRenderEmptyPlaceholder) {
      return this.renderNoPinnedTilesPlaceholder();

    }

    const items = menuItems.groups
      .map((group) => group.items)
      .reduce((a: any, b: any) => a.concat(b), []);

    const tiles = this.state.pinnedItems.map((pin) => {
      const itemToRender = items.find((item) => item.stateId === pin);
      return this.renderTile(itemToRender);
    });

    return (
      <Grid sm="4" gap="large">
        {tiles}
      </Grid>
    );
  };

  renderNonPinnedTiles = () => {
    const { intl } = this.props;

    return (
      <div>
        <SecondLevelTitle>
          {intl.formatMessage({ id: 'translate.page.dashboard.tiles.non-selected-tiles-title' })}
        </SecondLevelTitle>

        {menuItems.groups.map((group: any) => {
          if (!group.items || group.items.length === 0) {
            return null;
          }

          return (
            <Accordion title={group.title} padding="none" initialState="expanded">
              <Grid sm="4" gap="large">
                {
                  group.items
                    .filter((item) => !this.isTilePinned(item.stateId))
                    .map(this.renderTile)
                }
              </Grid>
            </Accordion>
          );
        })}
      </div>
    );
  };

  renderEditViewButtons() {
    const { intl } = this.props;

    return (
      <div>
        <Button onClick={() => this.dismissPinnedTileToFavorites()}>
          {intl.formatMessage({ id: 'translate.generic.dismiss' })}
        </Button>

        <Button color="primary" onClick={() => this.savePinnedTilesToFavorites()}>
          {intl.formatMessage({ id: 'translate.generic.save' })}
        </Button>
      </div>
    );
  }

  renderTitle() {
    const { intl } = this.props;
    const { isInEditView } = this.state;

    return (
      <Flex justify="space-between" align="center" margin="none" gutter="none">
        <SecondLevelTitle>
          {intl.formatMessage({
            id:
              isInEditView ? 'translate.page.dashboard.tiles.title.edit' : 'translate.page.dashboard.tiles.title'
          })}
        </SecondLevelTitle>

        {isInEditView ? this.renderEditViewButtons() : (
          <IconButton icon="edit" shape="circle" onClick={() => this.openEditView()} />
        )}
      </Flex>
    );
  }

  renderAllTiles() {
    return (
      <PartialLoader
        position="absolute"
        resources={[{
          requestTypeName: customRequestTypes.GET_DASHBOARD_PIN_TILES
        }, {
          requestTypeName: customRequestTypes.SET_DASHBOARD_PIN_TILES
        }]}
      >
        {this.renderPinnedTiles()}

        {this.state.isInEditView && this.renderNonPinnedTiles()}
      </PartialLoader>
    );
  }

  renderNormalView() {
    return (
      <div>
        {this.renderTitle()}

        <div style={{ position: 'relative' }}>
          {this.renderAllTiles()}
        </div>
      </div>
    );
  }

  renderEditView() {
    return (
      <div>
        {this.renderTitle()}

        <Container padding="large" elevation="none">
          <Grid style={{ position: 'relative' }}>
            {this.renderAllTiles()}
          </Grid>
        </Container>

        <Container padding="none" elevation="none">
          <Toolbar style={{ marginTop: '0' }}>
            <Spacer />

            {this.renderEditViewButtons()}
          </Toolbar>
        </Container>
      </div>
    );
  }

  render() {
    return this.state.isInEditView ? this.renderEditView() : this.renderNormalView();
  }
}

const mapStateToProps = ({ dashboard, routing }: RootState) => ({
  dashboardPreferences: dashboard.preferences,
  locationSearch: routing.locationBeforeTransitions.search
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardTiles: (payload, onComplete) => dispatch(setDashboardTiles(payload, onComplete))
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(TileSection));
