import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { RootState } from '../../../core/reducers';
import * as menuItems from '../../../static-api/menu-items';
import { Box, DialogSearch, Flex, Icon, Text, Title } from 'sg-styleguide';

import './tool-finder.scss';

interface ToolFinderProps {
  handleClose: any;
  router: any;
  state?: any;
  intl: Intl;
}

interface ToolFinderState {
  searchValue: string;
  searchList: any[];
};

class ToolFinder extends React.Component<ToolFinderProps, ToolFinderState> {
  readonly state = {
    searchValue: '',
    searchList: []
  };

  constructor(props) {
    super(props);

    this.closeHandler = this.closeHandler.bind(this);
    this.template = this.template.bind(this);
  }

  componentWillMount() {
    const fullList = menuItems.groups.reduce((a, b) => a.concat(b.items), []);

    this.state.searchList = this.filterList(fullList, '');
  }

  filterList(list, searchValue) {
    // dont want to calculate factorial on runtime - performance, performance, performance
    return list.filter((item) => {
      const stripRegEx = /[^A-Z\s0-9]/ig;
      const stripBackslashRegEx = /\\/g;

      if (item.pageEndpoint === undefined) {
        console.warn('Please define pageEndpoint', item);
        return false;
      }

      if (item.title === undefined) {
        console.error('Missing title', item, item.title);
        return;
      }

      const strippedItem = (item.title).replace(stripRegEx, '').toLowerCase();
      const searchValueStripped = searchValue.replace(stripBackslashRegEx, '/').replace(stripRegEx, '').toLowerCase();
      item.ranking = 0;
      item.tagsFound = {};
      // Exact title match
      if (strippedItem === searchValueStripped) {
        item.ranking = item.ranking < 3 ? 3 : item.ranking;
        // Partial title match
      } else if (strippedItem.search(searchValueStripped) !== -1) {
        item.ranking = item.ranking < 2 ? 2 : item.ranking;
      } else if (item.keywords) {
        let i = item.keywords.length;
        for (const word of item.keywords) {
          /**
           * Keywords Scores Formula
           * 1. encrease with keywordRanking (position in the array)
           * 2. decrease with keywordsArrayLength
           */
          const keyword = word.toLocaleLowerCase();
          const keywordRanking = (i / item.keywords.length);
          const keywordScoresToAdd = keywordRanking / item.keywords.length;

          // keyword match both "search value in keyword" and "keyword in search value"
          if (searchValueStripped.search(keyword) !== -1 || keyword.search(searchValueStripped) !== -1) {
            item.tagsFound[keyword] = '(' + i + '/' + item.keywords.length + ')' + '/' + item.keywords.length;
            item.ranking = item.ranking < 1 ? item.ranking + keywordScoresToAdd : item.ranking;
          }
          i = i - 1;
        }
      }
      return item.ranking;
    }).sort((toolA, toolB) => toolB.ranking - toolA.ranking);
  }

  handleNavigateAction(stateId) {
    this.props.router.push(`/${stateId}${location.search}`);
    this.closeHandler();
  }

  closeHandler() {
    this.props.handleClose();
  }

  template = (row) => {
    const { intl } = this.props;
    return (
      <div
        className="tool-finder__searched-item"
        onClick={() => {
          this.handleNavigateAction(row.stateId);
        }}>
        <Flex direction="row" margin="none" align="center">
          <Title level="4">{intl.formatMessage({ id: row.pageTitle })}</Title>
          <Icon color="rgba(54,54,54,.45)" name="dot" size="10" />
          <Text size="small" color="light">{row.parent}</Text>
        </Flex>
      </div>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <DialogSearch
        placeholder={intl.formatMessage({ id: 'translate.tool.finder.input.placeholder' })}
        data={this.state.searchList}
        onCloseHandler={this.closeHandler}
        handleEnter={(row) => this.handleNavigateAction(row.stateId)}
        customFilter={this.filterList}
        indexKey="title"
        template={this.template}
        noResultsText={intl.formatMessage({ id: 'translate.tool.finder.no-results.text' })}
      />
    );
  }
}

export default injectIntl(ToolFinder);
