import { delay } from 'redux-saga';
import { call, cancel, fork, put, select } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import { siteRequestAuthorizationFailed } from '../actions/session';
import { fetchPendingTasksRequested, requestStartedBackgroundTask, subscribeForTaskCompletion } from '../actions/tasks';
import { getTasksIds } from '../selectors/pending-tasks';
import store from '../store';

const DEFAULT_TIMEOUT = 99999999;

function handleAvalonApiRequest(saga) {
  return function* callSaga(action) {
    const apiCallHandler = yield fork(function* () {
      try {
        yield put(fetchActions.httpRequestStarted(action));
        const response = yield call(saga, action);

        // fetch pendingTasks from anyApi
        const allPendingTasksIds = getTasksIds(response);

        if (allPendingTasksIds.length > 0) {
          yield put(fetchPendingTasksRequested(allPendingTasksIds));
        }

        yield put(fetchActions.httpRequestSucceeded(action, response));
      } catch (exception) {
        if (exception.status === 202 && exception.data && exception.data.task_id) {
          yield put(fetchPendingTasksRequested([exception.data.task_id]));

          yield put(requestStartedBackgroundTask(action));

          yield put(
            subscribeForTaskCompletion(
              exception.data.task_id,
              (response, task?: Task) => {
                store.dispatch(
                  fetchActions.httpRequestSucceeded(action, response, task)
                );
              },
              (message, task?: Task) => store.dispatch(
                fetchActions.httpRequestFailed(action, { message }, task)
              )
            )
          );

          return;
        } else if (exception.status === 401) {
          yield put(siteRequestAuthorizationFailed(action));
          return;
        }

        // executed if exception is not for background task or re-auth
        yield put(fetchActions.httpRequestFailed(action, exception));
      }
    }); // End of Fork Saga

    yield delay((window as any).ajax_timeout ? (window as any).ajax_timeout : DEFAULT_TIMEOUT);

    if (apiCallHandler.isRunning()) {
      yield cancel(apiCallHandler);
      yield put(fetchActions.httpRequestFailed(action, 'TIMED_OUT'));
    }
  };
}

export default handleAvalonApiRequest;
