import * as React from 'react';
import { Container, DatePicker, Grid, Section, Tab, Tabs } from 'sg-styleguide';
import { getCountries } from '../../../core/actions/countries';
import { requestDomainStats } from '../../../core/actions/pages/resource-stats';
import { API_RESOURCE } from '../../../core/constants/api';

import customRequestTypes from '../../../core/constants/custom-request-types';
import { Domain, StatsType, Webstats } from '../../../core/definitions/stats';

import { RootState } from '../../../core/reducers';
import { getCountryNameByCode, filterStagingDomains } from '../../../core/selectors';
import { getStats, areStatsMissing } from '../../../core/selectors/resource-stats';
import { getDateId, getDateReadyFormat } from '../../../core/utils/stats-format';
import DomainSelect from '../../components/domain-select';
import { getCalendarTranslations } from '../../components/form-date-picker/form-date-picker';
import { formatDateToMonthYear } from './utils';

// COMPONENTS
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../containers/partial-loader';
import { SecondLevelTitle } from '../../containers/titles';
import SGTable from './../../components/sg-table';
import Audience from './audience';
import Behaviour from './behaviour';
import Sources from './sources';
import Technology from './technology';
import Traffic from './traffic';

type Props = {
  environment: {
    isPhone: boolean;
    isTablet: boolean;
  };
  listedDomains: any[];
  userLang: string;
  countries: RootState['countries'];
  requestDomainStats: (domainId: any, dateId: string) => any;
  getCountries: Function;
  getCountryNameByCode: (countryCode: string) => string;
  areStatsMissing: (dateId: string, domainName: string) => boolean,
  selectStats: (dateId: string, domainName: string, statsType: StatsType) => any;
  intl: Intl;
};

type State = {
  selectedDomain: {
    id: number;
    name: string;
  };
  activeTab: string;
  selectedDateInSeconds: number;
};

const TABS = [{
  id: 'traffic',
  label: 'translate.page.stats.traffic.summary.title'
}, {
  id: 'audience',
  label: 'translate.page.stats.traffic.audience.title'
}, {
  id: 'sources',
  label: 'translate.page.stats.traffic.sources.title'
}, {
  id: 'behaviour',
  label: 'translate.page.stats.behaviour.label'
}, {
  id: 'technology',
  label: 'translate.page.stats.technology.label'
}];

export const isMonthInTheFuture = ([firstDate, ...other]) => Boolean(firstDate.getTime() > new Date().getTime());

class ResourceStats extends React.Component<Props, State> {
  readonly state = {
    selectedDomain: null,
    activeTab: TABS[0].id,
    selectedDateInSeconds: +new Date() / 1000
  };

  onTabSelected = (id) => this.setState({ activeTab: id });

  renderPageContentItems = () => {
    const { activeTab, selectedDomain } = this.state;
    const { requestDomainStats, getCountryNameByCode, selectStats, intl, environment, areStatsMissing } = this.props;
    const domainName = selectedDomain && selectedDomain.name;
    const currentDateId = getDateId(this.state.selectedDateInSeconds);

    const contentProps = {
      intl,
      environment,
      statsMissing: areStatsMissing(currentDateId, domainName),
      getStats: (statsType: StatsType) => selectStats(currentDateId, domainName, statsType),
      renderDatepicker: () => (
        <DatePicker
          key="datepicker"
          value={this.state.selectedDateInSeconds}
          shouldDisableMonth={isMonthInTheFuture}
          label={intl.formatMessage({ id: 'translate.page.stats.select-period.label' })}
          size="small"
          disableDayPicker
          hideDayInHeader
          renderInputDateValue={(date) => formatDateToMonthYear(date, this.props.userLang)}
          onChange={(selectedDateInSeconds) => {
            this.setState({ selectedDateInSeconds });
            requestDomainStats(domainName, getDateId(selectedDateInSeconds));
          }}
          translation={getCalendarTranslations(intl)}
        />
      )
    };

    switch (activeTab) {
      case 'traffic':
        return <Traffic {...contentProps} />;
      case 'audience':
        return (
          <Audience
            {...contentProps}
            getCountryNameByCode={getCountryNameByCode}
          />
        );
      case 'sources':
        return <Sources {...contentProps} />;
      case 'behaviour':
        return (
          <Behaviour
            domain={domainName}
            {...contentProps}
          />
        );
      case 'technology':
        return <Technology {...contentProps} />;
      default:
        return null;
    }
  };

  render() {
    const { listedDomains, requestDomainStats, intl } = this.props;
    const { selectedDomain, activeTab } = this.state;

    return (
      <React.Fragment>
        <PageHeader
          icon="presentational-traffic-statistics"
          title={intl.formatMessage({ id: 'translate.page.stats.title' })}
          instructions={intl.formatMessage({ id: 'translate.page.stats.info-text' })}
        />
        <Section>
          <Grid>
            <DomainSelect
              options={listedDomains}
              selectedValue={selectedDomain && selectedDomain.id}
              optionValue="id"
              optionLabel="name"
              onChange={this.handleDomainSelect}
            />
            <div>
              <SecondLevelTitle>
                {intl.formatMessage({ id: 'translate.page.stats.statistics.title' })}
              </SecondLevelTitle>

              <Container padding="none">
                <Tabs size="small" border="light">
                  {TABS.map(({ id, label }) =>
                    <Tab
                      key={id}
                      active={activeTab === id}
                      data-e2e={`stats-tab-${id}`}
                      onClick={() => this.onTabSelected(id)}
                    >
                      {intl.formatMessage({ id: label })}
                    </Tab>
                  )}
                </Tabs>
                <div style={{ width: '100%', position: 'relative' }}>
                  <PartialLoader
                    position="absolute"
                    resources={[{
                      requestTypeName: customRequestTypes.DOMAIN_STATS
                    }]}
                  >
                    <Grid padding={['none', 'large', 'large']}>
                      {this.renderPageContentItems()}
                    </Grid>
                  </PartialLoader>
                </div>
              </Container>
            </div>
          </Grid>
        </Section>
      </React.Fragment>
    );
  }

  handleDomainSelect = (domainId: number) => {
    const { listedDomains, requestDomainStats } = this.props;
    const { name } = listedDomains.find(({ id }) => id === domainId);

    this.setState({
      selectedDomain: {
        id: domainId,
        name
      }
    });

    requestDomainStats(
      name,
      getDateId(
        this.state.selectedDateInSeconds
      )
    );
  };

  componentDidMount() {
    this.props.getCountries();
  }
}

export default indexWithCRUD(
  ({ webstats, countries, environment, session, pageItems }: RootState) => ({
    countries,
    environment,
    listedDomains: filterStagingDomains({ pageItems }),
    userLang: session && session.user && session.user.language,
    getCountryNameByCode: (countryCode: string) => getCountryNameByCode({ countries }, countryCode),
    selectStats: getStats({ webstats }),
    areStatsMissing: areStatsMissing({ webstats })
  }),
  (dispatch) => ({
    requestDomainStats: (domainId: string, dateId: string) =>
      dispatch(requestDomainStats(domainId, dateId)),
    getCountries: () => dispatch(getCountries())
  })
)(ResourceStats, API_RESOURCE.DOMAIN);
