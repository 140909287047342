import * as React from 'react';
import { injectIntl } from 'react-intl';
import Section from 'sg-styleguide/lib/components/layout/section';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import ROUTES from '../../../core/constants/routes';

type MysqlPageProps = {
  location: any;
  intl: any;
  router: any;
  children: any;
};

export const MysqlPage = ({ intl, location, router, children }: MysqlPageProps) => (
  <React.Fragment>
    <PageHeader
      title={intl.formatMessage({ id: 'translate.page.mysql.title' })}
      icon="presentational-mysql-database"
      instructions={intl.formatMessage({ id: 'translate.page.mysql.instructions' })}
    />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          database: intl.formatMessage({ id: 'translate.generic.databases' }),
          user: intl.formatMessage({ id: 'translate.generic.users' }),
          access: intl.formatMessage({ id: 'translate.generic.remote' }),
          myAdmin: intl.formatMessage({ id: 'translate.generic.phpmyadmin' })
        }}
        routesByTabId={{
          database: ROUTES.MYSQL_DATABASE,
          user: ROUTES.MYSQL_USER,
          access: ROUTES.MYSQL_ACCESS,
          myAdmin: ROUTES.MYSQL_PHP_MY_ADMIN
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>
      {children}
    </Section>
  </React.Fragment>
);

export default injectIntl(MysqlPage);
