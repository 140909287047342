import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'sg-styleguide';
import SettingListItem from '../../../components/setting-list-item';

export type SettingListItemProps = {
  children: any;
  intl: Intl;
  setting: {
    id: string;
    editable: number;
    value: any;
    time_remaining: number;
  };
  selectedZone: {
    cf_plus: number;
  };
  title: string;
  info: string;
  resources: LoaderResource;
  goToUA: Function;
};

const SettingListItemWrapper = (props: SettingListItemProps) => {
  const { children, intl, setting, selectedZone, title, info, resources, goToUA } = props;

  if (!setting) {
    return null;
  }

  const isPlusAccountNeeded = setting.editable === 0 && selectedZone.cf_plus === 0;

  return (
    <SettingListItem
      title={title}
      info={info}
      disabled={isPlusAccountNeeded}
      resources={resources}
    >
      {isPlusAccountNeeded && (
        <Button color="primary" onClick={goToUA}>
          {intl.formatMessage({ id: 'translate.page.cloudflare.upgrade.to.plus' })}
        </Button>
      )}

      {!isPlusAccountNeeded && children}
    </SettingListItem>
  );
};

export default injectIntl(SettingListItemWrapper);
