import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Dialog, Flex, Grid, Text, Textarea } from 'sg-styleguide';
import { createNotification } from '../../../../core/actions/notifications';

interface ViewCertificateDialogProps {
  intl?: Intl;
  sslCertificates: any[];
  createNotification?: typeof createNotification;
}

class ViewCertificateDialog extends React.Component<ViewCertificateDialogProps, any> {
  crtDOMRef = null;
  keyDOMRef = null;
  cabundleDOMRef = null;

  constructor(props) {
    super(props);
  }

  copyToClipboard = ({ textAreaRef, successMessage, errorMessage }) => {
    const { intl, createNotification } = this.props;
    textAreaRef.disabled = false;
    textAreaRef.select();

    const copySucceeded = document.execCommand('copy');
    textAreaRef.disabled = true;

    createNotification({
      type: 'generic',
      state: copySucceeded ? 'success' : 'error',
      success: {
        intlKey: successMessage
      },
      error: {
        intlKey: errorMessage
      }
    });
  };

  renderLabel = ({ label, textAreaRef, successMessage, errorMessage }) => (
    <Flex align="center" justify="space-between" margin="none" gutter="none">
      {label}
      <Text color="secondary" onClick={() => this.copyToClipboard({ textAreaRef, successMessage, errorMessage })}>
        {this.props.intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
      </Text>
    </Flex>
  );

  render() {
    const { intl, sslCertificates, ...props } = this.props;
    const crtValue = sslCertificates.length ? sslCertificates[0].file_data.crt : '';
    const keyValue = sslCertificates.length ? sslCertificates[0].file_data.key : '';
    const cabundleValue = sslCertificates.length ? sslCertificates[0].file_data.cabundle : '';

    const crtLabel = {
      label: intl.formatMessage({ id: 'translate.page.ssl.external.crt' }),
      textAreaRef: this.crtDOMRef,
      successMessage: 'translate.generic.copied.to.clipboard',
      errorMessage: 'translate.generic.failed.copied.to.clipboard'
    };

    const keyLabel = {
      label: intl.formatMessage({ id: 'translate.page.ssl.external.key' }),
      textAreaRef: this.keyDOMRef,
      successMessage: 'translate.generic.copied.to.clipboard',
      errorMessage: 'translate.generic.failed.copied.to.clipboard'
    };

    const cabundleLabel = {
      label: intl.formatMessage({ id: 'translate.page.ssl.external.cabundle' }),
      textAreaRef: this.cabundleDOMRef,
      successMessage: 'translate.generic.copied.to.clipboard',
      errorMessage: 'translate.generic.failed.copied.to.clipboard'
    };

    return (
      <Grid>
        {crtValue && (
          <Textarea
            onRefsReady={(textarea) => {
              if (!this.crtDOMRef) {
                this.crtDOMRef = textarea;
                this.setState({ time: +new Date() });
              }
            }}
            label={this.renderLabel(crtLabel)}
            value={crtValue}
            rows="4"
            readOnly
            disabled
          />
        )}

        {keyValue && (
          <Textarea
            onRefsReady={(textarea) => {
              if (!this.keyDOMRef) {
                this.keyDOMRef = textarea;
                this.setState({ time: +new Date() });
              }
            }}
            label={this.renderLabel(keyLabel)}
            value={keyValue}
            rows="4"
            readOnly
            disabled
          />
        )}

        {cabundleValue && (
          <Textarea
            onRefsReady={(textarea) => {
              if (!this.cabundleDOMRef) {
                this.cabundleDOMRef = textarea;
                this.setState({ time: +new Date() });
              }
            }}
            label={this.renderLabel(cabundleLabel)}
            value={cabundleValue}
            rows="4"
            readOnly
            disabled
          />
        )}
      </Grid>
    );
  }
}

export default connect<any, any, ViewCertificateDialogProps>(undefined, (dispatch) => ({
  createNotification: (params) => dispatch(createNotification(params))
}))(injectIntl(ViewCertificateDialog));
