import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  Label,
  Link,
  List,
  ListItem,
  Text,
  Title
} from 'sg-styleguide';

import { textToHTML } from 'sg-styleguide/lib/utils';
import FormattedCurrency from '../../../components/formatted-currency';
import ExcludingTax from '../../../components/excluding-tax';
import PromoSystem from '../../../components/promo-system';
import { BuyBackupTypes } from '../../../../core/constants/common';
import './backup-plan-card.scss';

type Props = {
  name: string;
  title: string;
  className?: string;
  label?: string;
  description?: string;
  subtitle?: string;
  icon?: string;
  multicolorIcon?: boolean;
  singlePrice?: string;
  priceData: PriceData;
  features?: string[];
  isSelected: boolean;
  disabled: boolean;
  onSelect: (selected: boolean) => void;
  intl: Intl;
};

/*
  TODO:

  Refactor this with styleguide PlanCard
*/

class BackupPlanCard extends React.Component<Props, any> {
  renderLabel() {
    const { label } = this.props;
    if (!label) {
      return null;
    }
    return (
      <Label className="backup-plan-card-label" type="active">
        {label}
      </Label>
    );
  }

  renderSelectButton() {
    const { intl, isSelected, onSelect, disabled = false } = this.props;

    return (
      <div style={{ marginTop: 'auto' }}>
        <Button
          color="primary"
          disabled={disabled}
          type={isSelected ? 'default' : 'outlined'}
          action="button"
          onClick={() => onSelect(!isSelected)}
        >
          {isSelected && (
            <Icon name="check" />
          )}
          {isSelected ?
            intl.formatMessage({ id: 'translate.generic.selected' }) :
            intl.formatMessage({ id: 'translate.generic.select' })
          }
        </Button>
      </div>
    );
  }

  render() {
    const { name, className,  icon, title, subtitle, intl, multicolorIcon } = this.props;

    const classes = [
      'backup-plan-card',
      name && `backup-plan-card--${name}`,
      className
    ].filter(Boolean).join(' ');

    return (
      <Container className={classes}>
        {this.renderLabel()}

        <Flex justify="center" padding="none" margin="none">
          <Icon name={icon} size="50" className="backup-plan-card__icon" multicolor={multicolorIcon} />
        </Flex>

        <Flex gutter="x-large" direction="column" align="center" margin="none">
          <Title level="3" density="compact">
            {title}
          </Title>
          <Text color="lighter">
            {subtitle}
          </Text>
        </Flex>

        <Flex
          align="center"
          background="light"
          direction="column"
          padding="medium"
        >
          {this.renderPlanPrices()}
        </Flex>

        {this.renderPlanDescription()}
        {this.renderSelectButton()}
      </Container>
    );
  }

  renderPlanPrices = () => {
    const { intl, name, priceData, singlePrice } = this.props;

    switch (name) {
      case BuyBackupTypes.singleBackup:
        return (
          <React.Fragment>
            <Title level="3" color="dark" density="none" weight="regular">
              <FormattedCurrency price={singlePrice} />
            </Title>
            <Text color="light" weight="light">
              <ExcludingTax />
            </Text>
          </React.Fragment>
        );
      case BuyBackupTypes.growBig:
        return (
          <React.Fragment>
            <Title
              level="3"
              color="dark"
              density="none"
              weight="regular"
            >
              <FormattedCurrency
                price={priceData.price}
                period="1"
              />
            </Title>
            <Text color="light">
              <PromoSystem
                text={priceData.regular_price_text}
                price={priceData.price}
                regular_price={priceData.regular_price}
                userData={{
                  taxName: 'VAT',
                  currencySymbol: '&#36;',
                  currencySymbolBefore: true,
                  currency: 'EUR'
                }}
              />
            </Text>
            <Text color="light" weight="light">
              <ExcludingTax />
            </Text>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  renderPlanDescriptionItem = () => {
    const { intl, name, features } = this.props;

    switch (name) {
      case BuyBackupTypes.singleBackup:
        return (
          <React.Fragment>
            <Text>
              {textToHTML(intl.formatMessage(
                { id: 'translate.page.backup.buy-backup.single-backup.instant-backups-count.text' },
                { count: 1 }
              ))}
            </Text>
            <Text>
              {textToHTML(intl.formatMessage(
                { id: 'translate.page.backup.buy-backup.single-backup.restores.text' }
              ))}
            </Text>
          </React.Fragment>
        );
      case BuyBackupTypes.growBig:
        return features.length && (
          <React.Fragment>
            {
              features.map((feature) => (
                <Text>
                  {textToHTML(feature)}
                </Text>
              ))
            }
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  renderPlanDescription = () => {
    return (
      <Flex
        align="center"
        direction="column"
        padding={['medium', 'medium', 'x-large', 'medium']}
        margin="none"
      >
        {this.renderPlanDescriptionItem()}
      </Flex>
    );
  }
}

export default injectIntl(BackupPlanCard);
