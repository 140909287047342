import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

type Props = {
  taxName: string;
  className: string;
  color: string;
  transform: string;
  intl: Intl;
};

const ExcludingTax = ({
  taxName,
  className = '',
  color = 'light',
  transform = 'uppercase',
  intl
}: Props) => {

  if (!taxName) {
    return null;
  }

  const styles: any = {
    color,
    textTransform: transform,
    fontWeight: 'light'
  };

  return (
    <span style={styles} className={className}>
      {`${intl.formatMessage({ id: 'translate.generic.excluding' })} ${taxName}`}
    </span>
  );
};

const mapStateToProps = (state): Partial<Props> => ({
  taxName: state.session && state.session.user.tax_name
});

export default connect<any, any, Partial<Props>>(mapStateToProps)(
  injectIntl(ExcludingTax)
);
