import * as React from 'react';
import { IndexRoute, Route } from 'react-router';
import ROUTES from './core/constants/routes';
import App from './web/pages/app';
import AppManager from './web/pages/app-manager';
import BackupRestorePage from './web/pages/backup-restore';
import BackupRestoreCreateRestorePage from './web/pages/backup-restore/create-restore';
import BackupRestoreHistoryPage from './web/pages/backup-restore/restore-history';
import CloudflarePage from './web/pages/cloudflare';
import CronPage from './web/pages/cron';
import CronCronPage from './web/pages/cron/job';
import CronNotificationPage from './web/pages/cron/notification';
import Dashboard from './web/pages/dashboard';
import DnsPage from './web/pages/dns';
import DomainAliasPage from './web/pages/domain-alias';
import EmailPage from './web/pages/email';
import EmailAuthentication from './web/pages/email-authentication';
import EmailAutoresponderPage from './web/pages/email-autoresponder';
import EmailFilterPage from './web/pages/email-filters';
import EmailForwardPage from './web/pages/email-forward';
import ErrorLogs from './web/pages/error-logs';
import FileManagerPage from './web/pages/file-manager';
import FtpPage from './web/pages/ftp';
import Git from './web/pages/git';
import HttpsEnforce from './web/pages/https-enforce';
import LocationIpBlockPage from './web/pages/location-ip-block';
import MysqlPage from './web/pages/mysql';
import MysqlAccessPage from './web/pages/mysql/mysql-access';
import MysqlPHPMyAdmin from './web/pages/mysql/php-my-admin';
import MysqlDbPage from './web/pages/mysql/mysql-db';
import MysqlUserPage from './web/pages/mysql/mysql-user';
import PgsqlPage from './web/pages/pgsql';
import PgsqlAccessPage from './web/pages/pgsql/pgsql-access';
import PgsqlDbPage from './web/pages/pgsql/pgsql-db';
import PgsqlUserPage from './web/pages/pgsql/pgsql-user';
import PhpSettings from './web/pages/php-settings';
import ProtectedUrlsPage from './web/pages/protected-url';
import ProtectedURLs from './web/pages/protected-url/protected-URLs';
import ProtectedUsers from './web/pages/protected-url/protected-users';
import RedirectPage from './web/pages/redirect';
import ResourceStats from './web/pages/resource-stats';
import SpamExperts from './web/pages/spam-experts';
import SSHPage from './web/pages/ssh';
import SSL from './web/pages/ssl';
import SubdomainPage from './web/pages/subdomain';
import SuperCacherTabs from './web/pages/super-cacher';
import DomainCachePage from './web/pages/super-cacher/domain-cache';
import DynamicCachePage from './web/pages/super-cacher/dynamic-cache';
import MemcachedPage from './web/pages/super-cacher/memcached';
import WPAutoupdate from './web/pages/wp-autoupdate';
import WPManage from './web/pages/wp-manage';
import WPStaging from './web/pages/wp-staging';
import WPTransfer from './web/pages/wp-transfer';
import SGSiteScanner from './web/pages/sg-sitescanner';

// ROUTES_IMPORT_GENERATOR_TAG (Do Not Touch)

export function getRoutes(store: any) {
  return (
    <Route path={ROUTES.INDEX} component={App}>
      <IndexRoute component={Dashboard} />
      <Route path={ROUTES.DASHBOARD} component={Dashboard} />

      {/*ROUTES_GENERATOR_TAG (Do Not Touch)*/}

      <Route path={ROUTES.SSL} component={SSL} />

      <Route path={ROUTES.WP_MANAGE} component={WPManage} />

      <Route path={ROUTES.WP_AUTOUPDATE} component={WPAutoupdate} />

      <Route path={ROUTES.BACKUP} component={BackupRestorePage}>
        <IndexRoute component={BackupRestoreCreateRestorePage} />
        <Route path={ROUTES.BACKUP_RESTORE_MANAGE} component={BackupRestoreCreateRestorePage} />
        <Route path={ROUTES.BACKUP_RESTORE_HISTORY} component={BackupRestoreHistoryPage} />
      </Route>

      <Route path={ROUTES.CLOUDFLARE} component={CloudflarePage} />

      <Route path={ROUTES.EMAIL_AUTORESPONDER} component={EmailAutoresponderPage} />

      <Route path={ROUTES.EMAIL_FILTERS} component={EmailFilterPage} />

      <Route path={ROUTES.EMAIL_FORWARD} component={EmailForwardPage} />

      <Route path={ROUTES.EMAIL_AUTHENTICATION} component={EmailAuthentication} />

      <Route path={ROUTES.SPAM_EXPERTS} component={SpamExperts} />

      <Route path={ROUTES.SSH} component={SSHPage} />

      <Route path={ROUTES.CRON} component={CronPage}>
        <IndexRoute component={CronCronPage} />
        <Route path={ROUTES.CRON_JOB} component={CronCronPage} />
        <Route path={ROUTES.CRON_NOTIFICATION} component={CronNotificationPage} />
      </Route>

      <Route path={ROUTES.PHP_SETTINGS} component={PhpSettings} />

      <Route path={ROUTES.LOCATION_IP_BLOCK} component={LocationIpBlockPage} />

      <Route path={ROUTES.REDIRECT} component={RedirectPage} />

      <Route path={ROUTES.CACHER} component={SuperCacherTabs}>
        <IndexRoute component={DomainCachePage} />
        <Route path={ROUTES.CACHER_STATIC_CACHE} component={DomainCachePage} />
        <Route path={ROUTES.CACHER_DYNAMIC_CACHE} component={DynamicCachePage} />
        <Route path={ROUTES.CACHER_MEMCACHED} component={MemcachedPage} />
      </Route>

      <Route path={ROUTES.DNS} component={DnsPage} />

      <Route path={ROUTES.MYSQL} component={MysqlPage}>
        <IndexRoute component={MysqlDbPage} />
        <Route path={ROUTES.MYSQL_DATABASE} component={MysqlDbPage} />
        <Route path={ROUTES.MYSQL_USER} component={MysqlUserPage} />
        <Route path={ROUTES.MYSQL_ACCESS} component={MysqlAccessPage} />
        <Route path={ROUTES.MYSQL_PHP_MY_ADMIN} component={MysqlPHPMyAdmin} />
      </Route>

      <Route path={ROUTES.PGSQL} component={PgsqlPage}>
        <IndexRoute component={PgsqlDbPage} />
        <Route path={ROUTES.PGSQL_DATABASE} component={PgsqlDbPage} />
        <Route path={ROUTES.PGSQL_USER} component={PgsqlUserPage} />
        <Route path={ROUTES.PGSQL_ACCESS} component={PgsqlAccessPage} />
      </Route>

      <Route path={ROUTES.PROTECTED} component={ProtectedUrlsPage}>
        <IndexRoute component={ProtectedURLs} />
        <Route path={ROUTES.PROTECTED_URLS} component={ProtectedURLs} />
        <Route path={ROUTES.PROTECTED_USERS} component={ProtectedUsers} />
      </Route>

      <Route path={ROUTES.SUBDOMAIN} component={SubdomainPage} />
      <Route path={ROUTES.PARKED_DOMAINS} component={DomainAliasPage} />
      <Route path={ROUTES.EMAIL} component={EmailPage} />
      <Route path={ROUTES.FTP} component={FtpPage} />

      <Route path={ROUTES.FILE_MANAGER} component={FileManagerPage} />
      <Route path={ROUTES.WP_STAGING} component={WPStaging} />
      <Route path={ROUTES.WP_TRANSFER} component={WPTransfer} />

      <Route path={ROUTES.RESOURCE_STATS} component={ResourceStats} />
      <Route path={ROUTES.ERROR_LOGS} component={ErrorLogs} />
      <Route path={ROUTES.HTTPS_ENFORCE} component={HttpsEnforce} />
      <Route path={ROUTES.APP_MANAGER} component={AppManager} />
      <Route path={ROUTES.GIT} component={Git} />

      <Route path={ROUTES.SG_SITESCANNER} component={SGSiteScanner} />
    </Route>
  );
}
