import { openNewTab } from 'sg-styleguide/lib/utils';
import 'whatwg-fetch';

export function downloadFile(endpoint: string, state: any, urlParams = {}) {
  const currentSiteId: string = state.routing.locationBeforeTransitions.query.siteId;
  const siteToken: string = state.session.siteTokens[currentSiteId].siteToken;
  let currentSiteObj: SiteItem;

  state.sites.items.map((siteObj: SiteItem) => {
    if (siteObj.id === currentSiteId) {
      currentSiteObj = siteObj;
    }
  });

  const apiHost = 'https://' + currentSiteObj.api_url + '/api-sgcp/v00';

  let urlParamsStr = '';

  Object.keys(urlParams).forEach((key) => {
    urlParamsStr += key + '=' + encodeURIComponent(urlParams[key]) + '&';
  });

  let isNewTabOpened;
  const fileUrl = apiHost + endpoint + '?' + urlParamsStr + '_site_token=' + siteToken;
  openNewTab({ url: fileUrl, callback: (isPopupBlocked) => isNewTabOpened = !isPopupBlocked });

  return isNewTabOpened;
}
