import { createAction } from 'redux-actions';
import {
  DOMAIN_STATS_REQUESTED,
  DOMAIN_STATS_SUCCEEDED,
  DOMAIN_STATS_FAILED
} from '../../constants/actions';

import customRequestTypes from '../../constants/custom-request-types';

export const requestDomainStats = (domainId: string, dateId: string) => ({
  type: DOMAIN_STATS_REQUESTED,
  payload: {
    domainId,
    dateId
  },
  requestTypeName: customRequestTypes.DOMAIN_STATS
});

export const successDomainStats =
  createAction(DOMAIN_STATS_SUCCEEDED, (domainId: string, dateId: string, data: object) =>
    ({ domainId, dateId, data }));
