import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Column, Grid, Notice, Text } from 'sg-styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import ROUTES from '../../../../core/constants/routes';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

const formName = REDUX_FORM.CREATE_MYSQL_DATABASE;

type Props = {
  intl?: Intl;
  router?: any;
  location?: any;
  dbMysqlUser?: any[];
  onAddUserToDatabase: Function;
  environment?: any;
  onSubmit: Function;
};

class Create extends React.Component<Props, void> {
  render() {
    const { intl, onAddUserToDatabase, environment, onSubmit } = this.props;
    const { dbMysqlUser, router, location } = this.props;

    return (
      <VCS resourceName={API_RESOURCE.MYSQL_DB.resourceNameMetaApi} hasMethod="POST">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.database.create.title' })}
          renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
            if (notification.state === 'error') {
              return renderDefaultNotificationTemplate();
            }

            return (
              <div style={{ textAlign: 'center' }}>
                {dbMysqlUser.length ? (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="add-to-database"
                    onClick={(event) => {
                      event.preventDefault();
                      onAddUserToDatabase(notification.responseData);
                    }}
                  >
                    {intl.formatMessage({ id: 'translate.page.database.create.button.add.user.to.database' })}
                  </Button>
                ) : (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="create-user"
                    onClick={() => router.push(`${ROUTES.MYSQL_USER}${location.search}`)}
                  >
                    {intl.formatMessage({ id: 'translate.page.database.create.button.create.user' })}
                  </Button>
                )}

                <MobileBreakElement />

                <Button
                  type="outlined"
                  color="secondary"
                  data-e2e="create-new"
                  onClick={removeNotification}
                >
                  {intl.formatMessage({ id: 'translate.page.database.create.button.create.new' })}
                </Button>
              </div>
            );
          }}
          resourceName={API_RESOURCE.MYSQL_DB.resourceName}
          formName={formName}
          resources={[{
            resourceName: API_RESOURCE.MYSQL_DB.resourceName,
            methods: ['POST']
          }]}
        >
          <Grid
            sm="12"
            padding={environment.isPhone ? 'medium' : 'large'}
            gap={environment.isPhone ? 'medium' : 'large'}
          >
            <Column smSpan="12">
              <Notice
                type="info"
                background="light"
                shadow={false}
              >
                <Text>
                  {intl.formatMessage({ id: 'translate.page.database.create.notice.one.click' })}
                </Text>
              </Notice>
            </Column>
            <Column smSpan="12">
              <Button
                color="primary"
                action="button"
                data-e2e="create-box-submit"
                onClick={() => onSubmit({
                  _metaFields: {
                    ...API_RESOURCE.MYSQL_DB
                  }
                })}
              >
                {intl.formatMessage({ id: 'translate.page.database.create.button.database' })}
              </Button>
            </Column>
          </Grid>
        </CreateBox>
      </VCS>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  location: state.location,
  dbMysqlUser: state.pageItems.dbMysqlUser || [],
  environment: state.environment
});

export default connect<{}, {}, Props>(mapStateToProps)(withRouter(injectIntl(Create)));
