import { call, put, select, takeEvery } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import * as Actions from '../../constants/actions';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* deleteItemSaga({ payload }: DeleteItemAction) {
  const state = yield select();
  const { itemId, bodyParams, fetchAfterDelete } = payload; // TODO bodyParams move after app-manager is rdy

  const { endpoint, parentResourceName, resourceName } = payload._metaFields;

  const response = yield call(siteApi({
    endpoint: endpoint + '/' + itemId,
    method: 'DELETE',
    body: bodyParams,
    state
  }));

  yield put({ item: response.data, type: Actions.DELETE_ITEM_SUCCEEDED, resourceName, parentResourceName });

  const endpointMeta = state.siteMetaApi.endpoints[endpoint.substr(1)];
  const endpointHasUnstableIds = endpointMeta && endpointMeta.options && endpointMeta.options.unstable_ids;

  if (endpointHasUnstableIds) {
    yield put({ type: Actions.FETCH_ITEMS_REQUESTED, payload: { resourceName, endpoint } });
  }

  if (fetchAfterDelete) {
    yield put({ type: Actions.FETCH_ITEMS_REQUESTED, payload: { ...fetchAfterDelete } });
  }

  return response;
}

function* deleteItem(): any {
  yield takeEvery(Actions.DELETE_ITEM_REQUESTED, handleAvalonApiRequest(deleteItemSaga));
}

export default deleteItem;
