import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../containers/visibility-control-service';

type Props = {
  children: any;
  intl: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.LOCATION_IP_BLOCK;
const formName = REDUX_FORM.CREATE_LOCATION_IP_BLOCK;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.locationIpBlock.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[{
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['GET']
      }, {
        resourceName,
        methods: ['POST']
      }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
