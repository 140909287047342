import * as React from 'react';
import { injectIntl } from 'react-intl';
import Section from 'sg-styleguide/lib/components/layout/section';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import ROUTES from '../../../core/constants/routes';

type CronProps = {
  location: any;
  intl: Intl;
  router: any;
  children: any;
};

export const CronPage = ({ intl, location, router, children }: CronProps) => (
  <React.Fragment>
    <PageHeader
      title={intl.formatMessage({ id: 'translate.page.cron.title' })}
      icon="presentational-cron-job"
      instructions={intl.formatMessage({ id: 'translate.page.cron.info' })}
    />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          job: intl.formatMessage({ id: 'translate.page.cron.title' }),
          notification: intl.formatMessage({ id: 'translate.page.cron.notification.title' })
        }}
        routesByTabId={{
          job: ROUTES.CRON_JOB,
          notification: ROUTES.CRON_NOTIFICATION
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>
      {children}
    </Section>
  </React.Fragment>
);

export default injectIntl(CronPage);
