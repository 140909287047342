import { createAction } from 'redux-actions';
import * as Actions from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

export const pageLoad = (payload) => ({
  type: Actions.PAGE_LOAD,
  requestTypeName: customRequestTypes.PAGE_LOAD,
  payload
});

export const loginUser = (payload, onComplete = null) => ({
  type: Actions.LOGIN_REQUESTED,
  payload,
  onComplete,
  requestTypeName: customRequestTypes.LOGIN
});

export const storeSessionData = (session) => ({
  type: Actions.STORE_SESSION_DATA,
  payload: {
    session
  }
});

export const changeCurrentSite = (siteId: string) => ({
  type: Actions.CHANGE_CURRENT_SITE,
  payload: {
    siteId
  },
  requestTypeName: customRequestTypes.CHANGE_CURRENT_SITE
});

export const logoutUser = ({ sessionExpired = false } = {}) => ({
  type: Actions.LOGOUT,
  requestTypeName: customRequestTypes.LOGOUT,
  payload: {
    sessionExpired
  }
});

export const refreshClientToken = () => ({ type: Actions.REFRESH_CLIENT_TOKEN });

export const refreshClientTokenSucceeded = (clientTokenData) => ({
  type: Actions.REFRESH_CLIENT_TOKEN_SUCCEEDED,
  session: clientTokenData
});

export const refreshClientTokenFailed = () => ({ type: Actions.REFRESH_CLIENT_TOKEN_FAILED });

export const siteRequestAuthorizationFailed = (actionToRetry) => ({
  type: Actions.SITE_REQUEST_AUTHORIZATION_FAILED,
  payload: { actionToRetry }
});

export const refreshSiteToken = () => ({ type: Actions.REFRESH_SITE_TOKEN });

export const refreshSiteTokenSucceeded = (siteId, siteToken) => ({
  type: Actions.REFRESH_SITE_TOKEN_SUCCEEDED,
  payload: {
    siteId,
    siteToken
  }
});

export const refreshSiteTokenFailed = () => ({ type: Actions.REFRESH_SITE_TOKEN_FAILED });
