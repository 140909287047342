import * as React from 'react';
import { Flex, Grid, Column, Text, Textarea } from 'sg-styleguide';
import { injectIntl } from 'react-intl';
import { DIALOGS } from '../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../containers/sg-dialog';

import { MalwareReportEntry } from '../../../core/definitions/sg-sitescanner';

type Props = {
  intl?: Intl;
  malwareReport: MalwareReportEntry[];
  domainName: string;
  handleCopyToClipboard: (value: string) => any;
};

export const MalwareDetails = ({ intl, malwareReport, domainName, handleCopyToClipboard }: Props) => {
  return (
    <SGDialog
      id={DIALOGS.SG_SITESCANNER_MALWARE_DETAILS}
      state="warning"
      icon="e2e"
      size="large"
      title={intl.formatMessage({
        id: 'translate.page.sg-sitescanner.malware-found.title'
      }, { site: domainName })}
      footer={(
        <SGDialogCancel
          id={DIALOGS.SG_SITESCANNER_MALWARE_DETAILS}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      )}
    >
      <Grid>
        {
          malwareReport && malwareReport.map((r) => (
            <Column>
              <Text weight="bold" align="left" color="dark">
                {intl.formatMessage({
                  id: 'translate.page.sg-sitescanner.url.label'
                })}: <Text tag="span" weight="light">{r.url}</Text>
              </Text>
              <Text weight="bold" align="left" color="dark" padding={['none', 'none', 'x-small', 'none']}>
                {intl.formatMessage({
                  id: 'translate.page.sg-sitescanner.threat.label'
                })}: <Text tag="span" weight="light">{r.threat}</Text>
              </Text>
              <Textarea
                readOnly
                rows="4"
                value={r.details}
                size="small"
                label={
                  <Flex align="center" justify="space-between" margin="none" gutter="none">
                    {intl.formatMessage({ id: 'translate.page.sg-sitescanner.details.label' })}
                    <Text color="secondary" onClick={() => handleCopyToClipboard(r.details)}>
                      {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                    </Text>
                  </Flex>
                }
              />
            </Column>
          ))
        }
      </Grid>
    </SGDialog>
  );
};

export default injectIntl(MalwareDetails) as (props: Props) => any;
