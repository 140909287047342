import { FileManagerUploadInitialState, FileManagerRootState } from './internal-state-definition';

export const uploadInitialState: FileManagerUploadInitialState = {
  requested: [],
  pending: [],
  uploaded: [],
  failed: [],
  totalSizeTransferred: 0
};

export const initialFileManagerState: FileManagerRootState = {
  entities: {},
  codeEditor: {
    files: []
  },
  contextNavigationEntity: null,
  contextContentEntities: [],
  messageDialog: null,
  confirmDialog: null,
  selectedNavigationEntity: null,
  selectedContentEntities: [],
  uploader: { ...uploadInitialState },
  time: 0,

  // Selectors
  prioritizedEntities: []
};
