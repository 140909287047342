import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../core/constants/common';
import FormPassword from '../../components/form-password';
import { withSpanelForm } from '../../containers/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.GENERIC_PASSWORD;

export const ChangePassword = ({
  intl,
  validationUtils
}: Props) => (
  <Field
    name="password"
    type="password"
    label={intl.formatMessage({ id: 'translate.generic.new.password' })}
    validate={[validationUtils.required, validationUtils.password]}
    component={FormPassword}
  />
);

export default injectIntl(
  withSpanelForm(
    reduxForm({ form: formName })(ChangePassword)
  )
);
